import React, { useMemo } from 'react';
import EditIcon from '../icon/EditIcon';
import SortIcon from '../icon/SortIcon';
import SupprimerIcon from '../icon/SupprimerIcon';
import { iconExtension } from '../../utils/GeneralHelper';
import { useTable, usePagination, useSortBy } from 'react-table';
import DocumentDotMenu from '../DocumentDotMenu';


const EquipementsListTable = (props) => {
    //console.log(props)
    //import des columns
    const EQUIPEMENTS_LIST_COLUMN = [
        {
            Header: 'Nom',
            accessor: 'nom'
        },
        {
            Header: 'Marque',
            accessor: 'marque'
        },
        {
            Header: 'N° de série',
            accessor: 'numero_de_serie'
        },
        {
            Header: 'Référence interne',
            accessor: 'ref_interne'
        },
        {
            Header: 'Date du dernier contrôle',
            accessor: date => {
                if (date.date_dernier_controle !== undefined) {
                    return new Date(date.date_dernier_controle).toLocaleDateString("fr-FR");
                    //return new Intl.DateTimeFormat('fr-FR').format(date.date_dernier_controle);
                };
                return "Aucune";
            }
        },
        {
            Header: 'Date prochain controle',
            accessor: date => {
                if (date.date_prochain_controle !== undefined) {
                    if (new Date(date.date_prochain_controle) < new Date()) {
                        return <span style={{ color: "#D8556B" }}>{new Date(date.date_prochain_controle).toLocaleDateString("fr-FR")}</span>;
                    }
                    return new Date(date.date_prochain_controle).toLocaleDateString("fr-FR");
                };
                return "Aucune";
            }
        },
        {
            Header: 'Document',
            accessor: "document",
            Cell: ({ row }) => (
                <div className="tableTdContainer" >
                    {row.original.document !== undefined ? iconExtension(row.original.document.split(".").pop()) : ''}
                    {row.original.titre !== undefined ? row.original.titre : ''}
                </div>
            )
        },
        {
            Header: 'Actions',
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button onClick={() => props.modifierEquipementEpc(row.original)} className='table-modify-button'><EditIcon /></button>
                    <button onClick={() => props.supprimerEquipementEpc(row.original)} className='table-delete-button' style={{ marginRight: "0.8rem" }}><SupprimerIcon /></button>
                    <DocumentDotMenu
                        documentSaveRoute={"documentsEPC"}
                        documentId={row.original.IDdocument}
                        extension={row.original.document !== undefined ? row.original.document.split(".") : undefined}
                        fileName={`${row.original.document !== undefined ? row.original.document : undefined}`}
                        openUploadDocument={() => props.openUploadDocument({ refId: row.original._id })}
                        openSupprimerDocument={() => props.openSupprimerDocument(row.original.IDdocument)}
                        openRemplacerDocument={() => props.openRemplacerDocument({ IDdocument: row.original.IDdocument, refId: row.original._id })}
                    />
                    {/* <button onClick={() => props.historiqueEquipementEpc(`historique/${row.original.gestion_suivi}`)} className='select-semaine-button default-radius'><Icon icon="fluent:clipboard-search-24-regular" /></button>  */}
                </div>
            ),
        }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => EQUIPEMENTS_LIST_COLUMN, []);

    //console.log(props);

    const tableInstance = useTable({
        columns,
        data: props.equipementEpcList,
        initialState: {
            pageIndex: 0,
            sortBy: [
                {
                    id: 'nom_equipement',
                    desc: false
                }
            ]
        }
    }, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance;

    return (
        <div className="main-content">
            <table className="default-table" {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr key="header" {...headerGroup.getHeaderGroupProps}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                            {column.isSorted ? column.isSortedDesc
                                                ? <SortIcon classIconHaut="filter-icon filter-icon__haut filter-icon__active" classIconBas="filter-icon filter-icon__bas" />
                                                : <SortIcon classIconBas="filter-icon filter-icon__bas filter-icon__active" classIconHaut="filter-icon filter-icon__haut" />
                                                : <SortIcon classIconHaut="filter-icon filter-icon__haut" classIconBas="filter-icon filter-icon__bas" />
                                            }
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={i} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            <div className="pagination">
                <div className="nombreLigneParPage-pagination">
                    <p>Nombre de ligne par page:</p>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="nbPageAndButton-paginationContainer">
                    <div className="button-pagination">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <span>{'<<'}</span>
                        </button>{' '}
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <span>{'<'}</span>
                        </button>{' '}
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            <span>{'>'}</span>
                        </button>{' '}
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <span>{'>>'}</span>
                        </button>{' '}
                    </div>
                    <div className="nombrePage-pagination">
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} sur {pageOptions.length}
                            </strong>{' '}
                        </span>
                    </div>
                </div>
                <div className="goToPage-pagination">
                    <span>
                        Aller à la page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: '100px' }}
                        />
                    </span>{' '}
                </div>
            </div>
        </div>
    );
};

export default EquipementsListTable;