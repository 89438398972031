import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import * as actionCreators from '../../store/actions/exportAction';
import { useSelector, useDispatch } from 'react-redux';
import AjouterDocumentForm from '../../components/forms/DocumentsForms/AjouterDocumentForm';
import ModifierDocumentForm from '../../components/forms/ModifierDocumentForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import TableRender from '../../components/tables/TableRender';
import { getDocumentPublicColumns } from '../../components/tables/TableColumns';
import { advancedSearch } from '../../utils/GeneralHelper';

const onLoadPage = () => (actionCreators.getCategorieDocument());
const onQuitPage = () => (actionCreators.unloadCategoriesDocuments());
const getAllDocumentsPublics = () => (actionCreators.getDocumentsPublics());
const unloadDocumentsPublics = () => (actionCreators.unloadDocuments());

const DocumentsPublics = () => {
    const [showedItems, setShowedItems] = useState([]);
    const [searchBarContent, setSearchBarContent] = useState(null);
    const documentsPublics = useSelector(state => state.documents.documentsPublics);
    const userType = useSelector(state => state.user.user.statut);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(onLoadPage());
        dispatch(getAllDocumentsPublics());
        return () => {
            dispatch(onQuitPage());
            dispatch(unloadDocumentsPublics());
            setShowedItems([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchDocuments = (e) => {
        if (e.target.value !== '') {
            setSearchBarContent(e.target.value);
        }
        else {
            setSearchBarContent(null);
        }
    };

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openAddDocument = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterDocumentForm maxFiles={10} documentSaveRoute={"documentsPublics"} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierDocument = (documentData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierDocumentForm document={documentData} documentSaveRoute={"documentsPublics"} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerDocument = (documentData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerDocument" document={documentData} documentSaveRoute={"documentsPublics"} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    useEffect(() => {
        let showedDocuments = documentsPublics;
        if (searchBarContent !== null) {
            showedDocuments = showedDocuments.filter(document => advancedSearch(document._id.titre, searchBarContent));
        }
        setShowedItems(showedDocuments);
    }, [documentsPublics, searchBarContent]);


    return (
        <div className='section'>
            <div className="container-basic mgB-m2">
                <h1 className="h1-blue-dark">Documents publics</h1>
                <div className="tableau-top-section">
                    <h3>{showedItems.length} document{showedItems.length > 1 && "s"} correspond{showedItems.length > 1 && "ent"} à votre recherche</h3>
                    <input className="header-input" onChange={(e) => searchDocuments(e)} name="searchDocuments" type="text" placeholder="Rechercher un document" />
                    {/* <select className="header-select" onChange={(e) => selectCategorie(e)} name="categorieSelect" id="categorie-select">
                        <option value="All">Tous les statuts</option>
                        {categoriesTypesDocument.map((categorie) => {
                            return <option key={categorie._id} value={categorie.libelleCategorie}>{categorie.libelleCategorie}</option>;
                        })}
                    </select> */}
                    {userType === "Administration" && <button onClick={() => openAddDocument()} className='default-button default-button-blue'>+ Ajouter des documents</button>}
                </div>
            </div>
            <TableRender
                columns={getDocumentPublicColumns((documentData) => openModifierDocument(documentData), (documentData) => openSupprimerDocument(documentData))}
                tableData={showedItems}
                sortBy={[
                    {
                        id: '_id.titre',
                        desc: false
                    }
                ]}
                pageName="DocumentsPublic"
            />
            {/* <DocumentListTable openModifierDocument={(documentData) => openModifierDocument(documentData)} openSupprimerDocument={(documentData) => openSupprimerDocument(documentData)} documentsList={showedItems}/> */}
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default DocumentsPublics;