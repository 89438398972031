import React, { useEffect, useMemo } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import SortIcon from '../../icon/SortIcon';

const BasicTableRender = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => props.columns, []);

    const tableInstance = useTable({
        columns,
        data: props.tableData,
        initialState: {
            pageIndex: 0,
            pageSize: props.pageSize ? props.pageSize : 10,
            sortBy: props.sortBy,
            disableMultiSort: false
        }
    }, useGlobalFilter, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = tableInstance;

    useEffect(() => {
        if (props.filterTable) {
            setGlobalFilter(props.searchBarContent);
            props.setFilterTable(false);
        }
    }, [props.filterTable]);

    // useEffect(() => {
    //     let columnSorted = tableInstance.allColumns.find(column => column.isSorted)
    //     console.log('object');
    //     if (columnSorted) {
    //         if (props.hasOwnProperty("onSortedColumnChange")) {
    //             props.onSortedColumnChange(columnSorted.id)
    //         }
    //     }
    // }, [tableInstance])


    return (
        <div className={`main-content${props.noBorder ? " noBorder" : ""}`}>
            {props.tableTitle && <>{props.tableTitle}</>}
            <table className="default-table" {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr key="header" {...headerGroup.getHeaderGroupProps}>
                                {
                                    headerGroup.headers.map((column) => {
                                        return (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                onClick={(e) => {
                                                    if (column.canSort) {
                                                        column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                                                    }
                                                    // onSortedColumnChange(column.id)
                                                }}>{column.render("Header")}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <SortIcon classIconHaut="filter-icon filter-icon__haut filter-icon__active" classIconBas="filter-icon filter-icon__bas" />
                                                        : <SortIcon classIconBas="filter-icon filter-icon__bas filter-icon__active" classIconHaut="filter-icon filter-icon__haut" />
                                                    : <p></p>
                                                }
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={i} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            {!props.noPagination &&
                <div className="pagination">
                    <div className="nombreLigneParPage-pagination">
                        <p>Nombre de lignes par page:</p>
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="nbPageAndButton-paginationContainer">
                        <div className="button-pagination">
                            <button onClick={() => { gotoPage(0); }} disabled={!canPreviousPage}>
                                <span>{'<<'}</span>
                            </button>{' '}
                            <button onClick={() => { previousPage(); }} disabled={!canPreviousPage}>
                                <span>{'<'}</span>
                            </button>{' '}
                            <button onClick={() => { nextPage(); }} disabled={!canNextPage}>
                                <span>{'>'}</span>
                            </button>{' '}
                            <button onClick={() => { gotoPage(pageCount - 1); }} disabled={!canNextPage}>
                                <span>{'>>'}</span>
                            </button>{' '}
                        </div>
                        <div className="nombrePage-pagination">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} sur {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                    </div>
                    <div className="goToPage-pagination">
                        <span>
                            Aller à la page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                    </div>
                </div>
            }
        </div>
    );
};

export default BasicTableRender;