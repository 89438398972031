import React, { useEffect, useRef, useState } from 'react';
import * as actionCreators from '../../store/actions/exportAction';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import AjouterMateriauxForm from '../../components/forms/GestionsForms/AjouterMateriauxForm';
import ModifyMateriauxForm from '../../components/forms/ModifyMateriauxForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import TableRender from '../../components/tables/TableRender';
import { getMateriauxColumns } from '../../components/tables/TableColumns';
import Breadcrumb from '../../components/ui/Breadcrumb';
import SearchInput from '../../components/ui/SearchInput';
import LoadingBar from 'react-top-loading-bar';

const onLoadPage = () => (actionCreators.getMateriauxList());
const onQuitPage = () => (actionCreators.unloadMateriauxList());

const MateriauxListPage = () => {
    const progressBarRef = useRef(null);
    const [showedItems, setShowedItems] = useState([]);
    const [searchBarContent, setSearchBarContent] = useState(null);
    // REDUX VEHICULE LIST
    const materiauxListState = useSelector(
        state => state.materiauxList
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(onLoadPage());
        return () => {
            dispatch(onQuitPage());
            setShowedItems([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let showedMateriaux = materiauxListState.allMateriaux;
        // searchbar
        if (searchBarContent !== null) {
            showedMateriaux = showedMateriaux.filter(materiaux => materiaux.libelle_mat.toLowerCase().includes(searchBarContent.toLowerCase()));
        }
        setShowedItems(showedMateriaux);
        // console.log(showedMateriaux);
    }, [materiauxListState.allMateriaux, searchBarContent]);

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openAddMateriaux = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterMateriauxForm progressBarRef={progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifyMateriaux = (materiauxData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifyMateriauxForm progressBarRef={progressBarRef} materiaux={materiauxData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerMateriaux = (materiauxData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerMateriaux" materiaux={materiauxData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const searchMateriaux = (e) => {
        if (e.target.value !== '') {
            setSearchBarContent(e.target.value);
        }
        else {
            setSearchBarContent(null);
        }
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Liste des matériaux</h1>
                    <button onClick={() => openAddMateriaux()} className='btn btn-primary medium-btn'>+ Matériaux</button>

                </div>
                <Breadcrumb
                    links={[
                        { label: "Geston des données", link: "/gestion-de-donnees" },
                        { label: "Matériaux", link: "/gestion-de-donnees/materiaux" },
                    ]}
                />
                <div className="filterContainer mgT-m1">
                    <SearchInput placeholder="Rechercher un matériaux" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={searchMateriaux} />
                </div>
                <TableRender
                    columns={getMateriauxColumns((materiauxData) => openModifyMateriaux(materiauxData), (materiauxData) => openSupprimerMateriaux(materiauxData))}
                    tableData={showedItems}
                    sortBy={[
                        {
                            id: 'libelle_mat',
                            desc: false
                        }
                    ]}
                    pageName="GestionMateriaux"
                />
                <Modal modalClass={modalClasses}>{modalContent}</Modal>
            </div>
        </>
    );
};

export default MateriauxListPage;
