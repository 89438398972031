import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import { formatBytes, iconExtension } from '../../../utils/GeneralHelper';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import customToast from '../../../utils/ToastifyHelper';

const ModifierDocumentSalarie = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const formSchema = yup.object().shape({
        documentTitle: yup.string().required("Veuillez saisir le titre du document"),
        descriptionDocument: yup.string()
    });

    const onSubmit = (values, actions) => {
        const postData = { titre: values.documentTitle, description: values.descriptionDocument };
        // start progress
        props.startProgress();
        axiosInstance.patch(`/documents/${props.documentData._id}`, postData)
            .then((result) => {
                props.completeProgress();
                // progress complete
                setSubmitting(false);
                props.editDocumentSalarie(result.data);
                customToast.success("Document modifié");
                close();
            }).catch((err) => {
                props.completeProgress();
                setSubmitting(false);
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            "documentTitle": props.documentData.titre,
            "descriptionDocument": props.documentData.description
        },
        validationSchema: formSchema,
        onSubmit
    });

    const fieldNotModified = () => {
        return props.documentData.titre === values.documentTitle && values.descriptionDocument === props.documentData.description;
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Modifier un document de {props.salarie.nom} {props.salarie.prenom}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='documentContent' className='form-label'>Document modifié</label>
                    <div className="document-upload mgT-s2">
                        <div className="fileItem">
                            {iconExtension(props.documentData.file.filename)}
                            <p>{props.documentData.titre}.{props.documentData.file.filename.split(".").pop().toLowerCase()} - {formatBytes(props.documentData.file.length)}</p>
                        </div>
                    </div>
                </div>
                <div className='label-and-input'>
                    <label htmlFor='documentTitle' className='form-label'>Titre du document<span className='required-field'>*</span></label>
                    <input name='documentTitle' className={`new-default-input${errors.documentTitle && touched.documentTitle ? " input-error" : ""}`} type="text" placeholder="Titre du document" value={values.documentTitle} onChange={handleChange} onBlur={handleBlur} />
                    {errors.documentTitle && touched.documentTitle && <p className="form-error-message">{errors.documentTitle}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='descriptionDocument' className='form-label'>Description du document</label>
                    <textarea name='descriptionDocument' className={`new-default-input${errors.descriptionDocument && touched.descriptionDocument ? " input-error" : ""}`} placeholder="Description du document" value={values.descriptionDocument} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className="button-container-flex mgT-b">
                    <button type="button" disabled={isSubmitting} className='btn btn-secondary fw mgR-m1' onClick={() => close()}>Annuler</button>
                    <input disabled={isSubmitting || fieldNotModified()} style={{ opacity: isSubmitting || fieldNotModified() ? 0.4 : 1 }} type="submit" className='btn btn-primary fw' value="Modifier" />
                </div>
            </form>
        </div>
    );
};

export default ModifierDocumentSalarie;