import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import { getInsensitiveWorld } from '../../../utils/GeneralHelper';
import Breadcrumb from '../../../components/ui/Breadcrumb';
import SearchInput from '../../../components/ui/SearchInput';
import TableRender from '../../../components/tables/TableRender';
import { epiColumns } from '../../../components/tables/TableColumns';
import Modal from '../../../components/Modal';

import AjouterEpiForm from './AjouterEpiForm';
import ModifierEpiForm from './ModifierEpiForm';
import SupprimerModalForm from '../../../components/forms/SupprimerModalForm';

const EpiGestions = () => {
    const progressBarRef = useRef(null);
    const navigate = useNavigate();
    const [epiList, setEpiList] = useState([]);
    const [showedEpi, setShowedEpi] = useState([]);

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("equipement_amiante").then((result) => {
            progressBarRef.current.complete();
            setEpiList(result.data);
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    }, []);

    const [searchEpiBarContent, setSearchEpiBarContent] = useState(null);
    const onChangeSearchEpi = (e) => {
        setSearchEpiBarContent(e.target.value);
    };
    const epiMatch = (epi, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return true;
    };

    useEffect(() => {
        if (searchEpiBarContent !== null && searchEpiBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedEpi(epiList.filter(epi => {
                    return epiMatch(epi, searchEpiBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedEpi(epiList);
        }
    }, [epiList, searchEpiBarContent]);

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalEpiSelected, setModalEpiSelected] = useState({});

    const openAddEpi = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const addEpi = (epi) => {
        setEpiList(epiList.concat(epi));
    };

    const openEditEpi = (epiData) => {
        setModalEpiSelected(epiData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editEpi = (epiUpdated) => {
        setEpiList(epiList.map((epi) => {
            if (epi._id === epiUpdated._id) {
                return epiUpdated;
            }
            return epi;
        }));
    };

    const openSupprimerModal = (epiData) => {
        setModalEpiSelected(epiData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const deleteEpi = (epiSupprimerId) => {
        setEpiList(epiList.filter(epi => {
            return epi._id !== epiSupprimerId;
        }));
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>EPI</h1>
                    <button onClick={() => openAddEpi()} className='btn btn-primary medium-btn'>+ Ajouter EPI</button>
                </div>
                <Breadcrumb
                    links={[
                        { label: "Geston des données", link: "/gestion-de-donnees" },
                        { label: "Epi", link: "/amiante/epi" },
                    ]}
                />
                <div className='filterContainer mgT-m1'>
                    <SearchInput placeholder="Rechercher un epi" searchContent={searchEpiBarContent ? searchEpiBarContent : ""} onChangeSearch={onChangeSearchEpi} />
                </div>
                <TableRender
                    columns={epiColumns((epiData) => openEditEpi(epiData), (epiData) => openSupprimerModal(epiData))}
                    tableData={showedEpi}
                    sortBy={[

                    ]}
                    pageName="gestionsEpi"
                />
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter" &&
                    <AjouterEpiForm progressBarRef={progressBarRef} addEpi={(epi) => addEpi(epi)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "modifier" &&
                    <ModifierEpiForm key={modalEpiSelected._id} progressBarRef={progressBarRef} editEpi={(epi) => editEpi(epi)} epiData={modalEpiSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "supprimer" &&
                    <SupprimerModalForm key={modalEpiSelected._id} destination="supprimerEpi" epiData={modalEpiSelected} deleteEpi={(epiSupprimerId) => deleteEpi(epiSupprimerId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>

        </>
    );
};

export default EpiGestions;;