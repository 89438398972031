import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../utils/axiosInstance';
import customToast from '../../utils/ToastifyHelper';

const ModifierSalarieForm = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onSubmit = data => {
        setButtonDisabled(true);
        const postData = { typeArchivage: data.typeArchivage, commentaire: data.Commentaire };
        console.log(props.salarie.user._id._id);
        axiosInstance.patch(`/salarie_archive/modifier/${props.salarie.user._id._id}`, postData)
            .then(() => {
                customToast.success(props.salarie.user._id.nom + " " + props.salarie.user._id.prenom + " a été modifié avec succès");
                props.modifierSalarie();
                close();
            }).catch((err) => {
                setButtonDisabled(false);
                if (err.response) {
                    // Request made and server responded
                    if (err.response.data.hasOwnProperty("msg")) {
                        customToast.error(err.response.data.msg);
                    }
                    else {
                        customToast.error(err.response.data.errors[0].msg);
                    }
                }
                else if (err.request) {
                    // The request was made but no response was received
                    console.log(err.request);
                }
                else {
                    // Something happened in setting up the request that triggered an err
                    console.log('Error', err.message);
                    customToast.error("Erreur du serveur");
                }
            });
        setButtonDisabled(false);

    };


    return (
        <div className="modal-content modifier-form">
            <h3 className='modal-title'>Modifier {props.salarie.user.nom} {props.salarie.user.prenom}</h3>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
                <label className='ajouter-form-label default-label' htmlFor="typeArchivage">Type d'archivage</label>
                <select className="new-default-input" name="typeArchivage" {...register("typeArchivage", { required: true })}>
                    <option value="Sortie salarié">Sortie salarié</option>
                </select>
                <label className='ajouter-form-label default-label' htmlFor="commentaire">Commentaire</label>
                <textarea className="modifier-form-input default-input textarea-input" name="commentaire" placeholder="Commentaire" {...register("Commentaire")} />
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <input disabled={isButtonDisabled} className="default-button modifier-form-valider" type="submit" value="Modifier" />
                </div>
            </form>
        </div>
    );
};

export default ModifierSalarieForm;