import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as actionCreators from "../../store/actions/exportAction";
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import LoadingBar from 'react-top-loading-bar';
import SearchInputApi from '../../components/ui/SearchInputApi';
import ActualiteItem from './ActualiteItem';
import PaginationTable from '../../components/tables/PaginationTable';

const saveTableOptions = (tableName, tableOptions) => (actionCreators.saveTableOptions(tableName, tableOptions));


const ActualitesAdmin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchBarContent, setSearchBarContent] = useState(null);
    const [actualite, setActualite] = useState({ actualite: [], limit: null, page: null, total: 0, sortProperty: { sort: "order", order: "asc" } });
    const onChangeSearch = (e) => {
        setSearchBarContent(e.target.value);
    };
    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");
    // api progress bar
    const progressBarRef = useRef(null);
    const tableOptions = useSelector((state) => state.tableOptions);

    const fetchData = (page, limit, sort = "") => {
        let fetchUrl = "/actualites?etat=0_1&";
        if (page !== null) {
            fetchUrl += `page=${page}&`;
        }
        else {
            if (tableOptions.hasOwnProperty("ActualiteOptions")) {
                fetchUrl += `page=${tableOptions["ActualiteOptions"].page}&`;
            }
        }
        if (limit !== null) {
            fetchUrl += `limit=${limit}&`;
        }
        else {
            if (tableOptions.hasOwnProperty("ActualiteOptions")) {
                fetchUrl += `limit=${tableOptions["ActualiteOptions"].limit}&`;
            }
        }
        if (searchBarContent === null) {
            if (tableOptions.hasOwnProperty("ActualiteOptions") && tableOptions["ActualiteOptions"].search && tableOptions["ActualiteOptions"].search !== "") {
                setSearchBarContent(tableOptions["ActualiteOptions"].search);
                fetchUrl += `search=${tableOptions["ActualiteOptions"].search}&`;
            }
        }
        else {
            if (searchBarContent !== "") {
                fetchUrl += `search=${searchBarContent}&`;
            }
        }
        if (sort !== "") {
            fetchUrl += `sort=${sort}`;
        }
        else {
            fetchUrl += `sort=${actualite.sortProperty.sort},${actualite.sortProperty.order}`;
        }
        progressBarRef.current.continuousStart();
        axiosInstance.get(fetchUrl).then((result) => {
            progressBarRef.current.complete();
            setActualite(result.data);
            dispatch(saveTableOptions("ActualiteOptions", { limit: result.data.limit, page: result.data.page, secteurs: result.data.secteurs, sortProperty: result.data.sortProperty, search: result.data.search }));
        }).catch((err) => {
            // progressBarRef.current.complete()
            showError(err);
        });

    };

    const fetchDataFromTable = (sortProperties) => {
        let sort = "";
        if (sortProperties.length) {
            sort += `${sortProperties[0].id},${sortProperties[0].desc ? "desc" : "asc"}`;
        }
        fetchData(actualite.page, actualite.limit, sort);
    };

    useEffect(() => {
        fetchDataFromTable([]);
    }, []);



    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Just'actus</h1>
                    <button onClick={() => navigate("/justeau-actus/ajouter-actualite")} className='btn btn-primary medium-btn'>+ Actualité</button>
                </div>
                <div className="filterContainer mgT-s2">
                    <SearchInputApi placeholder="Rechercher une actualité" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearch} search={() => fetchData(1, actualite.limit)} />
                </div>
                <div className='mgB-m1' style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2rem"
                }}>
                    {actualite.actualite.map((actu) => {
                        return (
                            <ActualiteItem key={actu._id} actualite={actu} />
                        );
                    })}
                </div>
                <PaginationTable fetchData={fetchData} page={actualite.page} limit={actualite.limit} total={actualite.total} />
            </div>
        </>
    );
};

export default ActualitesAdmin;