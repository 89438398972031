import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../utils/axiosInstance';
import customToast from '../../utils/ToastifyHelper';
import { format } from 'date-fns';
import * as actionCreators from '../../store/actions/exportAction';
import { showError } from '../../utils/ErrorHelper';
import { formatMateriaux, formatTechniqueModeOperatoire } from '../../utils/GeneralHelper';
import { removeFichierChantier } from '../../store/actions/FichiersAct';
import { fr } from 'date-fns/locale';

const supprimerAbsence = (idAbsence) => (actionCreators.deleteAbsence(idAbsence));
const loadHoraireOnChange = (annee, numero_semaine) => (actionCreators.getReleveHeureListMe(annee, numero_semaine));
const updateReleveHeure = (annee, numeroSemaine, semaineData) => (actionCreators.updateReleveHeureAdmin(annee, numeroSemaine, semaineData));
const onSupprimerBanqueHeure = (idBanque) => (actionCreators.supprimerBanque(idBanque));
const supprimerCategorie = (idCategorie) => (actionCreators.deleteCategorie(idCategorie));
const onLoadPageDocument = () => (actionCreators.getCategorieDocument());
const supprimerChantier = (idChantier) => (actionCreators.deleteChantier(idChantier));
const onDeleteFile = (documentId) => (actionCreators.deleteDocument(documentId));
const onDeleteFileSalarie = (documentId) => (actionCreators.deleteDocumentSalarie(documentId));
const deleteGestionsDocumentsChantiers = (documentId) => (actionCreators.deleteDocumentGestionsChantiers(documentId));
const supprimerMateriaux = (idMateriaux) => (actionCreators.deleteMateriaux(idMateriaux));
const reloadReleveHeureAdmin = (idChef) => (actionCreators.getAdminReleveHeureChef(idChef));
const supprimerType = (payload) => (actionCreators.deleteType(payload));
const supprimerVehicule = (vehiculeImmatriculation) => (actionCreators.deleteVehicule(vehiculeImmatriculation));
const supprimerEquipement = (idEquipement) => (actionCreators.deleteEquipementEpc(idEquipement));
const supprimerControle = (idEquipement) => (actionCreators.deleteControle(idEquipement));
const deleteDocumentEpc = (idDocument) => (actionCreators.deleteDocumentEpc(idDocument));

const SupprimerModalForm = (props) => {
    const dispatch = useDispatch();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };
    const [suppressionMessage, setSuppressionMessage] = useState(null);
    const [titre, setTitre] = useState("");

    useEffect(() => {
        switch (props.destination) {
            case "supprimerSuiviTypeFormation":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> du suivi/formation</p>);
                setTitre("Supprimer un suivi ou formation");
                break;
            case "supprimerAbsence":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de l'absence de {props.absence.utilisateur_absent.nom} {props.absence.utilisateur_absent.prenom} le {format(new Date(props.absence.date_deb_absence), 'dd/MM/yyyy')}</p>);
                setTitre("Supprimer une absence");
                break;
            case "supprimerAbsenceSalarie":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de l'absence de {props.salarieHoraire.nom} {props.salarieHoraire.prenom} le {props.salarieHoraire.date_jour}</p>);
                setTitre("Supprimer une absence");
                break;
            case "supprimerAbsenceSalarieV2":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de l'absence de {props.absence.utilisateur_absent.nom} {props.absence.utilisateur_absent.prenom} le {format(new Date(props.absence.date_deb_absence), "eeee dd MMMM yyyy", { locale: fr })}</p>);
                setTitre("Supprimer une absence");
                break;
            case "supprimerAbsenceSalarieAdmin":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de l'absence de {props.salarieHoraire.nom} {props.salarieHoraire.prenom} le {props.salarieHoraire.date_jour}</p>);
                setTitre("Supprimer une absence");
                break;
            case "supprimerBanqueHeure":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> d'une heure de la banque</p>);
                setTitre("Supprimer une heure de la banque");
                break;
            case "supprimerCategorie":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de la catégorie {props.categorie.libelleCategorie}</p>);
                setTitre("Supprimer une catégorie");
                break;
            case "supprimerChantier":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de {props.chantier._id.libelle_lieu}</p>);
                setTitre("Supprimer un chantier");
                break;
            case "supprimerDocument":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> du document</p>);
                setTitre("Supprimer un document");
                break;
            case "supprimerDocumentGestionsChantiers":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> du document de la liste des documents requis au DOE</p>);
                setTitre("Supprimer un document du DOE");
                break;
            case "supprimerGestionsDocumentDoe":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> du document {props.documentData.baseDocument.titre} de la liste des documents requis au DOE</p>);
                setTitre("Supprimer un document du DOE");
                break;
            case "supprimerHoraireSalarie":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> des horaires de {props.salarieHoraire.nom} {props.salarieHoraire.prenom} effectuées le {props.salarieHoraire.date_jour}</p>);
                setTitre("Supprimer une horaire");
                break;
            case "supprimerHoraireSalarieV2":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> des horaires de {props.travailSalarie.salarie.nom} {props.travailSalarie.salarie.prenom} effectuées le {format(new Date(props.travailSalarie.date_jour), "eeee dd MMMM yyyy", { locale: fr })}</p>);
                setTitre("Supprimer une horaire");
                break;
            case "supprimerHoraireSalarieAdmin":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> des horaires de {props.salarieHoraire.nom} {props.salarieHoraire.prenom} effectuées le {props.salarieHoraire.date_jour}</p>);
                setTitre("Supprimer une horaire");
                break;
            case "supprimerMateriaux":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de {props.materiaux.libelle_mat}</p>);
                setTitre("Supprimer un matériaux");
                break;
            case "supprimerSalarie":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de {props.salarie.user.nom + " " + props.salarie.user.prenom}</p>);
                setTitre("Supprimer un salarié");
                break;
            case "supprimerToutesHeures":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de toutes les heures saisie le {format(props.jourData, "dd/MM/yyyy")}</p>);
                setTitre("Supprimer toutes les heures d'un jour");
                break;
            case "supprimerToutesHeuresV2":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de toutes les heures saisie le {format(new Date(props.jourDate), "dd/MM/yyyy")}</p>);
                setTitre("Supprimer toutes les heures d'un jour");
                break;
            case "supprimerToutesHeuresAdmin":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de toutes les heures saisie le {format(props.jourData, "dd/MM/yyyy")}</p>);
                setTitre("Supprimer toutes les heures d'un jour");
                break;
            case "supprimerToutesHeuresChantiers":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de toutes les heures saisie le {format(props.jourChantier.date_jour, "dd/MM/yyyy")} sur le chantier {props.jourChantier.nomChantier}</p>);
                setTitre("Supprimer toutes les heures d'un chantier");
                break;
            case "supprimerToutesHeuresChantiersV2":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de toutes les heures saisie le {format(new Date(props.jourChantier.date_jour), "dd/MM/yyyy")} sur le chantier {props.jourChantier.nomChantier}</p>);
                setTitre("Supprimer toutes les heures d'un chantier");
                break;
            case "supprimerToutesHeuresChantiersAdmin":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de toutes les heures saisie le {format(props.jourChantier.date_jour, "dd/MM/yyyy")} sur le chantier {props.jourChantier.nomChantier}</p>);
                setTitre("Supprimer toutes les heures d'un chantier");
                break;
            case "supprimerTypeDocuments":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> du type {props.categorie.typeDocument.libelleType} de la categorie {props.categorie.categorie.libelleCategorie}</p>);
                setTitre("Supprimer un type de document");
                break;
            case "supprimerVehicule":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> du véhicule {props.vehiculeData.immatriculation}</p>);
                setTitre("Supprimer un véhicule");
                break;
            case "supprimerGestionsEquipements":
                setSuppressionMessage(<p className='modal-confirmation-text' >Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression </span>{['a', 'e', 'i', 'o', 'u', 'y'].includes(props.materielData.nom[0].toLowerCase()) ? 'd\'' : 'de '}{props.materielData.nom} / N°{props.materielData.numero_de_serie}</p>);
                setTitre("Supprimer un équipement EPC");
                break;
            case "supprimerProcessus":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> du processus</p>);
                setTitre("Supprimer un processus");
                break;
            case "supprimerAnalyseProcessus":
                setSuppressionMessage(<p className="modal-confirmation-text">Appuyer sur valider pour confirmer la <span className="suppression-mot">suppression</span> de l'analyse du processus effectuée le {format(new Date(props.analyse.analyse.dateValidation), "dd/MM/yyyy")} sur le chantier {props.analyse.analyse.validationChantier.codeChantier}</p>);
                setTitre("Supprimer l'analyse d'un processus");
                break;
            case "supprimerControle":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du controle</p>);
                setTitre("Supprimer un controle");
                break;
            case "supprimerDocumentAnalyseProcessus":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du document d'analyse</p>);
                setTitre("Supprimer un document d'analyse");
            case "supprimerVacationChantier":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du relevé d'exposition</p>);
                setTitre("Supprimer le relevé d'exposition");
                break;
            case "supprimerDocumentChantier":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du document du DOE</p>);
                setTitre("Supprimer un document du DOE");
                break;
            case "supprimerDocumentSalarie":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du document salarié</p>);
                setTitre("Supprimer un document salarié");
                break;
            case "supprimerFormationSuivi":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de {props.formationData.nomFormation} des formations</p>);
                setTitre("Supprimer une formation");
                break;
            case "supprimerEntretienVehicule":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de {props.entretienData.nomEntretien} des entretiens /suiviees</p>);
                setTitre("Supprimer un entretien/suivi");
                break;
            case "SupprimerFormationSalarie":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de la formation {props.formationData.nomFormation} effectué le {format(new Date(props.formationData.formationsValidees.dateFormation), "dd/MM/yyyy")}</p>);
                setTitre("Supprimer une formation");
                break;
            case "supprimerDocumentMateriel":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du document</p>);
                setTitre("Supprimer un document");
                break;
            case "archiverChantier":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour <span className='suppression-mot'>archiver</span> le chantier ({props.modalChantierSelected.codeChantier}) {props.modalChantierSelected._id.libelle_lieu}</p>);
                setTitre("Archiver le chantier");
                break;
            case "archiverChantierAmiante":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour <span className='suppression-mot'>archiver</span> le chantier ({props.modalChantierSelected.codeChantier}) {props.modalChantierSelected._id.libelle_lieu}</p>);
                setTitre("Archiver le chantier");
                break;
            case "supprimerProcessusDuChantier":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du processus {props.modalProcessusSelected.codeProcessus} du chantier</p>);
                setTitre("Supprimer le processus du chantier");
                break;
            case "supprimerDocumentAmiante":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du document</p>);
                setTitre("Supprimer un document");
                break;
            case "supprimerActualite":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de l'article</p>);
                setTitre("Supprimer un article");
                break;
            case "archiverActualite":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer l'<span className='suppression-mot'>archivage</span> de l'article</p>);
                setTitre("Archiver un article");
                break;
            case "supprimerEpi":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de l'equipement {props.epiData.libelleEquipement}</p>);
                setTitre("Supprimer un article");
                break;
            case "supprimerDocumentVehicule":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du véhicule {props.vehicule.immatriculation}</p>);
                setTitre("Supprimer un article");
                break;
            case "supprimerSite":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du site {props.modalSiteSelected._id.libelle_lieu}</p>);
                setTitre("Supprimer un site");
                break;
            case "supprimerDocumentProcessus":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du document {props.documentData.titre}</p>);
                setTitre("Supprimer un document processus");
            case "supprimerZoneChantierAmiante":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de la zone</p>);
                setTitre(`Supprimer la zone ${props.zoneSelected.nomZoneAmiante}`);
                break;
            case "supprimerIntervenant":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de {props.intervenantSelected.libelleIntervenant} des intervenants</p>);
                setTitre(`Supprimer un intervenant`);
                break;
            case "supprimerFichiersChantierEspaceDocumentaire":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du fichier {props.fichiersSupprimer.nomFichier}</p>);
                setTitre(`Supprimer un fichier de l'espace documentaire`);
                break;
            case "supprimerControleEPC":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du controle du {new Date(props.controleData).toLocaleDateString("fr-FR")}</p>);
                setTitre("Supprimer un controle");
                break;
            case "supprimerEntretienEquipement":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de l'entretien {props.entretienData.nomEntretien}</p>);
                setTitre("Supprimer un entretien");
                break;
            case "SupprimerEntretienValidFromEquipement":
                console.log(props.equipement);
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> de l'entretien du {new Date(props.equipement.date).toLocaleDateString("fr-FR")}</p>);
                setTitre("Supprimer un entretien");
                break;
            case "supprimerCodeFamilleOptions":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du code famille {props.codeFamilleSelected.value}: {props.codeFamilleSelected.label}</p>);
                setTitre("Supprimer un code famille");
                break;
            case "supprimerCodeCedOptions":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du code CED {props.codeCedSelected.value}: {props.codeCedSelected.label}</p>);
                setTitre("Supprimer un code CED");
                break;
            case "supprimerConditionnementOptions":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du conditionnement du déchet {props.conditionnementSelected.value}</p>);
                setTitre("Supprimer un type de conditionnement");
                break;
            case "supprimerGestionDechet":
                setSuppressionMessage(<p className='modal-confirmation-text'>Appuyer sur valider pour confirmer la <span className='suppression-mot'>suppression</span> du déchet {props.dechetSelected.libelleDechet}</p>);
                setTitre("Supprimer un code déchet");
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onValiderSuppression = () => {
        let postData;
        setButtonDisabled(true);
        switch (props.destination) {
            case "supprimerSuiviTypeFormation":
                axiosInstance.delete(`/gestionTypeSuivie/${props.suiviFormationId}`)
                    .then((result) => {
                        props.supprimerSuiviFormationSalarie(props.suiviFormationId);
                        customToast.success("Suivi/formation supprimer");
                        close();
                    }).catch((err) => {
                        showError(err);
                        setButtonDisabled(false);
                    });
                break;
            case "supprimerAbsence":
                axiosInstance.delete('/absences/' + props.absence.utilisateur_absent._id + "&" + props.absence.date_deb_absence)
                    .then((result) => {
                        customToast.success("L'absence a été supprimée avec succès");
                        dispatch(supprimerAbsence(props.absence._id));
                        close();
                    }).catch((err) => {
                        if (err.response) {
                            // Request made and server responded
                            if (err.response.data.hasOwnProperty("msg")) {
                                customToast.error(err.response.data.msg);
                            }
                            else {
                                customToast.error(err.response.data.errors[0].msg);
                            }
                        }
                        else if (err.request) {
                            // The request was made but no response was received
                            console.log(err.request);
                        }
                        else {
                            // Something happened in setting up the request that triggered an err
                            console.log('Error', err.message);
                            customToast.error("Erreur du serveur");
                        }
                        setButtonDisabled(false);
                    });
                break;
            case "supprimerAbsenceSalarie":
                axiosInstance.patch('/horaires_semaines/modifier_jour/me&' + props.currentWeekState.year.yearNumber + "&" + props.currentWeekState.weekNumber, props.salarieHoraire.postData)
                    .then((result) => {
                        customToast.success("L'absence a été supprimée avec succès");
                        dispatch(loadHoraireOnChange(props.currentWeekState.year.yearNumber, props.currentWeekState.weekNumber));
                        close();
                    }).catch((err) => {
                        showError(err);
                        setButtonDisabled(false);
                    });
                break;
            case "supprimerAbsenceSalarieV2":
                if (props.saisiePar) {
                    axiosInstance.delete(`/releve-heure-v2/absenceSalarie/${props.absence._id}`).then((result) => {
                        customToast.success("L'absence a été supprimée avec succès");
                        props.supprimerAbsence(props.absence);
                        close();
                    }).catch((err) => {
                        showError(err);
                        setButtonDisabled(false);
                    });
                }
                else {
                    axiosInstance.delete(`/releve-heure-v2/absenceSalarie/me/${props.absence._id}`).then((result) => {
                        customToast.success("L'absence a été supprimée avec succès");
                        props.supprimerAbsence(props.absence);
                        close();
                    }).catch((err) => {
                        showError(err);
                        setButtonDisabled(false);
                    });
                }
                break;
            case "supprimerAbsenceSalarieAdmin":
                axiosInstance.patch('/horaires_semaines/modifier_jour/' + props.idChef + '&' + props.annee + "&" + props.numeroSemaine, props.salarieHoraire.postData)
                    .then((result) => {
                        customToast.success("L'absence a été supprimée avec succès");
                        dispatch(updateReleveHeure(props.annee, props.numeroSemaine, result.data));
                        close();
                    }).catch((err) => {
                        if (err.response) {
                            // Request made and server responded
                            if (err.response.data.hasOwnProperty("msg")) {
                                customToast.error(err.response.data.msg);
                            }
                            else {
                                customToast.error(err.response.data.errors[0].msg);
                            }
                        }
                        else if (err.request) {
                            // The request was made but no response was received
                            console.log(err.request);
                        }
                        else {
                            // Something happened in setting up the request that triggered an err
                            console.log('Error', err.message);
                            customToast.error("Erreur du serveur");
                        }
                        setButtonDisabled(false);
                    });
                break;
            case "supprimerBanqueHeure":
                axiosInstance.delete(`/banque_heure/${props.banqueHeureData._id}`)
                    .then((result) => {
                        customToast.success("Heure supprimée de la banque avec succès");
                        dispatch(onSupprimerBanqueHeure(props.banqueHeureData._id));
                        close();
                    }).catch((err) => {
                        showError(err);
                        setButtonDisabled(false);
                    });
                break;
            case "supprimerCategorie":
                axiosInstance.delete('/categories/' + props.categorie._id)
                    .then((result) => {
                        if (props.categorie.typeDocuments.length > 0) {
                            dispatch(onLoadPageDocument());
                        }
                        else {
                            dispatch(supprimerCategorie(props.categorie._id));
                        }
                        customToast.success("La catégorie a été supprimée avec succès");
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerChantier":
                axiosInstance.delete('/chantiers/' + props.chantier._id._id)
                    .then((result) => {
                        customToast.success("Le chantier a été supprimé avec succès");
                        dispatch(supprimerChantier(props.chantier._id._id));
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerDocument":
                if (props.documentSaveRoute === "documentsSalarie") {
                    axiosInstance.delete(`uploads/supprimerDocumentUtilisateurs/${props.idSalarie}&${props.document}`).then((result) => {
                        dispatch(onDeleteFileSalarie(props.document));
                        customToast.success("Le document à été supprimé avec succès");
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                else if (props.documentSaveRoute === "documentsEPC") {
                    axiosInstance.delete(`uploads/documentEPC/${props.document}`).then((result) => {
                        dispatch(deleteDocumentEpc(props.document));
                        customToast.success("Le document à été supprimé avec succès");
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                } else {
                    axiosInstance.delete(`uploads/documentsPublics/${props.document}`)
                        .then((result) => {
                            dispatch(onDeleteFile(props.document));
                            customToast.success("Le document à été supprimé avec succès");
                            close();
                        }).catch((err) => {
                            setButtonDisabled(false);
                            showError(err);
                        });
                }
                break;
            case "supprimerDocumentGestionsChantiers":
                axiosInstance.delete(`/uploads/documentsGestionsChantiers/${props.documentData}`)
                    .then((result) => {
                        customToast.success("Le document à été supprimé avec succès");
                        dispatch(deleteGestionsDocumentsChantiers(props.documentData));
                        setButtonDisabled(false);
                        console.log(result.data);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerGestionsDocumentDoe":
                axiosInstance.delete(`/uploads/documentsGestionsChantiers/${props.documentData.baseDocument._id}`)
                    .then((result) => {
                        customToast.success("Le document à été supprimé avec succès");
                        props.supprimerDocumentDoe(props.documentData.baseDocument._id);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerHoraireSalarie":
                axiosInstance.patch('/horaires_semaines/modifier_jour/me&' + props.currentWeekState.year.yearNumber + "&" + props.currentWeekState.weekNumber, props.salarieHoraire.postData)
                    .then((result) => {
                        customToast.success(`Les heures de ${props.salarieHoraire.nom} ${props.salarieHoraire.prenom} effectuées le ${props.salarieHoraire.date_jour} ont été supprimées avec succès`);
                        dispatch(loadHoraireOnChange(props.currentWeekState.year.yearNumber, props.currentWeekState.weekNumber));
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerHoraireSalarieV2":
                if (props.saisiePar) {
                    axiosInstance.delete(`/releve-heure-v2/travailSalarie/${props.travailSalarie._id}`).then((result) => {
                        customToast.success(`Les heures de ${props.travailSalarie.salarie.nom} ${props.travailSalarie.salarie.prenom} effectuées le ${format(new Date(props.travailSalarie.date_jour), "eeee dd MMMM yyyy", { locale: fr })} ont été supprimées avec succès`);
                        props.deleteHeure(props.travailSalarie);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                else {
                    axiosInstance.delete(`/releve-heure-v2/travailSalarie/me/${props.travailSalarie._id}`).then((result) => {
                        customToast.success(`Les heures de ${props.travailSalarie.salarie.nom} ${props.travailSalarie.salarie.prenom} effectuées le ${format(new Date(props.travailSalarie.date_jour), "eeee dd MMMM yyyy", { locale: fr })} ont été supprimées avec succès`);
                        props.deleteHeure(props.travailSalarie);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                break;
            case "supprimerHoraireSalarieAdmin":
                axiosInstance.patch('/horaires_semaines/modifier_jour/' + props.idChef + '&' + props.annee + "&" + props.numeroSemaine, props.salarieHoraire.postData)
                    .then((result) => {
                        customToast.success(`Les heures de ${props.salarieHoraire.nom} ${props.salarieHoraire.prenom} effectuées le ${props.salarieHoraire.date_jour} ont été supprimées avec succès`);
                        dispatch(updateReleveHeure(props.annee, props.numeroSemaine, result.data));
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerMateriaux":
                axiosInstance.delete('/materiaux/' + props.materiaux._id)
                    .then((result) => {
                        customToast.success(`Le matériaux ${props.materiaux.libelle_mat} a été supprimé avec succès`);
                        dispatch(supprimerMateriaux(props.materiaux._id));
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerSalarie":
                const deleteUrl = "/utilisateurs/" + props.salarie.user._id;
                axiosInstance.delete(deleteUrl)
                    .then(() => {
                        customToast.success(`Le salarié ${props.salarie.user.nom + " " + props.salarie.user.prenom} a été supprimé avec succès`);
                        props.supprimerSalarie();
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerToutesHeures":
                postData = { date_jour: format(props.jourData, "yyyy-MM-dd") };
                axiosInstance.patch(`/horaires_semaines/supprimerJour/me&${props.annee}&${props.numeroSemaine}`, postData)
                    .then((result) => {
                        dispatch(loadHoraireOnChange(props.annee, props.numeroSemaine));
                        customToast.success("Les heures ont été supprimées avec succès");
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerToutesHeuresV2":
                if (props.saisiePar) {
                    axiosInstance.delete(`/releve-heure-v2/jourTravailSalarie?date_jour=${props.jourDate}`, { saisiePar: props.saisiePar._id }).then((result) => {
                        customToast.success("Les heures ont été supprimées avec succès");
                        props.supprimerTouteHeures(props.jourDate);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                else {
                    axiosInstance.delete(`/releve-heure-v2/jourTravailSalarie/me?date_jour=${props.jourDate}`).then((result) => {
                        customToast.success("Les heures ont été supprimées avec succès");
                        props.supprimerTouteHeures(props.jourDate);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                break;
            case "supprimerToutesHeuresAdmin":
                postData = { date_jour: format(props.jourData, "yyyy-MM-dd") };
                axiosInstance.patch(`/horaires_semaines/supprimerJour/${props.idChef}&${props.annee}&${props.numeroSemaine}`, postData)
                    .then((result) => {
                        dispatch(reloadReleveHeureAdmin(props.idChef));
                        customToast.success("Les heures ont été supprimées avec succès");
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerToutesHeuresChantiers":
                postData = { date_jour: format(props.jourChantier.date_jour, "yyyy-MM-dd"), chantier: props.jourChantier.idChantier };
                axiosInstance.patch(`/horaires_semaines/supprimerChantierJour/me&${props.annee}&${props.numeroSemaine}`, postData)
                    .then((result) => {
                        dispatch(loadHoraireOnChange(props.annee, props.numeroSemaine));
                        customToast.success("Les heures ont été supprimées avec succès");
                        close();

                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerToutesHeuresChantiersV2":
                if (props.saisiePar) {
                    axiosInstance.delete(`/releve-heure-v2/jourTravailSalarie?date_jour=${props.jourChantier.date_jour}&chantier=${props.jourChantier.chantierId}`, { saisiePar: props.saisiePar._id }).then((result) => {
                        customToast.success("Les heures ont été supprimées avec succès");
                        props.supprimerTouteHeuresChantier(props.jourChantier);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                else {
                    axiosInstance.delete(`/releve-heure-v2/jourTravailSalarie/me?date_jour=${props.jourChantier.date_jour}&chantier=${props.jourChantier.chantierId}`).then((result) => {
                        customToast.success("Les heures ont été supprimées avec succès");
                        props.supprimerTouteHeuresChantier(props.jourChantier);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                break;
            case "supprimerToutesHeuresChantiersAdmin":
                postData = { date_jour: format(props.jourChantier.date_jour, "yyyy-MM-dd"), chantier: props.jourChantier.idChantier };
                axiosInstance.patch(`/horaires_semaines/supprimerChantierJour/${props.idChef}&${props.annee}&${props.numeroSemaine}`, postData)
                    .then((result) => {
                        dispatch(updateReleveHeure(props.annee, props.numeroSemaine, result.data));
                        customToast.success("Les heures ont été supprimées avec succès");
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerTypeDocuments":
                axiosInstance.patch(`/categories/supprimerType/${props.categorie.categorie._id}`, { idTypeDocument: props.categorie.typeDocument._id })
                    .then(() => {
                        axiosInstance.delete('/typeDocuments/' + props.categorie.typeDocument._id)
                            .then(() => {
                                customToast.success("Type de document supprimé avec succès");
                                dispatch(supprimerType({ categorieUpdated: props.categorie.categorie, idTypeSupprimer: props.categorie.typeDocument._id }));
                                close();
                            }).catch((error) => {
                                setButtonDisabled(false);
                                showError(error);
                            });
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerVehicule":
                axiosInstance.delete(`/vehicules/${props.vehiculeData._id}`)
                    .then((result) => {
                        customToast.success(`Le véhicule ${props.vehiculeData.immatriculation} a été supprimé avec succès`);
                        props.supprimerVehicule(props.vehiculeData._id);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerGestionsEquipements":
                // axiosInstance.delete('/equipementEPC/' + props.equipementEpc._id)
                // .then((result) => {

                //     if(!!props.equipementEpc){

                //         axiosInstance.delete(`uploads/documentEPC/${props.equipementEpc.IDdocument}`).then((result) => {
                //             dispatch(deleteDocumentEpc(props.equipementEpc.IDdocument))
                //         })
                //     }
                //     customToast.success("L'équipement à été supprimé avec succès")
                //     dispatch(supprimerEquipement(props.equipementEpc._id));
                //     close();
                //     setButtonDisabled(false)
                // }).catch((err) => {
                //     //console.log(props.equipementEpc.gestion_suivi)
                //     setButtonDisabled(false)
                //     showError(err)
                // });
                axiosInstance.delete(`/equipementEPC/${props.materielData._id}`).then((result) => {
                    customToast.success("L'équipement a été supprimer");
                    props.deleteMateriel(props.materielData._id);
                    close();
                    setButtonDisabled(false);
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerProcessus":
                if (!props.processus.validationProcessus.length) {
                    axiosInstance.delete(`/processusAmiante/${props.processus._id}`)
                        .then((result) => {
                            customToast.success("Le processus à été supprimé avec succès");
                            // dispatch(supprimerEquipement(props.equipementEpc._id));
                            dispatch(actionCreators.supprimerProcessus(props.processus._id));
                            setButtonDisabled(false);
                            close();
                        }).catch((err) => {
                            setButtonDisabled(false);
                            showError(err);
                        });
                }
                else {
                    setButtonDisabled(false);
                    close();
                    customToast.error("Vous ne pouvez pas supprimer ce processus car il possède des analyses");

                }
                break;
            case "supprimerAnalyseProcessus":
                setButtonDisabled(false);
                postData = { typeValidationAnalyse: props.analyse.typeValidation };
                if (postData.typeValidationAnalyse === "controle") {
                    postData.validationControleId = props.analyse.analyse._id;
                }
                axiosInstance.patch(`/validationProcessus/supprimerAnalyse/${props.analyse.analyseId}`, postData)
                    .then((result) => {
                        // props.deleteAnalyse(props.analyse.analyseId, props.analyse.typeValidation);
                        if (props.analyse.analyse.document !== null) {
                            axiosInstance.delete(`/uploads/${postData.typeValidationAnalyse === "controle" ? props.analyse.analyse.document._id : props.analyse.analyse.document}`)
                                .then(() => {
                                    if (result.data.validationDeleted) {
                                        props.deleteAllAnalyse(props.analyse.analyseId);
                                    }
                                    else {
                                        props.deleteOneAnalyse(props.analyse.analyseId, props.analyse.typeValidation, props.analyse.analyse._id);
                                    }
                                    customToast.success(result.data.msg);
                                    setButtonDisabled(true);
                                    close();
                                }).catch((error) => {
                                    setButtonDisabled(false);
                                    showError(error);
                                });
                        }
                        else {
                            if (result.data.validationDeleted) {
                                props.deleteAllAnalyse(props.analyse.analyseId);
                            }
                            else {
                                props.deleteOneAnalyse(props.analyse.analyseId, props.analyse.typeValidation, props.analyse.analyse._id);
                            }
                            customToast.success("L'analyse a été supprimer");
                            setButtonDisabled(true);
                            close();
                        }
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerControle":
                axiosInstance.patch('/equipementEPC/deleteControles/' + props.controleId, { "idHistorique": props.idHistorique })
                    .then((result) => {
                        customToast.success("Le controle à été supprimé avec succès");
                        dispatch(supprimerControle(props.idHistorique));
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerDocumentAnalyseProcessus":
                setButtonDisabled(false);
                postData = { typeValidationAnalyse: props.analyse.typeValidation };
                if (postData.typeValidationAnalyse === "controle") {
                    postData.validationControleId = props.analyse.analyse._id;
                }
                axiosInstance.patch(`/validationProcessus/supprimerDocumentAnalyse/${props.analyse.analyseId}`, postData).then((result) => {
                    axiosInstance.delete(`/uploads/${props.analyse.document}`).then(() => {
                        props.deleteDocumentAnalyse(props.analyse.analyseId, props.analyse.typeValidation, props.analyse.analyse._id);
                        customToast.success("Le document à été supprimé avec succès");
                        // doc is not deleted from list
                        close();
                    }).catch((error) => {
                        setButtonDisabled(false);
                        showError(error);
                    });
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerVacationChantier":
                setButtonDisabled(false);

                axiosInstance.delete(`/vacationsAmiante/${props.vacation}`).then(() => {
                    props.deleteReleveExposition(props.vacation);
                    customToast.success("Le relevé de vacation a été supprimé avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerDocumentChantier":
                setButtonDisabled(false);
                axiosInstance.delete(`/uploads/supprimerDocumentChantier/${props.idChantier}&${props.documentData}`).then((result) => {
                    props.onSuppressionDocument(props.documentData);
                    customToast.success("Le document a été supprimer avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerDocumentSalarie":
                axiosInstance.delete(`/uploads/supprimerDocumentUtilisateurs/${props.salarie._id}&${props.documentData._id}`)
                    .then((result) => {
                        setButtonDisabled(false);
                        props.deleteDocumentSalarie(props.documentData._id);
                        close();
                        customToast.success("Le document a été supprimer avec succès");
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerFormationSuivi":
                axiosInstance.delete(`/formationsSuiviSalarie/${props.formationData._id}`).then((result) => {
                    setButtonDisabled(false);
                    props.deleteFormationSuivi(props.formationData._id);
                    close();
                    customToast.success(result.data.msg);
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerEntretienVehicule":
                axiosInstance.delete(`/entretienSuiviVehicule/${props.entretienData._id}`).then((result) => {
                    setButtonDisabled(false);
                    props.deleteEntretienSuivi(props.entretienData._id);
                    close();
                    customToast.success(result.data.msg);
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "SupprimerFormationSalarie":
                if (props.formationData.formationsValidees.hasOwnProperty("document")) {
                    // supprimer le document
                    axiosInstance.delete(`/uploads/${props.formationData.formationsValidees.document._id}`).then((res) => {
                        axiosInstance.delete(`/salarieFormations/${props.salarie._id}/${props.formationData.formationId}/${props.formationData.formationsValidees._id}`).then((result) => {
                            setButtonDisabled(false);
                            // props.deleteFormation({formationId: props.formationData.formationId, formationsValideesId: props.formationData.formationsValidees._id})
                            props.deleteFormation(result.data);
                            close();
                            customToast.success("La formation a été supprimer avec succcès");
                        }).catch((err) => {
                            setButtonDisabled(false);
                            showError(err);
                        });
                    }).catch((error) => {
                        showError(error);
                    });
                }
                else {
                    axiosInstance.delete(`/salarieFormations/${props.salarie._id}/${props.formationData.formationId}/${props.formationData.formationsValidees._id}`).then((result) => {
                        setButtonDisabled(false);
                        // props.deleteFormation({formationId: props.formationData.formationId, formationsValideesId: props.formationData.formationsValidees._id})
                        props.deleteFormation(result.data);
                        close();
                        customToast.success("La formation a été supprimer avec succcès");
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                break;
            case "supprimerDocumentMateriel":
                axiosInstance.delete(`/uploads/supprimerDocumentMateriel/${props.materielId}&${props.documentData._id}`)
                    .then((result) => {
                        setButtonDisabled(false);
                        props.deleteDocumentMateriel(props.documentData._id);
                        close();
                        customToast.success("Le document a été supprimer avec succès");
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "archiverChantier":
                axiosInstance.patch(`/chantiers/archiverChantier/${props.modalChantierSelected._id._id}`)
                    .then((result) => {
                        customToast.success("Le chantier a été archivé avec succès");
                        props.archiverChantier(props.modalChantierSelected._id._id);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "archiverChantierAmiante":
                axiosInstance.patch(`/chantiers/archiverChantier/${props.modalChantierSelected._id._id}`)
                    .then((result) => {
                        customToast.success("Le chantier a été archivé avec succès");
                        props.archiverChantier(props.modalChantierSelected._id._id);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                break;
            case "supprimerProcessusDuChantier":
                axiosInstance.patch(`/chantiers/supprimerProcessus/${props.chantier._id._id}`, { processusId: props.modalProcessusSelected._id }).then((result) => {
                    props.setChantier(result.data);
                    axiosInstance.get("/processusAmiante").then((res) => {
                        let dataSelectFormat = [];
                        for (const processus of res.data) {
                            dataSelectFormat.push({
                                value: processus._id, label: `${processus.codeProcessus} - ${formatMateriaux(processus.materiaux)} - Niveau ${processus.niveauEmpoussierement} - ${formatTechniqueModeOperatoire(processus.techniqueModesOperatoires)} - ${processus.protectionCollective}`,
                                isDisabled: result.data.processus.findIndex(process => process._id === processus._id) >= 0
                            });
                        }
                        props.setAllProcessus(dataSelectFormat);
                        close();
                        customToast.success("Le processus a été supprimer du chantier avec succès");
                    }).catch((error) => {
                        setButtonDisabled(false);
                        showError(error);
                    });
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerDocumentAmiante":
                axiosInstance.delete(`/uploads/documentsAmiante/${props.documentData}`).then((result) => {
                    setButtonDisabled(false);
                    props.supprimerDocumentAmiante(props.documentData);
                    close();
                    customToast.success("Le document a été supprimer avec succès");
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerDocumentVehicule":
                props.progressBarRef.current.continuousStart();
                axiosInstance.delete(`/uploads/supprimerDocumentVehicule/${props.vehicule._id}&${props.documentData._id}`).then((result) => {
                    props.progressBarRef.current.complete();
                    close();
                    props.supprimerDocument(props.documentData._id);
                    customToast.success("Le document a été supprimer avec succès");
                }).catch((err) => {
                    props.progressBarRef.current.complete();
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerActualite":
                if (!props.actualiteData.pictures.hasOwnProperty("articlePicture") || props.actualiteData.pictures.articlePicture === null) {
                    axiosInstance.delete(`/actualites/${props.actualiteData._id}`).then(() => {
                        setButtonDisabled(false);
                        close();
                        customToast.success("L'article a été supprimer avec succès");
                        props.deleteArticle();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
                }
                else {
                    axiosInstance.delete(`/uploads/${props.actualiteData.pictures.articlePicture._id}`).then(() => {
                        axiosInstance.delete(`/actualites/${props.actualiteData._id}`).then(() => {
                            setButtonDisabled(false);
                            close();
                            customToast.success("L'article a été supprimer avec succès");
                            props.deleteArticle();
                        }).catch((err) => {
                            setButtonDisabled(false);
                            showError(err);
                        });
                    }).catch((error) => {
                        setButtonDisabled(false);
                        showError(error);
                    });
                }
                break;
            case "archiverActualite":
                axiosInstance.patch(`/actualites/changeVisibility/${props.actualiteData._id}`, { etat: -1 }).then((result) => {
                    setButtonDisabled(false);
                    close();
                    customToast.success("L'article a été archiver avec succès");
                    props.archiverArticle();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerEpi":
                axiosInstance.delete(`/equipement_amiante/${props.epiData._id}`).then((result) => {
                    setButtonDisabled(false);
                    props.deleteEpi(props.epiData._id);
                    close();
                    customToast.success("L'équipement a été supprimer avec succès");
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerSite":
                axiosInstance.delete(`/site/${props.modalSiteSelected._id._id}`).then((result) => {
                    setButtonDisabled(false);
                    props.supprimerSite(props.modalSiteSelected._id._id);
                    close();
                    customToast.success("Le site a été supprimer avec succès");
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerDocumentProcessus":
                axiosInstance.delete(`/uploads/documentsProcessus/${props.documentData._id}&${props.processusId}`).then((result) => {
                    setButtonDisabled(false);
                    props.deleteDocumentProcessus(props.documentData._id);
                    close();
                    customToast.success("Le document a été supprimer avec succès");
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerZoneChantierAmiante":
                axiosInstance.delete(`/zoneAmiante/${props.zoneSelected._id}`).then((result) => {
                    setButtonDisabled(false);
                    props.supprimerZoneAmiante(props.zoneSelected._id);
                    customToast.success("La zone a été supprimer avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerIntervenant":
                axiosInstance.delete(`/intervenantsTiers/${props.intervenantSelected._id}`).then((result) => {
                    setButtonDisabled(false);
                    props.supprimerIntervenantsTiers(props.intervenantSelected._id);
                    customToast.success("L'intervenant a été supprimer avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerFichiersChantierEspaceDocumentaire":
                axiosInstance.delete(`/fichiersChantiers/espaceDocumentaire/${props.fichiersSupprimer._id._id}`).then((result) => {
                    setButtonDisabled(false);
                    dispatch(removeFichierChantier(props.fichiersSupprimer._id._id));
                    customToast.success("Le fichier a été supprimer avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerControleEPC":
                axiosInstance.patch(`controleMateriel/deleteControle/` + props.materielId, { toDelete: props.controleData }).then((result) => {
                    setButtonDisabled(false);
                    props.deleteControleMateriel(props.controleData);
                    close();
                    customToast.success("Le controle a été supprimé avec succès");
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;

            case "supprimerEntretienEquipement":
                axiosInstance.delete(`/entretienEquipement/deleteEntretien/` + props.entretienData._id).then((result) => {
                    setButtonDisabled(false);
                    props.deleteEntretien(props.entretienData._id);
                    close();
                    customToast.success("L'entretien a été supprimé avec succès");
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "SupprimerEntretienValidFromEquipement":
                //delete un des entretiens 
                // axiosInstance.delete(`/uploads/deleteDocEntretien/${props.idDocument}&${props.entretienId}&${props.validite}`).then(
                // ).catch((err) => {
                //     setButtonDisabled(false)
                //     showError(err)
                // });

                axiosInstance.delete(`/entretienEquipement/deleteEntretienEquipement/${props.entretienId}&${props.validite}`,).then((result) => {
                    setButtonDisabled(false);
                    props.deleteEntretien(result);

                    close();
                    customToast.success("L'entretien a été supprimé avec succès");
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerCodeFamilleOptions":
                axiosInstance.delete(`/formOptions/options/codeFamilleDechet/${props.codeFamilleSelected.value}`).then(() => {
                    setButtonDisabled(false);
                    props.supprimerCodeFamille(props.codeFamilleSelected);
                    customToast.success("Le code famille a été supprimer avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerCodeCedOptions":
                axiosInstance.delete(`/formOptions/options/codeCedDechet/${props.codeCedSelected.value}`).then(() => {
                    setButtonDisabled(false);
                    props.supprimerCodeCed(props.codeCedSelected);
                    customToast.success("Le code CED a été supprimer avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerConditionnementOptions":
                axiosInstance.delete(`/formOptions/options/conditionnementDechet/${props.conditionnementSelected.value}`).then(() => {
                    setButtonDisabled(false);
                    props.supprimerConditionnement(props.conditionnementSelected);
                    customToast.success("Le conditionnement a été supprimer avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            case "supprimerGestionDechet":
                axiosInstance.delete(`/dechets/${props.dechetSelected._id}`).then(() => {
                    setButtonDisabled(false);
                    props.supprimerDechet(props.dechetSelected);
                    customToast.success("Le déchet a été supprimer avec succès");
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
                break;
            default:
                break;
        }
    };

    return (
        <div className="modal-content supprimerForm" style={{ display: "flex", flexDirection: "column" }}>
            <h1 className='modal-title'>{titre}</h1>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <hr />
            {suppressionMessage}
            <div className="button-container" style={{ flex: 1, alignItems: "flex-end" }}>
                <button disabled={isButtonDisabled} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => close()}>Annuler</button>
                <button disabled={isButtonDisabled} className='btn btn-primary fw medium-btn' onClick={() => onValiderSuppression()}>Valider</button>
            </div>
        </div>
    );
};

export default SupprimerModalForm;