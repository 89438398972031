import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../components/ui/Breadcrumb';
import SearchInput from '../../components/ui/SearchInput';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import TableRender from '../../components/tables/TableRender';
import { suiviMaterielColumn } from '../../components/tables/TableColumns';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import Modal from '../../components/Modal';

import AjouterEquipementsEpcForm from '../../components/forms/AmianteForms/AjouterEquipementEpcForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import ModifierEquipementEpcForm from '../../components/forms/ModifierEquipementEpcForm';
import { useNavigate } from 'react-router-dom';

const SuiviMateriel = () => {
    const progressBarRef = useRef(null);
    const navigate = useNavigate();
    const [materiels, setMateriels] = useState([]);
    const [showedMateriels, setShowedMateriels] = useState([]);

    // loadData
    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/equipementEPC").then((result) => {
            progressBarRef.current.complete();
            setMateriels(result.data.equipementEPC);
            console.log(result.data);
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    }, []);

    //document search bar
    const [searchMaterielBarContent, setSearchMaterielBarContent] = useState(null);
    const suiviMaterielMatch = (materiel, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        console.log(getInsensitiveWorld(materiel.marque).includes(insensitiveSearch));
        return getInsensitiveWorld(materiel.nom).includes(insensitiveSearch) ||
            getInsensitiveWorld(materiel.marque).includes(insensitiveSearch) ||
            getInsensitiveWorld(materiel.numero_de_serie).includes(insensitiveSearch) ||
            getInsensitiveWorld(materiel.ref_interne).includes(insensitiveSearch);
    };

    const onChangeSearchMateriel = (e) => {
        setSearchMaterielBarContent(e.target.value);
    };

    // filter
    const [filterMateriel, setFilterMateriel] = useState("All");

    useEffect(() => {
        if (searchMaterielBarContent !== null && searchMaterielBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedMateriels(materiels.filter(materiel => {
                    return suiviMaterielMatch(materiel, searchMaterielBarContent) && (materiel.nom === filterMateriel || filterMateriel === "All");
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedMateriels(materiels.filter(materiel => {
                return (materiel.nom === filterMateriel || filterMateriel === "All");
            }));
        }
    }, [materiels, searchMaterielBarContent, filterMateriel]);

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalMaterielSelected, setModalMaterielSelected] = useState({});

    const openAddMaterielModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const addMateriel = (materiel) => {
        setMateriels(materiels.concat(materiel));
    };

    const openSupprimerModal = (materielData) => {
        setModalMaterielSelected(materielData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const deleteMateriel = (materielId) => {
        setMateriels(materiels.filter(materiel => {
            return materiel._id !== materielId;
        }));
    };

    const openModifierMaterielModal = (materielData) => {
        setModalMaterielSelected(materielData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editMateriel = (materiel) => {
        setMateriels(materiels.map((mat) => {
            if (mat._id === materiel._id) {
                return materiel;
            }
            return mat;
        }));
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Suivi du matériel</h1>
                    <button onClick={() => openAddMaterielModal()} className='btn btn-primary medium-btn'>+ Matériel</button>
                    {/* <button onClick={() => openAddFormationModal()} className='btn btn-primary'>+ Ajouter</button> */}
                </div>
                <Breadcrumb
                    links={[
                        { label: "Geston des données", link: "/gestion-de-donnees" },
                        { label: "Suivi matériel", link: "/gestion-de-donnees/suivi-materiel" },
                    ]}
                />

                <div className='filterContainer mgT-m1'>
                    <SearchInput placeholder="Rechercher un matériel" searchContent={searchMaterielBarContent ? searchMaterielBarContent : ""} onChangeSearch={onChangeSearchMateriel} />
                    <select name="equipementSelect" id="equipement-select" className="new-default-input" onChange={(e) => setFilterMateriel(e.target.value)} value={filterMateriel ? filterMateriel : "All"}>
                        <option value="All">Tout les équipements</option>
                        <option value="EXTRACTEUR">Extracteurs</option>
                        <option value="UNITE DE FILTRATION UF">Unité de filtration UF</option>
                        <option value="UNITE DE CHAUFFE ET FILTRATION">Unité de chauffe et filtration</option>
                        <option value="SYSTÈME ADDUCTION  AIR">Système adduction air</option>
                        <option value="ASPIRATEUR">Aspirateur</option>
                        <option value="BORDUREUSE">Bordureuse</option>
                        <option value="RECTIFIEUSE">Rectifieuse</option>
                        <option value="CONTROLEUR DE DEPRESSION">Contrôleur de dépression</option>
                        <option value="ANEMOMETRE">Anemomètre</option>
                        <option value="LUXMETRE">Luxmètre</option>
                        <option value="U.M.D">U.M.D</option>
                    </select>
                </div>
                <TableRender
                    columns={
                        suiviMaterielColumn((materielData) => openSupprimerModal(materielData), (materielData) => openModifierMaterielModal(materielData), (link) => navigate(link))
                    }
                    tableData={showedMateriels}
                    sortBy={[

                    ]}
                    pageName="gestionSuiviMaterel"
                />
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter" &&
                    <AjouterEquipementsEpcForm progressBarRef={progressBarRef} addMateriel={(materiel) => addMateriel(materiel)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "modifier" &&
                    <ModifierEquipementEpcForm key={modalMaterielSelected._id} progressBarRef={progressBarRef} editMateriel={(materiel) => editMateriel(materiel)} materielData={modalMaterielSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "supprimer" &&
                    <SupprimerModalForm key={modalMaterielSelected._id} destination="supprimerGestionsEquipements" materielData={modalMaterielSelected} deleteMateriel={(materielId) => deleteMateriel(materielId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>
        </>
    );
};

export default SuiviMateriel;;