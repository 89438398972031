import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

const logger = (store) => (next) => (action) => {
    // console.log('dispatching', action)
    let result = next(action);
    // console.log('next state', store.getState())
    return result;
};

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['optionsFilterSort']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV === "development",
    middleware: [logger, thunk]
});

export const persistor = persistStore(store);