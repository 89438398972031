import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import Select from "react-select";
import { addYears, format } from 'date-fns';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, iconExtension, formatBytes } from '../../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import customToast from '../../../utils/ToastifyHelper';
import { addMonths } from 'date-fns';
import { addDays } from 'date-fns';

const CreerFormationSalarieModal = (props) => {
    const controller = new AbortController();

    const close = () => {
        controller.abort();
        props.closeModal();
        props.unrenderForm();
    };

    const [formationsPossible, setFormationsPossible] = useState([]);
    const [formationSelected, setFormationSelected] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/formationsSuiviSalarie/${props.salarie._id}`).then((result) => {
            setFormationsPossible(result.data);
            console.log(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);


    const formSchema = yup.object().shape({
        formationSuiviId: yup.string().required("Veuillez saisir l'id de la formation"),
        dateFormation: yup.date(),
        dateExpiration: yup.date(),
        organismeFormation: yup.string(),
        titreDocument: yup.string()
    });

    const onSubmit = (values, actions) => {
        let postData = {
            ...values,
            dateFormation: values.dateFormation !== "" ? values.dateFormation : null,
            dateExpiration: values.dateExpiration !== "" ? values.dateExpiration : null
        };
        if (documentSelected !== null) {
            let documentData = new FormData();
            if (values.titreDocument !== "") {
                documentData.append("titre", values.titreDocument);
            }
            else {
                documentData.append("titre", `documentFormation_${values.dateFormation}_${props.salarie.nom}-${props.salarie.prenom}`);
            }
            documentData.append("document", documentSelected);
            documentData.append("documentSaveRoute", "documentFormationSalarie");
            axiosInstance.post("/uploads", documentData).then((result) => {
                postData["idDocument"] = result.data.documentId;
                axiosInstance.post(`/salarieFormations/${props.salarie._id}`, postData).then((result) => {
                    props.addFormation(result.data);
                    actions.resetForm();
                    close();
                    customToast.success("La formation a été ajouté avec succès");
                }).catch((err) => {
                    setSubmitting(false);
                    showError(err);
                });
            }).catch((err) => {
                setSubmitting(false);
                showError(err);
            });
        }
        else {
            axiosInstance.post(`/salarieFormations/${props.salarie._id}`, postData).then((result) => {
                console.log(result.data);
                props.addFormation(result.data);
                actions.resetForm();
                close();
                customToast.success("La formation a été ajouté avec succès");
            }).catch((err) => {
                setSubmitting(false);
                showError(err);
            });
        }
        // do the axios request and reset forms
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            formationSuiviId: "",
            dateFormation: "",
            dateExpiration: "",
            organismeFormation: "",
            titreDocument: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const [documentSelected, setDocumentSelected] = useState(null);

    // Document
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            setDocumentSelected(acceptedFiles[0]);
            setFieldValue("titreDocument", acceptedFiles[0].path.split(".").slice(0, -1)[0]);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (values.dateFormation !== "" && formationSelected !== null && (formationSelected.renouvellement.annee !== null || formationSelected.renouvellement.mois !== null || formationSelected.renouvellement.jour !== null)) {
            let dateExpiration = new Date(values.dateFormation);
            if (formationSelected.renouvellement.annee !== null) {
                dateExpiration = addYears(dateExpiration, formationSelected.renouvellement.annee);
            }
            if (formationSelected.renouvellement.mois !== null) {
                dateExpiration = addMonths(dateExpiration, formationSelected.renouvellement.mois);
            }
            if (formationSelected.renouvellement.jour !== null) {
                dateExpiration = addDays(dateExpiration, formationSelected.renouvellement.jour);
            }
            setFieldValue("dateExpiration", format(dateExpiration, "yyyy-MM-dd"));
        }
    }, [values.dateFormation]);


    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Créer une formation</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='formationSuiviId' className='form-label'>Choisisez une formation<span className='required-field'>*</span></label>
                    <Select
                        options={formationsPossible.map((formation) => {
                            return { label: formation.nomFormation, value: formation._id, renouvellement: formation.renouvellement };
                        })}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => { setFieldValue("formationSuiviId", opt.value); setFormationSelected(opt); }}
                        placeholder={"Rechercher une formation"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                    // value={values.formationPour ? {label: formationsPossible, value: values.formationPour} : ""}
                    />
                    {errors.formationSuiviId && touched.formationSuiviId && <p className="form-error-message">{errors.formationSuiviId}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateFormation' className='form-label'>Date de la formation<span className='required-field'>*</span></label>
                    <input name='dateFormation' className={`new-default-input${errors.dateFormation && touched.dateFormation ? " input-error" : ""}`} type="date" value={values.dateFormation} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateExpiration' className='form-label'>Date d'expiration de la formation<span className='required-field'>*</span></label>
                    <input name='dateExpiration' className={`new-default-input${errors.dateExpiration && touched.dateExpiration ? " input-error" : ""}`} type="date" value={values.dateExpiration} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='organismeFormation' className='form-label'>Organisme de la formation</label>
                    <input name='organismeFormation' className={`new-default-input${errors.organismeFormation && touched.organismeFormation ? " input-error" : ""}`} type="text" value={values.organismeFormation} placeholder='Organisme de la formation' onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input' style={{ flex: 1 }}>
                    <label htmlFor='titreDocument' className='form-label'>Document formation</label>
                    <div className='mgT-s2'>
                        {documentSelected ?
                            <div className="fileItem">
                                {iconExtension(documentSelected.path)}
                                <input style={{ marginTop: 0 }} name='titreDocument' className={`new-default-input${errors.titreDocument && touched.titreDocument ? " input-error" : ""}`} type="text" value={values.titreDocument} placeholder='Titre du document' onChange={handleChange} onBlur={handleBlur} />
                                <p className='mgL-s2 mgR-s3' style={{ whiteSpace: "nowrap" }}>.{documentSelected.path.split(".")[documentSelected.path.split(".").length - 1]} - {formatBytes(documentSelected.size)}</p>
                                <Icon onClick={() => { setDocumentSelected(null); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                {errors.titreDocument && touched.titreDocument && <p className="form-error-message">{errors.titreDocument}</p>}
                            </div>
                            :
                            <div {...getRootProps({ style })}>
                                <input {...getInputProps()} />
                                <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                            </div>
                        }
                    </div>
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Ajouter la formation" />
            </form>
        </div>
    );
};

export default CreerFormationSalarieModal;