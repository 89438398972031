import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import Modal from '../../components/Modal';
import EquipementsListTable from '../../components/tables/EquipementsListTable';
import AjouterEquipementsEpcForm from '../../components/forms/AmianteForms/AjouterEquipementEpcForm';
import AjouterDocumentForm from '../../components/forms/AmianteForms/AjouterDocumentEPCForm';
import PatchEquipementEpcForm from '../../components/forms/ModifierEquipementEpcForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import { useNavigate } from 'react-router-dom';

const onLoadPage = () => (actionCreators.getEquipementEpcList());
const onQuitPage = () => (actionCreators.unloadEquipementsList());

const GestionEquipements = () => {
    const [showedItems, setShowedItems] = useState([]);
    const [selectedNomEqu, setSelectedNomEqu] = useState("All");
    const [searchBarContent, setSearchBarContent] = useState(null);
    const equipementListState = useSelector(state => state.equipementEpcList);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const searchEquipement = (content) => {
        if (content.target.value !== '') {
            setSearchBarContent(content.target.value);
        } else {
            setSearchBarContent(null);
        }
    };

    const selectNom = (select) => {
        setSelectedNomEqu(select.target.value);
    };

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");




    useEffect(() => {
        dispatch(onLoadPage());
        return () => {
            dispatch(onQuitPage());
            setShowedItems([]);
        };
    }, []);

    useEffect(() => {
        let showedEquipement = equipementListState.allEquipementsEpcState;
        showedEquipement.map((data) => {

            equipementListState.documentsEquipementEpcList.map(document => {
                if (document._refID === data._id) {
                    data["titre"] = document.baseDocument.titre;
                    data["document"] = document.baseDocument.file.filename;
                    data["IDdocument"] = document.baseDocument._id;
                }
                return document;
            });

            return data;
        });

        // showedEquipement.map((equipement) => {
        //     let historique = equipementListState.dateEquipementEpcList.filter((predi) => predi._id === equipement.gestion_suivi)[0].historique
        //     let date_dernier_controle = historique.length !== 0 ? historique.sort((a,b) => (a.dateControle > b.dateControle) ? -1 : ((b.dateControle > a.dateControle) ? 1 : 0))[0].dateControle : undefined
        //     equipement["date_dernier_controle"] = date_dernier_controle !== undefined ? new Intl.DateTimeFormat('fr-FR').format(new Date(date_dernier_controle))  : "Aucune"
        // })


        //searchBar
        if (searchBarContent !== null) {
            showedEquipement = showedEquipement.filter(equipement => (equipement.marque.toLowerCase().includes(searchBarContent.toLowerCase()) || equipement.numero_de_serie.toLowerCase().includes(searchBarContent.toLowerCase()) || equipement.ref_interne.toLowerCase().includes(searchBarContent.toLowerCase())));
        }
        if (selectedNomEqu !== "All") {
            showedEquipement = showedEquipement.filter(equipement => equipement.nom === selectedNomEqu);

        }

        setShowedItems(showedEquipement);
    }, [equipementListState, searchBarContent, selectedNomEqu]);

    const openAddEquipementEpc = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterEquipementsEpcForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalClasses("Aucune modal")} selectedData={selectedNomEqu} />);
    };

    const openDeleteEquipementEpc = (equipementEpcData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerGestionsEquipements" equipementEpc={equipementEpcData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalClasses("Aucune modal")} />);
    };

    const openModifyEquipementEpc = (equipementEpcNewData) => {

        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<PatchEquipementEpcForm equipementEpc={equipementEpcNewData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalClasses("Aucune modal")} />);
    };


    const openUploadDocument = (doc) => {

        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterDocumentForm maxFiles={1} documentSaveRoute={"ajouterDocumentsEPC"} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalClasses("Aucune modal")} equipementId={doc} />);
    };

    const openDeleteDocument = (documentData) => {

        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerDocument" document={documentData} documentSaveRoute={"documentsEPC"} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openPatchDocument = (doc) => {

        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterDocumentForm maxFiles={1} documentSaveRoute={"remplacerDocumentsEPC"} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalClasses("Aucune modal")} equipementId={doc} />);
    };


    return (
        <div className='section'>
            <div className="container-basic mgB-m2">
                <h1 className="h1-blue-dark">Équipements</h1>
                <div className="tableau-top-section">
                    <h3>{showedItems.length} équipement{showedItems.length > 1 && 's'} {showedItems.length > 1 ? "correspondent" : "correspond"} à votre recherche</h3>
                    <input type="search" className="header-input header-search" name='searchEquipement' placeholder='Rechecher un équipement' onChange={(e) => searchEquipement(e)} />
                    <select name="equipementSelect" id="equipement-select" className="header-select" onChange={(e) => selectNom(e)}>
                        <option value="All">Tout les équipements</option>
                        <option value="EXTRACTEUR">Extracteurs</option>
                        <option value="UNITE DE FILTRATION UF">Unité de filtration UF</option>
                        <option value="UNITE DE CHAUFFE ET FILTRATION">Unité de chauffe et filtration</option>
                        <option value="SYSTÈME ADDUCTION  AIR">Système adduction air</option>
                        <option value="ASPIRATEUR">Aspirateur</option>
                        <option value="BORDUREUSE">Bordureuse</option>
                        <option value="RECTIFIEUSE">Rectifieuse</option>
                        <option value="CONTROLEUR DE DEPRESSION">Contrôleur de dépression</option>
                        <option value="ANEMOMETRE">Anemomètre</option>
                        <option value="LUXMETRE">Luxmètre</option>
                        <option value="U.M.D">U.M.D</option>
                    </select>
                    <button className="default-button default-button-blue" onClick={() => openAddEquipementEpc()}>+ Équipements</button>
                </div>
            </div>
            <EquipementsListTable
                equipementEpcList={showedItems}
                supprimerEquipementEpc={(materiauxData) => openDeleteEquipementEpc(materiauxData)}
                modifierEquipementEpc={(materiauxNewData) => openModifyEquipementEpc(materiauxNewData)}
                historiqueEquipementEpc={(link) => navigate(link)}
                openUploadDocument={(doc) => openUploadDocument(doc)}
                openSupprimerDocument={(doc) => openDeleteDocument(doc)}
                openRemplacerDocument={(doc) => openPatchDocument(doc)}
            />
            <Modal animation={false} modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );

};

export default GestionEquipements;