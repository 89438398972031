import * as actionTypes from '../actions/actionTypes';

const processusState = [];

const reducer = (state = processusState, action) => {
    switch (action.type) {
        case actionTypes.SET_PROCESSUS:
            return action.allProcessus;
        case actionTypes.AJOUTER_PROCESSUS:
            return state.concat(action.processus);
        case actionTypes.MODIFIER_PROCESSUS:
            return state.map(processus => {
                return processus._id === action.processus._id ? action.processus : processus;
            });
        case actionTypes.SUPPRIMER_PROCESSUS:
            return state.filter(processus => {
                return processus._id !== action.deletedProcessusId;
            });
        case actionTypes.UNLOAD_PROCESSUS:
            return [];
        default:
            return state;
    }
};

export default reducer;