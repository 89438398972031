import * as actionTypes from "../actions/actionTypes";

const optionsFilterSortState = {};

const reducer = (state = optionsFilterSortState, action) => {
	switch (action.type) {
		case actionTypes.SAVE_OPTIONS_FILTER_SORT:
			return {
				...state,
				[action.pageName]: {
					...state[action.pageName],
					[action.optionName]: action.optionValue
				}
			};
		case actionTypes.ADD_TABLE_FILTER:
			if (state.hasOwnProperty(action.pageName) && state[action.pageName].hasOwnProperty("sortedColumn") && state[action.pageName].sortedColumn.length) {
				if (state[action.pageName].sortedColumn.find(sort => (sort.id === action.filterId))) {
					return {
						...state,
						[action.pageName]: {
							...state[action.pageName],
							sortedColumn: state[action.pageName].sortedColumn.map((sort) => {
								if (sort.id === action.filterId) {
									return { id: action.filterId, desc: action.desc };
								}
								return sort;
							})
						}
					};
				}
				else {
					// return {
					// 	...state,
					// 	[action.pageName]: {
					// 		...state[action.pageName],
					// 		sortedColumn: state[action.pageName].sortedColumn.concat({id: action.filterId, desc: action.desc})
					// 	}
					// };
					return {
						...state,
						[action.pageName]: {
							...state[action.pageName],
							sortedColumn: [{ id: action.filterId, desc: action.desc }]
						}
					};
				}
			}
			else {
				return {
					...state,
					[action.pageName]: {
						...state[action.pageName],
						sortedColumn: [{ id: action.filterId, desc: action.desc }]
					}
				};
			}
		case actionTypes.REMOVE_TABLE_FILTER:
			if (state.hasOwnProperty(action.pageName) && state[action.pageName].hasOwnProperty("sortedColumn") && state[action.pageName].sortedColumn.length) {
				return {
					...state,
					[action.pageName]: {
						...state[action.pageName],
						sortedColumn: state[action.pageName].sortedColumn.filter(sort => {
							return sort.id !== action.filterId;
						})
					}
				};
			}
			else {
				return state;
			}
		default:
			return state;
	}
};

export default reducer;
