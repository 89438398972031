import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HistoriqueControleEPCTable from '../../components/tables/HistoriqueControleEPCTable';
import * as actionCreators from '../../store/actions/exportAction';
import { useDispatch, useSelector } from 'react-redux';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import ControlesForm from '../../components/forms/ControlesEPC';
import Modal from '../../components/Modal';
import EditIcon from '../../components/icon/EditIcon';
import DatePicker from 'react-date-picker';

const onLoadPage = (idControle) => (actionCreators.getControleList(idControle));
const onQuitPage = () => (actionCreators.unloadControleList());

const HistoriqueEquipementEpc = (props) => {
    const [showedItems, setShowedItems] = useState([]);
    const historiqueList = useSelector(state => state.historiqueControleEPC);
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");
    const [searchBar, setSearchBar] = useState("");
    const { idControle } = useParams();

    const dispatch = useDispatch();

    const openModifyControle = (props) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ControlesForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} data={props} historiqueId={idControle} action="MODIFY" />);
    };
    const openModifyNextControle = (props) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ControlesForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} historiqueId={idControle} action="MODIFY_NEXT" />);
    };
    const openAddControle = (props) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ControlesForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} data={props} historiqueId={idControle} action="ADD" />);
    };
    const openDeleteControle = (props) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerControle" idHistorique={props._id} controleId={idControle} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalClasses("Aucune modal")} />);
    };


    const searchDocuments = (search) => {
        setSearchBar(search);
    };
    //TODO: SearchBar

    const [dateStart, setDateStart] = useState();
    const [dateEnd, setDateEnd] = useState();

    useEffect(() => {
        dispatch(onLoadPage(idControle));
        //.then((data) => {if (new Date(action.controleList[0].prochainControle) > new Date()) {return customToast.error("La date du prochain controle est dépassée")})


        return () => { onQuitPage(); };
    }, []);

    useEffect(() => {
        if (historiqueList.allControles.historique !== undefined) {

            if (dateStart !== undefined && dateEnd === undefined) {
                return setShowedItems(historiqueList.allControles.historique.filter(item => new Date(item.dateControle) >= new Date(dateStart)));
            }
            if (dateStart === undefined && dateEnd !== undefined) {
                return setShowedItems(historiqueList.allControles.historique.filter(item => new Date(item.dateControle) <= new Date(dateEnd)));
            }
            if (dateStart !== undefined && dateEnd !== undefined) {
                return setShowedItems(historiqueList.allControles.historique.filter(item => (new Date(item.dateControle) >= new Date(dateStart) && new Date(item.dateControle) <= new Date(dateEnd))));
            }


            return setShowedItems(historiqueList.allControles.historique);

        }

    }, [historiqueList.allControles.historique, historiqueList.allControles, dateStart, dateEnd]);


    //TODO: Tableau
    return (
        <div>
            <div className='container-basic mgB-m2'>
                <h1 className="h1-blue-dark">Historique des controles</h1>
                <div className="tableau-top-section" tyle={{ alignItems: "center" }}>
                    <h3 className="banqueHeure-Total" style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
                        <p style={new Date(historiqueList.allControles.prochainControle) > new Date() ? { marginRight: "1rem" } : { marginRight: "1rem", color: "#D8556B" }}> Prochain controle: {historiqueList.allControles.prochainControle !== null ? new Date(historiqueList.allControles.prochainControle).toLocaleDateString('fr-FR') : "Aucun"} </p>
                        <button onClick={() => openModifyNextControle()} className='table-modify-button' style={{ width: "2.5rem", height: "2.5rem" }}><EditIcon /></button>
                    </h3>
                    <div style={{ marginRight: "1rem", padding: "0.8rem" }} >
                        <DatePicker value={dateStart} onChange={(e) => setDateStart(e)} className="input-datePicker" />
                        <DatePicker value={dateEnd} onChange={(e) => setDateEnd(e)} className="input-datePicker" />
                    </div>
                    <button onClick={() => openAddControle()} className='default-button default-button-blue'>+ Ajouter un controle</button>
                </div>
            </div>
            <HistoriqueControleEPCTable historiqueTable={showedItems} supprimerControle={(materiauxData) => openDeleteControle(materiauxData)} modifierControle={(materiauxNewData) => openModifyControle(materiauxNewData)} />
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default HistoriqueEquipementEpc;