import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';


const ProfilePicture = ({ height, width, edit, openEditProfilePicture, profilePicture }) => {
    const [pictureLoaded, setPictureLoaded] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        setPictureLoaded(false);
        axiosInstance.get(`uploads/profilePicture/${profilePicture}`, {
            responseType: 'blob',
            timeout: 20000
        }).then((res) => {
            let fileURL = URL.createObjectURL(res.data);
            setImagePreview(fileURL);
            setPictureLoaded(true);
        }).catch((error) => {
            showError(error);
        });
    }, [profilePicture]);

    if (pictureLoaded) {
        if (edit) {
            return (
                <div style={{ position: "relative" }}>
                    <img
                        src={imagePreview}
                        style={{
                            width: width,
                            height: height
                        }}
                        onLoad={() => { URL.revokeObjectURL(imagePreview); }}
                    >
                    </img>
                    <div onClick={openEditProfilePicture} className='editProfilePicture-penContainer' style={{
                        width: "40px",
                        height: "40px",
                        zIndex: 3,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        cursor: "pointer"
                    }}>
                        <FontAwesomeIcon icon={faPen} />
                    </div>
                </div>
            );
        }
        else {
            return (
                <div style={{ width: width, height: height }}>
                    <img
                        src={imagePreview}
                        style={{
                            width: width,
                            height: height
                        }}
                        onLoad={() => { URL.revokeObjectURL(imagePreview); }}
                    >
                    </img>
                </div>
            );
        }
    }
    else {
        return (
            <div className='profilePictureDefault' style={{
                height: height ? height : "20px",
                width: width ? width : "20px",
                backgroundColor: "white",
                borderRadius: "50%",
                border: "1px solid #d8556b",
                display: 'flex',
                alignItems: "end",
                justifyContent: "center",
                overflow: 'hidden',
                position: "relative"
            }}>
                <FontAwesomeIcon icon={faUser} />
            </div>
        );
    }

};

export default ProfilePicture;
