import React, { useRef, useState } from "react";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import { Icon } from '@iconify/react';
import customToast from "../../utils/ToastifyHelper";
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import ReleveHeureAbsenceRenderList from "./ReleveHeureAbsenceRenderList";

const ReleveAbsenceListFormV2 = (props) => {
    const searchSalarieInput = useRef();
    const [salarieSelectedId, setSalarieSelectedId] = useState(null);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const commentaire = useRef();

    const optionsSalarie = [
        {
            type: 'group',
            name: "Ouvrier",
            items: props.listeDesSalaries.hasOwnProperty("ouvrierList") && props.listeDesSalaries.ouvrierList.length ? props.listeDesSalaries.ouvrierList.map((ouvrier) => {
                let fullName = ouvrier._id.login_matricule + " / " + ouvrier._id.nom + " " + ouvrier._id.prenom;
                return { name: fullName, value: ouvrier._id._id };
            }) : []
        },
        {
            type: 'group',
            name: "Chef de chantier",
            items: props.listeDesSalaries.hasOwnProperty("chefChantierList") && props.listeDesSalaries.chefChantierList.length ? props.listeDesSalaries.chefChantierList.map((chef) => {
                let fullName = chef._id.login_matricule + " / " + chef._id.nom + " " + chef._id.prenom;
                return { name: fullName, value: chef._id._id };
            }) : []
        },
        {
            type: 'group',
            name: "Intérimaire",
            items: props.listeDesSalaries.hasOwnProperty("interimaireList") && props.listeDesSalaries.interimaireList.length ? props.listeDesSalaries.interimaireList.map((interimaire) => {
                let fullName = interimaire._id.login_matricule + " / " + interimaire._id.nom + " " + interimaire._id.prenom;
                return { name: fullName, value: interimaire._id._id };
            }) : []
        }
    ];

    const handleFilterSalarie = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return optionsSalarie;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChangeSalarie = (...args) => {
        setSalarieSelectedId(args[0]);
    };

    const onSubmit = () => {
        setButtonDisabled(true);
        if (salarieSelectedId === null) {
            customToast.error("Aucun salarié sélectionné");
            setButtonDisabled(false);
        }
        else {
            let postValueAbsence = {
                "utilisateur_absent": salarieSelectedId,
                "date_deb_absence": props.jourDate,
                "date_fin_absence": props.jourDate,
                'commentaire': commentaire.current.value,
            };

            if (props.saisiePar) {
                postValueAbsence.saisiePar = props.saisiePar._id;
                axiosInstance.post("/releve-heure-v2/absence", postValueAbsence).then((result) => {
                    customToast.success("L'absence a été ajoutée avec succès");
                    setButtonDisabled(false);
                    setSalarieSelectedId(null);
                    props.ajouterAbsence(result.data);
                    commentaire.current.value = "";
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
            }
            else {
                axiosInstance.post("/releve-heure-v2/absence/me", postValueAbsence).then((result) => {
                    customToast.success("L'absence a été ajoutée avec succès");
                    setButtonDisabled(false);
                    setSalarieSelectedId(null);
                    props.ajouterAbsence(result.data);
                    commentaire.current.value = "";
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
            }

        }
    };

    return (
        <>
            {props.open ?
                <tr className='jourTableSubRow'>
                    <td className="salarieAbsence-td" colSpan="6">
                        <div className="salarieHeureAndForm-container">
                            <div className='jourReleveHeure-form'>
                                <div className='jourReleveHeure-form-top'>
                                    <div className='ajouterHeureSalarie-form' style={{ marginTop: 0 }} >
                                        <div className="selectSalarie">
                                            <label className='default-label' htmlFor="salarieSelect">Ajouter une absence</label>
                                            <SelectSearch
                                                ref={searchSalarieInput}
                                                options={optionsSalarie}
                                                filterOptions={handleFilterSalarie}
                                                value={salarieSelectedId === null ? '' : salarieSelectedId}
                                                name="salarie"
                                                placeholder="Choisir un salarié"
                                                search
                                                onChange={handleChangeSalarie}
                                            />
                                        </div>
                                        <div className="commentaireContainer">
                                            <div className="commentaireOnly">
                                                <label className='default-label' htmlFor="commentaire">Commentaire</label>
                                                <input ref={commentaire} type="text" className='default-input' placeholder="Commentaire" />
                                            </div>
                                            <div className="ajouterHoraireButton-container">
                                                <button className="ajouterAbsenceButton" disabled={props.statutSemaine === 0 ? isButtonDisabled : true} onClick={() => onSubmit()} value="AjouterA"><Icon icon="fa:user-times" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {props.absences.length > 0 && <ReleveHeureAbsenceRenderList absences={props.absences} openSupprimerAbsence={props.openSupprimerAbsence} statutSemaine={props.statutSemaine} />}
                        </div>
                    </td>
                </tr>
                :
                null
            }
        </>
    );
};

export default ReleveAbsenceListFormV2;
