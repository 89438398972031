import React, { useState } from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import LoadingBar from 'react-top-loading-bar';
import { formatBytes, iconExtension } from '../../../utils/GeneralHelper';
import axiosInstance from '../../../utils/axiosInstance';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';


const AjouterDocumentGestionDoe = (props) => {
    const controller = new AbortController();
    const [progress, setProgress] = useState(0);

    const close = () => {
        controller.abort();
        props.closeModal();
        props.unrenderForm();
    };

    const formSchema = yup.object().shape({
        documentTitle: yup.string().required("Veuillez saisir le titre du document"),
    });

    const onSubmit = (values, actions) => {
        // bar progress
        const config = {
            onUploadProgress: progressEvent => {
                let calculateProgress = progress;
                calculateProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress(Math.floor(calculateProgress));
            }
        };

        let postData = new FormData();
        postData.append("titre", values.documentTitle);
        postData.append("document", props.documentData);
        postData.append("documentSaveRoute", "gestionDocumentsChantiers");
        console.log(props.documentData);
        axiosInstance.post("/uploads", postData, { ...config, timeout: 0, signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } })
            .then((result) => {
                actions.resetForm();
                customToast.success("Le document a été enregistré avec succès");
                props.ajouterDocumentDoe(result.data);
                close();
            }).catch((err) => {
                setSubmitting(false);
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            "documentTitle": props.documentData.name.replace(/\.[^/.]+$/, ""),
        },
        validationSchema: formSchema,
        onSubmit
    });

    return (
        <>
            <LoadingBar color={"#d13852"} progress={progress} onLoaderFinished={() => setProgress(0)} />
            <div className="modal-content ajouter-form">
                <h3 className="modal-title">Ajouter un document</h3>
                <button className='modal-button' onClick={() => close()}>x</button>
                <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                    <div className='label-and-input'>
                        <label htmlFor='documentContent' className='form-label'>Document ajouté</label>
                        <div className="document-upload mgT-s2">
                            <div className="fileItem">
                                {iconExtension(props.documentData.path)}
                                <p>{props.documentData.path} - {formatBytes(props.documentData.size)}</p>
                            </div>
                        </div>
                    </div>
                    <div className='label-and-input last-input'>
                        <label htmlFor='documentTitle' className='form-label'>Titre du document<span className='required-field'>*</span></label>
                        <input name='documentTitle' className={`new-default-input${errors.documentTitle && touched.documentTitle ? " input-error" : ""}`} type="text" placeholder="Titre du document" value={values.documentTitle} onChange={handleChange} onBlur={handleBlur} />
                        {errors.documentTitle && touched.documentTitle && <p className="form-error-message">{errors.documentTitle}</p>}
                    </div>
                    <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Sauvegarder" />
                </form>
            </div>
        </>
    );
};

export default AjouterDocumentGestionDoe;