import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Breadcrumb from "../../../components/ui/Breadcrumb";
import OptionsDechets from "./OptionsDechets/OptionsDechets";

const OptionsFormulaires = () => {
    const progressBarRef = useRef(null);

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Options des formulaires</h1>
                </div>
                <Breadcrumb
                    links={[
                        { label: "Réglages", link: "/reglages" },
                        { label: "Options formulaires", link: "/reglages/options-formulaires" },
                    ]}
                />
                <hr className="mgT-s2" style={{ color: "#d9d9d9", backgroundColor: "#d9d9d9" }} />
                <OptionsDechets progressBarRef={progressBarRef} />
            </div>

        </>
    );
};

export default OptionsFormulaires;
