import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../../store/actions/exportAction';
import { useFormik } from 'formik';
import * as yup from "yup";
import axiosInstance from '../../../utils/axiosInstance';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';
import Select from "react-select";


const addMateriaux = (materiaux) => (actionCreators.addMateriaux(materiaux));


const AjouterMateriauxForm = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [sitesOptions, setSitesOptions] = useState([]);
    const [provenancesSites, setProvenancesSites] = useState([]);

    useEffect(() => {
        axiosInstance.get("/site").then((result) => {
            setSitesOptions(result.data.map((site) => {
                return (
                    { value: site._id._id, label: site._id.libelle_lieu }
                );
            }));
        }).catch((err) => {
            showError(err);
        });
    }, []);

    // validationschema
    const formSchema = yup.object().shape({
        libelle_mat: yup.string().required("Veuillez saisir un nom de matériaux")
    });

    const dispatch = useDispatch();

    const onSubmit = (values, actions) => {
        let postData = {
            ...values,
            provenances: provenancesSites.map((site) => {
                return (
                    site.value
                );
            })
        };
        props.progressBarRef.current.continuousStart();
        axiosInstance.post('/materiaux', postData)
            .then((result) => {
                props.progressBarRef.current.complete();
                customToast.success("Le matériau a été ajouté avec succès");
                dispatch(addMateriaux(result.data));
                close();
            }).catch((err) => {
                props.progressBarRef.current.complete();
                setSubmitting(false);
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelle_mat: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onChangeProvenancesSite = (opt) => {
        setProvenancesSites(opt);
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Ajouter un matériaux</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="nom">Nom<span className='required-field'>*</span></label>
                    <input name='libelle_mat' className={`new-default-input${errors.libelle_mat && touched.libelle_mat ? " input-error" : ""}`} type="text" placeholder="Nom du matériaux" value={values.libelle_mat} onChange={handleChange} onBlur={handleBlur} />
                    {errors.libelle_mat && touched.libelle_mat && <p className="form-error-message">{errors.libelle_mat}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label className='form-label' htmlFor="provenance">Provenance</label>
                    <Select
                        options={sitesOptions}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeProvenancesSite(opt)}
                        placeholder={"Matériaux provenant du site"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isMulti={true}
                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                    />
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} type="submit" className='btn btn-primary medium-btn fw' value="Ajouter" />
            </form>
        </div>
    );
};

export default AjouterMateriauxForm;
