import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const topBarState = {
    currentTab: null,
    // scrollToAction: null
};

const reducer = (state = topBarState, action) => {
    switch (action.type) {
        case actionTypes.SET_TOPBAR_CURRENT_TAB:
            return updateObject(state, { currentTab: action.currentTab });
        // case actionTypes.TOPBAR_SCROLL_TO_TAB:
        //     return updateObject(state, {currentTab: action.scrollToTab, scrollToAction: action.scrollToTab})
        // case actionTypes.UNLOAD_TOPBAR_SCROLL:
        //     return updateObject(state, {scrollToAction: null})
        case actionTypes.UNLOAD_TOPBAR_TAB:
            return {
                currentTab: null,
                // scrollToAction: null
            };
        default:
            return state;
    }
};

export default reducer;
