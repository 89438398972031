import React from "react";
import SupprimerIcon from "../../components/icon/SupprimerIcon";
import EditIcon from "../../components/icon/EditIcon";
import { formatIntTwoDigitString } from '../../utils/GeneralHelper';
import ReactTooltip from "react-tooltip";

const ReleveHeureAbsenceRenderList = (props) => {
    return (
        <ul className="salarieReleveHeure-list">
            {props.absences.map((absence) => {
                return (
                    <li key={absence._id}>
                        <p className='readOnly-input selectSalarie-readOnly-input' placeholder="Salarie">{absence.utilisateur_absent.nom + " " + absence.utilisateur_absent.prenom}</p>
                        <div className='salarieList-commentaireButton-container'>
                            <p className='readOnly-input commentaireInput-readOnly' placeholder="Commentaire">{absence.commentaire || ''}</p>
                            {/* <button data-tip data-for={"modifierHeureTip-" + absence._id} disabled={props.statutSemaine === 0 ? false : true} onClick={() => console.log(absence)} className='table-modify-button'><EditIcon /></button>
                            <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"modifierHeureTip-" + absence._id} place="bottom" effect="solid">
                                Modifier l'absence
                            </ReactTooltip> */}
                            <button data-tip data-for={"supprimerHeureTip-" + absence._id} disabled={props.statutSemaine === 0 ? false : true} onClick={() => props.openSupprimerAbsence(absence)} className='table-delete-button'><SupprimerIcon /></button>
                            <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"supprimerHeureTip-" + absence._id} place="bottom" effect="solid">
                                Supprimer l'absence
                            </ReactTooltip>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default ReleveHeureAbsenceRenderList;
