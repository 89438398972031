import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../../store/actions/exportAction';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosInstance from '../../../utils/axiosInstance';
import DatePicker from 'react-date-picker';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';
import { useFormik } from 'formik';

const AjouterEntretienEquipementForm = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const dispatch = useDispatch();
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onSubmit = (values, actions) => {
        setButtonDisabled(true);

        axiosInstance.post('/entretienEquipement/addEntretien', {
            ...values,
            nomEntretien: values.nomEntretien ? values.nomEntretien : null,
            anneeRenouvellement: values.anneeRenouvellement ? values.anneeRenouvellement : null,
            moisRenouvellement: values.moisRenouvellement ? values.moisRenouvellement : null,
            jourRenouvellement: values.jourRenouvellement ? values.jourRenouvellement : null,
            anneeSignal: values.anneeSignal ? values.anneeSignal : null,
            moisSignal: values.moisSignal ? values.moisSignal : null,
            jourSignal: values.jourSignal ? values.jourSignal : null,
        })
            .then((result) => {
                customToast.success("L'entretien a été ajouté avec succès");
                console.log(result);
                props.addEntretien(result.data);
                actions.resetForm();
                close();
            }).catch((err) => {
                setButtonDisabled(false);
                showError(err);
            });
        setButtonDisabled(false);
        close();

    };


    // validationschema
    const schema = yup.object().shape({
        nomEntretien: yup.string().required("Veuillez selectionner un nom")
    });


    // const { register, handleSubmit, formState: { errors } } = useForm({
    //     resolver: yupResolver(schema)
    // });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nomEntretien: "",
            anneeRenouvellement: "",
            moisRenouvellement: "",
            jourRenouvellement: "",
            anneeSignal: "",
            moisSignal: "",
            jourSignal: "",
        },
        validationSchema: schema,
        onSubmit
    });

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Ajouter un entretien</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form" onSubmit={handleSubmit}>

                <label className='ajouter-form-label default-label' htmlFor="nomEntretien">Nom de l'entretien <span className='required-field'>*</span></label>
                <input className={`new-default-input${errors.nomEntretien && touched.nomEntretien ? " input-error" : ""}`} name="nomEntretien" type="text" placeholder="Nom de l'entretien" value={values.nomEntretien} onChange={handleChange} onBlur={handleBlur} />
                {errors.nomEntretien && touched.nomEntretien && <p className="form-error-message">{errors.nomEntretien}</p>}

                <div className='label-and-input'>
                    <label className='form-label'>Entretien à renouveler dans</label>
                    <div className='number-label-input-container'>
                        <div>
                            <input className={`new-default-input${errors.anneeRenouvellement && touched.anneeRenouvellement ? " input-error" : ""}`} name="anneeRenouvellement" type="number" placeholder="Annee" value={values.anneeRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>an(s)</p>
                            {errors.anneeRenouvellement && touched.anneeRenouvellement && <p className="form-error-message">{errors.anneeRenouvellement}</p>}
                        </div>
                        <div>
                            <input className={`new-default-input${errors.moisRenouvellement && touched.moisRenouvellement ? " input-error" : ""}`} name="moisRenouvellement" type="number" placeholder="Mois" value={values.moisRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>mois</p>
                            {errors.moisRenouvellement && touched.moisRenouvellement && <p className="form-error-message">{errors.moisRenouvellement}</p>}
                        </div>
                        <div>
                            <input className={`new-default-input${errors.jourRenouvellement && touched.jourRenouvellement ? " input-error" : ""}`} name="jourRenouvellement" type="number" placeholder="Jour" value={values.jourRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>jour(s)</p>
                            {errors.jourRenouvellement && touched.jourRenouvellement && <p className="form-error-message">{errors.jourRenouvellement}</p>}
                        </div>
                    </div>
                </div>
                <div className='label-and-input'>
                    <label className='form-label'>Rappel de l'entretien dans</label>
                    <div className='number-label-input-container'>
                        <div>
                            <input className={`new-default-input${errors.anneeSignal && touched.anneeSignal ? " input-error" : ""}`} name="anneeSignal" type="number" placeholder="Annee" value={values.anneeSignal} onChange={handleChange} onBlur={handleBlur} />
                            <p>an(s)</p>
                            {errors.anneeSignal && touched.anneeSignal && <p className="form-error-message">{errors.anneeSignal}</p>}
                        </div>
                        <div>
                            <input className={`new-default-input${errors.moisSignal && touched.moisSignal ? " input-error" : ""}`} name="moisSignal" type="number" placeholder="Mois" value={values.moisSignal} onChange={handleChange} onBlur={handleBlur} />
                            <p>mois</p>
                            {errors.moisSignal && touched.moisSignal && <p className="form-error-message">{errors.moisSignal}</p>}
                        </div>
                        <div>
                            <input className={`new-default-input${errors.jourSignal && touched.jourSignal ? " input-error" : ""}`} name="jourSignal" type="number" placeholder="Jour" value={values.jourSignal} onChange={handleChange} onBlur={handleBlur} />
                            <p>jour(s)</p>
                            {errors.jourSignal && touched.jourSignal && <p className="form-error-message">{errors.jourSignal}</p>}
                        </div>
                    </div>
                </div>

                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} type="submit" className='validate-button-fw' value="Ajouter" />

            </form>

        </div>);
};

export default AjouterEntretienEquipementForm;