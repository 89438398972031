import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Breadcrumb from "../../../components/ui/Breadcrumb";
import * as yup from "yup";
import { useFormik } from 'formik';
import axiosInstance from "../../../utils/axiosInstance";
import { showError } from "../../../utils/ErrorHelper";

const ReglagesAmiante = () => {
    const progressBarRef = useRef(null);

    const formSchema = yup.object().shape({
        tauxAmianteRepos: yup.number().required("Veuillez renseigner le taux d'amiante en temps de repos (f/L)"),
        phasePreparation: yup.string().required("Veuillez renseigner le nom de la phase de preparation"),
        phaseExecution: yup.string().required("Veuillez renseigner le nom de la phase d'exécution"),
        phaseDecontaminationMateriel: yup.string().required("Veuillez renseigner le nom de la phase de décontamination des matériaux"),
        phaseRepliDeChantier: yup.string().required("Veuillez renseigner le nom de la phase de repli des chantiers"),
    });

    const onSubmit = (values, actions) => {
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/options/amiante`, values).then((result) => {
            progressBarRef.current.complete();
        }).catch((err) => {
            progressBarRef.current.complete();
            setSubmitting(false);
            showError(err);
        });
    };

    // Handle formm
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            tauxAmianteRepos: "",
            phasePreparation: "",
            phaseExecution: "",
            phaseDecontaminationMateriel: "",
            phaseRepliDeChantier: "",
        },
        validationSchema: formSchema,
        onSubmit
    });

    const [initialValues, setInitialValues] = useState({
        tauxAmianteRepos: "",
        phasePreparation: "",
        phaseExecution: "",
        phaseDecontaminationMateriel: "",
        phaseRepliDeChantier: "",
    });


    useEffect(() => {
        axiosInstance.get("/options?categorieOptions=amiante").then((result) => {
            setInitialValues(result.data.options);
            setFieldValue("tauxAmianteRepos", result.data.options.tauxAmianteRepos);
            setFieldValue("phasePreparation", result.data.options.phasePreparation);
            setFieldValue("phaseExecution", result.data.options.phaseExecution);
            setFieldValue("phaseDecontaminationMateriel", result.data.options.phaseDecontaminationMateriel);
            setFieldValue("phaseRepliDeChantier", result.data.options.phaseRepliDeChantier);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const fieldEdited = () => {
        return initialValues.tauxAmianteRepos !== values.tauxAmianteRepos || initialValues.phasePreparation !== values.phasePreparation || initialValues.phaseExecution !== values.phaseExecution || initialValues.phaseDecontaminationMateriel !== values.phaseDecontaminationMateriel || initialValues.phaseRepliDeChantier !== values.phaseRepliDeChantier;
    };


    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Réglages de l'amiante</h1>
                </div>
                <Breadcrumb
                    links={[
                        { label: "Réglages", link: "/reglages" },
                        { label: "Amiante", link: "/reglages/amiante" },
                    ]}
                />
                <hr className="mgT-s2" style={{ color: "#d9d9d9", backgroundColor: "#d9d9d9" }} />
                <div className="container-basic mgT-s2">
                    <form className='form' onSubmit={handleSubmit}>
                        <div className="setings-container">
                            <h4>Vacations</h4>
                            <p>Modifier les valeurs de vacations par défault</p>
                            <div className="form-exposition-repos mgT-m1">
                                <div className='label-and-input'>
                                    <label htmlFor='tauxAmianteRepos' className='form-label'>Taux d'amiante en temps de repos (f/L)</label>
                                    <input name='tauxAmianteRepos' className={`new-default-input${errors.tauxAmianteRepos && touched.tauxAmianteRepos ? " input-error" : ""}`} type="text" placeholder="Taux d'amiante en zone de repos" value={values.tauxAmianteRepos} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.tauxAmianteRepos && touched.tauxAmianteRepos && <p className="form-error-message">{errors.tauxAmianteRepos}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="setings-container mgT-m2">
                            <h4>Phase de chantier</h4>
                            <p>Renommer les phases de chantier</p>
                            <div className="form-exposition-repos mgT-m1">
                                <div className='label-and-input'>
                                    <label htmlFor='phasePreparation' className='form-label'>Phase de préparation</label>
                                    <input name='phasePreparation' className={`new-default-input${errors.phasePreparation && touched.phasePreparation ? " input-error" : ""}`} type="text" placeholder="Changer le nom de la phase de préparation" value={values.phasePreparation} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.phasePreparation && touched.phasePreparation && <p className="form-error-message">{errors.phasePreparation}</p>}
                                </div>
                            </div>
                            <div className="form-exposition-repos mgT-m1">
                                <div className='label-and-input'>
                                    <label htmlFor='phaseExecution' className='form-label'>Phase d'exécution</label>
                                    <input name='phaseExecution' className={`new-default-input${errors.phaseExecution && touched.phaseExecution ? " input-error" : ""}`} type="text" placeholder="Changer le nom de la phase d'exécution" value={values.phaseExecution} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.phaseExecution && touched.phaseExecution && <p className="form-error-message">{errors.phaseExecution}</p>}
                                </div>
                            </div>
                            <div className="form-exposition-repos mgT-m1">
                                <div className='label-and-input'>
                                    <label htmlFor='phaseDecontaminationMateriel' className='form-label'>Phase de décontamination du matériels</label>
                                    <input name='phaseDecontaminationMateriel' className={`new-default-input${errors.phaseDecontaminationMateriel && touched.phaseDecontaminationMateriel ? " input-error" : ""}`} type="text" placeholder="Changer le nom de la phase de décontamination du matériels" value={values.phaseDecontaminationMateriel} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.phaseDecontaminationMateriel && touched.phaseDecontaminationMateriel && <p className="form-error-message">{errors.phaseDecontaminationMateriel}</p>}
                                </div>
                            </div>
                            <div className="form-exposition-repos mgT-m1">
                                <div className='label-and-input'>
                                    <label htmlFor='phaseRepliDeChantier' className='form-label'>Phase de repli du chantier</label>
                                    <input name='phaseRepliDeChantier' className={`new-default-input${errors.phaseRepliDeChantier && touched.phaseRepliDeChantier ? " input-error" : ""}`} type="text" placeholder="Changer le nom de la phase de repli de chantier" value={values.phaseRepliDeChantier} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.phaseRepliDeChantier && touched.phaseRepliDeChantier && <p className="form-error-message">{errors.phaseRepliDeChantier}</p>}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <input disabled={isSubmitting || !fieldEdited()} style={{ opacity: !isSubmitting && fieldEdited() ? 1 : 0.4 }} className="btn btn-primary" type="submit" value="Sauvegarder" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ReglagesAmiante;