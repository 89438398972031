import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../components/ui/Breadcrumb';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import SearchInput from '../../components/ui/SearchInput';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import TableRender from '../../components/tables/TableRender';
import { siteColumns } from '../../components/tables/TableColumns';
import AjouterSite from './AjouterSite';
import Modal from '../../components/Modal';

import ModifierSite from './ModifierSite';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import { useNavigate } from 'react-router-dom';



const GestionSites = () => {
    const progressBarRef = useRef(null);
    const navigate = useNavigate();

    // modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalSiteSelected, setModalSiteSelected] = useState({});

    // Sites data
    const [sites, setSites] = useState([]);
    const [showedSites, setShowedSites] = useState([]);

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/site").then((result) => {
            setSites(result.data);
            progressBarRef.current.complete();
        }).catch((err) => {
            showError(err);
        });
    }, []);

    //document search bar
    const [searchSitesBarContent, setSearchSitesBarContent] = useState(null);
    const sitesMatch = (site, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(site._id.libelle_mat).includes(insensitiveSearch);
    };

    const onChangeSearchSites = (e) => {
        setSearchSitesBarContent(e.target.value);
    };

    // SEARCH INPUT
    useEffect(() => {
        if (searchSitesBarContent !== null && searchSitesBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedSites(sites.filter(site => {
                    return sitesMatch(site, searchSitesBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedSites(sites);
        }
    }, [sites, searchSitesBarContent]);

    const addSite = (site) => {
        setSites(sites.concat(site));
    };

    const openAddSiteModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editSite = (updatedSite) => {
        setSites(sites.map((site) => {
            if (site._id._id === updatedSite._id._id) {
                return updatedSite;
            }
            return site;
        }));
    };

    const openModifierSiteModal = (site) => {
        setModalSiteSelected(site);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerSite = (siteSupprimerId) => {
        console.log(siteSupprimerId);
        setSites(sites.filter(site => {
            return (site._id._id !== siteSupprimerId);
        }));
    };

    const openSupprimerModal = (site) => {
        setModalSiteSelected(site);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Liste des sites</h1>
                    <button onClick={() => openAddSiteModal()} className='btn btn-primary medium-btn'>+ Sites</button>
                    {/* <button onClick={() => openAddFormationModal()} className='btn btn-primary'>+ Ajouter</button> */}
                </div>
                <Breadcrumb
                    links={[
                        { label: "Geston des données", link: "/gestion-de-donnees" },
                        { label: "Sites", link: "/gestion-de-donnees/sites" },
                    ]}
                />
                <div className='filterContainer mgT-m1'>
                    <SearchInput placeholder="Rechercher un site" searchContent={searchSitesBarContent ? searchSitesBarContent : ""} onChangeSearch={onChangeSearchSites} />
                </div>
                <TableRender
                    columns={siteColumns((siteData) => openModifierSiteModal(siteData), (site) => openSupprimerModal(site), (link) => navigate(link))}
                    tableData={showedSites}
                    sortBy={[
                        {
                            id: '_id.libelle_lieu',
                            desc: false
                        }
                    ]
                    }
                    pageName="gestionSites"
                />
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter" &&
                    <AjouterSite progressBarRef={progressBarRef} addSite={(site) => addSite(site)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "modifier" &&
                    <ModifierSite progressBarRef={progressBarRef} editSite={(updatedSite) => editSite(updatedSite)} modalSiteSelected={modalSiteSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "supprimer" &&
                    <SupprimerModalForm destination={"supprimerSite"} progressBarRef={progressBarRef} supprimerSite={(supprimerSiteId) => supprimerSite(supprimerSiteId)} modalSiteSelected={modalSiteSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>
        </>
    );
};

export default GestionSites;;