import React, { useState, useEffect } from 'react';
import ChevronRightIcon from '../icon/ChevronRightIcon';
import UserAccountIcon from '../icon/UserAccountIcon';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import * as actionCreators from '../../store/actions/exportAction';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from '../ui/ProfilePicture';
import DefaultProfilePicture from '../ui/DefaultProfilePicture';


const onUserLogout = () => (actionCreators.logout());

const UserAccountHead = () => {
    const userState = useSelector(
        state => state.user
    );

    const navigate = useNavigate();

    const [isSmallMenuOpen, setSmallMenuOpen] = useState(false);
    const [menuDisplay, setMenuDisplay] = useState(isSmallMenuOpen ? "block" : "none");
    const [menuOpacity, setMenuOpacity] = useState(isSmallMenuOpen ? 1 : 0);

    useEffect(() => {
        if (isSmallMenuOpen) {
            setMenuDisplay("block");
            setTimeout(() =>
                setMenuOpacity(1), 20
            );
        }
        else {
            setMenuOpacity(0);
            setTimeout(() =>
                setMenuDisplay('none'), 150
            );
        }
    }, [isSmallMenuOpen]);

    const dispatch = useDispatch();

    return (
        <div onMouseEnter={() => setSmallMenuOpen(true)} onMouseLeave={() => setSmallMenuOpen(false)} className="dashboard-header-userAccount-bigContainer">
            <div className="dashboard-header-userAccount-container">
                <div className='accountSettings-profilePicture-wrapper mgR-s2'>
                    {userState.user.hasOwnProperty("profilePicture") && userState.user.profilePicture !== null ?
                        <ProfilePicture width="29px" height="29px" edit={false} profilePicture={userState.user.profilePicture} /> :
                        <DefaultProfilePicture additionalClassname={"dashboardHeader"} width="29px" height="29px" edit={false} />
                    }
                </div>
                {/* <UserAccountIcon /> */}
                <div className="username-role-container">
                    <p className="userRole">{userState.user.statut}</p>
                    <div className="userIdentity">
                        <h3>{userState.user.nom + " " + userState.user.prenom}</h3>
                    </div>
                </div>
                <ChevronRightIcon menuClass={isSmallMenuOpen ? "down-arrow down-arrow_open" : "down-arrow"} />
            </div>
            <div onClick={() => navigate("mon-compte")} style={{ display: menuDisplay, opacity: menuOpacity }} className="userAccount__menu">
                <div className="smallAccountItems">
                    <Icon icon="fluent:person-settings-20-filled" />
                    <p>Mon compte</p>
                </div>
                <hr />
                <div onClick={() => dispatch(onUserLogout())} className="smallAccountItems">
                    <Icon icon="carbon:logout" />
                    <p>Déconnexion</p>
                </div>

            </div>
        </div>
    );
};

export default UserAccountHead;
