import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import TimePicker from 'react-time-picker-input';
import "react-time-picker-input/dist/components/TimeInput.css";
import { formatIntTwoDigitString, getTotalHeure } from '../../../utils/GeneralHelper';
import { showError } from "../../../utils/ErrorHelper";
import customToast from "../../../utils/ToastifyHelper";
import axiosInstance from "../../../utils/axiosInstance";

const ModifierSalarieHeureV2 = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const totalHeureCalc = getTotalHeure({
        horaireAprem: { heureDeb: props.travailSalarie.horaireAprem.heureDeb, minuteDeb: props.travailSalarie.horaireAprem.minuteDeb, heureFin: props.travailSalarie.horaireAprem.heureFin, minuteFin: props.travailSalarie.horaireAprem.minuteFin },
        horaireMat: { heureDeb: props.travailSalarie.horaireMat.heureDeb, minuteDeb: props.travailSalarie.horaireMat.minuteDeb, heureFin: props.travailSalarie.horaireMat.heureFin, minuteFin: props.travailSalarie.horaireMat.minuteFin }
    });

    const commentaire = useRef();

    const [horaireMatinDeb, sethoraireMatinDeb] = useState(props.travailSalarie.horaireMat.heureDeb !== null && props.travailSalarie.horaireMat.minuteDeb !== null ? formatIntTwoDigitString(props.travailSalarie.horaireMat.heureDeb) + ":" + formatIntTwoDigitString(props.travailSalarie.horaireMat.minuteDeb) : "--:--");
    const [horaireMatinFin, sethoraireMatinFin] = useState(props.travailSalarie.horaireMat.heureFin !== null && props.travailSalarie.horaireMat.minuteFin !== null ? formatIntTwoDigitString(props.travailSalarie.horaireMat.heureFin) + ":" + formatIntTwoDigitString(props.travailSalarie.horaireMat.minuteFin) : "--:--");
    const [horaireApremDeb, sethoraireApremDeb] = useState(props.travailSalarie.horaireAprem.heureDeb !== null && props.travailSalarie.horaireAprem.minuteDeb !== null ? formatIntTwoDigitString(props.travailSalarie.horaireAprem.heureDeb) + ":" + formatIntTwoDigitString(props.travailSalarie.horaireAprem.minuteDeb) : "--:--");
    const [horaireApremFin, setHoraireApremFin] = useState(props.travailSalarie.horaireAprem.heureFin !== null && props.travailSalarie.horaireAprem.minuteFin !== null ? formatIntTwoDigitString(props.travailSalarie.horaireAprem.heureFin) + ":" + formatIntTwoDigitString(props.travailSalarie.horaireAprem.minuteFin) : "--:--");
    const [totalHeure, setTotalHeure] = useState(totalHeureCalc.heure !== 0 && totalHeureCalc.minute !== 0 ? formatIntTwoDigitString(totalHeureCalc.heure) + ":" + formatIntTwoDigitString(totalHeureCalc.minute) : "--:--");

    useEffect(() => {
        const tryUpdateTotalHeure = () => {
            const heureMatinDebut = horaireMatinDeb.match(/^(\d+):(\d+)/);
            const heureMatinFin = horaireMatinFin.match(/^(\d+):(\d+)/);
            const heureApremDebut = horaireApremDeb.match(/^(\d+):(\d+)/);
            const heureApremFin = horaireApremFin.match(/^(\d+):(\d+)/);
            if (heureMatinDebut !== null && heureMatinFin !== null && heureApremDebut !== null && heureApremFin !== null) {
                let totalHeureMatin = Math.abs(parseInt(heureMatinFin[1]) - parseInt(heureMatinDebut[1]));
                let totalMinuteMatin = parseInt(heureMatinFin[2]) - parseInt(heureMatinDebut[2]);
                let totalHeureAprem = Math.abs(parseInt(heureApremFin[1]) - parseInt(heureApremDebut[1]));
                let totalMinuteAprem = parseInt(heureApremFin[2]) - parseInt(heureApremDebut[2]);
                let totalHeure = totalHeureMatin + totalHeureAprem;
                if (totalMinuteMatin < 0) {
                    totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                    totalHeure--;
                }
                if (totalMinuteAprem < 0) {
                    totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                    totalHeure--;
                }
                let totalMinute = totalMinuteMatin + totalMinuteAprem;
                const quotientMinute = Math.floor(totalMinute / 60);
                if (quotientMinute >= 1) {
                    totalHeure += quotientMinute;
                    totalMinute = Math.floor(totalMinute % 60);
                }

                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else if (heureMatinDebut !== null && heureMatinFin !== null && heureApremDebut === null && heureApremFin === null) {
                let totalHeureMatin = Math.abs(parseInt(heureMatinFin[1]) - parseInt(heureMatinDebut[1]));
                let totalMinuteMatin = parseInt(heureMatinFin[2]) - parseInt(heureMatinDebut[2]);
                let totalHeure = totalHeureMatin;
                if (totalMinuteMatin < 0) {
                    totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                    totalHeure--;
                }
                let totalMinute = totalMinuteMatin;
                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else if (heureMatinDebut === null && heureMatinFin === null && heureApremDebut !== null && heureApremFin !== null) {
                let totalHeureAprem = Math.abs(parseInt(heureApremFin[1]) - parseInt(heureApremDebut[1]));
                let totalMinuteAprem = parseInt(heureApremFin[2]) - parseInt(heureApremDebut[2]);
                let totalHeure = totalHeureAprem;
                if (totalMinuteAprem < 0) {
                    totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                    totalHeure--;
                }
                let totalMinute = totalMinuteAprem;
                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else {
                ;
            }
        };
        tryUpdateTotalHeure();
    }, [horaireMatinDeb, horaireMatinFin, horaireApremDeb, horaireApremFin]);

    const onModifySalarie = () => {
        setButtonDisabled(true);
        const heureMatinDebut = horaireMatinDeb.match(/^(\d+):(\d+)/);
        const heureMatinFin = horaireMatinFin.match(/^(\d+):(\d+)/);
        const heureApremDebut = horaireApremDeb.match(/^(\d+):(\d+)/);
        const heureApremFin = horaireApremFin.match(/^(\d+):(\d+)/);
        if ((heureMatinDebut !== null && heureMatinFin !== null) || (heureApremDebut !== null && heureApremFin !== null)) {
            let heureMatDeb = null;
            let minuteMatDeb = null;
            let heureMatFin = null;
            let minuteMatFin = null;
            let heureApremDeb = null;
            let minuteApremDeb = null;
            let heureAprFin = null;
            let minuteAprFin = null;
            if (heureMatinDebut !== null && heureMatinFin !== null) {
                heureMatDeb = parseInt(heureMatinDebut[1]);
                minuteMatDeb = parseInt(heureMatinDebut[2]);
                heureMatFin = parseInt(heureMatinFin[1]);
                minuteMatFin = parseInt(heureMatinFin[2]);
            }
            if (heureApremDebut !== null && heureApremFin !== null) {
                heureApremDeb = parseInt(heureApremDebut[1]);
                minuteApremDeb = parseInt(heureApremDebut[2]);
                heureAprFin = parseInt(heureApremFin[1]);
                minuteAprFin = parseInt(heureApremFin[2]);
            }
            let postValueTravaux = {
                'commentaire': commentaire.current.value,
                'heureMatDeb': heureMatDeb,
                'minuteMatDeb': minuteMatDeb,
                'heureMatFin': heureMatFin,
                'minuteMatFin': minuteMatFin,
                'heureApremDeb': heureApremDeb,
                'minuteApremDeb': minuteApremDeb,
                'heureApremFin': heureAprFin,
                'minuteApremFin': minuteAprFin,
            };
            if (props.saisiePar) {
                axiosInstance.patch(`/releve-heure-v2/travailSalarie/${props.travailSalarie._id}`, postValueTravaux)
                    .then((result) => {
                        customToast.success("Les heures ont été modifié avec succès");
                        props.modifierHeure(result.data);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
            }
            else {
                axiosInstance.patch(`/releve-heure-v2/travailSalarie/me/${props.travailSalarie._id}`, postValueTravaux)
                    .then((result) => {
                        customToast.success("Les heures ont été modifié avec succès");
                        props.modifierHeure(result.data);
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
            }
        }
        else {
            customToast.error("Veuillez compléter les heures du salarié");
            setButtonDisabled(false);
        }
    };

    return (
        <div className="modal-content modifier-form" style={{ display: "flex", flexDirection: "column" }}>
            <h3 className="modal-title">Modifier le relevé d'heure de {props.travailSalarie.salarie.prenom} {props.travailSalarie.salarie.nom} le {format(new Date(props.travailSalarie.date_jour), "dd/MM/yyyy")}</h3>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <div className="modifyHeure-container">
                    <div className="heureModify-inputLabel">
                        <label className="default-label" htmlFor="select-heure-matinDeb">Matin</label>
                        <div className="heureMatin-modify">
                            <TimePicker name='select-horaire-matinDeb' value={horaireMatinDeb} allowDelete onChange={(dateString) => sethoraireMatinDeb(dateString)} />
                            <p>-</p>
                            <TimePicker name='select-horaire-matinFin' value={horaireMatinFin} allowDelete onChange={(dateString) => sethoraireMatinFin(dateString)} />
                        </div>
                    </div>
                    <div className="heureModify-inputLabel">
                        <label className="default-label" htmlFor="select-heure-apremDeb">Après-midi</label>
                        <div className="heureAprem-modify">
                            <TimePicker name='select-horaire-apremDeb' value={horaireApremDeb} allowDelete onChange={(dateString) => sethoraireApremDeb(dateString)} />
                            <p>-</p>
                            <TimePicker name='select-horaire-apremFin' value={horaireApremFin} allowDelete onChange={(dateString) => setHoraireApremFin(dateString)} />
                        </div>
                    </div>
                    <div className="heureModify-total">
                        <label className="default-label" htmlFor="select-heure-total">Total</label>
                        <div className="heureTotal-modify">
                            <TimePicker name='select-horaire-Total' value={totalHeure} disabled />
                        </div>
                    </div>
                </div>
                <label className="default-label">Commentaire</label>
                <input ref={commentaire} type="text" className='default-input commentaire-input' defaultValue={props.travailSalarie.commentaire} placeholder="Commentaire" />
                <div className="button-container" style={{ flex: 1, alignItems: "flex-end" }} >
                    <button disabled={isButtonDisabled} className="btn btn-secondary fw medium-btn mgR-m1" onClick={() => close()}>Annuler</button>
                    <button disabled={isButtonDisabled} className="btn btn-primary fw medium-btn" onClick={() => onModifySalarie()}>Valider</button>
                </div>
            </div>
        </div>
    );
};

export default ModifierSalarieHeureV2;
