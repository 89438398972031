import { format } from 'date-fns';
import React from 'react';
import EditIcon from './icon/EditIcon';
import SupprimerIcon from './icon/SupprimerIcon';
import differenceInYears from 'date-fns/differenceInYears';
import { Icon } from '@iconify/react';
import { addYears } from 'date-fns/esm';
import { showError } from '../utils/ErrorHelper';
import axiosInstance from '../utils/axiosInstance';
import ProcessusDocumentDotMenu from './ProcessusDocumentDotMenu';

const ValidationProcessus = (props) => {

    const getAnalyseCycleTitle = () => {
        if (props.processus.firstValidation && props.processus.secondValidation && props.processus.thirdValidation) {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon className='icon iconGreen mgR-s2' icon="material-symbols:check" />
                    <h2 className='section-title'>Analyse du processus terminer et valider</h2>
                </div>
            );
        }
        else {
            let premiereAnalyse = props.processus.validationTest || props.processus.firstValidation || props.processus.secondValidation || props.processus.thirdValidation;
            if (premiereAnalyse) {
                const datePremiereAnalyse = new Date(premiereAnalyse.dateValidation);
                if (differenceInYears(new Date(), datePremiereAnalyse) > 0) {
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon className='icon iconRed mgR-s2' icon="fluent:prohibited-12-regular" />
                            <h2 className='section-title'>Analyse du processus terminer sans validation</h2>
                        </div>
                    );
                }
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon className='icon iconYellow mgR-s2' icon="mdi:progress-check" />
                        <h2 className='section-title'>Cycle d'analyse du processus débuté le {format(datePremiereAnalyse, "dd/MM/yyyy")}, fin prévue le {format(addYears(datePremiereAnalyse, 1), "dd/MM/yyyy")}</h2>
                    </div>
                );
            }
            else {
                if (props.processus.controle.length && differenceInYears(new Date(), new Date(props.processus.controle[0].dateValidation))) {
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon className='icon iconRed mgR-s2' icon="fluent:prohibited-12-regular" />
                            <h2 className='section-title'>Analyse du processus terminer sans validation</h2>
                        </div>
                    );
                }
                return <h2 className='section-title mgB-s2'>Analyse du processus en cours</h2>;
            }
        }
    };

    const viewFile = (documentId) => {
        axiosInstance.get(`uploads/documents/${documentId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    return (
        <div className="container-basic mgB-m2">
            <div className='mgB-s2' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {getAnalyseCycleTitle()}
                <button
                    onClick={() => props.openAjouterAnalyse(props.processus)}
                    className="default-button default-button-blue"
                >
                    + Analyse
                </button>
            </div>
            <table className="default-table">
                <thead>
                    <tr>
                        <th>Chantier</th>
                        <th>Date</th>
                        <th>Analyse (SA/F/RC)</th>
                        <th>Type de validation</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {props.processus.validationTest !== null &&
                        <tr>
                            <td>({props.processus.validationTest.validationChantier.codeChantier}) {props.processus.validationTest.validationChantier._id.libelle_lieu}</td>
                            <td>{format(new Date(props.processus.validationTest.dateValidation), "dd/MM/yyyy")}</td>
                            <td>{`${props.processus.validationTest.concentrationFibre.sa}  /  ${props.processus.validationTest.concentrationFibre.f}  /  ${props.processus.validationTest.concentrationFibre.rc}`}</td>
                            <td>Chantier test</td>
                            <td>
                                <div className="table-buton-container">
                                    <button onClick={() => props.openModifierAnalyse({ typeValidation: "validationTest", analyse: props.processus.validationTest, processus: props.processus })} className='table-modify-button'><EditIcon /></button>
                                    {/* {props.processus.validationTest.hasOwnProperty("document") && props.processus.validationTest.document !== null &&
                                        <button onClick={() => viewFile(props.processus.validationTest.document)} className='table-archive-button'><Icon className="eyeDocumentSvg" icon="ph:file-bold" /></button>
                                    } */}
                                    <button onClick={() => props.openSupprimerAnalyse({ typeValidation: "validationTest", analyse: props.processus.validationTest, analyseId: props.processus._id })} className='table-delete-button'><SupprimerIcon /></button>
                                    <ProcessusDocumentDotMenu
                                        document={props.processus.validationTest.document}
                                        typeValidation={"validationTest"}
                                        analyse={props.processus.validationTest}
                                        analyseId={props.processus._id}
                                        processus={props.processus}
                                        openAjouterDocumentAnalyse={props.openAjouterDocumentAnalyse}
                                        openSupprimerDocumentAnalyse={props.openSupprimerDocumentAnalyse}
                                    />
                                </div>
                            </td>
                        </tr>
                    }
                    {props.processus.firstValidation !== null &&
                        <tr>
                            <td>({props.processus.firstValidation.validationChantier.codeChantier}) {props.processus.firstValidation.validationChantier._id.libelle_lieu}</td>
                            <td>{format(new Date(props.processus.firstValidation.dateValidation), "dd/MM/yyyy")}</td>
                            <td>{`${props.processus.firstValidation.concentrationFibre.sa}  /  ${props.processus.firstValidation.concentrationFibre.f}  /  ${props.processus.firstValidation.concentrationFibre.rc}`}</td>
                            <td>Validation 1</td>
                            <td>
                                <div className="table-buton-container">
                                    <button onClick={() => props.openModifierAnalyse({ typeValidation: "firstValidation", analyse: props.processus.firstValidation, processus: props.processus })} className='table-modify-button'><EditIcon /></button>
                                    {/* {props.processus.firstValidation.hasOwnProperty("document") && props.processus.firstValidation.document !== null &&
                                        <button onClick={() => viewFile(props.processus.firstValidation.document)} className='table-archive-button'><Icon className="eyeDocumentSvg" icon="ph:file-bold" /></button>
                                    } */}
                                    <button onClick={() => props.openSupprimerAnalyse({ typeValidation: "firstValidation", analyse: props.processus.firstValidation, analyseId: props.processus._id })} className='table-delete-button'><SupprimerIcon /></button>
                                    <ProcessusDocumentDotMenu
                                        document={props.processus.firstValidation.document}
                                        typeValidation={"firstValidation"}
                                        analyse={props.processus.firstValidation}
                                        analyseId={props.processus._id}
                                        processus={props.processus}
                                        openAjouterDocumentAnalyse={props.openAjouterDocumentAnalyse}
                                        openSupprimerDocumentAnalyse={props.openSupprimerDocumentAnalyse}
                                    />
                                </div>
                            </td>
                        </tr>
                    }
                    {props.processus.secondValidation !== null &&
                        <tr>
                            <td>({props.processus.secondValidation.validationChantier.codeChantier}) {props.processus.secondValidation.validationChantier._id.libelle_lieu}</td>
                            <td>{format(new Date(props.processus.secondValidation.dateValidation), "dd/MM/yyyy")}</td>
                            <td>{`${props.processus.secondValidation.concentrationFibre.sa}  /  ${props.processus.secondValidation.concentrationFibre.f}  /  ${props.processus.secondValidation.concentrationFibre.rc}`}</td>
                            <td>Validation 2</td>
                            <td>
                                <div className="table-buton-container">
                                    <button onClick={() => props.openModifierAnalyse({ typeValidation: "secondValidation", analyse: props.processus.secondValidation, processus: props.processus })} className='table-modify-button'><EditIcon /></button>
                                    {/* {props.processus.secondValidation.hasOwnProperty("document") && props.processus.secondValidation.document !== null &&
                                        <button onClick={() => viewFile(props.processus.secondValidation.document)} className='table-archive-button'><Icon className="eyeDocumentSvg" icon="ph:file-bold" /></button>
                                    } */}
                                    <button onClick={() => props.openSupprimerAnalyse({ typeValidation: "secondValidation", analyse: props.processus.secondValidation, analyseId: props.processus._id })} className='table-delete-button'><SupprimerIcon /></button>
                                    <ProcessusDocumentDotMenu
                                        document={props.processus.secondValidation.document}
                                        typeValidation={"secondValidation"}
                                        analyse={props.processus.secondValidation}
                                        analyseId={props.processus._id}
                                        processus={props.processus}
                                        openAjouterDocumentAnalyse={props.openAjouterDocumentAnalyse}
                                        openSupprimerDocumentAnalyse={props.openSupprimerDocumentAnalyse}
                                    />
                                </div>
                            </td>
                        </tr>
                    }
                    {props.processus.thirdValidation !== null &&
                        <tr>
                            <td>({props.processus.thirdValidation.validationChantier.codeChantier}) {props.processus.thirdValidation.validationChantier._id.libelle_lieu}</td>
                            <td>{format(new Date(props.processus.thirdValidation.dateValidation), "dd/MM/yyyy")}</td>
                            <td>{`${props.processus.thirdValidation.concentrationFibre.sa}  /  ${props.processus.thirdValidation.concentrationFibre.f}  /  ${props.processus.thirdValidation.concentrationFibre.rc}`}</td>
                            <td>Validation 3</td>
                            <td>
                                <div className="table-buton-container">
                                    <button onClick={() => props.openModifierAnalyse({ typeValidation: "thirdValidation", analyse: props.processus.thirdValidation, processus: props.processus })} className='table-modify-button'><EditIcon /></button>
                                    {/* {props.processus.thirdValidation.hasOwnProperty("document") && props.processus.thirdValidation.document !== null &&
                                        <button onClick={() => viewFile(props.processus.thirdValidation.document)} className='table-archive-button'><Icon className="eyeDocumentSvg" icon="ph:file-bold" /></button>
                                    } */}
                                    <button onClick={() => props.openSupprimerAnalyse({ typeValidation: "thirdValidation", analyse: props.processus.thirdValidation, analyseId: props.processus._id })} className='table-delete-button'><SupprimerIcon /></button>
                                    <ProcessusDocumentDotMenu
                                        document={props.processus.thirdValidation.document}
                                        typeValidation={"thirdValidation"}
                                        analyse={props.processus.thirdValidation}
                                        analyseId={props.processus._id}
                                        processus={props.processus}
                                        openAjouterDocumentAnalyse={props.openAjouterDocumentAnalyse}
                                        openSupprimerDocumentAnalyse={props.openSupprimerDocumentAnalyse}
                                    />
                                </div>
                            </td>
                        </tr>
                    }
                    {props.processus.controle.map((controle) => {
                        return (
                            <tr key={controle._id}>
                                <td>({controle.validationChantier.codeChantier}) {controle.validationChantier._id.libelle_lieu}</td>
                                <td>{format(new Date(controle.dateValidation), "dd/MM/yyyy")}</td>
                                <td>{`${controle.concentrationFibre.sa}  /  ${controle.concentrationFibre.f}  /  ${controle.concentrationFibre.rc}`}</td>
                                <td>Contrôle</td>
                                <td>
                                    <div className="table-buton-container">
                                        <button onClick={() => props.openModifierAnalyse({ typeValidation: "controle", analyse: controle, processus: props.processus })} className='table-modify-button'><EditIcon /></button>
                                        {/* {controle.hasOwnProperty("document") && controle.document !== null &&
                                            <button onClick={() => viewFile(controle.document._id)} className='table-archive-button'><Icon className="eyeDocumentSvg" icon="ph:file-bold" /></button>
                                        } */}
                                        <button onClick={() => props.openSupprimerAnalyse({ typeValidation: "controle", analyse: controle, analyseId: props.processus._id })} className='table-delete-button'><SupprimerIcon /></button>
                                        <ProcessusDocumentDotMenu
                                            document={controle.hasOwnProperty("document") && controle.document !== null ? controle.document._id : null}
                                            typeValidation={"controle"}
                                            analyse={controle}
                                            analyseId={props.processus._id}
                                            processus={props.processus}
                                            openAjouterDocumentAnalyse={props.openAjouterDocumentAnalyse}
                                            openSupprimerDocumentAnalyse={props.openSupprimerDocumentAnalyse}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })
                    }
                </tbody>
            </table>
        </div>
    );
};

export default ValidationProcessus



