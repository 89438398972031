import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import { format } from 'date-fns';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, iconExtension, formatBytes } from '../../../utils/GeneralHelper';
import { Icon } from '@iconify/react';

import customToast from '../../../utils/ToastifyHelper';

const ModifierEntretienToEquipementModal = (props) => {
    const controller = new AbortController();

    const close = () => {
        controller.abort();
        props.closeModal();
        props.unrenderForm();
    };




    const formSchema = yup.object().shape({
        dateEntretien: yup.date(),
        dateExpiration: yup.date(),
    });

    const [documentSelected, setDocumentSelected] = useState(null);
    const [supprimerDocInitial, setSupprimerDocInitial] = useState(false);

    // Document
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            setDocumentSelected(acceptedFiles[0]);
            setFieldValue("titreDocument", acceptedFiles[0].path.split(".").slice(0, -1)[0]);
        }
    }, [acceptedFiles]);

    //TODO Onsubmit
    const onSubmit = (values, actions) => {
        let postData = {
            ...values,
            dateFormation: values.dateEntretien !== "" ? values.dateEntretien : null,
            dateExpiration: values.dateExpiration !== "" ? values.dateExpiration : null
        };

        // Si doc supprimer
        if (supprimerDocInitial) {
            //Si nouveau doc
            //Supprimer le doc, upload le new et patch
            if (documentSelected !== null) {


                let documentData = new FormData();
                if (values.titreDocument !== null) {
                    documentData.append("titre", values.titreDocument);
                } else {
                    documentData.append("titre", `document_entretien_${props.entretien.nomEntretien}`);
                }
                documentData.append("document", documentSelected);
                documentData.append("documentSaveRoute", "documentFormationSalarie");
                axiosInstance.patch("/uploads/entretienModify/" + props.entretien.entretienDoc._id, documentData).then((res) => {

                    postData["idDocument"] = res.data._id;
                    axiosInstance.patch(`/entretienEquipement/modifEntretienEquipement/` + props.entretien.idLink, { dateEntretien: postData.dateEntretien, dateExpiration: postData.dateExpiration, idValiditee: props.entretien.idValides, idDoc: postData.idDocument }).then((result2) => {

                        actions.resetForm();
                        close();
                        props.modifyEntretien(result2);
                        customToast.success("L'entretien a été modifié avec succès");
                    }).catch((err) => {
                        setSubmitting(false);
                        showError(err);
                    });

                });


            }
            //Sinon supprimer le doc, patch
            else {
                axiosInstance.delete(`/uploads/deleteDocEntretien/${props.entretien.entretienDoc._id}&${props.entretien.idLink}&${props.entretien.idValides}`).then((res) => {
                    axiosInstance.patch(`/entretienEquipement/modifEntretienEquipement/` + props.entretien.idLink, { dateEntretien: values.dateEntretien, dateExpiration: values.dateExpiration, idValiditee: props.entretien.idValides, idDoc: null }).then((result) => {
                        actions.resetForm();
                        close();
                        props.modifyEntretien(result);
                        customToast.success("L'entretien a été modifié avec succès");
                    }).catch((err) => {
                        setSubmitting(false);
                        showError(err);
                    });
                });

            }
            //si doc pas supprimer
        } else {
            //Si titre modifier
            //patch titre in doc, patch

            if (props.entretien.entretienDoc !== null && props.entretien.entretienDoc.titre !== values.titreDocument) {
                axiosInstance.patch('/documents/' + props.entretien.entretienDoc._id, { titre: values.titreDocument }).then((res) => {

                    axiosInstance.patch(`/entretienEquipement/modifEntretienEquipement/` + props.entretien.idLink, { dateEntretien: values.dateEntretien, dateExpiration: values.dateExpiration, idValiditee: props.entretien.idValides, idDoc: res.data._id }).then((result) => {
                        actions.resetForm();
                        close();
                        props.modifyEntretien(result);
                        customToast.success("L'entretien a été modifié avec succès");
                    }).catch((err) => {
                        setSubmitting(false);
                        showError(err);
                    });
                });
            }

            else {
                if (documentSelected !== null) {
                    let documentData = new FormData();
                    if (values.titreDocument !== "") {
                        documentData.append("titre", values.titreDocument);
                    }
                    else {
                        documentData.append("titre", `documentFormation_${values.dateFormation}_${props.salarie.nom}-${props.salarie.prenom}`);
                    }
                    documentData.append("document", documentSelected);
                    documentData.append("documentSaveRoute", "documentFormationSalarie");
                    console.log(documentData);
                    axiosInstance.post("/uploads", documentData).then((result1) => {
                        postData["idDocument"] = result1.data.documentId;
                        console.log(result1.data.documentId);
                        axiosInstance.patch(`/entretienEquipement/modifEntretienEquipement/` + props.entretien.idLink, { dateEntretien: postData.dateEntretien, dateExpiration: postData.dateExpiration, idValiditee: props.entretien.idValides, idDoc: postData.idDocument }).then((result2) => {
                            actions.resetForm();
                            close();
                            props.modifyEntretien(result2);
                            customToast.success("L'entretien a été modifié avec succès");
                        }).catch((err) => {
                            setSubmitting(false);
                            showError(err);
                        });
                    }).catch((err) => {
                        setSubmitting(false);
                        showError(err);
                    });
                } else {
                    axiosInstance.patch(`/entretienEquipement/modifEntretienEquipement/` + props.entretien.idLink, { dateEntretien: values.dateEntretien, dateExpiration: values.dateExpiration, idValiditee: props.entretien.idValides }).then((result) => {
                        actions.resetForm();
                        close();
                        props.modifyEntretien(result);
                        customToast.success("L'entretien a été modifié avec succès");
                    }).catch((err) => {
                        setSubmitting(false);
                        showError(err);
                    });
                }
            }
        }




    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setSubmitting } = useFormik({
        initialValues: {
            dateEntretien: props.entretien.dateEntretien !== null ? format(new Date(props.entretien.dateEntretien), "yyyy-MM-dd") : "",
            dateExpiration: props.entretien.dateExpiration !== null ? format(new Date(props.entretien.dateExpiration), "yyyy-MM-dd") : "",
            titreDocument: props.entretien.hasOwnProperty("entretienDoc") && props.entretien.entretienDoc !== null ? props.entretien.entretienDoc.titre : ""

        },
        validationSchema: formSchema,
        onSubmit
    });


    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Modifier un entretien</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='nomFormation' className='form-label'>Nom de la formation</label>
                    <input style={{ cursor: "pointer" }} name='nomEntretien' className={`new-default-input${errors.nomEntretien && touched.nomEntretien ? " input-error" : ""}`} type="text" value={props.entretien.nomEntretien} readOnly={true} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateEntretien' className='form-label'>Date de l'entretien<span className='required-field'>*</span></label>
                    <input name='dateEntretien' className={`new-default-input${errors.dateEntretien && touched.dateEntretien ? " input-error" : ""}`} type="date" value={values.dateEntretien} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateExpiration' className='form-label'>Date d'expiration de l'entretien<span className='required-field'>*</span></label>
                    <input name='dateExpiration' className={`new-default-input${errors.dateExpiration && touched.dateExpiration ? " input-error" : ""}`} type="date" value={values.dateExpiration} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input' style={{ flex: 1 }}>
                    <label htmlFor='titreDocument' className='form-label'>Document entretien</label>
                    <div className='mgT-s2'>
                        {documentSelected ?
                            <div className="fileItem">
                                {iconExtension(documentSelected.path)}
                                <input style={{ marginTop: 0 }} name='titreDocument' className={`new-default-input${errors.titreDocument && touched.titreDocument ? " input-error" : ""}`} type="text" value={values.titreDocument} placeholder='Titre du document' onChange={handleChange} onBlur={handleBlur} />
                                <p className='mgL-s2 mgR-s3' style={{ whiteSpace: "nowrap" }}>.{documentSelected.path.split(".")[documentSelected.path.split(".").length - 1]} - {formatBytes(documentSelected.size)}</p>
                                <Icon onClick={() => { setDocumentSelected(null); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                {errors.titreDocument && touched.titreDocument && <p className="form-error-message">{errors.titreDocument}</p>}
                            </div>
                            :
                            !supprimerDocInitial && props.entretien.entretienDoc !== null ?
                                <div className="fileItem">
                                    {iconExtension(props.entretien.entretienDoc.file.filename)}
                                    <input style={{ marginTop: 0 }} name='titreDocument' className={`new-default-input${errors.titreDocument && touched.titreDocument ? " input-error" : ""}`} type="text" value={values.titreDocument} placeholder='Titre du document' onChange={handleChange} onBlur={handleBlur} />
                                    <p className='mgL-s2 mgR-s3' style={{ whiteSpace: "nowrap" }}>.{props.entretien.entretienDoc.file.filename.split(".")[props.entretien.entretienDoc.file.filename.split(".").length - 1]} - {formatBytes(props.entretien.entretienDoc.file.length)}</p>
                                    <Icon onClick={() => { setSupprimerDocInitial(true); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                </div>
                                :
                                <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                    <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                </div>
                        }
                    </div>
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier l'entretien" />
            </form>
        </div>
    );
};

export default ModifierEntretienToEquipementModal;