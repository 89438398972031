import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/GeneralHelper';
import Select from 'react-select';

const AjouterDocumentsSalarieForms = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [typeDocumentSelected, setTypeDocumentSelected] = useState();

    const typeDocumentOptions = props.typeDocumentSuivi.map((typeDoc) => {
        return { value: typeDoc._id, label: typeDoc.libelleGestion };
    });

    const onChangeTypeDoc = (opt) => {
        setTypeDocumentSelected(opt);
        if (opt !== null) {

        }
    };

    return (
        <div className='modal-content ajouter-form'>
            <h3 className='modal-title'>Ajouter un document à {capitalizeFirstLetter(props.salarieData.prenom)} {capitalizeFirstLetter(props.salarieData.nom)}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <label className='ajouter-form-label default-label' htmlFor="typeSuivie">Choisisez le type de document</label>
                <Select
                    options={typeDocumentOptions}
                    className="formSelect-search-container formSelect-search-container-full mgB-s2"
                    classNamePrefix="formSelect-search"
                    onChange={(opt) => onChangeTypeDoc(opt)}
                    placeholder="Sélectionner un type de document"
                    isSearchable={true}
                    value={typeDocumentSelected || ''}
                />

            </div>
        </div>
    );
};

export default AjouterDocumentsSalarieForms;