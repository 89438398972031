import React, { useEffect } from 'react';

const Modal = (props) => {
    useEffect(() => {
        if (props.modalClass !== "modal") {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
    }, [props.modalClass]);


    return (
        <div className={props.modalClass}>
            {props.children}
        </div>
    );
};

export default Modal;
