import React, { useState } from 'react';
import axiosInstance from '../../../utils/axiosInstance';
import ReactTooltip from "react-tooltip";
import { Icon } from '@iconify/react';
import axios from 'axios';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';
import { useFormik } from 'formik';
import * as yup from "yup";



const AjouterSalarieForm = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    // form schema
    const formSchema = yup.object().shape({
        nom: yup.string().required("Veuillez saisir le nom du salarié"),
        prenom: yup.string().required("Veuillez saisir le prénom du salarié"),
        statut: yup.string().required("Aucun statut sélectionné pour le salarié"),
        secteur: yup.string().required("Aucun secteur sélectionné pour le salarié"),
        telephone: yup.string().matches(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/, "Veuillez saisir un numéro de téléphone correct "),
        residence: yup.string().nullable().notRequired(),
        Ville: yup.string().nullable().notRequired(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        numeroPermis: yup.string().nullable().notRequired(),
        matricule: yup.string().required("Veuillez saisir le matricule du salarié"),
        motDePasse: yup
            .string()
            .required('Veuillez saisir votre nouveau mot de passe')
            .min(8, 'Votre mot de passe doit faire au minimum 8 caractères')
            .max(20, 'Votre mot de passe ne peut pas excedé 20 caractères')
            .matches(
                /^(?=.*[A-Z])/,
                'Votre mot de passe doit contenir au moins une lettre majuscule'
            )
            .matches(
                /^(?=.*\d)/,
                'Votre mot de passe doit contenir au moins un chiffre'
            ),
    },
        ["codePostal", "codePostal"]
    );

    // handle submit
    const onSubmit = (values, actions) => {
        const postData = {
            nom: values.nom,
            prenom: values.prenom,
            statut: values.statut,
            secteur: values.secteur,
            telephone: values.telephone,
            login_matricule: values.matricule,
            mdp: values.motDePasse,
            adresse: values.residence,
            codePostal: values.codePostal.length ? values.codePostal : null,
            ville: values.ville,
            numeroPermis: values.numeroPermis
        };

        let salarie = {
            user: {
                nom: postData.nom,
                prenom: postData['prenom'],
                login_matricule: postData.login_matricule,
                telephone: postData["telephone"],
                residence: {
                    adresse: postData.adresse,
                    codePostal: postData.codePostal,
                    ville: postData.ville
                }
            },
            userRole: postData.statut,
            secteur: postData.secteur,
        };

        axiosInstance.post("/utilisateurs", postData).then((result) => {
            const baseURL = process.env.REACT_APP_JUSTEAU_API_URL;
            axios.get(baseURL + '/auth', { headers: { 'x-auth-token': result.data.token } }).then((res) => {
                customToast.success("Salarié ajouté avec succès");
                salarie.user._id = res.data._id;
                salarie.user.historiqueStatut = res.data.historiqueStatut;
                salarie.user.userRole = res.data.userRole.statut;
                actions.resetForm();
                props.ajouterSalarie(salarie);
                close();
            }).catch((error) => {
                setSubmitting(false);
                showError(error);
            });
        }).catch((err) => {
            setSubmitting(false);
            showError(err);
        });
    };

    // Handle formm
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nom: "",
            prenom: "",
            statut: "Administration",
            secteur: "Travaux publics",
            telephone: "",
            matricule: "",
            residence: "",
            ville: "",
            codePostal: "",
            motDePasse: "",
            numeroPermis: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const generatePassword = () => {
        const list = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";
        var res = "";
        for (var i = 0; i < 8; i++) {
            var rnd = Math.floor(Math.random() * list.length);
            res = res + list.charAt(rnd);
        }
        return res;
    };

    const getGeneratePassword = () => {
        let password = generatePassword();
        while (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/, "i")) {
            password = generatePassword();
        }
        setFieldValue("motDePasse", password);
    };

    return (
        <div className="modal-content default-form">
            <h2 className="new-modal-title">Ajouter un salarié</h2>
            <button className='modal-close-button' onClick={() => close()}><Icon className='close-icon' icon="ic:round-close" /></button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                <div className="two-column-form">
                    <div className='label-and-input'>
                        <label htmlFor='nom' className='form-label'>Nom<span className='required-field'>*</span></label>
                        <input name='nom' className={`new-default-input${errors.nom && touched.nom ? " input-error" : ""}`} type="text" placeholder="Nom" value={values.nom} onChange={handleChange} onBlur={handleBlur} />
                        {errors.nom && touched.nom && <p className="form-error-message">{errors.nom}</p>}
                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='prenom' className='form-label'>Prénom<span className='required-field'>*</span></label>
                        <input name='prenom' className={`new-default-input${errors.prenom && touched.prenom ? " input-error" : ""}`} type="text" placeholder="Prénom" value={values.prenom} onChange={handleChange} onBlur={handleBlur} />
                        {errors.prenom && touched.prenom && <p className="form-error-message">{errors.prenom}</p>}
                    </div>
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor='statut' >Statut<span className='required-field'>*</span></label>
                    <select name="statut" className={`new-default-input${errors.statut && touched.statut ? " input-error" : ""}`} value={values.statut} onChange={handleChange} onBlur={handleBlur} >
                        <option value="Administration">Administration</option>
                        <option value="Chauffeur">Chauffeur</option>
                        <option value="Chef de chantier">Chef de chantier</option>
                        <option value="Conducteur de travaux">Conducteur de travaux</option>
                        <option value="Dispatcher">Dispatcher</option>
                        <option value="Encadrant de chantier">Encadrant de chantier</option>
                        <option value="Encadrant technique">Encadrant technique</option>
                        <option value="Intérimaire">Intérimaire</option>
                        <option value="Opérateur">Opérateur</option>
                        <option value="Ouvrier">Ouvrier</option>
                    </select>
                    {errors.statut && touched.statut && <p className="form-error-message">{errors.statut}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='secteur' className='form-label'>Secteur<span className='required-field'>*</span></label>
                    <select name="secteur" className={`new-default-input${errors.secteur && touched.secteur ? " input-error" : ""}`} value={values.secteur} onChange={handleChange} onBlur={handleBlur} >
                        <option value="Travaux publics">Travaux publics</option>
                        <option value="Gros oeuvres">Gros oeuvres</option>
                        <option value="3D">3D</option>
                        <option value="Transport">Transport</option>
                        <option value="Atelier">Atelier</option>
                    </select>
                    {errors.secteur && touched.secteur && <p className="form-error-message">{errors.secteur}</p>}
                </div>
                {values.statut === "Chauffeur" &&
                    <div className='label-and-input'>
                        <label htmlFor='numeroPermis' className='form-label'>Numéro de permis</label>
                        <input name='numeroPermis' className={`new-default-input${errors.numeroPermis && touched.numeroPermis ? " input-error" : ""}`} type="text" placeholder="Numéro de permis" value={values.numeroPermis} onChange={handleChange} onBlur={handleBlur} />
                        {errors.numeroPermis && touched.numeroPermis && <p className="form-error-message">{errors.numeroPermis}</p>}
                    </div>
                }
                <div className='label-and-input'>
                    <label htmlFor='telephone' className='form-label'>Téléphone</label>
                    <input name='telephone' className={`new-default-input${errors.telephone && touched.telephone ? " input-error" : ""}`} type="text" placeholder="Téléphone" value={values.telephone} onChange={handleChange} onBlur={handleBlur} />
                    {errors.telephone && touched.telephone && <p className="form-error-message">{errors.telephone}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="residence">Adresse</label>
                    <input className={`new-default-input${errors.residence && touched.residence ? " input-error" : ""}`} name="residence" type="text" placeholder="residence" value={values.residence} onChange={handleChange} onBlur={handleBlur} />
                    {errors.residence && touched.residence && <p className="form-error-message">{errors.residence}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="codePostal">Code postal</label>
                    <input className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} name="codePostal" type="text" placeholder="codePostal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                    {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="ville">Ville</label>
                    <input className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} name="ville" type="text" placeholder="ville" value={values.ville} onChange={handleChange} onBlur={handleBlur} />
                    {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label' htmlFor="matricule">Matricule<span className='required-field'>*</span></label>
                    <input className={`new-default-input${errors.matricule && touched.matricule ? " input-error" : ""}`} name="matricule" type="text" placeholder="Matricule" value={values.matricule} onChange={handleChange} onBlur={handleBlur} />
                    {errors.matricule && touched.matricule && <p className="form-error-message">{errors.matricule}</p>}
                </div>
                <div className='label-and-input last-form-container'>
                    <div className="motDePasse-iconAndText">
                        <label className='form-label' htmlFor="motDePasse">Mot de passe<span className='required-field'>*</span></label>
                        <Icon icon="ep:question-filled" data-tip data-for="newSalariePassword" />
                    </div>
                    <ReactTooltip id="newSalariePassword" place="bottom" effect="solid">
                        Le mot de passe doit contenir de 8 a 15 caractères, au moins 1 lettre en majuscule et au moins 1 chiffre
                    </ReactTooltip>
                    <div className="mdpInput-button-container">
                        <input value={values.motDePasse} className={`new-default-input${errors.motDePasse && touched.motDePasse ? " input-error" : ""}`} name="motDePasse" type="text" placeholder="Mot de passe" onChange={handleChange} onBlur={handleBlur} />
                        <button type="button" onClick={() => getGeneratePassword()} disabled={isSubmitting} className='generate-mdp-button mgT-s2' data-tip data-for="genertateBtn" ><Icon icon="si-glyph:arrow-reload" /></button>
                        <ReactTooltip id="genertateBtn" place="bottom" effect="solid">
                            Génerer un mot de passe
                        </ReactTooltip>
                    </div>
                    {errors.motDePasse && touched.motDePasse && <p className="form-error-message">{errors.motDePasse}</p>}
                </div>

                <input disabled={isSubmitting} className={`btn btn-primary medium-btn${isSubmitting ? " disabled-button" : ""}`} type="submit" value="Ajouter" />
            </form>
        </div>
    );
};

export default AjouterSalarieForm;
