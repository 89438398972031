import React, { useState } from 'react';
import { Menu, MenuItem } from "@material-ui/core";
import { Icon } from '@iconify/react';
import SupprimerIcon from './icon/SupprimerIcon';
import fileDownload from 'js-file-download';
import axiosInstance from '../utils/axiosInstance';
import customToast from '../utils/ToastifyHelper';
import { showError } from '../utils/ErrorHelper';

// const onDeleteFile = (documentId) => (actionCreators.deleteDocument(documentId))

const ReleveHeureDotMenu = (props) => {
    const [menuPosition, setMenuPosition] = useState(null);
    const [className, setClassName] = useState("threeDot-button");

    const dotOpenAction = (event) => {
        setClassName("threeDot-button hovered");
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition({
            top: event.pageY,
            left: event.pageX
        });
    };

    // const handleItemClick = (event) => {
    //   setMenuPosition(null);
    // };

    const downloadFile = () => {
        setMenuPosition(null);
        axiosInstance.get(`uploads/documents/${props.documentId}`, {
            responseType: 'blob',
        }).then((result) => {
            fileDownload(result.data, props.fileName);
        }).catch((err) => {
            showError(err);
        });
    };

    const viewFile = () => {
        setMenuPosition(null);
        if (props.documentSaveRoute === "documentsSalarie" || props.documentSaveRoute === "documentsEPC") {
            axiosInstance.get(`uploads/documents/${props.documentId}`, {
                responseType: 'blob',
            }).then((result) => {
                let fileURL = URL.createObjectURL(result.data);
                window.open(fileURL);
            }).catch((err) => {
                showError(err);
            });
        }
        else {
            axiosInstance.get(`uploads/documentsPublics/${props.documentId}`, {
                responseType: 'blob',
            }).then((result) => {
                let fileURL = URL.createObjectURL(result.data);
                window.open(fileURL);
            }).catch((err) => {
                showError(err);
            });
        }
    };

    const deleteFile = () => {
        setMenuPosition(null);
        props.openSupprimerDocument(props.documentId);
        // axiosInstance.delete(`uploads/documentsPublics/${props.documentId}`).then((result) => {
        //     dispatch(onDeleteFile(props.documentId))
        // }).catch((err) => {
        //     console.log(err)
        // });
    };

    const editFile = () => {
        setMenuPosition(null);
        props.openModifierDocument(props.document);
        // axiosInstance.delete(`uploads/documentsPublics/${props.documentId}`).then((result) => {
        //     dispatch(onDeleteFile(props.documentId))
        // }).catch((err) => {
        //     console.log(err)
        // });
    };

    const replaceFile = () => {
        setMenuPosition(null);
        props.openRemplacerDocument(props.documentId);
    };

    const uploadFile = () => {
        setMenuPosition(null);
        props.openUploadDocument();
    };

    return (
        <>
            <button className={className} disabled={props.locked} onClick={(e) => dotOpenAction(e)}><Icon icon="bi:three-dots" /></button>

            {props.documentId === undefined
                ? <Menu open={!!menuPosition} onClose={() => { setMenuPosition(null); setClassName("threeDot-button"); }} anchorReference="anchorPosition" anchorPosition={menuPosition}>

                    <MenuItem onClick={() => { uploadFile(); setClassName("threeDot-button"); }}><Icon className="telechargerDocumentSvg" icon="ic:round-plus" /> <p className='dotMenuLabel'>Ajouter un document</p></MenuItem>

                    {/* <MenuItem onClick={handleItemClick}>Button 1</MenuItem>
                <MenuItem onClick={handleItemClick}>Button 2</MenuItem>
                <NestedMenuItem label="Button 3" parentMenuOpen={!!menuPosition} onClick={handleItemClick}>
                    <MenuItem onClick={handleItemClick}>Sub-Button 1</MenuItem>
                    <MenuItem onClick={handleItemClick}>Sub-Button 2</MenuItem>
                    <NestedMenuItem label="Sub-Button 3" parentMenuOpen={!!menuPosition} onClick={handleItemClick}>
                        <MenuItem onClick={handleItemClick}>Sub-Sub-Button 1</MenuItem>
                        <MenuItem onClick={handleItemClick}>Sub-Sub-Button 2</MenuItem>
                    </NestedMenuItem>
                </NestedMenuItem> */}
                </Menu>

                : <Menu open={!!menuPosition} onClose={() => { setMenuPosition(null); setClassName("threeDot-button"); }} anchorReference="anchorPosition" anchorPosition={menuPosition}>


                    {props.extension !== "xls" && props.extension !== "xlsx" && props.extension !== "csv" && <MenuItem onClick={() => { viewFile(); setClassName("threeDot-button"); }}><Icon className="eyeDocumentSvg" icon="akar-icons:eye" /> <p className='dotMenuLabel'>Voir le document</p></MenuItem>}
                    {props.documentSaveRoute !== "documentsEPC" && <MenuItem onClick={() => { editFile(); setClassName("threeDot-button"); }}><Icon className="eyeDocumentSvg" icon="ci:edit" /> <p className='dotMenuLabel'>Éditer le document</p></MenuItem>}
                    <MenuItem onClick={() => { downloadFile(); setClassName("threeDot-button"); }}><Icon className="telechargerDocumentSvg" icon="eva:download-fill" /> <p className='dotMenuLabel'>Télécharger le document</p></MenuItem>
                    {(props.documentSaveRoute === "documentsSalarie" || props.documentSaveRoute === "documentsEPC") && <MenuItem onClick={() => { replaceFile(); setClassName("threeDot-button"); }}><Icon className="eyeDocumentSvg" icon="eva:upload-fill" /> <p className='dotMenuLabel'>Remplacer le document</p></MenuItem>}
                    <MenuItem onClick={() => { deleteFile(); setClassName("threeDot-button"); }}><SupprimerIcon className="supprimerDocumentSvg" /> <p className='dotMenuLabel'>Supprimer le document</p></MenuItem>

                </Menu>

            }


        </>
    );
};

export default ReleveHeureDotMenu;