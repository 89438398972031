import { format } from 'date-fns';
import React from 'react';

const ArchiveSalarieDetail = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    return (
        <div className="modal-content supprimerForm">
            <h3 className='modal-title'>Historique de {props.salarie.user._id.nom} {props.salarie.user._id.prenom}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <hr />
            <ul className="resumeSalarieHeure">
                {props.salarie.user._id.historiqueStatut.map((historique) => {
                    return <li key={historique.dateDebutStatut}>{format(new Date(historique.dateDebutStatut), "dd/MM/yyyy")} -&gt; {historique.statut}</li>;
                })}
            </ul>

        </div>
    );
};

export default ArchiveSalarieDetail;