import customToast from "./ToastifyHelper";

export const showError = (error) => {
    if (error.message === "timeout") {
        customToast.error("Le délai de la requête à expirer, veuillez réessayer, si le problème persiste, contacter un administrateur.");
    }
    else if (error.response) {
        if (error.status === 404) {
            customToast.error("La ressource demandée n'a pas été trouvée.");
        }
        else if (error.response.data.hasOwnProperty("msg")) {
            customToast.error(error.response.data.msg);
        }
        else if (error.response.data.hasOwnProperty("errors") && error.response.data.errors.length) {
            if (error.response.data.errors[0].hasOwnProperty("msg")) {
                customToast.error(error.response.data.errors[0].msg);
            }
            else {
                console.log(error.response.data.errors[0]);
            }
        }
        else {
            console.log(JSON.stringify(error));
        }
    }
    else if (error.request) {
        console.log(error.request);
    }
    else {
        customToast.error("Erreur, si le problème persiste, contacter un administrateur");
        console.log('Error', error.message);
    }
};