import React, { useEffect, useState } from "react";
import SearchInput from "../../../../components/ui/SearchInput";
import Modal from "../../../../components/Modal";
import AjouterZoneAmiante from "./AjouterZoneAmiante";

import BasicTableRender from "../../../../components/tables/Basic/BasicTableRender";
import { getZonesChantiersAmianteColumns } from "../../../../components/tables/Basic/BasicTableColumns";
import axiosInstance from "../../../../utils/axiosInstance";
import { showError } from "../../../../utils/ErrorHelper";
import ModifierZoneAmiante from "./ModifierZoneAmiante";
import SupprimerModalForm from "../../../../components/forms/SupprimerModalForm";

const ProfilChantierAmiante_Zones = (props) => {

    const [searchZoneBarContent, setSearchZoneBarContent] = useState(null);
    const onChangeSearchZone = (e) => {
        setSearchZoneBarContent(e.target.value);
    };
    const [filterTable, setFilterTable] = useState(false);
    const [zonesChantier, setZonesChantier] = useState([]);

    useEffect(() => {
        if (searchZoneBarContent !== null && searchZoneBarContent !== "") {
            let timer = setTimeout(() => {
                setFilterTable(true);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setFilterTable(true);
        }
    }, [zonesChantier, searchZoneBarContent]);

    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalZoneSelected, setModalZoneSelected] = useState({});

    const openAjouterZoneAmianteModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openModifierZoneAmianteModal = (zoneData) => {
        setModalZoneSelected(zoneData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editZoneAmiante = (updatedZone) => {
        // setFormationsSuivis(formationsSuivis.map((formation) => {
        //     if (formation._id === updatedFormation._id) {
        //         return updatedFormation;
        //     }
        //     return formation;
        // }));
        setZonesChantier(
            zonesChantier.map((zone) => {
                if (zone._id === updatedZone._id) {
                    return updatedZone;
                }
                return zone;
            })
        );
    };

    const openSupprimerZoneAmianteModal = (zoneData) => {
        setModalZoneSelected(zoneData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const supprimerZoneAmiante = (zoneId) => {
        setZonesChantier(zonesChantier.filter((zone) => zone._id !== zoneId));
    };

    // load zone
    useEffect(() => {
        // props.progressBarRef.current.continuousStart();
        axiosInstance.get(`/zoneAmiante/${props.chantier._id._id}`).then((result) => {
            // props.progressBarRef.current.complete();
            setZonesChantier(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    return (
        <>
            <div>
                <div className="filterContainer">
                    <SearchInput fullwidth={true} placeholder="Rechercher une zone" searchContent={searchZoneBarContent ? searchZoneBarContent : ""} onChangeSearch={onChangeSearchZone} />
                    <button className='btn btn-primary' onClick={() => openAjouterZoneAmianteModal()}>
                        + Ajouter des zones
                    </button>
                </div>
                <BasicTableRender
                    columns={getZonesChantiersAmianteColumns(props.chantier.dateDebutPrevue !== null ? props.chantier.dateDebutOfficielle !== null ? new Date(props.chantier.dateDebutOfficielle) : new Date(props.chantier.dateDebutPrevue) : new Date(), (zoneData) => openModifierZoneAmianteModal(zoneData), (zoneData) => openSupprimerZoneAmianteModal(zoneData))}
                    tableData={zonesChantier}
                    sortBy={[
                    ]}
                    filterTable={filterTable}
                    setFilterTable={setFilterTable}
                    searchBarContent={searchZoneBarContent}
                />
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter" &&
                    <AjouterZoneAmiante setZonesChantier={setZonesChantier} chantierId={props.chantier._id._id} progressBarRef={props.progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "modifier" &&
                    <ModifierZoneAmiante zoneSelected={modalZoneSelected} editZoneAmiante={(updatedZone) => editZoneAmiante(updatedZone)} setZonesChantier={setZonesChantier} chantierId={props.chantier._id._id} progressBarRef={props.progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "supprimer" &&
                    <SupprimerModalForm supprimerZoneAmiante={(zoneId) => supprimerZoneAmiante(zoneId)} destination="supprimerZoneChantierAmiante" zoneSelected={modalZoneSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>
        </>
    );
};

export default ProfilChantierAmiante_Zones;
