import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import { showError } from '../../../utils/ErrorHelper';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, iconExtension, formatBytes } from '../../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import customToast from '../../../utils/ToastifyHelper';
import { format } from 'date-fns';
import axiosInstance from '../../../utils/axiosInstance';

const ModifierFormationSalarieModal = (props) => {
    const controller = new AbortController();

    const close = () => {
        controller.abort();
        props.closeModal();
        props.unrenderForm();
    };

    const [formationsPossible, setFormationsPossible] = useState([]);
    const [formationSelected, setFormationSelected] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/formationsSuiviSalarie/${props.salarie._id}`).then((result) => {
            setFormationsPossible(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const formSchema = yup.object().shape({
        formationSuiviId: yup.string().required("Veuillez saisir l'id de la formation"),
        dateFormation: yup.date(),
        dateExpiration: yup.date(),
        organismeFormation: yup.string(),
        titreDocument: yup.string()
    });

    const onSubmit = (values, actions) => {
        let postData = {
            ...values,
            dateFormation: values.dateFormation !== "" ? values.dateFormation : null,
            dateExpiration: values.dateExpiration !== "" ? values.dateExpiration : null
        };
        if (supprimerDocInitial) {
            if (documentSelected !== null) {
                // supprimer le doc
                axiosInstance.delete(`/uploads/${props.formationData.formationsValidees.document._id}`).then((res1) => {
                    // supprimer le doc dans la formation salarie
                    axiosInstance.delete(`/salarieFormations/${props.salarie._id}/${props.formationData.formationId}/${props.formationData.formationsValidees._id}/supprimerDocument`).then((res2) => {
                        // copier de creerFormationModal lajout du doc puis ajouter lid dans le patch
                        let documentData = new FormData();
                        if (values.titreDocument !== "") {
                            documentData.append("titre", values.titreDocument);
                        }
                        else {
                            documentData.append("titre", `documentFormation_${values.dateFormation}_${props.salarie.nom}-${props.salarie.prenom}`);
                        }
                        documentData.append("document", documentSelected);
                        documentData.append("documentSaveRoute", "documentFormationSalarie");
                        axiosInstance.post("/uploads", documentData).then((result) => {
                            postData["idDocument"] = result.data.documentId;
                            axiosInstance.patch(`/salarieFormations/${props.salarie._id}/modifierFormation/${props.formationData.formationId}/${props.formationData.formationsValidees._id}`, postData).then((res3) => {
                                props.editFormation(res3.data);
                                close();
                            }).catch((error3) => {
                                setSubmitting(false);
                                showError(error3);
                            });
                        }).catch((err) => {
                            setSubmitting(false);
                            showError(err);
                        });
                    }).catch((err2) => {
                        setSubmitting(false);
                        showError(err2);
                    });
                }).catch((err1) => {
                    setSubmitting(false);
                    showError(err1);
                });
            }
            else {
                // supprimer le doc puis puis patch normal
                axiosInstance.delete(`/uploads/${props.formationData.formationsValidees.document._id}`).then((res1) => {
                    axiosInstance.delete(`/salarieFormations/${props.salarie._id}/${props.formationData.formationId}/${props.formationData.formationsValidees._id}/supprimerDocument`).then((res2) => {
                        axiosInstance.patch(`/salarieFormations/${props.salarie._id}/modifierFormation/${props.formationData.formationId}/${props.formationData.formationsValidees._id}`, postData).then((res3) => {
                            props.editFormation(res3.data);
                            close();
                        }).catch((err3) => {
                            setSubmitting(false);
                            showError(err3);
                        });
                    }).catch((err2) => {
                        setSubmitting(false);
                        showError(err2);
                    });
                }).catch((err1) => {
                    setSubmitting(false);
                    showError(err1);
                });
            }
        }
        else {
            // si doc et titre doc = titre doc
            if (props.formationData.formationsValidees.hasOwnProperty("document") && props.formationData.formationsValidees.document.titre !== values.titreDocument) {
                // modifier le titre directement dans le document
                axiosInstance.patch(`/documents/${props.formationData.formationsValidees.document._id}`, { titre: values.titreDocument }).then((res) => {
                    axiosInstance.patch(`/salarieFormations/${props.salarie._id}/modifierFormation/${props.formationData.formationId}/${props.formationData.formationsValidees._id}`, postData).then((result) => {
                        props.editFormation(result.data);
                        close();
                    }).catch((err) => {
                        setSubmitting(false);
                        showError(err);
                    });
                }).catch((error) => {
                    setSubmitting(false);
                    showError(error);
                });
            }
            else {
                if (documentSelected !== null) {
                    let documentData = new FormData();
                    if (values.titreDocument !== "") {
                        documentData.append("titre", values.titreDocument);
                    }
                    else {
                        documentData.append("titre", `documentFormation_${values.dateFormation}_${props.salarie.nom}-${props.salarie.prenom}`);
                    }
                    documentData.append("document", documentSelected);
                    documentData.append("documentSaveRoute", "documentFormationSalarie");
                    axiosInstance.post("/uploads", documentData).then((res1) => {
                        postData["idDocument"] = res1.data.documentId;
                        console.log(res1.data.documentId);
                        axiosInstance.patch(`/salarieFormations/${props.salarie._id}/modifierFormation/${props.formationData.formationId}/${props.formationData.formationsValidees._id}`, postData).then((res2) => {
                            console.log(res2.data);
                            props.editFormation(res2.data);
                            close();
                        }).catch((err2) => {
                            setSubmitting(false);
                            showError(err2);
                        });
                    }).catch((err1) => {
                        setSubmitting(false);
                        showError(err1);
                    });
                }
                else {
                    axiosInstance.patch(`/salarieFormations/${props.salarie._id}/modifierFormation/${props.formationData.formationId}/${props.formationData.formationsValidees._id}`, postData).then((result) => {
                        console.log(result.data);
                        props.editFormation(result.data);
                        close();
                    }).catch((err) => {
                        setSubmitting(false);
                        showError(err);
                    });
                }
            }
        }
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            formationSuiviId: props.formationData.formationId,
            dateFormation: props.formationData.formationsValidees.hasOwnProperty("dateFormation") && props.formationData.formationsValidees.dateFormation !== null ? format(new Date(props.formationData.formationsValidees.dateFormation), "yyyy-MM-dd") : "",
            dateExpiration: props.formationData.formationsValidees.hasOwnProperty("dateExpiration") && props.formationData.formationsValidees.dateExpiration !== null ? format(new Date(props.formationData.formationsValidees.dateExpiration), "yyyy-MM-dd") : "",
            organismeFormation: props.formationData.formationsValidees.organismeFormation,
            titreDocument: props.formationData.formationsValidees.hasOwnProperty("document") ? props.formationData.formationsValidees.document.titre : ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const [documentSelected, setDocumentSelected] = useState(null);
    const [supprimerDocInitial, setSupprimerDocInitial] = useState(false);

    // Document
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            setDocumentSelected(acceptedFiles[0]);
            setFieldValue("titreDocument", acceptedFiles[0].path.split(".").slice(0, -1)[0]);
        }
    }, [acceptedFiles]);


    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Modifier une formation</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='nomFormation' className='form-label'>Nom de la formation</label>
                    <input style={{ cursor: "pointer" }} name='nomFormation' className={`new-default-input${errors.nomFormation && touched.nomFormation ? " input-error" : ""}`} type="text" value={props.formationData.nomFormation} readOnly={true} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateFormation' className='form-label'>Date de la formation<span className='required-field'>*</span></label>
                    <input name='dateFormation' className={`new-default-input${errors.dateFormation && touched.dateFormation ? " input-error" : ""}`} type="date" value={values.dateFormation} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateExpiration' className='form-label'>Date d'expiration de la formation<span className='required-field'>*</span></label>
                    <input name='dateExpiration' className={`new-default-input${errors.dateExpiration && touched.dateExpiration ? " input-error" : ""}`} type="date" value={values.dateExpiration} onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='organismeFormation' className='form-label'>Organisme de la formation</label>
                    <input name='organismeFormation' className={`new-default-input${errors.organismeFormation && touched.organismeFormation ? " input-error" : ""}`} type="text" value={values.organismeFormation} placeholder='Organisme de la formation' onChange={handleChange} onBlur={handleBlur} />
                </div>
                <div className='label-and-input' style={{ flex: 1 }}>
                    <label htmlFor='titreDocument' className='form-label'>Document formation</label>
                    <div className='mgT-s2'>
                        {documentSelected ?
                            <div className="fileItem">
                                {iconExtension(documentSelected.path)}
                                <input style={{ marginTop: 0 }} name='titreDocument' className={`new-default-input${errors.titreDocument && touched.titreDocument ? " input-error" : ""}`} type="text" value={values.titreDocument} placeholder='Titre du document' onChange={handleChange} onBlur={handleBlur} />
                                <p className='mgL-s2 mgR-s3' style={{ whiteSpace: "nowrap" }}>.{documentSelected.path.split(".")[documentSelected.path.split(".").length - 1]} - {formatBytes(documentSelected.size)}</p>
                                <Icon onClick={() => { setDocumentSelected(null); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                {errors.titreDocument && touched.titreDocument && <p className="form-error-message">{errors.titreDocument}</p>}
                            </div>
                            :
                            !supprimerDocInitial && props.formationData.formationsValidees.hasOwnProperty("document") ?
                                <div className="fileItem">
                                    {iconExtension(props.formationData.formationsValidees.document.file.filename)}
                                    <input style={{ marginTop: 0 }} name='titreDocument' className={`new-default-input${errors.titreDocument && touched.titreDocument ? " input-error" : ""}`} type="text" value={values.titreDocument} placeholder='Titre du document' onChange={handleChange} onBlur={handleBlur} />
                                    <p className='mgL-s2 mgR-s3' style={{ whiteSpace: "nowrap" }}>.{props.formationData.formationsValidees.document.file.filename.split(".")[props.formationData.formationsValidees.document.file.filename.split(".").length - 1]} - {formatBytes(props.formationData.formationsValidees.document.file.length)}</p>
                                    <Icon onClick={() => { setSupprimerDocInitial(true); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                </div>
                                :
                                <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                    <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                </div>
                        }
                    </div>
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier la formation" />
            </form>
        </div>
    );
};

export default ModifierFormationSalarieModal;