import React, { useState } from 'react';
import { useEffect } from 'react';
import TableRender from '../../components/tables/TableRender';
import axiosInstance from '../../utils/axiosInstance';
import customToast from '../../utils/ToastifyHelper';
import { getProcessusColumns } from '../../components/tables/TableColumns';
import Modal from '../../components/Modal';
import AjouterProcessusForm from '../../components/forms/AmianteForms/AjouterProcessusForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import ModifierProcessusForm from '../../components/forms/ModifierProcessusForm';
import ExportProcessus from '../../components/forms/ExportProcessus';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import { useNavigate } from 'react-router-dom';
import { showError } from '../../utils/ErrorHelper';
import { advancedSearch } from '../../utils/GeneralHelper';

const setOptionsProcessus = (nomOption, options) => (actionCreators.setFormOptions(nomOption, options));

const GestionProcessus = () => {
    // const [allProcessus, setAllProcessus] = useState([]);
    const navigate = useNavigate();
    const allProcessus = useSelector(state => state.processus);
    const [showedItems, setShowedItems] = useState([]);
    const [searchProcessus, setSearchProcessus] = useState("");
    const dispatch = useDispatch();

    const loadFormOptions = () => {
        axiosInstance.get("/formOptions?formName=categorieMateriaux")
            .then((result) => {
                if (result.data.length) {
                    dispatch(setOptionsProcessus("categorieMateriaux", { options: result.data[0].options.sort((a, b) => a.localeCompare(b)), id: result.data[0]._id }));
                }
            }).catch((err) => {
                showError(err);
            });

        axiosInstance.get("/formOptions?formName=categorieTechniques")
            .then((result) => {
                if (result.data.length) {
                    dispatch(setOptionsProcessus("categorieTechniques", { options: result.data[0].options.sort((a, b) => a.localeCompare(b)), id: result.data[0]._id }));
                }
            }).catch((err) => {
                showError(err);
            });

        axiosInstance.get("/formOptions?formName=methodeProcessus")
            .then((result) => {
                if (result.data.length) {
                    dispatch(setOptionsProcessus("methodeProcessus", { options: result.data[0].options.sort((a, b) => a.localeCompare(b)), id: result.data[0]._id }));
                }
            }).catch((err) => {
                showError(err);
            });

        axiosInstance.get("/formOptions?formName=outilProcessus")
            .then((result) => {
                if (result.data.length) {
                    dispatch(setOptionsProcessus("outilProcessus", { options: result.data[0].options.sort((a, b) => a.localeCompare(b)), id: result.data[0]._id }));
                }
            }).catch((err) => {
                showError(err);
            });

        axiosInstance.get("/formOptions?formName=protectionCollectiveProcessus")
            .then((result) => {
                if (result.data.length) {
                    dispatch(setOptionsProcessus("protectionCollectiveProcessus", { options: result.data[0].options.sort((a, b) => a.localeCompare(b)), id: result.data[0]._id }));
                }
            }).catch((err) => {
                showError(err);
            });
    };

    useEffect(() => {
        axiosInstance.get("/processusAmiante")
            .then((result) => {
                dispatch(actionCreators.setProcessusList(result.data));
            }).catch((err) => {
                showError(err);
            });
        // load Options
        loadFormOptions();
        return () => {
            dispatch(actionCreators.resetFormOptions());
        };
    }, []);


    useEffect(() => {
        if (searchProcessus !== "") {
            setShowedItems(allProcessus.filter(processus => {
                return (
                    advancedSearch(processus.codeProcessus, searchProcessus) ||
                    advancedSearch(processus.materiaux.categorie, searchProcessus) ||
                    advancedSearch(processus.materiaux.materiaux, searchProcessus)
                );
            }));
        }
        else {
            setShowedItems(allProcessus);
        }
    }, [allProcessus, searchProcessus]);


    // const addProcessus = (processus) => {
    //     setAllProcessus(allProcessus.concat(processus));
    // }

    // const modifierProcessus = (processusUpdated) => {
    //     setAllProcessus(allProcessus.map((processus) => {
    //         return processusUpdated._id === processus._id ? processusUpdated : processus;
    //     }))
    // }

    // const supprimerProcessus = (processusId) => {
    //     setAllProcessus(allProcessus.filter(processus => {
    //         return processusId !== processus._id;
    //     }))
    // }

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openAjouterProcessus = () => {
        setModalClasses("modal modal__active");
        document.body.classList.add("body--modal-open");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterProcessusForm closeModal={() => { setModalClasses("modal"); document.body.classList.remove("body--modal-open"); }} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierProcessus = (processusData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierProcessusForm processus={processusData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openDeleteProcessus = (processusData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerProcessus" processus={processusData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openExportProcessus = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ExportProcessus closeModal={() => { setModalClasses("modal"); }} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    return (
        <div className='section'>
            <div className="container-basic mgB-m2">
                <h1 className="h1-blue-dark">Liste des processus</h1>
                <div className="tableau-top-section">
                    <h3>{showedItems.length} processus{showedItems.length > 1 && "s"} {showedItems.length > 1 ? "correspondent" : "correspond"} à votre recherche</h3>
                    <input className="header-input" onChange={(e) => setSearchProcessus(e.target.value)} name="searchProcessus" type="text" placeholder="Rechercher un processus" />
                    <button onClick={() => openAjouterProcessus()} className='default-button default-button-blue' style={{ marginRight: '.8rem' }}>+ Processus</button>
                    <button onClick={() => openExportProcessus()} className='default-button default-button-blue'>Exporter</button>
                </div>
            </div>
            <TableRender
                columns={getProcessusColumns((processus) => openDeleteProcessus(processus), (processus) => openModifierProcessus(processus), (link) => navigate(link))}
                tableData={showedItems}
                sortBy={[
                    {
                        id: 'codeProcessus',
                        desc: false
                    }
                ]}
                pageName="GestionProcessus"
            />
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default GestionProcessus;