import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import LoadingBar from 'react-top-loading-bar';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import Breadcrumb from '../../components/ui/Breadcrumb';
import SearchInput from '../../components/ui/SearchInput';
import TableRender from '../../components/tables/TableRender';
import { getGestionSalarieAmianteColumns, getGestionSalarieColumns } from '../../components/tables/TableColumns';
import Modal from '../../components/Modal';

import AjouterSalarieAmianteForm from '../../components/forms/GestionsForms/AjouterSalarieAmianteForm';
import ModifySalarieAmianteForm from '../../components/forms/GestionsForms/ModifySalarieAmianteForm';
import ArchiverSalarieAmianteForm from '../../components/forms/GestionsForms/ArchiverSalarieAmianteForm';

const GestionSalarieAmiantePage = () => {
    const navigate = useNavigate();
    const [salarieAmiantes, setSalarieAmiantes] = useState([]);
    const [showedItems, setShowedItems] = useState([]);
    const progressBarRef = useRef(null);
    const [itemStatutToShow, setItemStatutToShow] = useState("All");
    const [itemSecteurToShow, setItemSecteurToShow] = useState("All");

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/utilisateurs/allUtilisateursAmiante").then((result) => {
            progressBarRef.current.complete();
            setSalarieAmiantes(result.data);
            // console.log(result.data);
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    }, []);

    const [searchBarContent, setSearchBarContent] = useState(null);
    const salarieAmiantesMatch = (salarie, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(salarie._id.nom).includes(insensitiveSearch) || getInsensitiveWorld(salarie._id.prenom).includes(insensitiveSearch);
    };

    const onChangeSearchSalarie = (e) => {
        setSearchBarContent(e.target.value);
    };

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedItems(salarieAmiantes.filter(salarie => {
                    return salarieAmiantesMatch(salarie, searchBarContent) && (
                        itemStatutToShow === "All" || itemStatutToShow === salarie._id.userRole.statut
                    ) && (itemSecteurToShow === "All" || itemSecteurToShow === salarie._id.secteur);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedItems(salarieAmiantes.filter(salarie => {
                return (
                    itemStatutToShow === "All" || itemStatutToShow === salarie._id.userRole.statut
                ) && (itemSecteurToShow === "All" || itemSecteurToShow === salarie._id.secteur);
            }));
        }
    }, [salarieAmiantes, searchBarContent, itemStatutToShow, itemSecteurToShow]);

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalSalarieSelected, setModalSalarieSelected] = useState({});

    const openAddSalarie = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const addSalarie = (salarie) => {
        setSalarieAmiantes(salarieAmiantes.concat(salarie));
    };

    const openModifySalarie = (salarieData) => {
        setModalSalarieSelected(salarieData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const modifySalarie = (salarieUpdated) => {
        setSalarieAmiantes(salarieAmiantes.map((salarie) => {
            if (salarie._id._id === salarieUpdated._id) {
                return { ...salarie, _id: salarieUpdated };
            }
            return salarie;
        }));
    };

    const openArchiverSalarie = (salarieData) => {
        setModalSalarieSelected(salarieData);
        setModalClasses("modal modal__active");
        setModalContent("archiver");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const archiverSalarie = (salarieArchivedId) => {
        setSalarieAmiantes(salarieAmiantes.filter((salarie) => {
            return (salarie._id._id !== salarieArchivedId);
        }));
    };

    const selectStatutSalarieInput = (e) => {
        setItemStatutToShow(e.target.value);
    };

    const selectSecteurSalarieInput = (e) => {
        setItemSecteurToShow(e.target.value);
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Liste des salariés amiantes</h1>
                    <button onClick={() => openAddSalarie()} className='btn btn-primary medium-btn'>+ Salarié</button>
                </div>
                <Breadcrumb
                    links={[
                        { label: "Amiante", link: "/amiante" },
                        { label: "Salariés", link: "/amiante/salaries" },
                    ]}
                />
                <div className="filterContainer mgT-s2">
                    <SearchInput placeholder="Rechercher un salarié" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchSalarie} />
                    <select onChange={(e) => selectSecteurSalarieInput(e)} name="secteurSelect" id="secteur-select" className='new-default-input'>
                        <option value="All">Tous les secteurs</option>
                        <option value="Travaux publics">Travaux publics</option>
                        <option value="Gros oeuvres">Gros oeuvres</option>
                        <option value="3D">3D</option>
                        <option value="Transport">Transport</option>
                        <option value="Atelier">Atelier</option>
                    </select>
                    <select onChange={(e) => selectStatutSalarieInput(e)} name="statutSelect" id="statut-select" className='new-default-input'>
                        <option value="All">Tous les statuts</option>
                        <option value="Encadrant de chantier">Encadrant de chantier</option>
                        <option value="Encadrant technique">Encadrant technique</option>
                        <option value="Opérateur">Opérateur</option>
                    </select>
                </div>
                <TableRender
                    columns={getGestionSalarieAmianteColumns((salarieData) => openModifySalarie(salarieData), (salarieData) => openArchiverSalarie(salarieData), (link) => navigate(link))}
                    tableData={showedItems}
                    sortBy={[
                        {
                            id: 'user.nom',
                            desc: false,
                            disableSortRemove: true,
                        }
                    ]}
                    pageName="GestionSalarieAmiante"
                />
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter" &&
                    <AjouterSalarieAmianteForm progressBarRef={progressBarRef} ajouterSalarie={(salarie) => addSalarie(salarie)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "modifier" &&
                    <ModifySalarieAmianteForm progressBarRef={progressBarRef} salarie={modalSalarieSelected} modifierSalarie={(salarieUpdated) => modifySalarie(salarieUpdated)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "archiver" &&
                    <ArchiverSalarieAmianteForm progressBarRef={progressBarRef} salarie={modalSalarieSelected} archiverSalarie={(salarieArchived) => archiverSalarie(salarieArchived)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>

        </>
    );
};

export default GestionSalarieAmiantePage;;