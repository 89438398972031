import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAdminGestionBanqueHeureColumns } from "../../components/tables/TableColumns";
import TableRender from "../../components/tables/TableRender";
import * as actionCreators from "../../store/actions/exportAction";
import Select from 'react-select';
import axiosInstance from "../../utils/axiosInstance";
import fileDownload from 'js-file-download';
import { advancedSearch } from "../../utils/GeneralHelper";

const onLoadPage = () => actionCreators.getUtilisateursBanques();
const onQuitPage = () => actionCreators.unloadUserList();
const saveSortFilter = (pageName, optionName, optionValue) =>
	actionCreators.setOptionsFilterSort(pageName, optionName, optionValue);

const BanqueHeureAdminList = () => {
	const navigate = useNavigate();
	const userListBanques = useSelector((state) => state.userList);
	const optionsFilterSort = useSelector((state) => state.optionsFilterSort);
	const dispatch = useDispatch();
	const [showedItem, setItemToShow] = useState([]);
	const [searchBarContent, setSearchBarContent] = useState(optionsFilterSort.hasOwnProperty("banqueHeureList") && optionsFilterSort.banqueHeureList.hasOwnProperty("search") ? optionsFilterSort.banqueHeureList.search : null);
	const statutOptions = [
		{ label: "Chauffeur", value: "Chauffeur" },
		{ label: "Chef de chantier", value: "Chef de chantier" },
		{ label: "Intérimaire", value: "Interimaire" },
		{ label: "Ouvrier", value: "Ouvrier" }
	];
	const [itemStatutToShow, setItemStatutToShow] = useState(optionsFilterSort.hasOwnProperty("banqueHeureList") && optionsFilterSort.banqueHeureList.hasOwnProperty("statut") ? optionsFilterSort.banqueHeureList.statut : statutOptions);
	const secteurOptions = [
		{ label: "Travaux publics", value: "Travaux publics" },
		{ label: "Gros oeuvres", value: "Gros oeuvres" },
		{ label: "3D", value: "3D" },
		{ label: "Transport", value: "Transport" },
		{ label: "Atelier", value: "Atelier" },
	];
	const [itemSecteurToShow, setItemSecteurToShow] = useState(optionsFilterSort.hasOwnProperty("banqueHeureList") && optionsFilterSort.banqueHeureList.hasOwnProperty("secteur") ? optionsFilterSort.banqueHeureList.secteur : secteurOptions);

	useEffect(() => {
		dispatch(onLoadPage());
		return () => {
			dispatch(
				onQuitPage());
			setItemToShow([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setItemToShow([]);
		if (itemStatutToShow.find(statut => statut.value === "Ouvrier")) {
			// OUVRIER
			if (userListBanques.ouvrierList) {
				userListBanques.ouvrierList.forEach((ouvrier) => {
					if (
						(itemSecteurToShow.find(secteur => secteur.value === ouvrier._id.secteur)) &&
						(!searchBarContent ||
							advancedSearch(ouvrier._id.nom, searchBarContent) ||
							advancedSearch(ouvrier._id.prenom, searchBarContent) ||
							advancedSearch(ouvrier._id.login_matricule, searchBarContent))
					) {
						let addOuvrier = {
							userRole: "Ouvrier",
							user: ouvrier._id,
							banqueHeure: ouvrier.banqueHeure,
							absence: ouvrier.absence,
						};
						setItemToShow((oldArray) => [...oldArray, addOuvrier]);
					}
				});
			}
		}
		if (itemStatutToShow.find(statut => statut.value === "Interimaire")) {
			// INTERIMAIRE
			if (userListBanques.interimaireList) {
				userListBanques.interimaireList.forEach((interimaire) => {
					if (
						(itemSecteurToShow.find(secteur => secteur.value === interimaire._id.secteur)) &&
						(!searchBarContent ||
							advancedSearch(interimaire._id.nom, searchBarContent) ||
							advancedSearch(interimaire._id.prenom, searchBarContent) ||
							advancedSearch(interimaire._id.login_matricule, searchBarContent))
					) {
						let addInterimaire = {
							userRole: "Intérimaire",
							user: interimaire._id,
							banqueHeure: interimaire.banqueHeure,
							absence: interimaire.absence,
						};
						setItemToShow((oldArray) => [...oldArray, addInterimaire]);
					}
				});
			}
		}
		if (itemStatutToShow.find(statut => statut.value === "Chauffeur")) {
			// CHAUFFEUR
			if (userListBanques.chauffeurList) {
				userListBanques.chauffeurList.forEach((chauffeur) => {
					if (
						(itemSecteurToShow.find(secteur => secteur.value === chauffeur._id.secteur)) &&
						(!searchBarContent ||
							advancedSearch(chauffeur._id.nom, searchBarContent) ||
							advancedSearch(chauffeur._id.prenom, searchBarContent) ||
							advancedSearch(chauffeur._id.login_matricule, searchBarContent))
					) {
						let addChauffeur = {
							userRole: "Chauffeur",
							user: chauffeur._id,
							banqueHeure: chauffeur.banqueHeure,
							absence: chauffeur.absence,
						};
						setItemToShow((oldArray) => [...oldArray, addChauffeur]);
					}
				});
			}
		}
		if (itemStatutToShow.find(statut => statut.value === "Chef de chantier")) {
			// CHEF CHANTIER
			if (userListBanques.chefChantierList) {
				userListBanques.chefChantierList.forEach((chefChantier) => {
					if (chefChantier.hasOwnProperty("banqueHeure") && (itemSecteurToShow.find(secteur => secteur.value === chefChantier._id.secteur)) &&
						(!searchBarContent ||
							advancedSearch(chefChantier._id.nom, searchBarContent) ||
							advancedSearch(chefChantier._id.prenom, searchBarContent) ||
							advancedSearch(chefChantier._id.login_matricule, searchBarContent))
					) {
						let addChefChantier = {
							userRole: "Chef de chantier",
							user: chefChantier._id,
							banqueHeure: chefChantier.banqueHeure,
							absence: chefChantier.absence,
						};
						setItemToShow((oldArray) => [...oldArray, addChefChantier]);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userListBanques, itemStatutToShow, searchBarContent, itemSecteurToShow]);

	const exportData = () => {
		axiosInstance.get("/export_excel/exportHeuresTotal", { responseType: 'blob' })
			.then((result) => {
				fileDownload(result.data, "ExportHeureSalaries.xlsx");
			});
	};

	const selectStatut = (e) => {
		setItemStatutToShow(e);
		dispatch(saveSortFilter("banqueHeureList", "statut", e));
	};

	const selectSecteur = (e) => {
		setItemSecteurToShow(e);
		dispatch(saveSortFilter("banqueHeureList", "secteur", e));
	};

	const searchSalarie = (e) => {
		if (e.target.value !== "") {
			setSearchBarContent(e.target.value);
			dispatch(saveSortFilter("banqueHeureList", "search", e.target.value));
		} else {
			setSearchBarContent(null);
			dispatch(saveSortFilter("banqueHeureList", "search", null));
		}
	};

	return (
		<div className="section">
			<div className="container-basic mgB-m2">
				<h1 className="h1-blue-dark">Consulter les banques d'heures</h1>
				<h3 className="searchNumberTitle mgB-s2">
					{showedItem.length} salarié{showedItem.length > 1 && "s"}{" "}
					{showedItem.length > 1 ? "correspondent" : "correspond"} à votre
					recherche
				</h3>
				<div className="tableau-top-section mgB-s2">
					<Select
						defaultValue={secteurOptions}
						isMulti
						name="secteurFilter"
						options={secteurOptions}
						className="formSelect-search-container mgR-s2"
						classNamePrefix="formSelect-search filter-search"
						isSearchable={false}
						onChange={(e) => selectSecteur(e)}
						value={itemSecteurToShow}
					/>
					<Select
						defaultValue={statutOptions}
						isMulti
						name="secteurFilter"
						options={statutOptions}
						className="formSelect-search-container"
						classNamePrefix="formSelect-search filter-search"
						isSearchable={false}
						onChange={(e) => selectStatut(e)}
						value={itemStatutToShow}
					/>
				</div>
				<div className="tableau-top-section">
					<input
						className="header-input"
						onChange={(e) => searchSalarie(e)}
						name="searchSalarie"
						type="text"
						placeholder="Rechercher un salarié"
						value={searchBarContent ? searchBarContent : ""}
					/>
					<button className="default-button" onClick={() => exportData()}>Exporter les données</button>
				</div>
			</div>
			<TableRender
				columns={getAdminGestionBanqueHeureColumns((link) => navigate(link))}
				tableData={showedItem}
				sortBy={[{ id: `${"user.nom"}`, desc: false }]}
				pageName={"banqueHeureList"}
			/>
		</div>
	);
};

export default BanqueHeureAdminList;
