import React from "react";
import * as yup from "yup";
import { useFormik } from 'formik';
import axiosInstance from "../../../../utils/axiosInstance";
import customToast from "../../../../utils/ToastifyHelper";
import { showError } from "../../../../utils/ErrorHelper";

const AjouterCodeCed = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    //--------------- FORM SCHEMA ------------------
    const formSchema = yup.object().shape({
        label: yup.string().required("Veuillez saisir la description du code CED"),
        value: yup.number().required("Veuillez saisir un code CED valide"),
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        axiosInstance.post("/formOptions/options/codeCedDechet", values).then((result) => {
            props.progressBarRef.current.complete();
            customToast.success("Nouveau code Ced ajouté");
            props.ajouterCodeCed(result.data);
            close();
        }).catch((err) => {
            props.progressBarRef.current.complete();
            actions.setSubmitting(false);
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
        initialValues: {
            label: "",
            value: undefined,
        },
        validationSchema: formSchema,
        onSubmit
    });

    return (
        <div className="new-modal">
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="new-modal-topSection">
                <h3 className='modal-title'>Ajouter un code CED</h3>
            </div>
            <div className="new-modal-mainSection">
                <form className="fh-form" onSubmit={handleSubmit}>
                    <div className='label-and-input'>
                        <label htmlFor='value' className='form-label'>Code CED</label>
                        <input name='value' className={`new-default-input${errors.value && touched.value ? " input-error" : ""}`} type="number" placeholder="Code CED" value={values.value !== undefined ? values.value : ""} onChange={handleChange} onBlur={handleBlur} />
                        {errors.value && touched.value && <p className="form-error-message">{errors.value}</p>}
                    </div>
                    <div className='label-and-input last-input'>
                        <label htmlFor='label' className='form-label'>Description du code CED</label>
                        <input name='label' className={`new-default-input${errors.label && touched.label ? " input-error" : ""}`} type="text" placeholder="Description du code CED" value={values.label} onChange={handleChange} onBlur={handleBlur} />
                        {errors.label && touched.label && <p className="form-error-message">{errors.label}</p>}
                    </div>
                    <div className="submit-section">
                        <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn fw" type="submit" value="Ajouter le code CED" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AjouterCodeCed;
