import axiosInstance from '../../utils/axiosInstance';
import * as actionTypes from './actionTypes';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';

const loadOuvrierList = (ouvrierList) => {
    return {
        type: actionTypes.LOAD_OUVRIERS_LIST,
        ouvrierList: ouvrierList
    };
};

const loadInterimaireList = (interimaireList) => {
    return {
        type: actionTypes.LOAD_INTERIMAIRES_LIST,
        interimaireList: interimaireList
    };
};

const loadAdminList = (adminList) => {
    return {
        type: actionTypes.LOAD_ADMIN_LIST,
        adminList: adminList
    };
};

const loadArchiveList = (archiveList) => {
    return {
        type: actionTypes.LOAD_ARCHIVE_LIST,
        archiveList: archiveList
    };
};

const loadChefChantierList = (chefChantierList) => {
    return {
        type: actionTypes.LOAD_CHEFS_CHANTIER_LIST,
        chefChantierList: chefChantierList
    };
};

const loadChauffeurList = (chauffeurList) => {
    return {
        type: actionTypes.LOAD_CHAUFFEURS_LIST,
        chauffeurList: chauffeurList
    };
};

const loadUserList = (userList) => {
    return {
        type: actionTypes.LOAD_USER_LIST,
        userList: userList
    };
};

export const unloadUserList = () => {
    return {
        type: actionTypes.UNLOAD_USER_LIST
    };
};

export const addSalarie = (salarie) => {
    return {
        type: actionTypes.ADD_USER_TO_LIST,
        newSalarie: salarie,
    };
};


export const getOuvrierList = () => (dispatch) => {
    // get ouvrier list
    axiosInstance.get('/ouvriers', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadOuvrierList(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getAdminList = () => (dispatch) => {
    // get admin list
    axiosInstance.get('/administrations', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadAdminList(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getInterimaireList = () => (dispatch) => {
    // get interimaires
    axiosInstance.get('/interimaires', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadInterimaireList(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getChauffeurList = () => (dispatch) => {
    // get chauffeurs
    axiosInstance.get('/chauffeurs', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadChauffeurList(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getChefChantierList = () => (dispatch) => {
    // get chef chantier
    axiosInstance.get('/chefs_chantier/nombre_releve')
        .then((result) => {
            dispatch(loadChefChantierList(result.data));
        }).catch((err) => {
            showError(err);
        });
};


export const getUserList = () => (dispatch) => {
    // get all User
    axiosInstance.get('/utilisateurs', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadUserList(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getUserListChefHoraire = () => (dispatch) => {
    // get chef chantier
    let chefChantiers = [];
    axiosInstance.get('/chefs_chantier')
        .then((result) => {
            for (const chefChantier of result.data) {
                axiosInstance.get(`/horaires_semaines?chef_chantier=${chefChantier._id._id}`)
                    .then((res) => {
                        chefChantiers.push({ chef: chefChantier._id.nom, nombreReleve: res.data.length });
                    }).catch((error) => {
                        showError(error);
                    });
            }
            dispatch(loadChefChantierList(chefChantiers));
        }).catch((err) => {
            showError(err);
        }).finally(() => {
            console.log(chefChantiers[0]);
        });

};

export const getArchiveList = () => (dispatch) => {
    // get chef chantier
    axiosInstance.get('/salarie_archive')
        .then((result) => {
            dispatch(loadArchiveList(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getUtilisateursBanques = () => (dispatch) => {
    axiosInstance.get('/banque_heure')
        .then((result) => {
            dispatch(loadUserList(result.data));
        }).catch((err) => {
            showError(err);
        });
};