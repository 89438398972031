import React, { useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';

const updateChantiers = (chantierUpdated) => (actionCreators.modifyChantier(chantierUpdated));

function ArchiverChantierForm(props) {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const close = () => {
    props.closeModal();
    props.unrenderForm();
  };

  const onValiderArchivage = () => {
    setButtonDisabled(true);
    // delete Chantier
    if (props.archivage) {
      axiosInstance.patch('/chantiers/archiverChantier/' + props.chantier._id._id)
        .then((result) => {
          customToast.success("Le chantier a été archivé avec succès");
          dispatch(updateChantiers(result.data));
          props.archiverChantier();
          close();
        }).catch((err) => {
          setButtonDisabled(false);
          showError(err);
        });
    }
    else {
      axiosInstance.patch('/chantiers/desarchiverChantier/' + props.chantier._id._id)
        .then((result) => {
          customToast.success("Le chantier a été désarchiver avec succès");
          dispatch(updateChantiers(result.data));
          props.archiverChantier();
          close();
        }).catch((err) => {
          setButtonDisabled(false);
          showError(err);
        });
    }
    setButtonDisabled(false);
  };

  return (
    <div className="supprimerForm">
      <h1 className='modal-title'>{props.archivage ? 'Archiver un chantier' : 'Desarchiver un chantier'}</h1>
      <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
      <hr />
      <p className="modal-confirmation-text">Appuyer sur valider pour confirmer <span className="suppression-mot">{props.archivage ? "l'archivage" : "le désarchivage"}</span> de {props.chantier.codeChantier} {props.chantier._id.libelle_lieu}</p>
      <div className="button-container">
        <button disabled={isButtonDisabled} className='default-button supprimer-form-annuler' onClick={() => close()}>Annuler</button>
        <button disabled={isButtonDisabled} className='default-button supprimer-form-valider' onClick={() => onValiderArchivage()}>Valider</button>
      </div>
    </div>
  );
}

export default ArchiverChantierForm;