import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import customToast from '../../utils/ToastifyHelper';

const DesarchiverSalarieForm = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const close = () => {
    props.closeModal();
    props.unrenderForm();
  };

  const onSubmit = data => {
    setButtonDisabled(true);
    const postData = { statut: data.Statut, secteur: data.Secteur };
    axiosInstance.patch(`/salarie_archive/desarchiver/${props.salarie.user._id._id}`, postData)
      .then(() => {
        customToast.success(props.salarie.user._id.nom + " " + props.salarie.user._id.prenom + " est maintenant un " + postData.statut);
        props.desarchiverSalarie();
        close();
      }).catch((err) => {
        setButtonDisabled(false);
        showError(err);
      });
    setButtonDisabled(false);

  };

  return (
    <div className="modal-content modifier-form">
      <h3 className='modal-title'>Désarchiver {props.salarie.user._id.nom} {props.salarie.user._id.prenom}</h3>
      <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
      <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
        <label className='ajouter-form-label default-label' htmlFor="statut">Sélectionner un nouveau statut</label>
        <select className="new-default-input" name="statut" {...register("Statut", { required: true })}>
          <option value="Administration">Administration</option>
          <option value="Chauffeur">Chauffeur</option>
          <option value="Chef de chantier">Chef de chantier</option>
          <option value="Conducteur de travaux">Conducteur de travaux</option>
          <option value="Dispatcher">Dispatcher</option>
          <option value="Encadrant de chantier">Encadrant de chantier</option>
          <option value="Encadrant technique">Encadrant technique</option>
          <option value="Intérimaire">Intérimaire</option>
          <option value="Opérateur">Opérateur</option>
          <option value="Ouvrier">Ouvrier</option>
        </select>
        <label className='ajouter-form-label default-label' htmlFor="secteur">Secteur</label>
        <select className="new-default-input" name="secteur" defaultValue={props.salarie.user._id.secteur} {...register("Secteur", { required: true })}>
          <option value="Travaux publics">Travaux publics</option>
          <option value="Gros oeuvres">Gros oeuvres</option>
          <option value="3D">3D</option>
          <option value="Transport">Transport</option>
          <option value="Atelier">Atelier</option>
        </select>
        <div className="button-container">
          <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
          <input disabled={isButtonDisabled} className="default-button modifier-form-valider" type="submit" value="Désarchiver" />
        </div>
      </form>
    </div>
  );
};

export default DesarchiverSalarieForm;