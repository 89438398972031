// USER ACTION
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const GET_ME = "GET_ME";
export const STORE_SESSION_TOKEN = "STORE_SESSION_TOKEN";
export const STORE_SESSION_REFRESH_TOKEN = "STORE_SESSION_REFRESH_TOKEN";
export const STORE_LOCAL_TOKEN = "STORE_LOCAL_TOKEN";
export const STORE_TOKEN_IN_STATE = "STORE_TOKEN_IN_STATE";
export const ADD_LOGIN_ERROR = "ADD_LOGIN_ERROR";
export const DELETE_ALL_LOGIN_ERROR = "DELETE_ALL_LOGIN_ERROR";
export const START_LOGIN_SPINNER = "START_LOGIN_SPINNER";
export const STOP_LOGIN_SPINNER = "STOP_LOGIN_SPINNER";
export const ADD_USER_UPLOAD = "ADD_USER_UPLOAD";
export const RESET_USER_UPLOAD = "RESET_USER_UPLOAD";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

// GESTION SALARIE
export const LOAD_OUVRIERS_LIST = "LOAD_OUVRIERS_LIST";
export const LOAD_INTERIMAIRES_LIST = "LOAD_INTERIMAIRES_LIST";
export const LOAD_CHEFS_CHANTIER_LIST = "LOAD_CHEFS_CHANTIER_LIST";
export const LOAD_CHAUFFEURS_LIST = "LOAD_CHAUFFEURS_LIST";
export const LOAD_ADMIN_LIST = "LOAD_ADMIN_LIST";
export const LOAD_USER_LIST = "LOAD_USER_LIST";
export const LOAD_ARCHIVE_LIST = "LOAD_ARCHIVE_LIST";
export const UNLOAD_USER_LIST = "UNLOAD_USER_LIST";
export const ADD_USER_TO_LIST = "ADD_USER_TO_LIST";

// VEHICULES
export const LOAD_VEHICULE_LIST = "LOAD_VEHICULE_LIST";
export const UNLOAD_VEHICULE_LIST = "UNLOAD_VEHICULE_LIST";
export const ADD_VEHICULE = "ADD_VEHICULE";
export const ADD_VEHICULE_ERROR = "ADD_VEHICULE_ERROR";
export const MODIFY_VEHICULE = "MODIFY_VEHICULE";
export const DELETE_VEHICULE = "DELETE_VEHICULE";

// MATERIAUX
export const LOAD_MATERIAUX_LIST = "LOAD_MATERIAUX_LIST";
export const UNLOAD_MATERIAUX_LIST = "UNLOAD_MATERIAUX_LIST";
export const ADD_MATERIAUX = "ADD_MATERIAUX";
export const ADD_MATERIAUX_ERROR = "ADD_MATERIAUX_ERROR";
export const MODIFY_MATERIAUX = "MODIFY_MATERIAUX";
export const DELETE_MATERIAUX = "DELETE_MATERIAUX";

// HISTORIQUE CONTROLE EPC
export const LOAD_CONTROLE_LIST = 'LOAD_CONTROLE_LIST';
export const UNLOAD_CONTROLE_LIST = 'UNLOAD_CONTROLE_LIST';
export const ADD_CONTROLE = 'ADD_CONTROLE';
export const ADD_CONTROLE_ERROR = 'ADD_CONTROLE_ERROR';
export const MODIFY_CONTROLE = 'MODIFY_CONTROLE';
export const DELETE_CONTROLE = 'DELETE_CONTROLE';
export const MODIFY_PROCHAIN_CONTROLE = 'MODIFY_PROCHAIN_CONTROLE';

// EQUIPEMENT EPC
export const LOAD_EQUIPEMENT_EPC_LIST = 'LOAD_EQUIPEMENT_EPC_LIST';
export const UNLOAD_EQUIPEMENT_EPC_LIST = 'UNLOAD_EQUIPEMENT_EPC_LIST';
export const ADD_EQUIPEMENT_EPC = 'ADD_EQUIPEMENT_EPC';
export const ADD_DOCUMENT_EPC = 'ADD_DOCUMENT_EPC';
export const DELETE_DOCUMENT_EPC = 'DELETE_DOCUMENT_EPC';
export const MODIFY_DOCUMENT_EPC = 'MODIFY_DOCUMENT_EPC';
export const ADD_EQUIPEMENT_EPC_ERROR = 'ADD_EQUIPEMENT_EPC_ERROR';
export const MODIFY_EQUIPEMENT_EPC = 'MODIFY_EQUIPEMENT_EPC';
export const DELETE_EQUIPEMENTS_EPC = 'DELETE_EQUIPEMENT_EPC';

// CHANTIER
export const LOAD_CHANTIER_ZONE_LIST = "LOAD_CHANTIER_LIST";
export const UNLOAD_CHANTIER_ZONE_LIST = "UNLOAD_CHANTIER_LIST";
export const ADD_CHANTIER = "ADD_CHANTIER";
export const ADD_CHANTIER_ERROR = "ADD_CHANTIER_ERROR";
export const MODIFY_CHANTIER = "MODIFY_CHANTIER";
export const DELETE_CHANTIER = "DELETE_CHANTIER";

// ABSENCES
export const LOAD_ALL_ABSENCES = "LOAD_ALL_ABSENCES";
export const UNLOAD_ALL_ABSENCES = "UNLOAD_ALL_ABSENCES";
export const ADD_ABSENCE = "ADD_ABSENCE";
export const MODIFY_ABSENCE = "MODIFY_ABSENCE";
export const DELETE_ABSENCE = "DELETE_ABSENCE";

// RELEVE HEURE
export const LOAD_RELEVE_HEURE_ME = "LOAD_RELEVE_HEURE_ME";
export const UNLOAD_RELEVE_HEURE = "UNLOAD_RELEVE_HEURE";

// CURRENT WEEK
export const CHANGE_CURRENT_WEEK = "CHANGE_CURRENT_WEEK";
export const RESET_CURRENT_WEEK = "RESET_CURRENT_WEEK";

// BANQUE HEURE

export const LOAD_BANQUE_HEURE_ME = "LOAD_BANQUE_HEURE_ME";
export const UNLOAD_BANQUE_HEURE = "UNLOAD_BANQUE_HEURE";
export const ADD_HORAIRE_BANQUE_HEURE = "ADD_HORAIRE_BANQUE_HEURE";
export const DELETE_BANQUE_HEURE = "DELETE_BANQUE_HEURE";

// CONSULTATION RELEVE HEURE
export const LOAD_ADMIN_RELEVE_HEURE_CHEF = "LOAD_ADMIN_RELEVE_HEURE_CHEF";
export const UNLOAD_ADMIN_RELEVE_HEURE = "UNLOAD_ADMIN_RELEVE_HEURE";
export const UPDATE_RELEVE_HEURE_ADMIN = "UPDATE_RELEVE_HEURE_ADMIN";
export const CHANGE_STATUT_RELEVE_HEURE_ADMIN =
	"CHANGE_STATUT_RELEVE_HEURE_ADMIN";

// CATEGORIE DOCUMENT
export const LOAD_CATEGORIES_DOCUMENT = "LOAD_CATEGORIES_DOCUMENT";
export const UNLOAD_CATEGORIES_DOCUMENT = "UNLOAD_CATEGORIES_DOCUMENT";
export const ADD_CATEGORIE_DOCUMENT = "ADD_CATEGORIE_DOCUMENT";
export const MODIFY_CATEGORIE_DOCUMENT = "MODIFY_CATEGORIE_DOCUMENT";
export const DELETE_CATEGORIE_DOCUMENT = "DELETE_CATEGORIE_DOCUMENT";
export const ADD_TYPE_DOCUMENTS = "ADD_TYPE_DOCUMENTS";
export const DELETE_TYPE_DOCUMENTS = "DELETE_TYPE_DOCUMENTS";
export const MODIFY_TYPE_DOCUMENT = "MODIFY_TYPE_DOCUMENT";

// DOCUMENT
export const GET_DOCUMENTS_PUBLICS = "GET_DOCUMENTS_PUBLICS";
export const UNLOAD_DOCUMENTS = "UNLOAD_DOCUMENTS";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SALARIE = "DELETE_DOCUMENT_SALARIE";
export const EDIT_DOCUMENT_PUBLICS = "EDIT_DOCUMENT_PUBLICS";
export const LOAD_DOCUMENTS = "LOAD_DOCUMENTS";
export const ADD_GESTIONS_CHANTIERS_DOCUMENTS =
	"ADD_GESTIONS_CHANTIERS_DOCUMENTS";
export const DELETE_DOCUMENTS_GESTION_CHANTIERS =
	"DELETE_DOCUMENTS_GESTION_CHANTIERS";
export const UPDATE_GESTIONS_DOCUMENTS_CHANTIERS =
	"UPDATE_GESTIONS_DOCUMENTS_CHANTIERS";

// TOPBAR
export const SET_TOPBAR_CURRENT_TAB = "SET_TOPBAR_CURRENT_TAB";
export const TOPBAR_SCROLL_TO_TAB = "TOPBAR_SCROLL_TO_TAB";
export const UNLOAD_TOPBAR_TAB = "UNLOAD_TOPBAR_TAB";
export const UNLOAD_TOPBAR_SCROLL = "UNLOAD_TOPBAR_SCROLL";

// SUIVI TYPE FORMATION
export const LOAD_SUIVI_TYPE_FORMATION_LIST = "LOAD_SUIVI_TYPE_FORMATION_LIST";
export const UNLOAD_SUIVI_TYPE_FORMATION_LIST = "UNLOAD_SUIVI_TYPE_FORMATION_LIST";
export const ADD_SUIVI_TYPE_FORMATION = "ADD_SUIVI_TYPE_FORMATION";
export const MODIFY_SUIVI_TYPE_FORMATION = "MODIFY_SUIVI_TYPE_FORMATION";
export const DELETE_SUIVI_TYPE_FORMATION = "DELETE_SUIVI_TYPE_FORMATION";


// CHANTIER OPTIONS
export const LOAD_CHANTIER_OPTIONS = "LOAD_CHANTIER_OPTIONS";

// FORM OPTIONS
export const SET_FORM_OPTIONS = "SET_FORM_OPTIONS";
export const ADD_FORM_OPTIONS = "ADD_FORM_OPTIONS";
export const RESET_FORM_OPTIONS = "RESET_FORM_OPTIONS";

// PROCESSUS OPTIONS
export const SET_PROCESSUS = "SET_PROCESSUS";
export const AJOUTER_PROCESSUS = "AJOUTER_PROCESSUS";
export const MODIFIER_PROCESSUS = "MODIFIER_PROCESSUS";
export const SUPPRIMER_PROCESSUS = "SUPPRIMER_PROCESSUS";
export const UNLOAD_PROCESSUS = "UNLOAD_PROCESSUS";

// SAVE FILTER SORT OPTIONS
export const SAVE_OPTIONS_FILTER_SORT = "SAVE_OPTIONS_FILTER_SORT";
export const REMOVE_TABLE_FILTER = 'REMOVE_TABLE_FILTER';
export const ADD_TABLE_FILTER = 'ADD_TABLE_FILTER';

// NEW TABLE SAVE OPTIONS
export const SAVE_TABLE = "SAVE_TABLE";

// PAGE SAVE OPTIONS
export const SAVE_PAGE_OPTIONS = "SAVE_PAGE_OPTIONS";

// FICHIER
export const LOAD_FICHIERS_CHANTIER = "LOAD_FICHIERS_CHANTIER";
export const UNLOAD_FICHIERS_CHANTIER = "UNLOAD_FICHIERS_CHANTIER";
export const ADD_FICHIER_CHANTIER = "ADD_FICHIER_CHANTIER";

// UPLOADS FILES
export const SET_UPLOADS_FILES = "SET_UPLOADS_FILES";
export const REMOVE_FILE_FROM_UPlOAD = "REMOVE_FILE_FROM_UPlOAD";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const REMOVE_FICHIER_CHANTIER = "REMOVE_FICHIER_CHANTIER";
export const SET_NOM_FICHIER = "SET_NOM_FICHIER";
export const UPDATE_FICHIER_CHANTIER = "UPDATE_FICHIER_CHANTIER";
export const SET_VISIBILITY_FICHIER = "SET_VISIBILITY_FICHIER";