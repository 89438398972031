import * as actionTypes from '../actions/actionTypes';

// const documentList = [];
const documents = {
    documentsPublics: [],
    documentGestionsChantiers: []
};

const reducer = (state = documents, action) => {
    switch (action.type) {
        case actionTypes.GET_DOCUMENTS_PUBLICS:
            return {
                ...state,
                documentsPublics: [...action.documentsPublics],
                documentGestionsChantiers: [...state.documentGestionsChantiers]
            };
        case actionTypes.LOAD_DOCUMENTS:
            return {
                ...state,
                documentsPublics: [...state.documentsPublics],
                documentGestionsChantiers: [...action.documents]
            };
        case actionTypes.UNLOAD_DOCUMENTS:
            return {
                ...state,
                documentsPublics: [],
                documentGestionsChantiers: []
            };
        case actionTypes.ADD_DOCUMENT:
            return {
                ...state,
                documentsPublics: state.documentsPublics.concat(action.document),
                documentGestionsChantiers: [...state.documentGestionsChantiers]
            };
        case actionTypes.ADD_GESTIONS_CHANTIERS_DOCUMENTS:
            return {
                ...state,
                documentsPublics: [...state.documentsPublics],
                documentGestionsChantiers: state.documentGestionsChantiers.concat(action.document)
            };
        case actionTypes.DELETE_DOCUMENT:
            return {
                ...state,
                documentsPublics: state.documentsPublics.filter(document => document._id._id !== action.documentId),
                documentGestionsChantiers: [...state.documentGestionsChantiers]
            };
        case actionTypes.DELETE_DOCUMENT_SALARIE:
            return {
                ...state,
                documentsPublics: state.documentsPublics.filter(document => document._id !== action.documentId),
                documentGestionsChantiers: [...state.documentGestionsChantiers]
            };
        case actionTypes.DELETE_DOCUMENTS_GESTION_CHANTIERS:
            return {
                ...state,
                documentsPublics: [...state.documentsPublics],
                documentGestionsChantiers: state.documentGestionsChantiers.filter(document => document.baseDocument._id !== action.documentId),
            };
        case actionTypes.EDIT_DOCUMENT_PUBLICS:
            return {
                ...state,
                documentsPublics: state.documentsPublics.map((document) => {
                    if (document._id._id === action.document._id) {
                        return { _id: action.document };
                    }
                    return document;
                }),
                documentGestionsChantiers: [...state.documentGestionsChantiers]
            };
        case actionTypes.UPDATE_GESTIONS_DOCUMENTS_CHANTIERS:
            return {
                ...state,
                documentsPublics: [...state.documentsPublics],
                documentGestionsChantiers: state.documentGestionsChantiers.map((document) => {
                    if (document._id === action.document._id) {
                        return action.document;
                    }
                    return document;
                }),
            };
        default:
            return state;
    }
};

export default reducer;