import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import SelectSearch from '../SelectSearch';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';

const ModifierAnalyseProcessusForm = (props) => {
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const onSubmit = data => {
        const postData = {
            chantierAnalyse: data.chantierAnalyse.value,
            dateAnalyse: data.dateAnalyse,
            typeValidationAnalyse: data.typeValidationAnalyse.value,
            ancienTypeValidationAnalyse: props.analyse.typeValidation,
            analyseF: data.analyseF,
            analyseRC: data.analyseRC,
            analyseSa: data.analyseSa
        };
        if (postData.ancienTypeValidationAnalyse === "controle") {
            postData.validationControleId = props.analyse.analyse._id;
        }
        setButtonDisabled(true);
        axiosInstance.patch(`/validationProcessus/modifierAnalyse/${props.analyse.processus._id}`, postData)
            .then((result) => {
                props.updateValidations(result.data);
                setButtonDisabled(false);
                close();
            }).catch((err) => {
                showError(err);
                setButtonDisabled(false);
            });
    };

    const [selectableValidationOptions, setSelectableValidationOptions] = useState([]);

    const typeValidationOption = [
        { label: "Chantier test", value: "validationTest" },
        { label: "Validation 1", value: "firstValidation" },
        { label: "Validation 2", value: "secondValidation" },
        { label: "Validation 3", value: "thirdValidation" },
        { label: "Contrôle", value: "controle" }
    ];

    useEffect(() => {
        let validationOptions = [];
        for (const optionValidation of typeValidationOption) {
            if (optionValidation.value === "controle") {
                validationOptions.push(optionValidation);

            }
            else if (props.analyse.processus[optionValidation.value] === null || optionValidation.value === props.analyse.typeValidation) {
                validationOptions.push(optionValidation);
            }
        }
        setSelectableValidationOptions(validationOptions);
    }, []);

    const allChantierOptions = useSelector(state => state.chantierZoneList.chantierFormatOptions);

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier une analyse de processus</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
                <label className='ajouter-form-label default-label' htmlFor="typeValidationAnalyse">Type de validation</label>
                <SelectSearch
                    options={selectableValidationOptions}
                    dataName="typeValidationAnalyse"
                    control={control}
                    name="typeValidationAnalyse"
                    className="formSelect-search-container mgB-s2"
                    classNamePrefix="formSelect-search"
                    placeholder="Sélectionner la phase d'analyse"
                    isClearable
                    required
                    defaultValue={typeValidationOption.find(option => { return (option.value === props.analyse.typeValidation); })}
                />
                {errors['typeValidationAnalyse'] && errors['typeValidationAnalyse'].type === "required" && <p className="login-error-message">Veuillez sélectionner la phase d'analyse du processus</p>}
                <label className='ajouter-form-label default-label' htmlFor="dateAnalyse">Date</label>
                <input defaultValue={format(new Date(props.analyse.analyse.dateValidation), "yyyy-MM-dd")} className="ajouter-form-input default-input" name="dateAnalyse" type="date" {...register("dateAnalyse", { required: true })} />
                {errors['dateAnalyse'] && errors['dateAnalyse'].type === "required" && <p className="login-error-message">Veuillez saisir une date d'analyse du processus</p>}
                <label className='ajouter-form-label default-label' htmlFor="chantierAnalyse">Chantier</label>
                <SelectSearch
                    options={allChantierOptions}
                    dataName="chantierAnalyse"
                    control={control}
                    name="chantierAnalyse"
                    className="formSelect-search-container mgB-s2"
                    classNamePrefix="formSelect-search"
                    placeholder="Sélectionner le chantier d'analyse"
                    isClearable
                    required
                    defaultValue={allChantierOptions.find(chantier => chantier.value === props.analyse.analyse.validationChantier._id._id)}
                />
                {errors['chantierAnalyse'] && errors['chantierAnalyse'].type === "required" && <p className="login-error-message">Veuillez sélectionner un chantier d'analyse du processus</p>}
                <label className='ajouter-form-label default-label' htmlFor="analyseSa">Sensibilité analytique (f/L)</label>
                <input placeholder="Entrer le résultat d'analyse SA" defaultValue={props.analyse.analyse.concentrationFibre.sa} className="ajouter-form-input default-input" step=".01" name="analyseSa" type="number" {...register("analyseSa", { required: true, min: 0 })} />
                {errors['analyseSa'] && errors['analyseSa'].type === "required" && <p className="login-error-message">Veuillez saisir l'analyse SA</p>}
                {errors["analyseSa"] && errors["analyseSa"].type === "min" && (
                    <p className="login-error-message">Veuillez saisir une valeur supérieur à 0</p>
                )}
                <label className='ajouter-form-label default-label' htmlFor="analyseF">Nombre total de fibres d'amiante comptées</label>
                <input placeholder="Entrer le résultat d'analyse F" defaultValue={props.analyse.analyse.concentrationFibre.f} className="ajouter-form-input default-input" step=".01" name="analyseF" type="number" {...register("analyseF", { required: true, min: 0 })} />
                {errors['analyseF'] && errors['analyseF'].type === "required" && <p className="login-error-message">Veuillez saisir l'analyse F</p>}
                {errors['analyseF'] && errors['analyseF'].type === "min" && <p className="login-error-message">Veuillez saisir une valeur supérieur à 0</p>}
                <label className='ajouter-form-label default-label' htmlFor="analyseRC">Concentration (f/L)</label>
                <input placeholder="Entrer le résultat d'analyse RC" defaultValue={props.analyse.analyse.concentrationFibre.rc} className="ajouter-form-input default-input" step=".01" name="analyseRC" type="number" {...register("analyseRC", { required: true, min: 0 })} />
                {errors['analyseRC'] && errors['analyseRC'].type === "required" && <p className="login-error-message">Veuillez saisir l'analyse RC</p>}
                {errors['analyseRC'] && errors['analyseRC'].type === "min" && <p className="login-error-message">Veuillez saisir une valeur supérieur à 0</p>}
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <input disabled={isButtonDisabled} className="default-button modifier-form-valider" type="submit" value="Modifier" />
                </div>
            </form>
        </div>
    );
};

export default ModifierAnalyseProcessusForm;