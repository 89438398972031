import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import InsideNavigationBar from "../../components/ui/InsideNavigationBar";

import SearchInput from "../../components/ui/SearchInput";
import { getSecteursActivite } from "../../utils/GeneralHelper";
import TablePaginationV2 from "../../components/tables/Render/TablePaginationV2";
import { getReleveHeureParChefColumns, getReleveHeureParSemaineColumns } from "../../components/tables/Basic/BasicTableColumns";
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/exportAction";
import Select from 'react-select';
import Modal from "../../components/Modal";
import ListeSalarieReleveHeureV2 from "./Modal/ListeSalarieReleveHeureV2";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import fileDownload from 'js-file-download';
import { format, set } from "date-fns";
import customToast from "../../utils/ToastifyHelper";
import DatePicker from 'react-date-picker';
import { fr } from "date-fns/locale";


const savePageOptions = (pageName, pageOptions) => (actionCreators.savePageOptions(pageName, pageOptions));

const ReleveHeureAdminV2 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pageOptionsSaved = useSelector(
        state => state.pageState
    );

    const progressBarRef = useRef(null);

    const [pageOptions, setPageOptions] = useState({
        page: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("page") ? pageOptionsSaved.ReleveHeureAdminV2.page : 1,
        limit: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("limit") ? pageOptionsSaved.ReleveHeureAdminV2.limit : 10,
        total: 0,
        sort: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("sort") ? pageOptionsSaved.ReleveHeureAdminV2.sort : "nom",
        sortBy: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("sortBy") ? pageOptionsSaved.ReleveHeureAdminV2.sortBy : "asc",
        search: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("search") ? pageOptionsSaved.ReleveHeureAdminV2.search : "",
        secteur: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("secteur") ? pageOptionsSaved.ReleveHeureAdminV2.secteur : "All",
        totalPages: 1,
        fetchData: true
    });

    const [tableData, setTableData] = useState([]);


    const [afficherRelevePar, setAfficherRelevePar] = useState("Chef");

    const fetchData = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/releve-heure-v2/releveParSalarie?page=${pageOptions.page}&limit=${pageOptions.limit}&sort=${pageOptions.sort}&sortBy=${pageOptions.sortBy}&search=${pageOptions.search}&secteur=${pageOptions.secteur}`).then((result) => {
            progressBarRef.current.complete();
            setTableData(result.data.result);
            setPageOptions({ ...pageOptions, page: result.data.currentPage, totalPages: result.data.totalPages, total: result.data.total, fetchData: false });
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });

    };

    useEffect(() => {
        if (pageOptions.fetchData) {
            fetchData();
            setPageOptions({ ...pageOptions, fetchData: false });
        }
        return () => {
            if (pageOptions.fetchData) {
                dispatch(savePageOptions("ReleveHeureAdminV2", { page: pageOptions.page, limit: pageOptions.limit, sort: pageOptions.sort, sortBy: pageOptions.sortBy, search: pageOptions.search, secteur: pageOptions.secteur }));
            }
        };
    }, [pageOptions]);

    const setPageOptionsFetch = (options) => {
        setPageOptions({ ...pageOptions, ...options, fetchData: true });
    };

    // SEARCHBAR
    const [searchChefBarContent, setSearchChefBarContent] = useState(null);

    const onChangeSearchChef = (e) => {
        setSearchChefBarContent(e.target.value);
    };

    useEffect(() => {
        if (searchChefBarContent !== null) {
            let timer = setTimeout(() => {
                setPageOptionsFetch({ "search": searchChefBarContent, "page": 1 });
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [searchChefBarContent]);

    const [pageOptionsReleveParSemaine, setPageOptionsReleveParSemaine] = useState({
        page: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("page") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.page : 1,
        limit: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("limit") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.limit : 10,
        total: 0,
        sort: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("sort") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.sort : "annee",
        sortBy: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("sortBy") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.sortBy : "desc",
        annee: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("annee") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.annee : "",
        numeroSemaine: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("numeroSemaine") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.numeroSemaine : "",
        statutSemaine: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("statutSemaine") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.statutSemaine : "",
        saisiePar: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("saisiePar") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.saisiePar : "",
        utilisateurPresent: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("utilisateurPresent") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.utilisateurPresent : "",
        totalPages: 1,
        fetchData: true
    });

    const [tableDataReleveParSemaine, setTableDataReleveParSemaine] = useState([]);

    const fetchDataReleveParSemaine = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/releve-heure-v2/statutsSemaine?page=${pageOptionsReleveParSemaine.page}&limit=${pageOptionsReleveParSemaine.limit}&sort=${pageOptionsReleveParSemaine.sort}&sortBy=${pageOptionsReleveParSemaine.sortBy}&annee=${pageOptionsReleveParSemaine.annee ? pageOptionsReleveParSemaine.annee.value : ""}&numeroSemaine=${pageOptionsReleveParSemaine.numeroSemaine ? pageOptionsReleveParSemaine.numeroSemaine.value : ""}&statutSemaine=${pageOptionsReleveParSemaine.statutSemaine ? pageOptionsReleveParSemaine.statutSemaine.value : ""}&saisiePar=${pageOptionsReleveParSemaine.saisiePar ? pageOptionsReleveParSemaine.saisiePar.value : ""}&utilisateurPresent=${pageOptionsReleveParSemaine.utilisateurPresent ? pageOptionsReleveParSemaine.utilisateurPresent.value : ""}`).then((result) => {
            progressBarRef.current.complete();
            setTableDataReleveParSemaine(result.data.statutsSemaine);
            setPageOptionsReleveParSemaine({ ...pageOptionsReleveParSemaine, page: result.data.currentPage, totalPages: result.data.totalPages, total: result.data.total, fetchData: false });
        }).catch((err) => {
            progressBarRef.current.complete();
            console.log(err);
            showError(err);
        });
    };

    useEffect(() => {
        if (pageOptionsReleveParSemaine.fetchData) {
            fetchDataReleveParSemaine();
            setPageOptionsReleveParSemaine({ ...pageOptionsReleveParSemaine, fetchData: false });
        }
        return () => {
            if (pageOptionsReleveParSemaine.fetchData) {
                dispatch(savePageOptions("ReleveHeureAdminV2_Semaine", { page: pageOptionsReleveParSemaine.page, limit: pageOptionsReleveParSemaine.limit, sort: pageOptionsReleveParSemaine.sort, sortBy: pageOptionsReleveParSemaine.sortBy, annee: pageOptionsReleveParSemaine.annee, numeroSemaine: pageOptionsReleveParSemaine.numeroSemaine, statutSemaine: pageOptionsReleveParSemaine.statutSemaine, saisiePar: pageOptionsReleveParSemaine.saisiePar, utilisateurPresent: pageOptionsReleveParSemaine.utilisateurPresent }));
            }
        };
    }, [pageOptionsReleveParSemaine]);

    const setPageOptionsFetchReleveParSemaine = (options) => {
        setPageOptionsReleveParSemaine({ ...pageOptionsReleveParSemaine, ...options, fetchData: true });
    };

    const [anneeReleveParSemaineOptions, setAnneeReleveParSemaineOptions] = useState([]);
    const [numeroSemaineReleveParSemaineOptions, setNumeroSemaineReleveParSemaineOptions] = useState([]);
    const [saisieParUtilisateursReleveParSemaineOptions, setSaisieParUtilisateursReleveParSemaineOptions] = useState([]);
    const [listeDesSalaries, setListeDesSalaries] = useState({});


    useEffect(() => {
        axiosInstance.get("/releve-heure-v2/statutsSemaine/annees").then((result) => {
            // let anneeOptions = [{ label: "Toutes les années", value: "All", semaines: [] }];
            let anneeOptions = [];
            for (const options of result.data) {
                anneeOptions.push({ label: options.annee, value: options.annee, semaines: options.semaines });
            }
            setAnneeReleveParSemaineOptions(anneeOptions);
        });

        axiosInstance.get("/releve-heure-v2/listeUtilisateursSaisiePar").then((result) => {
            // let optionsSaisieParListe = [{ label: "Tous les utilisateurs", value: "All" }];
            let optionsSaisieParListe = [];
            for (const optionsSaisiePar of result.data) {
                optionsSaisieParListe.push({ label: `(${optionsSaisiePar.login_matricule}) - ${optionsSaisiePar.prenom} ${optionsSaisiePar.nom}`, value: optionsSaisiePar._id });
            }
            setSaisieParUtilisateursReleveParSemaineOptions(optionsSaisieParListe);
        });

        axiosInstance.get('/utilisateurs').then((result) => {
            setListeDesSalaries(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const onChangeAnnee = (opt) => {
        setPageOptionsFetchReleveParSemaine({ "annee": opt, "page": 1 });
        if (opt !== null) {
            let optionsNumeroSemaine = [];
            for (const numeroSemaine of opt.semaines) {
                optionsNumeroSemaine.push({ label: numeroSemaine, value: numeroSemaine });
            }
            setNumeroSemaineReleveParSemaineOptions(optionsNumeroSemaine);
        }
        else {
            setNumeroSemaineReleveParSemaineOptions([]);
        }
    };

    const onChangeNumeroSemaine = (opt) => {
        setPageOptionsFetchReleveParSemaine({ "numeroSemaine": opt, "page": 1 });
    };

    const onChangeSaisiePar = (opt) => {
        setPageOptionsFetchReleveParSemaine({ "saisiePar": opt, "page": 1 });
    };

    const onChangeStatutSemaine = (opt) => {
        setPageOptionsFetchReleveParSemaine({ "statutSemaine": opt, "page": 1 });
    };

    const handleChangeSalarie = (...args) => {
        setPageOptionsFetchReleveParSemaine({ "utilisateurPresent": args[0], "page": 1 });
    };

    const optionsSalarie = [
        {
            label: "Ouvrier",
            options: listeDesSalaries.hasOwnProperty("ouvrierList") && listeDesSalaries.ouvrierList.length ? listeDesSalaries.ouvrierList.map((ouvrier) => {
                let fullName = ouvrier._id.login_matricule + " / " + ouvrier._id.nom + " " + ouvrier._id.prenom;
                return { label: fullName, value: ouvrier._id._id };
            }) : []
        },
        {
            label: "Chef de chantier",
            options: listeDesSalaries.hasOwnProperty("chefChantierList") && listeDesSalaries.chefChantierList.length ? listeDesSalaries.chefChantierList.map((chef) => {
                let fullName = chef._id.login_matricule + " / " + chef._id.nom + " " + chef._id.prenom;
                return { label: fullName, value: chef._id._id };
            }) : []
        },
        {
            label: "Intérimaire",
            options: listeDesSalaries.hasOwnProperty("interimaireList") && listeDesSalaries.interimaireList.length ? listeDesSalaries.interimaireList.map((interimaire) => {
                let fullName = interimaire._id.login_matricule + " / " + interimaire._id.nom + " " + interimaire._id.prenom;
                return { label: fullName, value: interimaire._id._id };
            }) : []
        }
    ];

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openSalariePresentSemaine = (idSemaine) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ListeSalarieReleveHeureV2 idSemaine={idSemaine} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const [dateExport, onChangeDateExport] = useState([new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)]);

    const [checkedGo, setCheckedGo] = useState(true);
    const [checkedTp, setCheckedTp] = useState(true);
    const [checkedInterimaire, setCheckedInterimaire] = useState(true);

    const handleChangeGo = () => {
        setCheckedGo(!checkedGo);
    };

    const handleChangeTp = () => {
        setCheckedTp(!checkedTp);
    };

    const handleChangeInterimaire = () => {
        setCheckedInterimaire(!checkedInterimaire);
    };

    const downloadExport = () => {
        if (dateExport !== null && dateExport[0] !== null && dateExport[1] !== null) {
            if (!checkedGo && !checkedTp && !checkedInterimaire) {
                customToast.error("Sélectionner au moins une catégorie de salarié à exporter");
            }
            else {
                progressBarRef.current.continuousStart();
                axiosInstance.get(`/releve-heure-v2/export/exportHeureSalarie?dateDeb=${format(dateExport[0], "yyyy-MM-dd")}&dateFin=${format(dateExport[1], "yyyy-MM-dd")}&salarieGo=${checkedGo.toString()}&salarieTp=${checkedTp.toString()}&salarieInterimaire=${checkedInterimaire.toString()}&showZones=${checkedAfficherZone.toString()}`, {
                    responseType: 'blob',
                    timeout: 60000,
                })
                    .then((result) => {
                        let nomExportType = "";
                        if (checkedGo) {
                            nomExportType += "_GrosOeuvres";
                        }
                        if (checkedTp) {
                            nomExportType += "_TravauxPublics";
                        }
                        if (checkedInterimaire) {
                            nomExportType += "_Interimaires";
                        }
                        fileDownload(result.data, "ExportHeureSalaries" + nomExportType + " du " + format(dateExport[0], "dd/MM/yyyy") + " au " + format(dateExport[1], "dd/MM/yyyy") + ".xlsx");
                        progressBarRef.current.complete();
                    }).catch((err) => {
                        showError(err);
                        progressBarRef.current.complete();
                    });
            }
        }
        else {
            customToast.error("Sélectionner une date de début et de fin de période pour télécharger l'export");
        }
    };

    const [exportChefSelected, setExportChefSelected] = useState(null);
    const [numeroSemaine, setNumeroSemaine] = useState([]);
    const [numeroSelected, setNumeroSelected] = useState(null);


    const onChangeChef = (opt) => {
        setExportChefSelected(opt);
        if (opt !== null) {
            progressBarRef.current.continuousStart();
            axiosInstance.get(`/releve-heure-v2/tousLesRelevesOptions/${opt.value}`)
                .then((result) => {
                    progressBarRef.current.complete();
                    setNumeroSemaine(result.data);
                }).catch((err) => {
                    showError(err);
                });
        }
    };

    const onExportReleveParSemaine = () => {
        if (exportChefSelected !== null && numeroSelected !== null) {

            progressBarRef.current.continuousStart();
            axiosInstance.get(`/releve-heure-v2/export_releve_heure_semaine_chef/${numeroSelected._id}`, {
                responseType: 'blob',
            })
                .then((result) => {

                    progressBarRef.current.complete();
                    fileDownload(result.data, `exportSemaine ${numeroSelected.numeroSemaine} année ${numeroSelected.annee} de ${exportChefSelected.label}.xlsx`);
                }).catch((err) => {
                    showError(err);
                });
        }
        else {
            customToast.error("Veuillez sélectionner un chef de chantier et un numéro de semaine pour exporter la semaine");
        }
    };

    const downloadExportPaye = () => {
        if (dateExportPaye !== null && dateExportPaye[0] !== null && dateExportPaye[1] !== null) {
            let secteurSalarie = checkedGoMois ? "Gros oeuvres" : "Travaux publics";

            progressBarRef.current.continuousStart();
            axiosInstance.get(`/releve-heure-v2/export_releve_heure_mois?dateDebutExport=${format(dateExportPaye[0], "yyyy-MM-dd")}&dateFinExport=${format(dateExportPaye[1], "yyyy-MM-dd")}&secteurSalarie=${secteurSalarie}&showTotalHeures=${checkedTotalHeure.toString()}`, {
                responseType: 'blob',
                timeout: 60000,
            })
                .then((result) => {
                    fileDownload(result.data, `Export Heure du ${format(dateExportPaye[0], "dd/MM/yyyy")} au ${format(dateExportPaye[1], "dd/MM/yyyy")}.xlsx`);
                    progressBarRef.current.complete();
                }).catch((err) => {
                    showError(err);
                    progressBarRef.current.complete();
                });
        } else {
            customToast.error("Sélectionner une date de début et de fin de période pour télécharger l'export");
        }
    };

    const [checkedGoMois, setCheckedGoMois] = useState(true);
    const [checkedTpMois, setCheckedTpMois] = useState(false);

    const handleChangeGoMois = () => {
        if (!checkedGoMois) {
            setCheckedTpMois(checkedGoMois);
            setCheckedGoMois(!checkedGoMois);
        }
    };

    const handleChangeTpMois = () => {
        if (!checkedTpMois) {
            setCheckedGoMois(checkedTpMois);
            setCheckedTpMois(!checkedTpMois);
        }
    };

    const [checkedTotalHeure, setCheckedTotalHeure] = useState(true);
    const [checkedAfficherZone, setCheckedAfficherZone] = useState(true);

    const [dateExportPaye, onChangeDateExportPaye] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <h1>Relevé d'heures</h1>
                <InsideNavigationBar
                    activeValue={afficherRelevePar}
                    setActiveValue={(value) => setAfficherRelevePar(value)}
                    links={[
                        { label: "Relevé par chefs de chantier", value: "Chef" },
                        { label: "Relevé par semaine", value: "Releve" },
                        { label: "Exporter des relevés d'heures", value: "Export" }
                    ]}
                />
                {afficherRelevePar === "Chef" &&
                    <>
                        <div className="filterContainer">
                            <SearchInput fullwidth={true} placeholder="Rechercher un relevé par nom, prenom, matricule..." searchContent={searchChefBarContent !== null ? searchChefBarContent : pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("search") ? pageOptionsSaved.ReleveHeureAdminV2.search : ""} onChangeSearch={onChangeSearchChef} />
                            <select value={pageOptions.secteur} className="new-default-input" onChange={(e) => setPageOptionsFetch({ "secteur": e.target.value, "page": 1 })} >
                                <option value="All">Tout les secteurs</option>
                                {getSecteursActivite().map((secteur) => {
                                    return <option key={secteur} value={secteur}>{secteur}</option>;
                                })}
                            </select>
                        </div>
                        <TablePaginationV2
                            columns={getReleveHeureParChefColumns((url) => navigate(url))}
                            tableData={tableData}
                            pageOptions={pageOptions}
                            setPageOptionsFetch={setPageOptionsFetch}
                        />
                    </>
                }
                {afficherRelevePar === "Releve" &&
                    <>
                        <div className="filterContainer">
                            <Select
                                options={anneeReleveParSemaineOptions}
                                onChange={onChangeAnnee}
                                placeholder="Sélectionner une année"
                                className="formSelect-search-container formSelect-annee-filter"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    }),
                                }}
                                isSearchable={true}
                                value={pageOptionsReleveParSemaine.annee}
                                isClearable={true}
                            />
                            <Select
                                options={numeroSemaineReleveParSemaineOptions}
                                className="formSelect-search-container formSelect-numero-semaine-filter"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => onChangeNumeroSemaine(opt)}
                                placeholder="Sélectionner le numéro de semaine"
                                value={pageOptionsReleveParSemaine.numeroSemaine}
                                isClearable={true}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                            />
                            <Select
                                options={saisieParUtilisateursReleveParSemaineOptions}
                                onChange={onChangeSaisiePar}
                                placeholder="Sélectionner un chef de chantier"
                                className="formSelect-search-container formSelect-chef-chantier-filter"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                value={pageOptionsReleveParSemaine.saisiePar}
                                isClearable={true}
                            />
                            <Select
                                options={[{ value: 0, label: "En cours" }, { value: 1, label: "Terminé" }]}
                                className="formSelect-search-container formSelect-statut-semaine-filter"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => onChangeStatutSemaine(opt)}
                                placeholder="Statut de la semaine"
                                value={pageOptionsReleveParSemaine.statutSemaine}
                                isClearable={true}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                            />
                            <Select
                                options={optionsSalarie}
                                value={pageOptionsReleveParSemaine.utilisateurPresent === null ? '' : pageOptionsReleveParSemaine.utilisateurPresent}
                                placeholder="Sélectionner un salarié présent dans la semaine"
                                isSearchable={true}
                                onChange={handleChangeSalarie}
                                className="formSelect-search-container formSelect-salarie-present-filter"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isClearable={true}
                            />
                        </div>
                        <TablePaginationV2
                            columns={getReleveHeureParSemaineColumns((idSemaine) => openSalariePresentSemaine(idSemaine), (url) => navigate(url))}
                            tableData={tableDataReleveParSemaine}
                            pageOptions={pageOptionsReleveParSemaine}
                            setPageOptionsFetch={setPageOptionsFetchReleveParSemaine}
                        />
                    </>
                }
                {afficherRelevePar === "Export" &&
                    <>
                        <div className="exportSection mgB-m2">
                            <div className="excelCard">
                                <div className="imgContainer">

                                </div>
                                <div className="downloadOptions">
                                    <h3 className="modal-title">Exporter les heures des salariés</h3>
                                    <div className="downloadLine">
                                        <label className='ajouter-form-label default-label' htmlFor="nom">Sélectionner la période d'export: </label>
                                        <DateRangePicker calendarIcon={null} minDate={new Date("2022-01-01")} maxDate={new Date("2099-12-31")} className="input-datetimePicker searchDate-select" showLeadingZeros={true} format="dd/MM/yyyy" onChange={onChangeDateExport} value={dateExport} />
                                    </div>
                                    <div className="downloadLine">
                                        <label className='ajouter-form-label default-label' htmlFor="nom">Sélectionner les sociétés des salariés: </label>
                                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                                            <div className="checkboxExport">
                                                <input className='login-checkbox'
                                                    type="checkbox"
                                                    checked={checkedGo}
                                                    onChange={handleChangeGo}
                                                />
                                                <label className='checkbox-label' htmlFor="checkbox">Gros oeuvres</label>
                                            </div>
                                            <div className="checkboxExport">
                                                <input className='login-checkbox'
                                                    type="checkbox"
                                                    checked={checkedTp}
                                                    onChange={handleChangeTp}
                                                />
                                                <label className='checkbox-label' htmlFor="checkbox">Travaux publics</label>
                                            </div>
                                            <div className="checkboxExport">
                                                <input className='login-checkbox'
                                                    type="checkbox"
                                                    checked={checkedInterimaire}
                                                    onChange={handleChangeInterimaire}
                                                />
                                                <label className='checkbox-label' htmlFor="checkbox">Intérimaires</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="downloadLine">
                                        <label className='ajouter-form-label default-label' htmlFor="nom">Afficher les zones: </label>
                                        <div style={{ display: 'flex', alignItems: "center", flex: 1 }}>
                                            <div className="checkboxExport" style={{}}>
                                                <input className='login-checkbox'
                                                    type="checkbox"
                                                    checked={checkedAfficherZone}
                                                    onChange={() => setCheckedAfficherZone(true)}
                                                />
                                                <label onClick={() => { setCheckedAfficherZone(true); }} className='checkbox-label' htmlFor="checkbox">Oui</label>
                                            </div>
                                            <div className="checkboxExport" style={{ marginLeft: "1rem" }}>
                                                <input className='login-checkbox'
                                                    type="checkbox"
                                                    checked={!checkedAfficherZone}
                                                    onChange={() => setCheckedAfficherZone(false)}
                                                />
                                                <label onClick={() => { setCheckedAfficherZone(false); }} className='checkbox-label' htmlFor="checkbox">Non</label>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={() => downloadExport()} className='btn btn-primary downloadExportButton mgT-m1'>Télécharger</button>
                                </div>
                            </div>
                        </div>
                        <div className="container-basic mgB-m2">
                            <h3 className='mgB-s2'>Exporter une semaine</h3>
                            <p className='form-label'>Chef de chantier</p>
                            <Select
                                options={saisieParUtilisateursReleveParSemaineOptions.length > 1 ? saisieParUtilisateursReleveParSemaineOptions.slice(1) : saisieParUtilisateursReleveParSemaineOptions}
                                className="formSelect-search-container formSelect-search-container-full mgB-s2"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => onChangeChef(opt)}
                                placeholder="Sélectionner un chef de chantier"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                value={exportChefSelected || ''}
                            />
                            <p className='form-label'>Numéro de la semaine</p>
                            <Select
                                options={numeroSemaine}
                                className="formSelect-search-container formSelect-search-container-full mgB-m1"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => setNumeroSelected(opt)}
                                placeholder="Sélectionner le numéro de semaine"
                                isDisabled={exportChefSelected === null}
                                value={numeroSelected || ''}
                                getOptionLabel={e => `${e.annee} - Semaine ${e.numeroSemaine}`}
                                getOptionValue={e => e._id}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                            />
                            <button onClick={() => onExportReleveParSemaine()} className='btn btn-primary fw'>Télécharger</button>
                        </div>
                        <div className="exportSection container-basic exportParMois">
                            <h3 className='mgB-s2'>Exporter les heures (paye)</h3>
                            <div style={{ display: 'flex', alignItems: "center", gap: "0.8rem" }}>
                                <label className='ajouter-form-label default-label' htmlFor="nom">Sélectionner la période: </label>
                                <DateRangePicker calendarIcon={null} minDate={new Date("2022-01-01")} maxDate={new Date("2099-12-31")} className="input-datetimePicker" showLeadingZeros={true} format="dd/MM/yyyy" onChange={onChangeDateExportPaye} value={dateExportPaye} />
                            </div>
                            <div className="downloadLine">
                                <label className='ajouter-form-label default-label' htmlFor="nom">Sélectionner les sociétés des salariés: </label>
                                <div style={{ display: 'flex', alignItems: "center", flex: 1 }}>
                                    <div className="checkboxExport" style={{ flex: 1 }}>
                                        <input className='login-checkbox'
                                            type="checkbox"
                                            checked={checkedGoMois}
                                            onChange={handleChangeGoMois}
                                        />
                                        <label onClick={() => { handleChangeGoMois(); }} className='checkbox-label' htmlFor="checkbox">Gros oeuvres</label>
                                    </div>
                                    <div className="checkboxExport" style={{ flex: 1 }}>
                                        <input className='login-checkbox'
                                            type="checkbox"
                                            checked={checkedTpMois}
                                            onChange={handleChangeTpMois}
                                        />
                                        <label onClick={() => { handleChangeTpMois(); }} className='checkbox-label' htmlFor="checkbox">Travaux publics</label>
                                    </div>
                                </div>
                            </div>
                            <div className="downloadLine">
                                <label className='ajouter-form-label default-label' htmlFor="nom">Afficher le total des heures: </label>
                                <div style={{ display: 'flex', alignItems: "center", flex: 1 }}>
                                    <div className="checkboxExport" style={{}}>
                                        <input className='login-checkbox'
                                            type="checkbox"
                                            checked={checkedTotalHeure}
                                            onChange={() => setCheckedTotalHeure(true)}
                                        />
                                        <label onClick={() => { setCheckedTotalHeure(true); }} className='checkbox-label' htmlFor="checkbox">Oui</label>
                                    </div>
                                    <div className="checkboxExport" style={{ marginLeft: "1rem" }}>
                                        <input className='login-checkbox'
                                            type="checkbox"
                                            checked={!checkedTotalHeure}
                                            onChange={() => setCheckedTotalHeure(false)}
                                        />
                                        <label onClick={() => { setCheckedTotalHeure(false); }} className='checkbox-label' htmlFor="checkbox">Non</label>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => downloadExportPaye()} className='btn btn-primary fw mgT-m1'>Télécharger</button>
                        </div>
                    </>
                }
            </div>
            <Modal modalClass={modalClasses}>{modalContent}</Modal>

        </>
    );
};

export default ReleveHeureAdminV2;
