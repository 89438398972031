import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import customToast from '../../utils/ToastifyHelper';
import * as actionTypes from './actionTypes';

const loadControleList = (controleList) => {
    return {
        type: actionTypes.LOAD_CONTROLE_LIST,
        controleList: controleList
    };
};

export const unloadControleList = () => {
    return {
        type: actionTypes.UNLOAD_CONTROLE_LIST,
    };
};

export const addControle = (controleData) => {
    return {
        type: actionTypes.ADD_CONTROLE,
        controles: controleData
    };
};

export const modifyControle = (controleNewData) => {
    return {
        type: actionTypes.MODIFY_CONTROLE,
        controleUpdated: controleNewData
    };
};

export const modifyProchainControle = (prochainControle) => {
    return {
        type: actionTypes.MODIFY_PROCHAIN_CONTROLE,
        prochainControle: prochainControle
    };
};

export const deleteControle = (controleId) => {
    return {
        type: actionTypes.DELETE_CONTROLE,
        controleId: controleId
    };
};


export const getControleList = (controleId) => (dispatch) => {
    // get all materiaux
    axiosInstance.get(`/equipementEPC/controles/${controleId}`, { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadControleList(result.data));
        }).catch((err) => {
            showError(err);
        });
};