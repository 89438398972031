import { toast } from 'react-toastify';
import { css } from 'glamor';

export const optionsDefautlt = {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 3000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: true,
    pauseOnHover: true
};

const customToast = {
    success(msg, options = {}) {
        return toast.success(msg, {
            ...optionsDefautlt,
            ...options,
            className: 'toast-success-container toast-success-container-after',
            progressClassName: css({
                background: '#34A853',
            }),
        });
    },
    error(msg, options = {}) {
        return toast.error(msg, {
            ...optionsDefautlt,
            ...options,
            className: 'toast-error-container toast-error-container-after',
            progressClassName: css({
                background: '#EE0022',
            }),
        });
    },
    info(msg, options = {}) {
        return toast.info(msg, {
            ...optionsDefautlt,
            ...options,
            className: 'toast-info-container toast-info-container-after',
            progressClassName: css({
                background: '#07F',
            }),
        });
    },
    loading(msg, options = {}) {
        return toast.loading(msg, { ...optionsDefautlt, ...options, pauseOnHover: false });
    },
    update(msg, id, type, options = {}) {
        return toast.update(id, { ...optionsDefautlt, ...options, render: msg, type: type, isLoading: false });
    }
};


export default customToast;