import React from "react";
import * as yup from "yup";
import { useFormik } from 'formik';
import Select from 'react-select';
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import customToast from "../../utils/ToastifyHelper";
import SelectCreatable from 'react-select/creatable';

const ModifierDechet = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    //--------------- FORM SCHEMA ------------------
    const formSchema = yup.object().shape({
        libelleDechet: yup.string().required("Veuillez saisir le nom du déchet"),
        codeFamille: yup.object().required("Veuillez sélectionner un code famille").shape({
            value: yup.number().required("Veuillez sélectionner un code famille"),
            label: yup.string().required("Veuillez sélectionner un code famille"),
        }),
        codeCed: yup.object().required("Veuillez sélectionner un code CED").shape({
            value: yup.number().required("Veuillez sélectionner un code CED"),
            label: yup.string().required("Veuillez sélectionner un code CED"),
        }),
        conditionnement: yup.object().required("Veuillez sélectionner un conditionnement").shape({
            value: yup.string().required("Veuillez sélectionner un conditionnement"),
            label: yup.string().required("Veuillez sélectionner un conditionnement"),
        })
    });

    const onSubmit = (values, actions) => {
        let patchData = {
            ...values,
            codeFamille: values.codeFamille.value,
            codeCed: values.codeCed.value,
            conditionnement: values.conditionnement.value
        };
        props.progressBarRef.current.continuousStart();
        axiosInstance.patch(`/dechets/${props.dechetSelected._id}`, patchData).then((result) => {
            props.progressBarRef.current.complete();
            customToast.success("Nouveau déchet ajouté");
            props.modifierCodeDechet(result.data);
            if (values.conditionnement.__isNew__) {
                props.ajouterConditionnement({ label: values.conditionnement.label, value: values.conditionnement.value });
            }
            close();
        }).catch((err) => {
            props.progressBarRef.current.complete();
            actions.setSubmitting(false);
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            libelleDechet: props.dechetSelected.libelleDechet,
            codeFamille: props.codeFamilleOptions.length ? props.codeFamilleOptions.find(option => option.value === props.dechetSelected.codeFamille) : undefined,
            codeCed: props.codeCedOptions.length ? props.codeCedOptions.find(option => option.value === props.dechetSelected.codeCed) : undefined,
            conditionnement: { label: props.dechetSelected.conditionnement, value: props.dechetSelected.conditionnement }
        },
        validationSchema: formSchema,
        onSubmit
    });

    return (
        <div className="new-modal">
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="new-modal-topSection">
                <h3 className='modal-title'>Modifier un déchet</h3>
            </div>
            <div className="new-modal-mainSection">
                <form className="fh-form" onSubmit={handleSubmit}>
                    <div className='label-and-input'>
                        <label htmlFor='libelleDechet' className='form-label'>Nom du déchet</label>
                        <input name='libelleDechet' className={`new-default-input${errors.libelleDechet && touched.libelleDechet ? " input-error" : ""}`} type="text" placeholder="Nom du déchet" value={values.libelleDechet !== undefined ? values.libelleDechet : ""} onChange={handleChange} onBlur={handleBlur} />
                        {errors.libelleDechet && touched.libelleDechet && <p className="form-error-message">{errors.libelleDechet}</p>}
                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='codeFamille' className='form-label'>Code famille</label>
                        <Select
                            name="codeFamille"
                            options={props.codeFamilleOptions}
                            isLoading={!props.codeFamilleOptions.length}
                            isDisabled={!props.codeFamilleOptions.length}
                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                            classNamePrefix="formSelect-search"
                            onChange={(opt) => setFieldValue("codeFamille", opt ? opt : "")}
                            placeholder={"Sélectionner le code famille"}
                            value={values.codeFamille ? values.codeFamille : ""}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    fontFamily: "DM Sans",
                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                    borderRadius: "0.6rem",
                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                    '&:hover': {
                                        border: '1px solid black',
                                    },
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            onBlur={handleBlur}
                        />
                        {errors.codeFamille && touched.codeFamille && <p className="form-error-message">{errors.codeFamille.value || errors.codeFamille.label}</p>}

                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='codeCed' className='form-label'>Code CED</label>
                        <Select
                            name="codeCed"
                            options={props.codeCedOptions}
                            isLoading={!props.codeCedOptions.length}
                            isDisabled={!props.codeCedOptions.length}
                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                            classNamePrefix="formSelect-search"
                            onChange={(opt) => setFieldValue("codeCed", opt ? opt : "")}
                            placeholder={"Sélectionner le code CED"}
                            value={values.codeCed ? values.codeCed : ""}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    fontFamily: "DM Sans",
                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                    borderRadius: "0.6rem",
                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                    '&:hover': {
                                        border: '1px solid black',
                                    },
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            onBlur={handleBlur}
                        />
                        {errors.codeCed && touched.codeCed && <p className="form-error-message">{errors.codeCed.value || errors.codeCed.label}</p>}
                    </div>
                    <div className='label-and-input last-input'>
                        <label htmlFor='conditionnement' className='form-label'>Conditionnement du déchet</label>
                        <SelectCreatable
                            name="conditionnement"
                            options={props.conditionnementOptions}
                            isLoading={!props.conditionnementOptions.length}
                            isDisabled={!props.conditionnementOptions.length}
                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                            classNamePrefix="formSelect-search"
                            onChange={(opt) => setFieldValue("conditionnement", opt ? opt : "")}
                            placeholder={"Sélectionner le conditionnement"}
                            value={values.conditionnement ? values.conditionnement : ""}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    fontFamily: "DM Sans",
                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                    borderRadius: "0.6rem",
                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                    '&:hover': {
                                        border: '1px solid black',
                                    },
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            onBlur={handleBlur}
                            formatCreateLabel={(value) => `Ajouter "${value}" au type de conditionnement`}
                        />
                        {errors.conditionnement && touched.conditionnement && <p className="form-error-message">{errors.conditionnement.value || errors.conditionnement.label}</p>}
                    </div>
                    <div className="submit-section">
                        <button type="button" disabled={isSubmitting} className='btn btn-secondary medium-btn fw mgR-m1' onClick={() => close()}>Annuler</button>
                        <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn fw" type="submit" value="Modifier le déchet" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModifierDechet;
