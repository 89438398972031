import axiosInstance from "../../utils/axiosInstance";
import * as actionTypes from './actionTypes';
import { showError } from '../../utils/ErrorHelper';
import axios from "axios";
import { removeFileExtension } from "../../utils/GeneralHelper";
import mongoose from "mongoose";
import { addFichierChantier } from "./FichiersAct";

export const setUploadsFiles = (files) => {
    let uploadedFiles = files.map(file => {
        return {
            _id: new mongoose.Types.ObjectId(),
            original: file,
            abortController: new AbortController(),
            progress: 0,
            nomFichier: removeFileExtension(file.name),
            visibility: [{ "value": "Administration", "label": "Administration" }, { "value": "Encadrant technique", "label": "Encadrant technique" }]
        };
    });
    return {
        type: actionTypes.SET_UPLOADS_FILES,
        uploadedFiles: uploadedFiles
    };
};

export const removeFileFromUpload = (fichier) => (dispatch) => {
    if (fichier.progress === 0) {
        dispatch({
            type: actionTypes.REMOVE_FILE_FROM_UPlOAD,
            fichierId: fichier._id
        });
    }
    else {
        fichier.abortController.abort();
        dispatch({
            type: actionTypes.REMOVE_FILE_FROM_UPlOAD,
            fichierId: fichier._id
        });
    }
};

const updateProgress = (fichierId, progress) => {
    return {
        type: actionTypes.UPDATE_PROGRESS,
        progress: progress,
        fichierId: fichierId
    };
};

export const setNomFichier = (fichierId, nomFichier) => {
    return {
        type: actionTypes.SET_NOM_FICHIER,
        nomFichier: nomFichier,
        fichierId: fichierId
    };
};

export const setVisibilityFichier = (fichierId, visibility) => {
    return {
        type: actionTypes.SET_VISIBILITY_FICHIER,
        visibility: visibility,
        fichierId: fichierId
    };
};

export const uploadFile = (uploadLink) => (dispatch, getState) => {
    const uploadedFiles = getState().uploadFiles;
    for (const fichier of uploadedFiles) {
        const controller = fichier.abortController;

        const config = {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(updateProgress(fichier._id, percentCompleted));
            },
            signal: controller.signal,
            timeout: 0,
            headers: { 'Content-Type': 'multipart/form-data' }
        };
        let postData = new FormData();
        postData.append("_id", fichier._id);
        postData.append("nomFichier", fichier.nomFichier);
        postData.append("visibility", fichier.visibility.map(v => v.value));
        postData.append("document", fichier.original);

        axiosInstance.post(uploadLink, postData, config).then((result) => {
            // ajouter au fichier
            dispatch(addFichierChantier(result.data));
        }).catch((err) => {
            if (!axios.isCancel(err)) {
                showError(err);
            }
            else {
                axiosInstance.delete(`/uploads/cancelUpload/${fichier._id}`).then((result) => {
                }).catch((err) => {
                });
            }
        });
    }
};

