import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const DefaultProfilePicture = ({ height, width, edit, openEditProfilePicture, additionalClassname }) => {
    if (edit) {
        return (
            <div style={{ position: "relative" }}>
                <div className={`profilePictureDefault ${additionalClassname ? additionalClassname : ""}`} style={{
                    height: height ? height : "20px",
                    width: width ? width : "20px",
                    backgroundColor: "transparent",
                    borderRadius: "50%",
                    border: "1px solid #d8556b",
                    display: 'flex',
                    alignItems: "end",
                    justifyContent: "center",
                    overflow: 'hidden',
                    position: "relative",
                    zIndex: 2
                }}>
                    <FontAwesomeIcon icon={faUser} />
                </div>
                <div onClick={openEditProfilePicture} className='editProfilePicture-penContainer' style={{
                    width: "40px",
                    height: "40px",
                    zIndex: 3,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    cursor: "pointer"
                }}>
                    <FontAwesomeIcon icon={faPen} />
                </div>
            </div>
        );
    }
    else {
        return (
            <div className={`profilePictureDefault ${additionalClassname ? additionalClassname : ""}`} style={{
                height: height ? height : "20px",
                width: width ? width : "20px",
                backgroundColor: "white",
                borderRadius: "50%",
                border: "1px solid #d8556b",
                display: 'flex',
                alignItems: "end",
                justifyContent: "center",
                overflow: 'hidden',
                position: "relative"
            }}>
                <FontAwesomeIcon icon={faUser} />
            </div>
        );
    }
};

export default DefaultProfilePicture;