import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faNewspaper, faUserClock } from '@fortawesome/pro-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import ChefHeader from '../header/ChefHeader';
import Sidebar from '../sidebar/Sidebar';
import { useMediaQuery } from 'react-responsive';
import { Icon } from '@iconify/react';
import axiosInstance from '../../utils/axiosInstance';
import ActualiteItem from '../../pages/Actualites/ActualiteItem';
import { showError } from '../../utils/ErrorHelper';


const DashboardChefChantier = () => {
    const navigate = useNavigate();

    const isPhoneScreen = useMediaQuery({
        maxWidth: 860
    });

    const [sidebarState, setSidebarState] = useState([
        {
            Header: "Accueil",
            url: "/",
            type: "link",
            iconName: "ic:baseline-home",
            active: false
        },
        {
            Header: "Just'actus",
            url: "/justeau-actus/actualites",
            type: "link",
            iconName: "fa6-solid:newspaper",
            active: false
        },
        {
            Header: "Relevé d'heures",
            url: "/mes-releves-heures",
            type: "link",
            iconName: "teenyicons:clock-solid",
            active: false,
        },
        {
            Header: "Banque d'heures",
            url: "/ma-banque-heures",
            type: "link",
            iconName: "mdi:user-clock",
            active: false,
        }
    ]);

    const currentUrl = window.location.pathname;

    useEffect(() => {
        if (currentUrl === "/") {
            setSidebarState(sidebarState.map((nav) => {
                if (nav.url === "/") {
                    return { ...nav, active: true };
                }
                return nav;
            }));
        }
        else {
            setSidebarState(sidebarState.map((nav) => {
                if (nav.type === "group") {
                    if (currentUrl.includes(nav.url)) {
                        return {
                            ...nav, active: true, opened: true, height: "fit-content", links: nav.links.map((subnav) => {

                                if (currentUrl.includes(subnav.url)) {
                                    return { ...subnav, active: true };
                                }
                                return subnav;
                            })
                        };
                    }
                    return nav;
                }
                else {
                    if (currentUrl.includes(nav.url) && nav.url !== "/") {
                        return { ...nav, active: true };
                    }
                    return nav;
                }
            }));
        }
    }, []);

    const handleIconNavigation = (url) => {
        setSidebarState(
            sidebarState.map((nav) => {
                if (nav.url === "/") {
                    return { ...nav, active: false };
                }
                else {
                    if (nav.type === "group") {
                        if (url.includes(nav.url)) {
                            if (nav.opened) {
                                return {
                                    ...nav, active: true, links: nav.links.map((subnav) => {
                                        if (subnav.url === url) {
                                            return { ...subnav, active: true };
                                        }
                                        return { ...subnav, active: false };
                                    })
                                };
                            }
                            return {
                                ...nav, active: true, opened: true, height: "fit-content", links: nav.links.map((subnav) => {
                                    if (subnav.url === url) {
                                        return { ...subnav, active: true };
                                    }
                                    return { ...subnav, active: false };
                                })
                            };
                        }
                        return {
                            ...nav, active: false, links: nav.links.map((subnav) => {
                                return { ...subnav, active: false };
                            })
                        };
                    }
                    else {
                        if (nav.url.includes(url)) {
                            return { ...nav, active: true };
                        }
                        return { ...nav, active: false };
                    }
                }
            })
        );
        navigate(url);
    };

    const [actualite, setActualite] = useState([]);

    const fetchActualite = () => {
        axiosInstance.get("/actualites/actualitesPublished?page=1&limit=4&sort=order,asc").then((result) => {
            setActualite(result.data.actualite);
        }).catch((err) => {
            showError(err);
        });
    };

    useEffect(() => {
        fetchActualite();
    }, []);


    const mainSection = <div className='section mgT-m1'>
        <div id="applicationGrid">
            <div className='appIcon' onClick={() => handleIconNavigation("/mes-releves-heures")} data-tip data-for="releveHeure">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faClock} />
                </div>
                <ReactTooltip id="releveHeure" place="bottom" effect='solid'>
                    Relevé d'heures
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/ma-banque-heures")} data-tip data-for="banque">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faUserClock} />
                </div>
                <ReactTooltip id="banque" place="bottom" effect='solid'>
                    Banque d'heures
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/justeau-actus/actualites")} >
                <div className="iconContainer" data-tip data-for="actualite">
                    <FontAwesomeIcon icon={faNewspaper} />
                </div>
                <ReactTooltip id="actualite" place="bottom" effect='solid'>
                    Actualités
                </ReactTooltip>
            </div>
        </div>
        <div className='mgT-b1'>
            <h3 className='mgB-s2'>Just'actus</h3>
            <div className='mgB-m1' style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "2rem"
            }}>
                {actualite.map((actu) => {
                    return (
                        <ActualiteItem key={actu._id} actualite={actu} />
                    );
                })}
            </div>
        </div>
        <div className='mgT-b1'>
            <h3>Télécharger l'application</h3>
            <div className='mgT-s3' style={{ display: 'flex', alignContent: "center", flexWrap: "wrap", gap: "1.6rem" }}>
                <a className='downloadAppContainer' href='https://play.google.com/store/apps/details?id=com.justeau_mobileapp' target="_blank">
                    <div className='downloadApp-infoContainer'>
                        <Icon icon="ant-design:android-filled" />
                        <div className='downloadApp-infoText'>
                            <p>Télécharger pour android</p>
                            <p>version: 2.1.8</p>
                        </div>
                    </div>
                </a>
                <a className='downloadAppContainer' href='https://apps.apple.com/us/app/justeau/id6476137992'>
                    {/* <a className='downloadAppContainer' href='#' target="_blank"> */}
                    <div className='downloadApp-infoContainer'>
                        <Icon icon="ant-design:apple-filled" />
                        <div className='downloadApp-infoText'>
                            <p>Télécharger pour ios</p>
                            <p>version: 1.1</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>;

    return (
        <div className={`dashboard-layout admin-dashboard-layout ${isPhoneScreen ? "noSidebar" : ""}`}>
            <Sidebar sidebarState={sidebarState} setSidebarState={setSidebarState} />
            <div className="dashboard-layout-main-container">
                <ChefHeader />
                <div className="dashboard-layout-page-content">
                    {currentUrl === "/" ? mainSection : <Outlet />}
                </div>
                {/* <FooterDashboard /> */}
            </div>
        </div>
    );
};

export default DashboardChefChantier;

