import React from 'react';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { capitalizeFirstLetter } from '../../utils/GeneralHelper';

const SignatureSalarieModal = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    return (
        <div className="modal-content">
            <h3 className="modal-title mgB-b1">Signature de {capitalizeFirstLetter(props.entreeSortieZone.salarie.prenom)} {capitalizeFirstLetter(props.entreeSortieZone.salarie.nom)}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <img className='signature' src={`data:image/png;base64,${props.entreeSortieZone.signatureSalarie}`} alt="signature" />
        </div>
    );
};

export default SignatureSalarieModal;