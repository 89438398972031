import React from 'react';
import format from 'date-fns/format';

const ArchiveChantierDetail = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    return (
        <div className="modal-content supprimerForm">
            <h3 className='modal-title'>Historique du chantier {props.chantier.codeChantier} {props.chantier._id.libelle_lieu}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <hr />
            <ul className="resumeSalarieHeure">
                {props.chantier.historique.map((historique) => {
                    return <li key={historique._id}>{format(new Date(historique.dateAction), "dd/MM/yyyy")} -&gt; {historique.description}</li>;
                })}
            </ul>

        </div>
    );
};

export default ArchiveChantierDetail;