import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import SelectSearch from "../SelectSearch";
import axiosInstance from "../../utils/axiosInstance";
import customToast from "../../utils/ToastifyHelper";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/exportAction";
import SelectSearchCreatable from "./SelectSearchCreatable";
import { showError } from "../../utils/ErrorHelper";

const ModifierProcessusForm = (props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm();
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const allFormOptions = useSelector((state) => state.formOptions);

	const close = () => {
		props.closeModal();
		props.unrenderForm();
	};

	const dispatch = useDispatch();

	const onSubmit = (data) => {
		setButtonDisabled(true);
		const postData = {
			codeProcessus: data.codeProcessus,
			materiaux: data.materiaux,
			niveauEmpoussierement: data.niveauEmpoussierement.value,
			estimationConcentration: data.estimationConcentration,
			zone: data.zone !== null ? data.zone.value : data.zone,
			categorieMateriaux: data.categorieMateriaux
				? data.categorieMateriaux.value
				: "",
			categorieTechnique: data.categorieTechniques
				? data.categorieTechniques.value
				: "",
			outil: data.outilProcessus ? data.outilProcessus.value : "",
			protectionCollective: data.protectionCollectiveProcessus
				? data.protectionCollectiveProcessus.value
				: "",
			methode: data.methodeProcessus ? data.methodeProcessus.value : "",
		};

		if (postData.categorieMateriaux !== "") {
			if (
				!allFormOptions.categorieMateriaux.options.includes(
					postData.categorieMateriaux
				)
			) {
				dispatch(
					actionCreators.addFormOptions(
						"categorieMateriaux",
						postData.categorieMateriaux
					)
				);
			}
		}
		if (postData.categorieTechnique !== "") {
			if (
				!allFormOptions.categorieTechniques.options.includes(
					postData.categorieTechnique
				)
			) {
				dispatch(
					actionCreators.addFormOptions(
						"categorieTechniques",
						postData.categorieTechnique
					)
				);
			}
		}
		if (postData.outil !== "") {
			if (!allFormOptions.outilProcessus.options.includes(postData.outil)) {
				dispatch(
					actionCreators.addFormOptions("outilProcessus", postData.outil)
				);
			}
		}
		if (postData.protectionCollective !== "") {
			if (
				!allFormOptions.protectionCollectiveProcessus.options.includes(
					postData.protectionCollective
				)
			) {
				dispatch(
					actionCreators.addFormOptions(
						"protectionCollectiveProcessus",
						postData.protectionCollective
					)
				);
			}
		}
		if (postData.methode !== "") {
			if (!allFormOptions.methodeProcessus.options.includes(postData.methode)) {
				dispatch(
					actionCreators.addFormOptions("methodeProcessus", postData.methode)
				);
			}
		}
		axiosInstance
			.patch(`/processusAmiante/${props.processus._id}`, postData)
			.then((result) => {
				setButtonDisabled(false);
				customToast.success("Le processus a été modifié avec succès");
				close();
				dispatch(actionCreators.modifierProcessus(result.data));
			})
			.catch((err) => {
				setButtonDisabled(false);
				showError(err);
			});
	};

	const niveauEmpoussierementOption = [
		{ label: "Niveau 1", value: "1" },
		{ label: "Niveau 2", value: "2" },
		{ label: "Niveau 2+", value: "2+" },
		{ label: "Niveau 3", value: "3" },
	];

	const zoneOptions = [
		{ label: "Extérieur", value: "Extérieur" },
		{ label: "Intérieur", value: "Intérieur" }
	];

	return (
		<div className="modal-content modifier-form">
			<h3 className="modal-title">Modifier un processus</h3>
			<button className="modal-button" onClick={() => close()}>
				x
			</button>
			<form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
				<div className='label-and-input'>
					<label
						className='form-label'
						htmlFor="codeProcessus"
					>
						Code processus
					</label>
					<input
						defaultValue={props.processus.codeProcessus}
						className="new-default-input"
						name="codeProcessus"
						type="text"
						placeholder="Code processus"
						{...register("codeProcessus", { required: true })}
					/>
					{errors["codeProcessus"] &&
						errors["codeProcessus"].type === "required" && (
							<p className="login-error-message">
								Veuillez saisir un code processus
							</p>
						)}
				</div>
				<div className='label-and-input'>
					<label className="form-label" htmlFor="materiaux">
						Matériaux
					</label>
					<input
						defaultValue={props.processus.materiaux.materiaux}
						className="new-default-input"
						name="materiaux"
						type="text"
						placeholder="Matériaux"
						{...register("materiaux", { required: false })}
					/>
				</div>
				<div className='label-and-input'>
					<label
						className="form-label"
						htmlFor="niveauEmpoussierement"
					>
						Niveau d'empoussièrement
					</label>
					<SelectSearch
						options={niveauEmpoussierementOption}
						dataName="niveauEmpoussierement"
						control={control}
						name="niveauEmpoussierement"
						className="formSelect-search-container formSelect-search-container-full mgT-s2"
						classNamePrefix="formSelect-search"
						placeholder="Sélectionner le niveau d'empoussièrement"
						isClearable
						isSearchable={false}
						required
						styles={{
							control: (base, state) => ({
								...base,
								fontFamily: "DM Sans",
								border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
								borderRadius: "0.6rem",
								boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
								'&:hover': {
									border: '1px solid black',
								},
							})
						}}
						defaultValue={{
							label: `Niveau ${props.processus.niveauEmpoussierement}`,
							value: props.processus.niveauEmpoussierement,
						}}
					/>
					{errors["niveauEmpoussierement"] &&
						errors["niveauEmpoussierement"].type === "required" && (
							<p className="login-error-message">
								Veuillez sélectionner le niveau d'empoussièrement du processus
							</p>
						)}
				</div>
				<div className='label-and-input'>
					<label
						className="form-label"
						htmlFor="categorieMateriaux"
					>
						Catégorie du matériaux
					</label>
					<SelectSearchCreatable
						options={allFormOptions.categorieMateriaux.options}
						dataName="categorieMateriaux"
						control={control}
						name="categorieMateriaux"
						className="formSelect-search-container mgB-s2"
						classNamePrefix="formSelect-search"
						placeholder="Sélectionner une catégorie materiaux"
						defaultValue={
							props.processus.materiaux.categorie !== ""
								? {
									label: props.processus.materiaux.categorie,
									value: props.processus.materiaux.categorie,
								}
								: null
						}
						isClearable
						isSearchable
					/>
				</div>
				<label
					className="ajouter-form-label default-label"
					htmlFor="categorieTechniques"
				>
					Catégorie de la technique et mode opératoires
				</label>
				<SelectSearchCreatable
					options={allFormOptions.categorieTechniques.options}
					dataName="categorieTechniques"
					control={control}
					name="categorieTechniques"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner la catégorie de la technique du processus"
					defaultValue={
						props.processus.techniqueModesOperatoires.categorie !== ""
							? {
								label: props.processus.techniqueModesOperatoires.categorie,
								value: props.processus.techniqueModesOperatoires.categorie,
							}
							: null
					}
					isClearable
					isSearchable
				/>
				<label
					className="ajouter-form-label default-label"
					htmlFor="methodeProcessus"
				>
					Méthode de la technique et mode opératoires
				</label>
				<SelectSearchCreatable
					options={allFormOptions.methodeProcessus.options}
					dataName="methodeProcessus"
					control={control}
					name="methodeProcessus"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner la méthode de la technique du processus"
					defaultValue={
						props.processus.techniqueModesOperatoires.methode !== ""
							? {
								label: props.processus.techniqueModesOperatoires.methode,
								value: props.processus.techniqueModesOperatoires.methode,
							}
							: null
					}
					isClearable
					isSearchable
				/>
				<label
					className="ajouter-form-label default-label"
					htmlFor="outilProcessus"
				>
					Outil de la technique et mode opératoires
				</label>
				<SelectSearchCreatable
					options={allFormOptions.outilProcessus.options}
					dataName="outilProcessus"
					control={control}
					name="outilProcessus"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner une option"
					defaultValue={
						props.processus.techniqueModesOperatoires.outil !== ""
							? {
								label: props.processus.techniqueModesOperatoires.outil,
								value: props.processus.techniqueModesOperatoires.outil,
							}
							: null
					}
					isClearable
					isSearchable
				/>
				<label
					className="ajouter-form-label default-label"
					htmlFor="outilProcessus"
				>
					Moyen de protection collective
				</label>
				<SelectSearchCreatable
					options={allFormOptions.protectionCollectiveProcessus.options}
					dataName="protectionCollectiveProcessus"
					control={control}
					name="protectionCollectiveProcessus"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner une option"
					defaultValue={
						props.processus.protectionCollective !== ""
							? {
								label: props.processus.protectionCollective,
								value: props.processus.protectionCollective,
							}
							: null
					}
					isClearable
					isSearchable
				/>
				<label
					className="ajouter-form-label default-label"
					htmlFor="niveauEmpoussierement"
				>
					Zone
				</label>
				<SelectSearch
					options={zoneOptions}
					dataName="zone"
					control={control}
					name="zone"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner la zone"
					defaultValue={{ label: props.processus.zone, value: props.processus.zone }}
					isClearable
					isSearchable={false}
					required={false}
				/>
				<label className='ajouter-form-label default-label' htmlFor="analyseRC">Estimation concentration</label>
				<input placeholder="Entrer l'estimation de la concentration" defaultValue={props.processus.estimationConcentration} className="ajouter-form-input default-input" step=".01" name="estimationConcentration" type="number" {...register("estimationConcentration", { required: true, min: 0 })} />
				{errors['estimationConcentration'] && errors['estimationConcentration'].type === "required" && <p className="login-error-message">Veuillez saisir l'estimation de la concentration</p>}
				{errors['estimationConcentration'] && errors['estimationConcentration'].type === "min" && <p className="login-error-message">Veuillez saisir une valeur supérieur à 0</p>}
				<div className="button-container-flex">
					<button type="button" disabled={isButtonDisabled} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => close()}>Annuler</button>
					<input disabled={isButtonDisabled} style={{ opacity: isButtonDisabled ? 0.4 : 1 }} type="submit" className='btn btn-primary medium-btn fw' value="Modifier" />
				</div>
			</form>
		</div>
	);
};

export default ModifierProcessusForm;
