import React, { useState } from 'react';
import customToast from '../../../utils/ToastifyHelper';
import * as actionCreators from '../../../store/actions/exportAction';
import axiosInstance from '../../../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import { showError } from '../../../utils/ErrorHelper';

const addCategorie = (categorie) => (actionCreators.addCategorie(categorie));


const AjouterCategorieDocumentsForm = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [ajouterCategorieText, setAjouterCategorieText] = useState("");
    const dispatch = useDispatch();

    const onSubmit = () => {
        setButtonDisabled(true);
        if (ajouterCategorieText.length > 0) {
            axiosInstance.post('/categories', { libelleCategorie: ajouterCategorieText })
                .then((result) => {
                    dispatch(addCategorie(result.data));
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
        }
        else {
            customToast.error("Veuillez entrer un nom de catégorie");
            setButtonDisabled(false);
        }
    };

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Ajouter une catégorie de document</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <label className='ajouter-form-label default-label' htmlFor="nom">Nom de la catégorie</label>
                <input onChange={(e) => setAjouterCategorieText(e.target.value)} className="ajouter-form-input default-input" name="nom" type="text" placeholder="Entrez le nom de la catégorie" />
                <button onClick={() => onSubmit()} disabled={isButtonDisabled} className="validate-button-fw">Ajouter</button>
            </div>
        </div>
    );
};

export default AjouterCategorieDocumentsForm;