import React, { useEffect } from 'react';
import LoginForm from '../components/forms/LoginForm';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    // Si state contient tous redirection home
    const isAuthenticated = useSelector(
        state => state.user.isAuth
    );
    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);
    let loginForm = null;
    if (!isAuthenticated) {
        loginForm = <LoginForm />;
    }
    return (
        <div className="layout login-layout">
            {loginForm}
        </div>
    );
};

export default LoginPage;
