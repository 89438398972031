import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const absenceListState = { absences: [] };

const reducer = (state = absenceListState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_ALL_ABSENCES:
            return updateObject(state, { absences: action.absenceList });
        case actionTypes.UNLOAD_ALL_ABSENCES:
            return { absences: [] };
        case actionTypes.ADD_ABSENCE:
            return updateObject(state, { absences: state.absences.concat(action.absence) });
        case actionTypes.MODIFY_ABSENCE:
            return {
                ...state,
                absences: state.absences.map(
                    (absence) => absence.utilisateur_absent._id === action.oldAbsenceId.utilisateur_absent._id && absence.date_deb_absence === action.oldAbsenceId.date_deb_absence ?
                        {
                            ...absence,
                            utilisateur_absent: action.absenceUpdated.utilisateur_absent,
                            date_deb_absence: action.absenceUpdated.date_deb_absence,
                            type_absence: action.absenceUpdated.type_absence,
                            date_fin_absence: action.absenceUpdated.date_fin_absence,
                            commentaire: action.absenceUpdated.commentaire,
                            tempsAbsence: {
                                ...absence.tempsAbsence,
                                heure: action.absenceUpdated.tempsAbsence.heure,
                                minute: action.absenceUpdated.tempsAbsence.minute,
                            }
                        }
                        :
                        absence
                )
            };
        case actionTypes.DELETE_ABSENCE:
            return {
                ...state,
                absences: state.absences.filter(absence => absence._id !== action.absenceId)
            };
        default:
            return state;
    }
};

export default reducer;
