import React, { useState } from 'react';
import EditIcon from './icon/EditIcon';
import SupprimerIcon from './icon/SupprimerIcon';
import { Icon } from '@iconify/react';
import customToast from '../utils/ToastifyHelper';
import axiosInstance from '../utils/axiosInstance';
import * as actionCreators from '../store/actions/exportAction';
import { useDispatch } from 'react-redux';
import { Collapse } from 'react-collapse';
import { showError } from '../utils/ErrorHelper';

const addType = (payload) => (actionCreators.addTypeDocuments(payload));

const CategorieItem = (props) => {
    const [isCategorieOpen, setCategorieOpen] = useState(false);
    const [ajouterTypeText, setAjouterTypeText] = useState("");
    const dispatch = useDispatch();

    const clickOnRow = (event) => {
        if (event.target.tagName !== "BUTTON" && (event.target.tagName !== "svg" || event.target.parentNode.tagName !== "BUTTON") && (event.target.tagName !== "path" || event.target.parentNode.parentNode.tagName !== "BUTTON")) {
            setCategorieOpen(!isCategorieOpen);
        }
    };

    const submitNewType = () => {
        if (ajouterTypeText.length > 0) {
            axiosInstance.post("/typeDocuments", { libelleType: ajouterTypeText })
                .then((result) => {
                    axiosInstance.patch(`/categories/ajouterType/${props.categorie._id}`, { "idTypeDocument": result.data._id })
                        .then(() => {
                            dispatch(addType({ typeDocuments: result.data, categorie: props.categorie._id }));
                            setAjouterTypeText("");
                        }).catch((error) => {
                            showError(error);
                        });
                }).catch((err) => {
                    showError(err);
                });
        }
        else {
            customToast.error("Veuillez entrer un nom de type de fichiers");
        }
    };


    return (
        <>
            <div
                onClick={(e) => clickOnRow(e)}
                style={{ borderTop: props.border ? "1px solid #ddd" : "" }}
                className={isCategorieOpen ? "categorieContainer open" : "categorieContainer"}
            >
                <p>{props.categorie.libelleCategorie}</p>
                <p>{props.categorie.typeDocuments.length}</p>
                <div className="table-buton-container" style={{ height: "4rem" }}>
                    {props.categorie.libelleCategorie !== "Sans catégorie" &&
                        <button onClick={() => props.openModifierCategorie(props.categorie)} className='table-modify-button'><EditIcon /></button>
                    }
                    {props.categorie.libelleCategorie !== "Sans catégorie" &&
                        <button onClick={() => props.openSupprimerCategorie(props.categorie)} className='table-delete-button'><SupprimerIcon /></button>
                    }
                </div>
                <Icon className={isCategorieOpen ? "chevronCategorie open" : "chevronCategorie"} icon="dashicons:arrow-right-alt2" />
            </div>
            <Collapse isOpened={isCategorieOpen}>
                <div className='typeDocuments-container'>
                    <div className="typeDocuments-item">
                        <input className="ajouterType-item" value={ajouterTypeText} onChange={(e) => setAjouterTypeText(e.target.value)} name="ajouterType" type="text" placeholder="Ajouter un type de document" />
                        <button onClick={() => submitNewType()} className='table-modify-button'>+</button>
                    </div>
                    {props.categorie.typeDocuments.map((type) => {
                        return (
                            <div key={type._id} className="typeDocuments-item">
                                <p>{type.libelleType}</p>
                                <div className="table-buton-container">
                                    <Icon onClick={() => props.openModifierType({ categorie: props.categorie, typeDocument: type })} icon="ci:edit" />
                                    <Icon onClick={() => props.openSupprimerType({ categorie: props.categorie, typeDocument: type })} icon="fluent:delete-20-filled" />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Collapse>
        </>
    );
};

export default CategorieItem;