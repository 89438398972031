import axiosInstance from '../../utils/axiosInstance';
import * as actionTypes from './actionTypes';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';

const loadAdminReleveHeure = (releveHeureList) => {
    return {
        type: actionTypes.LOAD_ADMIN_RELEVE_HEURE_CHEF,
        releveHeure: releveHeureList
    };
};

export const unloadAdminReleveHeure = () => {
    return {
        type: actionTypes.UNLOAD_ADMIN_RELEVE_HEURE
    };
};

export const updateReleveHeureAdmin = (annee, numeroSemaine, newSemaine) => {
    return {
        type: actionTypes.UPDATE_RELEVE_HEURE_ADMIN,
        annee: annee,
        numeroSemaine: numeroSemaine,
        newSemaine: newSemaine
    };
};

export const getAdminReleveHeureChef = (idChef) => (dispatch) => {
    axiosInstance.get(`horaires_semaines/releves-heures/${idChef}`)
        .then((result) => {
            dispatch(loadAdminReleveHeure(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getAdminReleveHeure = (idReleve) => (dispatch) => {
    axiosInstance.get(`horaires_semaines/${idReleve}`)
        .then((result) => {
            dispatch(loadAdminReleveHeure([result.data]));
        }).catch((err) => {
            showError(err);
        });
};

export const getAllReleveHeure = () => (dispatch) => {
    axiosInstance.get('horaires_semaines').then((result) => {
        dispatch(loadAdminReleveHeure(result.data));
    }).catch((err) => {
        showError(err);
    });
};

export const changeStatutSemaine = (idSemaine, numStatut) => {
    return {
        type: actionTypes.CHANGE_STATUT_RELEVE_HEURE_ADMIN,
        idSemaine: idSemaine,
        numStatut: numStatut
    };
};