import React, { useEffect, useRef, useState, useMemo } from 'react';
import LoadingBar from 'react-top-loading-bar';
import * as yup from "yup";
import { useFormik } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, capitalizeFirstLetter, getLastModified } from '../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Modal from '../../components/Modal';

import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import Select from "react-select";




const ModifierActualite = () => {
    const progressBarRef = useRef(null);
    let { actualiteId } = useParams();
    const [actualite, setActualite] = useState(null);
    const [newArticlePicture, setNewArticlePicture] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [pictureDeleted, setPictureDeleted] = useState(null);
    const [optionsOrder, setOptionsOrder] = useState([]);
    const navigate = useNavigate();

    const formSchema = yup.object().shape({
        titre: yup.string().required("Veuillez saisir le titre de l'article"),
        articleContent: yup.string().required("Veuillez saisir le contenu de l'article"),
        order: yup.object().shape({
            label: yup.number().required("Veuillez saisir la position de l'actualité"),
            value: yup.number().required("Veuillez saisir la position de l'actualité")
        }).nullable(true)
    });

    const onSubmit = (values) => {
        progressBarRef.current.continuousStart();
        if (pictureDeleted !== null) {
            if (newArticlePicture !== null) {
                let postData = { ...values, order: actualite.etat === -1 ? null : values.order.value };
                let picturePostData = new FormData();
                picturePostData.append("titre", newArticlePicture.path.split(".").slice(0, -1)[0].normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                picturePostData.append("document", newArticlePicture);
                picturePostData.append("documentSaveRoute", "saveImages");
                axiosInstance.delete(`/uploads/${pictureDeleted}`).then(() => {
                    axiosInstance.post("/uploads", picturePostData, { timeout: 0, headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
                        postData.picture = result.data.picture;
                        axiosInstance.patch(`/actualites/${actualite._id}`, postData).then((result2) => {
                            progressBarRef.current.complete();
                            setSubmitting(false);
                            setActualite(result2.data);
                            setPictureDeleted(null);
                            if (result2.data.pictures.hasOwnProperty("articlePicture") && result2.data.pictures.articlePicture !== null) {
                                // load actualite image
                                axiosInstance.get(`uploads/documents/${result2.data.pictures.articlePicture._id}`, {
                                    responseType: 'blob',
                                }).then((res) => {
                                    let fileURL = URL.createObjectURL(res.data);
                                    setImagePreview(fileURL);
                                }).catch((error) => {
                                    progressBarRef.current.complete();
                                    setSubmitting(false);
                                    showError(error);
                                });
                            }
                        }).catch((error2) => {
                            progressBarRef.current.complete();
                            setSubmitting(false);
                            showError(error2);
                        });
                    }).catch((err) => {
                        progressBarRef.current.complete();
                        setSubmitting(false);
                        showError(err);
                    });
                }).catch((error) => {
                    progressBarRef.current.complete();
                    setSubmitting(false);
                    showError(error);
                });
            }
            else {
                axiosInstance.delete(`/uploads/${pictureDeleted}`).then(() => {
                    axiosInstance.patch(`/actualites/${actualite._id}`, { titre: values.titre, articleContent: values.articleContent, picture: null, order: actualite.etat === -1 ? null : values.order.value }).then((result) => {
                        progressBarRef.current.complete();
                        setSubmitting(false);
                        setActualite(result.data);
                        setPictureDeleted(null);
                    }).catch((err) => {
                        progressBarRef.current.complete();
                        setSubmitting(false);
                        showError(err);
                    });
                }).catch((error) => {
                    setSubmitting(false);
                    progressBarRef.current.complete();
                    showError(error);
                });
            }
        }
        else {
            if (newArticlePicture !== null) {
                // console.log(newArticlePicture.path.split(".").slice(0, -1)[0].normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                let postData = { ...values, order: actualite.etat === -1 ? null : values.order.value };
                let picturePostData = new FormData();
                picturePostData.append("titre", newArticlePicture.name.replace(/\.[^/.]+$/, ""));
                picturePostData.append("document", newArticlePicture);
                picturePostData.append("documentSaveRoute", "saveImages");
                axiosInstance.post("/uploads", picturePostData, { timeout: 0, headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
                    postData.picture = result.data.picture;
                    console.log(postData.picture);
                    axiosInstance.patch(`/actualites/${actualite._id}`, postData).then((result2) => {
                        progressBarRef.current.complete();
                        setSubmitting(false);
                        setActualite(result2.data);
                        setPictureDeleted(null);
                        if (result2.data.pictures.hasOwnProperty("articlePicture") && result2.data.pictures.articlePicture !== null) {
                            // load actualite image
                            axiosInstance.get(`uploads/documents/${result2.data.pictures.articlePicture._id}`, {
                                responseType: 'blob',
                            }).then((res) => {
                                let fileURL = URL.createObjectURL(res.data);
                                setImagePreview(fileURL);
                            }).catch((error) => {
                                progressBarRef.current.complete();
                                setSubmitting(false);
                                showError(error);
                            });
                        }
                    }).catch((error2) => {
                        progressBarRef.current.complete();
                        setSubmitting(false);
                        showError(error2);
                    });
                }).catch((err) => {
                    progressBarRef.current.complete();
                    setSubmitting(false);
                    showError(err);
                });
            }
            else {
                axiosInstance.patch(`/actualites/${actualite._id}`, { titre: values.titre, articleContent: values.articleContent, order: actualite.etat === -1 ? null : values.order.value }).then((result) => {
                    progressBarRef.current.complete();
                    setSubmitting(false);
                    setActualite(result.data);
                    setPictureDeleted(null);
                    if (result.data.pictures.hasOwnProperty("articlePicture") && result.data.pictures.articlePicture !== null) {
                        // load actualite image
                        axiosInstance.get(`uploads/documents/${result.data.pictures.articlePicture._id}`, {
                            responseType: 'blob',
                        }).then((res) => {
                            let fileURL = URL.createObjectURL(res.data);
                            setImagePreview(fileURL);
                        }).catch((error) => {
                            progressBarRef.current.complete();
                            setSubmitting(false);
                            showError(error);
                        });
                    }
                }).catch((err) => {
                    progressBarRef.current.complete();
                    setSubmitting(false);
                    showError(err);
                });
            }
        }
    };

    // Handle formm
    const { values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting, submitForm, setSubmitting } = useFormik({
        initialValues: {
            titre: "",
            articleContent: "",
            order: null
        },
        validationSchema: formSchema,
        onSubmit
    });

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/actualites/${actualiteId}`).then((result) => {
            progressBarRef.current.complete();
            setActualite(result.data);
            setFieldValue("titre", result.data.titre);
            setFieldValue("articleContent", result.data.articleContent);
            if (result.data.hasOwnProperty("order")) {
                setFieldValue("order", { label: result.data.order + 1, value: result.data.order });
            }
            if (result.data.pictures.hasOwnProperty("articlePicture") && result.data.pictures.articlePicture !== null) {
                // load actualite image
                axiosInstance.get(`uploads/documents/${result.data.pictures.articlePicture._id}`, {
                    responseType: 'blob',
                }).then((res) => {
                    let fileURL = URL.createObjectURL(res.data);
                    setImagePreview(fileURL);
                }).catch((error) => {
                    showError(error);
                });
            }
            axiosInstance.get("/actualites/actualitesNumber").then((res) => {
                let options = [];
                for (let index = 0; index < res.data; index++) {
                    options.push({ label: index + 1, value: index });
                }
                setOptionsOrder(options);
            }).catch((error) => {
                showError(error);
            });
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    }, []);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (newArticlePicture !== null) {
            URL.revokeObjectURL(imagePreview);
        }
        if (acceptedFiles.length) {
            setNewArticlePicture(acceptedFiles[0]);
            setImagePreview(URL.createObjectURL(acceptedFiles[0]));
        }
    }, [acceptedFiles]);

    const deleteImage = () => {
        if (newArticlePicture === null) {
            setPictureDeleted(actualite.pictures.articlePicture._id);
            setActualite({ ...actualite, pictures: { ...actualite.pictures, articlePicture: null } });
        }
        else {
            setNewArticlePicture(null);
        }
        URL.revokeObjectURL(imagePreview);
        setImagePreview(null);
    };

    const onPressActualitePublic = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/actualites/changeVisibility/${actualite._id}`, { etat: 1 }).then(() => {
            progressBarRef.current.complete();
            setActualite({ ...actualite, etat: 1 });
        }).catch((err) => {
            showError(err);
        });
    };

    const onPressActualitePrivee = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/actualites/changeVisibility/${actualite._id}`, { etat: 0 }).then(() => {
            progressBarRef.current.complete();
            setActualite({ ...actualite, etat: 0 });
        }).catch((err) => {
            showError(err);
        });
    };

    const onPressDesarchiverActualite = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/actualites/changeVisibility/${actualite._id}`, { etat: 0 }).then((res) => {
            progressBarRef.current.complete();
            setFieldValue("order", { label: res.data.order + 1, value: res.data.order });
            setActualite({ ...actualite, etat: 0, order: res.data.order });
        }).catch((err) => {
            showError(err);
        });
    };

    // modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalActualiteSelected, setModalActualiteSelected] = useState({});

    const deleteArticle = () => {
        navigate("/justeau-actus/actualites");
    };

    const archiverArticle = () => {
        setActualite({ ...actualite, etat: -1 });
    };

    const openSupprimerModal = () => {
        setModalActualiteSelected(actualite);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const openArchiverModal = () => {
        setModalActualiteSelected(actualite);
        setModalClasses("modal modal__active");
        setModalContent("archiver");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const actualiteHasBeenUpdated = () => {
        if (actualite !== null) {
            if (actualite.titre !== values.titre || actualite.articleContent !== values.articleContent) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div className='mgB-m1' style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                }}>
                    <h1>Modifier une actualité</h1>
                    <button onClick={() => submitForm()} disabled={isSubmitting} style={{ opacity: !isSubmitting ? 1 : 0.4 }} className="btn btn-primary medium-btn" type="submit" form="addArticle">Sauvegarder</button>
                </div>
                <div className="edit-actualite-container">
                    <div className='container-basic actualite-content' id="addArticle">
                        <form className='form'>
                            <div className='label-and-input'>
                                <label htmlFor='nom' className='form-label'><h4 className=''>Titre</h4></label>
                                <input name='titre' className={`new-default-input${errors.titre && touched.titre ? " input-error" : ""}`} type="text" placeholder="Titre de l'article" value={values.titre} onChange={handleChange} onBlur={handleBlur} />
                                {errors.titre && touched.titre && <p className="form-error-message">{errors.titre}</p>}
                            </div>
                            <div className='label-and-input'>
                                <label htmlFor='articleContent' className='form-label'>Contenu de l'article</label>
                                <div className='mgT-s2'>
                                    <Editor
                                        textareaName='articleContent'
                                        apiKey='amwslsmq87or9tnsmqt8c5hikteg74lm1rmajlr0isluzbx9'
                                        init={{
                                            branding: false,
                                            menubar: false,
                                            image_advtab: false,
                                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link'
                                        }}
                                        value={values.articleContent}
                                        onEditorChange={(newText) => { setFieldValue("articleContent", newText); }}
                                    />
                                </div>
                                {errors.articleContent && touched.articleContent && <p className="form-error-message">{errors.articleContent}</p>}
                            </div>
                            {actualite !== null &&
                                <div className='label-and-input'>
                                    <label htmlFor='image' className='form-label'>Image principale</label>
                                    {(!actualite.pictures.hasOwnProperty("articlePicture") || actualite.pictures.articlePicture === null) && newArticlePicture === null
                                        ?
                                        <div className='mgT-s2'>
                                            <div {...getRootProps({ style })}>
                                                <input {...getInputProps()} />
                                                <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                                <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className='mgT-s2' style={{
                                                overflow: 'hidden',
                                            }}>
                                                <div className='addImageContainer'>
                                                    <img
                                                        src={imagePreview}
                                                        className='articleImage'
                                                        // style={{  
                                                        //     display: 'block',
                                                        //     maxWidth: '100%',
                                                        //     maxHeight: '450px'
                                                        // }}
                                                        onLoad={() => { URL.revokeObjectURL(imagePreview); }}
                                                    >
                                                    </img>
                                                    <div className='imageDeleteOverlay'>
                                                        <button onClick={() => deleteImage()} className="btn_image"><FontAwesomeIcon icon={faTrashCan} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }


                        </form>
                    </div>
                    {actualite !== null &&
                        <div className="actualite-info">
                            <div className="container-basic">
                                <p>INFORMATIONS</p>
                                <hr style={{ marginTop: "0.4rem", backgroundColor: "#d9d9d9 ", marginBottom: "0.6rem" }} />
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <p style={{ fontWeight: 500 }}>Création</p>
                                        <p>Il y a {getLastModified(new Date(actualite.created))}</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <p style={{ fontWeight: 500 }}>Auteur</p>
                                        <p>{capitalizeFirstLetter(actualite.author.nom)} {capitalizeFirstLetter(actualite.author.prenom)}</p>
                                    </div>
                                </div>
                                {actualite.hasOwnProperty("lastEditor") && actualite.lastEditor !== null &&
                                    <div className='mgT-s1'>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <p style={{ fontWeight: 500 }}>Dernière modification</p>
                                            <p>Il y a {getLastModified(new Date(actualite.created))}</p>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <p style={{ fontWeight: 500 }}>Par</p>
                                            <p>{capitalizeFirstLetter(actualite.author.nom)} {capitalizeFirstLetter(actualite.author.prenom)}</p>
                                        </div>
                                    </div>
                                }
                                <div className='mgT-s3'>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <p style={{ fontWeight: 500 }}>Visibilité</p>
                                        <p>{actualite.etat === -1 ? "Archivée" : actualite.etat === 1 ? "Public" : "Privée"}</p>
                                    </div>
                                </div>
                                {actualite.etat >= 0 &&
                                    <div className='mgT-s3'>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <p style={{ fontWeight: 500, flex: 1 }}>Ordre</p>
                                            <div style={{ flex: 0.5 }}>
                                                <Select
                                                    options={optionsOrder}
                                                    className="formSelect-search-container formSelect-search-container-full"
                                                    classNamePrefix="formSelect-search"
                                                    onChange={(opt) => setFieldValue("order", opt)}
                                                    placeholder={""}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            fontFamily: "DM Sans",
                                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                            borderRadius: "0.6rem",
                                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                            '&:hover': {
                                                                border: '1px solid black',
                                                            },
                                                        })
                                                    }}
                                                    isSearchable={true}
                                                    isClearable={false}
                                                    value={values.order ? { value: values.order.value, label: values.order.label } : null}
                                                />
                                                {errors.order && touched.order && <p className="form-error-message">{errors.order}</p>}
                                            </div>
                                            {/* <input  name='order' className={`new-default-input${errors.order && touched.order ? " input-error" : ""}`} type="number" value={values.order} onChange={handleChange} onBlur={handleBlur} /> */}
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                actualite.etat === -1 ?
                                    <button onClick={() => onPressDesarchiverActualite()} style={{ width: "100%" }} className='oldButton yellow-button mgT-s3'>Désarchiver l'actualité</button>
                                    :
                                    actualite.etat === 1 ?
                                        <button onClick={() => onPressActualitePrivee()} style={{ width: "100%" }} className='oldButton yellow-button mgT-s3'>Mettre l'actualité en privée</button>
                                        :
                                        <button onClick={() => { onPressActualitePublic(); }} style={{ width: "100%" }} className='oldButton green-button mgT-s3'>Rendre l'actualité public</button>
                            }
                            {actualite.etat === - 1
                                ?
                                <button onClick={() => openSupprimerModal()} style={{ width: "100%" }} className='oldButton red-button mgT-s3'>Supprimer l'actualité</button>
                                :
                                <button onClick={() => openArchiverModal()} style={{ width: "100%" }} className='oldButton red-button mgT-s3'>Archiver l'actualité</button>
                            }
                        </div>
                    }
                </div>
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "supprimer" &&
                    <SupprimerModalForm key={modalActualiteSelected._id} destination="supprimerActualite" actualiteData={modalActualiteSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} deleteArticle={() => deleteArticle()} />
                }
                {modalContent === "archiver" &&
                    <SupprimerModalForm key={modalActualiteSelected._id} destination="archiverActualite" actualiteData={modalActualiteSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} archiverArticle={() => archiverArticle()} />
                }
            </Modal>
        </>
    );
};

export default ModifierActualite;;