import React from 'react';
import justeauLogo from '../assets/img/justeaulogo.png';

const Support = () => {
    return (
        <>
            <div className="dashboard-header-chef">
                <div className="left-header-chef">
                    <img className="chef-header-logo" src={justeauLogo} alt="justeau logo" />
                </div>
            </div>
            <div className='privacyPolicyContainer'>
                <h1>Page d'assistance</h1>
                <div className="privacyPolicySection">
                    <h2>Contact</h2>
                    <p style={{ marginBottom: 28 }}>
                        Pour toutes questions concernant l'usage de l'application (bugs, améliorations), veuillez nous contacter par email à <a href='mailto:benoitg@terredepixels.fr'>benoitg@terredepixels.fr</a>.
                    </p>
                    <hr style={{ marginBottom: 28 }} />
                    <p>
                        For any questions on the Application usage (bugs, improvement), please contact us via email at <a href='mailto:benoitg@terredepixels.fr'>benoitg@terredepixels.fr</a>.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Support;