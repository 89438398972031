import React, { useState, useEffect, useRef } from 'react';
import { dateToHoursAndMinutesString } from '../../utils/GeneralHelper';
import Select from 'react-select';
import customToast from '../../utils/ToastifyHelper';
import axiosInstance from '../../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import { showError } from '../../utils/ErrorHelper';
import MyTimeInput from '../MyTimeInput';
import SupprimerIcon from '../icon/SupprimerIcon';
import EditIcon from '../icon/EditIcon';

const DetailVacationSalarie = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };
    const dispatch = useDispatch();
    const [canEdit, setCanEdit] = useState(false);
    const [verificationEquipements, setVerificationEquipements] = useState(props.entreeSortie.verificationEquipements);
    const [verificationEpi, setVerificationEpi] = useState(props.entreeSortie.equipements);
    const [selectedPhase, setSelectedPhase] = useState({ label: props.entreeSortie.phaseChantier, value: props.entreeSortie.phaseChantier });
    const [recuperationType, setRecuperationType] = useState(props.entreeSortie.recuperationAmiante.recuperation ? { label: "Récupération avec exposition", value: "Récupération avec exposition" } : { label: "Pause sans exposition", value: "Pause sans exposition" });
    const phasesChantierOptions = [
        { label: "Préparation", value: "Préparation" },
        { label: "Retrait", value: "Retrait" },
        { label: "SAS personnel", value: "SAS personnel" },
        { label: "SAS matériel", value: "SAS matériel" },
        { label: "Nettoyage", value: "Nettoyage" },
        { label: "Sortie déchets", value: "Sortie déchets" },
        { label: "Repli", value: "Repli" }
    ];
    const recuperationTypes = [
        { label: "Récupération avec exposition", value: "Récupération avec exposition" },
        { label: "Pause sans exposition", value: "Pause sans exposition" },
    ];
    const [temperature, setTemperature] = useState(props.entreeSortie.temperature);
    const [operations, setOperations] = useState(props.entreeSortie.operations.map((operation) => {
        return { ...operation, dateHeureDebut: { heure: dateToHoursAndMinutesString(operation.dateHeureDebut).substring(0, 2), minute: dateToHoursAndMinutesString(operation.dateHeureDebut).substring(3, 5) }, dateHeureSortie: { heure: dateToHoursAndMinutesString(operation.dateHeureSortie).substring(0, 2), minute: dateToHoursAndMinutesString(operation.dateHeureSortie).substring(3, 5) } };
    }));


    // DUREE PAUSE INPUT
    const minuteRef = useRef();
    const [dureePause, setDureePause] = useState(props.entreeSortie.recuperationAmiante.recuperation && props.entreeSortie.recuperationAmiante.dateHeureSortie !== null ? { heure: dateToHoursAndMinutesString(props.entreeSortie.recuperationAmiante.dateHeureSortie).substring(0, 2), minute: dateToHoursAndMinutesString(props.entreeSortie.recuperationAmiante.dateHeureSortie).substring(3, 5) } : { heure: "", minute: "" });

    const onTimeInput = (horaire, temps) => {
        let formatHoraire = horaire.target.value;
        if (temps === "Heure") {
            if (parseInt(formatHoraire) > 23) {
                // modifier le processus via fonction props
                setDureePause({ ...dureePause, heure: "23" });
                minuteRef.current.focus();
            }
            else {
                setDureePause({ ...dureePause, heure: formatHoraire });
                if (formatHoraire.length === 2) {
                    minuteRef.current.focus();
                }
            }
        }
        else {
            if (parseInt(formatHoraire) > 59) {
                setDureePause({ ...dureePause, minute: "59" });
                minuteRef.current.blur();

            }
            else {
                setDureePause({ ...dureePause, minute: formatHoraire });
                if (formatHoraire.length === 2) {
                    minuteRef.current.blur();
                }
            }
        }
    };

    const modifierVacations = () => {
        let epi = verificationEpi.map((item) => {
            return item._id;
        });
        let equipements = verificationEquipements.map((item) => {
            return item._id;
        });

        let dureePauseDate = null;
        if (recuperationType.label === "Récupération avec exposition" && dureePause.heure !== "" && dureePause.minute !== "") {
            if (operations.length) {
                dureePauseDate = new Date(props.entreeSortie.operations[0].dateHeureDebut);
            }
            else {
                dureePauseDate = new Date();
            }
            dureePauseDate.setHours(parseInt(dureePause.heure));
            dureePauseDate.setMinutes(parseInt(dureePause.minute));
            dureePauseDate = dureePauseDate.toISOString();
        }

        const modifData = {
            equipements: epi,
            verificationEquipements: equipements,
            temperature,
            phaseChantier: selectedPhase.value,
            recuperationType: recuperationType.label,
            dureePause: dureePauseDate
        };

        console.log(modifData);

        axiosInstance.patch(`/entree_sortie_zone/${props.idVacation}/edit/${props.entreeSortie._id}`, modifData)
            .then((result) => {
                // dispatch(setEntreeSortieZone(result.data))
                props.modifierUnEntreeSortieZone(result.data);
                customToast.success("La vacation a été modifiée avec succès");
                close();
            }).catch((err) => {
                showError(err);
            });
    };

    const addAutoControle = (autoControle) => {
        setVerificationEquipements(verificationEquipements.concat(autoControle));
    };

    const removeAutoControle = (autoControle) => {
        setVerificationEquipements(verificationEquipements.filter(verification => {
            return verification._id !== autoControle._id;
        }));
    };

    const addEpi = (epi) => {
        setVerificationEpi(verificationEpi.concat(epi));
    };

    const removeEpi = (epi) => {
        setVerificationEpi(verificationEpi.filter(verifEpi => {
            return verifEpi._id !== epi._id;
        }));
    };

    const isAutoControleActive = (autoControle) => {
        return (verificationEquipements.findIndex(item => item._id === autoControle._id) >= 0);
    };

    const isEpiActive = (epi) => {
        return (verificationEpi.findIndex(item => item._id === epi._id) >= 0);
    };

    const updateCanEdit = () => {
        if (checkUpdatePhase() || checkUpdateOnEquipement() || checkUpdateOnEpi() || checkUpdateOnTemperature() || checkUpdateRecuperationType() || checkUpdateRecuperationDuree()) {
            if (!canEdit) {
                setCanEdit(true);
            }
        }
        else {
            if (canEdit) {
                setCanEdit(false);
            }
        }
    };

    const checkUpdateOnTemperature = () => {
        return temperature !== props.entreeSortie.temperature;
    };

    const checkUpdatePhase = () => {
        return !(props.entreeSortie.phaseChantier === selectedPhase.value);
    };

    const checkUpdateOnEquipement = () => {
        for (const equipement of props.entreeSortie.verificationEquipements) {
            if (!(verificationEquipements.findIndex(item => item._id === equipement._id) >= 0)) {
                return true;
            }
        }
        return (!(props.entreeSortie.verificationEquipements.length === verificationEquipements.length));
    };

    const checkUpdateOnEpi = () => {
        for (const epi of props.entreeSortie.equipements) {
            if (!(verificationEpi.findIndex(item => item._id === epi._id) >= 0)) {
                return true;
            }
        }
        return (!(props.entreeSortie.equipements.length === verificationEpi.length));
    };

    const checkUpdateRecuperationType = () => {
        let defaultRecuperationType = props.entreeSortie.recuperationAmiante.recuperation ? "Récupération avec exposition" : "Pause sans exposition";
        return (defaultRecuperationType !== recuperationType.label);
    };

    const checkUpdateRecuperationDuree = () => {
        let defaultHeure = props.entreeSortie.recuperationAmiante.recuperation && props.entreeSortie.recuperationAmiante.dateHeureSortie !== null ? { heure: dateToHoursAndMinutesString(props.entreeSortie.recuperationAmiante.dateHeureSortie).substring(0, 2), minute: dateToHoursAndMinutesString(props.entreeSortie.recuperationAmiante.dateHeureSortie).substring(3, 5) } : { heure: "", minute: "" };
        return (defaultHeure.heure !== dureePause.heure || defaultHeure.minute !== dureePause.minute);
    };

    useEffect(() => {
        updateCanEdit();
    }, [verificationEquipements, verificationEpi, selectedPhase, temperature, dureePause, recuperationType]);

    const modifierOperationsInput = (operationId, intervalle, horaire) => {
        setOperations(operations.map((operation) => {
            if (operationId === operation._id) {
                if (intervalle === "Debut") {
                    return { ...operation, dateHeureDebut: horaire };
                }
                else {
                    return { ...operation, dateHeureSortie: horaire };
                }
            }
            return operation;
        }));
    };

    const formatInput = (horaire, intervalle) => {
        const horaireValue = horaire.target.value;
        if (intervalle === "Heure") {
            if (horaireValue.length === 1) {
                setDureePause({ ...dureePause, heure: `0${horaireValue}` });
            }
        }
        else {
            if (horaireValue.length === 1) {
                modifierOperationsInput({ ...dureePause, minute: `0${horaireValue}` });
            }
        }
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Intervention n° {props.numeroIntervention} de {props.salarie.prenom} {props.salarie.nom}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="container-flex mgB-m1" style={{ justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "49%" }}>
                    <label className='default-label mgB-s2'>Début de l'intervention</label>
                    <input className='default-input' style={{
                        textAlign: "center",
                        marginRight: 0,
                        cursor: "default"
                    }} readOnly={true} value={dateToHoursAndMinutesString(props.entreeSortie.operations[0].dateHeureDebut)} type="text" placeholder="Début de l'intervention" />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "49%" }}>
                    <label className='default-label mgB-s2'>Fin de l'intervention</label>
                    <input className='default-input' readOnly={true} style={{ textAlign: "center", marginRight: 0, cursor: "default" }} value={dateToHoursAndMinutesString(props.entreeSortie.operations[props.entreeSortie.operations.length - 1].dateHeureSortie)} type="text" placeholder="Début de l'intervention" />
                </div>
            </div>
            <div className="container-flex mgB-m1" style={{ justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", width: recuperationType.label === "Récupération avec exposition" ? "49%" : "100%", transition: "all .2s ease" }}>
                    <label className='default-label mgB-s1'>Récupération après vacation</label>
                    {/* <input className='default-input' style={{textAlign: "center", marginRight: 0}} readOnly={true} value={props.entreeSortie.phaseChantier} type="text" placeholder="Début de l'intervention" /> */}
                    <Select
                        options={recuperationTypes}
                        // placeholder={"Sélectionner la phase du chantier"}
                        name={"recuperationType"}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        className={"formSelect-search-container formSelect-search-container-center"}
                        classNamePrefix={"formSelect-search"}
                        onChange={(opt) => setRecuperationType(opt)}
                        defaultValue={recuperationType}
                    />
                </div>
                {recuperationType.label === "Récupération avec exposition" &&
                    <div style={{ display: "flex", flexDirection: "column", width: "49%" }}>
                        <label className='default-label mgB-s1'>Fin de la récupération</label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                                style={{ width: "20%" }}
                                className='custom-time-input left'
                                value={dureePause.heure}
                                type="number"
                                onChange={text => onTimeInput(text, "Heure")}
                                onBlur={text => formatInput(text, "Heure")}
                            />
                            <div className='custom-time-input center'>
                                <p>:</p>
                            </div>
                            <input
                                style={{ width: "20%" }}
                                className='custom-time-input right'
                                value={dureePause.minute}
                                type="number"
                                ref={minuteRef}
                                onChange={text => onTimeInput(text, "Minute")}
                                onBlur={text => formatInput(text, "Minute")}
                            />
                        </div>
                    </div>
                }
            </div>
            <label className='default-label mgB-s1'>Auto-contrôle</label>
            <div className="container-flex mgB-s2" style={{ flexWrap: "wrap", gap: "1rem" }}>
                {props.autoControleList.map((autoControle) => {
                    return <div key={autoControle._id} onClick={() => { isAutoControleActive(autoControle) ? removeAutoControle(autoControle) : addAutoControle(autoControle); }} style={{ cursor: "pointer", opacity: isAutoControleActive(autoControle) ? 1 : 0.4 }} className='chip-autoControle'><p>{autoControle.libelleAutoControle}</p></div>;
                })}
            </div>
            <label className='default-label mgB-s1'>EPI</label>
            <div className="container-flex mgB-m1" style={{ flexWrap: "wrap", gap: "1rem" }}>
                {props.epiList.map((epi) => {
                    return <div key={epi._id} onClick={() => { isEpiActive(epi) ? removeEpi(epi) : addEpi(epi); }} className='chip-autoControle' style={{ opacity: isEpiActive(epi) ? 1 : 0.4, cursor: "pointer" }}><p>{epi.shortLibelleEquipement}</p></div>;
                })}
            </div>
            <div className="container-flex mgB-m1" style={{ justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "49%" }}>
                    <label className='default-label mgB-s1'>Phase du chantier</label>
                    {/* <input className='default-input' style={{textAlign: "center", marginRight: 0}} readOnly={true} value={props.entreeSortie.phaseChantier} type="text" placeholder="Début de l'intervention" /> */}
                    <Select
                        options={phasesChantierOptions}
                        // placeholder={"Sélectionner la phase du chantier"}
                        name={"phaseChantier"}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        className={"formSelect-search-container formSelect-search-container-center"}
                        classNamePrefix={"formSelect-search"}
                        onChange={(opt) => setSelectedPhase(opt)}
                        defaultValue={{ label: props.entreeSortie.phaseChantier, value: props.entreeSortie.phaseChantier }}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "49%" }}>
                    <label className='default-label mgB-s1'>Température</label>
                    <div className='input-with-right-icon' style={{ flex: 1 }}>
                        <input onChange={(e) => setTemperature(e.target.value)} className='default-input number-noArrows' style={{ textAlign: "center", marginRight: 0, width: "100%" }} value={temperature} type="number" placeholder="Température" />
                        <div className='right-content vacationNumber'><p>°C</p></div>
                    </div>
                </div>
            </div>
            <button
                disabled={!canEdit}
                className="default-button modifier-form-valider"
                onClick={() => modifierVacations()}
            >
                Modifier la vacation
            </button>
            {/* <label className='default-label mgB-s1'>Types de processus</label> */}
            {
                // operations.map((op) => {
                //     return (
                //         <div key={op._id} className="container-flex" style={{justifyContent: "space-between", alignItems: "center", padding: "0.6rem 0", borderTop: "1px solid #ddd"}}>
                //             {/* <p style={{width: "49%"}} className="vacationNumber">{op.typeProcessus.codeProcessus}</p> */}
                //             <Select
                //                 options={props.processusChantier}
                //             // placeholder={"Sélectionner la phase du chantier"}
                //                 name={"processus"}
                //                 isDisabled={false}
                //                 isLoading={false}
                //                 isClearable={false}
                //                 isRtl={false}
                //                 isSearchable={false}
                //                 className={"formSelect-search-container formSelect-search-container-center mediumWidth mgR-s2"}
                //                 classNamePrefix={"formSelect-search"}
                //                 // onChange={(opt) => setSelectedPhase(opt)}
                //                 defaultValue={props.processusChantier.find(processus => {
                //                     return processus.value === op.typeProcessus._id;
                //                 })}
                //             />
                //             {/* <p style={{width: "49%"}} className="vacationIndex">de {dateToHoursAndMinutesString(op.dateHeureDebut)} à {dateToHoursAndMinutesString(op.dateHeureSortie)}</p> */}
                //             <div className="container-flex mgR-s2" style={{flex: 1}}>
                //                 <MyTimeInput operation={op} horaire={op.dateHeureDebut} intervalle={"Debut"} modifierOperationsInput={(horaire) => modifierOperationsInput(op._id, "Debut", horaire)} marginRight={"0.8rem"}/>
                //                 <MyTimeInput operation={op} horaire={op.dateHeureSortie} modifierOperationsInput={(horaire) => modifierOperationsInput(op._id, "Sortie", horaire)} intervalle={"Sortie"} />
                //             </div>
                //             <div className='container-flex'>
                //                 <button
                //                     onClick={() => console.log()}
                //                     className="table-modify-button mgR-s2"
                //                     style={{height: "4rem", width: "4rem"}}
                //                 >
                //                     <EditIcon />
                //                 </button>
                //                 <button
                //                     onClick={() => console.log()}
                //                     className="table-delete-button"
                //                     style={{height: "4rem", width: "4rem"}}
                //                 >
                //                     <SupprimerIcon />
                //                 </button>
                //             </div>
                //         </div>
                //     )
                // })
            }
        </div>
    );
};

export default DetailVacationSalarie;