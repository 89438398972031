import * as actionTypes from '../actions/actionTypes';

const tableOptionsState = {};

const reducer = (state = tableOptionsState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_TABLE:
            return { ...state, [action.tableName]: action.tableOptions };
        default:
            return state;
    }
};

export default reducer;