import React from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import Select from "react-select";
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';

const ModifierEntretienSuivi = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    // handle form
    const formSchema = yup.object().shape({
        nomEntretien: yup.string().required("Veuillez renseigner le nom de la formation"),
        anneeRenouvellement: yup.number().integer("L'année doit être un nombre entier"),
        moisRenouvellement: yup.number().integer("Le mois doit être un nombre entier"),
        jourRenouvellement: yup.number().integer("Le jour doit être un nombre entier"),
        anneeSignalementUrgence: yup.number().integer("L'année doit être un nombre entier"),
        moisSignalementUrgence: yup.number().integer("Le mois doit être un nombre entier"),
        jourSignalementUrgence: yup.number().integer("Le jour doit être un nombre entier")
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const patchData = {
            ...values,
            anneeRenouvellement: values.anneeRenouvellement ? values.anneeRenouvellement : null,
            moisRenouvellement: values.moisRenouvellement ? values.moisRenouvellement : null,
            jourRenouvellement: values.jourRenouvellement ? values.jourRenouvellement : null,
            anneeSignalementUrgence: values.anneeSignalementUrgence ? values.anneeSignalementUrgence : null,
            moisSignalementUrgence: values.moisSignalementUrgence ? values.moisSignalementUrgence : null,
            jourSignalementUrgence: values.jourSignalementUrgence ? values.jourSignalementUrgence : null
        };
        axiosInstance.patch(`/entretienSuiviVehicule/${props.entretienData._id}`, patchData).then((result) => {
            props.progressBarRef.current.complete();
            props.editEntretienSuivi(result.data);
            actions.resetForm();
            close();
        }).catch((err) => {
            actions.resetForm();
            props.progressBarRef.current.complete();
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nomEntretien: props.entretienData.nomEntretien,
            anneeRenouvellement: props.entretienData.renouvellement.annee ? props.entretienData.renouvellement.annee : "",
            moisRenouvellement: props.entretienData.renouvellement.mois ? props.entretienData.renouvellement.mois : "",
            jourRenouvellement: props.entretienData.renouvellement.jour ? props.entretienData.renouvellement.jour : "",
            anneeSignalementUrgence: props.entretienData.signalerUrgence.annee ? props.entretienData.signalerUrgence.annee : "",
            moisSignalementUrgence: props.entretienData.signalerUrgence.mois ? props.entretienData.signalerUrgence.mois : "",
            jourSignalementUrgence: props.entretienData.signalerUrgence.jour ? props.entretienData.signalerUrgence.jour : ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Modifier une formation</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                <div div className='label-and-input'>
                    <label htmlFor='nomEntretien' className='form-label'>Nom de l'entretien<span className='required-field'>*</span></label>
                    <input name='nomEntretien' className={`new-default-input${errors.nomEntretien && touched.nomEntretien ? " input-error" : ""}`} type="text" placeholder="Nom de la formation" value={values.nomEntretien} onChange={handleChange} onBlur={handleBlur} />
                    {errors.nomEntretien && touched.nomEntretien && <p className="form-error-message">{errors.nomEntretien}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label'>Formation à renouveler dans</label>
                    <div className='number-label-input-container'>
                        <div>
                            <input name='anneeRenouvellement' className={`new-default-input${errors.anneeRenouvellement && touched.anneeRenouvellement ? " input-error" : ""}`} type="number" placeholder="nombre d'année(s)" value={values.anneeRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>an(s)</p>
                        </div>
                        <div>
                            <input name='moisRenouvellement' className={`new-default-input${errors.moisRenouvellement && touched.moisRenouvellement ? " input-error" : ""}`} type="number" placeholder="nombre de mois" value={values.moisRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>mois</p>
                        </div>
                        <div>
                            <input name='jourRenouvellement' className={`new-default-input${errors.jourRenouvellement && touched.jourRenouvellement ? " input-error" : ""}`} type="number" placeholder="nombre de jour(s)" value={values.jourRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>jour(s)</p>
                        </div>
                    </div>
                </div>
                <div className='label-and-input last-input'>
                    <label className='form-label'>Mettre un rappel avant la fin de la formation</label>
                    <div className='number-label-input-container'>
                        <div>
                            <input name='anneeSignalementUrgence' className={`new-default-input${errors.anneeSignalementUrgence && touched.anneeSignalementUrgence ? " input-error" : ""}`} type="number" placeholder="nombre d'année(s)" value={values.anneeSignalementUrgence} onChange={handleChange} onBlur={handleBlur} />
                            <p>an(s)</p>
                        </div>
                        <div>
                            <input name='moisSignalementUrgence' className={`new-default-input${errors.moisSignalementUrgence && touched.moisSignalementUrgence ? " input-error" : ""}`} type="number" placeholder="nombre de mois" value={values.moisSignalementUrgence} onChange={handleChange} onBlur={handleBlur} />
                            <p>mois</p>
                        </div>
                        <div>
                            <input name='jourSignalementUrgence' className={`new-default-input${errors.jourSignalementUrgence && touched.jourSignalementUrgence ? " input-error" : ""}`} type="number" placeholder="nombre de jour(s)" value={values.jourSignalementUrgence} onChange={handleChange} onBlur={handleBlur} />
                            <p>jour(s)</p>
                        </div>
                    </div>
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier la formation" />
            </form>
        </div>
    );
};

export default ModifierEntretienSuivi;