import * as actionTypes from '../actions/actionTypes';

const pageState = {};

const reducer = (state = pageState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_PAGE_OPTIONS:
            if (state.hasOwnProperty(action.pageName)) {
                return { ...state, [action.pageName]: { ...state[action.pageName], ...action.pageOptions } };
            }
            else {
                return { ...state, [action.pageName]: action.pageOptions };
            }
        default:
            return state;
    }
};

export default reducer;
