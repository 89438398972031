import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';


const equipementsEpcListState = { allEquipementsEpcState: [], documentsEquipementEpcList: [] };

const reducer = (state = equipementsEpcListState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_EQUIPEMENT_EPC_LIST:
            return updateObject(state, { allEquipementsEpcState: action.equipementEpcList, documentsEquipementEpcList: action.documentsEquipementEpcList });
        case actionTypes.UNLOAD_EQUIPEMENT_EPC_LIST:
            return { allEquipementsEpcState: [], documentsEquipementEpcList: [] };
        case actionTypes.ADD_EQUIPEMENT_EPC:
            return updateObject(state, { allEquipementsEpcState: state.allEquipementsEpcState.concat(action.equipementEpc.data.equipementEpc) });
        case actionTypes.ADD_DOCUMENT_EPC:
            return updateObject(state, { documentsEquipementEpcList: state.documentsEquipementEpcList.concat({ idEquipement: action.equipementEpc._idEquipement, ...action.equipementEpc.idDocument }) });
        case actionTypes.DELETE_DOCUMENT_EPC:
            return updateObject(state, {
                documentsEquipementEpcList: state.documentsEquipementEpcList.filter(document => document.baseDocument._id !== action.equipementEpc),
                allEquipementsEpcState: state.allEquipementsEpcState.map((document) => {
                    if (document.IDdocument === action.equipementEpc) {
                        delete document['document'];
                        delete document['IDdocument'];
                    }
                    return document;
                })
            });
        case actionTypes.MODIFY_DOCUMENT_EPC:

            return updateObject(
                state,
                {
                    documentsEquipementEpcList: state.documentsEquipementEpcList.map(document => document._refID !== action.equipementEpc._refID ? document : action.equipementEpc),
                    allEquipementsEpcState: state.allEquipementsEpcState.map(equipement => {
                        if (equipement._id === action.equipementEpc._refID) {
                            equipement.IDdocument = action.equipementEpc.baseDocument._id;
                            equipement.document = action.equipementEpc.baseDocument.file.filename;
                        }
                        return equipement;
                    })
                }
            );
        case actionTypes.MODIFY_EQUIPEMENT_EPC:
            //console.log(action.equipementUpdated);
            // {...equipement, nom: action.equipementUpdated.nom, marque: action.equipementUpdated.marque, numero_de_serie: action.equipementUpdated.numero_de_serie, ref_interne: action.equipementUpdated.ref_interne}
            return updateObject(state,
                {
                    allEquipementsEpcState: state.allEquipementsEpcState.map(
                        (equipement) => equipement._id === action.equipementUpdated._id ? action.equipementUpdated : equipement
                    )
                });
        case actionTypes.DELETE_EQUIPEMENTS_EPC:
            return updateObject(state, { allEquipementsEpcState: state.allEquipementsEpcState.filter(equipement => equipement._id !== action.equipementEpcId) });
        default:
            return state;
    }
};

export default reducer;