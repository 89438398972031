import * as actionTypes from './actionTypes';
import axiosInstance from '../../utils/axiosInstance';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';


export const unloadBanqueHeure = () => {
    return {
        type: actionTypes.UNLOAD_BANQUE_HEURE,
    };
};

export const supprimerBanque = (idBanque) => {
    return {
        type: actionTypes.DELETE_BANQUE_HEURE,
        idBanque: idBanque
    };
};

const loadBanqueHeure = (horaire) => {
    return {
        type: actionTypes.LOAD_BANQUE_HEURE_ME,
        horaire: horaire
    };
};

export const addTempsBanqueHeure = (horaireBanque) => {
    return {
        type: actionTypes.ADD_HORAIRE_BANQUE_HEURE,
        horaire: horaireBanque
    };
};

export const getBanqueHeure = (statut) => (dispatch) => {
    // let postUrl = "";
    // switch (statut) {
    //     case 'Chef de chantier':
    //         postUrl = "/diriger_chantiers_classique/me"
    //         break;
    //     case 'Ouvrier':
    //         postUrl = "/travails_ouvriers/me"
    //         break;
    //     case 'Interimaire':
    //         postUrl = "/travails_interimaires/me"
    //         break;
    //     default:
    //         break;
    // }
    // // get all materiaux
    axiosInstance.get('/banque_heure/me')
        .then((result) => {
            dispatch(loadBanqueHeure(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getBanqueHeureById = (idUtilisateur) => (dispatch) => {
    axiosInstance.get(`/banque_heure/${idUtilisateur}`)
        .then((result) => {
            dispatch(loadBanqueHeure(result.data));
        }).catch((err) => {
            showError(err);
        });
}

