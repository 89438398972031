import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import { totalHoraireSalarie } from "../../../utils/GeneralHelper";
import { showError } from "../../../utils/ErrorHelper";
import { format } from "date-fns";

const ListeSalarieReleveHeureV2 = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const getGroupHoraireSemaineParSalarie = (jours) => {
        let horaires = {};
        for (const jour of Object.values(jours)) {
            if (jour.hasOwnProperty("travails")) {
                for (const travailsChantier of Object.values(jour.travails)) {
                    for (const travail of travailsChantier) {
                        if (horaires.hasOwnProperty(travail.salarie._id)) {
                            horaires[travail.salarie._id]["horaires"].push({ horaireAprem: travail.horaireAprem, horaireMat: travail.horaireMat });
                        } else {
                            horaires[travail.salarie._id] = { "salarie": travail.salarie, "horaires": [{ horaireAprem: travail.horaireAprem, horaireMat: travail.horaireMat }] };
                        }
                    }
                }
            }
        };

        return horaires;
        // dans chaque route creer le statut semaine sil il n'esxiste pas encore
    };

    const getAllAbsences = (jours) => {
        let absences = [];
        for (const jour of Object.values(jours)) {
            if (jour.hasOwnProperty("absences")) {
                absences = absences.concat(jour.absences);
            }
        }
        return absences;
    };

    const [horaireSemaineTotal, setHoraireSemaineTotal] = useState([]);
    const [absences, setAbsences] = useState([]);

    useEffect(() => {
        axiosInstance.get(`/releve-heure-v2/ReleveHeureSalarie?idReleveHeure=${props.idSemaine}`).then((result) => {
            // setReleveHeure(result.data);
            setHoraireSemaineTotal(getGroupHoraireSemaineParSalarie(result.data.jours));
            setAbsences(getAllAbsences(result.data.jours));
        }).catch((err) => {
            showError(err);
        });
    }, []);



    return <div className="modal-content modifier-form">
        <h3 className="modal-title" style={{ marginBottom: 0 }} >Liste des salariés présents dans la semaine</h3>
        <button className='modal-button' onClick={() => close()}>x</button>
        <ul className="resumeSalarieHeure">
            {Object.values(horaireSemaineTotal).length > 0 && <h3>Total des heures par salarié</h3>}
            {Object.values(horaireSemaineTotal).length > 0 &&
                Object.keys(horaireSemaineTotal).map((salarieId) => {
                    return <li key={salarieId}>{horaireSemaineTotal[salarieId]["salarie"].nom} {horaireSemaineTotal[salarieId]["salarie"].prenom} {totalHoraireSalarie(horaireSemaineTotal[salarieId]["horaires"])}</li>;
                })
            }
        </ul>
        <ul className="resumeSalarieHeure">
            {absences.length !== 0 && <h3>Absences</h3>}
            {absences.map((absence) => {
                return <li key={absence._id}>{absence["utilisateur_absent"].nom} {absence["utilisateur_absent"].prenom} {format(new Date(absence["date_deb_absence"]), "dd/MM/yyyy")}</li>;
            })
            }
        </ul>
    </div>;
};

export default ListeSalarieReleveHeureV2;
