import React from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';

const ModifierStockMateriaux = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    // handle form
    const formSchema = yup.object().shape({
        commentaire: yup.string(),
        stock: yup.number().required("Veuillez entrer la quantité présent sur le site")
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        axiosInstance.patch(`/site/${props.siteId}/modifierStockMateriaux/${props.modalMateriauxSelected._id}`, { ...values }).then((result) => {
            props.progressBarRef.current.complete();
            actions.resetForm();
            props.onModifierQuantiteMateriaux(result.data, props.modalMateriauxSelected._id);
            console.log(result.data);
            close();
        }).catch((err) => {
            setSubmitting(false);
            props.progressBarRef.current.complete();
            showError(err);
        });

    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            commentaire: "",
            stock: 0
        },
        validationSchema: formSchema,
        onSubmit
    });

    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Modifier la quantité de {props.modalMateriauxSelected.materiaux.libelle_mat} provenant du site</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='stock' className='form-label'>Quantité</label>
                    <input name='stock' className={`new-default-input${errors.stock && touched.stock ? " input-error" : ""}`} type="number" placeholder="Quantité présent sur site" value={values.stock} onChange={handleChange} onBlur={handleBlur} />
                    {errors.stock && touched.stock && <p className="form-error-message">{errors.stock}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='commentaire' className='form-label'>Commentaire</label>
                    <input name='commentaire' className={`new-default-input${errors.commentaire && touched.commentaire ? " input-error" : ""}`} type="text" placeholder="Commentaire sur la modification" value={values.commentaire} onChange={handleChange} onBlur={handleBlur} />
                    {errors.commentaire && touched.commentaire && <p className="form-error-message">{errors.commentaire}</p>}
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier la quantité" />
            </form>
        </div>
    );
};

export default ModifierStockMateriaux;