import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const userState = {
    jwtToken: null,
    refreshToken: null,
    user: null,
    loginError: null,
    isAuth: false,
    loadingSpinner: false,
    userUpload: []
};

if (localStorage.getItem('refreshToken')) {
    userState.refreshToken = localStorage.getItem('refreshToken');
}

const logout = (state) => {
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('jwtToken');
    return updateObject(state, { jwtToken: null, refreshToken: null, user: null, isAuth: false, loadingSpinner: false, userUpload: [] });
};

const reducer = (state = userState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return updateObject(state, { jwtToken: action.jwtToken, refreshToken: action.refreshToken, user: action.user, isAuth: true, userUpload: [] });
        case actionTypes.LOGOUT:
            return logout(state);
        case actionTypes.STORE_LOCAL_TOKEN:
            localStorage.setItem('refreshToken', action.refreshToken);
            return state;
        case actionTypes.STORE_SESSION_REFRESH_TOKEN:
            sessionStorage.setItem('refreshToken', action.refreshToken);
            return state;
        case actionTypes.STORE_SESSION_TOKEN:
            sessionStorage.setItem('jwtToken', action.jwtToken);
            return state;
        case actionTypes.STORE_TOKEN_IN_STATE:
            return updateObject(state, { jwtToken: action.jwtToken });
        case actionTypes.ADD_LOGIN_ERROR:
            return updateObject(state, { loginError: action.errorMessage });
        case actionTypes.DELETE_ALL_LOGIN_ERROR:
            return updateObject(state, { loginError: null });
        case actionTypes.START_LOGIN_SPINNER:
            return updateObject(state, { loadingSpinner: true });
        case actionTypes.STOP_LOGIN_SPINNER:
            return updateObject(state, { loadingSpinner: false });
        case actionTypes.START_LOADING:
            return updateObject(state, { loadingSpinner: true });
        case actionTypes.STOP_LOADING:
            return updateObject(state, { loadingSpinner: false });
        case actionTypes.ADD_USER_UPLOAD:
            return updateObject(state, { userUpload: state.userUpload.concat(true) });
        case actionTypes.RESET_USER_UPLOAD:
            return updateObject(state, { userUpload: [] });
        case actionTypes.UPDATE_PROFILE_PICTURE:
            return updateObject(state, { user: { ...state.user, profilePicture: action.picture } });
        default:
            return state;
    }
};

export default reducer;