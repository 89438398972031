import React from "react";
import * as yup from "yup";
import { useFormik } from 'formik';
import axiosInstance from "../../../../utils/axiosInstance";
import customToast from "../../../../utils/ToastifyHelper";
import { showError } from "../../../../utils/ErrorHelper";

const ModifierConditionnement = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    //--------------- FORM SCHEMA ------------------
    const formSchema = yup.object().shape({
        conditionnement: yup.string().required("Veuillez saisir le nom du conditionnement"),
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        axiosInstance.patch(`/formOptions/options/conditionnementDechet/${props.conditionnementSelected.value}`, { label: values.conditionnement, value: values.conditionnement }).then((result) => {
            props.progressBarRef.current.complete();
            customToast.success("Le conditionnement a été modifié");
            props.modifierConditionnement(result.data, props.conditionnementSelected.value);
            close();
        }).catch((err) => {
            props.progressBarRef.current.complete();
            actions.setSubmitting(false);
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
        initialValues: {
            conditionnement: props.conditionnementSelected.value
        },
        validationSchema: formSchema,
        onSubmit
    });

    return (
        <div className="new-modal">
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="new-modal-topSection">
                <h3 className='modal-title'>Modifier le type de conditionnement</h3>
            </div>
            <div className="new-modal-mainSection">
                <form className="fh-form" onSubmit={handleSubmit}>
                    <div className='label-and-input last-input'>
                        <label htmlFor='conditionnement' className='form-label'>Conditionnement du déchet</label>
                        <input name='conditionnement' className={`new-default-input${errors.conditionnement && touched.conditionnement ? " input-error" : ""}`} type="text" placeholder="Conditionnement du déchet" value={values.conditionnement} onChange={handleChange} onBlur={handleBlur} />
                        {errors.conditionnement && touched.conditionnement && <p className="form-error-message">{errors.conditionnement}</p>}
                    </div>
                    <div className="submit-section">
                        <button type="button" disabled={isSubmitting} className='btn btn-secondary medium-btn fw mgR-m1' onClick={() => close()}>Annuler</button>
                        <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn fw" type="submit" value="Modifier le conditionnement" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModifierConditionnement;
