import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getWeekNumber, getAllDaysOfWeek, getMaxWeekInYear } from "../../utils/DateHelper";
import * as actionCreators from '../../store/actions/exportAction';
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import ValiderSemaineChef from "../../components/forms/ReleveHeureForms/ValiderSemaineChef";
import Modal from '../../components/Modal';
import { Icon } from '@iconify/react';
import ReleveJour from "./ReleveJour";
import ModifierChantierV2 from "./Modal/ModifierChantierV2";
import SupprimerModalForm from "../../components/forms/SupprimerModalForm";
import ModifierSalarieHeureV2 from "./Modal/ModifierSalarieHeureV2";
import CopierVersJourV2 from "./Modal/CopierVersJourV2";
import ValiderSemaineV2 from "./Modal/ValiderSemaineV2";

const ReleveHeureV2 = () => {
    const dispatch = useDispatch();

    const [listeDesChantiers, setListeDesChantiers] = useState([]);
    const [listeDesZonesChantiers, setListeDesZonesChantiers] = useState([]);
    const [listeDesSalaries, setListeDesSalaries] = useState({});

    const [releveHeure, setReleveHeure] = useState({
        annee: null,
        numeroSemaine: null,
        statutSemaine: 0,
        jours: {}
    });
    const [allDaysInWeek, setAllDaysInWeek] = useState([]);
    const currentWeekState = useSelector(
        state => state.currentWeek
    );

    const getListeDesChantiers = () => {
        axiosInstance.get('/chantiers/optionsChantiers?zone=true').then((result) => {
            setListeDesChantiers(result.data);
        }).catch((err) => {
            showError(err);
        });
    };

    const getListeDesZonesChantiers = () => {
        axiosInstance.get('/zones').then((res) => {
            setListeDesZonesChantiers(res.data);
        }).catch((error) => {
            showError(error);
        });
    };

    const getListeDesSalaries = () => {
        axiosInstance.get('/utilisateurs').then((result) => {
            setListeDesSalaries(result.data);
        }).catch((err) => {
            showError(err);
        });
    };

    const getReleveHeure = (annee, numeroSemaine) => {
        axiosInstance.get(`/releve-heure-v2/ReleveHeureSalarie/me?annee=${annee}&numeroSemaine=${numeroSemaine}`).then((result) => {
            setReleveHeure(result.data);
        }).catch((err) => {
            showError(err);
        });
    };

    const setCurrentWeek = (currentWeekNumber, yearNumber, maxWeekInYear) => (actionCreators.changeCurrentWeek(currentWeekNumber, yearNumber, maxWeekInYear));

    useEffect(() => {
        getListeDesChantiers();
        getListeDesZonesChantiers();
        getListeDesSalaries();
    }, []);


    useEffect(() => {
        setAllDaysInWeek(getAllDaysOfWeek(currentWeekState.weekNumber, currentWeekState.year.yearNumber));
        getReleveHeure(currentWeekState.year.yearNumber, currentWeekState.weekNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWeekState]);

    // button numero semaine
    const decrWeekNumber = () => {
        if (currentWeekState.weekNumber === 1) {
            const maxWeekInYear = getMaxWeekInYear(currentWeekState.year.yearNumber - 1);
            dispatch(setCurrentWeek(maxWeekInYear, currentWeekState.year.yearNumber - 1, maxWeekInYear));
        }
        else {
            dispatch(setCurrentWeek(currentWeekState.weekNumber - 1, currentWeekState.year.yearNumber, currentWeekState.year.maxWeekInYear));
        }
    };

    const incrWeekNumber = () => {
        if (currentWeekState.weekNumber === currentWeekState.year.maxWeekInYear) {
            const maxWeekInYear = getMaxWeekInYear(currentWeekState.year.yearNumber + 1);
            dispatch(setCurrentWeek(1, currentWeekState.year.yearNumber + 1, maxWeekInYear));
        }
        else {
            dispatch(setCurrentWeek(currentWeekState.weekNumber + 1, currentWeekState.year.yearNumber, currentWeekState.year.maxWeekInYear));
        }
    };

    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const [buttonWidth, setButtonWidth] = useState(0);
    const chooseNumeroSemaineRef = useRef();

    useLayoutEffect(() => {
        setButtonWidth(chooseNumeroSemaineRef.current.offsetWidth);
    }, []);

    const ajouterAbsence = (absence) => {
        setReleveHeure({
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(absence.date_deb_absence), "yyyy-MM-dd")]: releveHeure.jours.hasOwnProperty(format(new Date(absence.date_deb_absence), "yyyy-MM-dd")) ?
                    {
                        ...releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")],
                        absences: releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].hasOwnProperty("absences") ?
                            releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].absences.concat(absence)
                            : [absence]
                    }
                    :
                    {
                        absences: [absence],
                        travails: {}
                    }
            }
        });
    };

    const ajouterHeure = (travail) => {
        setReleveHeure({
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(travail.date_jour), "yyyy-MM-dd")]: releveHeure.jours.hasOwnProperty(format(new Date(travail.date_jour), "yyyy-MM-dd")) ?
                    {
                        ...releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")],
                        travails: releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].hasOwnProperty("travails") ? {
                            ...releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails,
                            [travail.chantier._id._id]: releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails.hasOwnProperty(travail.chantier._id._id) ?
                                releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails[travail.chantier._id._id].concat(travail)
                                :
                                [travail]
                        }
                            : {
                                [travail.chantier._id._id]: [travail]
                            }
                    }
                    :
                    {
                        travails: {
                            [travail.chantier._id._id]: [travail]
                        },
                        absences: []
                    }
            }
        });
    };

    const modifierChantier = (editData) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(editData.date_jour), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")],
                    travails: {
                        ...releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails,
                        [editData.newChantier._id._id]: releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails.hasOwnProperty(editData.newChantier._id._id) ?
                            releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.newChantier._id._id].concat(
                                releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId].map((travailSalarie) => {
                                    return { ...travailSalarie, chantier: editData.newChantier };
                                })
                            )
                            :
                            releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId].map((travailSalarie) => {
                                return { ...travailSalarie, chantier: editData.newChantier };
                            })
                    }
                }
            }
        };

        delete updateReleveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId];

        setReleveHeure(updateReleveHeure);
    };

    const deleteHeure = (travailSalarie) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")],
                    travails: {
                        ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails,
                        [travailSalarie.chantier._id._id]: releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].filter((travail) => travail._id !== travailSalarie._id)
                    }
                }
            }
        };

        if (updateReleveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].length === 0) {
            delete updateReleveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id];
        }

        setReleveHeure(updateReleveHeure);
    };

    const supprimerAbsence = (absence) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(absence.date_deb_absence), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")],
                    absences: releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].absences.filter((absenceFilter) => absenceFilter._id !== absence._id)
                }
            }
        };

        setReleveHeure(updateReleveHeure);
    };

    const modifierHeure = (travailSalarie) => {
        setReleveHeure({
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")],
                    travails: {
                        ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails,
                        [travailSalarie.chantier._id._id]: releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].map((travail) => {
                            if (travail._id === travailSalarie._id) {
                                return travailSalarie;
                            }
                            return travail;
                        })
                    }
                }
            }
        });
    };

    const supprimerTouteHeures = (dateJour) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: { ...releveHeure.jours }
        };

        if (updateReleveHeure.jours.hasOwnProperty(dateJour) && updateReleveHeure.jours[dateJour].hasOwnProperty("travails")) {
            updateReleveHeure.jours[dateJour].travails = {};
        }

        setReleveHeure(updateReleveHeure);
    };

    const supprimerTouteHeuresChantier = (jourChantier) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [jourChantier.date_jour]: {
                    ...releveHeure.jours[jourChantier.date_jour],
                    travails: {
                        ...releveHeure.jours[jourChantier.date_jour].travails
                    }
                }
            }
        };

        if (updateReleveHeure.jours[jourChantier.date_jour].travails.hasOwnProperty(jourChantier.chantierId)) {
            delete updateReleveHeure.jours[jourChantier.date_jour].travails[jourChantier.chantierId];
        }

        setReleveHeure(updateReleveHeure);
    };

    const copierVers = (jourData) => {

        let travailsDupliquer = {};

        for (const travail of jourData.travailsDupliquer) {
            if (travailsDupliquer.hasOwnProperty(travail.chantier._id._id)) {
                travailsDupliquer[travail.chantier._id._id].push(travail);
            } else {
                travailsDupliquer[travail.chantier._id._id] = [travail];
            }
        }

        setReleveHeure({
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [jourData.date_jour_destination]: releveHeure.jours.hasOwnProperty(jourData.date_jour_destination) ?
                    {
                        ...releveHeure.jours[jourData.date_jour_destination],
                        travails: travailsDupliquer
                    }
                    :
                    {
                        travails: travailsDupliquer,
                        absences: []
                    }
            }
        });
    };

    const validerSemaine = () => {
        setReleveHeure({
            ...releveHeure,
            statutSemaine: 1
        });
    };

    const openValiderSemaine = (semaineData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ValiderSemaineV2 semaineData={semaineData} jours={releveHeure.jours} validerSemaine={validerSemaine} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierChantier = (jourChantier) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierChantierV2 jourChantier={jourChantier} modifierChantier={modifierChantier} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openDeleteHeure = (travailSalarie) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerHoraireSalarieV2" deleteHeure={deleteHeure} travailSalarie={travailSalarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierSalarieHeure = (travailSalarie) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierSalarieHeureV2 travailSalarie={travailSalarie} modifierHeure={modifierHeure} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerTouteHeures = (jourDate) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresV2" jourDate={jourDate} supprimerTouteHeures={supprimerTouteHeures} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerTouteHeuresChantier = (jourChantier) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresChantiersV2" jourChantier={jourChantier} supprimerTouteHeuresChantier={supprimerTouteHeuresChantier} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerAbsence = (absence) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerAbsenceSalarieV2" absence={absence} supprimerAbsence={supprimerAbsence} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openCopierVers = (jourData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<CopierVersJourV2 jourData={jourData} copierVers={copierVers} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    return (
        <div className="section">
            <div className="relevee-heure-chef-top-container container-basic mgB-m2">
                <div className="titleSemaineAndValidate-container">
                    <h1 className='h1-blue-dark'>Relevé d'heures</h1>
                    <button style={{ width: buttonWidth }} disabled={releveHeure.statutSemaine === 0 || releveHeure.statutSemaine === null ? false : true} className={releveHeure.statutSemaine === 0 || releveHeure.statutSemaine === null ? "validate-button-fw" : "validate-button-fw disabled-button"} onClick={() => openValiderSemaine({ annee: currentWeekState.year.yearNumber, numeroSemaine: currentWeekState.weekNumber })} value="Valider">Valider la semaine</button>
                </div>
                <div className="relevee-heure-chef-topSection">
                    {allDaysInWeek.length > 0 && <h2 className='h2-light'>Du {format(allDaysInWeek[0], 'eeee dd', { locale: fr })} au {format(allDaysInWeek[allDaysInWeek.length - 1], 'dd MMMM yyyy', { locale: fr })}</h2>}
                    <div ref={chooseNumeroSemaineRef} className="semaine-selection">
                        <button onClick={() => decrWeekNumber()} className='select-semaine-button select-semaine-button-left'><Icon className="select-semaine-left" icon="bx:left-arrow" /></button>
                        <p className='currentWeek'>Semaine {currentWeekState.weekNumber}</p>
                        <button onClick={() => incrWeekNumber()} className='select-semaine-button select-semaine-button-right'><Icon className="select-semaine-right" icon="bx:right-arrow" /></button>
                    </div>
                </div>
            </div>
            <div className={releveHeure.statutSemaine === 0 || releveHeure.statutSemaine === null ? "releve-heure-table-container" : "releve-heure-table-container semaine-valider"}>
                <table className="releve-heure-chef-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Nom du chantier</th>
                            <th>Zone</th>
                            <th>Nombre d'heures saisies</th>
                            <th>Etat</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allDaysInWeek.map((jour) =>
                            <ReleveJour ajouterHeure={ajouterHeure} ajouterAbsence={ajouterAbsence} openSupprimerAbsence={openSupprimerAbsence} openCopierVers={openCopierVers} openSupprimerTouteHeuresChantier={openSupprimerTouteHeuresChantier} openSupprimerTouteHeures={openSupprimerTouteHeures} openModifierSalarieHeure={openModifierSalarieHeure} openDeleteHeure={openDeleteHeure} key={format(jour, 'yyyy-MM-dd')} listeDesSalaries={listeDesSalaries} listeDesChantiers={listeDesChantiers} statutSemaine={releveHeure.statutSemaine} dateJour={jour} jour={releveHeure.jours.hasOwnProperty(format(jour, 'yyyy-MM-dd')) ? releveHeure.jours[format(jour, 'yyyy-MM-dd')] : null} allDays={allDaysInWeek} openModifierChantier={openModifierChantier} />
                        )}
                    </tbody>
                </table>
            </div>
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>

    );
};

export default ReleveHeureV2;
