import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../../utils/axiosInstance";
import { showError } from "../../../../utils/ErrorHelper";
import { formatMateriaux, formatTechniqueModeOperatoire } from "../../../../utils/GeneralHelper";
import Select from 'react-select';
import customToast from "../../../../utils/ToastifyHelper";

const ModifierZoneAmiante = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [allUtilisateursAmiante, setAllUtilisateursAmiante] = useState([]);

    useEffect(() => {
        axiosInstance.get("/utilisateurs/allUtilisateursAmiante").then((result) => {
            let allUtilisateurs = {};
            let allUtilisateursAmianteOptions = [];
            for (const utilisateur of result.data) {
                if (allUtilisateurs.hasOwnProperty(utilisateur._id.userRole.statut)) {
                    allUtilisateurs[utilisateur._id.userRole.statut].push({ label: `${utilisateur._id.login_matricule} - ${utilisateur._id.nom} ${utilisateur._id.prenom}`, value: utilisateur._id._id, fullName: `${utilisateur._id.nom} ${utilisateur._id.prenom}` });
                }
                else {
                    allUtilisateurs[utilisateur._id.userRole.statut] = [{ label: `${utilisateur._id.login_matricule} - ${utilisateur._id.nom} ${utilisateur._id.prenom}`, value: utilisateur._id._id, fullName: `${utilisateur._id.nom} ${utilisateur._id.prenom}` }];
                }
            }
            for (const userRole of Object.keys(allUtilisateurs)) {
                allUtilisateurs[userRole].sort((a, b) => a.fullName.localeCompare(b.fullName));
                allUtilisateursAmianteOptions.push({ label: userRole, options: allUtilisateurs[userRole] });
            }
            allUtilisateursAmianteOptions.sort((a, b) => a.label.localeCompare(b.label));
            setAllUtilisateursAmiante(allUtilisateursAmianteOptions);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const formSchema = yup.object().shape({
        nomZoneAmiante: yup.string().required("Veuillez renseigner le nom de la zone"),
        environnement: yup.string().required("Veuillez renseigner l'environnement de la zone"),
        phase: yup.string().required("Veuillez renseigner la phase de la zone"),
        encadrantDeChantier: yup.string().required("Veuillez renseigner l'encadrant de chantier de la zone"),
        processus: yup.array().min(1, "Veuillez renseigner les processus de la zone").of(yup.string()).required("Veuillez renseigner les processus de la zone"),
    });


    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const patchData = {
            ...values,
            encadrantDeChantier: values.encadrantDeChantier,
            chantier: props.chantierId
        };

        axiosInstance.patch(`/zoneAmiante/${props.zoneSelected._id}`, patchData).then((result) => {
            props.progressBarRef.current.complete();
            setSubmitting(false);
            props.editZoneAmiante(result.data);
            close();
            customToast.success("Les zones ont bien été ajoutées au chantier");
        }).catch((err) => {
            props.progressBarRef.current.complete();
            setSubmitting(false);
            showError(err);
        });

    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nomZoneAmiante: props.zoneSelected.nomZoneAmiante,
            environnement: props.zoneSelected.environnement,
            phase: props.zoneSelected.phase,
            encadrantDeChantier: props.zoneSelected.encadrantDeChantier._id,
            processus: props.zoneSelected.processus.map((processus) => {
                return processus._id;
            })
        },
        validationSchema: formSchema,
        onSubmit
    });

    const environnementOptions = [
        { label: "Intérieur", value: "Intérieur" },
        { label: "Exterieur", value: "Exterieur" },
    ];

    const [phaseOptions, setPhaseOptions] = useState([
        { label: "Préparation", value: "phasePreparation" },
        { label: "Exécution", value: "phaseExecution" },
        { label: "Décontamination matériel", value: "phaseDecontaminationMateriel" },
        { label: "Repli de chantier", value: "phaseRepliDeChantier" }
    ]);

    const [allProcessus, setAllProcessus] = useState([]);

    useEffect(() => {
        axiosInstance.get("/processusAmiante").then((res) => {
            let dataSelectFormat = [];
            for (const processus of res.data) {
                dataSelectFormat.push({
                    value: processus._id, label: `${processus.codeProcessus} - ${formatMateriaux(processus.materiaux)} - Niveau ${processus.niveauEmpoussierement} - ${formatTechniqueModeOperatoire(processus.techniqueModesOperatoires)} - ${processus.protectionCollective}`
                });
            }
            setAllProcessus(dataSelectFormat);
        }).catch((error) => {
            showError(error);
        });

        axiosInstance.get("/options?categorieOptions=amiante").then((result) => {
            const newOptions = [
                { label: result.data.options.phasePreparation, value: "phasePreparation" },
                { label: result.data.options.phaseExecution, value: "phaseExecution" },
                { label: result.data.options.phaseDecontaminationMateriel, value: "phaseDecontaminationMateriel" },
                { label: result.data.options.phaseRepliDeChantier, value: "phaseRepliDeChantier" }
            ];
            setPhaseOptions(newOptions);
            setFieldValue("phase", newOptions.find((option) => option.label === props.zoneSelected.phase).value);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    // console.log(values.encadrantDeChantier);
    const onChangeEncadrantDeChantier = (opt) => {
        if (opt) {
            setFieldValue("encadrantDeChantier", opt.value);
        }
        else {
            setFieldValue("encadrantDeChantier", "");
        }
    };

    return <div className="modal-content default-form">
        <h3 className='modal-title'>Modifier la zone {props.zoneSelected.nomZoneAmiante}</h3>
        <button className='modal-button' onClick={() => close()}>x</button>
        <form className="vertical-form scrollable" onSubmit={handleSubmit}>
            <div>
                <div className='label-and-input'>
                    <label htmlFor='nomZoneAmiante' className='form-label'>Nom de la formation<span className='required-field'>*</span></label>
                    <input name='nomZoneAmiante' className={`new-default-input${errors.nomZoneAmiante && touched.nomZoneAmiante ? " input-error" : ""}`} type="text" placeholder="Nom de la formation" value={values.nomZoneAmiante} onChange={handleChange} onBlur={handleBlur} />
                    {errors.nomZoneAmiante && touched.nomZoneAmiante && <p className="form-error-message">{errors.nomZoneAmiante}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor={"environnement"} className='form-label'>Environnement de la zone<span className='required-field'>*</span></label>
                    <Select
                        options={environnementOptions}
                        isLoading={!environnementOptions.length}
                        isDisabled={!environnementOptions.length}
                        isSearchable={false}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => setFieldValue("environnement", opt ? opt.value : "")}
                        value={values.environnement ? { value: values.environnement, label: values.environnement } : ""}
                        placeholder={"Sélectionner l'environnement de la zone"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                    />
                    {errors.environnement && touched.environnement && <p className="form-error-message">{errors.environnement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor={"phase"} className='form-label'>Phase de la zone<span className='required-field'>*</span></label>
                    <Select
                        options={phaseOptions}
                        isLoading={!phaseOptions.length}
                        isDisabled={!phaseOptions.length}
                        placeholder={"Sélectionner la phase de la zone"}
                        isSearchable={false}
                        className={`formSelect-search-container formSelect-search-container-full mgT-s2${errors.phase && touched.phase ? " input-error" : ""}`}
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => setFieldValue(`phase`, opt ? opt.value : "")}
                        defaultValue={{ label: values.phase, value: values.phase }}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                    />
                    {errors.phase && touched.phase && <p className="form-error-message">{errors.phase}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor="processus" className='form-label'>Processus effectuer dans la zone<span className='required-field'>*</span></label>
                    <Select
                        options={allProcessus}
                        isLoading={!allProcessus.length}
                        isDisabled={!allProcessus.length}
                        placeholder={"Sélectionner les processus dans la zone"}
                        isSearchable={true}
                        isMulti={true}
                        className={`formSelect-search-container formSelect-search-container-full mgT-s2${errors.processus && touched.processus ? " input-error" : ""}`}
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => setFieldValue("processus", opt.map(processus => processus.value))}
                        defaultValue={props.zoneSelected.processus.map((processus) => {
                            return {
                                value: processus._id,
                                label: `${processus.codeProcessus} - ${formatMateriaux(processus.materiaux)} - Niveau ${processus.niveauEmpoussierement} - ${formatTechniqueModeOperatoire(processus.techniqueModesOperatoires)} - ${processus.protectionCollective}`
                            };
                        })}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                    />
                    {errors.processus && touched.processus && <p className="form-error-message">{errors.processus}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor="encadrantDeChantier" className='form-label'>Encadrant de chantier<span className='required-field'>*</span></label>
                    <Select
                        options={allUtilisateursAmiante}
                        isLoading={!allUtilisateursAmiante.length}
                        isDisabled={!allUtilisateursAmiante.length}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        placeholder={"Sélectionner l'encadrant de chantier"}
                        onChange={(opt) => onChangeEncadrantDeChantier(opt)}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                        defaultValue={{ label: `${props.zoneSelected.encadrantDeChantier.login_matricule} - ${props.zoneSelected.encadrantDeChantier.nom} ${props.zoneSelected.encadrantDeChantier.prenom}`, value: props.zoneSelected.encadrantDeChantier._id, fullName: `${props.zoneSelected.encadrantDeChantier.nom} ${props.zoneSelected.encadrantDeChantier.prenom}` }}
                    />
                    {errors.encadrantDeChantier && touched.encadrantDeChantier && <p className="form-error-message">{errors.encadrantDeChantier}</p>}
                </div>
            </div>
            <input disabled={isSubmitting} style={{ opacity: !isSubmitting ? 1 : 0.4 }} className="btn btn-primary medium-btn" type="submit" value="Sauvegarder" />
        </form>
    </div>;
};

export default ModifierZoneAmiante;
