import React, { useState } from 'react';
import customToast from '../../utils/ToastifyHelper';
import * as actionCreators from '../../store/actions/exportAction';
import axiosInstance from '../../utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { showError } from '../../utils/ErrorHelper';

const supprimerType = (payload) => (actionCreators.deleteType(payload));
const modifierType = (payload) => (actionCreators.modifyType(payload));
const addType = (payload) => (actionCreators.addTypeDocuments(payload));

const ModifierTypeDocumentsForm = (props) => {
    const categoriesTypesDocument = useSelector(state => state.categorieDocument);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [modifierTypeText, setModifierTypeText] = useState(props.categorie.typeDocument.libelleType);
    const [categorieType, setCategorieType] = useState(props.categorie.categorie._id);
    const dispatch = useDispatch();

    const onSubmit = () => {
        setButtonDisabled(true);
        if (modifierTypeText.length > 0) {
            let patchData = { "libelleType": modifierTypeText };
            if (categorieType !== props.categorie.categorie._id) {
                patchData["oldCategorie"] = props.categorie.categorie._id;
                patchData["newCategorie"] = categorieType;
            }
            axiosInstance.patch(`/typeDocuments/${props.categorie.typeDocument._id}`, patchData)
                .then((result) => {
                    if (categorieType !== props.categorie.categorie._id) {
                        // supprimer dans ancien, rajouter dans nouveau
                        dispatch(supprimerType({ categorieUpdated: props.categorie.categorie, idTypeSupprimer: result.data._id }));
                        dispatch(addType({ typeDocuments: result.data, categorie: categorieType }));
                    }
                    else {
                        dispatch(modifierType({ categorieUpdated: props.categorie.categorie, typeDocument: result.data }));
                    }
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
        }
        else {
            customToast.error("Veuillez entrer un nom de type de documents");
            setButtonDisabled(false);
        }
    };

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const changeCategorie = (event) => {
        setCategorieType(event.target.value);
    };

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier un type de document</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <label className='ajouter-form-label default-label' htmlFor="nom">Nom du type de document</label>
                <input value={modifierTypeText} onChange={(e) => setModifierTypeText(e.target.value)} className="ajouter-form-input default-input" name="nom" type="text" placeholder="Entrez le nom du type" />
                <label className='ajouter-form-label default-label' htmlFor="nom">Catégorie du document</label>
                <select className='new-default-input' onChange={changeCategorie} value={categorieType}>
                    {categoriesTypesDocument.map((categorie) => {
                        return <option key={categorie._id} value={categorie._id}>{categorie.libelleCategorie}</option>;
                    })}
                </select>
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <button disabled={isButtonDisabled} className="default-button modifier-form-valider" onClick={() => onSubmit()}>Valider</button>
                </div>
            </div>
        </div>
    );
};

export default ModifierTypeDocumentsForm;