import axiosInstance from '../../utils/axiosInstance';
import * as actionTypes from './actionTypes';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';

const login = (payload) => {
    return {
        type: actionTypes.LOGIN,
        refreshToken: payload.refreshToken,
        jwtToken: payload.jwtToken,
        user: payload.user
    };
};

const storeSessionRefreshToken = (refreshToken) => {
    return {
        type: actionTypes.STORE_SESSION_REFRESH_TOKEN,
        refreshToken: refreshToken
    };
};

export const storeSessionToken = (jwtToken) => {
    return {
        type: actionTypes.STORE_SESSION_TOKEN,
        jwtToken: jwtToken
    };
};

export const startLoginSpinner = () => {
    return {
        type: actionTypes.START_LOGIN_SPINNER
    };
};

export const stopLoginSpinner = () => {
    return {
        type: actionTypes.STOP_LOGIN_SPINNER
    };
};

const storeLocalToken = (refreshToken) => {
    return {
        type: actionTypes.STORE_LOCAL_TOKEN,
        refreshToken: refreshToken
    };
};

const addLoginError = (errorMessage) => {
    return {
        type: actionTypes.ADD_LOGIN_ERROR,
        errorMessage: errorMessage
    };
};

const deleteAllLoginError = () => {
    return {
        type: actionTypes.DELETE_ALL_LOGIN_ERROR
    };
};

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    };
};

export const storeTokenInState = (newToken) => {
    return {
        type: actionTypes.STORE_TOKEN_IN_STATE,
        jwtToken: newToken
    };
};

export const updateProfilePicture = (picture) => {
    return {
        type: actionTypes.UPDATE_PROFILE_PICTURE,
        picture: picture
    };
};

export const loginByTokenStored = (refreshToken) => dispatch => {
    axiosInstance.post('refresh_token', null, { headers: { 'x-auth-token': refreshToken } })
        .then((response) => {
            const jwtToken = response.data.token;
            axiosInstance.get('auth', { headers: { 'x-auth-token': jwtToken, 'Content-Type': 'application/json' } })
                .then((result) => {
                    dispatch(storeSessionToken(jwtToken));
                    const userResponse = result.data;
                    const payload = {
                        refreshToken: refreshToken,
                        jwtToken: jwtToken,
                        user: { nom: userResponse.nom, prenom: userResponse.prenom, statut: userResponse.userRole.statut, login_matricule: userResponse.login_matricule, userRole: userResponse.userRole, id_utilisateur: userResponse._id, secteur: userResponse.secteur, historiqueStatut: userResponse.historiqueStatut, profilePicture: userResponse.profilePicture }
                    };
                    dispatch(login(payload));
                }).catch((error) => {
                    showError(error);
                });
        }).catch((err) => {
            showError(err);
        });
};

export const authUserInput = (payload) => dispatch => {
    dispatch(startLoginSpinner());
    axiosInstance.post('auth', { login_matricule: payload.identifiant, mdp: payload.mdp })
        .then((response) => {
            const jwtToken = response.data.token;
            const refreshToken = response.data.refresh_token;
            axiosInstance.get('auth', { headers: { 'x-auth-token': jwtToken } })
                .then((result) => {
                    dispatch(storeSessionRefreshToken(refreshToken));
                    dispatch(storeSessionToken(jwtToken));
                    if (payload.stayConnected) {
                        dispatch(storeLocalToken(refreshToken));
                    }
                    const userResponse = result.data;
                    const payloadLogin = {
                        refreshToken: refreshToken,
                        jwtToken: jwtToken,
                        user: { nom: userResponse.nom, prenom: userResponse.prenom, statut: userResponse.userRole.statut, login_matricule: userResponse.login_matricule, userRole: userResponse.userRole, id_utilisateur: userResponse._id, secteur: userResponse.secteur, historiqueStatut: userResponse.historiqueStatut, profilePicture: userResponse.profilePicture }
                    };
                    dispatch(deleteAllLoginError());
                    dispatch(stopLoginSpinner());
                    dispatch(login(payloadLogin));
                }).catch((error) => {
                    showError(error);
                    dispatch(stopLoginSpinner());
                });
        }).catch((err) => {
            dispatch(stopLoginSpinner());
            showError(err);
            // Ajouter dans le state error
        });
};

export const addUserUpload = () => {
    return {
        type: actionTypes.ADD_USER_UPLOAD
    };
};

export const resetUserUpload = () => {
    return {
        type: actionTypes.RESET_USER_UPLOAD
    };
};