import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../utils/axiosInstance";
import customToast from "../../../utils/ToastifyHelper";
import SelectSearch from "../../SelectSearch";
import * as actionCreators from "../../../store/actions/exportAction";
import SelectSearchCreatable from "../SelectSearchCreatable";
import { showError } from "../../../utils/ErrorHelper";

const AjouterProcessusForm = (props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm();
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const dispatch = useDispatch();
	const allFormOptions = useSelector((state) => state.formOptions);

	const onSubmit = (data) => {
		setButtonDisabled(true);
		const postData = {
			codeProcessus: data.codeProcessus,
			niveauEmpoussierement: data.niveauEmpoussierement.value,
			estimationConcentration: data.estimationConcentration,
			materiaux: data.materiaux ? data.materiaux : "",
			zone: data.zone !== null ? data.zone.value : data.zone,
			// categorieMateriaux: typeof data.categorieMateriaux === 'object' && data.categorieMateriaux !== null ? ajouterOptionToData(data.categorieMateriaux.value, allFormOptions.categorieMateriaux, "categorieMateriaux") : "",
			categorieMateriaux: data.categorieMateriaux
				? data.categorieMateriaux.value
				: "",
			categorieTechnique: data.categorieTechniques
				? data.categorieTechniques.value
				: "",
			outil: data.outilProcessus ? data.outilProcessus.value : "",
			protectionCollective: data.protectionCollectiveProcessus
				? data.protectionCollectiveProcessus.value
				: "",
			methode: data.methodeProcessus ? data.methodeProcessus.value : "",
		};
		axiosInstance
			.post("/processusAmiante", postData)
			.then((result) => {
				// props.addProcessus(result.data)
				if (postData.categorieMateriaux !== "") {
					if (
						!allFormOptions.categorieMateriaux.options.includes(
							postData.categorieMateriaux
						)
					) {
						dispatch(
							actionCreators.addFormOptions(
								"categorieMateriaux",
								postData.categorieMateriaux
							)
						);
					}
				}
				if (postData.categorieTechnique !== "") {
					if (
						!allFormOptions.categorieTechniques.options.includes(
							postData.categorieTechnique
						)
					) {
						dispatch(
							actionCreators.addFormOptions(
								"categorieTechniques",
								postData.categorieTechnique
							)
						);
					}
				}
				if (postData.outil !== "") {
					if (!allFormOptions.outilProcessus.options.includes(postData.outil)) {
						dispatch(
							actionCreators.addFormOptions("outilProcessus", postData.outil)
						);
					}
				}
				if (postData.protectionCollective !== "") {
					if (
						!allFormOptions.protectionCollectiveProcessus.options.includes(
							postData.protectionCollective
						)
					) {
						dispatch(
							actionCreators.addFormOptions(
								"protectionCollectiveProcessus",
								postData.protectionCollective
							)
						);
					}
				}
				if (postData.methode !== "") {
					if (
						!allFormOptions.methodeProcessus.options.includes(postData.methode)
					) {
						dispatch(
							actionCreators.addFormOptions(
								"methodeProcessus",
								postData.methode
							)
						);
					}
				}
				dispatch(actionCreators.addProcessus(result.data));
				customToast.success("Le processus a été ajouté avec succès");
				close();
				setButtonDisabled(false);
			})
			.catch((err) => {
				setButtonDisabled(false);
				showError(err);
			});
	};

	const close = () => {
		props.closeModal();
		props.unrenderForm();
	};

	const niveauEmpoussierementOption = [
		{ label: "Niveau 1", value: "1" },
		{ label: "Niveau 2", value: "2" },
		{ label: "Niveau 2+", value: "2+" },
		{ label: "Niveau 3", value: "3" },
	];

	const zoneOptions = [
		{ label: "Extérieur", value: "Extérieur" },
		{ label: "Intérieur", value: "Intérieur" }
	];

	// console.log(allFormOptions.categorieMateriaux);

	return (
		<div className="modal-content ajouter-form">
			<h3 className="modal-title">Ajouter un processus</h3>
			<button className="modal-button" onClick={() => close()}>
				x
			</button>
			<form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
				<div className="scrollable-form"></div>
				<label
					className="ajouter-form-label default-label"
					htmlFor="codeProcessus"
				>
					Code processus
				</label>
				<input
					className="ajouter-form-input default-input"
					name="codeProcessus"
					type="text"
					placeholder="Code processus"
					{...register("codeProcessus", { required: true })}
				/>
				{errors["codeProcessus"] &&
					errors["codeProcessus"].type === "required" && (
						<p className="login-error-message">
							Veuillez saisir un code processus
						</p>
					)}
				<label className="ajouter-form-label default-label" htmlFor="materiaux">
					Matériaux
				</label>
				<input
					className="ajouter-form-input default-input"
					name="materiaux"
					type="text"
					placeholder="Matériaux"
					{...register("materiaux", { required: false })}
				/>
				<label
					className="ajouter-form-label default-label"
					htmlFor="niveauEmpoussierement"
				>
					Niveau d'empoussièrement
				</label>
				<SelectSearch
					options={niveauEmpoussierementOption}
					dataName="niveauEmpoussierement"
					control={control}
					name="niveauEmpoussierement"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner le niveau d'empoussièrement"
					isClearable
					isSearchable={false}
					required
				/>
				{errors["niveauEmpoussierement"] &&
					errors["niveauEmpoussierement"].type === "required" && (
						<p className="login-error-message">
							Veuillez sélectionner le niveau d'empoussièrement du processus
						</p>
					)}
				<label
					className="ajouter-form-label default-label"
					htmlFor="categorieMateriaux"
				>
					Catégorie du matériaux
				</label>
				<SelectSearchCreatable
					options={allFormOptions.categorieMateriaux !== undefined ? allFormOptions.categorieMateriaux.options : []}
					dataName="categorieMateriaux"
					control={control}
					name="categorieMateriaux"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner une catégorie materiaux"
					isClearable
					isSearchable
				/>
				{/* <select className="new-default-input" name="categorieMateriaux" {...register("categorieMateriaux", { required: true })}>
                    {allFormOptions.categorieMateriaux.map((categorie) => {
                        return (
                            <option key={categorie} value={categorie}>{categorie}</option>
                        )
                    })}
                </select> */}
				<label
					className="ajouter-form-label default-label"
					htmlFor="categorieTechniques"
				>
					Catégorie de la technique et mode opératoires
				</label>
				<SelectSearchCreatable
					options={allFormOptions.categorieTechniques !== undefined ? allFormOptions.categorieTechniques.options : []}
					dataName="categorieTechniques"
					control={control}
					name="categorieTechniques"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner la catégorie de la technique du processus"
					isClearable
					isSearchable
				/>
				{/* <select className="new-default-input" name="categorieTechniques" {...register("categorieTechniques", { required: true })}>
                    {allFormOptions.categorieTechniques.map((categorieTechnique) => {
                        return (
                            <option key={categorieTechnique} value={categorieTechnique}>{categorieTechnique}</option>
                        )
                    })}
                </select> */}
				<label
					className="ajouter-form-label default-label"
					htmlFor="methodeProcessus"
				>
					Méthode de la technique et mode opératoires
				</label>
				<SelectSearchCreatable
					options={allFormOptions.methodeProcessus !== undefined ? allFormOptions.methodeProcessus.options : []}
					dataName="methodeProcessus"
					control={control}
					name="methodeProcessus"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner la méthode de la technique du processus"
					isClearable
					isSearchable
				/>
				{/* <select className="new-default-input" name="methodeProcessus" {...register("methodeProcessus", { required: true })}>
                    {allFormOptions.methodeProcessus.map((methodeTechnique) => {
                        return (
                            <option key={methodeTechnique} value={methodeTechnique}>{methodeTechnique}</option>
                        )
                    })}
                </select> */}
				<label
					className="ajouter-form-label default-label"
					htmlFor="outilProcessus"
				>
					Outil de la technique et mode opératoires
				</label>
				<SelectSearchCreatable
					options={allFormOptions.outilProcessus !== undefined ? allFormOptions.outilProcessus.options : []}
					dataName="outilProcessus"
					control={control}
					name="outilProcessus"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner une option"
					isClearable
					isSearchable
				/>
				{/* <select className="new-default-input" name="outilProcessus" {...register("outilProcessus", { required: true })}>
                    {allFormOptions.outilProcessus.map((outil) => {
                        return (
                            <option key={outil} value={outil}>{outil}</option>
                        )
                    })}
                </select> */}
				<label
					className="ajouter-form-label default-label"
					htmlFor="outilProcessus"
				>
					Moyen de protection collective
				</label>
				<SelectSearchCreatable
					options={allFormOptions.protectionCollectiveProcessus !== undefined ? allFormOptions.protectionCollectiveProcessus.options : []}
					dataName="protectionCollectiveProcessus"
					control={control}
					name="protectionCollectiveProcessus"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner une option"
					isClearable
					isSearchable
				/>
				{/* <select className="new-default-input" name="protectionCollectiveProcessus" {...register("protectionCollectiveProcessus", { required: true })}>
                    {allFormOptions.protectionCollectiveProcessus.map((protectionCollective) => {
                        return (
                            <option key={protectionCollective} value={protectionCollective}>{protectionCollective}</option>
                        )
                    })}
                </select> */}
				<label
					className="ajouter-form-label default-label"
					htmlFor="niveauEmpoussierement"
				>
					Zone
				</label>
				<SelectSearch
					options={zoneOptions}
					dataName="zone"
					control={control}
					name="zone"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner la zone"
					defaultValue={zoneOptions[0]}
					isClearable
					isSearchable={false}
					required
				/>
				<label className="ajouter-form-label default-label" htmlFor="estimationConcentration">
					Estimation de la concentration
				</label>
				<input
					placeholder="Entrer l'estimation de la concentration"
					className="ajouter-form-input default-input"
					step=".01"
					name="estimationConcentration"
					type="number"
					{...register("estimationConcentration", { required: true, min: 0 })}
				/>
				{errors["estimationConcentration"] && errors["estimationConcentration"].type === "required" && (
					<p className="login-error-message">Veuillez saisir l'estimation de la concentration</p>
				)}
				{errors["estimationConcentration"] && errors["estimationConcentration"].type === "required" && (
					<p className="login-error-message">Veuillez saisir une valeur supérieur à 0</p>
				)}
				<input
					disabled={isButtonDisabled}
					className="validate-button-fw default-button ajouter-salarie-button"
					type="submit"
					value="Ajouter"
				/>
			</form>
		</div>
	);
};

export default AjouterProcessusForm;
