import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '../icon/MenuIcon';
import justeauLogo from '../../assets/img/justeaulogo.png';
import HorlogeIcon from '../icon/HorlogeIcon';
import TruckIcon from '../icon/TruckIcon';
import DatabaseIcon from '../icon/DatabaseIcon';
import AmianteIcon from '../icon/AmianteIcon';
import ChevronRightIcon from '../icon/ChevronRightIcon';
import HomeIcon from '../icon/HomeIcon';
import { Icon } from '@iconify/react';
import { active } from 'glamor';


const AdminSidebar = (props) => {
    const navigate = useNavigate();

    // // Active link
    // const [ activeLinkName, setActiveLinkName ] = useState({link: null, subLinkTitle: null});
    // const [ activeLinks, setActiveLinks ] = useState([])

    // // Menu gestion
    // const [ isGestionOpen , setGestionOpen ] = useState(false);
    // const gestionList = useRef(null);
    // const [ gestionHeight , setGestionHeight ] = useState({height: '0'});
    // const [ gestionIconClasses, setGestionIconClasses ] = useState('chevron-right');
    // const handleGestionOpen = () => {
    //     if (!isGestionOpen) {
    //         let openHeight = gestionList.current.childNodes.length * gestionList.current.childNodes[0].offsetHeight;
    //         setGestionIconClasses('chevron-right chevron-right-opened')
    //         setGestionHeight({height: openHeight})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: "Gestion"})
    //     }
    //     else {
    //         setGestionIconClasses('chevron-right')
    //         setGestionHeight({height: '0'})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: null})
    //     }
    //     setGestionOpen(!isGestionOpen)
    // }

    // // Menu transport
    // const [ isTransportOpen , setTransportOpen ] = useState(false);
    // const transportList = useRef(null);
    // const [ transportHeight , setTransportHeight ] = useState({height: '0'});
    // const [ transportIconClasses, setTransportIconClasses ] = useState('chevron-right');
    // const handleTransportOpen = () => {
    //     if (!isTransportOpen) {
    //         let openTransportHeight = transportList.current.childNodes.length * transportList.current.childNodes[0].offsetHeight;
    //         setTransportIconClasses('chevron-right chevron-right-opened')
    //         setTransportHeight({height: openTransportHeight})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: "Transport"})
    //     }
    //     else {
    //         setTransportIconClasses('chevron-right')
    //         setTransportHeight({height: '0'})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: null})

    //     }
    //     setTransportOpen(!isTransportOpen)
    // }

    //     // Menu gestion
    // const [ isAmianteOpen , setAmianteOpen ] = useState(false);
    // const amianteList = useRef(null);
    // const [ amianteHeight , setAmianteHeight ] = useState({height: '0'});
    // const [ amianteIconClasses, setAmianteIconClasses ] = useState('chevron-right');
    // const handleAmianteOpen = () => {
    //     if (!isAmianteOpen) {
    //         let openHeight = amianteList.current.childNodes.length * amianteList.current.childNodes[0].offsetHeight;
    //         setAmianteIconClasses('chevron-right chevron-right-opened')
    //         setAmianteHeight({height: openHeight})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: "Amiante"})
    //     }
    //     else {
    //         setAmianteIconClasses('chevron-right')
    //         setAmianteHeight({height: '0'})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: null})
    //     }
    //     setAmianteOpen(!isAmianteOpen)
    // }

    // // Menu relevé heure
    // const [ isReleveHeureOpen , setReleveHeureOpen ] = useState(false);
    // const releveHeureList = useRef(null);
    // const [ releveHeureHeight , setReleveHeureHeight ] = useState({height: '0'});
    // const [ releveHeureIconClasses, setReleveHeureIconClasses ] = useState('chevron-right');
    // const handleReleveHeureOpen = () => {
    //     if (!isReleveHeureOpen) {
    //         let openReleveHeureHeight = releveHeureList.current.childNodes.length * releveHeureList.current.childNodes[0].offsetHeight;
    //         setReleveHeureIconClasses('chevron-right chevron-right-opened')
    //         setReleveHeureHeight({height: openReleveHeureHeight})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: "ReleveHeure"})
    //     }
    //     else {
    //         setReleveHeureIconClasses('chevron-right')
    //         setReleveHeureHeight({height: '0'})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: null})

    //     }
    //     setReleveHeureOpen(!isReleveHeureOpen)
    // }

    // // Menu Documents
    // const [ isDocumentsOpen , setDocumentsOpen ] = useState(false);
    // const documentsList = useRef(null);
    // const [ documentsHeight , setDocumentsHeight ] = useState({height: '0'});
    // const [ documentsIconClasses, setDocumentsIconClasses ] = useState('chevron-right');
    // const handleDocumentsOpen = () => {
    //     if (!isDocumentsOpen) {
    //         let openDocumentsHeight = documentsList.current.childNodes.length * documentsList.current.childNodes[0].offsetHeight;
    //         setDocumentsIconClasses('chevron-right chevron-right-opened')
    //         setDocumentsHeight({height: openDocumentsHeight})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: "Documents"})
    //     }
    //     else {
    //         setDocumentsIconClasses('chevron-right')
    //         setDocumentsHeight({height: '0'})
    //         setActiveLinkName({...activeLinkName, subLinkTitle: null})

    //     }
    //     setDocumentsOpen(!isDocumentsOpen)
    // }

    // useEffect(() => {
    //   console.log(props.sidebarState)
    // }, [props.sidebarState])


    // useEffect(() => {
    //     // parse url on reload
    //     const currentUrl = window.location.pathname;
    //     switch (currentUrl) {
    //         case "/releve-heure/releve-chef":
    //             handleReleveHeureOpen();
    //             setActiveLinks(["ReleveHeureChef", "Relevé d'heures"])
    //             break;
    //         case "/releve-heure/banque-salaries":
    //             handleReleveHeureOpen();
    //             setActiveLinks(["BanqueHeure", "Relevé d'heures"])
    //             break;
    //         case "/releve-heure/absence":
    //             handleReleveHeureOpen();
    //             setActiveLinks(["Absence", "Relevé d'heures"])
    //             break;
    //         case "/transport/affecter-transport":
    //             handleTransportOpen();
    //             setActiveLinks(["AffecterTransport", "Transport"])
    //             break; 
    //         case "/transport/vehicules":
    //             handleTransportOpen();
    //             setActiveLinks(["Véhicules", "Transport"])
    //             break;
    //         case "/gestion-de-donnees/site":
    //             handleGestionOpen()
    //             setActiveLinks(["Site", "Gestion des données"])
    //             break;
    //         case "/gestion-de-donnees/formations-suivis":
    //             handleGestionOpen()
    //             setActiveLinks(["Formations", "Gestion des données"])
    //             break;
    //         case "/gestion-de-donnees/chantiers":
    //             handleGestionOpen()
    //             setActiveLinks(["Chantier", "Gestion des données"])
    //             break;  
    //         case "/gestion-de-donnees/materiaux":
    //             handleGestionOpen()
    //             setActiveLinks(["Matériaux", "Gestion des données"])
    //             break; 
    //         case "/gestion-de-donnees/salaries":
    //             handleGestionOpen()
    //             setActiveLinks(["Salariés", "Gestion des données"])
    //             break;
    //         case "/gestion-de-donnees/archive-salarie":
    //             handleGestionOpen()
    //             setActiveLinks(["ArchiveSalariés", "Gestion des données"])
    //             break;
    //         case "/gestion-de-donees/equipements":
    //             handleGestionOpen()
    //             setActiveLinkName({subLinkTitle: "Gestion", link:"Equipements"})
    //             break;
    //         case "/amiante":
    //             setActiveLinks(["Amiante"])
    //             break;  
    //         case "/gestion-de-donnees/archive-chantier":
    //             handleGestionOpen();
    //             setActiveLinks(["ArchiveChantiers", "Gestion des données"])
    //             break;
    //         case "/documents/publics":
    //             handleDocumentsOpen();
    //             setActiveLinks(["DocumentsPublics", "Documents"])
    //             break;
    //         case "/documents/categories":
    //             handleDocumentsOpen();
    //             setActiveLinks(["DocumentsCategories", "Documents"])
    //             break;
    //         case "/amiante/equipements":
    //             handleAmianteOpen()
    //             setActiveLinks(["Amiante", "Equipements"])
    //             break;
    //         case "/amiante/processus":
    //             handleAmianteOpen()
    //             setActiveLinks(["Amiante", "Processus"])
    //             break;
    //         case "/amiante/releve-exposition":
    //             handleAmianteOpen()
    //             setActiveLinks(["Amiante", "ReleveExposition"])
    //             break;
    //         default:
    //             if(currentUrl.includes('/releve-heure/releve-chef')) {
    //                 handleReleveHeureOpen();
    //                 setActiveLinks(["ReleveHeureChef", "Relevé d'heures"])
    //             }
    //             else {
    //                 setActiveLinks(["Home"])
    //             }
    //             break;
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    // const navigateTo = (link) => {
    //     switch (link) {
    //         case "Home":
    //             setActiveLinks([`${link}`])
    //             navigate("/")
    //             break;
    //         case "ReleveHeureChef":
    //             setActiveLinks([`${link}`, "Relevé d'heures"])
    //             navigate("releve-heure/releve-chef");
    //             break;
    //         case "BanqueHeure":
    //             setActiveLinks([`${link}`, "Relevé d'heures"])
    //             navigate("releve-heure/banque-salaries");
    //             break;
    //         case "Absence":
    //             setActiveLinks([`${link}`, "Relevé d'heures"])
    //             navigate("releve-heure/absence");
    //             break;
    //         case "AffecterTransport":
    //             setActiveLinks([`${link}`, "Transport"])
    //             navigate("transport/affecter-transport")
    //             break;
    //         case "Véhicules":
    //             setActiveLinks([`${link}`, "Transport"])
    //             navigate("transport/vehicules");
    //             break;
    //         case "Salariés":
    //             setActiveLinks([`${link}`, "Gestion des données"])
    //             navigate("gestion-de-donnees/salaries");
    //             break;
    //         case "ArchiveSalariés":
    //             setActiveLinks([`${link}`, "Gestion des données"])
    //             navigate("gestion-de-donnees/archive-salarie")
    //             break;
    //         case "Chantier":
    //             setActiveLinks([`${link}`, "Gestion des données"])
    //             navigate("gestion-de-donnees/chantiers");
    //             break;
    //         case "ArchiveChantiers":
    //             setActiveLinks([`${link}`, "Gestion des données"])
    //             navigate("gestion-de-donnees/archive-chantier")
    //             break;
    //         case "Site":
    //             setActiveLinks([`${link}`, "Gestion des données"])
    //             navigate("gestion-de-donnees/site");
    //             break;
    //         case "Formations":
    //             setActiveLinks([`${link}`, "Gestion des données"])
    //             navigate("gestion-de-donnees/formations-suivis");
    //             break;
    //         case "Matériaux":
    //             setActiveLinks([`${link}`, "Gestion des données"])
    //             navigate("gestion-de-donnees/materiaux");
    //             break
    //         case "DocumentsPublics":
    //             setActiveLinks([`${link}`, "Documents"])
    //             navigate("documents/publics")
    //             break;
    //         case "DocumentsCategories":
    //             setActiveLinks([`${link}`, "Documents"])
    //             navigate("documents/categories")
    //             break;
    //         case "Equipements":
    //             setActiveLinks([`${link}`, "Amiante"])
    //             navigate("amiante/equipements")
    //             break;
    //         case "Processus":
    //             setActiveLinks([`${link}`, "Amiante"])
    //             navigate("amiante/processus")
    //             break;
    //         case "ReleveExposition":
    //             setActiveLinks([`${link}`, "Amiante"])
    //             navigate("amiante/releve-exposition")
    //         default:
    //             break;
    //     }
    // }

    const handleItemClick = (e, nav) => {
        if (nav.type === "link") {
            props.setSidebarState(props.sidebarState.map((sidebarNav) => {
                if (sidebarNav.url === nav.url) {
                    return { ...sidebarNav, active: true };
                }
                else if (sidebarNav.type === "group") {
                    return {
                        ...sidebarNav, active: false, links: sidebarNav.links.map((sidebarSubNav) => {
                            return { ...sidebarSubNav, active: false };
                        })
                    };
                }
                return { ...sidebarNav, active: false };
            }));
            navigate(nav.url);
        }
        else if (nav.type === "group") {
            let openDocumentsHeight = e.currentTarget.nextSibling.childNodes.length * e.currentTarget.nextSibling.childNodes[0].offsetHeight - 4;
            let wasAutoOpened = false;
            props.setSidebarState(props.sidebarState.map((sidebarNav) => {
                if (sidebarNav.url === nav.url) {
                    if (sidebarNav.height === "fit-content") {
                        wasAutoOpened = true;
                        return { ...sidebarNav, height: openDocumentsHeight };
                    }
                    return { ...sidebarNav, opened: !sidebarNav.opened, height: !sidebarNav.opened ? openDocumentsHeight : 0 };
                }
                return sidebarNav;
            }));
            if (wasAutoOpened) {
                setTimeout(() => {
                    props.setSidebarState(props.sidebarState.map((sidebarNav) => {
                        if (sidebarNav.url === nav.url) {
                            return { ...sidebarNav, opened: !sidebarNav.opened, height: 0 };
                        }
                        return sidebarNav;
                    }));
                }, 50);
            }
        }
        else if (nav.type === "sublink") {
            props.setSidebarState(props.sidebarState.map((sidebarNav) => {
                if (`/${nav.url.split("/")[1]}`.includes(sidebarNav.url) && sidebarNav.url !== "/") {
                    return {
                        ...sidebarNav, active: true, links: sidebarNav.links.map((subnavLink) => {
                            if (nav.url.includes(subnavLink.url)) {
                                return { ...subnavLink, active: true };
                            }
                            return { ...subnavLink, active: false };
                        })
                    };
                }
                else {
                    if (sidebarNav.type === "group") {
                        return {
                            ...sidebarNav, active: false, links: sidebarNav.links.map((subnavLink) => {
                                return { ...subnavLink, active: false };
                            })
                        };
                    }
                    else {
                        return { ...sidebarNav, active: false };
                    }
                }
            }));
            navigate(nav.url);
        }
    };

    return (
        <div onMouseEnter={(e) => { e.currentTarget.classList.add("openHover"); document.getElementsByClassName("dashboard-layout-main-container")[0].classList.add("openHover"); }} onMouseLeave={(e) => { e.currentTarget.classList.remove("openHover"); document.getElementsByClassName("dashboard-layout-main-container")[0].classList.remove("openHover"); }} className="sidebar sidebar--admin-sidebar">
            <div className="sidebar__top">
                <img className="sidebar__top-logo" src={justeauLogo} alt="justeau logo" />
                {/* <MenuIcon menuClass="menuIconSidebar"/> */}
            </div>
            <hr className='sidebar__separator' />
            <div className="sidebar__main">
                <ul>
                    {props.sidebarState.map((nav) => {

                        return <li key={nav.Header} className='sidebar__main-linkContainer'>
                            <div className={nav.active ? "sidebar__main-linkItem sidebar__main-linkItem-linkActive" : "sidebar__main-linkItem"} onClick={(e) => handleItemClick(e, nav)}>
                                <Icon icon={nav.iconName} className='link-icon' />
                                <p className="sidebar__main-linkItem-text">{nav.Header}</p>
                                {nav.type === "group" &&
                                    <ChevronRightIcon menuClass={nav.opened ? "chevron-right chevron-right-opened" : "chevron-right"} />
                                }
                            </div>
                            {nav.type === "group" &&
                                <ul className="subMenu-linklist" style={{ height: nav.height }}>
                                    {nav.links.map((subnavLink) => {
                                        return <li key={subnavLink.url} className={subnavLink.active ? 'sidebar__main-nested-link sidebar__main-nested-link-linkActive' : 'sidebar__main-nested-link'} onClick={(e) => handleItemClick(e, subnavLink)}>
                                            {subnavLink.Header}
                                        </li>;
                                    })}
                                </ul>
                            }
                        </li>;
                    })}
                    {/* <li className='sidebar__main-linkContainer'>
                        <div className={activeLinks.includes("Home") ? 'sidebar__main-linkItem sidebar__main-linkItem-linkActive': 'sidebar__main-linkItem'} onClick={() => navigateTo("Home")}>
                            <HomeIcon menuClass="link-icon" />
                            <p className="sidebar__main-linkItem-text">Accueil</p>
                        </div>
                    </li>
                    <li className='sidebar__main-linkContainer'>
                        <div className={activeLinks.includes("Relevé d'heures") ? 'sidebar__main-linkItem sidebar__main-linkItem-linkActive': 'sidebar__main-linkItem'} onClick={() => handleReleveHeureOpen()}>
                            <HorlogeIcon menuClass="link-icon" />
                            <p className="sidebar__main-linkItem-text">Relevé d'heures</p>
                            <ChevronRightIcon menuClass={releveHeureIconClasses} />
                        </div>
                        <ul className="subMenu-linklist" style={releveHeureHeight} ref={releveHeureList}>
                            <li className={activeLinks.includes("ReleveHeureChef") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("ReleveHeureChef")}>Consulter les relevés d'heures</li>
                            <li className={activeLinks.includes("BanqueHeure") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("BanqueHeure")}>Banque d'heures salariés</li>
                            <li className={activeLinks.includes("Absence") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Absence")}>Absences</li>
                        </ul>
                    </li>
                    <li className='sidebar__main-linkContainer'>
                        <div className={activeLinks.includes("Transport") ?'sidebar__main-linkItem sidebar__main-linkItem-linkActive': 'sidebar__main-linkItem'} onClick={() => handleTransportOpen()}>
                            <TruckIcon menuClass="link-icon" />
                            <p className="sidebar__main-linkItem-text">Transport</p>
                            <ChevronRightIcon menuClass={transportIconClasses} />
                        </div>
                        <ul className="subMenu-linklist" style={transportHeight} ref={transportList}>
                            <li className={activeLinks.includes("AffecterTransport") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("AffecterTransport")}>Affecter des transports</li>
                            <li className={activeLinks.includes("Véhicules") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Véhicules")}>Véhicules</li>
                        </ul>
                        
                    </li>
                    <li className='sidebar__main-linkContainer'>
                        <div className={activeLinks.includes("Gestion des données") ?'sidebar__main-linkItem sidebar__main-linkItem-linkActive': 'sidebar__main-linkItem'} onClick={() => handleGestionOpen()}>
                            <DatabaseIcon menuClass="link-icon" />
                            <p className="sidebar__main-linkItem-text">Gestion des données</p>
                            <ChevronRightIcon menuClass={gestionIconClasses} />
                        </div>
                        <ul className="subMenu-linklist" style={gestionHeight} ref={gestionList}>
                            <li className={activeLinks.includes("Salariés") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Salariés")}>Salariés</li>
                            <li className={activeLinks.includes("ArchiveSalariés") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("ArchiveSalariés")}>Archive salariés</li>
                            <li className={activeLinks.includes("Chantier") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Chantier")}>Chantiers</li>
                            <li className={activeLinks.includes("ArchiveChantiers") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("ArchiveChantiers")}>Archive chantiers</li>
                            <li className={activeLinks.includes("Formations") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Formations")}>Formations</li>
                            <li className={activeLinks.includes("Site") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Site")}>Sites</li>
                            <li className={activeLinks.includes("Matériaux") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Matériaux")}>Matériaux</li>
                        </ul>
                        
                    </li>
                    <li className='sidebar__main-linkContainer'>
                        <div className={activeLinks.includes("Amiante") ?'sidebar__main-linkItem sidebar__main-linkItem-linkActive': 'sidebar__main-linkItem'} onClick={() => handleAmianteOpen()}>
                            <AmianteIcon menuClass="link-icon" />
                            <p className="sidebar__main-linkItem-text">Amiante</p>
                            <ChevronRightIcon menuClass={amianteIconClasses} />
                        </div>
                        <ul className="subMenu-linklist" style={amianteHeight} ref={amianteList}>
                            <li className={activeLinks.includes("ReleveExposition") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("ReleveExposition")}>Relevé d'exposition</li>
                            <li className={activeLinks.includes("Equipements") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Equipements")}>EPC</li>
                            <li className={activeLinks.includes("Processus") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("Processus")}>Processus</li>
                        </ul>
                    </li>
                    <li className='sidebar__main-linkContainer'>
                        <div className={activeLinks.includes("Documents") ?'sidebar__main-linkItem sidebar__main-linkItem-linkActive': 'sidebar__main-linkItem'} onClick={() => handleDocumentsOpen()}>
                            <Icon className="link-icon" icon="bi:folder-fill" />
                            <p className="sidebar__main-linkItem-text">Documents</p>
                            <ChevronRightIcon menuClass={documentsIconClasses} />
                        </div>
                        <ul className="subMenu-linklist" style={documentsHeight} ref={documentsList}>
                            <li className={activeLinks.includes("DocumentsPublics") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("DocumentsPublics")}>Documents publics</li>
                            <li className={activeLinks.includes("DocumentsCategories") ?'sidebar__main-nested-link sidebar__main-nested-link-linkActive': 'sidebar__main-nested-link'} onClick={() => navigateTo("DocumentsCategories")}>Gestion des catégories</li>
                        </ul>
                    </li> */}
                </ul>
            </div>
        </div>
    );
};

export default AdminSidebar;
