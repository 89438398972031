import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCoordinatesFromDD, getCoordinatesFromDMS } from '../../utils/GeneralHelper';
import { useFormik } from 'formik';
import * as yup from "yup";
import customToast from '../../utils/ToastifyHelper';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../components/ui/Breadcrumb';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';

import Map, { Marker } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import Modal from '../../components/Modal';
import MateriauxProvenantSite from './MateriauxProvenantSite';
import AjouterMateriauxProvenantSite from '../../components/forms/Site/AjouterMateriauxProvenantSite';
import UserAccountIcon from '../../components/icon/UserAccountIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import Select from "react-select";




const ProfilSite = (props) => {
    let { idSite } = useParams();
    const [site, setSite] = useState(null);
    const [afficherProfilSite, setAfficherProfilSite] = useState("informationsGenerales");

    const progressBarRef = useRef(null);
    const mapRef = useRef();
    const [currentMarker, setCurrentMarker] = useState(null);

    const onMapLoad = useCallback(() => {
        mapRef.current.on('click', (e) => {
            setFieldValue("gps", getCoordinatesFromDD(e.lngLat.lat, e.lngLat.lng));
            setCurrentMarker({ longitude: e.lngLat.lng, latitude: e.lngLat.lat });
        });
    }, []);

    const [groupedSalarieContactOptions, setGroupedSalarieContactOptions] = useState([]);
    const [salarieContacts, setSalarieContacts] = useState([]);

    useEffect(() => {
        axiosInstance.get("/utilisateurs/contacts").then((result) => {
            let groupedOptions = { "Administration": [], "Chef de chantier": [], "Ouvrier": [], "Chauffeur": [], "Intérimaire": [], "Encadrant de chantier": [], "Opérateur": [], "Encadrant technique": [] };
            for (const utilisateur of result.data) {
                if (utilisateur.userRole.statut !== "Archiver") {
                    groupedOptions[utilisateur.userRole.statut].push({ label: `${utilisateur.nom} ${utilisateur.prenom} - ${utilisateur.telephone.replace(/\s/g, '').replace(/(.{2})/g, '$1 ')}`, value: utilisateur._id });
                }
            }
            let groupedOptionsFormat = [];
            for (const utilisateurOptions of Object.keys(groupedOptions)) {
                groupedOptions[utilisateurOptions].sort((a, b) => a.label.localeCompare(b.label));
                groupedOptionsFormat.push({ label: utilisateurOptions, options: groupedOptions[utilisateurOptions] });
            }
            setGroupedSalarieContactOptions(groupedOptionsFormat);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const [salarieContactSelected, setSalarieContactSelected] = useState(null);
    const onAjouterContact = () => {
        if (salarieContactSelected) {
            progressBarRef.current.continuousStart();
            axiosInstance.patch(`/lieux/ajouterContact/${idSite}`, { contact: salarieContactSelected.value }).then((result) => {
                progressBarRef.current.complete();
                setSalarieContacts(salarieContacts.concat(result.data));
                setSalarieContactSelected(null);
            }).catch((err) => {
                progressBarRef.current.complete();
                showError(err);
            });
        }
        else {
            customToast.error("Aucun salarié n'a été sélectionné pour être ajouté au contact");
        }
    };

    const supprimerContact = (contactId) => {
        progressBarRef.current.continuousStart();
        axiosInstance.patch(`/lieux/supprimerContact/${idSite}`, { contact_id: contactId }).then((result) => {
            progressBarRef.current.complete();
            setSalarieContacts(salarieContacts.filter(contact => {
                return (
                    contact._id._id !== contactId
                );
            }));
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    const isSalarieAlreadyInContacts = (userId) => {
        for (const contact of salarieContacts) {
            if (contact._id._id === userId) {
                return true;
            }
        }
        return false;
    };


    // handle form
    const formSchema = yup.object().shape({
        libelle_lieu: yup.string().required("Veuillez saisir le nom du chantier"),
        ville: yup.string().optional(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        lieuExecution: yup.string().optional(),
        gps: yup.string().nullable().notRequired().when("gps", (gps, schema) => {
            if (gps) {
                return schema.matches(/^([0-9]{1,2})°([0-9]{1,2})'([0-9]{1,2}(?:\.[0-9]+)?)"([NS]) ([0-9]{1,3})°([0-9]{1,2})'([0-9]{1,2}(?:\.[0-9]+)?)"([EW])$/, "Erreur de format du point gps, la latitude et la longitude doivent être séparé par un espace");
            }
            return schema;
        }),
    },
        [["codePostal", "codePostal"], ["gps", "gps"]]
    );

    const onSubmit = (values, actions) => {
        progressBarRef.current.continuousStart();
        const patchData = {
            ...values,
            codePostal: values.codePostal ? values.codePostal : null,
            gps: values.gps.length ? values.gps : null,
        };
        // post chantier
        axiosInstance.patch(`/site/${site._id._id}`, patchData)
            .then((result) => {
                progressBarRef.current.complete();
                setSubmitting(false);
                customToast.success("Le site a été modifié avec succès");
                setSite(result.data);
            }).catch((err) => {
                progressBarRef.current.complete();
                setSubmitting(false);
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelle_lieu: "",
            ville: "",
            codePostal: "",
            lieuExecution: "",
            gps: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/site/${idSite}`).then((result) => {
            progressBarRef.current.complete();
            setSite(result.data);
            setSalarieContacts(result.data._id.contacts);
            if (result.data._id.libelle_lieu !== null) {
                setFieldValue("libelle_lieu", result.data._id.libelle_lieu);
            }
            if (result.data._id.adresse.ville !== null) {
                setFieldValue("ville", result.data._id.adresse.ville);
            }
            if (result.data._id.adresse.codePostal !== null) {
                setFieldValue("codePostal", result.data._id.adresse.codePostal);
            }
            if (result.data._id.adresse.lieuExecution !== null) {
                setFieldValue("lieuExecution", result.data._id.adresse.lieuExecution);
            }
            if (result.data._id.adresse !== null && result.data._id.adresse.hasOwnProperty("gps") && result.data._id.adresse.gps.latitude !== null && result.data._id.adresse.gps.longitude !== null) {
                setFieldValue("gps", getCoordinatesFromDD(result.data._id.adresse.gps.latitude, result.data._id.adresse.gps.longitude));
                setCurrentMarker({
                    latitude: result.data._id.adresse.gps.latitude,
                    longitude: result.data._id.adresse.gps.longitude,
                });
            }
        }).catch((err) => {
            showError(err);
        });
    }, [idSite]);


    const fieldNotModified = () => {
        return (values.libelle_lieu === site._id.libelle_lieu &&
            values.ville === site._id.adresse.ville &&
            values.codePostal === site._id.adresse.codePostal &&
            values.lieuExecution === site._id.adresse.lieuExecution &&
            values.gps === getCoordinatesFromDD(site._id.adresse.gps.latitude, site._id.adresse.gps.longitude));
    };

    const onGpsBlur = (e) => {
        if (!errors.gps && values.gps !== "") {
            setCurrentMarker(getCoordinatesFromDMS(values.gps));
            mapRef.current.flyTo({
                center: [getCoordinatesFromDMS(values.gps).longitude, getCoordinatesFromDMS(values.gps).latitude],
                essential: true
            });
        }
        else {
            setCurrentMarker(null);
        }
    };

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");
    const [modalDataSelected, setModalDataSelected] = useState({});

    const addMateriauxProvenantDuSite = (materiaux) => {
        setSite({ ...site, stockageMateriauxSites: site.stockageMateriauxSites.concat(materiaux) });
        console.log(materiaux);
    };

    const openAddMateriauxProvenantDuSite = (site) => {
        setModalDataSelected(site);
        setModalClasses("modal modal__active");
        setModalContent("ajouter materiaux provenant site");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };


    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            {site &&
                <div className="section">
                    <h1>{site._id.libelle_lieu}</h1>
                    <Breadcrumb
                        links={[
                            { label: "Gestion des données", link: "/gestion-de-donnees/" },
                            { label: "Sites", link: "/gestion-de-donnees/sites/" },
                            { label: "Profil", link: "/gestion-de-donnees/salaries/profil/" },
                        ]}
                    />
                    <InsideNavigationBar
                        activeValue={afficherProfilSite}
                        setActiveValue={(value) => setAfficherProfilSite(value)}
                        links={[
                            { label: "Informations générales", value: "informationsGenerales" },
                            { label: "Stockages", value: "stockages" },
                        ]}
                    />
                    {afficherProfilSite === "informationsGenerales" &&
                        <div className="profil-salarie-container">
                            <div className='container-basic salarie-info'>
                                <h4 className='mgB-b'>Informations générales</h4>
                                <form className='form' onSubmit={handleSubmit}>
                                    <div className='label-and-input'>
                                        <label htmlFor='libelle_lieu' className='form-label'>Nom du site<span className='required-field'>*</span></label>
                                        <input name='libelle_lieu' className={`new-default-input${errors.libelle_lieu && touched.libelle_lieu ? " input-error" : ""}`} type="text" placeholder="Nom du chantier" value={values.libelle_lieu} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.libelle_lieu && touched.libelle_lieu && <p className="form-error-message">{errors.libelle_lieu}</p>}
                                    </div>
                                    <div className='label-and-input'>
                                        <label htmlFor='lieuExecution' className='form-label'>Lieu d'éxecution</label>
                                        <input name='lieuExecution' className={`new-default-input${errors.lieuExecution && touched.lieuExecution ? " input-error" : ""}`} type="text" placeholder="Lieu d'éxecution" value={values.lieuExecution} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.lieuExecution && touched.lieuExecution && <p className="form-error-message">{errors.lieuExecution}</p>}
                                    </div>
                                    <div className='label-and-input'>
                                        <label htmlFor='ville' className='form-label'>Ville</label>
                                        <input name='ville' className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} type="text" placeholder="Ville" value={values.ville} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                                    </div>
                                    <div className='label-and-input'>
                                        <label htmlFor='codePostal' className='form-label'>Code postal</label>
                                        <input name='codePostal' className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} type="text" placeholder="Code postal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                                    </div>
                                    <div className='label-and-input'>
                                        <label htmlFor='gps' className='form-label'>Point gps</label>
                                        <input name='gps' className={`new-default-input${errors.gps && touched.gps ? " input-error" : ""}`} type="text" placeholder={`exemple: 47°27'43.0"N 0°30'57.2"W`} value={values.gps} onChange={handleChange} onBlur={(e) => { handleBlur(e); onGpsBlur(e); }} />
                                        {errors.gps && touched.gps && <p className="form-error-message">{errors.gps}</p>}
                                    </div>
                                    <Map
                                        ref={mapRef}
                                        attributionControl={false}
                                        initialViewState={site._id.adresse.hasOwnProperty("gps") && site._id.adresse.gps.latitude !== null && site._id.adresse.gps.longitude !== null ? { latitude: site._id.adresse.gps.latitude, longitude: site._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                        onLoad={onMapLoad}
                                        style={{ width: "100%", height: "270px", marginBottom: "2rem" }}
                                        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                    >
                                        {currentMarker !== null &&
                                            <Marker longitude={currentMarker.longitude} latitude={currentMarker.latitude} anchor="bottom">

                                            </Marker>
                                        }
                                    </Map>
                                    <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier le site" />
                                </form>
                            </div>
                            <div className='salarie-stats'>
                                <div className="container-basic">
                                    <h4 className='mgB-s2'>Contacts</h4>
                                    <div className='mgB-s3' style={{ display: 'flex' }}>
                                        <Select
                                            options={groupedSalarieContactOptions}
                                            className="formSelect-search-container formSelect-search-container-full"
                                            classNamePrefix="formSelect-search"
                                            onChange={(opt) => setSalarieContactSelected(opt)}
                                            placeholder={""}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    fontFamily: "DM Sans",
                                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                    borderRadius: "0.6rem",
                                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                    '&:hover': {
                                                        border: '1px solid black',
                                                    },
                                                })
                                            }}
                                            isOptionDisabled={(option) => isSalarieAlreadyInContacts(option.value)}
                                            isSearchable={true}
                                            isClearable={true}
                                        />
                                        <button onClick={() => onAjouterContact()} disabled={salarieContactSelected === null} style={{ opacity: salarieContactSelected !== null ? 1 : 0.4 }} className="btn btn-primary mgL-s3">Ajouter</button>
                                    </div>
                                    <hr />
                                    <div style={{ maxHeight: "200px", overflowY: "auto" }} className='mgT-s2'>
                                        {salarieContacts.length ? salarieContacts.map((contact, index) => {
                                            return <div key={contact._id._id} style={{ justifyContent: "flex-start", backgroundColor: index % 2 !== 0 ? "#f1f1f1f1" : "#fff", padding: "1.2rem 1.2rem", cursor: "default" }} className="dashboard-header-userAccount-container">
                                                <UserAccountIcon />
                                                <div style={{ flex: 1 }} className="mgL-s2">
                                                    <p style={{ color: "#d8556b", fontWeight: 500, lineHeight: "1.1rem", fontSize: "1.2rem", paddingBottom: "0.4rem" }}>Administration</p>
                                                    <div style={{ width: "100%", display: "flex", flexWrap: "wrap", alignItems: "center", gap: "1rem" }}>
                                                        <p style={{ flex: 1, fontSize: "1.3rem", fontWeight: 700, color: "#1E1E1E", lineHeight: "1.6rem", whiteSpace: "nowrap" }}>{contact._id.nom + " " + contact._id.prenom}</p>
                                                        <p style={{ flex: 1, lineHeight: "1rem", whiteSpace: "nowrap" }}>{contact._id.telephone}</p>
                                                    </div>
                                                </div>
                                                <FontAwesomeIcon onClick={() => supprimerContact(contact._id._id)} icon={faXmarkLarge} size='lg' />
                                            </div>;
                                        }) :
                                            <p>Aucun salarié dans la liste des contacts</p>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                    {afficherProfilSite === "stockages" &&
                        <div className='mgT-m2'>
                            <h3>Matériaux provenant du site</h3>
                            <MateriauxProvenantSite idSite={idSite} site={site} progressBarRef={progressBarRef} openAddMateriauxProvenantDuSite={(siteId) => openAddMateriauxProvenantDuSite(siteId)} />
                        </div>
                    }
                </div>
            }
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter materiaux provenant site" &&
                    <AjouterMateriauxProvenantSite progressBarRef={progressBarRef} addMateriauxProvenantDuSite={(data) => addMateriauxProvenantDuSite(data)} modalDataSelected={modalDataSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>
        </>
    );
};

export default ProfilSite;;