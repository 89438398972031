import React, { useMemo } from "react";
import { useTable, useSortBy } from 'react-table';
import SortIcon from '../../icon/SortIcon';


const TablePaginationV2 = (props) => {

    const columns = useMemo(() => props.columns, []);

    const tableInstance = useTable({
        columns,
        data: props.tableData,
        initialState: {
            sortBy: [{ id: props.pageOptions.sort, desc: props.pageOptions.sortBy === "desc" }],
            disableMultiSort: false
        },
    }, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    const canNextPage = () => {
        return (props.pageOptions.page + 1 <= props.pageOptions.totalPages);
    };

    const canPreviousPage = () => {
        return (props.pageOptions.page - 1 > 0);
    };

    const canGoToFirstPage = () => {
        return (props.pageOptions.page > 1);
    };

    const canGoToLastPage = () => {
        return (props.pageOptions.page < props.pageOptions.totalPages);
    };

    return (
        <div className={`main-content${props.noBorder ? " noBorder" : ""}`}>
            {props.tableTitle && <>{props.tableTitle}</>}
            <table className="default-table" {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr key="header" {...headerGroup.getHeaderGroupProps}>
                                {
                                    headerGroup.headers.map((column) => {
                                        return (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                onClick={(e) => {
                                                    if (column.canSort) {
                                                        column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                                                        if (column.isSorted) {
                                                            if (column.isSortedDesc) {
                                                                // unsort
                                                                props.setPageOptionsFetch({ sort: "" });
                                                            }
                                                            else {
                                                                // sort desc
                                                                props.setPageOptionsFetch({ sort: column.id, sortBy: "desc" });
                                                            }
                                                        }
                                                        else {
                                                            // sort asc
                                                            props.setPageOptionsFetch({ sort: column.id, sortBy: "asc" });
                                                        }


                                                    }
                                                }}>{column.render("Header")}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <SortIcon classIconHaut="filter-icon filter-icon__haut filter-icon__active" classIconBas="filter-icon filter-icon__bas" />
                                                        : <SortIcon classIconBas="filter-icon filter-icon__bas filter-icon__active" classIconHaut="filter-icon filter-icon__haut" />
                                                    : <p></p>
                                                }
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr key={row.id} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td key={cell.row.id} {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {!props.noPagination &&
                <div className="pagination">
                    <div className="nombreLigneParPage-pagination">
                        <p>Nombre de lignes par page:</p>
                        <select
                            value={props.pageOptions.limit}
                            onChange={e => {
                                props.setPageOptionsFetch({ limit: Number(e.target.value) });
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="nbPageAndButton-paginationContainer">
                        <div className="button-pagination">
                            <button onClick={() => { props.setPageOptionsFetch({ page: 1 }); }} disabled={!canGoToFirstPage()}>
                                <span>{'<<'}</span>
                            </button>{' '}
                            <button onClick={() => { props.setPageOptionsFetch({ page: props.pageOptions.page - 1 }); }} disabled={!canPreviousPage()}>
                                <span>{'<'}</span>
                            </button>{' '}
                            <button onClick={() => { props.setPageOptionsFetch({ page: props.pageOptions.page + 1 }); }} disabled={!canNextPage()}>
                                <span>{'>'}</span>
                            </button>{' '}
                            <button onClick={() => { props.setPageOptionsFetch({ page: props.pageOptions.totalPages }); }} disabled={!canGoToLastPage()}>
                                <span>{'>>'}</span>
                            </button>{' '}
                        </div>
                        <div className="nombrePage-pagination">
                            <span>
                                Page{' '}
                                <strong>
                                    {props.pageOptions.page} sur {props.pageOptions.totalPages}
                                </strong>{' '}
                            </span>
                        </div>
                    </div>
                    <div className="goToPage-pagination">
                        <span>
                            Aller à la page:{' '}
                            <input
                                type="number"
                                value={props.pageOptions.page}
                                onChange={e => {
                                    props.setPageOptionsFetch({ page: e.target.value });
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                    </div>
                </div>
            }
        </div>
    );
};

export default TablePaginationV2;
