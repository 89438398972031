import React, { useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { formatBytes, iconExtension } from '../../../utils/GeneralHelper';
import Select from 'react-select/creatable';
import * as yup from "yup";
import { useFormik } from 'formik';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import customToast from '../../../utils/ToastifyHelper';


const AjouterDocumentProcessus = (props) => {
    const controller = new AbortController();
    const [progress, setProgress] = useState(0);

    const close = () => {
        controller.abort();
        props.closeModal();
        props.unrenderForm();
    };

    const formSchema = yup.object().shape({
        titre: yup.string().required("Veuillez saisir le titre du document"),
        typeDocumentProcessus: yup.string(),
    });

    const onSubmit = (values, actions) => {
        // bar progress
        const config = {
            onUploadProgress: progressEvent => {
                let calculateProgress = progress;
                calculateProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress(Math.floor(calculateProgress));
            }
        };

        let postData = new FormData();
        postData.append("titre", values.titre);
        postData.append("typeDocumentProcessus", values.typeDocumentProcessus);
        postData.append("document", props.documentData);
        postData.append("processusId", props.processusId);
        postData.append("documentSaveRoute", "ajouterDocumentProcessus");

        axiosInstance.post("/uploads", postData, { ...config, timeout: 0, signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } })
            .then((result) => {
                actions.resetForm();
                customToast.success("Le document a été enregistré avec succès");
                props.addDocumentProcessus(result.data);
                close();
            }).catch((err) => {
                setSubmitting(false);
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting, setFieldValue } = useFormik({
        initialValues: {
            "titre": props.documentData.name.replace(/\.[^/.]+$/, ""),
            "typeDocumentProcessus": "Fiche processus",
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onChangeTypeDocument = (opt) => {
        if (opt) {
            setFieldValue("typeDocumentProcessus", opt.value);
        }
        else {
            setFieldValue("typeDocumentProcessus", opt);
        }
    };

    return (
        <>
            <LoadingBar color={"#d13852"} progress={progress} onLoaderFinished={() => setProgress(0)} />
            <div className="modal-content ajouter-form">
                <h3 className="modal-title">Ajouter un document processus</h3>
                <button className='modal-button' onClick={() => close()}>x</button>
                <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                    <div className='label-and-input'>
                        <label htmlFor='documentContent' className='form-label'>Document ajouté</label>
                        <div className="document-upload mgT-s2">
                            <div className="fileItem">
                                {iconExtension(props.documentData.path)}
                                <p>{props.documentData.path} - {formatBytes(props.documentData.size)}</p>
                            </div>
                        </div>
                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='titre' className='form-label'>Titre du document<span className='required-field'>*</span></label>
                        <input name='titre' className={`new-default-input${errors.titre && touched.titre ? " input-error" : ""}`} type="text" placeholder="Titre du document" value={values.titre} onChange={handleChange} onBlur={handleBlur} />
                        {errors.titre && touched.titre && <p className="form-error-message">{errors.titre}</p>}
                    </div>
                    <div className='label-and-input last-input'>
                        <label htmlFor='typeDocumentProcessus' className='form-label'>Type du document</label>
                        <Select
                            options={[
                                { label: "Fiche processus", value: "Fiche processus" },
                                { label: "Notice de poste", value: "Notice de poste" },
                                { label: "Autre", value: "Autre" }
                            ]}
                            name='typeDocumentProcessus'
                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                            classNamePrefix="formSelect-search"
                            onChange={(opt) => onChangeTypeDocument(opt)}
                            placeholder={"Visibilité du document"}
                            defaultValue={{ label: "Fiche processus", value: "Fiche processus" }}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    fontFamily: "DM Sans",
                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                    borderRadius: "0.6rem",
                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                    '&:hover': {
                                        border: '1px solid black',
                                    },
                                })
                            }}
                            isSearchable={false}
                            isClearable={false}
                            isMulti={false}
                        />
                        {errors.typeDocumentProcessus && touched.typeDocumentProcessus && <p className="form-error-message">{errors.typeDocumentProcessus}</p>}
                    </div>
                    <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Ajouter document" />

                </form>
            </div>
        </>
    );
};

export default AjouterDocumentProcessus;