import React, { useEffect, useState } from "react";
import { formatBytes, getAllUserType } from "../../utils/GeneralHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faXmark } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import customToast from "../../utils/ToastifyHelper";
import { showError } from "../../utils/ErrorHelper";
import { removeFileFromUpload, setNomFichier, setVisibilityFichier } from "../../store/actions/uploadFilesAct";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import Select from "react-select";


const FileUploadItem = (props) => {

    const dispatch = useDispatch();
    const [nomFichierInput, setNomFichierInput] = useState(props.fichier.nomFichier);
    const [errorNomFichier, setErrorNomFichier] = useState(null);


    const onChangeNomFichier = (e) => {
        setNomFichierInput(e.target.value);
    };

    const checkErrorNomFichier = () => {
        if (errorNomFichier !== null) {
            setErrorNomFichier(null);
        }
    };

    useEffect(() => {
        if (nomFichierInput !== null && nomFichierInput !== "") {
            let timer = setTimeout(() => {
                dispatch(setNomFichier(props.fichier._id, nomFichierInput));
                checkErrorNomFichier();
                // Voir si le nom n'est pas déja pris
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            dispatch(setNomFichier(""));
            setErrorNomFichier("Veuillez renseigner le nom du fichier");
        }
    }, [nomFichierInput]);

    const optionsUserTypeVisibilityRights = getAllUserType().map((userType) => {
        return { label: userType, value: userType };
    });

    return <div className="uploadFile-item">
        {props.fichier.progress !== 100 &&
            <div className="deleteIconContainer">
                <FontAwesomeIcon className="deleteIcon" icon={faXmark} onClick={() => dispatch(removeFileFromUpload(props.fichier))} />
            </div>
        }
        <div className="fileInfo">
            <div className="fileIcon-container">
                <FontAwesomeIcon className="fileIcon" icon={faFile} />
            </div>
            <div className="fileInfo-content">
                {props.fichier.progress === 0 ?
                    <>
                        <input name='fileName' className={`new-default-input${errorNomFichier !== null ? " input-error" : ""}`} type="text" placeholder="Nom du fichier" value={nomFichierInput} onChange={onChangeNomFichier} />
                        {errorNomFichier !== null && <p className="form-error-message">{errorNomFichier}</p>}
                    </>
                    :
                    <p className="fileName">{props.fichier.nomFichier}</p>
                }
                <p className="fileSize">{formatBytes(props.fichier.original.size)}</p>
            </div>
        </div>
        <div className='label-and-input visibleBy'>
            <label htmlFor='visibility' className='form-label'>Visible par</label>
            <Select
                options={optionsUserTypeVisibilityRights}
                className="formSelect-search-container formSelect-search-container-full mgT-s2"
                classNamePrefix="formSelect-search"
                onChange={(opt) => dispatch(setVisibilityFichier(props.fichier._id, opt))}
                placeholder={"Visibilité"}
                defaultValue={props.fichier.visibility}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        fontFamily: "DM Sans",
                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                        borderRadius: "0.6rem",
                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                        '&:hover': {
                            border: '1px solid black',
                        },
                    })
                }}
                isMulti={true}
                isSearchable={true}
                isClearable={false}
            />
        </div>
        <div className="progressBarFileUpload-container">
            <ProgressBar bgColor="#1E1E1E" height="10px" maxCompleted={100} isLabelVisible={false} className='progressBarFileUpload' completed={props.fichier.progress} />
            {props.fichier.progress > 0 && <p>{props.fichier.progress}%</p>}
        </div>
    </div>;
};

export default FileUploadItem;

