import axiosInstance from '../../utils/axiosInstance';
import * as actionTypes from './actionTypes';
import { format } from 'date-fns';
import customToast from '../../utils/ToastifyHelper';

const loadReleveHeureList = (statutSemaine, jours) => {
    return {
        type: actionTypes.LOAD_RELEVE_HEURE_ME,
        statutSemaine: statutSemaine,
        jours: jours
    };
};

export const unloadReleveHeure = () => {
    return {
        type: actionTypes.UNLOAD_RELEVE_HEURE
    };
};

export const getReleveHeureListMe = (annee, numero_semaine) => (dispatch) => {
    // get releve heure
    axiosInstance.get(`/horaires_semaines/me?numero_semaine=${numero_semaine}&annee=${annee}`)
        .then((result) => {
            let statutSemaine = null;
            let jours = [];
            if (result.data.length > 0) {
                statutSemaine = result.data[0].statut_horaire_semaine;
                for (const jour of result.data[0].jours) {
                    jours.push({ date: format(new Date(jour.date_jour), 'yyyy-MM-dd'), travail_ouvriers: jour.travail_ouvriers, travail_interimaires: jour.travail_interimaires, travail_chefs_chantier: jour.travail_chefs_chantier, absences: jour.absences, chantier: jour.chantier });
                    //         // get travaux Ouvrier info
                    //         for (let i = 0; i < jourAjouter.travail_ouvriers.length; i++) {
                    //             const travailsId = jourAjouter.travail_ouvriers[i];
                    //             axiosInstance.get("/travails_ouvriers/" + travailsId)
                    //             .then((res) => {
                    //                 let travaux = { heure: res.data.horaire.heure, minute: res.data.horaire.minute, ouvrier: res.data.ouvrier._id }
                    //                 if(!chantierAjouter) {
                    //                     axiosInstance.get("/chantiers/" + res.data.chantier)
                    //                     .then((response) => {
                    //                         jourAjouter.chantier = response.data
                    //                     })
                    //                     chantierAjouter = true;
                    //                 }
                    //                 if(res.data.commentaire) {
                    //                     travaux.commentaire = res.data.commentaire
                    //                 }
                    //                 jourAjouter.travail_ouvriers[i] = travaux
                    //             }).catch((error) => {
                    //                 console.log(error)                        
                    //             });
                    //         }
                    //         // get travaux Interimaires info
                    //         for (let i = 0; i < jourAjouter.travail_interimaires.length; i++) {
                    //             const travailsId = jourAjouter.travail_interimaires[i];
                    //             axiosInstance.get("/travails_ouvriers/" + travailsId)
                    //             .then((res) => {
                    //                 let travaux = { heure: res.data.horaire.heure, minute: res.data.horaire.minute, interimaire: res.data.interimaire._id }
                    //                 if(!chantierAjouter) {
                    //                     axiosInstance.get("/chantiers/" + res.data.chantier)
                    //                     .then((response) => {
                    //                         jourAjouter.chantier = response.data
                    //                     })
                    //                     chantierAjouter = true;
                    //                 }
                    //                 if(res.data.commentaire) {
                    //                     travaux.commentaire = res.data.commentaire
                    //                 }
                    //                 jourAjouter.travail_interimaires[i] = travaux
                    //             }).catch((error) => {
                    //                 console.log(error)                        
                    //             });
                    //         }
                    //         // get travaux chef chantier info
                    //         for (let i = 0; i < jourAjouter.travail_chefs_chantier.length; i++) {
                    //             const travailsId = jourAjouter.travail_chefs_chantier[i];
                    //             axiosInstance.get("/diriger_chantiers_classique/" + travailsId)
                    //             .then((res) => {
                    //                 let travaux = { heure: res.data.horaire.heure, minute: res.data.horaire.minute, chefChantier: res.data.chef_chantier._id }
                    //                 if(!chantierAjouter) {
                    //                     axiosInstance.get("/chantiers/" + res.data.chantier)
                    //                     .then((response) => {
                    //                         jourAjouter.chantier = response.data
                    //                     })
                    //                     chantierAjouter = true;
                    //                 }
                    //                 if(res.data.commentaire) {
                    //                     travaux.commentaire = res.data.commentaire
                    //                 }
                    //                 jourAjouter.travail_chefs_chantier[i] = travaux
                    //             }).catch((error) => {
                    //                 console.log(error)                        
                    //             });
                    //         }
                    //         chantierAjouter = false;
                }
            }
            dispatch(loadReleveHeureList(statutSemaine, jours));
        }).catch((err) => {
            if (err.response) {
                // Request made and server responded
                if (err.response.data.hasOwnProperty("msg")) {
                    customToast.error(err.response.data.msg);
                }
                else {
                    customToast.error(err.response.data.errors[0].msg);
                }
            }
            else if (err.request) {
                // The request was made but no response was received
                console.log(err.request);
            }
            else {
                // Something happened in setting up the request that triggered an err
                console.log('Error', err.message);
                customToast.error("Erreur du serveur");
            }
        });
};