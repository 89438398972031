import axiosInstance from '../../utils/axiosInstance';
import * as actionTypes from './actionTypes';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';

const loadCategoriesDocuments = (categoriesList) => {
    return {
        type: actionTypes.LOAD_CATEGORIES_DOCUMENT,
        categoriesDocument: categoriesList
    };
};

export const addCategorie = (categorie) => {
    return {
        type: actionTypes.ADD_CATEGORIE_DOCUMENT,
        documentCategorie: categorie
    };
};

export const unloadCategoriesDocuments = () => {
    return {
        type: actionTypes.UNLOAD_CATEGORIES_DOCUMENT
    };
};

export const deleteCategorie = (categorieId) => {
    return {
        type: actionTypes.DELETE_CATEGORIE_DOCUMENT,
        categorieId: categorieId
    };
};

export const deleteType = (payload) => {
    return {
        type: actionTypes.DELETE_TYPE_DOCUMENTS,
        categorieUpdated: payload.categorieUpdated,
        idTypeSupprimer: payload.idTypeSupprimer
    };
};
export const addTypeDocuments = (payload) => {
    return {
        type: actionTypes.ADD_TYPE_DOCUMENTS,
        categorieUpdatedId: payload.categorie,
        typeDocuments: payload.typeDocuments
    };
};

export const modifyCategorie = (categorieUpdated) => {
    return {
        type: actionTypes.MODIFY_CATEGORIE_DOCUMENT,
        categorieUpdated: categorieUpdated
    };
};

export const modifyType = (payload) => {
    return {
        type: actionTypes.MODIFY_TYPE_DOCUMENT,
        categorieUpdated: payload.categorieUpdated,
        typeDocument: payload.typeDocument
    };
};

export const getCategorieDocument = () => (dispatch) => {
    // get all materiaux
    axiosInstance.get('/categories', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadCategoriesDocuments(result.data));
        }).catch((err) => {
            showError(err);
        });
};