import { combineReducers } from 'redux';
import userReducer from "./reducers/userRdc";
import userListRdc from "./reducers/userListRdc";
import vehiculeListRdc from "./reducers/vehiculeListRdc";
import materiauxListRdc from "./reducers/materiauxListRdc";
import chantierListRdc from "./reducers/chantierListRdc";
import absencesRdc from "./reducers/absencesRdc";
import releveHeureRdc from "./reducers/releveHeureRdc";
import currentYearAndWeekRdc from "./reducers/currentYearAndWeekRdc";
import banqueHeureRdc from "./reducers/banqueHeureRdc";
import adminReleveRdc from "./reducers/adminReleveRdc";
import categoriesDocumentRdc from "./reducers/categoriesDocumentRdc";
import documentRdc from "./reducers/documentRdc";
import equipementEpcListRdc from "./reducers/equipementEpcListRdc";
import topBarRdc from "./reducers/topBarRdc";
import formOptionsRdc from "./reducers/formOptionsRdc";
import processusRdc from "./reducers/processusRdc";
import optionsFilterSortRdc from "./reducers/optionsFilterSortRdc";
import suiviTypeFormationRdc from "./reducers/suiviTypeFormationRdc";
import tableOptionsRdc from "./reducers/tableOptionsRdc";
import pageStateRdc from "./reducers/PageStateRdc";
import fichiersRdc from "./reducers/FichiersRdc";
import uploadFilesRdc from "./reducers/UploadFilesRdc";

const reducers = combineReducers({
	user: userReducer,
	userList: userListRdc,
	vehiculeList: vehiculeListRdc,
	materiauxList: materiauxListRdc,
	chantierZoneList: chantierListRdc,
	absenceList: absencesRdc,
	releveHeure: releveHeureRdc,
	currentWeek: currentYearAndWeekRdc,
	banqueHeure: banqueHeureRdc,
	releveHeureAdminRdc: adminReleveRdc,
	categorieDocument: categoriesDocumentRdc,
	documents: documentRdc,
	equipementEpcList: equipementEpcListRdc,
	topBar: topBarRdc,
	formOptions: formOptionsRdc,
	processus: processusRdc,
	optionsFilterSort: optionsFilterSortRdc,
	suiviTypeFormation: suiviTypeFormationRdc,
	tableOptions: tableOptionsRdc,
	pageState: pageStateRdc,
	fichiers: fichiersRdc,
	uploadFiles: uploadFilesRdc
});

export default reducers;