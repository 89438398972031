import React, { useRef } from 'react';

const SingleTimeInput = (props) => {
    const minuteRef = useRef();

    const onTimeInput = (horaire, temps) => {
        let formatHoraire = horaire.target.value;
        if (temps === "Heure") {
            if (parseInt(formatHoraire) > 23) {
                props.modifierHoraireInput({ ...props.horaire, heure: "23" });
                minuteRef.current.focus();
            }
            else {
                props.modifierHoraireInput({ ...props.horaire, heure: formatHoraire });
                if (formatHoraire.length === 2) {
                    minuteRef.current.focus();
                }
            }
        }
        else {
            if (parseInt(formatHoraire) > 59) {
                props.modifierHoraireInput({ ...props.horaire, minute: "59" });
                minuteRef.current.blur();
            }
            else {
                props.modifierHoraireInput({ ...props.horaire, minute: formatHoraire });
                if (formatHoraire.length === 2) {
                    minuteRef.current.blur();
                }
            }
        }
    };

    const formatInput = (horaire, intervalle) => {
        const horaireValue = horaire.target.value;
        if (intervalle === "Heure") {
            if (horaireValue.length === 1) {
                props.modifierHoraireInput({ ...props.horaire, heure: `0${horaireValue}` });
            }
        }
        else {
            if (horaireValue.length === 1) {
                props.modifierHoraireInput({ ...props.horaire, minute: `0${horaireValue}` });
            }
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", marginRight: props.marginRight !== undefined ? props.marginRight : 0, ...props.style }}>
            <input
                style={{ width: "20%" }}
                className='custom-time-input left'
                value={props.horaire.heure}
                type="number"
                onChange={text => onTimeInput(text, "Heure")}
                onBlur={text => formatInput(text, "Heure")}
            />
            <div className='custom-time-input center'>
                <p>:</p>
            </div>
            <input
                style={{ width: "20%" }}
                className='custom-time-input right'
                value={props.horaire.minute}
                type="number"
                ref={minuteRef}
                onChange={text => onTimeInput(text, "Minute")}
                onBlur={text => formatInput(text, "Minute")}
            />
        </div>
    );
};

export default SingleTimeInput;