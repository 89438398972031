// import React from 'react';
// import UserAccountHead from './UserAccountHead'
// import { useDispatch } from 'react-redux'   
// import * as actionCreators from '../../store/actions/exportAction';
// import justeauLogo from '../../assets/img/justeaulogo.png'
// import { useNavigate } from 'react-router-dom';

// const onUserLogout = () => (actionCreators.logout());
// const ChefSidebar = () => {
//     const dispatch = useDispatch();
//     const currentUrl = window.location.pathname;
//     const navigate = useNavigate();

//     return (
//         <div className="dashboard-header-chef">
//             <div className="left-header-chef">
//                 <img className="chef-header-logo" src={justeauLogo} alt="justeau logo"/>
//             </div>
//             <div className="middle-header-chef">
//                 <p onClick={() => navigate("/")} className={currentUrl === '/' ? 'chef-button-nav chef-button-nav-active' : 'chef-button-nav'}>Banque d'heures</p>
//                 <p onClick={() => navigate("/releve-heures-chef")} className={currentUrl === '/releve-heures-chef' ? 'chef-button-nav chef-button-nav-active' : 'chef-button-nav'}>Relevés d'heures</p>  
//                 <p onClick={() => navigate("/documents/publics")} className={currentUrl === '/documentsss/publics' ? 'chef-button-nav chef-button-nav-active' : 'chef-button-nav'}>Documents publics</p>  
//             </div>
//             <div className="right-header-chef">
//                 <UserAccountHead />
//                 {/* <button onClick={() => downloadExport()} className={loading ? 'downloadButtonApp active': 'downloadButtonApp'}><p>Télécharger l'application</p>{loading ? <ClipLoader loading={loading} color={"#fff"} size={15}/> : <Icon icon="ant-design:download-outlined" style={{width: 50, height: 50}}/>}</button>     */}
//                 <button className="logout_button" onClick={() => dispatch(onUserLogout())}>Déconnexion</button>
//             </div>
//         </div>
//     )
// };

// export default ChefSidebar;

import React from 'react';
import UserAccountHead from './UserAccountHead';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import { Icon } from '@iconify/react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const onUserLogout = () => (actionCreators.logout());

const DefaultHeader = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const toggleSidebar = () => {
        document.getElementsByClassName("dashboard-layout-main-container")[0].classList.toggle("small_sidebar");
        document.getElementsByClassName("sidebar")[0].classList.toggle("small_sidebar");
    };

    const isPhoneScreen = useMediaQuery({
        maxWidth: 860
    });

    return (
        <div className="dashboard-header">
            <div onClick={() => isPhoneScreen ? navigate("/") : toggleSidebar()} className='sidebarToggle-button'>
                <Icon icon={isPhoneScreen ? "ic:baseline-home" : "mdi:menu"} />
            </div>
            <div className="fullBar">
            </div>
            <UserAccountHead />
            <button className="logout_button" onClick={() => dispatch(onUserLogout())}>Déconnexion</button>
        </div>
    );
};

export default DefaultHeader;