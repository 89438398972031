import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllDaysOfWeek } from "../../utils/DateHelper";
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import { fr } from "date-fns/locale";
import customToast from "../../utils/ToastifyHelper";
import ReleveJour from "./ReleveJour";
import Modal from "../../components/Modal";
import ModifierChantierV2 from "./Modal/ModifierChantierV2";
import SupprimerModalForm from "../../components/forms/SupprimerModalForm";
import ModifierSalarieHeureV2 from "./Modal/ModifierSalarieHeureV2";
import CopierVersJourV2 from "./Modal/CopierVersJourV2";

const OneReleveHeureConsultationV2 = () => {

    const [listeDesChantiers, setListeDesChantiers] = useState([]);
    const [listeDesSalaries, setListeDesSalaries] = useState({});
    const [allDaysInWeek, setAllDaysInWeek] = useState([]);
    const [releveSaisiePar, setReleveSaisiePar] = useState(null);



    const [releveHeure, setReleveHeure] = useState({
        annee: null,
        numeroSemaine: null,
        statutSemaine: 0,
        jours: {}
    });

    let { idReleve } = useParams();


    const getListeDesChantiers = () => {
        axiosInstance.get('/chantiers/optionsChantiers?zone=true').then((result) => {
            setListeDesChantiers(result.data);
        }).catch((err) => {
            showError(err);
        });
    };

    const getListeDesSalaries = () => {
        axiosInstance.get('/utilisateurs').then((result) => {
            setListeDesSalaries(result.data);
        }).catch((err) => {
            showError(err);
        });
    };

    const getReleveHeure = () => {
        axiosInstance.get(`/releve-heure-v2/ReleveHeureSalarie/?idReleveHeure=${idReleve}`).then((result) => {
            setAllDaysInWeek(getAllDaysOfWeek(result.data.numeroSemaine, result.data.annee));
            setReleveHeure(result.data);
            axiosInstance.get(`/utilisateurs/${result.data.saisiePar}`).then((res) => {
                setReleveSaisiePar(res.data);
            }).catch((err) => {
                showError(err);
            });
        }).catch((err) => {
            showError(err);
        });
    };

    useEffect(() => {
        getListeDesChantiers();
        getReleveHeure();
        getListeDesSalaries();
    }, []);

    const verouillerSemaine = () => {
        axiosInstance.patch(`/releve-heure-v2/modifierStatutDeLaSemaine/${releveHeure._id}`, { statut: 1 })
            .then(() => {
                customToast.success("Verouillage de la semaine");
                setReleveHeure({
                    ...releveHeure,
                    statutSemaine: 1
                });
            }).catch((err) => {
                showError(err);
            });
    };

    const deverouillerSemaine = () => {
        axiosInstance.patch(`/releve-heure-v2/modifierStatutDeLaSemaine/${releveHeure._id}`, { statut: 0 })
            .then(() => {
                customToast.success("Déverouillage de la semaine");
                setReleveHeure({
                    ...releveHeure,
                    statutSemaine: 0
                });
            }).catch((err) => {
                showError(err);
            });
    };

    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const ajouterAbsence = (absence) => {
        setReleveHeure({
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(absence.date_deb_absence), "yyyy-MM-dd")]: releveHeure.jours.hasOwnProperty(format(new Date(absence.date_deb_absence), "yyyy-MM-dd")) ?
                    {
                        ...releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")],
                        absences: releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].hasOwnProperty("absences") ?
                            releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].absences.concat(absence)
                            : [absence]
                    }
                    :
                    {
                        absences: [absence],
                        travails: {}
                    }
            }
        });
    };

    const ajouterHeure = (travail) => {
        setReleveHeure({
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(travail.date_jour), "yyyy-MM-dd")]: releveHeure.jours.hasOwnProperty(format(new Date(travail.date_jour), "yyyy-MM-dd")) ?
                    {
                        ...releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")],
                        travails: releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].hasOwnProperty("travails") ? {
                            ...releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails,
                            [travail.chantier._id._id]: releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails.hasOwnProperty(travail.chantier._id._id) ?
                                releveHeure.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails[travail.chantier._id._id].concat(travail)
                                :
                                [travail]
                        }
                            : {
                                [travail.chantier._id._id]: [travail]
                            }
                    }
                    :
                    {
                        travails: {
                            [travail.chantier._id._id]: [travail]
                        },
                        absences: []
                    }
            }
        });
    };

    const modifierChantier = (editData) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(editData.date_jour), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")],
                    travails: {
                        ...releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails,
                        [editData.newChantier._id._id]: releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails.hasOwnProperty(editData.newChantier._id._id) ?
                            releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.newChantier._id._id].concat(
                                releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId].map((travailSalarie) => {
                                    return { ...travailSalarie, chantier: editData.newChantier };
                                })
                            )
                            :
                            releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId].map((travailSalarie) => {
                                return { ...travailSalarie, chantier: editData.newChantier };
                            })
                    }
                }
            }
        };

        delete updateReleveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId];

        setReleveHeure(updateReleveHeure);
    };

    const deleteHeure = (travailSalarie) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")],
                    travails: {
                        ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails,
                        [travailSalarie.chantier._id._id]: releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].filter((travail) => travail._id !== travailSalarie._id)
                    }
                }
            }
        };

        if (updateReleveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].length === 0) {
            delete updateReleveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id];
        }

        setReleveHeure(updateReleveHeure);
    };

    const supprimerAbsence = (absence) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(absence.date_deb_absence), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")],
                    absences: releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].absences.filter((absenceFilter) => absenceFilter._id !== absence._id)
                }
            }
        };

        setReleveHeure(updateReleveHeure);
    };

    const modifierHeure = (travailSalarie) => {
        setReleveHeure({
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")],
                    travails: {
                        ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails,
                        [travailSalarie.chantier._id._id]: releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].map((travail) => {
                            if (travail._id === travailSalarie._id) {
                                return travailSalarie;
                            }
                            return travail;
                        })
                    }
                }
            }
        });
    };

    const supprimerTouteHeures = (dateJour) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: { ...releveHeure.jours }
        };

        if (updateReleveHeure.jours.hasOwnProperty(dateJour) && updateReleveHeure.jours[dateJour].hasOwnProperty("travails")) {
            updateReleveHeure.jours[dateJour].travails = {};
        }

        setReleveHeure(updateReleveHeure);
    };

    const supprimerTouteHeuresChantier = (jourChantier) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [jourChantier.date_jour]: {
                    ...releveHeure.jours[jourChantier.date_jour],
                    travails: {
                        ...releveHeure.jours[jourChantier.date_jour].travails
                    }
                }
            }
        };

        if (updateReleveHeure.jours[jourChantier.date_jour].travails.hasOwnProperty(jourChantier.chantierId)) {
            delete updateReleveHeure.jours[jourChantier.date_jour].travails[jourChantier.chantierId];
        }

        setReleveHeure(updateReleveHeure);
    };

    const copierVers = (jourData) => {

        let travailsDupliquer = {};

        for (const travail of jourData.travailsDupliquer) {
            if (travailsDupliquer.hasOwnProperty(travail.chantier._id._id)) {
                travailsDupliquer[travail.chantier._id._id].push(travail);
            } else {
                travailsDupliquer[travail.chantier._id._id] = [travail];
            }
        }

        setReleveHeure({
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [jourData.date_jour_destination]: releveHeure.jours.hasOwnProperty(jourData.date_jour_destination) ?
                    {
                        ...releveHeure.jours[jourData.date_jour_destination],
                        travails: travailsDupliquer
                    }
                    :
                    {
                        travails: travailsDupliquer,
                        absences: []
                    }
            }
        });
    };

    const openModifierChantier = (jourChantier) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierChantierV2 jourChantier={jourChantier} modifierChantier={modifierChantier} saisiePar={releveSaisiePar} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openDeleteHeure = (travailSalarie) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerHoraireSalarieV2" deleteHeure={deleteHeure} saisiePar={releveSaisiePar} travailSalarie={travailSalarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierSalarieHeure = (travailSalarie) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierSalarieHeureV2 travailSalarie={travailSalarie} saisiePar={releveSaisiePar} modifierHeure={modifierHeure} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerTouteHeures = (jourDate) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresV2" saisiePar={releveSaisiePar} jourDate={jourDate} supprimerTouteHeures={supprimerTouteHeures} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerTouteHeuresChantier = (jourChantier) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresChantiersV2" saisiePar={releveSaisiePar} jourChantier={jourChantier} supprimerTouteHeuresChantier={supprimerTouteHeuresChantier} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerAbsence = (absence) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerAbsenceSalarieV2" absence={absence} saisiePar={releveSaisiePar} supprimerAbsence={supprimerAbsence} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openCopierVers = (jourData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<CopierVersJourV2 jourData={jourData} copierVers={copierVers} saisiePar={releveSaisiePar} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    return (
        <div className="section">
            {releveHeure.annee &&
                <>
                    <div className="releveConsultation-top mgB-s2">
                        <div>
                            <h2>Relevé du {format(allDaysInWeek[0], 'eeee dd', { locale: fr })} au {format(allDaysInWeek[allDaysInWeek.length - 1], 'dd MMMM yyyy', { locale: fr })}</h2>
                            {releveSaisiePar && <p>({releveSaisiePar.login_matricule}) - {releveSaisiePar.prenom} {releveSaisiePar.nom}</p>}
                        </div>
                        <div className="validerButtonContainer">
                            <button onClick={() => verouillerSemaine()} disabled={releveHeure.statutSemaine} className={!releveHeure.statutSemaine ? 'validerSemaine-button' : 'validerSemaine-button disabled-button'}><p>Verrouiller la semaine</p></button>
                            <button onClick={() => deverouillerSemaine()} disabled={!releveHeure.statutSemaine} className={releveHeure.statutSemaine ? 'devaliderSemaine-button' : 'devaliderSemaine-button disabled-button'}><p>Déverrouiller la semaine</p></button>
                        </div>
                    </div>
                    <div className="releve-heure-table-container admin-releve-table">
                        <table className="releve-heure-chef-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Nom du chantier</th>
                                    <th>Zone</th>
                                    <th>Nombre d'heures saisies</th>
                                    <th>Etat</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allDaysInWeek.map((jour) =>
                                    <ReleveJour ajouterHeure={ajouterHeure} ajouterAbsence={ajouterAbsence} saisiePar={releveSaisiePar} openSupprimerAbsence={openSupprimerAbsence} openCopierVers={openCopierVers} openSupprimerTouteHeuresChantier={openSupprimerTouteHeuresChantier} openSupprimerTouteHeures={openSupprimerTouteHeures} openModifierSalarieHeure={openModifierSalarieHeure} openDeleteHeure={openDeleteHeure} key={format(jour, 'yyyy-MM-dd')} listeDesSalaries={listeDesSalaries} listeDesChantiers={listeDesChantiers} statutSemaine={releveHeure.statutSemaine} dateJour={jour} jour={releveHeure.jours.hasOwnProperty(format(jour, 'yyyy-MM-dd')) ? releveHeure.jours[format(jour, 'yyyy-MM-dd')] : null} allDays={allDaysInWeek} openModifierChantier={openModifierChantier} />
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            }
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default OneReleveHeureConsultationV2;
