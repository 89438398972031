import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const fichiersListState = {
    fichiersChantier: []
};

const reducer = (state = fichiersListState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_FICHIERS_CHANTIER:
            return updateObject(state, { fichiersChantier: action.fichiersChantier });
        case actionTypes.ADD_FICHIER_CHANTIER:
            return updateObject(state, { fichiersChantier: state.fichiersChantier.concat(action.fichierChantier) });
        case actionTypes.REMOVE_FICHIER_CHANTIER:
            return updateObject(state, { fichiersChantier: state.fichiersChantier.filter(fichierChantier => fichierChantier._id._id !== action.fichierChantierId) });
        case actionTypes.UNLOAD_FICHIERS_CHANTIER:
            return { fichiersChantier: [] };
        case actionTypes.UPDATE_FICHIER_CHANTIER:
            return updateObject(state, { fichiersChantier: state.fichiersChantier.map(fichierChantier => fichierChantier._id._id === action.fichierChantier._id._id ? action.fichierChantier : fichierChantier) });
        default:
            return state;
    }
};

export default reducer;
