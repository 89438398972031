import React, { useState, useEffect } from 'react';
import { formatBytes, iconExtension } from '../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import axiosInstance from '../utils/axiosInstance';
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch } from 'react-redux';
import * as actionCreators from '../store/actions/exportAction';
import customToast from '../utils/ToastifyHelper';
import { showError } from '../utils/ErrorHelper';

const onUploadFinished = () => (actionCreators.addUserUpload());
const addDocument = (document) => (actionCreators.addDocument(document));
const onDeleteFileSalarie = (documentId) => (actionCreators.deleteDocumentSalarie(documentId));
const addDocumentEpc = (document) => (actionCreators.addDocumentEpc(document));
const modifyDocumentEpc = (document) => (actionCreators.modifyDocumentEpc(document));

const FileUploadItem = (props) => {
    const dispatch = useDispatch();
    const controller = new AbortController();
    const categoriesTypesDocument = useSelector(state => state.categorieDocument);
    const [typeFichier, setTypeFichier] = useState("");
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [description, setDescription] = useState("");
    const [progress, setProgress] = useState(0);

    const changeCategorie = (event) => {
        setTypeFichier(event.target.value);
    };

    useEffect(() => {
        if (props.uploadFile === "cancel") {
            controller.abort();
        }
        else if (props.uploadFile) {
            const config = {
                onUploadProgress: progressEvent => {
                    let calculateProgress = progress;
                    calculateProgress = (progressEvent.loaded / progressEvent.total) * 100;
                    setProgress(Math.floor(calculateProgress));
                }
            };
            let postData = new FormData();
            postData.append("document", props.file);
            postData.append("titre", props.file.path.split('.').slice(0, -1).join('.'));
            postData.append("description", description);
            postData.append("_refId", props.document.refId);
            postData.append("documentSaveRoute", props.documentSaveRoute);
            if (typeFichier !== "") {
                postData.append("typeDocument", typeFichier);
            }
            if (props.documentSaveRoute === "documentsSalarie") {
                postData.append("idSalarie", props.idSalarie);
            }
            if (props.documentSaveRoute === "remplacerDocumentsSalarie") {
                axiosInstance.patch(`/uploads/documentUtilisateurs/${props.idSalarie}&${props.document}`, postData, { ...config, timeout: 0, signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
                    setCloseButtonDisabled(true);
                    dispatch(onUploadFinished());
                    dispatch(onDeleteFileSalarie(props.document));
                    dispatch(addDocument(result.data));
                }).catch((err) => {
                    setCloseButtonDisabled(false);
                    showError(err);
                });
            } else if (props.documentSaveRoute === "remplacerDocumentsEPC") {
                axiosInstance.patch(`/uploads/documentsEPC/${props.document.IDdocument}`, postData, { ...config, timeout: 0, signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
                    setCloseButtonDisabled(true);
                    dispatch(onUploadFinished());
                    dispatch(modifyDocumentEpc(result.data));
                    dispatch(addDocument(result.data));
                }).catch((err) => {
                    setCloseButtonDisabled(false);
                    showError(err);
                });
            }
            else {
                axiosInstance.post("/uploads", postData, { ...config, timeout: 0, signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
                    setCloseButtonDisabled(true);
                    dispatch(onUploadFinished());
                    dispatch(addDocument(result.data));

                    if (!!result.data._refID) { dispatch(addDocumentEpc({ '_idEquipement': result.data._refID, 'idDocument': result.data })); }
                }).catch((err) => {
                    setCloseButtonDisabled(false);
                    showError(err);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.uploadFile]);


    return (
        <li className="fileItem">
            {iconExtension(props.file.path)}
            <p>{props.file.path} - {formatBytes(props.file.size)}</p>
            {props.epc === undefined &&
                <div>
                    <select disabled={props.uploadFile} className='new-default-input' onChange={changeCategorie} value={typeFichier}>
                        <option disabled={true} value="" style={{ textAlign: "center" }}>
                            -- Choisisez le type du fichier --
                        </option>
                        {categoriesTypesDocument.map((categorie) => {
                            return (
                                categorie.typeDocuments.map((type) => {
                                    return <option style={{ textAlign: "center" }} key={categorie._id + type._id} value={type._id}>{type.libelleType}</option>;
                                })
                            );
                        })}
                    </select>
                    <input disabled={props.uploadFile} value={description} onChange={(e) => setDescription(e.target.value)} className="ajouter-form-input default-input" type="text" name="descriptionFichier" placeholder='Description du fichier' />

                </div>
            }
            <ProgressBar isLabelVisible={props.uploadFile} bgColor="#2fc451" borderRadius='.5rem' className='progressBar' completed={progress} />
            <Icon disabled={props.uploadFile && closeButtonDisabled} className='deleteFileSvg' icon="clarity:remove-solid" onClick={() => { props.removeFiles(props.index); if (props.uploadFile) { controller.abort(); } }} />
        </li>
    );
};

export default FileUploadItem;