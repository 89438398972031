import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { showError } from "../../utils/ErrorHelper";
import axiosInstance from "../../utils/axiosInstance";
import LoadingBar from 'react-top-loading-bar';
import LiReleveHeureConsultationV2 from "./LiReleveHeureConsultationV2";
import customToast from "../../utils/ToastifyHelper";
import Modal from "../../components/Modal";
import { format } from "date-fns";
import ModifierChantierV2 from "./Modal/ModifierChantierV2";
import SupprimerModalForm from "../../components/forms/SupprimerModalForm";
import ModifierSalarieHeureV2 from "./Modal/ModifierSalarieHeureV2";
import CopierVersJourV2 from "./Modal/CopierVersJourV2";

const RelevesHeuresConsultationV2 = () => {

    const progressBarRef = useRef(null);

    const [pageOptions, setPageOptions] = useState({
        page: 1,
        total: 0,
        totalPages: 1
    });

    let { idUtilisateur } = useParams();

    const [releveSaisiePar, setReleveSaisiePar] = useState(null);
    const [relevesHeures, setRelevesHeures] = useState([]);
    const [listeDesChantiers, setListeDesChantiers] = useState([]);
    const [listeDesSalaries, setListeDesSalaries] = useState({});

    const getListeDesChantiers = () => {
        axiosInstance.get('/chantiers/optionsChantiers?zone=true').then((result) => {
            setListeDesChantiers(result.data);
        }).catch((err) => {
            showError(err);
        });
    };

    const getListeDesSalaries = () => {
        axiosInstance.get('/utilisateurs').then((result) => {
            setListeDesSalaries(result.data);
        }).catch((err) => {
            showError(err);
        });
    };

    const fetchData = (page) => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/releve-heure-v2/tousLesReleves/${idUtilisateur}?page=${page + 1}`).then((result) => {
            progressBarRef.current.complete();
            setRelevesHeures(relevesHeures.concat(result.data.releveHeures));
            setPageOptions({ ...pageOptions, page: result.data.currentPage, totalPages: result.data.totalPages, total: result.data.total });
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    useEffect(() => {
        axiosInstance.get(`/utilisateurs/${idUtilisateur}`).then((res) => {
            setReleveSaisiePar(res.data);
        }).catch((err) => {
            showError(err);
        });
        fetchData(0);
        getListeDesChantiers();
        getListeDesSalaries();
    }, []);


    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");


    const verouillerSemaine = (releveHeure) => {
        axiosInstance.patch(`/releve-heure-v2/modifierStatutDeLaSemaine/${releveHeure._id}`, { statut: 1 })
            .then(() => {
                customToast.success("Verouillage de la semaine");
                setRelevesHeures(relevesHeures.map((releve) => {
                    if (releve._id === releveHeure._id) {
                        return {
                            ...releve,
                            statutSemaine: 1
                        };
                    }
                    return releve;
                }));
            }).catch((err) => {
                showError(err);
            });
    };

    const deverouillerSemaine = (releveHeure) => {
        axiosInstance.patch(`/releve-heure-v2/modifierStatutDeLaSemaine/${releveHeure._id}`, { statut: 0 })
            .then(() => {
                customToast.success("Verouillage de la semaine");
                setRelevesHeures(relevesHeures.map((releve) => {
                    if (releve._id === releveHeure._id) {
                        return {
                            ...releve,
                            statutSemaine: 0
                        };
                    }
                    return releve;
                }));
            }).catch((err) => {
                showError(err);
            });
    };

    const ajouterHeure = (releveHeure, travail) => {
        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return {
                    ...releve,
                    jours: {
                        ...releve.jours,
                        [format(new Date(travail.date_jour), "yyyy-MM-dd")]: releve.jours.hasOwnProperty(format(new Date(travail.date_jour), "yyyy-MM-dd")) ?
                            {
                                ...releve.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")],
                                travails: releve.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].hasOwnProperty("travails") ? {
                                    ...releve.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails,
                                    [travail.chantier._id._id]: releve.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails.hasOwnProperty(travail.chantier._id._id) ?
                                        releve.jours[format(new Date(travail.date_jour), "yyyy-MM-dd")].travails[travail.chantier._id._id].concat(travail)
                                        :
                                        [travail]
                                }
                                    : {
                                        [travail.chantier._id._id]: [travail]
                                    }
                            }
                            :
                            {
                                travails: {
                                    [travail.chantier._id._id]: [travail]
                                },
                                absences: []
                            }
                    }
                };
            }
            return releve;
        }));
    };

    const ajouterAbsence = (releveHeure, absence) => {
        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return {
                    ...releve,
                    jours: {
                        ...releve.jours,
                        [format(new Date(absence.date_deb_absence), "yyyy-MM-dd")]: releve.jours.hasOwnProperty(format(new Date(absence.date_deb_absence), "yyyy-MM-dd")) ?
                            {
                                ...releve.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")],
                                absences: releve.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].hasOwnProperty("absences") ?
                                    releve.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].absences.concat(absence)
                                    : [absence]
                            }
                            :
                            {
                                absences: [absence],
                                travails: {}
                            }
                    }
                };
            }
            return releve;
        }));
    };

    const modifierChantier = (releveHeure, editData) => {

        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(editData.date_jour), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")],
                    travails: {
                        ...releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails,
                        [editData.newChantier._id._id]: releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails.hasOwnProperty(editData.newChantier._id._id) ?
                            releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.newChantier._id._id].concat(
                                releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId].map((travailSalarie) => {
                                    return { ...travailSalarie, chantier: editData.newChantier };
                                })
                            )
                            :
                            releveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId].map((travailSalarie) => {
                                return { ...travailSalarie, chantier: editData.newChantier };
                            })
                    }
                }
            }
        };

        delete updateReleveHeure.jours[format(new Date(editData.date_jour), "yyyy-MM-dd")].travails[editData.oldChantierId];

        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return updateReleveHeure;
            }
            return releve;
        }));
    };

    const deleteHeure = (releveHeure, travailSalarie) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")],
                    travails: {
                        ...releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails,
                        [travailSalarie.chantier._id._id]: releveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].filter((travail) => travail._id !== travailSalarie._id)
                    }
                }
            }
        };

        if (updateReleveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].length === 0) {
            delete updateReleveHeure.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id];
        }

        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return updateReleveHeure;
            }
            return releve;
        }));
    };

    const supprimerAbsence = (releveHeure, absence) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [format(new Date(absence.date_deb_absence), "yyyy-MM-dd")]: {
                    ...releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")],
                    absences: releveHeure.jours[format(new Date(absence.date_deb_absence), "yyyy-MM-dd")].absences.filter((absenceFilter) => absenceFilter._id !== absence._id)
                }
            }
        };

        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return updateReleveHeure;
            }
            return releve;
        }));
    };

    const modifierHeure = (releveHeure, travailSalarie) => {
        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return {
                    ...releve,
                    jours: {
                        ...releve.jours,
                        [format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")]: {
                            ...releve.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")],
                            travails: {
                                ...releve.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails,
                                [travailSalarie.chantier._id._id]: releve.jours[format(new Date(travailSalarie.date_jour), "yyyy-MM-dd")].travails[travailSalarie.chantier._id._id].map((travail) => {
                                    if (travail._id === travailSalarie._id) {
                                        return travailSalarie;
                                    }
                                    return travail;
                                })
                            }
                        }
                    }
                };
            }
            return releve;
        }));
    };

    const supprimerTouteHeures = (releveHeure, dateJour) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: { ...releveHeure.jours }
        };

        if (updateReleveHeure.jours.hasOwnProperty(dateJour) && updateReleveHeure.jours[dateJour].hasOwnProperty("travails")) {
            updateReleveHeure.jours[dateJour].travails = {};
        }

        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return updateReleveHeure;
            }
            return releve;
        }));
    };

    const supprimerTouteHeuresChantier = (releveHeure, jourChantier) => {
        let updateReleveHeure = {
            ...releveHeure,
            jours: {
                ...releveHeure.jours,
                [jourChantier.date_jour]: {
                    ...releveHeure.jours[jourChantier.date_jour],
                    travails: {
                        ...releveHeure.jours[jourChantier.date_jour].travails
                    }
                }
            }
        };

        if (updateReleveHeure.jours[jourChantier.date_jour].travails.hasOwnProperty(jourChantier.chantierId)) {
            delete updateReleveHeure.jours[jourChantier.date_jour].travails[jourChantier.chantierId];
        }

        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return updateReleveHeure;
            }
            return releve;
        }));
    };

    const copierVers = (releveHeure, jourData) => {

        let travailsDupliquer = {};

        for (const travail of jourData.travailsDupliquer) {
            if (travailsDupliquer.hasOwnProperty(travail.chantier._id._id)) {
                travailsDupliquer[travail.chantier._id._id].push(travail);
            } else {
                travailsDupliquer[travail.chantier._id._id] = [travail];
            }
        }

        setRelevesHeures(relevesHeures.map((releve) => {
            if (releve._id === releveHeure._id) {
                return {
                    ...releve,
                    jours: {
                        ...releve.jours,
                        [jourData.date_jour_destination]: releve.jours.hasOwnProperty(jourData.date_jour_destination) ?
                            {
                                ...releve.jours[jourData.date_jour_destination],
                                travails: travailsDupliquer
                            }
                            :
                            {
                                travails: travailsDupliquer,
                                absences: []
                            }
                    }
                };
            }
            return releve;
        }));
    };

    const openModifierChantier = (releveHeure, jourChantier) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierChantierV2 jourChantier={jourChantier} modifierChantier={(data) => modifierChantier(releveHeure, data)} saisiePar={releveSaisiePar} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openDeleteHeure = (releveHeure, travailSalarie) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerHoraireSalarieV2" deleteHeure={(data) => deleteHeure(releveHeure, data)} saisiePar={releveSaisiePar} travailSalarie={travailSalarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierSalarieHeure = (releveHeure, travailSalarie) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierSalarieHeureV2 travailSalarie={travailSalarie} saisiePar={releveSaisiePar} modifierHeure={(data) => modifierHeure(releveHeure, data)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerTouteHeures = (releveHeure, jourDate) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresV2" saisiePar={releveSaisiePar} jourDate={jourDate} supprimerTouteHeures={(data) => supprimerTouteHeures(releveHeure, data)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerTouteHeuresChantier = (releveHeure, jourChantier) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresChantiersV2" saisiePar={releveSaisiePar} jourChantier={jourChantier} supprimerTouteHeuresChantier={(data) => supprimerTouteHeuresChantier(releveHeure, data)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerAbsence = (releveHeure, absence) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerAbsenceSalarieV2" absence={absence} saisiePar={releveSaisiePar} supprimerAbsence={(data) => supprimerAbsence(releveHeure, data)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openCopierVers = (releveHeure, jourData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<CopierVersJourV2 jourData={jourData} copierVers={(data) => copierVers(releveHeure, data)} saisiePar={releveSaisiePar} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div className="container-basic mgB-m2">
                    <h1 className="h1-blue-dark">Tous les relevés d'heures saisis par {releveSaisiePar !== null && releveSaisiePar.nom} {releveSaisiePar !== null && releveSaisiePar.prenom}</h1>
                </div>
                <ul className="releveHeureList">
                    {
                        relevesHeures.map((releveHeure) => {
                            return (<li className='container-basic mgB-m2' key={releveHeure._id}>
                                <LiReleveHeureConsultationV2 releveHeure={releveHeure} openCopierVers={(data) => openCopierVers(releveHeure, data)} openSupprimerAbsence={(data) => openSupprimerAbsence(releveHeure, data)} openSupprimerTouteHeuresChantier={(data) => openSupprimerTouteHeuresChantier(releveHeure, data)} openSupprimerTouteHeures={(data) => openSupprimerTouteHeures(releveHeure, data)} openModifierChantier={(data) => openModifierChantier(releveHeure, data)} openDeleteHeure={(data) => openDeleteHeure(releveHeure, data)} openModifierSalarieHeure={(data) => openModifierSalarieHeure(releveHeure, data)} ajouterAbsence={(absence) => ajouterAbsence(releveHeure, absence)} ajouterHeure={(travail) => ajouterHeure(releveHeure, travail)} releveSaisiePar={releveSaisiePar} listeDesChantiers={listeDesChantiers} listeDesSalaries={listeDesSalaries} verouillerSemaine={() => verouillerSemaine(releveHeure)} deverouillerSemaine={() => deverouillerSemaine(releveHeure)} />
                            </li>);
                        })
                    }
                </ul>
                {pageOptions.page + 1 <= pageOptions.totalPages && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button className="btn btn-primary" onClick={() => fetchData(pageOptions.page)}>Afficher plus</button></div>}
            </div>
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </>
    );
};

export default RelevesHeuresConsultationV2;
