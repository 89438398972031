import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const UploadFilesListState = [];

const reducer = (state = UploadFilesListState, action) => {
    switch (action.type) {
        case actionTypes.SET_UPLOADS_FILES:
            return action.uploadedFiles;
        case actionTypes.REMOVE_FILE_FROM_UPlOAD:
            return state.filter(uploadedFile => uploadedFile._id !== action.fichierId);
        case actionTypes.UPDATE_PROGRESS:
            return state.map(uploadedFile => uploadedFile._id === action.fichierId ? { ...uploadedFile, progress: action.progress } : uploadedFile);
        case actionTypes.SET_NOM_FICHIER:
            return state.map(uploadedFile => uploadedFile._id === action.fichierId ? { ...uploadedFile, nomFichier: action.nomFichier } : uploadedFile);
        case actionTypes.SET_VISIBILITY_FICHIER:
            return state.map(uploadedFile => uploadedFile._id === action.fichierId ? { ...uploadedFile, visibility: action.visibility } : uploadedFile);
        default:
            return state;
    }
};

export default reducer;