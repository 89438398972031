import React from 'react';
import UserAccountHead from './UserAccountHead';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import { Icon } from '@iconify/react';

const onUserLogout = () => (actionCreators.logout());


const AdminHeader = () => {
    const dispatch = useDispatch();
    const toggleSidebar = () => {
        document.getElementsByClassName("dashboard-layout-main-container")[0].classList.toggle("small_sidebar");
        document.getElementsByClassName("sidebar")[0].classList.toggle("small_sidebar");
    };

    return (
        <div className="dashboard-header">
            <div onClick={() => toggleSidebar()} className='sidebarToggle-button'>
                <Icon icon="mdi:menu" />
            </div>
            <div className="fullBar">
            </div>
            <UserAccountHead />
            <button className="logout_button" onClick={() => dispatch(onUserLogout())}>Déconnexion</button>
        </div>
    );
};

export default AdminHeader;
