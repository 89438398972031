import React from 'react';
import justeauLogo from '../assets/img/justeaulogo.png';

const PrivacyPolicy = () => {
    return (
        <>
            <div className="dashboard-header-chef">
                <div className="left-header-chef">
                    <img className="chef-header-logo" src={justeauLogo} alt="justeau logo" />
                </div>
            </div>
            <div className='privacyPolicyContainer'>
                <h1>Règles de confidentialité</h1>
                <div className="privacyPolicySection">
                    <h2>Usage de l'application</h2>
                    <p>
                        Justeau.app est une application réservée aux salariés de l'entreprise Justeau. Elle est conçue pour fonctionner sur les mobiles, tablettes, Android et IOS.
                        L'éditeur de l'application est Terre de Pixels, agence de communication située à 6 Rue de l'Espoir, 49700 Doué-en-Anjou.
                    </p>
                </div>
                <div className="privacyPolicySection">
                    <h2>Données personnelles</h2>
                    <p>
                        L'application a pour but de faciliter le travail d'administration, les chefs de chantier vont saisir leures heures et celles des salariés sur différents chantiers au cours des semaines.
                        Les données collectées sont uniquement traitées par le service d'administration de l'entreprise Justeau. Chaque salarié possède un accès direct à une partie de ces données personnelles via un historique de ses heures qu'il peut consulter en s'identifiant sur l'application.
                    </p>
                </div>
                <div className="privacyPolicySection">
                    <h2>Permissions demandées par l'application</h2>
                    <p>
                        Certains utilisateurs ont accès à un onglet supplémentaire qui permet la gestion de la partie amiante. Dans cet onglet, les chefs de chantier peuvent télécharger des documents concernant l'amiante. L'application demande l'accès aux photos et médias présents sur l'appareil uniquement pour consulter et télécharger les documents présent sur le serveur.
                        Ces documents sont du type csv, pdf, image et accompagnent les chefs de chantier dans une bonne pratique du désamiantage.
                    </p>
                </div>
                <div className="privacyPolicySection">
                    <h2>Données personnelles non collectées automatiquement</h2>
                    <p>
                        Les données collectées par l'application proviennent uniquement de saisie humaine.
                    </p>
                </div>
                <div className="privacyPolicySection">
                    <h2>Nous contacter</h2>
                    <p>
                        Pour toutes questions concernant la confidentialité lors de l'utilisation de l'Application, ou des questions sur nos pratiques, veuillez nous contacter par email à <a href='mailto:benoit@terredepixels.fr'>benoit@terredepixels.fr</a>.
                    </p>
                </div>
            </div>
            <div className='privacyPolicyContainer'>
                <h1>Privacy policy</h1>
                <div className="privacyPolicySection">
                    <h2>Application usage</h2>
                    <p>
                        Justeau.app is an application reserved for Justeau's company employees. It is designed to work on mobiles, tablets, Android and IOS.
                        Justeau.app is developed by Terre de Pixels, communication agency located at 6 Rue de l'Espoir, 49700 Doué-en-Anjou, France.
                    </p>
                </div>
                <div className="privacyPolicySection">
                    <h2>Personal data</h2>
                    <p>
                        The application purpose is to facilitate administrative work, site managers will have to enter their hours and their salaries who worked on the site during the week.
                        Collected data is only processed by Justeau's administration department. Each employees has direct access to a small part of their personal data via a history of their hours that they can consult by logged in the application.
                    </p>
                </div>
                <div className="privacyPolicySection">
                    <h2>permissions required by the application</h2>
                    <p>
                        Some users have acces to an additional tab which allows management of the asbestos part. In this tab, site managers can download documents concerning asbestos. The application requests access to photos and media present on the device only to save download and view files on server.
                        These documents are csv, pdf, image type and helps site managers in good asbestos removal practice.
                    </p>
                </div>
                <div className="privacyPolicySection">
                    <h2>Personnal data not collected automatically</h2>
                    <p>
                        Collected datas by the application comes only from human input.
                    </p>
                </div>
                <div className="privacyPolicySection">
                    <h2>Contact us</h2>
                    <p>
                        For any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at <a href='mailto:benoit@terredepixels.fr'>benoit@terredepixels.fr</a>.
                    </p>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;