import ProgressBar from '@ramonak/react-progress-bar';
import React, { useState, useEffect } from 'react';
import { formatBytes, iconExtension } from '../../utils/GeneralHelper';
import customToast from '../../utils/ToastifyHelper';
import { Icon } from '@iconify/react';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';


const UploadDocumentChantierDoe = (props) => {

    const controller = new AbortController();
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (props.uploadFile === "cancel") {
            controller.abort();
        }
        else if (props.uploadFile) {
            setCloseButtonDisabled(true);
            const config = {
                onUploadProgress: progressEvent => {
                    let calculateProgress = progress;
                    calculateProgress = (progressEvent.loaded / progressEvent.total) * 100;
                    setProgress(Math.floor(calculateProgress));
                }
            };
            let postData = new FormData();
            postData.append("document", props.file);
            postData.append("idChantier", props.idChantier);
            postData.append("documentSaveRoute", props.documentSaveRoute);
            if (props.documentSaveRoute === "documentChantier") {
                postData.append("titre", props.file.name);
                postData.append("idTypeDocument", props.documentTypeId);
            }
            else if (props.documentSaveRoute === "ajouterDocumentChantierSansType") {
                postData.append("titre", props.title);
            }
            else {
                postData.append("titre", props.title);
                postData.append("documentId", props.documentId);

            }
            axiosInstance.post("/uploads", postData, { ...config, signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {
                    props.updateDocuments(result.data);
                    // console.log(result.data);
                }).catch((err) => {
                    setCloseButtonDisabled(false);
                    showError(err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.uploadFile]);

    useEffect(() => {
        if (progress === 100) {
            setTimeout(() => {
                customToast.success("Document enregistrer avec succès");
                props.close();
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    return (
        <li className='fileItem'>
            {iconExtension(props.file.path)}
            <p>{props.file.path} - {formatBytes(props.file.size)}</p>
            <ProgressBar isLabelVisible={props.uploadFile} bgColor="#2fc451" borderRadius='.5rem' className='progressBar' completed={progress} />
            <Icon disabled={props.uploadFile && closeButtonDisabled} className='deleteFileSvg' icon="clarity:remove-solid" onClick={() => { props.removeFile(); if (props.uploadFile) { controller.abort(); } }} />
        </li>
    );
};

export default UploadDocumentChantierDoe;