import React, { useState } from "react";
import Select from 'react-select';
import axiosInstance from "../../../utils/axiosInstance";
import { showError } from "../../../utils/ErrorHelper";
import customToast from "../../../utils/ToastifyHelper";

const ModifierChantierV2 = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [chantierSelected, setChantierSelected] = useState(null);

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onModifyChantier = () => {
        setButtonDisabled(true);
        if (chantierSelected === null || props.jourChantier.oldChantierId === chantierSelected.value) {
            customToast.error("Vous n'avez pas modifié le chantier");
            setButtonDisabled(false);
        }
        else {
            let patchData = {
                oldChantierId: props.jourChantier.oldChantierId,
                newChantierId: chantierSelected.value,
                date_jour: props.jourChantier.date_jour
            };

            if (props.saisiePar) {
                patchData.saisiePar = props.saisiePar._id;
                axiosInstance.patch('/releve-heure-v2/modifierChantier', patchData).then((result) => {
                    setButtonDisabled(false);
                    customToast.success("Le chantier a été modifié avec succès");
                    props.modifierChantier({ date_jour: props.jourChantier.date_jour, oldChantierId: props.jourChantier.oldChantierId, newChantier: result.data });
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
            }
            else {
                axiosInstance.patch('/releve-heure-v2/modifierChantier/me', patchData).then((result) => {
                    setButtonDisabled(false);
                    customToast.success("Le chantier a été modifié avec succès");
                    props.modifierChantier({ date_jour: props.jourChantier.date_jour, oldChantierId: props.jourChantier.oldChantierId, newChantier: result.data });
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
            }

        }
    };

    return (
        <div className="modal-content modifier-form" style={{ display: "flex", flexDirection: "column" }}>
            <h3 className="modal-title">Modifier le chantier</h3>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <label className='modifier-form-label default-label' htmlFor="chantier">Sélectionner un chantier</label>
                <Select
                    options={props.jourChantier.allChantierOptions}
                    name="selectChantier"
                    className="formSelect-search-container mgB-s2"
                    classNamePrefix="formSelect-search"
                    onChange={(opt) => setChantierSelected(opt)}
                    placeholder="Sélectionner le chantier"
                    isSearchable={true}
                    defaultValue={props.jourChantier.allChantierOptions.find(chantierOpt => chantierOpt.value === props.jourChantier.oldChantierId)}
                />
                <div className="button-container" style={{ flex: 1, alignItems: "flex-end" }} >
                    <button disabled={isButtonDisabled} className='btn btn-secondary fw medium-btn mgR-m1' onClick={() => close()}>Annuler</button>
                    <button disabled={isButtonDisabled} className="btn btn-primary fw medium-btn" onClick={() => onModifyChantier()}>Valider</button>
                </div>
            </div>
        </div>
    );
};

export default ModifierChantierV2;
