import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import LoadingBar from 'react-top-loading-bar';
import ModalImage, { Lightbox } from 'react-modal-image';
import { format } from 'date-fns';
import { date } from 'yup';
import { capitalizeFirstLetter, getLastModified } from '../../utils/GeneralHelper';
import { useSelector } from 'react-redux';

const VoirActualite = (props) => {
    let { actualiteId } = useParams();
    const navigate = useNavigate();
    const [actualite, setActualite] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const progressBarRef = useRef(null);
    const [openImageFullscreen, setOpenImageFullscreen] = useState(false);
    const user = useSelector(
        state => state.user.user
    );

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/actualites/${actualiteId}`).then((result) => {
            progressBarRef.current.complete();
            console.log(result.data);
            setActualite(result.data);
            if (result.data.pictures.hasOwnProperty("articlePicture") && result.data.pictures.articlePicture !== null) {
                axiosInstance.get(`uploads/documents/${result.data.pictures.articlePicture._id}`, {
                    responseType: 'blob',
                }).then((res) => {
                    let fileURL = URL.createObjectURL(res.data);
                    setImagePreview(fileURL);
                }).catch((error) => {
                    showError(error);
                });
            }
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });

        return () => {
            URL.revokeObjectURL(imagePreview);
        };
    }, []);

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            {actualite !== null &&
                <div className='section'>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                        <h1>{actualite.titre}</h1>
                        {user.statut === "Administration" && <button onClick={() => navigate(`/justeau-actus/modifier-actualite/${actualite._id}`)} className='btn btn-primary medium-btn'>Modifier</button>}
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignContent: "center" }}>
                        <p style={{ fontStyle: "italic" }}>Publié il y a {getLastModified(new Date(actualite.created))} par {capitalizeFirstLetter(actualite.author.nom)} {capitalizeFirstLetter(actualite.author.prenom)}</p>
                        {actualite.hasOwnProperty("lastEditor") && actualite.lastEditor !== null &&
                            <p style={{ fontStyle: "italic" }}>dernière modification il y a {getLastModified(new Date(actualite.created))} par {capitalizeFirstLetter(actualite.lastEditor.nom)} {capitalizeFirstLetter(actualite.lastEditor.prenom)}</p>
                        }
                    </div>
                    <div className='mgT-m1' style={{ display: "flex", justifyContent: "center", backgroundColor: "white", borderRadius: "5px" }}>
                        <div style={{ maxHeight: "630px", maxWidth: "1200px", overflow: "hidden" }} className="articlePicture">
                            {imagePreview !== null &&
                                <img
                                    onClick={() => { setOpenImageFullscreen(true); }}
                                    src={imagePreview}
                                    className='voirArticleImage'
                                >
                                </img>
                            }
                        </div>
                    </div>
                    <div className='articleContent' dangerouslySetInnerHTML={{ __html: actualite.articleContent }} />
                </div>
            }
            {openImageFullscreen &&
                <Lightbox
                    large={imagePreview}
                    onClose={() => setOpenImageFullscreen(false)}
                    hideZoom={false}
                />
            }
        </>
    );
};

export default VoirActualite;