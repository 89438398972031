import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTable, usePagination, useSortBy } from 'react-table';
import SortIcon from '../icon/SortIcon';
import * as actionCreators from '../../store/actions/exportAction';
import { useSelector } from 'react-redux';

const saveSortFilter = (pageName, optionName, optionValue) => actionCreators.setOptionsFilterSort(pageName, optionName, optionValue);

const TableRender = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dispatch = useDispatch();
    const columns = useMemo(() => props.columns, []);
    const optionsFilterSort = useSelector((state) => state.optionsFilterSort);

    const onTableIndexChange = (value) => {
        if (props.pageName !== null) {
            dispatch(saveSortFilter(props.pageName, "pageIndex", value));
        }
    };
    const onTableSizeChange = (value) => {
        if (props.pageName !== null) {
            dispatch(saveSortFilter(props.pageName, "pageSize", value));
        }
        // setTableSize(value)
    };
    const onSortedColumnChange = (value) => {
        // console.log(value)
        if (props.pageName !== null) {
            dispatch(saveSortFilter(props.pageName, "sortedColumn", value));
        }
    };

    const tableInstance = useTable({
        columns,
        data: props.tableData,
        initialState: {
            pageIndex: optionsFilterSort.hasOwnProperty(props.pageName) && optionsFilterSort[props.pageName].hasOwnProperty("pageIndex") ? optionsFilterSort[props.pageName].pageIndex : 0,
            pageSize: optionsFilterSort.hasOwnProperty(props.pageName) && optionsFilterSort[props.pageName].hasOwnProperty("pageSize") ? optionsFilterSort[props.pageName].pageSize : 10,
            sortBy: optionsFilterSort.hasOwnProperty(props.pageName) && optionsFilterSort[props.pageName].hasOwnProperty("sortedColumn") ? optionsFilterSort[props.pageName].sortedColumn : props.sortBy,
            disableMultiSort: false
        }
    }, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance;

    // useEffect(() => {
    //     let columnSorted = tableInstance.allColumns.find(column => column.isSorted)
    //     console.log('object');
    //     if (columnSorted) {
    //         if (props.hasOwnProperty("onSortedColumnChange")) {
    //             props.onSortedColumnChange(columnSorted.id)
    //         }
    //     }
    // }, [tableInstance])


    return (
        <div className={`main-content${props.noBorder ? " noBorder" : ""}`}>
            {props.tableTitle && <>{props.tableTitle}</>}
            <table className="default-table" {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr key="header" {...headerGroup.getHeaderGroupProps}>
                                {
                                    headerGroup.headers.map((column) => {
                                        return (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                onClick={(e) => {
                                                    if (column.canSort) {
                                                        column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                                                        if (props.pageName !== null) {
                                                            if (column.isSorted) {
                                                                if (column.isSortedDesc) {
                                                                    // unsort
                                                                    dispatch(actionCreators.removeTableFilter(props.pageName, column.id));
                                                                }
                                                                else {
                                                                    // sort desc
                                                                    dispatch(actionCreators.addTableFilter(props.pageName, column.id, true));
                                                                }
                                                            }
                                                            else {
                                                                dispatch(actionCreators.addTableFilter(props.pageName, column.id, false));
                                                                // sort asc
                                                            }
                                                        }

                                                    }

                                                    // onSortedColumnChange(column.id)
                                                }}>{column.render("Header")}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <SortIcon classIconHaut="filter-icon filter-icon__haut filter-icon__active" classIconBas="filter-icon filter-icon__bas" />
                                                        : <SortIcon classIconBas="filter-icon filter-icon__bas filter-icon__active" classIconHaut="filter-icon filter-icon__haut" />
                                                    : <p></p>
                                                }
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={i} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            {!props.noPagination &&
                <div className="pagination">
                    <div className="nombreLigneParPage-pagination">
                        <p>Nombre de lignes par page:</p>
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                                onTableSizeChange(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="nbPageAndButton-paginationContainer">
                        <div className="button-pagination">
                            <button onClick={() => { gotoPage(0); onTableIndexChange(0); }} disabled={!canPreviousPage}>
                                <span>{'<<'}</span>
                            </button>{' '}
                            <button onClick={() => { onTableIndexChange(pageIndex - 1); previousPage(); }} disabled={!canPreviousPage}>
                                <span>{'<'}</span>
                            </button>{' '}
                            <button onClick={() => { onTableIndexChange(pageIndex + 1); nextPage(); }} disabled={!canNextPage}>
                                <span>{'>'}</span>
                            </button>{' '}
                            <button onClick={() => { onTableIndexChange(pageCount - 1); gotoPage(pageCount - 1); }} disabled={!canNextPage}>
                                <span>{'>>'}</span>
                            </button>{' '}
                        </div>
                        <div className="nombrePage-pagination">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} sur {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                    </div>
                    <div className="goToPage-pagination">
                        <span>
                            Aller à la page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                    </div>
                </div>
            }
        </div>
    );
};

export default TableRender;