import React from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import { formatBytes, iconExtension } from '../../../utils/GeneralHelper';
import Select from 'react-select/creatable';
import LoadingBar from 'react-top-loading-bar';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';
import axiosInstance from '../../../utils/axiosInstance';

const ModifierDocumentProcessus = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const formSchema = yup.object().shape({
        titre: yup.string().required("Veuillez saisir le titre du document"),
        typeDocumentProcessus: yup.string(),
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        let postData = {
            titre: values.titre,
            typeDocumentProcessus: values.typeDocumentProcessus
        };
        // postData.append("document", props.documentData)
        // postData.append("processusId", props.processusId)
        // postData.append("documentSaveRoute", "ajouterDocumentProcessus")

        axiosInstance.patch(`/uploads/documentsProcessus/${props.documentData._id}`, postData)
            .then((result) => {
                props.progressBarRef.current.complete();
                actions.resetForm();
                customToast.success("Le document a été modifié avec succès");
                console.log(result.data);
                props.editDocumentProcessus(result.data);
                close();
            }).catch((err) => {
                props.progressBarRef.current.complete();
                setSubmitting(false);
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting, setFieldValue } = useFormik({
        initialValues: {
            "titre": props.documentData.titre,
            "typeDocumentProcessus": props.documentData.typeDocumentProcessus,
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onChangeTypeDocument = (opt) => {
        if (opt) {
            setFieldValue("typeDocumentProcessus", opt.value);
        }
        else {
            setFieldValue("typeDocumentProcessus", opt);
        }
    };

    const fieldNotModified = () => {
        return props.documentData.titre === values.titre && values.typeDocumentProcessus === props.documentData.typeDocumentProcessus;
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Modifier un document</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='documentContent' className='form-label'>Document modifié</label>
                    <div className="document-upload mgT-s2">
                        <div className="fileItem">
                            {iconExtension(props.documentData.file.filename)}
                            <p>{props.documentData.titre}.{props.documentData.file.filename.split(".").pop().toLowerCase()} - {formatBytes(props.documentData.file.length)}</p>
                        </div>
                    </div>
                </div>
                <div className='label-and-input'>
                    <label htmlFor='titre' className='form-label'>Titre du document<span className='required-field'>*</span></label>
                    <input name='titre' className={`new-default-input${errors.titre && touched.titre ? " input-error" : ""}`} type="text" placeholder="Titre du document" value={values.titre} onChange={handleChange} onBlur={handleBlur} />
                    {errors.titre && touched.titre && <p className="form-error-message">{errors.titre}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='typeDocumentProcessus' className='form-label'>Type du document</label>
                    <Select
                        options={[
                            { label: "Fiche processus", value: "Fiche processus" },
                            { label: "Notice de poste", value: "Notice de poste" },
                            { label: "Autre", value: "Autre" }
                        ]}
                        name='typeDocumentProcessus'
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeTypeDocument(opt)}
                        placeholder={"Type du document"}
                        defaultValue={{ label: props.documentData.typeDocumentProcessus, value: props.documentData.typeDocumentProcessus }}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                    />
                    {errors.typeDocumentProcessus && touched.typeDocumentProcessus && <p className="form-error-message">{errors.typeDocumentProcessus}</p>}
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier document" />
            </form>
        </div>
    );
};

export default ModifierDocumentProcessus;