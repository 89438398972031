import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosInstance from '../../utils/axiosInstance';
import DatePicker from 'react-date-picker';
import customToast from '../../utils/ToastifyHelper';

const addControle = (controle) => (actionCreators.addControle(controle));
const modifyControle = (controle) => (actionCreators.modifyControle(controle));
const modifyProchainControle = (controle) => (actionCreators.modifyProchainControle(controle));
/** 
 * * props.newData => selected data of the table {dateControle: [Date String] , _id: [String]}
 * * onSubmit = (data) -> data => data of the submitted form
 * 
 */


const ControlesForm = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    /** 
    * @param props.action Decide of the action for the switch 
    * * - ADD
    * * - MODIFY
    * // * - DELETE -
    * * - MODIFY_NEXT
    */

    const dispatch = useDispatch();
    const close = () => {
        props.closeModal();
        props.unrenderForm();

    };
    //TODO: CLEAN CODE

    const onSubmit = (dataSubmitted) => {
        setButtonDisabled(true);
        const date = new Date(dataSubmitted.date_controle.toString()).toISOString();

        switch (props.action) {
            case "ADD":
                axiosInstance.patch(`equipementEPC/addControles/${props.historiqueId}`, { date })
                    .then((res) => {
                        dispatch(addControle(res.data));
                        customToast.success("Le controle à été ajouté avec succès");
                    }

                    ).catch((err) => console.log(err));
                break;

            case "MODIFY":
                axiosInstance.patch(`equipementEPC/modifyControles/${props.historiqueId}`, { date, _idControle: props.data._id })
                    .then((res) => {
                        dispatch(modifyControle(res.data));
                        customToast.success("Le controle à été modifié avec succès");
                    }).catch((err) => console.log(err));
                break;
            case "MODIFY_NEXT":
                axiosInstance.patch(`equipementEPC/nextControle/${props.historiqueId}`, { date })
                    .then((res) => {
                        dispatch(modifyProchainControle(date));
                        customToast.success("Le prochaine controle à été modifié avec succès");
                    }).catch((err) => console.log(err));
                break;
        }
        setButtonDisabled(true);
        close();
    };

    // validationschema
    const schema = yup.object().shape({
        date_controle: yup.date().required("Veuillez selectionner une date").test(dateString => new Date(dateString).toString() !== 'Invalid Date')
    });
    const { register, handleSubmit, formState: { errors }, control } = useForm({
        defaultValues: { date_controle: new Date() },
        resolver: yupResolver(schema)
    });


    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">{props.action === "MODIFY_NEXT" ? "Modifier la prochaine date de controle" : (props.action === "ADD" ? "Ajouter un controle" : "Modifier un controle")}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
                <label className='ajouter-form-label default-label' htmlFor="dateControle">Date du {props.action === "MODIFY_NEXT" ? "prochain " : ""}controle</label>
                <Controller control={control} name='date_controle' render={({ field: { value = new Date(), onBlur, onChange, name }, formState }) => (<DatePicker value={value} onChange={onChange} className="input-datePicker" onBlur={onBlur} name={name} />)} />
                {errors['date_controle'] && errors['date_controle'].type === "required" && <p className="login-error-msessage">Veuillez saisir une date</p>}
                <input disabled={isButtonDisabled} className="validate-button-fw" type="submit" value={(props.action === "ADD" ? "Ajouter" : "Modifier")} />
            </form>
        </div>);
};

export default ControlesForm;