import React, { useState } from 'react';
import customToast from '../../utils/ToastifyHelper';

const PaginationTable = (props) => {
    const [allerPage, setAllerPage] = useState("");

    const canPreviousPage = () => {
        return props.page - 1 > 0;
    };

    const getNombrePage = () => {
        if (props.total > 0) {
            return Math.ceil(props.total / 10);
        }
        return 0;
    };

    const canNextPage = () => {
        return props.page + 1 <= getNombrePage();
    };

    const goToPage = () => {
        if (allerPage === "") {
            customToast.error("Le numéro de page n'a pas été renseigner");
        }
        else if (parseInt(allerPage) < 1 || parseInt(allerPage) > getNombrePage()) {
            customToast.error("Les pages sont comprises entre 1 et " + getNombrePage());
        }
        else {
            props.fetchData(allerPage, props.limit);
        }
    };

    return (
        <div className='pagination-container'>
            <div className="nombreLigneParPage-pagination">
                <p className='mgR-s2'>Nombre de lignes par page:</p>
                <select
                    className='new-default-input noFullWidth'
                    // value={pageSize}
                    value={props.limit ? props.limit : 10}
                    onChange={e => {
                        props.fetchData(props.page, e.target.value);
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            <div className="nbPageAndButton-paginationContainer">
                <div className="button-pagination">
                    {/* <button onClick={() => {gotoPage(0); onTableIndexChange(0)}} disabled={!canPreviousPage}> */}
                    <button className={`pagination-btn first ${!canPreviousPage() ? "disabled" : ""}`} disabled={!canPreviousPage() || props.disabled} onClick={() => props.fetchData(1, props.limit)}>
                        <span>{'<<'}</span>
                    </button>
                    {/* <button onClick={() => {onTableIndexChange(pageIndex - 1 ); previousPage()}} disabled={!canPreviousPage}> */}
                    <button className={`pagination-btn second ${!canPreviousPage() ? "disabled" : ""}`} disabled={!canPreviousPage() || props.disabled} onClick={() => props.fetchData(props.page - 1, props.limit)}>
                        <span>{'<'}</span>
                    </button>
                    {/* <button onClick={() => {onTableIndexChange(pageIndex + 1); nextPage()}} disabled={!canNextPage}> */}
                    <button className={`pagination-btn ${!canNextPage() ? "disabled" : ""}`} disabled={!canNextPage() || props.disabled} onClick={() => props.fetchData(props.page + 1, props.limit)}>
                        <span>{'>'}</span>
                    </button>
                    {/* <button onClick={() => {onTableIndexChange(pageCount - 1); gotoPage(pageCount - 1)}} disabled={!canNextPage}> */}
                    <button className={`pagination-btn last ${!canNextPage() ? "disabled" : ""}`} disabled={!canNextPage() || props.disabled} onClick={() => props.fetchData(getNombrePage(), props.limit)}>
                        <span>{'>>'}</span>
                    </button>
                </div>
                <div className="nombrePage-pagination">
                    <p>Page <span>{props.page} sur {getNombrePage()}</span></p>
                    {/* {pageIndex + 1} sur {pageOptions.length} */}
                </div>
            </div>
            <div className="goToPage-pagination">
                <p>
                    Aller à la page:
                </p>
                <input
                    type="number"
                    className='new-default-input noFullWidth'
                    min={1}
                    max={getNombrePage()}
                    value={allerPage}
                    onChange={e => {
                        setAllerPage(e.target.value);
                    }}
                    style={{ width: "5rem" }}
                />
                <button onClick={() => goToPage()} className='btn btn-secondary'>ok</button>
            </div>
        </div>
    );
};

export default PaginationTable;