import React, { useEffect, useState } from 'react';
import * as actionCreators from '../../store/actions/exportAction';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import AjouterAbsenceForm from '../../components/forms/ReleveHeureForms/AjouterAbsenceForm';
import ModifyAbsenceForm from '../../components/forms/ReleveHeureForms/ModifyAbsenceForm';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { format } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import { getAbsenceColumns } from '../../components/tables/TableColumns';
import TableRender from '../../components/tables/TableRender';
import { advancedSearch } from '../../utils/GeneralHelper';

const onLoadPage = (dispatch) => {
    dispatch(actionCreators.getAbsenceList());
    dispatch(actionCreators.getUserList());
};
const onQuitPage = (dispatch) => {
    dispatch(actionCreators.unloadAbsenceList());
    dispatch(actionCreators.unloadUserList());
};
const Absence = () => {
    const [showedItems, setShowedItems] = useState([]);
    const [searchBarContent, setSearchBarContent] = useState(null);
    const [searchAbsenceValue, onChange] = useState([null, null]);


    // get chantier_zone state
    const absencesState = useSelector(
        state => state.absenceList
    );
    const dispatch = useDispatch();

    useEffect(() => {
        onLoadPage(dispatch);
        return () => {
            onQuitPage(dispatch);
            setShowedItems([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        let showedAbsences = absencesState.absences;
        // searchbar
        if (searchBarContent !== null) {
            showedAbsences = showedAbsences.filter(absence => {
                return (
                    advancedSearch(absence.utilisateur_absent.nom, searchBarContent) ||
                    advancedSearch(absence.utilisateur_absent.prenom, searchBarContent) ||
                    advancedSearch(absence.utilisateur_absent.login_matricule, searchBarContent)
                );
            });
        }
        if (searchAbsenceValue !== null && searchAbsenceValue[0] !== null && searchAbsenceValue[1] !== null) {
            let startDateFormat = new Date(format(searchAbsenceValue[0], 'yyyy/MM/dd')).getTime();
            let endDateFormat = new Date(format(searchAbsenceValue[1], 'yyyy/MM/dd')).getTime();
            showedAbsences = showedAbsences.filter(absence => {
                let date_deb_absence = new Date(format(new Date(absence.date_deb_absence), 'yyyy/MM/dd')).getTime();
                let date_fin_absence = new Date(format(new Date(absence.date_fin_absence), 'yyyy/MM/dd')).getTime();
                return (
                    (date_fin_absence >= startDateFormat) &&
                    (date_deb_absence <= startDateFormat || date_fin_absence < endDateFormat)
                );
            }
            );
        }
        setShowedItems(showedAbsences);

    }, [absencesState.absences, searchBarContent, searchAbsenceValue]);

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openAddAbsence = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterAbsenceForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifyAbsence = (absenceData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifyAbsenceForm absence={absenceData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerAbsence = (absenceData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm absence={absenceData} destination="supprimerAbsence" closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const searchAbsence = (e) => {
        if (e.target.value !== '') {
            setSearchBarContent(e.target.value);
        }
        else {
            setSearchBarContent(null);
        }
    };

    return (
        <div className='section'>
            <div className="container-basic mgB-m2">
                <h1 className="h1-blue-dark">Liste des absences</h1>
                <div className="tableau-top-section">
                    <h3>Il y a {absencesState.absences.length} absence{absencesState.absences.length > 1 && "s"}</h3>
                    <input className="header-input" onChange={(e) => searchAbsence(e)} name="searchChantier" type="text" placeholder="Rechercher une absence" />
                    <DateRangePicker calendarIcon={null} minDate={new Date("2022-01-01")} maxDate={new Date("2099-12-31")} className="input-datetimePicker searchDate-select" showLeadingZeros={true} format="dd/MM/yyyy" onChange={onChange} value={searchAbsenceValue} />
                    <button onClick={() => openAddAbsence()} className='btn btn-primary'>+ Absence</button>
                </div>
            </div>
            <TableRender
                columns={getAbsenceColumns((absenceData) => openModifyAbsence(absenceData), (absenceData) => openSupprimerAbsence(absenceData))}
                tableData={showedItems}
                sortBy={[{ id: 'date_deb_absence', desc: true }, { id: 'date_fin_absence', desc: true }]}
                pageName="AbsencesList"
            />
            {/* <AbsenceListTable absenceList={showedItems} supprimerAbsence={(absenceData) => openSupprimerAbsence(absenceData)} modifierAbsence={(absenceData) => openModifyAbsence(absenceData)}/> */}
            <Modal modalClass={modalClasses}>{modalContent}</Modal>

        </div>
    );
};

export default Absence;
