import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import customToast from '../../utils/ToastifyHelper';
import * as actionTypes from './actionTypes';

const loadAbsenceList = (absenceList) => {
    return {
        type: actionTypes.LOAD_ALL_ABSENCES,
        absenceList: absenceList,
    };
};

export const unloadAbsenceList = () => {
    return {
        type: actionTypes.UNLOAD_ALL_ABSENCES,
    };
};

export const addAbsence = (absenceData) => {
    return {
        type: actionTypes.ADD_ABSENCE,
        absence: absenceData
    };
};

export const modifyAbsence = (absenceNewData, oldAbsenceId) => {
    return {
        type: actionTypes.MODIFY_ABSENCE,
        absenceUpdated: absenceNewData,
        oldAbsenceId: oldAbsenceId
    };
};

export const deleteAbsence = (absenceId) => {
    return {
        type: actionTypes.DELETE_ABSENCE,
        absenceId: absenceId
    };
};

export const getAbsenceList = () => (dispatch) => {
    // get all absence
    axiosInstance.get('/absences')
        .then((result) => {
            dispatch(loadAbsenceList(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getAbsenceById = (idUtilisateur) => (dispatch) => {
    // get all absence
    axiosInstance.get(`/absences/utilisateurs/${idUtilisateur}`)
        .then((result) => {
            dispatch(loadAbsenceList(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getAbsenceListConnecte = () => (dispatch) => {
    // get all absence
    axiosInstance.get('/absences/me')
        .then((result) => {
            dispatch(loadAbsenceList(result.data));
        }).catch((err) => {
            showError(err);
        });
};