import React, { useState, useRef, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';
import Modal from '../../components/Modal';
import CreerFormation from '../../components/forms/GestionsForms/CreerFormation';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import SearchInput from '../../components/ui/SearchInput';
import TableRender from '../../components/tables/TableRender';
import { getFormationsSuiviColumns } from '../../components/tables/TableColumns';
import ModifierFormationSuivi from '../../components/forms/GestionsForms/ModifierFormationSuivi';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';

import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import Breadcrumb from '../../components/ui/Breadcrumb';
import Select from "react-select";

const GestionFormationsSuivi = () => {

    // filter
    const [fomationPourFilter, setFomationPourFilter] = useState("All");
    const progressBarRef = useRef(null);

    // modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalFormationSelected, setModalFormationSelected] = useState({});

    // Formations data
    const [formationsSuivis, setFormationsSuivis] = useState([]);
    const [showedFormationsSuivis, setShowedFormationsSuivis] = useState([]);

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/formationsSuiviSalarie").then((result) => {
            setFormationsSuivis(result.data);
            progressBarRef.current.complete();
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    }, []);

    const addFormation = (formation) => {
        setFormationsSuivis(formationsSuivis.concat(formation));
    };

    const openAddFormationModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };


    const openModifierFormationModal = (formationData) => {
        setModalFormationSelected(formationData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editFormation = (updatedFormation) => {
        setFormationsSuivis(formationsSuivis.map((formation) => {
            if (formation._id === updatedFormation._id) {
                return updatedFormation;
            }
            return formation;
        }));
    };

    const deleteFormationSuivi = (formationId) => {
        setFormationsSuivis(formationsSuivis.filter(formation => {
            return formation._id !== formationId;
        }));
    };

    //document search bar
    const [searchFormationBarContent, setSearchFormationBarContent] = useState(null);
    const formationMatch = (formation, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        console.log(getInsensitiveWorld(formation.nomFormation).includes(insensitiveSearch));
        return getInsensitiveWorld(formation.nomFormation).includes(insensitiveSearch);
    };

    // SEARCH INPUT
    useEffect(() => {
        if (searchFormationBarContent !== null && searchFormationBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedFormationsSuivis(formationsSuivis.filter(formation => {
                    return formationMatch(formation, searchFormationBarContent) && (formation.formationPour === fomationPourFilter || fomationPourFilter === "All");
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedFormationsSuivis(formationsSuivis.filter(formation => {
                return formation.formationPour === fomationPourFilter || fomationPourFilter === "All";
            }));
        }
    }, [formationsSuivis, searchFormationBarContent, fomationPourFilter]);



    const onChangeSearchFormation = (e) => {
        setSearchFormationBarContent(e.target.value);
    };

    const openSupprimerModal = (formationData) => {
        setModalFormationSelected(formationData);
        setModalClasses("modal modal__active");
        setModalContent("supprimer");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>Formations/suivis</h1>
                    <button onClick={() => openAddFormationModal()} className='btn btn-primary medium-btn'>+ Formation</button>
                    {/* <button onClick={() => openAddFormationModal()} className='btn btn-primary'>+ Ajouter</button> */}
                </div>
                <Breadcrumb
                    links={[
                        { label: "Geston des données", link: "/gestion-de-donnees" },
                        { label: "Formations", link: "/gestion-de-donnees/formations-suivis" },
                    ]}
                />
                <div className='filterContainer mgT-m1'>
                    <SearchInput placeholder="Rechercher une formation" searchContent={searchFormationBarContent ? searchFormationBarContent : ""} onChangeSearch={onChangeSearchFormation} />
                    <select onChange={(e) => setFomationPourFilter(e.target.value)} className='new-default-input' value={fomationPourFilter ? fomationPourFilter : "Tous les salariés"}>
                        <option value="All">Toutes les formations</option>
                        <option value="Tous les salariés">Tous les salariés</option>
                        <option value="Amiante">Amiantes</option>
                        <option value="Chauffeurs">Chauffeurs</option>
                    </select>
                </div>
                <TableRender
                    columns={getFormationsSuiviColumns((formationData) => openModifierFormationModal(formationData), (formationData) => openSupprimerModal(formationData))}
                    tableData={showedFormationsSuivis}
                    sortBy={[

                    ]}
                    pageName="gestionFormationsSuivi"
                />
            </div>
            {/* <Modal modalClass={modalClasses}>{modalContent}</Modal> */}
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter" &&
                    <CreerFormation progressBarRef={progressBarRef} addFormation={(formation) => addFormation(formation)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "modifier" &&
                    <ModifierFormationSuivi key={modalFormationSelected._id} progressBarRef={progressBarRef} formationData={modalFormationSelected} editFormation={(updatedFormation) => editFormation(updatedFormation)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "supprimer" &&
                    <SupprimerModalForm key={modalFormationSelected._id} destination="supprimerFormationSuivi" formationData={modalFormationSelected} deleteFormationSuivi={(formationId) => deleteFormationSuivi(formationId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>
        </>
    );
};

export default GestionFormationsSuivi;;