import axiosInstance from '../../utils/axiosInstance';
import * as actionTypes from './actionTypes';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';

const loadChantierList = (chantierList, zoneList) => {
    return {
        type: actionTypes.LOAD_CHANTIER_ZONE_LIST,
        chantierList: chantierList,
        zoneList: zoneList
    };
};

export const unloadChantierZoneList = () => {
    return {
        type: actionTypes.UNLOAD_CHANTIER_ZONE_LIST,
    };
};

export const addChantier = (chantierData) => {
    return {
        type: actionTypes.ADD_CHANTIER,
        chantier: chantierData
    };
};

export const modifyChantier = (chantierNewData) => {
    return {
        type: actionTypes.MODIFY_CHANTIER,
        chantierUpdated: chantierNewData
    };
};

export const deleteChantier = (chantierId) => {
    return {
        type: actionTypes.DELETE_CHANTIER,
        chantierId: chantierId
    };
};

export const setChantierOptions = (options) => {
    return {
        type: actionTypes.LOAD_CHANTIER_OPTIONS,
        allChantierOptions: options
    };
};

export const getChantierZoneList = () => (dispatch) => {
    // get all chantier
    axiosInstance.get('/chantiers', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            axiosInstance.get('/zones', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
                .then((res) => {
                    dispatch(loadChantierList(result.data, res.data));
                }).catch((error) => {
                    showError(error);
                });
        }).catch((err) => {
            showError(err);
        });
};

export const getChantierZoneListReleve = () => (dispatch) => {
    // get all chantier
    axiosInstance.get('/chantiers?zone=true', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            axiosInstance.get('/zones', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
                .then((res) => {
                    dispatch(loadChantierList(result.data, res.data));
                }).catch((error) => {
                    showError(error);
                });
        }).catch((err) => {
            showError(err);
        });
};

export const getChantierOptions = () => (dispatch) => {
    axiosInstance.get('/chantiers?statut_chantier=0&zone=true')
        .then((result) => {
            let formatOptions = [];
            for (const chantier of result.data) {
                formatOptions.push({ label: `${chantier.codeChantier} - ${chantier._id.libelle_lieu} (${chantier.zone.libelle_zone})`, value: chantier._id._id });
            }
            dispatch(setChantierOptions(formatOptions));
        }).catch((err) => {
            showError(err);
        });
};