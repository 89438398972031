import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';


const releveHeureAdminState = {
    releveHeure: []
};

const reducer = (state = releveHeureAdminState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_ADMIN_RELEVE_HEURE_CHEF:
            return updateObject(state, { releveHeure: action.releveHeure });
        case actionTypes.UNLOAD_ADMIN_RELEVE_HEURE:
            return { releveHeure: [] };
        case actionTypes.UPDATE_RELEVE_HEURE_ADMIN:
            return {
                ...state,
                releveHeure: state.releveHeure.map(
                    (releveHeure) => releveHeure.numero_semaine === action.numeroSemaine && releveHeure.annee === action.annee ?
                        { ...releveHeure, jours: action.newSemaine.jours }
                        :
                        releveHeure
                )
            };
        case actionTypes.CHANGE_STATUT_RELEVE_HEURE_ADMIN:
            return {
                ...state,
                releveHeure: state.releveHeure.map(
                    (releveHeure) => releveHeure._id === action.idSemaine ?
                        { ...releveHeure, statut_horaire_semaine: action.numStatut }
                        :
                        releveHeure
                )
            };
        default:
            return state;
    }
};

export default reducer;
