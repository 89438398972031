import React from "react";
import { format } from "date-fns";

import {
	formatIntTwoDigitString,
	getHeureSup,
	banqueHeureTotal,
	iconExtension,
	formatBytes,
	getLastModified,
	downloadFile,
	formatMateriaux,
	formatTechniqueModeOperatoire
} from "../../utils/GeneralHelper";
import { getAllDaysOfWeek } from "../../utils/DateHelper";
import ReactTooltip from "react-tooltip";
import EditIcon from "../icon/EditIcon";
import SupprimerIcon from "../icon/SupprimerIcon";
import fr from "date-fns/locale/fr";
import { Icon } from "@iconify/react";
import DocumentDotMenu from "../DocumentDotMenu";
import DocumentSalarieDotMenu from "../threeDotMenu/DocumentSalarieDotMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScrewdriverWrench } from "@fortawesome/pro-solid-svg-icons";
import DocumentMaterielDotMenu from "../threeDotMenu/DocumentMaterielDotMenu";
import ProfilePicture from "../ui/ProfilePicture";
import DefaultProfilePicture from "../ui/DefaultProfilePicture";

// TABLEAU ABSENCE
export const getAbsenceColumns = (modifierAbsence, supprimerAbsence) => {
	return [
		{
			Header: "Nom",
			accessor: "utilisateur_absent.nom",
		},
		{
			Header: "Prénom",
			accessor: "utilisateur_absent.prenom",
		},
		{
			Header: "Matricule",
			accessor: "utilisateur_absent.login_matricule",
		},
		{
			Header: "Date début absence",
			accessor: "date_deb_absence",
			Cell: ({ value }) => {
				return format(new Date(value), "dd/MM/yyyy");
			},
		},
		{
			Header: "Date fin absence",
			accessor: "date_fin_absence",
			Cell: ({ value }) => {
				if (value !== null) {
					return format(new Date(value), "dd/MM/yyyy");
				} else {
					return null;
				}
			},
		},
		{
			Header: "Temps d'absence",
			Cell: ({ row }) =>
				row.original.tempsAbsence.heure !== null &&
					row.original.tempsAbsence.minute !== null ? (
					<p>
						{formatIntTwoDigitString(row.original.tempsAbsence.heure)}h
						{formatIntTwoDigitString(row.original.tempsAbsence.minute)}
					</p>
				) : null,
		},
		{
			Header: "Type d'absence",
			accessor: "type_absence",
		},
		{
			Header: "Commentaire",
			accessor: "commentaire",
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={
								"modifierTip-" +
								row.original.utilisateur_absent.login_matricule +
								row.original.date_deb_absence
							}
							onClick={() => modifierAbsence(row.original)}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={
								"modifierTip-" +
								row.original.utilisateur_absent.login_matricule +
								row.original.date_deb_absence
							}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={
								"supprimerTip-" +
								row.original.utilisateur_absent.login_matricule +
								row.original.date_deb_absence
							}
							onClick={() => supprimerAbsence(row.original)}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={
								"supprimerTip-" +
								row.original.utilisateur_absent.login_matricule +
								row.original.date_deb_absence
							}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
				</div>
			),
		},
	];
};

// TABLEAU BANQUE HEURE
export const getBanqueHeureColumns = (user, supprimerBanqueHeure) => {
	let banqueHeureColumns = [
		{
			Header: "Date de modification",
			id: "dateModif",
			accessor: "dateValidation",
			Cell: ({ row }) => (
				<p>{format(new Date(row.original.dateValidation), "dd/MM/yyyy")}</p>
			),
		},
		{
			Header: "Motif",
			disableFilters: true,
			Cell: ({ row }) => {
				if (row.original.editeur !== null) {
					if (row.original.total.heure < 0) {
						return (
							<p>
								<span className="tableAbsence-span-negatif">
									{"-" +
										formatIntTwoDigitString(
											Math.abs(row.original.total.heure)
										) +
										"h" +
										formatIntTwoDigitString(
											Math.abs(row.original.total.minute)
										)}
								</span>
								, enlevée manuellement par{" "}
								{row.original.editeur.nom + " " + row.original.editeur.prenom}{" "}
								{row.original.commentaire !== undefined &&
									row.original.commentaire !== ""
									? "-> " + row.original.commentaire
									: ""}
							</p>
						);
					} else {
						return (
							<p>
								<span className="tableAbsence-span-positif">
									{"+" +
										formatIntTwoDigitString(row.original.total.heure) +
										"h" +
										formatIntTwoDigitString(row.original.total.minute)}
								</span>
								, ajoutée manuellement par{" "}
								{row.original.editeur.nom + " " + row.original.editeur.prenom}{" "}
								{row.original.commentaire !== undefined &&
									row.original.commentaire !== ""
									? "-> " + row.original.commentaire
									: ""}
							</p>
						);
					}
				} else {
					if (
						row.original.total.heure > 42 ||
						(row.original.total.heure === 42 && row.original.total.minute > 0)
					) {
						return (
							<p>
								<span className="tableAbsence-span-positif">
									{"+" +
										formatIntTwoDigitString(
											getHeureSup(row.original.total).heure
										) +
										"h" +
										formatIntTwoDigitString(
											getHeureSup(row.original.total).minute
										)}
								</span>
								, la semaine {row.original.semaine.numeroSemaine} du{" "}
								{format(
									getAllDaysOfWeek(
										row.original.semaine.numeroSemaine,
										row.original.semaine.annee
									)[0],
									"eeee dd",
									{ locale: fr }
								)}{" "}
								au{" "}
								{format(
									getAllDaysOfWeek(
										row.original.semaine.numeroSemaine,
										row.original.semaine.annee
									)[
									getAllDaysOfWeek(
										row.original.semaine.numeroSemaine,
										row.original.semaine.annee
									).length - 1
									],
									"dd MMMM yyyy",
									{ locale: fr }
								)}{" "}
								-&gt; {formatIntTwoDigitString(row.original.total.heure)}h
								{formatIntTwoDigitString(row.original.total.minute)}{" "}
								effectuée(s){" "}
							</p>
						);
					} else {
						return (
							<p>
								<span className="tableAbsence-span-nul">
									{formatIntTwoDigitString(
										getHeureSup(row.original.total).heure
									) +
										"h" +
										formatIntTwoDigitString(
											getHeureSup(row.original.total).minute
										)}
								</span>
								, la semaine {row.original.semaine.numeroSemaine} du{" "}
								{format(
									getAllDaysOfWeek(
										row.original.semaine.numeroSemaine,
										row.original.semaine.annee
									)[0],
									"eeee dd",
									{ locale: fr }
								)}{" "}
								au{" "}
								{format(
									getAllDaysOfWeek(
										row.original.semaine.numeroSemaine,
										row.original.semaine.annee
									)[
									getAllDaysOfWeek(
										row.original.semaine.numeroSemaine,
										row.original.semaine.annee
									).length - 1
									],
									"dd MMMM yyyy",
									{ locale: fr }
								)}{" "}
								-&gt; {formatIntTwoDigitString(row.original.total.heure)}h
								{formatIntTwoDigitString(row.original.total.minute)}{" "}
								effectuée(s){" "}
							</p>
						);
					}
				}
			},
		},
	];

	if (user && user === "Administration") {
		banqueHeureColumns.push({
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"supprimerTip-" + row.original._id}
							onClick={() => supprimerBanqueHeure(row.original)}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"supprimerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
				</div>
			),
		});
	}
	return banqueHeureColumns;
};

export const getAdminGestionBanqueHeureColumns = (navigate) => {
	return [
		{
			Header: "Matricule",
			accessor: "user.login_matricule",
		},
		{
			Header: "Nom",
			accessor: "user.nom",
		},
		{
			Header: "Prénom",
			accessor: "user.prenom",
		},
		{
			Header: "Secteur",
			accessor: "user.secteur",
		},
		{
			Header: "Statut",
			accessor: "userRole",
		},
		{
			Header: "Total",
			Cell: ({ row }) => {
				const totalHeure = banqueHeureTotal(
					row.original.banqueHeure,
					row.original.absence
				);
				return (
					<p>
						{totalHeure.val === "neg" ? (
							<span className="tableAbsence-span-negatif">
								-{formatIntTwoDigitString(totalHeure.heure)}h
								{formatIntTwoDigitString(totalHeure.minute)}
							</span>
						) : totalHeure.val === "pos" ? (
							<span className="tableAbsence-span-positif">
								+{formatIntTwoDigitString(totalHeure.heure)}h
								{formatIntTwoDigitString(totalHeure.minute)}
							</span>
						) : (
							<span className="tableAbsence-span-nul">
								{formatIntTwoDigitString(totalHeure.heure)}h
								{formatIntTwoDigitString(totalHeure.minute)}
							</span>
						)}
					</p>
				);
			},
		},
		{
			Header: "Actions",
			disableFilters: true,
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() =>
							navigate(`/releve-heure/banque-salaries/${row.original.user._id}`)
						}
						className="select-semaine-button default-radius"
					>
						<Icon icon="ant-design:field-time-outlined" />
					</button>
				</div>
			),
		},
	];
};

// TABLEAU ARCHIVE CHANTIER
export const getArchiveChantierColumns = (
	detailChantier,
	desarchiverChantier,
	supprimerChantier
) => {
	return [
		{
			Header: "Code",
			accessor: "codeChantier",
		},
		{
			Header: "Nom",
			accessor: "_id.libelle_lieu",
		},
		{
			Header: "Zone",
			accessor: "zone.libelle_zone",
		},
		{
			Header: "Adresse",
			Cell: ({ row }) => (
				<div>
					<p>
						{row.original._id.adresse.codePostal
							? row.original._id.adresse.codePostal + " "
							: ""}
						{row.original._id.adresse.ville
							? row.original._id.adresse.ville + ", "
							: ""}
						{row.original._id.adresse.lieuExecution}
					</p>
				</div>
			),
		},
		{
			Header: "Secteur",
			accessor: "secteur",
		},
		{
			Header: "Statut",
			Cell: ({ row }) =>
				row.original.statut_chantier === 0 ? (
					<div className="dotAndText">
						<Icon className="yellowDot dot" icon="carbon:dot-mark" />
						<p>En cours</p>
					</div>
				) : (
					<div className="dotAndText">
						<Icon className="greenDot dot" icon="carbon:dot-mark" />
						<p>Terminé</p>
					</div>
				),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"historiqueTip-" + row.original._id._id}
							onClick={() => detailChantier(row.original)}
							className="select-semaine-button default-radius sm2"
						>
							<Icon icon="eva:search-outline" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"historiqueTip-" + row.original._id._id}
							place="bottom"
							effect="solid"
						>
							Historique
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"desarchiverTip-" + row.original._id._id}
							onClick={() => desarchiverChantier(row.original)}
							className="table-modify-button"
						>
							<Icon icon="fluent:archive-arrow-back-20-regular" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"desarchiverTip-" + row.original._id._id}
							place="bottom"
							effect="solid"
						>
							Désarchiver
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"supprimerTip-" + row.original._id._id}
							onClick={() => supprimerChantier(row.original)}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"supprimerTip-" + row.original._id._id}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
				</div>
			),
		},
	];
};

// TABLEAU ARCHIVE SALARIE
export const getArchiveSalarieColumns = (
	detailSalarie,
	desarchiverSalarie,
	supprimerUser,
	modifierSalarie
) => {
	return [
		{
			Header: "Matricule",
			accessor: "user._id.login_matricule",
		},
		{
			Header: "Nom",
			accessor: "user._id.nom",
		},
		{
			Header: "Prénom",
			accessor: "user._id.prenom",
		},
		{
			Header: "Type d'archivage",
			accessor: "user.typeArchivage",
		},
		{
			Header: "Commentaire",
			accessor: data => {
				return (data.user.commentaire === "" || data.user.commentaire === undefined ? "Aucun" : data.user.commentaire);
			}
		},
		{
			Header: "Date d'archivage",
			Cell: ({ row }) => (
				<p>
					{format(
						new Date(
							row.original.user._id.userRole.dateDebutStatut
						),
						"dd/MM/yyyy"
					)}
				</p>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"historiqueTip-" + row.original.user.login_matricule}
							onClick={() => detailSalarie(row.original)}
							className="select-semaine-button default-radius sm2"
						>
							<Icon icon="eva:search-outline" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"historiqueTip-" + row.original.user.login_matricule}
							place="bottom"
							effect="solid"
						>
							Historique
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"modifierTip-" + row.original.user.login_matricule}
							onClick={() => modifierSalarie(row.original)}
							className="default-radius sm2 table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"modifierTip-" + row.original.user.login_matricule}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"desarchiverTip-" + row.original.user.login_matricule}
							onClick={() => desarchiverSalarie(row.original)}
							className="table-archive-button"
						>
							<Icon icon="fluent:archive-arrow-back-20-regular" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"desarchiverTip-" + row.original.user.login_matricule}
							place="bottom"
							effect="solid"
						>
							Désarchiver
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"supprimerTip-" + row.original.user.login_matricule}
							onClick={() => supprimerUser({ user: row.original.user._id })}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"supprimerTip-" + row.original.user.login_matricule}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
				</div>
			),
		},
	];
};

// TABLEAU CHANTIER
export const getChantierColumns = (
	modifierChantier,
	archiverChantier,
	navigate
) => {
	return [
		{
			Header: "Code",
			accessor: "codeChantier",
		},
		{
			Header: "Nom",
			accessor: "_id.libelle_lieu",
		},
		{
			Header: "Zone",
			accessor: "zone.libelle_zone",
		},
		{
			Header: "Adresse",
			Cell: ({ row }) => (
				<div>
					<p>
						{row.original._id.adresse.codePostal
							? row.original._id.adresse.codePostal + " "
							: ""}
						{row.original._id.adresse.ville
							? row.original._id.adresse.ville + ", "
							: ""}
						{row.original._id.adresse.lieuExecution}
					</p>
				</div>
			),
		},
		{
			Header: "Secteur",
			accessor: "secteur",
		},
		{
			Header: "Statut",
			Cell: ({ row }) =>
				row.original.statut_chantier === 0 ? (
					<div className="dotAndText">
						<Icon className="yellowDot dot" icon="carbon:dot-mark" />
						<p>En cours</p>
					</div>
				) : (
					<div className="dotAndText">
						<Icon className="greenDot dot" icon="carbon:dot-mark" />
						<p>Terminé</p>
					</div>
				),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"modifierTip-" + row.original._id._id}
							onClick={() => modifierChantier(row.original)}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"modifierTip-" + row.original._id._id}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"archiverTip-" + row.original._id._id}
							onClick={() => archiverChantier(row.original)}
							className="table-archive-button"
						>
							<Icon icon="fluent:archive-28-regular" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"archiverTip-" + row.original._id._id}
							place="bottom"
							effect="solid"
						>
							Archiver
						</ReactTooltip>
					</div>
					<div>
						<button
							onClick={() => {
								if (row.original.type === "ChantierAmiante") {
									navigate(
										`/amiante/chantiers/profil/${row.original._id._id}`
									);
								}
								else {
									navigate(
										`/gestion-de-donnees/chantiers/${row.original._id._id}`
									);
								}
							}}
							className="select-semaine-button default-radius"
						>
							<Icon icon="material-symbols:manage-search-rounded" />
						</button>
					</div>
				</div>
			),
		},
	];
};

// TABLEAU RELEVE HEURE DES CHEFS ADMINS
export const getChefReleveHeureAdminColumns = (navigate) => {
	return [
		{
			Header: "Nom",
			accessor: "chef._id.nom",
		},
		{
			Header: "Prénom",
			accessor: "chef._id.prenom",
		},
		{
			Header: "Matricule",
			accessor: "chef._id.login_matricule",
		},
		{
			Header: "Semaines validées",
			accessor: "nbReleve",
		},
		{
			Header: "Semaines en cours",
			accessor: "nbReleveEnCours",
		},
		{
			Header: "Actions",
			disableFilters: true,
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() =>
							navigate(`/releve-heure/releve-chef/${row.original.chef._id._id}`)
						}
						className="select-semaine-button default-radius"
					>
						<Icon icon="fluent:clipboard-search-24-regular" />
					</button>
				</div>
			),
		},
	];
};

// NOUVEAU TABLEAU RELEVE HEURE DES CHEFS ADMINS

export const getReleveHeureChefAdminColumns = (navigate) => {
	return [
		{
			Header: "Nom",
			accessor: "chef.nom",
		},
		{
			Header: "Prénom",
			accessor: "chef.prenom",
		},
		{
			Header: "Matricule",
			accessor: "chef.login_matricule",
		},
		{
			Header: "Semaines validées",
			accessor: "nbReleve",
		},
		{
			Header: "Semaines en cours",
			accessor: "nbReleveEnCours",
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() =>
							navigate(`/releve-heure/releve-chef/${row.original.chef._id}`)
						}
						className="select-semaine-button default-radius"
					>
						<Icon icon="fluent:clipboard-search-24-regular" />
					</button>
				</div>
			),
		},
	];
};

export const getReleveHeureSemaineAdminColumns = (navigate, openSalariePresentSemaine) => {
	return [
		{
			Header: "Année",
			accessor: "annee"
		},
		{
			Header: "Numéro semaine",
			accessor: "numero_semaine"
		},
		{
			Header: "Chef de chantier",
			accessor: "chef_chantier._id.nom",
			sortable: false,
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					<p>
						{row.original.chef_chantier._id.nom}{" "}
						{row.original.chef_chantier._id.prenom}
					</p>
				</div>
			),
		},
		{
			Header: "Statut",
			accessor: "statut_horaire_semaine",
			Cell: ({ row }) =>
				row.original.statut_horaire_semaine === 0 ? (
					<div className="dotAndText">
						<Icon className="yellowDot dot" icon="carbon:dot-mark" />
						<p>En cours</p>
					</div>
				) : (
					<div className="dotAndText">
						<Icon className="greenDot dot" icon="carbon:dot-mark" />
						<p>Terminé</p>
					</div>
				),
		},
		{
			Header: "Actions",
			disableFilters: true,
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => openSalariePresentSemaine(row.original)}
						className="select-semaine-button default-radius mgR-s2"
					>
						<Icon icon="mdi:user-outline" />
					</button>
					<button
						onClick={() => navigate(`/releve-heure/releve/${row.original._id}`)}
						className="select-semaine-button default-radius"
					>
						<Icon icon="fluent:clipboard-search-24-regular" />
					</button>
				</div>
			),
		}
	];
};

// TABLEAU DOCUMENTS PUBLICS
export const getDocumentPublicColumns = (
	openModifierDocument,
	openSupprimerDocument
) => {
	return [
		{
			Header: "Nom",
			accessor: "_id.titre",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{iconExtension(row.original._id.file.filename)}
					<p>{row.original._id.titre}</p>
				</div>
			),
		},
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div>
					<p>{row.original._id.file.filename.split(".").pop().toLowerCase()}</p>
				</div>
			),
		},
		{
			Header: "Type du fichier",
			accessor: "_id.typeDocument.libelleType",
		},
		{
			Header: "Description",
			accessor: "_id.description",
		},
		{
			Header: "Taille",
			accessor: "_id.file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original._id.file.length, 0)}</p>
				</div>
			),
		},
		{
			Header: "Dernière modification",
			accessor: "_id.file.uploadDate",
			Cell: ({ row }) => (
				<div>
					<p>il y a {getLastModified(row.original._id.file.uploadDate)}</p>
				</div>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					<DocumentDotMenu
						document={row.original._id}
						openModifierDocument={openModifierDocument}
						openSupprimerDocument={openSupprimerDocument}
						locked={row.original._id.locked}
						documentId={row.original._id._id}
						extension={row.original._id.file.filename
							.split(".")
							.pop()
							.toLowerCase()}
						fileName={`${row.original._id.titre
							}.${row.original._id.file.filename.split(".").pop().toLowerCase()}`}
					/>
				</div>
			),
		},
	];
};

// TABLEAU DOCUMENTS SALARIES
export const getDocumentSalarieColumns = (
	openRemplacerDocument,
	openSupprimerDocument
) => {
	return [
		{
			Header: "Nom",
			accessor: "titre",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{iconExtension(row.original.file.filename)}
					<p>{row.original.titre}</p>
				</div>
			),
		},
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div>
					<p>{row.original.file.filename.split(".").pop().toLowerCase()}</p>
				</div>
			),
		},
		{
			Header: "Type du fichier",
			accessor: "typeDocument.libelleType",
		},
		{
			Header: "Description",
			accessor: "description",
		},
		{
			Header: "Taille",
			accessor: "file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original.file.length)}</p>
				</div>
			),
		},
		{
			Header: "Dernière modification",
			accessor: "file.uploadDate",
			Cell: ({ row }) => (
				<div>
					<p>il y a {getLastModified(row.original.file.uploadDate)}</p>
				</div>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{/* <button data-tip data-for={"verrouillerTip-" + row.original._id} className={row.original.locked ? "table-delete-button" : "table-unlocked-button"}><Icon className='lockIcon' icon={row.original.locked ? "bxs:lock-alt" : "bxs:lock-open"}/></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{'top': 5}} id={"verrouillerTip-" + row.original._id} place="bottom" effect="solid">
                            {row.original.locked ? "Déverrouiller le fichier" : "Verrouiller le fichier"}
                        </ReactTooltip> */}
					<DocumentDotMenu
						openRemplacerDocument={openRemplacerDocument}
						documentSaveRoute={"documentsSalarie"}
						openSupprimerDocument={openSupprimerDocument}
						locked={row.original.locked}
						documentId={row.original._id}
						extension={row.original.file.filename
							.split(".")
							.pop()
							.toLowerCase()}
						fileName={`${row.original.titre}.${row.original.file.filename
							.split(".")
							.pop()
							.toLowerCase()}`}
					/>
				</div>
			),
		},
	];
};

// TABLEAU GESTION DOCUMENTS CHANTIERS
export const getGestionDocumentsChantiersColumns = (
	openModifierDocumentDoe,
	openSupprimerDocumentDoeModal
) => {
	return [
		{
			Header: "Nom",
			accessor: "libelleDocument",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{iconExtension(row.original.baseDocument.file.filename)}
					<p>{row.original.libelleDocument}</p>
				</div>
			),
		},
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div>
					<p>
						{row.original.baseDocument.file.filename
							.split(".")
							.pop()
							.toLowerCase()}
					</p>
				</div>
			),
		},
		{
			Header: "Taille",
			accessor: "baseDocument.file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original.baseDocument.file.length, 0)}</p>
				</div>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"modifierTip-" + row.original._id}
							onClick={() =>
								openModifierDocumentDoe({
									documentBase: row.original.baseDocument,
									documentId: row.original._id,
									documentTitre: row.original.libelleDocument,
								})
							}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"modifierTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"telechargerTip-" + row.original._id}
							onClick={() =>
								downloadFile(
									row.original.baseDocument._id,
									row.original.libelleDocument
								)
							}
							className="table-archive-button"
						>
							<Icon icon="eva:download-fill" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"telechargerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Télécharger
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"supprimerTip-" + row.original._id}
							// onClick={() =>
							// 	openSupprimerDocumentDoeModal(
							// 		row.original.baseDocument._id
							// 	)
							// }
							onClick={() =>
								openSupprimerDocumentDoeModal(
									row.original
								)
							}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"supprimerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
				</div>
			),
		},
	];
};

// TABLEAU GESTION SUIVI FORMATION
export const getGestionSuiviFormationSalarieColumns = (
	openModifierSuiviSalarie,
	openSupprimerSuiviSalarie
) => {
	return [
		{
			Header: "Nom de suivi ou de la formation",
			accessor: "libelleGestion",
		},
		{
			Header: "Destiné à",
			accessor: "typeGestion",
		},
		{
			Header: "Champs optionels",
			Cell: ({ row }) => {
				let champsOptionnels = [];
				if (row.original.hasOrganismeFormation) {
					champsOptionnels.push("organisme de formation");
				}
				if (row.original.hasDocument) {
					champsOptionnels.push("importer des documents");
				}
				if (row.original.hasFinValidite) {
					champsOptionnels.push("date fin de validité");
				}
				if (row.original.hasProchainControle) {
					champsOptionnels.push("date du prochain contrôle");
				}
				return <p>{champsOptionnels.toString()}</p>;
			},
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							onClick={() => openModifierSuiviSalarie(row.original)}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
					</div>
					<div>
						<button
							onClick={() => openSupprimerSuiviSalarie(row.original._id)}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
					</div>
				</div>
			),
		},
	];
};

export const getGestionSalarieColumns = (modifierSalarie, archiverSalarie, navigate) => {
	return (
		[
			{
				id: "avatar",
				header: () => null,
				Cell: ({ row }) => {
					return (
						<div className='accountSettings-profilePicture-wrapper mgR-s2'>
							{row.original.user.hasOwnProperty("profilePicture") && row.original.user.profilePicture !== null ?
								<ProfilePicture width="5rem" height="5rem" edit={false} profilePicture={row.original.user.profilePicture} /> :
								<DefaultProfilePicture additionalClassname={"table"} width="4rem" height="4rem" edit={false} />
							}
						</div>
					);
				}
			},
			{
				Header: 'Matricule',
				accessor: 'user.login_matricule'
			},
			{
				Header: 'Nom',
				accessor: 'user.nom'
			},
			{
				Header: 'Prénom',
				accessor: 'user.prenom'
			},
			{
				Header: 'Secteur',
				accessor: 'user.secteur'
			},
			{
				Header: 'Statut',
				accessor: 'userRole'
			},
			{
				Header: 'Téléphone',
				accessor: (data) => { return (!data.user.hasOwnProperty("telephone") || data.user.telephone === undefined || data.user.telephone === "" ? 'Aucun' : data.user.telephone); }
			},
			{
				Header: 'Actions',
				Cell: ({ row }) => (
					<div className="table-buton-container">
						<div>
							<button onClick={() => modifierSalarie(row.original)} className='table-modify-button'><EditIcon /></button>
						</div>
						<div>
							<button onClick={() => archiverSalarie(row.original)} className='table-archive-button'><Icon icon="fluent:archive-28-regular" /></button>
						</div>
						<div>
							<button onClick={() => navigate(`profil/${row.original.user._id}`)} className='table-black-button'><Icon icon="mdi:clipboard-user" /></button>
						</div>
					</div>
				)
			}
			// {
			//     Header: 'Actions',
			//     Cell: ({row}) => (
			//         <div className="table-buton-container">
			//             <div>
			//                 <button data-tip data-for={"modifierTip-" + row.original.user.login_matricule} onClick={() => props.modifierSalarie(row.original)} className='table-modify-button'><EditIcon /></button>
			//                 <ReactTooltip backgroundColor="#1E1E1E" offset={{'top': 5}} id={"modifierTip-" + row.original.user.login_matricule} place="bottom" effect="solid">
			//                     Modifier
			//                 </ReactTooltip>
			//             </div>
			//             <div>
			//                 <button data-tip data-for={"archiverTip-" + row.original.user.login_matricule} onClick={() => props.archiverSalarie(row.original)} className='table-archive-button'><Icon icon="fluent:archive-28-regular" /></button>
			//                 <ReactTooltip backgroundColor="#1E1E1E" offset={{'top': 5}} id={"archiverTip-" + row.original.user.login_matricule} place="bottom" effect="solid">
			//                     Archiver
			//                 </ReactTooltip>
			//             </div>
			//             <div>
			//                 <button data-tip data-for={"moreTip-" + row.original.user.login_matricule} onClick={() => navigate(`profil/${row.original.user._id}`)} className='table-black-button'><Icon icon="bx:file-blank" /></button>
			//                 <ReactTooltip backgroundColor="#1E1E1E" offset={{'top': 5}} id={"moreTip-" + row.original.user.login_matricule} place="bottom" effect="solid">
			//                     Consulter les documents
			//                 </ReactTooltip>
			//             </div>
			//         </div>
			//     )
			// }
		]
	);
};

export const getGestionSalarieAmianteColumns = (modifierSalarie, archiverSalarie, navigate) => {
	return (
		[
			{
				id: "avatar",
				header: () => null,
				Cell: ({ row }) => {
					return (
						<div className='accountSettings-profilePicture-wrapper mgR-s2'>
							{row.original._id.hasOwnProperty("profilePicture") && row.original._id.profilePicture !== null ?
								<ProfilePicture width="5rem" height="5rem" edit={false} profilePicture={row.original._id.profilePicture} /> :
								<DefaultProfilePicture additionalClassname={"table"} width="4rem" height="4rem" edit={false} />
							}
						</div>
					);
				}
			},
			{
				Header: 'Matricule',
				accessor: '_id.login_matricule'
			},
			{
				Header: 'Nom',
				accessor: '_id.nom'
			},
			{
				Header: 'Prénom',
				accessor: '_id.prenom'
			},
			{
				Header: 'Secteur',
				accessor: '_id.secteur'
			},
			{
				Header: 'Statut',
				accessor: '_id.userRole.statut'
			},
			{
				Header: 'Téléphone',
				accessor: (data) => { return (!data._id.hasOwnProperty("telephone") || data._id.telephone === undefined || data._id.telephone === "" ? 'Aucun' : data._id.telephone); }
			},
			{
				Header: 'Actions',
				Cell: ({ row }) => (
					<div className="table-buton-container">
						<div>
							<button onClick={() => modifierSalarie(row.original)} className='table-modify-button'><EditIcon /></button>
						</div>
						<div>
							<button onClick={() => archiverSalarie(row.original)} className='table-archive-button'><Icon icon="fluent:archive-28-regular" /></button>
						</div>
						<div>
							<button onClick={() => navigate(`profil/${row.original._id._id}`)} className='table-black-button'><Icon icon="mdi:clipboard-user" /></button>
						</div>
					</div>
				)
			}
			// {
			//     Header: 'Actions',
			//     Cell: ({row}) => (
			//         <div className="table-buton-container">
			//             <div>
			//                 <button data-tip data-for={"modifierTip-" + row.original.user.login_matricule} onClick={() => props.modifierSalarie(row.original)} className='table-modify-button'><EditIcon /></button>
			//                 <ReactTooltip backgroundColor="#1E1E1E" offset={{'top': 5}} id={"modifierTip-" + row.original.user.login_matricule} place="bottom" effect="solid">
			//                     Modifier
			//                 </ReactTooltip>
			//             </div>
			//             <div>
			//                 <button data-tip data-for={"archiverTip-" + row.original.user.login_matricule} onClick={() => props.archiverSalarie(row.original)} className='table-archive-button'><Icon icon="fluent:archive-28-regular" /></button>
			//                 <ReactTooltip backgroundColor="#1E1E1E" offset={{'top': 5}} id={"archiverTip-" + row.original.user.login_matricule} place="bottom" effect="solid">
			//                     Archiver
			//                 </ReactTooltip>
			//             </div>
			//             <div>
			//                 <button data-tip data-for={"moreTip-" + row.original.user.login_matricule} onClick={() => navigate(`profil/${row.original.user._id}`)} className='table-black-button'><Icon icon="bx:file-blank" /></button>
			//                 <ReactTooltip backgroundColor="#1E1E1E" offset={{'top': 5}} id={"moreTip-" + row.original.user.login_matricule} place="bottom" effect="solid">
			//                     Consulter les documents
			//                 </ReactTooltip>
			//             </div>
			//         </div>
			//     )
			// }
		]
	);
};

// TABLEAU MATERIAUX
export const getMateriauxColumns = (modifierMateriaux, supprimerMateriaux) => {
	return [
		{
			Header: "Nom",
			accessor: "libelle_mat",
		},
		{
			Header: "Provenances",
			Cell: ({ row }) => {
				let provenancesFormat = [];
				for (const provenance of row.original.provenances) {
					provenancesFormat.push(provenance._id.libelle_lieu);
				}
				return (
					<div>
						<p>{provenancesFormat.join(", ")}</p>
					</div>
				);
			}
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => modifierMateriaux(row.original)}
						className="table-modify-button"
					>
						<EditIcon />
					</button>
					<button
						onClick={() => supprimerMateriaux(row.original)}
						className="table-delete-button"
					>
						<SupprimerIcon />
					</button>
				</div>
			)
		}
	];
};

// TABLEAU RELEVE HEURE
export const getReleveHeureColumns = (navigate) => {
	return [
		{
			Header: "Numéro semaine",
			accessor: "numero_semaine",
		},
		{
			Header: "Année",
			accessor: "annee",
		},
		{
			Header: "Chef de chantier",
			accessor: "chef_chantier._id.nom",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					<p>
						{row.original.chef_chantier._id.nom}{" "}
						{row.original.chef_chantier._id.prenom}
					</p>
				</div>
			),
		},
		{
			Header: "Statut",
			accessor: "statut_horaire_semaine",
			Cell: ({ row }) =>
				row.original.statut_horaire_semaine === 0 ? (
					<div className="dotAndText">
						<Icon className="yellowDot dot" icon="carbon:dot-mark" />
						<p>En cours</p>
					</div>
				) : (
					<div className="dotAndText">
						<Icon className="greenDot dot" icon="carbon:dot-mark" />
						<p>Terminé</p>
					</div>
				),
		},
		{
			Header: "Actions",
			disableFilters: true,
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => navigate(`/releve-heure/releve/${row.original._id}`)}
						className="select-semaine-button default-radius"
					>
						<Icon icon="fluent:clipboard-search-24-regular" />
					</button>
				</div>
			),
		},
	];
};



export const getProcessusColumns = (
	openSupprimerProcessusModal,
	openModifierProcessus,
	navigate
) => {
	return [
		{
			Header: "Code processus",
			accessor: "codeProcessus",
		},
		{
			Header: "Matériaux",
			disableFilters: true,
			Cell: ({ row }) => (
				<p>
					{row.original.hasOwnProperty("materiaux") &&
						formatMateriaux(row.original.materiaux)}
				</p>
			),
		},
		{
			Header: "Niveau d'empoussièrement",
			accessor: "niveauEmpoussierement",
			Cell: ({ row }) => <p>Niveau {row.original.niveauEmpoussierement}</p>,
		},
		{
			Header: "Techniques et modes opératoires",
			disableFilters: true,
			Cell: ({ row }) => (
				<p>
					{row.original.hasOwnProperty("techniqueModesOperatoires") &&
						formatTechniqueModeOperatoire(
							row.original.techniqueModesOperatoires
						)}
				</p>
			),
		},
		{
			Header: "Moyens de protection collective",
			accessor: "protectionCollective",
		},
		{
			Header: "Zone",
			accessor: "zone"
		},
		{
			Header: "Estimation concentration",
			accessor: "estimationConcentration",
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => openModifierProcessus(row.original)}
						className="table-modify-button"
					>
						<EditIcon />
					</button>
					<button
						onClick={() => navigate(`/amiante/processus/${row.original._id}`)}
						className="select-semaine-button default-radius mgR-s2"
					>
						<Icon icon="material-symbols:manage-search-rounded" />
					</button>
					<button
						onClick={() => openSupprimerProcessusModal(row.original)}
						className="table-delete-button"
					>
						<SupprimerIcon />
					</button>
				</div>
			),
		},
	];
};

export const getReleveExpositionsColumns = (navigate, openDeleteVacation) => {
	return [
		{
			Header: "Code chantier",
			accessor: "chantier.codeChantier"
		},
		{
			Header: "Nom du chantier",
			accessor: "chantier._id.libelle_lieu"
		},
		{
			Header: "Encadrant de chantier",
			accessor: "chefChantier._id.login_matricule"
		},
		{
			Header: "Nombre de vacations",
			Cell: ({ row }) => {
				let nombreVacation = 0;
				for (const entreeSortie of row.original.entreeSortieZoneSalarie) {
					nombreVacation += entreeSortie.entreeSortie.length;
				}
				return (
					<p>
						{nombreVacation}
					</p>
				);
			},
		},
		{
			Header: "Date",
			accessor: "dateVacation",
			Cell: ({ row }) => {
				return (
					<p>
						{format(new Date(row.original.dateVacation), "dd/MM/yyyy")}
					</p>
				);
			}
		},
		{
			Header: "Statut",
			Cell: ({ row }) => {
				return (
					row.original.signatureChef === null ? (
						<div className="dotAndText">
							<Icon className="yellowDot dot" icon="carbon:dot-mark" />
							<p>En cours</p>
						</div>
					) : (
						<div className="dotAndText">
							<Icon className="greenDot dot" icon="carbon:dot-mark" />
							<p>Terminé</p>
						</div>
					)
				);
			}
		},
		{
			Header: "Action",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => navigate(`/amiante/releve-exposition/${row.original._id}`)}
						className="select-semaine-button default-radius"
					>
						<Icon icon="la:user-clock" />
					</button>
					<button
						onClick={() => openDeleteVacation(row.original._id)}
						className="table-delete-button mgL-s2"
					>
						<SupprimerIcon />
					</button>
				</div>
			),
		}
	];
};

export const getProfilChantierProcessusColumns = (
	navigate,
	supprimerProcessus
) => {
	return [
		{
			Header: "Code processus",
			accessor: "codeProcessus",
		},
		{
			Header: "Matériaux",
			disableFilters: true,
			Cell: ({ row }) => (
				<p>
					{row.original.hasOwnProperty("materiaux") &&
						formatMateriaux(row.original.materiaux)}
				</p>
			),
		},
		{
			Header: "Niveau d'empoussièrement",
			accessor: "niveauEmpoussierement",
			Cell: ({ row }) => <p>Niveau {row.original.niveauEmpoussierement}</p>,
		},
		{
			Header: "Techniques et modes opératoires",
			disableFilters: true,
			Cell: ({ row }) => (
				<p>
					{row.original.hasOwnProperty("techniqueModesOperatoires") &&
						formatTechniqueModeOperatoire(
							row.original.techniqueModesOperatoires
						)}
				</p>
			),
		},
		{
			Header: "Moyens de protection collective",
			accessor: "protectionCollective",
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => navigate(`/amiante/processus/${row.original._id}`)}
						className="select-semaine-button default-radius mgR-s2"
					>
						<Icon icon="material-symbols:manage-search-rounded" />
					</button>
					<button
						onClick={() => supprimerProcessus(row.original._id)}
						className="table-delete-button"
					>
						<SupprimerIcon />
					</button>
				</div>
			),
		},
	];
};

// document Salarie

export const getDocumentsSalarieColumns = (
	openSupprimerDocument,
	openEditDocument
) => {

	return [
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div className="tableTdContainer">{iconExtension(row.original.file.filename)}</div>
			)
		},
		{
			Header: "Nom",
			accessor: "titre",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{row.original.titre}.{row.original.file.filename.split(".").pop().toLowerCase()}
				</div>
			)
		},
		{
			Header: "Taille",
			accessor: "file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original.file.length)}</p>
				</div>
			),
		},
		{
			Header: "Dernière modification",
			accessor: "file.uploadDate",
			Cell: ({ row }) => (
				<div>
					<p>il y a {getLastModified(row.original.file.uploadDate)}</p>
				</div>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					<DocumentSalarieDotMenu
						document={row.original}
						openSupprimerDocument={(doc) => openSupprimerDocument(doc)}
						openEditDocument={(doc) => openEditDocument(doc)}
					/>
				</div>
			)
		}
	];
};

export const getDocumentsVehiculeColumns = (
	openSupprimerDocument,
	openEditDocumentVehicule
) => {

	return [
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div className="tableTdContainer">{iconExtension(row.original.file.filename)}</div>
			)
		},
		{
			Header: "Nom",
			accessor: "titre",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{row.original.titre}.{row.original.file.filename.split(".").pop().toLowerCase()}
				</div>
			)
		},
		{
			Header: "Taille",
			accessor: "file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original.file.length)}</p>
				</div>
			),
		},
		{
			Header: "Dernière modification",
			accessor: "file.uploadDate",
			Cell: ({ row }) => (
				<div>
					<p>il y a {getLastModified(row.original.file.uploadDate)}</p>
				</div>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					<DocumentSalarieDotMenu
						document={row.original}
						openSupprimerDocument={(doc) => openSupprimerDocument(doc)}
						openEditDocument={(doc) => openEditDocumentVehicule(doc)}
					/>
				</div>
			)
		}
	];
};

// FORMATIONS SUIVI

const formationsSuiviCustomSort = (rowA, rowB, id) => {
	let aRawData = rowA.values[id];
	let a = "";
	if (aRawData.annee !== "" && aRawData.annee !== null) {
		a += aRawData.annee;
	}
	if (aRawData.mois !== "" && aRawData.mois !== null) {
		a += aRawData.mois;
	}
	if (aRawData.jour !== "" && aRawData.jour !== null) {
		a += aRawData.jour;
	}
	let bRawData = rowB.values[id];
	let b = "";
	if (bRawData.annee !== "" && bRawData.annee !== null) {
		b += bRawData.annee;
	}
	if (bRawData.mois !== "" && bRawData.mois !== null) {
		b += bRawData.mois;
	}
	if (bRawData.jour !== "" && bRawData.jour !== null) {
		b += bRawData.jour;
	}

	let aInt = parseInt(a);
	let bInt = parseInt(b);

	if (aInt > bInt) return 1;
	if (aInt < bInt) return -1;
	return 0;

};

export const getFormationsSuiviColumns = (
	openModifierFormationModal,
	openSupprimerModal
) => {
	return [
		{
			Header: "Nom de la formation",
			accessor: "nomFormation"
		},
		{
			Header: "Formation pour",
			accessor: "formationPour"
		},
		{
			Header: "Formation à renouveler dans",
			accessor: "renouvellement",
			sortType: formationsSuiviCustomSort,
			Cell: ({ row }) => {
				let anneMoisJourRenouvellement = "";
				if (row.original.renouvellement.annee !== "" && row.original.renouvellement.annee !== null) {
					anneMoisJourRenouvellement += `${row.original.renouvellement.annee} an${row.original.renouvellement.annee > 1 ? "s" : ""}`;
				}
				if (row.original.renouvellement.mois !== "" && row.original.renouvellement.mois !== null) {
					if (anneMoisJourRenouvellement !== "") {
						anneMoisJourRenouvellement += " et ";
					}
					anneMoisJourRenouvellement += `${row.original.renouvellement.mois} mois`;
				}
				if (row.original.renouvellement.jour !== "" && row.original.renouvellement.jour !== null) {
					if (anneMoisJourRenouvellement !== "") {
						anneMoisJourRenouvellement += " et ";
					}
					anneMoisJourRenouvellement += `${row.original.renouvellement.jour} jour${row.original.renouvellement.jour > 1 ? "s" : ""}`;
				}
				return (
					<div>
						<p>{anneMoisJourRenouvellement === "" ? "formation non renouvelable" : anneMoisJourRenouvellement}</p>
					</div>
				);
			}
		},
		{
			Header: "Mettre un rappel",
			accessor: "signalerUrgence",
			sortType: formationsSuiviCustomSort,
			Cell: ({ row }) => {
				let anneMoisJourSignalerUrgence = "";
				if (row.original.signalerUrgence.annee !== "" && row.original.signalerUrgence.annee !== null) {
					anneMoisJourSignalerUrgence += `${row.original.signalerUrgence.annee} an${row.original.signalerUrgence.annee > 1 ? "s" : ""}`;
				}
				if (row.original.signalerUrgence.mois !== "" && row.original.signalerUrgence.mois !== null) {
					if (anneMoisJourSignalerUrgence !== "") {
						anneMoisJourSignalerUrgence += " et ";
					}
					anneMoisJourSignalerUrgence += `${row.original.signalerUrgence.mois} mois`;
				}
				if (row.original.signalerUrgence.jour !== "" && row.original.signalerUrgence.jour !== null) {
					if (anneMoisJourSignalerUrgence !== "") {
						anneMoisJourSignalerUrgence += " et ";
					}
					anneMoisJourSignalerUrgence += `${row.original.signalerUrgence.jour} jour${row.original.signalerUrgence.jour > 1 ? "s" : ""}`;
				}
				return (
					<div>
						<p>{anneMoisJourSignalerUrgence === "" ? "aucun rappel programmé" : anneMoisJourSignalerUrgence}</p>
					</div>
				);
			}
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => openModifierFormationModal(row.original)}
						className="table-modify-button"
					>
						<EditIcon />
					</button>
					<button
						onClick={() => openSupprimerModal(row.original)}
						className="table-delete-button"
					>
						<SupprimerIcon />
					</button>
				</div>
			)
		}
	];
};

export const getEntretiensSuiviColumns = (
	openModifierEntretienSuiviModal,
	openSupprimerModalEntretien
) => {
	return [
		{
			Header: "Nom de l'entretien / suivi",
			accessor: "nomEntretien"
		},
		{
			Header: "Entretien à renouveler dans",
			accessor: "renouvellement",
			sortType: formationsSuiviCustomSort,
			Cell: ({ row }) => {
				let anneMoisJourRenouvellement = "";
				if (row.original.renouvellement.annee !== "" && row.original.renouvellement.annee !== null) {
					anneMoisJourRenouvellement += `${row.original.renouvellement.annee} an${row.original.renouvellement.annee > 1 ? "s" : ""}`;
				}
				if (row.original.renouvellement.mois !== "" && row.original.renouvellement.mois !== null) {
					if (anneMoisJourRenouvellement !== "") {
						anneMoisJourRenouvellement += " et ";
					}
					anneMoisJourRenouvellement += `${row.original.renouvellement.mois} mois`;
				}
				if (row.original.renouvellement.jour !== "" && row.original.renouvellement.jour !== null) {
					if (anneMoisJourRenouvellement !== "") {
						anneMoisJourRenouvellement += " et ";
					}
					anneMoisJourRenouvellement += `${row.original.renouvellement.jour} jour${row.original.renouvellement.jour > 1 ? "s" : ""}`;
				}
				return (
					<div>
						<p>{anneMoisJourRenouvellement === "" ? "entretien non renouvelable" : anneMoisJourRenouvellement}</p>
					</div>
				);
			}
		},
		{
			Header: "Mettre un rappel",
			accessor: "signalerUrgence",
			sortType: formationsSuiviCustomSort,
			Cell: ({ row }) => {
				let anneMoisJourSignalerUrgence = "";
				if (row.original.signalerUrgence.annee !== "" && row.original.signalerUrgence.annee !== null) {
					anneMoisJourSignalerUrgence += `${row.original.signalerUrgence.annee} an${row.original.signalerUrgence.annee > 1 ? "s" : ""}`;
				}
				if (row.original.signalerUrgence.mois !== "" && row.original.signalerUrgence.mois !== null) {
					if (anneMoisJourSignalerUrgence !== "") {
						anneMoisJourSignalerUrgence += " et ";
					}
					anneMoisJourSignalerUrgence += `${row.original.signalerUrgence.mois} mois`;
				}
				if (row.original.signalerUrgence.jour !== "" && row.original.signalerUrgence.jour !== null) {
					if (anneMoisJourSignalerUrgence !== "") {
						anneMoisJourSignalerUrgence += " et ";
					}
					anneMoisJourSignalerUrgence += `${row.original.signalerUrgence.jour} jour${row.original.signalerUrgence.jour > 1 ? "s" : ""}`;
				}
				return (
					<div>
						<p>{anneMoisJourSignalerUrgence === "" ? "aucun rappel programmé" : anneMoisJourSignalerUrgence}</p>
					</div>
				);
			}
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => openModifierEntretienSuiviModal(row.original)}
						className="table-modify-button"
					>
						<EditIcon />
					</button>
					<button
						onClick={() => openSupprimerModalEntretien(row.original)}
						className="table-delete-button"
					>
						<SupprimerIcon />
					</button>
				</div>
			)
		}
	];
};

export const getFormationsValideesColumns = (
	viewFile,
	deleteFormation,
	openDeleteFormationModal,
	editFormation,
	openModalFormationSalarie
) => {
	return [
		{
			Header: "Date formation",
			accessor: "dateFormation",
			Cell: ({ row }) => (
				<p>{row.original.hasOwnProperty("dateFormation") && row.original.dateFormation !== null ? `Le ${format(new Date(row.original.dateFormation), "dd/MM/yyyy")}` : ""}</p>
			)
		},
		{
			Header: "Date expiration",
			accessor: "dateExpiration",
			Cell: ({ row }) => (
				<p>{row.original.hasOwnProperty("dateExpiration") && row.original.dateExpiration !== null ? `Le ${format(new Date(row.original.dateExpiration), "dd/MM/yyyy")}` : ""}</p>
			)
		},
		{
			Header: "Organisme de formation",
			accessor: "organismeFormation",
		},
		{
			Header: "Document",
			accessor: "document.titre",
			Cell: ({ row }) => (
				row.original.hasOwnProperty("document") ?
					<p className="tableFormationDocument">{iconExtension(row.original.document.file.filename)} {row.original.document.titre}.{row.original.document.file.filename.split(".")[row.original.document.file.filename.split(".").length - 1]}</p>
					:
					<p>Aucun document</p>
			)
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					{
						row.original.hasOwnProperty("document") && <button
							onClick={() => viewFile(row.original.document._id)}
							className="table-modify-button"
						>
							<Icon icon="mdi:file-document-outline" />
						</button>
					}
					<button
						onClick={() => openModalFormationSalarie(row.original, editFormation)}
						className="select-semaine-button default-radius"
					>
						<Icon icon="ic:outline-edit" />
					</button>
					<button
						onClick={() => openDeleteFormationModal(row.original, deleteFormation)}
						className="table-delete-button mgL-s2"
					>
						<SupprimerIcon />
					</button>
				</div>
			),
		}
	];
};

export const suiviMaterielColumn = (
	openSupprimerModal,
	openModifierMaterielModal,
	navigate
) => {
	return [
		{
			Header: 'Nom',
			accessor: 'nom'
		},
		{
			Header: 'Marque',
			accessor: 'marque'
		},
		{
			Header: 'N° de série',
			accessor: 'numero_de_serie'
		},
		{
			Header: 'Référence interne',
			accessor: 'ref_interne'
		},
		{
			Header: 'Date du dernier contrôle',
			accessor: date => {
				if (date.date_dernier_controle !== undefined && date.date_dernier_controle !== null) {
					return new Date(date.date_dernier_controle).toLocaleDateString("fr-FR");
					//return new Intl.DateTimeFormat('fr-FR').format(date.date_dernier_controle);
				};
				return "Aucune";
			}
		},
		{
			Header: 'Date prochain controle',
			accessor: date => {
				if (date.date_prochain_controle !== undefined && date.date_prochain_controle !== null) {
					if (new Date(date.date_prochain_controle) < new Date()) {
						return <span style={{ color: "#D8556B" }}>{new Date(date.date_prochain_controle).toLocaleDateString("fr-FR")}</span>;
					}
					return new Date(date.date_prochain_controle).toLocaleDateString("fr-FR");
				};
				return "Aucune";
			}
		},
		// {
		// 	Header: 'Actions',
		// 	Cell: ({row}) => ( 
		// 		<div className="table-buton-container">
		// 			<button onClick={() => console.log(row.original)} className='table-modify-button'><EditIcon /></button> 
		// 			<button onClick={() => console.log(row.original)} className='table-delete-button' style={{marginRight:"0.8rem"}}><SupprimerIcon /></button>
		// 			<DocumentDotMenu 
		// 				documentSaveRoute={"documentsEPC"} 
		// 				documentId={row.original.IDdocument} 
		// 				extension={row.original.document !== undefined ? row.original.document.split(".") : undefined} 
		// 				fileName={`${row.original.document !== undefined ? row.original.document : undefined}`}
		// 				openUploadDocument={() => console.log({refId: row.original._id})}
		// 				openSupprimerDocument={() => console.log(row.original.IDdocument)}
		// 				openRemplacerDocument={() => console.log({IDdocument: row.original.IDdocument, refId: row.original._id})}
		// 			/>
		// 			{/* <button onClick={() => props.historiqueEquipementEpc(`historique/${row.original.gestion_suivi}`)} className='select-semaine-button default-radius'><Icon icon="fluent:clipboard-search-24-regular" /></button>  */}
		// 		</div>
		// 	),
		// },
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button onClick={() => openModifierMaterielModal(row.original)} className='table-modify-button'><EditIcon /></button>
					</div>
					<div>
						<button onClick={() => openSupprimerModal(row.original)} className="table-delete-button mgR-s2">
							<SupprimerIcon />
						</button>
					</div>
					<div>
						<button onClick={() => navigate(`/amiante/suivi-materiel/${row.original._id}`)} className='table-black-button'><Icon icon="heroicons:wrench-screwdriver-20-solid" /></button>
					</div>
				</div>
			)
		}
	];
};

export const getDocumentsMaterielsColumns = (
	openEditDocument,
	openSupprimerDocument

) => {
	return [
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div className="tableTdContainer">{iconExtension(row.original.file.filename)}</div>
			)
		},
		{
			Header: "Nom",
			accessor: "titre",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{row.original.titre}.{row.original.file.filename.split(".").pop().toLowerCase()}
				</div>
			)
		},
		{
			Header: "Taille",
			accessor: "file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original.file.length)}</p>
				</div>
			),
		},
		{
			Header: "Dernière modification",
			accessor: "file.uploadDate",
			Cell: ({ row }) => (
				<div>
					<p>il y a {getLastModified(row.original.file.uploadDate)}</p>
				</div>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					<DocumentMaterielDotMenu
						document={row.original}
						openSupprimerDocument={(doc) => openSupprimerDocument(doc)}
						openEditDocument={(doc) => openEditDocument(doc)}
					/>
				</div>
			)
		}
	];
};

// TABLEAU CHANTIER
export const getChantiersAmianteColumns = (
	modifierChantier,
	archiverChantier,
	navigate
) => {
	return [
		{
			Header: "Code",
			accessor: "codeChantier",
		},
		{
			Header: "Nom",
			accessor: "_id.libelle_lieu",
		},
		{
			Header: "Zone",
			accessor: "zone.libelle_zone",
		},
		{
			Header: "Adresse",
			Cell: ({ row }) => (
				<div>
					<p>
						{row.original._id.adresse.codePostal
							? row.original._id.adresse.codePostal + " "
							: ""}
						{row.original._id.adresse.ville
							? row.original._id.adresse.ville + ", "
							: ""}
						{row.original._id.adresse.lieuExecution}
					</p>
				</div>
			),
		},
		{
			Header: "Secteur",
			accessor: "secteur",
		},
		{
			Header: "Statut",
			Cell: ({ row }) =>
				row.original.statut_chantier === 0 ? (
					<div className="dotAndText">
						<Icon className="yellowDot dot" icon="carbon:dot-mark" />
						<p>En cours</p>
					</div>
				) : (
					<div className="dotAndText">
						<Icon className="greenDot dot" icon="carbon:dot-mark" />
						<p>Terminé</p>
					</div>
				),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"modifierTip-" + row.original._id._id}
							onClick={() => modifierChantier(row.original)}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"modifierTip-" + row.original._id._id}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"archiverTip-" + row.original._id._id}
							onClick={() => archiverChantier(row.original)}
							className="table-archive-button"
						>
							<Icon icon="fluent:archive-28-regular" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"archiverTip-" + row.original._id._id}
							place="bottom"
							effect="solid"
						>
							Archiver
						</ReactTooltip>
					</div>
					<div>
						<button
							onClick={() =>
								navigate(
									`/amiante/chantiers/profil/${row.original._id._id}`
								)
							}
							className="select-semaine-button default-radius"
						>
							<Icon icon="material-symbols:manage-search-rounded" />
						</button>
					</div>
				</div>
			),
		},
	];
};

export const geNewProfilChantierProcessusColumns = (
	navigate,
	supprimerProcessus
) => {
	return [
		{
			Header: "Code processus",
			accessor: "codeProcessus",
		},
		{
			Header: "Matériaux",
			disableFilters: true,
			Cell: ({ row }) => (
				<p>
					{row.original.hasOwnProperty("materiaux") &&
						formatMateriaux(row.original.materiaux)}
				</p>
			),
		},
		{
			Header: "Niveau d'empoussièrement",
			accessor: "niveauEmpoussierement",
			Cell: ({ row }) => <p>Niveau {row.original.niveauEmpoussierement}</p>,
		},
		{
			Header: "Techniques et modes opératoires",
			disableFilters: true,
			Cell: ({ row }) => (
				<p>
					{row.original.hasOwnProperty("techniqueModesOperatoires") &&
						formatTechniqueModeOperatoire(
							row.original.techniqueModesOperatoires
						)}
				</p>
			),
		},
		{
			Header: "Moyens de protection collective",
			accessor: "protectionCollective",
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<button
						onClick={() => navigate(`/amiante/processus/${row.original._id}`)}
						className="select-semaine-button default-radius mgR-s2"
					>
						<Icon icon="material-symbols:manage-search-rounded" />
					</button>
					<button
						onClick={() => supprimerProcessus(row.original)}
						className="table-delete-button"
					>
						<SupprimerIcon />
					</button>
				</div>
			),
		}
	];
};

export const getDocumentsAmianteColumns = (
	openSupprimerDocumentAmianteModal,
	viewFile,
	openModifierDocumentAmiante
) => {
	return [
		{
			Header: "Nom",
			accessor: "_id.titre",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{iconExtension(row.original._id.file.filename)}
					<p>{row.original._id.titre}</p>
				</div>
			),
		},
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div>
					<p>
						{row.original._id.file.filename
							.split(".")
							.pop()
							.toLowerCase()}
					</p>
				</div>
			),
		},
		{
			Header: "Taille",
			accessor: "_id.file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original._id.file.length, 0)}</p>
				</div>
			),
		},
		{
			Header: "Type du document",
			accessor: "typeDocumentAmiante",
			Cell: ({ row }) => {
				if (row.original.typeDocumentAmiante !== "") {
					return (
						<p>{row.original.typeDocumentAmiante}</p>
					);
				}
				return (
					<p>Aucun</p>
				);
			}
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"modifierTip-" + row.original._id}
							onClick={() =>
								openModifierDocumentAmiante(
									row.original
								)
							}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"modifierTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div>
						<button
							onClick={() => viewFile(row.original._id._id)}
							className="select-semaine-button default-radius mgR-s2"
						>
							<Icon icon="akar-icons:eye" />
						</button>
					</div>
					<div>
						<button
							data-tip
							data-for={"telechargerTip-" + row.original._id}
							onClick={() =>
								downloadFile(
									row.original._id._id,
									row.original._id.titre
								)
							}
							className="table-archive-button"
						>
							<Icon icon="eva:download-fill" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"telechargerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Télécharger
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"supprimerTip-" + row.original._id}
							// onClick={() =>
							// 	openSupprimerDocumentAmianteModal(
							// 		row.original.baseDocument._id
							// 	)
							// }
							onClick={() =>
								openSupprimerDocumentAmianteModal(
									row.original._id._id
								)
							}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"supprimerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
				</div>
			),
		}
	];
};

export const getDocumentsAmianteColumnsChef = (
	viewFile
) => {
	return [
		{
			Header: "Nom",
			accessor: "_id.titre",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{iconExtension(row.original._id.file.filename)}
					<p>{row.original._id.titre}</p>
				</div>
			),
		},
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div>
					<p>
						{row.original._id.file.filename
							.split(".")
							.pop()
							.toLowerCase()}
					</p>
				</div>
			),
		},
		{
			Header: "Taille",
			accessor: "_id.file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original._id.file.length, 0)}</p>
				</div>
			),
		},
		{
			Header: "Type du document",
			accessor: "typeDocumentAmiante",
			Cell: ({ row }) => {
				if (row.original.typeDocumentAmiante !== "") {
					return (
						<p>{row.original.typeDocumentAmiante}</p>
					);
				}
				return (
					<p>Aucun</p>
				);
			}
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							onClick={() => viewFile(row.original._id._id)}
							className="select-semaine-button default-radius mgR-s2"
						>
							<Icon icon="akar-icons:eye" />
						</button>
					</div>
					<div>
						<button
							data-tip
							data-for={"telechargerTip-" + row.original._id}
							onClick={() =>
								downloadFile(
									row.original._id._id,
									row.original._id.titre
								)
							}
							className="table-archive-button"
						>
							<Icon icon="eva:download-fill" />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"telechargerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Télécharger
						</ReactTooltip>
					</div>
				</div>
			),
		}
	];
};

export const epiColumns = (
	openEditEpi,
	openSupprimerModal
) => {
	return [
		{
			Header: 'Nom',
			accessor: 'libelleEquipement'
		},
		{
			Header: 'Code',
			accessor: 'codeEquipement'
		},
		{
			Header: 'Sigle',
			accessor: 'shortLibelleEquipement'
		},
		{
			Header: 'Facteur de protection',
			accessor: 'fpa',
			Cell: ({ row }) => (
				<p>{row.original.fpa ? row.original.fpa : "N/A"}</p>
			)
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"modifierTip-" + row.original._id}
							onClick={() =>
								openEditEpi(
									row.original
								)
							}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"modifierTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div>
						<button
							data-tip
							data-for={"supprimerTip-" + row.original._id}
							// onClick={() =>
							// 	openSupprimerDocumentAmianteModal(
							// 		row.original.baseDocument._id
							// 	)
							// }
							onClick={() =>
								openSupprimerModal(
									row.original
								)
							}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"supprimerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
				</div>
			),
		}
	];
};

export const vehiculesColumns = (
	openEditVehicule,
	openSupprimerModal,
	navigate
) => {
	return [
		{
			Header: 'Catégorie',
			accessor: 'categorie'
		},
		{
			Header: 'Type du véhicule',
			accessor: 'typeVehicule'
		},
		{
			Header: 'Immatriculation',
			accessor: 'immatriculation'
		},
		{
			Header: 'Numéro parc',
			accessor: 'numeroParc'
		},
		{
			Header: 'Marque/modèle',
			accessor: 'marque'
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"modifierTip-" + row.original._id}
							onClick={() =>
								openEditVehicule(
									row.original
								)
							}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"modifierTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div className="mgR-s2">
						<button
							data-tip
							data-for={"supprimerTip-" + row.original._id}
							// onClick={() =>
							// 	openSupprimerDocumentAmianteModal(
							// 		row.original.baseDocument._id
							// 	)
							// }
							onClick={() =>
								openSupprimerModal(
									row.original
								)
							}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"supprimerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
					<div>
						<button
							onClick={() =>
								navigate(
									`/transport/vehicules/profil/${row.original._id}`
								)
							}
							className="select-semaine-button default-radius"
						>
							<Icon icon="material-symbols:manage-search-rounded" />
						</button>
					</div>
				</div>
			),
		}
	];
};

export const siteColumns = (
	openModifierSiteModal,
	openSupprimerModal,
	navigate
) => {
	return [
		{
			Header: 'Nom',
			accessor: "_id.libelle_lieu"
		},
		{
			Header: "Adresse",
			Cell: ({ row }) => {
				let adresse = [];
				if (row.original._id.adresse.codePostal) {
					adresse.push(row.original._id.adresse.codePostal);
				}
				if (row.original._id.adresse.ville) {
					adresse.push(row.original._id.adresse.ville);
				}
				if (row.original._id.adresse.lieuExecution) {
					adresse.push(row.original._id.adresse.lieuExecution);
				}
				return (
					<div>
						<p>
							{adresse.join(", ")}
						</p>
					</div>
				);
			},
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button
							data-tip
							data-for={"modifierTip-" + row.original._id}
							onClick={() =>
								openModifierSiteModal(
									row.original
								)
							}
							className="table-modify-button"
						>
							<EditIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"modifierTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Modifier
						</ReactTooltip>
					</div>
					<div className="mgR-s2">
						<button
							onClick={() =>
								navigate(
									`/gestion-de-donnees/sites/profil/${row.original._id._id}`
								)
							}
							className="select-semaine-button default-radius"
						>
							<Icon icon="material-symbols:manage-search-rounded" />
						</button>
					</div>
					<div>
						<button
							data-tip
							data-for={"supprimerTip-" + row.original._id}
							onClick={() =>
								openSupprimerModal(
									row.original
								)
							}
							className="table-delete-button"
						>
							<SupprimerIcon />
						</button>
						<ReactTooltip
							backgroundColor="#1E1E1E"
							offset={{ top: 5 }}
							id={"supprimerTip-" + row.original._id}
							place="bottom"
							effect="solid"
						>
							Supprimer
						</ReactTooltip>
					</div>
					{/* <div>
						<button
							onClick={() =>
								navigate(
									`/transport/vehicules/profil/${row.original._id}`
								)
							}
							className="select-semaine-button default-radius"
						>
							<Icon icon="material-symbols:manage-search-rounded" />
						</button>
					</div> */}
				</div>
			),
		}
	];
};

export const getHistoriqueStockageMateriauxColumns = (
) => {
	return [
		{
			Header: "Date de modification",
			accessor: "dateModification",
			Cell: ({ row }) => (
				<p>{`Le ${format(new Date(row.original.dateModification), "dd/MM/yyyy")}`}</p>
			)
		},
		{
			Header: "Quantité",
			accessor: "value"
		},
		{
			Header: "Modifié par",
			accessor: "utilisateur._id",
			Cell: ({ row }) => (
				<p>{row.original.utilisateur.nom} {row.original.utilisateur.prenom}</p>
			)
		},
		{
			Header: "Commentaire",
			accessor: "commentaire"
		}
		// {
		// 	Header: "Date formation",
		// 	accessor: "dateFormation",
		// 	Cell: ({ row }) => (
		// 		<p>{row.original.hasOwnProperty("dateFormation") && row.original.dateFormation !== null ? `Le ${format(new Date(row.original.dateFormation), "dd/MM/yyyy")}` : ""}</p>
		// 	)
		// }
		// {
		// 	Header: "Actions",
		// 	Cell: ({ row }) => (
		// 		<div className="table-buton-container">
		// 			{
		// 				row.original.hasOwnProperty("document") && <button
		// 				onClick={() => viewFile(row.original.document._id)}
		// 				className="table-modify-button"
		// 				>
		// 					<Icon icon="mdi:file-document-outline" />
		// 				</button>
		// 			}
		// 			<button
		// 				onClick={() => openModalFormationSalarie(row.original, editFormation)}
		// 				className="select-semaine-button default-radius"
		// 			>
		// 				<Icon icon="ic:outline-edit" />
		// 			</button>
		// 			<button
		// 				onClick={() => openDeleteFormationModal(row.original, deleteFormation)}
		// 				className="table-delete-button mgL-s2"
		// 			>
		// 				<SupprimerIcon />
		// 			</button>
		// 		</div>
		// 	),
		// }
	];
};

export const getDocumentsProcessusColumns = (
	openEditDocumentProcessus,
	openSupprimerDocumentProcessus
) => {
	return [
		{
			Header: "Format",
			Cell: ({ row }) => (
				<div className="tableTdContainer">{iconExtension(row.original._id.file.filename)}</div>
			)
		},
		{
			Header: "Nom",
			accessor: "_id.titre",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{row.original._id.titre}.{row.original._id.file.filename.split(".").pop().toLowerCase()}
				</div>
			)
		},
		{
			Header: "Type de document",
			accessor: "typeDocument",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					{row.original.typeDocumentProcessus}
				</div>
			)
		},
		{
			Header: "Taille",
			accessor: "_id.file.length",
			Cell: ({ row }) => (
				<div>
					<p>{formatBytes(row.original._id.file.length)}</p>
				</div>
			),
		},
		{
			Header: "Dernière modification",
			accessor: "_id.file.uploadDate",
			Cell: ({ row }) => (
				<div>
					<p>il y a {getLastModified(row.original._id.file.uploadDate)}</p>
				</div>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="tableTdContainer">
					<DocumentMaterielDotMenu
						document={{ ...row.original._id, typeDocumentProcessus: row.original.typeDocumentProcessus }}
						openSupprimerDocument={(doc) => openSupprimerDocumentProcessus(doc)}
						openEditDocument={(doc) => openEditDocumentProcessus(doc)}
					/>
				</div>
			)
		}
	];
};

export const getHistoriqueControlesColumns = (
	openEditControle,
	openDeleteControle
) => {
	return [
		{
			Header: "Date",
			accessor: "dateControle",
			Cell: ({ row }) => (
				<div className="table-buton-container">
					{new Date(row.original).toLocaleDateString("fr-FR")}
				</div>
			)
		},
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button onClick={() => openEditControle(row.original)} className='table-modify-button'><EditIcon /></button>
					</div>
					<div>
						<button onClick={() => openDeleteControle(row.original)} className="table-delete-button mgR-s2">
							<SupprimerIcon />
						</button>
					</div>
				</div>
			)
		}
	];
};

export const getEntretiensEquipementColumns = (
	openDeleteEntretien,
	openEditEntretien,
) => {
	return [
		{
			Header: "Nom de l'entretien",
			accessor: "nomEntretien",
		},
		{
			Header: "Renouvellement",
			accessor: "renouvellement",
			Cell: ({ row }) => {
				let anneMoisJourRenouvellement = "";
				if (row.original.renouvellement.annee !== "" && row.original.renouvellement.annee !== null && row.original.renouvellement.annee !== undefined) {
					anneMoisJourRenouvellement += `${row.original.renouvellement.annee} an${row.original.renouvellement.annee > 1 ? "s" : ""}`;
				}
				if (row.original.renouvellement.mois !== "" && row.original.renouvellement.mois !== null && row.original.renouvellement.mois !== undefined) {
					if (anneMoisJourRenouvellement !== "") {
						anneMoisJourRenouvellement += " et ";
					}
					anneMoisJourRenouvellement += `${row.original.renouvellement.mois} mois`;
				}
				if (row.original.renouvellement.jour !== "" && row.original.renouvellement.jour !== null && row.original.renouvellement.jour !== undefined) {
					if (anneMoisJourRenouvellement !== "") {
						anneMoisJourRenouvellement += " et ";
					}
					anneMoisJourRenouvellement += `${row.original.renouvellement.jour} jour${row.original.renouvellement.jour > 1 ? "s" : ""}`;
				}
				return (
					<div>
						<p>{anneMoisJourRenouvellement === "" ? "entretien non renouvelable" : anneMoisJourRenouvellement}</p>
					</div>
				);
			}
		},
		{
			Header: "Rappel",
			accessor: "signalerUrgence",
			Cell: ({ row }) => {
				let anneMoisJoursignalerUrgence = "";
				if (row.original.signalerUrgence.annee !== "" && row.original.signalerUrgence.annee !== null && row.original.signalerUrgence.annee !== undefined) {
					anneMoisJoursignalerUrgence += `${row.original.signalerUrgence.annee} an${row.original.signalerUrgence.annee > 1 ? "s" : ""}`;
				}
				if (row.original.signalerUrgence.mois !== "" && row.original.signalerUrgence.mois !== null && row.original.signalerUrgence.mois !== undefined) {
					if (anneMoisJoursignalerUrgence !== "") {
						anneMoisJoursignalerUrgence += " et ";
					}
					anneMoisJoursignalerUrgence += `${row.original.signalerUrgence.mois} mois`;
				}
				if (row.original.signalerUrgence.jour !== "" && row.original.signalerUrgence.jour !== null && row.original.signalerUrgence.jour !== undefined) {
					if (anneMoisJoursignalerUrgence !== "") {
						anneMoisJoursignalerUrgence += " et ";
					}
					anneMoisJoursignalerUrgence += `${row.original.signalerUrgence.jour} jour${row.original.signalerUrgence.jour > 1 ? "s" : ""}`;
				}
				return (
					<div>
						<p>{anneMoisJoursignalerUrgence === "" ? "entretien non renouvelable" : anneMoisJoursignalerUrgence}</p>
					</div>
				);
			}
		},
		{
			Header: 'Actions',
			Cell: ({ row }) => (
				<div className="table-buton-container">
					<div>
						<button onClick={() => openEditEntretien(row.original)} className='table-modify-button'><EditIcon /></button>
					</div>
					<div>
						<button onClick={() => openDeleteEntretien(row.original)} className="table-delete-button mgR-s2">
							<SupprimerIcon />
						</button>
					</div>
				</div>
			)
		}
	];
};

export const getEntretienValideesColumns = (
	openDeleteEntretienModal,
	openModifierEntretienModal,
	viewFile
) => {
	return [
		{
			Header: "Date entretien",
			accessor: "dateEntretien",
			Cell: ({ row }) => (
				<p>{row.original.hasOwnProperty("dateEntretien") && row.original.dateEntretien !== null ? `Le ${format(new Date(row.original.dateEntretien), "dd/MM/yyyy")}` : "aucune"}</p>
			)
		},
		{
			Header: "Date expiration",
			accessor: "dateExpiration",
			Cell: ({ row }) => (
				<p>{row.original.hasOwnProperty("dateExpiration") && row.original.dateExpiration !== null ? `Le ${format(new Date(row.original.dateExpiration), "dd/MM/yyyy")}` : "jamais"}</p>
			)
		},
		{
			Header: "Document",
			accessor: "document",
			Cell: ({ row }) => (
				<>
					{row.original.hasOwnProperty("document") && row.original.document !== null
						? <p onClick={() => viewFile(row.original.document._id)} className='documentFormation'>{iconExtension(row.original.document.file.filename)} {`${row.original.document.titre}.${row.original.document.file.filename.split(".")[row.original.document.file.filename.split(".").length - 1]}`}</p>
						: <p>aucun</p>}</>
			)
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div className="table-buton-container">

					<button
						onClick={() => openModifierEntretienModal(row.original.dateEntretien, row.original.dateExpiration, row.original._id,
							row.original.hasOwnProperty("document") ? row.original.document : null)}
						className="select-semaine-button default-radius"
					>
						<Icon icon="ic:outline-edit" />
					</button>
					<button
						onClick={() => openDeleteEntretienModal(row.original._id, row.original.dateEntretien, row.original.hasOwnProperty("document") ? row.original.document._id : null)}
						className="table-delete-button mgL-s2"
					>
						<SupprimerIcon />
					</button>
				</div>
			),
		}
	];
};