import React, { useEffect, useState } from "react";
import SearchInput from "../../../../components/ui/SearchInput";
import Modal from "../../../../components/Modal";

import ProfilePicture from "../../../../components/ui/ProfilePicture";
import DefaultProfilePicture from "../../../../components/ui/DefaultProfilePicture";
import PersonnelItem from "./PersonnelItem";
import axiosInstance from "../../../../utils/axiosInstance";
import customToast from "../../../../utils/ToastifyHelper";
import { showError } from "../../../../utils/ErrorHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

const ProfilChantierAmiante_Personnel = (props) => {
    const [searchBarContent, setSearchBarContent] = useState(null);
    const onChangeSearchPersonnel = (e) => {
        setSearchBarContent(e.target.value);
    };
    const [activeSearchValue, setActiveSearchValue] = useState(null);

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setActiveSearchValue(searchBarContent);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setActiveSearchValue(null);
        }
    }, [searchBarContent]);

    const [editMode, setEditMode] = useState(false);
    const [personnelAjouterList, setPersonnelAjouterList] = useState([]);
    const [personnelSupprimerList, setPersonnelSupprimerList] = useState([]);

    const changeEditionMode = () => {
        if (editMode) {
            // quitter le mode edition
            if (personnelAjouterList.length || personnelSupprimerList.length) {
                const patchData = {
                    ajouterSalarieList: personnelAjouterList.map((personnel) => {
                        return personnel._id._id;
                    }),
                    supprimerSalarieList: personnelSupprimerList.map((personnel) => {
                        return personnel._id._id;
                    })
                };
                props.progressBarRef.current.continuousStart();
                axiosInstance.patch(`/chantiers/modifierPersonnel/${props.chantier._id._id}`, patchData).then((result) => {
                    props.progressBarRef.current.complete();
                    customToast.success("La liste du personnel a été modifié");
                    setPersonnelAjouterList([]);
                    setPersonnelSupprimerList([]);
                    props.setPersonnelChantier(sortByNomPrenom(result.data, sortPersonnels.order, false));
                    setEditMode(!editMode);
                }).catch((err) => {
                    props.progressBarRef.current.complete();
                    showError(err);
                });
            }
            else {
                setEditMode(!editMode);
            }
        }
        else {
            setEditMode(!editMode);
        }
    };

    const isDefaultChecked = (salarie) => {
        for (const personnel of props.chantier.personnels) {
            if (personnel._id === salarie._id._id) {
                return true;
            }
        }
        return false;
    };

    const supprimerSalarie = (salarie) => {
        let salarieInListAjouter = false;
        for (const personnel of personnelAjouterList) {
            if (personnel._id._id === salarie._id._id) {
                salarieInListAjouter = true;
                break;
            }
        }
        if (salarieInListAjouter) {
            setPersonnelAjouterList(personnelAjouterList.filter(personnel => {
                return personnel._id._id !== salarie._id._id;
            }));
        }
        else {
            setPersonnelSupprimerList(personnelSupprimerList.concat(salarie));
        }
    };

    const ajouterSalarie = (salarie) => {
        let salarieInListSupprimer = false;
        for (const personnel of personnelSupprimerList) {
            if (personnel._id._id === salarie._id._id) {
                salarieInListSupprimer = true;
                break;
            }
        }
        if (salarieInListSupprimer) {
            setPersonnelSupprimerList(personnelSupprimerList.filter(personnel => {
                return personnel._id._id !== salarie._id._id;
            }));
        }
        else {
            setPersonnelAjouterList(personnelAjouterList.concat(salarie));
        }
    };

    const [sortPersonnels, setSortPersonnels] = useState({ sortProperty: "nom", order: "asc" });

    const onClickSort = (sort) => {
        if (sortPersonnels.sortProperty === sort) {
            if (sortPersonnels.order === "asc") {
                setSortPersonnels({ ...sortPersonnels, order: "desc" });
                if (sortPersonnels.sortProperty === "nom") {
                    props.setPersonnelChantier(sortByNomPrenom(props.chantier.personnels, "desc", false));
                    props.setSalariesAmiante(sortByNomPrenom(props.salariesAmiante, "desc", true));
                }
                else {
                    //formation
                }
            }
            else {
                setSortPersonnels({ ...sortPersonnels, order: "asc" });
                props.setPersonnelChantier(sortByNomPrenom(props.chantier.personnels, "asc", false));
                props.setSalariesAmiante(sortByNomPrenom(props.salariesAmiante, "asc", true));

            }
        }
        else {
            setSortPersonnels({ sortProperty: sort, order: "asc" });
            if (sortPersonnels.sortProperty === "nom") {
                props.setPersonnelChantier(sortByNomPrenom(props.chantier.personnels, "asc", false));
                props.setSalariesAmiante(sortByNomPrenom(props.salariesAmiante, "asc", true));
            }
            else {
                //formation
            }
        }
    };

    const sortByNomPrenom = (data, order, allSalarie) => {
        return data.sort((a, b) => {
            const nomComparison = allSalarie ? a._id.nom.localeCompare(b._id.nom) : a.nom.localeCompare(b.nom);
            if (nomComparison !== 0) {
                return order === 'asc' ? nomComparison : -nomComparison;
            }

            const prenomComparison = allSalarie ? a._id.prenom.localeCompare(b._id.prenom) : a.prenom.localeCompare(b.prenom);
            return order === 'asc' ? prenomComparison : -prenomComparison;
        });
    };


    return (
        <>
            <div className="filterContainer">
                <SearchInput fullwidth={true} placeholder="Rechercher des collaborateurs" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchPersonnel} />
                <button onClick={() => changeEditionMode()} className="btn btn-primary">{editMode ? "Sauvegarder" : props.chantier.personnels.length ? "Modifier le personnel" : "Ajouter le personnel"}</button>
            </div>
            <div className="container-basic mgB-s2 small-padding">
                <div style={{
                    display: "flex"
                }}>
                    <div onClick={() => onClickSort("nom")} style={{ width: "40%", display: "flex" }}>
                        <p className="pdR-s3" style={{ fontWeight: "700", fontSize: "1.5rem", cursor: "pointer" }}>Nom</p>
                        <div style={{ position: "relative", cursor: "pointer", opacity: sortPersonnels.sortProperty === "nom" ? 1 : 0 }}>
                            <FontAwesomeIcon style={{ position: "absolute", top: 0, fontSize: "1.7rem", color: sortPersonnels.sortProperty === "nom" && sortPersonnels.order === "desc" ? "#d13852" : "#1E1E1E" }} icon={faCaretUp} />
                            <FontAwesomeIcon style={{ position: "absolute", top: "8px", fontSize: "1.7rem", color: sortPersonnels.sortProperty === "nom" && sortPersonnels.order === "asc" ? "#d13852" : "#1E1E1E" }} icon={faCaretDown} />
                        </div>
                    </div>
                    <div style={{ width: "60%", display: "flex" }}>
                        <p className="pdR-s3" style={{ fontWeight: "700", fontSize: "1.5rem", cursor: "pointer" }}>Formation</p>
                        <div style={{ position: "relative", cursor: "pointer", opacity: sortPersonnels.sortProperty === "formation" ? 1 : 0 }}>
                            <FontAwesomeIcon style={{ position: "absolute", top: 0, fontSize: "1.7rem", color: sortPersonnels.sortProperty === "formation" && sortPersonnels.order === "desc" ? "#d13852" : "#1E1E1E" }} icon={faCaretUp} />
                            <FontAwesomeIcon style={{ position: "absolute", top: "8px", fontSize: "1.7rem", color: sortPersonnels.sortProperty === "formation" && sortPersonnels.order === "asc" ? "#d13852" : "#1E1E1E" }} icon={faCaretDown} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ flex: 1, overflowY: "auto" }}>
                <div className="personnelList mgB-m1" style={{}}>
                    {
                        editMode ?
                            props.salariesAmiante.map((salarie) => {
                                const isSalarieDefaultChecked = isDefaultChecked(salarie);
                                return (
                                    <PersonnelItem searchValue={activeSearchValue} ajouterSalarie={() => ajouterSalarie(salarie)} supprimerSalarie={() => supprimerSalarie(salarie)} defaultChecked={isSalarieDefaultChecked} personnelAjouterList={personnelAjouterList} setPersonnelAjouterList={setPersonnelAjouterList} personnelSupprimerList={personnelSupprimerList} setPersonnelSupprimerList={setPersonnelSupprimerList} personnelsList={props.chantier.personnels} editable={true} key={`${salarie._id._id}-edit`} salarie={salarie} />
                                );
                            })
                            :
                            props.chantier.personnels.length ?
                                props.chantier.personnels.map((salarie) => {
                                    const salarieFormat = { _id: salarie };
                                    return (
                                        <PersonnelItem searchValue={activeSearchValue} editable={false} key={`${salarie._id}`} salarie={salarieFormat} />
                                    );
                                })
                                :
                                <p>La liste du personnel est vide</p>
                    }
                </div>
            </div>
        </>
    );
};

export default ProfilChantierAmiante_Personnel;
