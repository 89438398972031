import React, { useEffect, useState, useMemo } from "react";
import SelectSearch from "../../SelectSearch";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import axiosInstance from "../../../utils/axiosInstance";
import customToast from "../../../utils/ToastifyHelper";
import { showError } from '../../../utils/ErrorHelper';
import UploadDocumentAnalyse from "../../UploadDocumentAnalyse";
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from "../../../utils/GeneralHelper";
import { useDropzone } from 'react-dropzone';
import { Icon } from '@iconify/react';

const AjouterAnalyseProcessus = (props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm();
	const close = () => {
		props.closeModal();
		props.unrenderForm();
	};
	const [isButtonDisabled, setButtonDisabled] = useState(false);

	const onSubmit = (data) => {
		const postData = {
			chantierAnalyse: data.chantierAnalyse.value,
			dateAnalyse: data.dateAnalyse,
			typeValidationAnalyse: data.typeValidationAnalyse.value,
			analyseF: data.analyseF,
			analyseRC: data.analyseRC,
			analyseSa: data.analyseSa,
		};
		setButtonDisabled(true);
		axiosInstance.post(`/validationProcessus/${props.processusAnalyse._id}`, postData)
			.then((result) => {
				if (files.length === 0) {
					setButtonDisabled(false);
					props.updateValidations(result.data);
					customToast.success("L'analyse du processus a été ajouté avec succès");
					close();
				}
				else {
					setButtonDisabled(false);
					setUploadFileProperty({ typeValidationAnalyse: data.typeValidationAnalyse.value });
					setUploadFile(true);
				}
			})
			.catch((err) => {
				showError(err);
				setButtonDisabled(false);
			});
	};

	const [selectableValidationOptions, setSelectableValidationOptions] = useState([]);

	const typeValidationOption = [
		{ label: "Chantier test", value: "validationTest" },
		{ label: "Validation 1", value: "firstValidation" },
		{ label: "Validation 2", value: "secondValidation" },
		{ label: "Validation 3", value: "thirdValidation" },
	];

	useEffect(() => {
		let validationOptions = [{ label: "Contrôle", value: "controle" }];
		for (const optionValidation of typeValidationOption) {
			if (props.processusAnalyse[optionValidation.value] === null) {
				validationOptions.push(typeValidationOption.find(option => option.value === optionValidation.value));
			}
		}
		setSelectableValidationOptions(validationOptions);
	}, []);

	const allChantierOptions = useSelector(
		(state) => state.chantierZoneList.chantierFormatOptions
	);

	const [files, setFiles] = useState([]);
	const [uploadFileProperty, setUploadFileProperty] = useState({});
	const [uploadFile, setUploadFile] = useState(false);

	// DROPZONE
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject
	} = useDropzone({ multiple: false, accept: { 'text/csv': [], 'application/pdf': [], 'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [] } });

	const removeFile = () => {
		setFiles([]);
	};

	useEffect(() => {
		setFiles(acceptedFiles.map((file, index) => {
			return (
				<UploadDocumentAnalyse close={close} documentSaveRoute={"documentAnalyseProcessus"} validationProcessusId={props.processusAnalyse._id} updateValidations={props.updateValidations} uploadFileProperty={uploadFileProperty} uploadFile={uploadFile} processusAmianteId={props.processusId} removeFile={removeFile} file={file} index={index} key={file.path} />
			);
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptedFiles, uploadFile]);

	const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	]);

	return (
		<div className="modal-content ajouter-form">
			<h3 className="modal-title">Ajouter une nouvelle analyse au processus</h3>
			<button className="modal-button" onClick={() => close()}>
				x
			</button>
			<form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
				<label
					className="ajouter-form-label default-label"
					htmlFor="typeValidationAnalyse"
				>
					Type de validation
				</label>
				<SelectSearch
					options={selectableValidationOptions}
					dataName="typeValidationAnalyse"
					control={control}
					name="typeValidationAnalyse"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner la phase d'analyse"
					isClearable
					required
				/>
				{errors["typeValidationAnalyse"] && errors["typeValidationAnalyse"].type === "required" && (
					<p className="login-error-message">
						Veuillez sélectionner la phase d'analyse du processus
					</p>
				)}
				<label
					className="ajouter-form-label default-label"
					htmlFor="dateAnalyse"
				>
					Date
				</label>
				<input
					className="ajouter-form-input default-input"
					name="dateAnalyse"
					type="date"
					{...register("dateAnalyse", { required: true })}
				/>
				{errors["dateAnalyse"] && errors["dateAnalyse"].type === "required" && (
					<p className="login-error-message">
						Veuillez saisir une date d'analyse du processus
					</p>
				)}
				<label
					className="ajouter-form-label default-label"
					htmlFor="chantierAnalyse"
				>
					Chantier
				</label>
				<SelectSearch
					options={allChantierOptions}
					dataName="chantierAnalyse"
					control={control}
					name="chantierAnalyse"
					className="formSelect-search-container mgB-s2"
					classNamePrefix="formSelect-search"
					placeholder="Sélectionner le chantier d'analyse"
					isClearable
					required
				/>
				{errors["chantierAnalyse"] &&
					errors["chantierAnalyse"].type === "required" && (
						<p className="login-error-message">
							Veuillez sélectionner un chantier d'analyse du processus
						</p>
					)}
				<label className="ajouter-form-label default-label" htmlFor="analyseSa">
					Sensibilité analytique (f/L)
				</label>
				<input
					placeholder="Entrer le résultat d'analyse SA"
					className="ajouter-form-input default-input"
					step=".01"
					name="analyseSa"
					type="number"
					{...register("analyseSa", { required: true, min: 0 })}
				/>
				{errors["analyseSa"] && errors["analyseSa"].type === "required" && (
					<p className="login-error-message">Veuillez saisir l'analyse SA</p>
				)}
				{errors["analyseSa"] && errors["analyseSa"].type === "min" && (
					<p className="login-error-message">Veuillez saisir une valeur supérieur à 0</p>
				)}
				<label className="ajouter-form-label default-label" htmlFor="analyseF">
					Nombre total de fibres d'amiante comptées
				</label>
				<input
					placeholder="Entrer le résultat d'analyse F"
					className="ajouter-form-input default-input"
					step=".01"
					name="analyseF"
					type="number"
					{...register("analyseF", { required: true, min: 0 })}
				/>
				{errors["analyseF"] && errors["analyseF"].type === "required" && (
					<p className="login-error-message">Veuillez saisir l'analyse F</p>
				)}
				{errors["analyseF"] && errors["analyseF"].type === "min" && (
					<p className="login-error-message">Veuillez saisir une valeur supérieur à 0</p>
				)}
				<label className="ajouter-form-label default-label" htmlFor="analyseRC">
					Concentration (f/L)
				</label>
				<input
					placeholder="Entrer le résultat d'analyse RC"
					className="ajouter-form-input default-input"
					step=".01"
					name="analyseRC"
					type="number"
					{...register("analyseRC", { required: true, min: 0 })}
				/>
				{errors["analyseRC"] && errors["analyseRC"].type === "required" && (
					<p className="login-error-message">Veuillez saisir l'analyse RC</p>
				)}
				{errors["analyseRC"] && errors["analyseRC"].type === "min" && (
					<p className="login-error-message">Veuillez saisir une valeur supérieur à 0</p>
				)}
				<label className='ajouter-form-label default-label' htmlFor="nom">Document résultat d'analyse</label>
				<div className="container">
					{files.length === 0 &&
						<div {...getRootProps({ style })}>
							<input {...getInputProps()} />
							<Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
							<p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
						</div>
					}
					<div>
						<ul className='fileList'>{files}</ul>
					</div>
				</div>
				<input
					disabled={isButtonDisabled}
					className="validate-button-fw default-button ajouter-salarie-button"
					type="submit"
					value="Ajouter"
				/>
			</form>
		</div>
	);
};

export default AjouterAnalyseProcessus;