import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const SelectSearch = (props) => {

    const options = props.options.map((value) => {
        if (value.hasOwnProperty("label")) {
            return value;
        }
        else {
            return {
                label: value,
                value: value
            };
        }
    });

    return (
        <Controller
            name={props.dataName}
            control={props.control}
            defaultValue={props.defaultValue ? props.defaultValue : null}
            rules={{ required: props.required }}
            render={({ field: { value, onChange, onBlur } }) => {
                return (
                    <Select
                        options={options}
                        placeholder={props.placeholder}
                        onBlur={onBlur}
                        name={props.name}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={props.isClearable}
                        isRtl={false}
                        isSearchable={props.isSearchable}
                        className={props.className}
                        onChange={onChange}
                        value={value}
                        classNamePrefix={props.classNamePrefix}
                        noOptionsMessage={() => props.optionMessage || "Aucune options"}
                    />
                );
            }}
        />
    );
};

export default SelectSearch;