import React from "react";
import ProfilChantierAmiante_InformationsGenerales_modifierChantier from "./ProfilChantierAmiante_InformationsGenerales_modifierChantier";
import ProfilChantierAmiante_InformationsGenerales_apercuChantier from "./ProfilChantierAmiante_InformationsGenerales_apercuChantier";
import { formatIntTwoDigitString, joinValuesWithSeparator, normalizeTime } from "../../../../utils/GeneralHelper";
import { useSelector } from "react-redux";

const ProfilChantierAmiante_InformationsGenerales = (props) => {
    const user = useSelector(
        state => state.user.user
    );

    const ajouterImageChantier = (image) => {
        props.setChantier({ ...props.chantier, _id: { ...props.chantier._id, imageLieu: image } });
    };

    const supprimerImageChantier = () => {
        props.setChantier({ ...props.chantier, _id: { ...props.chantier._id, imageLieu: null } });
    };


    return <div className="profil-salarie-container">
        <div className='container-basic salarie-info'>
            <div className='mgB-b'>
                <h4>Informations générales</h4>
                <p>{props.chantier.statut_chantier === 0 ? "Chantier en préparation" : "Chantier en cours"}</p>
            </div>
            <ProfilChantierAmiante_InformationsGenerales_modifierChantier progressBarRef={props.progressBarRef} chantier={props.chantier} zoneChantiers={props.zoneChantiers} />
        </div>
        <div style={{ flex: 1.5 }}>
            <ProfilChantierAmiante_InformationsGenerales_apercuChantier progressBarRef={props.progressBarRef} chantier={props.chantier} supprimerImageChantier={() => supprimerImageChantier()} ajouterImageChantier={(image) => ajouterImageChantier(image)} />
            {props.chantier.hasOwnProperty("moa") &&
                <div className="container-basic  mgT-m1">
                    <h4 className="mgB-s2">Maitre d'ouvrage</h4>
                    <div>
                        <p>{props.chantier.moa.libelleMoa}</p>
                        <p>{joinValuesWithSeparator([props.chantier.moa.codePostal, props.chantier.moa.ville], " ")}</p>
                        <p>{props.chantier.moa.adresse}</p>
                    </div>
                </div>
            }
            {props.chantier.hasOwnProperty("moe") &&
                <div className="container-basic  mgT-m1">
                    <h4 className="mgB-s2">Maitre d'oeuvre</h4>
                    <div>
                        <p>{props.chantier.moe.libellemoe}</p>
                        <p>{joinValuesWithSeparator([props.chantier.moe.codePostal, props.chantier.moe.ville], " ")}</p>
                        <p>{props.chantier.moe.adresse}</p>
                    </div>
                </div>
            }
            {user.statut === 'Administration' &&
                <div className="container-basic mgT-s3">
                    <div className={props.releveHeure.total !== "0h" ? "mgB-s2" : ""} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <h4 >Relevé d'heures</h4>
                        <p>{props.releveHeure.total}</p>
                    </div>
                    {props.releveHeure.total !== "0h" &&
                        <>
                            <hr />
                            <div className="mgT-s2">
                                {props.releveHeure.salaries.map((salarie) => {
                                    return <div key={salarie.salarie._id} style={{ display: "flex", alignItems: "center" }}>
                                        <p style={{ flex: 1 }}>({salarie.salarie.login_matricule}) - {salarie.salarie.nom} {salarie.salarie.prenom}</p>
                                        <p>{salarie.total}</p>
                                    </div>;
                                })}
                            </div>
                        </>
                    }
                </div>
            }
        </div>
    </div>;
};

export default ProfilChantierAmiante_InformationsGenerales;
