import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

// AXIOS SETUP
const baseURL = process.env.REACT_APP_JUSTEAU_API_URL;
// CREATE AXIOS INSTANCE
const axiosInstance = axios.create({
  baseURL,
  headers: { post: { 'Content-Type': 'application/json' } },
  timeout: 10000,
  timeoutErrorMessage: "Timeout Error"
});

// Refresh token interceptors
axiosInstance.interceptors.request.use(async req => {
  if (sessionStorage.getItem('jwtToken')) {
    const user = jwt_decode(sessionStorage.getItem('jwtToken'));
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (!isExpired) {
      if (!req.headers['x-auth-token']) {
        req.headers['x-auth-token'] = sessionStorage.getItem('jwtToken');
      }
      return req;
    }
    const response = await axios.post(baseURL + '/refresh_token', null, { headers: { 'x-auth-token': sessionStorage.getItem('refreshToken') } });
    sessionStorage.setItem('jwtToken', response.data.token);
    req.headers['x-auth-token'] = response.data.token;
  }
  return req;
});

export default axiosInstance;


