import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from '../../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import FileUploadItem from '../../FileUploadItem';
import customToast from '../../../utils/ToastifyHelper';
import * as actionCreators from '../../../store/actions/exportAction';

const onQuitPage = () => (actionCreators.resetUserUpload());

const AjouterDocumentForm = (props) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadFile, setUploadFile] = useState(false);
  const userUpload = useSelector(
    state => state.user.userUpload
  );

  const removeFiles = (indexToRemove) => {
    acceptedFiles.splice(indexToRemove, 1);
    setFiles(acceptedFiles.map((file, index) => {
      return (
        <FileUploadItem epc={true} documentSaveRoute={props.documentSaveRoute} document={props.documentId} uploadFile={uploadFile} removeFiles={removeFiles} file={file} index={index} key={file.path} />
      );
    }));
  };

  const close = () => {
    if (uploadFile && userUpload.length !== files.length) {
      setUploadFile("cancel");
      customToast.error("Les fichiers n'ont pas été enregistré car vous avez interrompu la sauvegarde");
    }
    dispatch(onQuitPage());
    removeFiles(0);
    props.closeModal();
    props.unrenderForm();
  };

  useEffect(() => {
    if (uploadFile && userUpload.length === files.length) {
      customToast.success("Tous les fichiers ont été sauvegardé avec succès, fermeture automatique de la fenêtre dans 5s");
      setTimeout(() => {
        close();
      }, 5000);
      setUploadFile(false);
      setButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpload, files]);

  const onSubmit = () => {
    setButtonDisabled(true);
    setUploadFile(true);
  };

  // DROPZONE
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ multiple: true, maxFiles: props.maxFiles, accept: { 'image/*': [], 'text/csv': [], 'application/pdf': [], 'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [] } });



  useEffect(() => {
    setFiles(acceptedFiles.map((file, index) => {
      return (
        <FileUploadItem epc={true} documentSaveRoute={props.documentSaveRoute} document={props.equipementId} uploadFile={uploadFile} removeFiles={removeFiles} file={file} index={index} key={file.path} />
      );
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles, uploadFile]);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div className="modal-content ajouter-form">
      <h3 className="modal-title">{props.documentSaveRoute !== "remplacerDocumentsEPC" ? "Ajouter des documents" : "Remplacer un document"}</h3>
      <button className='modal-button' onClick={() => close()}>x</button>
      <div className="vertical-form scrollable">
        <div className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
            <p className='dropzone-text'>Glissez vos fichier dans la zone, ou <span>cliquez pour les sélectionner</span></p>
          </div>
          <div>
            {files.length > 0 && <h4 className='ajouter-form-label default-label'>Documents à enregistrer</h4>}
            <ul className='fileList'>{files}</ul>
          </div>
        </div>
        <button onClick={() => onSubmit()} disabled={isButtonDisabled || acceptedFiles.length === 0} className="default-button default-button-blue">Enregistrer</button>
      </div>
    </div>
  );
};

export default AjouterDocumentForm;