import React, { useEffect, useRef, useState } from 'react';
import * as actionCreators from '../../store/actions/exportAction';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import AjouterChantierForm from '../../components/forms/GestionsForms/AjouterChantierForm';
import ModifyChantierForm from '../../components/forms/ModifyChantierForm';
import ArchiverChantierForm from '../../components/forms/ArchiverChantierForm';
import AjouterGestionDocumentChantier from '../../components/forms/GestionsForms/AjouterGestionDocumentChantier';
import ModifierDocumentsGestionsChantiers from '../../components/forms/ModifierDocumentsGestionsChantiers';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import TableRender from '../../components/tables/TableRender';
import { getChantierColumns, getGestionDocumentsChantiersColumns } from '../../components/tables/TableColumns';

import { useNavigate } from 'react-router-dom';
import { advancedSearch, getInsensitiveWorld } from '../../utils/GeneralHelper';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';
import Breadcrumb from '../../components/ui/Breadcrumb';
import SearchInput from '../../components/ui/SearchInput';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import LoadingBar from 'react-top-loading-bar';


// const onLoadPage = () => (actionCreators.getChantierZoneList());
// const onQuitPage = () => (actionCreators.unloadChantierZoneList());
// const unloadTab = () => (actionCreators.unloadTopbarTab())
// const loadDocument = () => (actionCreators.getDocumentsGestionsChantiers())
// const setCurrentTab = (tabName) => (actionCreators.setTopbarCurrentTab(tabName));


// const GestionChantierPage = () => {
//     const navigate = useNavigate()
//     const [showedItems, setShowedItems] = useState([]);
//     const [searchBarContent, setSearchBarContent] = useState("");
//     const [selectStatutChantier, setSelectStatutChantier] = useState("All");

//     // get chantier_zone state
//     const chantierZoneState = useSelector(
//         state => state.chantierZoneList
//     )
//     const dispatch = useDispatch();

//     useEffect(() => {
//         dispatch(onLoadPage());
//         dispatch(loadDocument())
//         dispatch(setCurrentTab("Tous les chantiers"))
//         return () => {
//             dispatch(onQuitPage());
//             dispatch(unloadTab())
//             setShowedItems([]);
//         }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])

//     useEffect(() => {
//         let showedChantier = chantierZoneState.allChantier.filter(chantier => (chantier.statut_chantier === 0));
//         // searchbar
//         if (searchBarContent !== "" && searchBarContent !== null) {
//             showedChantier = showedChantier.filter(chantier => (advancedSearch(chantier._id.libelle_lieu, searchBarContent) || advancedSearch(chantier.codeChantier, searchBarContent)));
//         }
//         if (selectStatutChantier !== 'All') {
//             showedChantier = showedChantier.filter(chantier => {
//                 return chantier.secteur === selectStatutChantier
//             });
//         }
//         setShowedItems(showedChantier);

//     }, [chantierZoneState.allChantier, searchBarContent, selectStatutChantier])

//     // Gestion Modal
//     const [modalClasses, setModalClasses] = useState("modal");
//     const [modalContent, setModalContent] = useState("Aucun contenu");

//     const archiverChantier = () => {
//         dispatch(onQuitPage())
//         dispatch(onLoadPage())
//     }

//     const openAddChantier = () => {
//         setModalClasses("modal modal__active")
//         setTimeout(() => {
//             setModalClasses("modal modal__active modal__fade")   
//         }, 0.1);
//         setModalContent(<AjouterChantierForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
//     }

//     const openAddGestionDocument = () => {
//         setModalClasses("modal modal__active")
//         setTimeout(() => {
//             setModalClasses("modal modal__active modal__fade")   
//         }, 0.1);
//         setModalContent(<AjouterGestionDocumentChantier closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
//     }

//     const openModifyChantier = (chantierData) => {
//         setModalClasses("modal modal__active")
//         setTimeout(() => {
//             setModalClasses("modal modal__active modal__fade")   
//         }, 0.1);
//         setModalContent(<ModifyChantierForm chantier={chantierData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
//     }

//     const openArchiverChantier = (chantierData) => {
//         setModalClasses("modal modal__active")
//         setTimeout(() => {
//             setModalClasses("modal modal__active modal__fade")   
//         }, 0.1);
//         setModalContent(<ArchiverChantierForm archivage={true} chantier={chantierData} archiverChantier={() => archiverChantier()} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
//     }

//     const searchChantier = (e) => {
//         if (e.target.value !== '') {
//             setSearchBarContent(e.target.value)
//         }
//         else {
//             setSearchBarContent("")
//         }   
//     }

//     const selectStatutChantierInput = (e) => {
//         setSelectStatutChantier(e.target.value);
//     }


//     const topBarState = useSelector(state => state.topBar.currentTab) 

//     const [showedGestionsDocuments, setShowedGestionsDocuments] = useState([]);
//     const documentsGestionChantiers = useSelector(state => state.documents.documentGestionsChantiers)

//     const [searchDocumentsChantiersBar, setSearchDocumentsChantiersBar] = useState("")

//     useEffect(() => {
//         let showedDocuments = documentsGestionChantiers
//         if (searchDocumentsChantiersBar !== "") {
//             showedDocuments = showedDocuments.filter(document => document.libelleDocument.toLowerCase().includes(searchDocumentsChantiersBar.toLowerCase()))
//         }
//         setShowedGestionsDocuments(showedDocuments)
//     }, [documentsGestionChantiers, searchDocumentsChantiersBar])

//     const supprimerGestionsDocumentsChantiers = (documentData) => {
//         setModalClasses("modal modal__active")
//         setTimeout(() => {
//             setModalClasses("modal modal__active modal__fade")   
//         }, 0.1);
//         setModalContent(<SupprimerModalForm destination="supprimerDocumentGestionsChantiers" documentData={documentData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
//     }

//     const modifierGestionsDocumentsChantiers = (document) => {
//         setModalClasses("modal modal__active")
//         setTimeout(() => {
//             setModalClasses("modal modal__active modal__fade")   
//         }, 0.1);
//         setModalContent(<ModifierDocumentsGestionsChantiers document={document} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")}/>)
//     }

//     const [insideNavigationPage, setInsideNavigationPage] = useState("tousLesChantiers")

//     return (      
//         <div className='section'>
//             <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
//                 <h1>{insideNavigationPage === "tousLesChantiers" ? "Liste des chantiers" : insideNavigationPage === "gestionDocuments" ? "Gestion des documents" : "Export"}</h1>
//                 {insideNavigationPage === "tousLesChantiers" && <button onClick={() => openAddChantier()} className='btn btn-primary medium-btn'>+ Chantier</button>}
//                 {insideNavigationPage === "gestionDocuments" && <button onClick={() => openAddGestionDocument()} className='btn btn-primary medium-btn'>+ Document</button>}
//             </div>
//             <Breadcrumb 
//                 links={[
//                     {label: "Gestion des données", link:"/gestion-de-donnees"},
//                     {label: "Chantiers", link:"/gestion-de-donnees/chantiers"},
//                 ]}
//             />
//             <InsideNavigationBar
//                 activeValue={insideNavigationPage}
//                 setActiveValue={(value) => setInsideNavigationPage(value)}
//                 links={[
//                     {label: "Liste des chantiers", value: "tousLesChantiers"},
//                     {label: "Gestion des documents", value: "gestionDocuments"},
//                     {label: "Export", value: "export"}
//                 ]}
//             />
//             {insideNavigationPage === "tousLesChantiers"}>
//                 <>
//                     <div className="filterContainer">
//                         <SearchInput placeholder="Rechercher un chantier" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={searchChantier} />
//                         <select value={selectStatutChantier} className="new-default-input" onChange={(e) => selectStatutChantierInput(e)} name="chantierStatutSelect" id="chantierStatut-select">
//                             <option value="All">Tous les chantiers</option>
//                             <option value="Gros oeuvres">Gros oeuvres</option>
//                             <option value="Travaux publics">Travaux publics</option>
//                         </select>
//                     </div>
//                     <TableRender
//                         columns={getChantierColumns((chantierData) => openModifyChantier(chantierData), (chantierData) => openArchiverChantier(chantierData), (link) => navigate(link))}
//                         tableData={showedItems}
//                         sortBy={[
//                             {
//                                 id: '_id.libelle_lieu',
//                                 desc: false
//                             }
//                         ]}
//                         pageName="GestionChantiers"
//                     />
//                 </>
//             }
//             {insideNavigationPage === "gestionDocuments"}>
//                 <div className="gestion-documents-wrapper">
//                     <div className="filterContainer">
//                         <SearchInput placeholder="Rechercher un document" searchContent={searchDocumentsChantiersBar ? searchDocumentsChantiersBar : ""} onChangeSearch={setSearchDocumentsChantiersBar} />
//                     </div>
//                     <TableRender 
//                         columns={getGestionDocumentsChantiersColumns((document) => modifierGestionsDocumentsChantiers(document), (documentData) => supprimerGestionsDocumentsChantiers(documentData))}
//                         tableData={showedGestionsDocuments}
//                         sortBy={[
//                             {
//                                 id: 'libelleDocument',
//                                 desc: false
//                             }
//                         ]}
//                         pageName="GestionChantiersDocument"
//                     />
//                 </div>
//             }
//             {insideNavigationPage === "export"}>
//                 <div className="gestion-exports-wrapper">
//                     <div className="main-content">
//                         <table className="default-table">
//                             <thead>
//                                 <tr>
//                                     <th>test1</th>
//                                     <th>test2</th>
//                                     <th>test3</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>test1</td>
//                                     <td>test2</td>
//                                     <td>test3</td>
//                                 </tr>
//                                 <tr>
//                                     <td>test1</td>
//                                     <td>test2</td>
//                                     <td>test3</td>
//                                 </tr>
//                                 <tr>
//                                     <td>test1</td>
//                                     <td>test2</td>
//                                     <td>test3</td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             }
//             <Modal modalClass={modalClasses}>{modalContent}</Modal>
//         </div>
//     );
// };

// export default GestionChantierPage;

const GestionChantierPage = () => {
    const navigate = useNavigate();

    const [chantiers, setChantiers] = useState([]);
    const [zoneChantiers, setZoneChantiers] = useState([]);
    const [showedItems, setShowedItems] = useState([]);
    const progressBarRef = useRef(null);

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/chantiers").then((result) => {
            progressBarRef.current.complete();
            setChantiers(result.data);
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });

        axiosInstance.get("/zones").then((res) => {
            setZoneChantiers(res.data);
        }).catch((error) => {
            showError(error);
        });
    }, []);

    // search bar
    const [searchBarContent, setSearchBarContent] = useState(null);
    const chantierMatch = (chantier, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(chantier._id.libelle_lieu).includes(insensitiveSearch) || getInsensitiveWorld(chantier.codeChantier).includes(insensitiveSearch);
    };

    const onChangeSearchChantier = (e) => {
        setSearchBarContent(e.target.value);
    };

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedItems(chantiers.filter(chantier => {
                    return chantierMatch(chantier, searchBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedItems(chantiers);
        }
    }, [chantiers, searchBarContent]);

    // MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalChantierSelected, setModalChantierSelected] = useState({});

    const openAddChantier = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const addChantier = (chantier) => {
        setChantiers(chantiers.concat(chantier));
    };

    const openModifyChantier = (chantierData) => {
        setModalChantierSelected(chantierData);
        setModalClasses("modal modal__active");
        setModalContent("modifier");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const editChantier = (chantierPatch) => {
        setChantiers(chantiers.map((chantier) => {
            if (chantier._id._id === chantierPatch._id._id) {
                return chantierPatch;
            }
            return chantier;
        }));
    };

    const openArchiverChantier = (chantierData) => {
        setModalChantierSelected(chantierData);
        setModalClasses("modal modal__active");
        setModalContent("archiver");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const archiverChantier = (chantierId) => {
        setChantiers(chantiers.filter(chantier => {
            return chantierId !== chantier._id._id;
        }));
    };

    const [insideNavigationPage, setInsideNavigationPage] = useState("tousLesChantiers");


    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                    <h1>{insideNavigationPage === "tousLesChantiers" ? "Liste des chantiers" : "Export"}</h1>
                    {insideNavigationPage === "tousLesChantiers" && <button onClick={() => openAddChantier()} className='btn btn-primary medium-btn'>+ Chantier</button>}
                </div>
                <Breadcrumb
                    links={[
                        { label: "Gestion des données", link: "/gestion-de-donnees" },
                        { label: "Chantiers", link: "/gestion-de-donnees/chantiers" },
                    ]}
                />
                <InsideNavigationBar
                    activeValue={insideNavigationPage}
                    setActiveValue={(value) => setInsideNavigationPage(value)}
                    links={[
                        { label: "Liste des chantiers", value: "tousLesChantiers" },
                        { label: "Export", value: "export" }
                    ]}
                />
                {insideNavigationPage === "tousLesChantiers" &&
                    <>
                        <div className="filterContainer">
                            <SearchInput fullwidth={true} placeholder="Rechercher un chantier" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchChantier} />
                        </div>
                        <TableRender
                            columns={getChantierColumns((chantierData) => openModifyChantier(chantierData), (chantierData) => openArchiverChantier(chantierData), (link) => navigate(link))}
                            tableData={showedItems}
                            sortBy={[
                                {
                                    id: '_id.libelle_lieu',
                                    desc: false
                                }
                            ]}
                            pageName="GestionChantiers"
                        />
                    </>
                }
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter" &&
                    <AjouterChantierForm progressBarRef={progressBarRef} zoneChantiers={zoneChantiers} addChantier={(chantier) => addChantier(chantier)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "modifier" &&
                    <ModifyChantierForm editChantier={(chantier) => editChantier(chantier)} progressBarRef={progressBarRef} zoneChantiers={zoneChantiers} modalChantierSelected={modalChantierSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "archiver" &&
                    <SupprimerModalForm destination="archiverChantier" modalChantierSelected={modalChantierSelected} archiverChantier={(chantierId) => archiverChantier(chantierId)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>
        </>
    );
};

export default GestionChantierPage;

