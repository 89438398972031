import { format } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import { Icon } from "@iconify/react";
import { calculateExpositionDuration, calculateExpositionRemainingTime, calculateExpositionTotalDuration, calculateFinishedExpositionDuration, calculatePauseDuration, calculateProgress, capitalizeFirstLetter, dateToHoursAndMinutesString, getDureeMaxVacationEnCours, getEstimationVlep, isOuvrierInVacation, salarieATerminer, salarieSontEnAttente, salarieSontEnVacations } from '../../utils/GeneralHelper';
import Modal from '../../components/Modal';
import SignatureSalarieModal from '../../components/NoActionModals/SignatureSalarieModal';
import DetailVacationSalarie from '../../components/forms/DetailVacationSalarie';
import ProgressBar from '@ramonak/react-progress-bar';
import ModifierOperationsAmianteSalarie from '../../components/forms/AmianteForms/ModifierOperationsAmianteSalarie';

const ReleveExposition = () => {
    const [releveExposition, setReleveExposition] = useState(null);
    const [autoControleList, setAutoControleList] = useState([]);
    const [epiList, setEpiList] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [rerender, setRerender] = useState(true);
    const [processusChantier, setProcessusChantier] = useState([]);
    let { idReleveExposition } = useParams();
    const navigate = useNavigate();


    const refreshCurrentTime = () => {
        setCurrentTime(new Date());
        let timer = setTimeout(() => {
            if (rerender) {
                refreshCurrentTime();
            }
            else {
                clearTimeout(timer);
            }
        }, 200);
    };

    useEffect(() => {
        refreshCurrentTime();
        return () => {
            setRerender(false);
        };
    }, []);

    useEffect(() => {
        axiosInstance.get(`/vacationsAmiante/${idReleveExposition}`).then((result) => {
            setReleveExposition(result.data);
            // console.log(result.data);
            // load processus
            axiosInstance.get("/processusAmiante/processusPourVacations")
                .then((res) => {
                    setProcessusChantier(res.data.map((process) => {
                        return { label: `${process.codeProcessus} - Niveau ${process.niveauEmpoussierement}`, value: process._id };
                    }));
                }).catch((error) => {
                    console.log(error);
                });
        }).catch((err) => {
            showError(err);
        });
        // load autoControle
        axiosInstance.get("/autoControle")
            .then((result) => {
                setAutoControleList(result.data);
            }).catch((err) => {
                showError(err);
            });
        // load EPI
        axiosInstance.get("/equipement_amiante")
            .then((result) => {
                setEpiList(result.data);
            }).catch((err) => {
                showError(err);
            });
    }, []);

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openSignatureSalarie = (entreeSortieZone) => {
        setModalClasses("modalSmall modal__active");
        setTimeout(() => {
            setModalClasses("modalSmall modal__active modal__fade");
        }, 0.1);
        setModalContent(<SignatureSalarieModal entreeSortieZone={entreeSortieZone} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openDetailVacationSalarie = (idVacation, entreeSortie, salarie, numeroIntervention) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<DetailVacationSalarie processusChantier={processusChantier} modifierUnEntreeSortieZone={(entreeSortieZoneVacation) => modifierUnEntreeSortieZone(entreeSortieZoneVacation)} idVacation={idVacation} entreeSortie={entreeSortie} salarie={salarie} numeroIntervention={numeroIntervention} autoControleList={autoControleList} epiList={epiList} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierOperationsSalarie = (idVacation, entreeSortie, salarie, numeroIntervention) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierOperationsAmianteSalarie processusChantier={processusChantier} modifierUnEntreeSortieZone={(entreeSortieZoneVacation) => modifierUnEntreeSortieZone(entreeSortieZoneVacation)} idVacation={idVacation} entreeSortie={entreeSortie} salarie={salarie} numeroIntervention={numeroIntervention} autoControleList={autoControleList} epiList={epiList} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const modifierUnEntreeSortieZone = (entreeSortieZoneVacation) => {
        setReleveExposition({
            ...releveExposition, entreeSortieZoneSalarie:
                releveExposition.entreeSortieZoneSalarie.map((entreeSortie) => {
                    if (entreeSortie._id === entreeSortieZoneVacation._id) {
                        return entreeSortieZoneVacation;
                    }
                    else {
                        return entreeSortie;
                    }
                })
        });
    };

    return (
        <div className='section'>
            {releveExposition !== null &&
                <>
                    <div className='container-basic mgB-m2'>
                        <div style={{ display: "flex", alignItems: "start" }}>
                            <Icon
                                onClick={() => navigate(-1)}
                                className="backButton"
                                icon="akar-icons:chevron-left"
                            />
                            <div>
                                <h1 className="h1-blue-dark">{releveExposition.chantier.codeChantier} - {capitalizeFirstLetter(releveExposition.chantier._id.libelle_lieu)}</h1>
                                <h3 className="banqueHeure-Total">Le {format(new Date(releveExposition.dateVacation), "dd/MM/yyyy")}</h3>
                            </div>
                        </div>
                    </div>
                    {
                        releveExposition.entreeSortieZoneSalarie.length &&
                        <div>
                            {salarieSontEnVacations(releveExposition.entreeSortieZoneSalarie) &&
                                <div className="vacationEnCours">
                                    <h2 className='section-vacation-title'>Vacations en cours</h2>
                                    <hr />
                                    {
                                        releveExposition.entreeSortieZoneSalarie.filter(entreeSortie => {
                                            return entreeSortie.signatureSalarie === null && isOuvrierInVacation(entreeSortie);
                                        }).map((entreeSortieEnCours) => {
                                            const dateDebutExposition = new Date(entreeSortieEnCours.entreeSortie[entreeSortieEnCours.entreeSortie.length - 1].operations[0].dateHeureDebut);
                                            const dureeMaxVacation = getDureeMaxVacationEnCours(entreeSortieEnCours.entreeSortie);
                                            return (
                                                <div key={entreeSortieEnCours._id} className='container-basic mgB-m1'>
                                                    <div className="container-flex mgB-s2" style={{ justifyContent: "space-between" }}>
                                                        <h3 style={{ width: "45%" }} className='vacationsSalarieTitle mgB-m1'>{capitalizeFirstLetter(entreeSortieEnCours.salarie.prenom)} {capitalizeFirstLetter(entreeSortieEnCours.salarie.nom)}</h3>
                                                        <div style={{ width: "55%" }}>
                                                            <p className="small-text">VLEP estimé</p>
                                                            <p className="vacationNumber">{getEstimationVlep(entreeSortieEnCours.entreeSortie, currentTime)} f/l</p>
                                                        </div>
                                                    </div>
                                                    <div className="container-flex mgB-s1" style={{ justifyContent: "space-between" }}>
                                                        <p style={{ width: "45%" }} className="vacationIndex">{calculateExpositionDuration(dateDebutExposition, currentTime)}</p>
                                                        <div style={{ width: "55%" }} className="vacationIndex">{calculateExpositionRemainingTime(dateDebutExposition, currentTime, dureeMaxVacation)}</div>
                                                    </div>
                                                    <ProgressBar className='mgB-s2' height="1rem" isLabelVisible={false} width={null} bgColor={"#2fc451"} borderRadius=".5rem" completed={calculateProgress(dateDebutExposition, currentTime, dureeMaxVacation)} />
                                                    <p className="small-title mgB-s1">Processus effectuées:</p>
                                                    <div className="container-flex mgB-s1">
                                                        <p className="small-text" style={{ width: "45%" }}>
                                                            Code processus
                                                        </p>
                                                        <p className="small-text" style={{ width: "55%" }}>
                                                            Durée
                                                        </p>
                                                    </div>
                                                    {entreeSortieEnCours.entreeSortie[entreeSortieEnCours.entreeSortie.length - 1].operations.map((item) => {
                                                        return (
                                                            <div key={item._id} className="container-flex" style={{ padding: "0.6rem 0", borderTop: "1px solid #ddd", alignItems: "center" }}>
                                                                <p className="vacationNumber" style={{ width: "45%" }}>{item.typeProcessus.codeProcessus}</p>
                                                                <p className="vacationIndex" style={{ width: "55%" }}>de {format(new Date(item.dateHeureDebut), "kk:mm")} à {item.dateHeureSortie === null ? "maintenant" : format(new Date(item.dateHeureSortie), "kk:mm")}</p>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                            );
                                        })
                                    }
                                </div>
                            }
                            {salarieSontEnAttente(releveExposition.entreeSortieZoneSalarie) &&
                                <div className="vacationEnAttente">
                                    <h2 className='section-vacation-title'>Vacations en attente</h2>
                                    <hr />
                                    {
                                        releveExposition.entreeSortieZoneSalarie.filter(entreeSortie => {
                                            return entreeSortie.signatureSalarie === null && !isOuvrierInVacation(entreeSortie);
                                        }).map((entreeSortieEnAttente) => {
                                            return (
                                                <div key={entreeSortieEnAttente._id} className='container-basic mgB-m1'>
                                                    <h3 className='vacationsSalarieTitle mgB-m1'>{capitalizeFirstLetter(entreeSortieEnAttente.salarie.prenom)} {capitalizeFirstLetter(entreeSortieEnAttente.salarie.nom)}</h3>
                                                    <div className="container-flex mgB-s2">
                                                        <div className="vacation-infos-container">
                                                            <p className="small-text">
                                                                Temps de vacation
                                                            </p>
                                                            <p className="vacationNumber">
                                                                {entreeSortieEnAttente.entreeSortie.length ? calculateExpositionTotalDuration(entreeSortieEnAttente.entreeSortie) : "00:00"}
                                                            </p>
                                                        </div>
                                                        <div className="vacation-infos-container">
                                                            <p className="small-text">
                                                                VLEP estimé
                                                            </p>
                                                            <p className="vacationNumber">
                                                                {entreeSortieEnAttente.entreeSortie.length ? `${getEstimationVlep(entreeSortieEnAttente.entreeSortie, null)} f/l` : "0 f/l"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {entreeSortieEnAttente.entreeSortie.length ?
                                                        <>
                                                            <p className="small-title mgB-s1">Vacations effectuées:</p>
                                                            <div className="container-flex mgB-s1">
                                                                <p className="small-text" style={{ width: "15%" }}>
                                                                    N° intervention
                                                                </p>
                                                                <p className="small-text" style={{ width: "15%" }}>
                                                                    Heure
                                                                </p>
                                                                <p className="small-text" style={{ width: "15%" }}>
                                                                    Durée
                                                                </p>
                                                                <p className="small-text" style={{ width: "15%" }}>
                                                                    Pause
                                                                </p>
                                                                <p className="small-text" style={{ width: "15%" }}>
                                                                    Action
                                                                </p>
                                                            </div>
                                                            {entreeSortieEnAttente.entreeSortie.map((entreeSortie, index) => {
                                                                return (
                                                                    <div key={entreeSortie._id} className="container-flex" style={{ padding: "0.6rem 0", borderTop: "1px solid #ddd", alignItems: "center" }}>
                                                                        <p className="vacationIndex" style={{ width: "15%" }}>{index + 1}</p>
                                                                        <p className="vacationNumber" style={{ width: "15%" }}>{calculateFinishedExpositionDuration(entreeSortie)}</p>
                                                                        <p className="vacationIndex" style={{ width: "15%" }}>de {dateToHoursAndMinutesString(entreeSortie.operations[0].dateHeureDebut)} à {dateToHoursAndMinutesString(entreeSortie.operations[entreeSortie.operations.length - 1].dateHeureSortie)}</p>
                                                                        <p className="vacationNumber" style={{ width: "15%" }}>{index !== entreeSortieEnAttente.entreeSortie.length - 1 ? calculatePauseDuration(entreeSortie.operations[entreeSortie.operations.length - 1].dateHeureSortie, entreeSortieEnAttente.entreeSortie[index + 1].operations[0].dateHeureDebut) : ""}</p>
                                                                        <button onClick={() => openDetailVacationSalarie(entreeSortieEnAttente._id, entreeSortie, entreeSortieEnAttente.salarie, index + 1)} className='round-button-small black-button mgR-s1'><Icon className="eyeDocumentSvg" icon="mdi:account-eye" /></button>
                                                                        <button onClick={() => openModifierOperationsSalarie(entreeSortieEnAttente._id, entreeSortie, entreeSortieEnAttente.salarie, index + 1)} className='round-button-small black-button'><Icon className="eyeDocumentSvg" icon="mdi:timer-edit" /></button>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            }
                            <div className="vacationTerminer">
                                <h2 className='section-vacation-title'>Vacations terminées</h2>
                                <hr />
                                {
                                    releveExposition.entreeSortieZoneSalarie.filter(entreeSortie => {
                                        return entreeSortie.signatureSalarie !== null;
                                    }).map((entreeSortieTerminer) => {
                                        return (
                                            <div key={entreeSortieTerminer._id} className='container-basic mgB-m1'>
                                                <div className="container-flex mgB-m1" style={{ alignItems: "center" }}>
                                                    <h3 className='vacationsSalarieTitle mgR-m1'>{capitalizeFirstLetter(entreeSortieTerminer.salarie.prenom)} {capitalizeFirstLetter(entreeSortieTerminer.salarie.nom)}</h3>
                                                    <button onClick={() => openSignatureSalarie(entreeSortieTerminer)} className="round-button black-button"><Icon icon="foundation:clipboard-pencil" /></button>
                                                </div>
                                                <div className="container-flex mgB-s2">
                                                    <div className="vacation-infos-container">
                                                        <p className="small-text">
                                                            Temps de vacation
                                                        </p>
                                                        <p className="vacationNumber">
                                                            {entreeSortieTerminer.entreeSortie.length ? calculateExpositionTotalDuration(entreeSortieTerminer.entreeSortie) : "00:00"}
                                                        </p>
                                                    </div>
                                                    <div className="vacation-infos-container">
                                                        <p className="small-text">
                                                            VLEP estimé
                                                        </p>
                                                        <p className="vacationNumber">
                                                            {entreeSortieTerminer.entreeSortie.length ? `${getEstimationVlep(entreeSortieTerminer.entreeSortie, null)} f/l` : "0 f/l"}
                                                        </p>
                                                    </div>
                                                </div>
                                                {entreeSortieTerminer.entreeSortie.length ?
                                                    <>
                                                        <p className="small-title mgB-s1">Vacations effectuées:</p>
                                                        <div className="container-flex mgB-s1">
                                                            <p className="small-text" style={{ width: "15%" }}>
                                                                N° intervention
                                                            </p>
                                                            <p className="small-text" style={{ width: "15%" }}>
                                                                Heure
                                                            </p>
                                                            <p className="small-text " style={{ width: "15%" }}>
                                                                Durée
                                                            </p>
                                                            <p className="small-text " style={{ width: "15%" }}>
                                                                Pause
                                                            </p>
                                                            <p className="small-text " style={{ width: "15%" }}>
                                                                Action
                                                            </p>
                                                        </div>
                                                        {entreeSortieTerminer.entreeSortie.map((entreeSortie, index) => {
                                                            return (
                                                                <div key={entreeSortie._id} className="container-flex" style={{ padding: "0.6rem 0", borderTop: "1px solid #ddd", alignItems: "center" }}>
                                                                    <p className="vacationIndex" style={{ width: "15%" }}>{index + 1}</p>
                                                                    <p className="vacationNumber" style={{ width: "15%" }}>{calculateFinishedExpositionDuration(entreeSortie)}</p>
                                                                    <p className="vacationIndex" style={{ width: "15%" }}>de {dateToHoursAndMinutesString(entreeSortie.operations[0].dateHeureDebut)} à {dateToHoursAndMinutesString(entreeSortie.operations[entreeSortie.operations.length - 1].dateHeureSortie)}</p>
                                                                    <p className="vacationNumber" style={{ width: "15%" }}>{index !== entreeSortieTerminer.entreeSortie.length - 1 ? calculatePauseDuration(entreeSortie.operations[entreeSortie.operations.length - 1].dateHeureSortie, entreeSortieTerminer.entreeSortie[index + 1].operations[0].dateHeureDebut) : ""}</p>
                                                                    <button onClick={() => openDetailVacationSalarie(entreeSortieTerminer._id, entreeSortie, entreeSortieTerminer.salarie, index + 1)} className='round-button-small black-button'><Icon className="eyeDocumentSvg" icon="mdi:account-eye" /></button>
                                                                    <button onClick={() => openModifierOperationsSalarie(entreeSortieTerminer._id, entreeSortie, entreeSortieTerminer.salarie, index + 1)} className='round-button-small black-button'><Icon className="eyeDocumentSvg" icon="mdi:timer-edit" /></button>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }
                </>
            }
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default ReleveExposition;