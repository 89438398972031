import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import axiosInstance from '../../utils/axiosInstance';
import customToast from '../../utils/ToastifyHelper';
import * as actionCreators from '../../store/actions/exportAction';
import { showError } from '../../utils/ErrorHelper';

const onModifyDocument = (document) => (actionCreators.modifierDocumentPublic(document));

const ModifierDocumentForm = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [titre, setTitre] = useState(props.document.titre);
    const [description, setDescription] = useState(props.document.description);
    const categoriesTypesDocument = useSelector(state => state.categorieDocument);
    const [typeFichier, setTypeFichier] = useState(props.document.typeDocument._id);
    const dispatch = useDispatch();

    const changeCategorie = (event) => {
        setTypeFichier(event.target.value);
    };

    // console.log(props.document);

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const submitForm = () => {
        setButtonDisabled(true);
        if (!titre.length) {
            customToast.error("Veuillez saisir un titre au document pour le modifier");
        }
        axiosInstance.patch(`/documents/${props.document._id}`, { titre, description, typeDocument: typeFichier }).then((result) => {
            customToast.success("Document modifié avec succès");
            dispatch(onModifyDocument(result.data));
            close();
            setButtonDisabled(false);
        }).catch((err) => {
            setButtonDisabled(false);
            showError(err);
        });
        setButtonDisabled(false);
    };

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier un document</h3>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <label className='modifier-form-label default-label' htmlFor="nomDocument">Titre du document</label>
                <input value={titre} onChange={(e) => setTitre(e.target.value)} className="ajouter-form-input default-input" name="nomDocument" type="text" placeholder="Entrez le nom du document" />
                <label className='modifier-form-label default-label' htmlFor="type">Type du document</label>
                <select className='new-default-input' onChange={changeCategorie} value={typeFichier} name="type">
                    {categoriesTypesDocument.map((categorie) => {
                        return (
                            categorie.typeDocuments.map((type) => {
                                return <option key={categorie._id + type._id} value={type._id}>{type.libelleType}</option>;
                            })
                        );
                    })}
                </select>
                <label className='modifier-form-label default-label' htmlFor="description">Description</label>
                <input value={description} onChange={(e) => setDescription(e.target.value)} className="ajouter-form-input default-input" name="description" type="text" placeholder="Entrez la description du document" />
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <button disabled={isButtonDisabled} className="default-button modifier-form-valider" onClick={() => submitForm()}>Valider</button>
                </div>
            </div>
        </div>
    );
};

export default ModifierDocumentForm;