import React, { useEffect, useState, useMemo } from 'react';
import { useForm } from "react-hook-form";
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from "../../../utils/GeneralHelper";
import UploadAjouterDocumentAnalyse from '../../UploadAjouterDocumentAnalyse';
import { Icon } from '@iconify/react';

const AjouterDocumentAnalyse = (props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm();
	const close = () => {
		props.closeModal();
		props.unrenderForm();
	};
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [errorNoDocument, setErrorNoDocument] = useState(false);

	const onSubmit = (data) => {
		setButtonDisabled(true);
		if (files.length === 0) {
			setButtonDisabled(false);
			setErrorNoDocument(true);
		}
		else {
			setButtonDisabled(false);
			setErrorNoDocument(false);
			setUploadFile(true);
		}
	};

	const [files, setFiles] = useState([]);
	const [uploadFile, setUploadFile] = useState(false);

	// DROPZONE
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject
	} = useDropzone({ multiple: false, accept: { 'text/csv': [], 'application/pdf': [], 'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [] } });

	const removeFile = () => {
		setFiles([]);
	};

	useEffect(() => {
		setFiles(acceptedFiles.map((file, index) => {
			return (
				<UploadAjouterDocumentAnalyse close={close} typeValidationAnalyse={props.analyse.typeValidation} analyseId={props.analyse.processus._id} validationControleId={props.analyse.typeValidation === "controle" ? props.analyse.analyse._id : null} documentSaveRoute={"ajouterDocumentAnalyseProcessus"} updateValidations={props.updateValidations} uploadFile={uploadFile} removeFile={removeFile} file={file} index={index} key={file.path} />
			);
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptedFiles, uploadFile]);

	const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	]);

	return (
		<div className="modal-content ajouter-form">
			<h3 className="modal-title">Ajouter un document à l'analyse du processus</h3>
			<button className="modal-button" onClick={() => close()}>
				x
			</button>
			<form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
				<label className='ajouter-form-label default-label' htmlFor="nom">Document résultat d'analyse</label>
				<div className="container">
					{files.length === 0 &&
						<div {...getRootProps({ style })}>
							<input {...getInputProps()} />
							<Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
							<p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
						</div>
					}
					<div>
						<ul className='fileList'>{files}</ul>
					</div>
				</div>
				<input
					disabled={isButtonDisabled || files.length === 0}
					className="validate-button-fw default-button ajouter-salarie-button"
					type="submit"
					value="Ajouter"
				/>
			</form>
		</div>
	);
};

export default AjouterDocumentAnalyse;