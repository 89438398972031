import React, { useRef, useState } from "react";
import ProfilePicture from '../../ui/ProfilePicture';
import DefaultProfilePicture from '../../ui/DefaultProfilePicture';

import { getNumeroTransportDemande, getNumeroTransportType } from "../../../utils/GeneralHelper";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import Map, { Marker } from 'react-map-gl/maplibre';
import axiosInstance from "../../../utils/axiosInstance";
import { showError } from "../../../utils/ErrorHelper";
import customToast from "../../../utils/ToastifyHelper";
import { socket } from '../../../utils/Socket';

const ResumerTransportModal = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [isSubmitting, setSubmitting] = useState(false);
    const [informationsChauffeurText, setInformationsChauffeurText] = useState(props.transportData.informationsTransport ? props.transportData.informationsTransport : "");

    //--------------- Map ------------------
    const [afficherCarteDepart, setAfficherCarteDepart] = useState(false);
    const [afficherCarteArriver, setAfficherCarteArriver] = useState(false);

    const lieuDepartMapRef = useRef();
    const lieuArriverMapRef = useRef();
    const mapTransportRef = useRef();

    const [lieuDepartMarker, setLieuDepartMarker] = useState(props.transportData.demande.hasOwnProperty("lieuDepart") && props.transportData.demande.lieuDepart._id.adresse !== null && props.transportData.demande.lieuDepart._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuDepart._id.adresse.gps.latitude !== null && props.transportData.demande.lieuDepart._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuDepart._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuDepart._id.adresse.gps.longitude } : null);
    const [lieuArriverMarker, setLieuArriverMarker] = useState(props.transportData.demande.hasOwnProperty("lieuArriver") && props.transportData.demande.lieuArriver._id.adresse !== null && props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude } : null);

    const getValidationMateriauAmpiroll = () => {
        return props.transportData.validationMateriau.map((validation) => {
            return {
                materiau: validation.provenanceMateriau !== null ? `${validation.materiau.libelle_mat} (${validation.provenanceMateriau.libelle_lieu})` : validation.materiau.libelle_mat,
                quantite: validation.quantite.toString()
            };
        });
    };

    const getValidationMateriauAller = () => {
        return props.transportData.validationTours.map((validation) => {
            return validation.validationAller.validationMateriau.map((materiauValidation) => {
                return {
                    materiau: materiauValidation.provenanceMateriau !== null ? `${materiauValidation.materiau.libelle_mat} (${materiauValidation.provenanceMateriau.libelle_lieu})` : materiauValidation.materiau.libelle_mat,
                    quantite: materiauValidation.quantite.toString()
                };
            });
        });
    };

    const getValidationMateriauRetour = () => {
        return props.transportData.validationTours.map((validation) => {
            return validation.validationRetour.validationMateriau.map((materiauValidation) => {
                return {
                    materiau: materiauValidation.provenanceMateriau !== null ? `${materiauValidation.materiau.libelle_mat} (${materiauValidation.provenanceMateriau.libelle_lieu})` : materiauValidation.materiau.libelle_mat,
                    quantite: materiauValidation.quantite.toString()
                };
            });
        });
    };

    return (
        <div className="new-modal">
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="new-modal-topSection">
                <h3 className='modal-title'>{getNumeroTransportType(props.transportData.demande.type)}{getNumeroTransportDemande(props.transportData.demande.typeDemande)}-{props.transportData.demande.numeroTransport}</h3>
                <p>Demande de <span style={{ textDecoration: "underline" }}>{props.transportData.demande.creator.nom} {props.transportData.demande.creator.prenom}</span> le {format(new Date(props.transportData.demande.dateDemande), "dd/MM/yyyy")} à {props.transportData.demande.dateDemande.substring(11, 16)}, pour le {format(new Date(props.transportData.demande.dateLivraison), "dd/MM/yyyy")} {props.transportData.demande.horaire.heure !== null && props.transportData.demande.horaire.minute !== null ? `à ${props.transportData.demande.horaire.heure}:${props.transportData.demande.horaire.minute}` : ""} </p>
                <p>Transport débuté le {format(new Date(props.transportData.dateDebutTransport), "dd/MM/yyyy")} à {props.transportData.dateDebutTransport.substring(11, 16)}</p>
            </div>
            <div className="new-modal-mainSection">
                <div className='formSection mgB-m1'>
                    <h4 className='mgB-s3'>Transport terminé le {format(new Date(props.transportData.dateValidation), "dd/MM/yyyy")} à {props.transportData.dateValidation.substring(11, 16)}</h4>
                    {props.transportData.type === "TransportCamion" &&
                        <div className='label-and-input' style={{ display: "flex", marginBottom: 0 }}>
                            <label htmlFor='documentTitle' className='form-label  mgR-s2'>{props.transportData.demande.typeDemande}:</label>
                            <p>{props.transportData.demande.hasOwnProperty("provenanceMateriau") && props.transportData.demande.provenanceMateriau !== null ? `${props.transportData.demande.materiau.libelle_mat} (${props.transportData.demande.provenanceMateriau.libelle_lieu}) avec un ${props.transportData.demande.typeVehicule}` : `${props.transportData.demande.materiau.libelle_mat} avec un ${props.transportData.demande.typeVehicule}`}</p>
                        </div>
                    }
                    {props.transportData.type === "TransportPorteEngin" &&
                        <div className='label-and-input' style={{ display: "flex", marginBottom: 0 }}>
                            <p>Transfert d'un {props.transportData.demande.typeVehicule}</p>
                        </div>
                    }
                    <div className="mgB-s3" style={{ display: "flex", alignItems: "baseline" }}>
                        <div style={{ flex: 1, marginRight: "1.4rem" }}>
                            <div className='label-and-input horizontal' style={{ marginBottom: 0 }}>
                                <label htmlFor='documentTitle' className='form-label'>De</label>
                                <p style={{ flex: 1 }}>{props.transportData.demande.lieuDepart._id.libelle_lieu}</p>
                            </div>
                            {afficherCarteDepart &&
                                <div style={{ minHeight: "180px", marginTop: "0.8rem" }}>
                                    <Map
                                        attributionControl={false}
                                        ref={lieuDepartMapRef}
                                        initialViewState={props.transportData.demande.lieuDepart._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuDepart._id.adresse.gps.latitude !== null && props.transportData.demande.lieuDepart._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuDepart._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuDepart._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                        style={{ width: "100%", minHeight: "180px", borderRadius: ".5rem" }}
                                        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                    >
                                        {lieuDepartMarker !== null &&
                                            <Marker longitude={lieuDepartMarker.longitude} latitude={lieuDepartMarker.latitude} anchor="bottom">

                                            </Marker>
                                        }
                                    </Map>
                                </div>
                            }
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className='label-and-input horizontal' style={{ marginBottom: 0 }}>
                                <label htmlFor='documentTitle' className='form-label'>Vers</label>
                                <p style={{ flex: 1 }}>{props.transportData.demande.lieuArriver._id.libelle_lieu}</p>
                                <div>
                                    <button
                                        type="button"
                                        data-tip
                                        data-for={"afficherMapDe-" + props.transportData.demande.lieuArriver._id._id}
                                        onClick={() => { setAfficherCarteArriver(!afficherCarteArriver); setAfficherCarteDepart(!afficherCarteDepart); }}
                                        className="select-semaine-button default-radius mgL-s2"
                                    >
                                        <FontAwesomeIcon className='showMap-icon' style={{ fontSize: "2.5rem" }} icon={faMapLocationDot} />
                                    </button>
                                    <ReactTooltip
                                        backgroundColor="#1E1E1E"
                                        offset={{ top: 5 }}
                                        id={"afficherMapDe-" + props.transportData.demande.lieuArriver._id._id}
                                        place="bottom"
                                        effect="solid"
                                    >
                                        {afficherCarteArriver ? "Masquer" : "Afficher"}
                                    </ReactTooltip>
                                </div>
                            </div>
                            {
                                afficherCarteArriver &&
                                <div style={{ minHeight: "180px", marginTop: "0.8rem" }}>
                                    <Map
                                        attributionControl={false}
                                        ref={lieuArriverMapRef}
                                        initialViewState={props.transportData.demande.lieuArriver._id.adresse.hasOwnProperty("gps") && props.transportData.demande.lieuArriver._id.adresse.gps.latitude !== null && props.transportData.demande.lieuArriver._id.adresse.gps.longitude !== null ? { latitude: props.transportData.demande.lieuArriver._id.adresse.gps.latitude, longitude: props.transportData.demande.lieuArriver._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                        style={{ width: "100%", minHeight: "180px", borderRadius: ".5rem" }}
                                        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                    >
                                        {lieuArriverMarker !== null &&
                                            <Marker longitude={lieuArriverMarker.longitude} latitude={lieuArriverMarker.latitude} anchor="bottom">

                                            </Marker>
                                        }
                                    </Map>
                                </div>
                            }
                        </div>
                    </div>
                    <h4 className='mgB-s1'>Chauffeur</h4>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div className="salarieBasicInfo mgT-s2 mgB-s3">
                            {props.transportData.chauffeur._id.profilePicture !== null ?
                                <ProfilePicture width="5rem" height="5rem" edit={false} profilePicture={props.transportData.chauffeur._id.profilePicture} /> :
                                <DefaultProfilePicture additionalClassname={"form"} width="5rem" height="5rem" edit={false} />
                            }
                            <div className="salarieNomStatut-container" style={{ cursor: !props.editable ? "pointer" : "auto" }}>
                                <p><span className="salarieNom">{props.transportData.chauffeur._id.nom} {props.transportData.chauffeur._id.prenom}</span></p>
                                <p className="salarieStatut">{props.transportData.chauffeur._id.login_matricule}</p>
                            </div>
                        </div>
                        <div className="mgB-s2" style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <div className='label-and-input' style={{ flex: 1, marginRight: "1.4rem", marginBottom: 0, display: "flex" }}>
                                <label htmlFor='documentTitle' className='form-label mgR-s2'>Tracteur/Porteur:</label>
                                <p>{props.transportData.tracteurPorteur.numeroParc ? `n°${props.transportData.tracteurPorteur.numeroParc} - ${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}` : `${props.transportData.tracteurPorteur.immatriculation} - ${props.transportData.tracteurPorteur.marque}`}</p>
                            </div>
                            <div className='label-and-input' style={{ flex: 1, display: "flex", marginBottom: 0 }}>
                                <label htmlFor='documentTitle' className='form-label  mgR-s2'>Remorque:</label>
                                <p>{props.transportData.remorque.numeroParc ? `n°${props.transportData.remorque.numeroParc} - ${props.transportData.remorque.immatriculation} - ${props.transportData.remorque.marque}` : `${props.transportData.remorque.immatriculation} - ${props.transportData.remorque.marque}`}</p>
                            </div>
                        </div>
                    </div>
                    {props.transportData.informationsTransport &&
                        <div className='label-and-input' style={{ marginBottom: "0.8rem" }}>
                            <label htmlFor='informationsTransport' className='form-label'>Informations pour le chauffeur</label>
                            <textarea disabled style={{ minHeight: "7.8rem" }} name='informationsTransport' className={`new-default-input`} type="text" placeholder="Informations pour le chauffeur" value={props.transportData.informationsTransport} />
                        </div>
                    }
                    {props.transportData.messageValidation &&
                        <div className='label-and-input' style={{ marginBottom: "0.8rem" }}>
                            <label htmlFor='messageValidation' className='form-label'>Commentaire du chauffeur</label>
                            <textarea disabled style={{ minHeight: "7.8rem" }} name='messageValidation' className={`new-default-input`} type="text" placeholder="Commentaire du chauffeur" value={props.transportData.messageValidation} />
                        </div>
                    }
                    {props.transportData.type === "TransportAmpiroll" &&
                        <div className="mgT-s2">
                            <h4>Transport des matériaux</h4>
                            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                <p className="form-label" style={{ flex: 1 }}>Matériaux</p>
                                <p className="form-label" style={{ flex: 0.4 }}>Quantité</p>
                            </div>
                            {getValidationMateriauAmpiroll().map((validation, index) => {
                                return <div key={index} style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    <p style={{ flex: 1 }}>{validation.materiau}</p>
                                    <p style={{ flex: 0.4 }}>{validation.quantite} t</p>
                                </div>;
                            })}
                        </div>
                    }
                    {props.transportData.type === "TransportCamion" &&
                        <div className="mgT-s2">
                            <h4 className="mgB-s2">Transport des matériaux</h4>
                            {props.transportData.demande.doubleFret ?
                                <>
                                    <div className="mgB-s2">
                                        <p style={{ fontWeight: "600" }}>De {props.transportData.demande.lieuDepart._id.libelle_lieu} vers {props.transportData.demande.lieuArriver._id.libelle_lieu}</p>
                                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                            <p className="form-label" style={{ flex: 0.1 }}>Tour</p>
                                            <p className="form-label" style={{ flex: 1 }}>Matériaux</p>
                                            <p className="form-label" style={{ flex: 0.4 }}>Quantité</p>
                                        </div>
                                        {getValidationMateriauAller().map((validation, tour) => {
                                            return (
                                                validation.map((materiauValidation, index) => {
                                                    return <div key={`${tour}-${index}`} style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                        <p style={{ flex: 0.1, display: "flex" }}>{tour + 1}</p>
                                                        <p style={{ flex: 1 }}>{materiauValidation.materiau}</p>
                                                        <p style={{ flex: 0.4 }}>{materiauValidation.quantite} t</p>
                                                    </div>;
                                                })
                                            );
                                        })}
                                    </div>
                                    <div className="mgB-m1">
                                        <p style={{ fontWeight: "600" }}>De {props.transportData.demande.lieuArriver._id.libelle_lieu} vers {props.transportData.demande.lieuDepart._id.libelle_lieu}</p>
                                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                            <p className="form-label" style={{ flex: 0.1 }}>Tour</p>
                                            <p className="form-label" style={{ flex: 1 }}>Matériaux</p>
                                            <p className="form-label" style={{ flex: 0.4 }}>Quantité</p>
                                        </div>
                                        {getValidationMateriauRetour().map((validation, tour) => {
                                            return (
                                                validation.map((materiauValidation, index) => {
                                                    return <div key={`${tour}-${index}`} style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                        <p style={{ flex: 0.1, display: "flex" }}>{tour + 1}</p>
                                                        <p style={{ flex: 1 }}>{materiauValidation.materiau}</p>
                                                        <p style={{ flex: 0.4 }}>{materiauValidation.quantite} t</p>
                                                    </div>;
                                                })
                                            );
                                        })}
                                    </div>
                                </>
                                :
                                <div className="mgB-s3">
                                    <p style={{ fontWeight: "600" }}>De {props.transportData.demande.lieuDepart._id.libelle_lieu} vers {props.transportData.demande.lieuArriver._id.libelle_lieu}</p>
                                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                        <p className="form-label" style={{ flex: 0.1 }}>Tour</p>
                                        <p className="form-label" style={{ flex: 1 }}>Matériaux</p>
                                        <p className="form-label" style={{ flex: 0.4 }}>Quantité</p>
                                    </div>
                                    {getValidationMateriauAller().map((validation, tour) => {
                                        return (
                                            validation.map((materiauValidation, index) => {
                                                return <div key={`${tour}-${index}`} style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                    <p style={{ flex: 0.1, display: "flex" }}>{tour + 1}</p>
                                                    <p style={{ flex: 1 }}>{materiauValidation.materiau}</p>
                                                    <p style={{ flex: 0.4 }}>{materiauValidation.quantite} t</p>
                                                </div>;
                                            })
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

        </div>
    );
};

export default ResumerTransportModal;