import * as actionTypes from './actionTypes';

export const setFormOptions = (nomFormOptions, formOptions) => {
    return {
        type: actionTypes.SET_FORM_OPTIONS,
        nomFormOptions,
        formOptions
    };
};

export const addFormOptions = (nomFormOptions, option) => {
    return {
        type: actionTypes.ADD_FORM_OPTIONS,
        nomFormOptions,
        option
    };
};

export const resetFormOptions = () => {
    return {
        type: actionTypes.RESET_FORM_OPTIONS,
    };
};