export const getCycleProcessus = (validationProcessus, dateRealisationProcessus) => {
    for (const cycleProcessus of validationProcessus) {
        let dateDebutCycle = cycleProcessus.validationTest !== null ? new Date(cycleProcessus.validationTest.dateValidation)
            : cycleProcessus.firstValidation !== null ? new Date(cycleProcessus.firstValidation.dateValidation)
                : cycleProcessus.secondValidation !== null ? new Date(cycleProcessus.secondValidation.dateValidation)
                    : cycleProcessus.thirdValidation !== null ? new Date(cycleProcessus.thirdValidation.dateValidation)
                        : null;
        if (dateDebutCycle) {
            let dateFinCycle = new Date(dateDebutCycle.getFullYear() + 1, dateDebutCycle.getMonth(), dateDebutCycle.getDate());
            if (dateRealisationProcessus >= dateDebutCycle && dateRealisationProcessus < dateFinCycle) {
                return cycleProcessus;
            }
        }
    }
    return null;
};

// Retourne la concentration d'un processus en choisisant le bon cycle
export const getConcentrationProcessus = (processus, dateRealisationProcessus) => {
    if (processus.validationProcessus.length) {
        const cycleProcessus = getCycleProcessus(processus.validationProcessus, dateRealisationProcessus);
        if (cycleProcessus) {
            let validationTest = cycleProcessus.validationTest !== null ? cycleProcessus.validationTest.concentrationFibre : null;
            let firstValidation = cycleProcessus.firstValidation !== null ? cycleProcessus.firstValidation.concentrationFibre : null;
            let secondValidation = cycleProcessus.secondValidation !== null ? cycleProcessus.secondValidation.concentrationFibre : null;
            let thirdValidation = cycleProcessus.thirdValidation !== null ? cycleProcessus.thirdValidation.concentrationFibre : null;
            let concentrationValues = [validationTest, firstValidation, secondValidation, thirdValidation];
            concentrationValues = concentrationValues.filter(variable => variable !== null).map((item) => {
                return item.concentrationFibre;
            });
            for (const cycleControle of cycleProcessus.controle) {
                concentrationValues.push(cycleControle.concentrationFibre);
            }
            const maxConcentrationObject = concentrationValues.reduce((maxVariable, currentVariable) =>
                (maxVariable && maxVariable.concentrationFibre > currentVariable.concentrationFibre) ? maxVariable : currentVariable,
                null
            );
            return maxConcentrationObject;
        }
        else {
            if (processus.hasOwnProperty("estimationConcentration")) {
                return { rc: processus.estimationConcentration, f: null };
            }
            else {
                return { rc: 0, f: null };
            }
        }
    }
    else if (processus.hasOwnProperty("estimationConcentration")) {
        return { rc: processus.estimationConcentration, f: null };
    }
    else {
        return { rc: 0, f: null };
    }
};

// Retourne parmi une liste de processus celui qui a la concentration max ainsi que sa concentration
export const getProcessusMax = (listeProcessus, dateRealisationProcessus) => {
    let processusMax = null;
    let concentrationMax = null;
    for (const processus of listeProcessus) {
        const concentrationProcessus = getConcentrationProcessus(processus, dateRealisationProcessus);
        if (concentrationMax === null || concentrationProcessus.rc > concentrationMax.rc) {
            concentrationMax = concentrationProcessus;
            processusMax = processus;
        }
    }
    return { processus: processusMax, concentration: concentrationMax };
};
