import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as actionCreators from '../../store/actions/exportAction';
import axiosInstance from '../../utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { formatIntTwoDigitString, banqueHeureTotal } from '../../utils/GeneralHelper';
import { getBanqueHeureColumns } from '../../components/tables/TableColumns';
import Modal from '../../components/Modal';
import ModifierBanqueHeureForm from '../../components/forms/ReleveHeureForms/ModifierBanqueHeureForm';
import customToast from '../../utils/ToastifyHelper';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import TableRender from '../../components/tables/TableRender';
import { showError } from '../../utils/ErrorHelper';

const onLoadPage = (dispatch, idUtilisateur) => {
    dispatch(actionCreators.getBanqueHeureById(idUtilisateur));
};

const onQuitPage = (dispatch) => {
    dispatch(actionCreators.unloadBanqueHeure());
};

const BanqueHeureConsultationAdmin = () => {
    const dispatch = useDispatch();
    let { idUtilisateur } = useParams();

    const [utilisateur, setUtilisateur] = useState(null);
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    useEffect(() => {
        axiosInstance.get(`/utilisateurs/${idUtilisateur}`)
            .then((result) => {
                setUtilisateur(result.data);
            }).catch((err) => {
                showError(err);
            });
        onLoadPage(dispatch, idUtilisateur);
        return () => {
            onQuitPage(dispatch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idUtilisateur]);

    const banqueHeureState = useSelector(
        state => state.banqueHeure.horaire
    );

    const totalHeure = banqueHeureTotal(banqueHeureState);

    const openAjouterBanqueHeure = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierBanqueHeureForm utilisateur={idUtilisateur} positif={true} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerBanqueHeure = (banqueHeureData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerBanqueHeure" banqueHeureData={banqueHeureData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openEnleverBanqueHeure = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierBanqueHeureForm utilisateur={idUtilisateur} positif={false} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    return (
        <div className='section'>
            <div className="container-basic mgB-m2">
                <div className="BanqueHeureAdmin-Header">
                    <div className="leftSide">
                        <h1 className="h1-blue-dark">Banque d'heures de {utilisateur !== null && utilisateur.nom} {utilisateur !== null && utilisateur.prenom}</h1>
                        <h3 className="banqueHeure-Total">{totalHeure.val === "neg" ? <span className='totalHeure-negatif'>-{formatIntTwoDigitString(totalHeure.heure)}h{formatIntTwoDigitString(totalHeure.minute)}</span> : totalHeure.val === "pos" ? <span className='totalHeure-positif'>+{formatIntTwoDigitString(totalHeure.heure)}h{formatIntTwoDigitString(totalHeure.minute)}</span> : <span className='totalHeure-nul'>{formatIntTwoDigitString(totalHeure.heure)}h{formatIntTwoDigitString(totalHeure.minute)}</span>} en banque à ce jour</h3>
                    </div>
                    <div className="banqueButtonContainer">
                        <button onClick={() => openAjouterBanqueHeure()} className='addBanque-button'><p>Ajouter des heures</p></button>
                        <button onClick={() => openEnleverBanqueHeure()} className='removeBanque-button'><p>Enlever des heures</p></button>
                    </div>
                </div>
            </div>
            <TableRender
                columns={getBanqueHeureColumns("Administration", (banqueHeureData) => openSupprimerBanqueHeure(banqueHeureData))}
                tableData={banqueHeureState}
                sortBy={[{ id: 'dateModif', desc: true }]}
                pageName="OneBanqueHeureAdmin"
            />
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default BanqueHeureConsultationAdmin;