import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import SortIcon from '../icon/SortIcon';

const SimpleTableRender = (props) => {

    const columns = useMemo(() => props.columns, []);

    const tableInstance = useTable({
        columns,
        data: props.tableData,
        initialState: {
            sortBy: props.sortBy
        }
    }, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div className={`simple-table-container${props.noBorder ? " noBorder" : ""}`}>
            {props.tableTitle && <>{props.tableTitle}</>}
            <table className="simple-table" {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr key="header" {...headerGroup.getHeaderGroupProps}>
                                {
                                    headerGroup.headers.map((column) => {
                                        return (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                onClick={(e) => {
                                                    if (column.canSort) {
                                                        column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                                                    }

                                                    // onSortedColumnChange(column.id)
                                                }}>{column.render("Header")}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <SortIcon classIconHaut="filter-icon filter-icon__haut filter-icon__active" classIconBas="filter-icon filter-icon__bas" />
                                                        : <SortIcon classIconBas="filter-icon filter-icon__bas filter-icon__active" classIconHaut="filter-icon filter-icon__haut" />
                                                    : <p></p>
                                                }
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={i} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
};

export default SimpleTableRender;