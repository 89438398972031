import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import Modal from '../../components/Modal';
import DesarchiverSalarieForm from '../../components/forms/DesarchiverSalarieForm';
import ArchiveSalarieDetail from '../../components/ArchiveSalarieDetail';
import ModifierSalarieForm from '../../components/forms/ModifierSalarierForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import TableRender from '../../components/tables/TableRender';
import { getArchiveSalarieColumns } from '../../components/tables/TableColumns';
import { advancedSearch } from '../../utils/GeneralHelper';
import Breadcrumb from '../../components/ui/Breadcrumb';
import SearchInput from '../../components/ui/SearchInput';


const onLoadPage = () => (actionCreators.getArchiveList());
const onQuitPage = () => (actionCreators.unloadUserList());

const GestionArchiveSalarie = () => {
    const userListState = useSelector(
        state => state.userList
    );
    const dispatch = useDispatch();
    const [showedItem, setItemToShow] = useState([]);
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");
    const [itemStatutToShow, setItemStatutToShow] = useState("All");
    const [itemSecteurToShow, setItemSecteurToShow] = useState("All");
    const [searchBarContent, setSearchBarContent] = useState(null);

    useEffect(() => {
        dispatch(onLoadPage());
        return () => {
            dispatch(onQuitPage());
            setItemToShow([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const salarieWas = (salarie, statut) => {
        salarie._id.historiqueStatut.forEach((historique) => {
            if (historique.statut === statut) {
                return true;
            }
        });
        return false;
    };

    useEffect(() => {
        setItemToShow([]);
        if (userListState.archiveList) {
            userListState.archiveList.forEach((salarie) => {
                if ((itemStatutToShow === "All" || salarieWas(salarie, itemStatutToShow)) && (itemSecteurToShow === "All" || salarie._id.secteur === itemSecteurToShow) && (!searchBarContent || advancedSearch(salarie._id.nom, searchBarContent) || advancedSearch(salarie._id.prenom, searchBarContent) || advancedSearch(salarie._id.login_matricule, searchBarContent))) {
                    let addOuvrier = { user: salarie };
                    setItemToShow(oldArray => [...oldArray, addOuvrier]);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userListState, itemStatutToShow, searchBarContent, itemSecteurToShow]);

    const selectStatut = (e) => {
        setItemStatutToShow(e.target.value);
    };

    const selectSecteur = (e) => {
        setItemSecteurToShow(e.target.value);
    };

    const searchSalarie = (e) => {
        if (e.target.value !== '') {
            setSearchBarContent(e.target.value);
        }
        else {
            setSearchBarContent(null);
        }

    };

    const deleteSalarie = () => {
        dispatch(onQuitPage());
        dispatch(onLoadPage());
    };

    const openSupprimerSalarie = (salarieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerSalarie" salarie={salarieData} supprimerSalarie={() => deleteSalarie()} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const desarchiverSalarie = () => {
        dispatch(onQuitPage());
        dispatch(onLoadPage());
    };

    const openDesarchiverSalarie = (salarieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<DesarchiverSalarieForm salarie={salarieData} desarchiverSalarie={() => desarchiverSalarie()} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierSalarie = (salarieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierSalarieForm salarie={salarieData} modifierSalarie={() => desarchiverSalarie()} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSalarieDetail = (salarieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ArchiveSalarieDetail salarie={salarieData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    return (
        <div className='section'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                <h1>Archive des salariés</h1>
            </div>
            <Breadcrumb
                links={[
                    { label: "Geston des données", link: "/gestion-de-donnees" },
                    { label: "Archive salariés", link: "/gestion-de-donnees/archive-salarie" },
                ]}
            />
            <div className="filterContainer mgT-m1">
                <SearchInput placeholder="Rechercher un salarié" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={searchSalarie} />
                <select className="new-default-input" onChange={(e) => selectSecteur(e)} name="secteurSelect" id="secteur-select">
                    <option value="All">Tous les secteurs</option>
                    <option value="Travaux publics">Travaux publics</option>
                    <option value="Gros oeuvres">Gros oeuvres</option>
                    <option value="3D">3D</option>
                    <option value="Transport">Transport</option>
                    <option value="Atelier">Atelier</option>
                </select>
                <select className="new-default-input" onChange={(e) => selectStatut(e)} name="statutSelect" id="statut-select">
                    <option value="All">Tous les statuts</option>
                    <option value="Administration">Administration</option>
                    <option value="Chauffeur">Chauffeur</option>
                    <option value="Chef de chantier">Chef de chantier</option>
                    <option value="Ouvrier">Ouvrier</option>
                    <option value="Intérimaire">Interimaire</option>
                </select>
            </div>
            {/* <div className="container-basic mgB-m2">
                <h1 className="h1-blue-dark">Archive des salariés</h1>
                <div className="tableau-top-section">
                    <h3>{showedItem.length} salarié{showedItem.length > 1 && "s"} {showedItem.length > 1 ? "correspondent" : "correspond"} à votre recherche</h3>
                    <input className="header-input" onChange={(e) => searchSalarie(e)} name="searchSalarie" type="text" placeholder="Rechercher un salarié" />

                </div>
            </div> */}
            <TableRender
                columns={getArchiveSalarieColumns((salarieData) => openSalarieDetail(salarieData), (salarieData) => openDesarchiverSalarie(salarieData), (salarieData) => openSupprimerSalarie(salarieData), (salarieData) => openModifierSalarie(salarieData))}
                tableData={showedItem}
                sortBy={[
                    {
                        id: 'user._id.nom',
                        desc: false,
                        disableSortRemove: true,
                    }
                ]}
                pageName="ArchiveSalarie"
            />
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default GestionArchiveSalarie;