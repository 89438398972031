import * as actionTypes from './actionTypes';
import axiosInstance from '../../utils/axiosInstance';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';

export const loadDocumentsPublics = (documents) => {
    return {
        type: actionTypes.GET_DOCUMENTS_PUBLICS,
        documentsPublics: documents
    };
};

const loadDocuments = (documents) => {
    return {
        type: actionTypes.LOAD_DOCUMENTS,
        documents: documents
    };
};

export const updateGestionsDocumentsChantiers = (document) => {
    return {
        type: actionTypes.UPDATE_GESTIONS_DOCUMENTS_CHANTIERS,
        document
    };
};


export const unloadDocuments = () => {
    return {
        type: actionTypes.UNLOAD_DOCUMENTS
    };
};

export const deleteDocument = (documentId) => {
    return {
        type: actionTypes.DELETE_DOCUMENT,
        documentId: documentId
    };
};

export const deleteDocumentSalarie = (documentId) => {
    return {
        type: actionTypes.DELETE_DOCUMENT_SALARIE,
        documentId: documentId
    };
};

export const deleteDocumentGestionsChantiers = (documentId) => {
    return {
        type: actionTypes.DELETE_DOCUMENTS_GESTION_CHANTIERS,
        documentId: documentId
    };
};

export const addDocument = (document) => {
    return {
        type: actionTypes.ADD_DOCUMENT,
        document: document
    };
};

export const addGestionsChantiersDocuments = (document) => {
    return {
        type: actionTypes.ADD_GESTIONS_CHANTIERS_DOCUMENTS,
        document
    };
};

export const getDocumentsPublics = () => (dispatch) => {
    axiosInstance.get("uploads/documentsPublics/")
        .then((result) => {
            dispatch(loadDocumentsPublics(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const getDocumentsGestionsChantiers = () => (dispatch) => {
    axiosInstance.get("uploads/gestionDocumentsChantiers/")
        .then((result) => {
            dispatch(loadDocuments(result.data));
        }).catch((err) => {
            showError(err);
        });
};

export const modifierDocumentPublic = (document) => {
    return {
        type: actionTypes.EDIT_DOCUMENT_PUBLICS,
        document
    };
};
