import React, { useRef, useState } from 'react';
import { Icon } from '@iconify/react';

const SearchInput = (props) => {
    const [isFocused, setFocused] = useState(false);
    const inputRef = useRef(null);

    return (
        <div style={props.fullwidth && { flex: 1 }} onClick={() => { if (!isFocused) { setFocused(true); inputRef.current.focus(); } }} className={`${isFocused ? "search-input focus" : "search-input"}`}>
            <div className='searchIcon-container'>
                <Icon icon="mdi:search" />
            </div>
            <input value={props.searchContent} onChange={props.onChangeSearch} onBlur={() => setFocused(false)} onClick={() => { if (!isFocused) { setFocused(true); } }} ref={inputRef} placeholder={props.placeholder ? props.placeholder : ""} type={"text"} />
        </div>
    );
};

export default SearchInput;