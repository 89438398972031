import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../../utils/axiosInstance';
import customToast from '../../../utils/ToastifyHelper';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { showError } from '../../../utils/ErrorHelper';


const AjouterGestionTypeSuiviSalarieForm = (props) => {

    const schema = yup.object().shape({
        nomSuiviFormation: yup.string().required("Veuillez saisir un nom"),
        destine: yup.string().required("Veuillez saisir une marque"),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [hasOrganismeFormation, setHasOrganismeFormation] = useState(false);
    const [hasDocument, setHasDocument] = useState(false);
    const [hasFinValidite, setHasFinValidite] = useState(false);
    const [hasProchainControle, setHasProchainControle] = useState(false);

    const onSubmit = data => {
        setButtonDisabled(true);
        axiosInstance.post("/gestionTypeSuivie", { libelleGestion: data.nomSuiviFormation, typeGestion: data.destine, hasOrganismeFormation, hasDocument, hasFinValidite, hasProchainControle })
            .then((result) => {
                props.ajouterSuiviFormationSalarie(result.data);
                customToast.success("Le type suivi ou la formation a été ajouté avec succès");
                close();
            }).catch((err) => {
                showError(err);
            });
        setButtonDisabled(false);
    };


    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Ajouter un type de suivi au salarié</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
                <label className='ajouter-form-label default-label' htmlFor="nom">Nom du suivi/formation</label>
                <input className="ajouter-form-input default-input" name="nomSuiviFormation" type="text" placeholder="Nom du suivi ou de la formation" {...register("nomSuiviFormation", { required: true })} />
                {errors['nomSuiviFormation'] && errors['nomSuiviFormation'].type === "required" && <p className="login-error-message">Veuillez saisir un nom pour le suivi ou la formation</p>}
                <label className='ajouter-form-label default-label' htmlFor="nom">Formation ou suivi destiné à</label>
                <select className="new-default-input" name="destine" {...register("destine", { required: true })}>
                    <option value="Tous les salariés">Tous les salariés</option>
                    <option value="Les salariés amiante">Les salariés amiante</option>
                    <option value="Les chauffeurs">Les chauffeurs</option>
                </select>
                <label className='ajouter-form-label default-label' htmlFor="nom">Sélectionnez les champs optionnels</label>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                    <div className="checkbox-form">
                        <input className='login-checkbox'
                            type="checkbox"
                            checked={hasOrganismeFormation}
                            onChange={() => setHasOrganismeFormation(!hasOrganismeFormation)}
                        />
                        <label onClick={() => setHasOrganismeFormation(!hasOrganismeFormation)} className='checkbox-label' htmlFor="checkbox">Organisme de formation</label>
                    </div>
                    <div className="checkbox-form">
                        <input className='login-checkbox'
                            type="checkbox"
                            checked={hasDocument}
                            onChange={() => setHasDocument(!hasDocument)}
                        />
                        <label onClick={() => setHasDocument(!hasDocument)} className='checkbox-label' htmlFor="checkbox">Importer des documents</label>
                    </div>
                    <div className="checkbox-form">
                        <input className='login-checkbox'
                            type="checkbox"
                            checked={hasFinValidite}
                            onChange={() => setHasFinValidite(!hasFinValidite)}
                        />
                        <label onClick={() => setHasFinValidite(!hasFinValidite)} className='checkbox-label' htmlFor="checkbox">Date fin de validité</label>
                    </div>
                    <div className="checkbox-form">
                        <input className='login-checkbox'
                            type="checkbox"
                            checked={hasProchainControle}
                            onChange={() => setHasProchainControle(!hasProchainControle)}
                        />
                        <label onClick={() => setHasProchainControle(!hasProchainControle)} className='checkbox-label' htmlFor="checkbox">Date du prochain contrôle</label>
                    </div>
                </div>
                <input disabled={isButtonDisabled} className="validate-button-fw default-button ajouter-salarie-button" type="submit" value="Ajouter" />
            </form>
        </div>
    );
};

export default AjouterGestionTypeSuiviSalarieForm;