import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const materiauxListState = { allMateriaux: [] };

const reducer = (state = materiauxListState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_MATERIAUX_LIST:
            return updateObject(state, { allMateriaux: action.materiauxList });
        case actionTypes.UNLOAD_MATERIAUX_LIST:
            return { allMateriaux: [] };
        case actionTypes.ADD_MATERIAUX:
            return updateObject(state, { allMateriaux: state.allMateriaux.concat(action.materiaux) });
        case actionTypes.MODIFY_MATERIAUX:
            return {
                ...state,
                allMateriaux: state.allMateriaux.map(
                    (materiaux) => materiaux._id === action.materiauxUpdated._id ?
                        { ...materiaux, libelle_mat: action.materiauxUpdated.libelle_mat, provenances: action.materiauxUpdated.provenances }
                        : materiaux
                )
            };
        case actionTypes.DELETE_MATERIAUX:
            return {
                ...state,
                allMateriaux: state.allMateriaux.filter(materiaux => materiaux._id !== action.materiauxId)
            };
        default:
            return state;
    }
};

export default reducer;
