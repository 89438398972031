import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as actionCreators from '../store/actions/exportAction';

// DashboardPagesImport
import DashboardAdmin from '../components/Dashboard/DashboardAdmin';
import DashboardChefChantier from '../components/Dashboard/DashboardChefChantier';
import DashboardChauffeur from '../components/Dashboard/DashboardChauffeur';
import DashboardOuvrier from '../components/Dashboard/DashboardOuvrier';
import DashboardEncadrantTechnique from '../components/Dashboard/DashboardEncadrantTechnique';
import DashboardConducteurTravaux from '../components/Dashboard/DashboardConducteurTravaux';
import DashboardDispatcher from '../components/Dashboard/DashboardDispatcher';


const onAutoConnect = (refreshToken) => (actionCreators.loginByTokenStored(refreshToken));

const HomePage = () => {
    const isAuthenticated = useSelector(
        state => state.user.isAuth
    );
    const user = useSelector(
        state => state.user.user
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isAuthenticated) {
            if (!sessionStorage.getItem('refreshToken')) {
                navigate("/login");
            }
            else {
                dispatch(onAutoConnect(sessionStorage.getItem('refreshToken')));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);
    // pages
    const homeAdmin = <DashboardAdmin />;
    const homeChefChantier = <DashboardChefChantier />;
    const homeOuvrier = <DashboardOuvrier />;
    const homeChauffeur = <DashboardChauffeur />;
    const homeInterimaire = <DashboardOuvrier />;

    const getDashboard = (user) => {
        switch (user.statut) {
            case "Administration":
                return (
                    <DashboardAdmin />
                );
            case "Chef de chantier":
                return (
                    <DashboardChefChantier />
                );

            case "Encadrant technique":
                return (
                    <DashboardEncadrantTechnique />
                );
            case "Encadrant de chantier":
                return (
                    <DashboardChefChantier />
                );
            case "Ouvrier":
                return (
                    <DashboardOuvrier />
                );
            case "Intérimaire":
                return (
                    <DashboardOuvrier />
                );
            case "Chauffeur":
                return (
                    <DashboardChauffeur />
                );
            case "Conducteur de travaux":
                return (
                    <DashboardConducteurTravaux />
                );
            case "Opérateur":
                return (
                    <DashboardOuvrier />
                );
            case "Dispatcher":
                return (
                    <DashboardDispatcher />
                );
            default:
                return (<p>Erreur</p>);
        }
    };

    return (
        <div>
            {isAuthenticated && getDashboard(user)}
        </div>
    );
};

export default HomePage;
