import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../utils/axiosInstance';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';
import ClipLoader from "react-spinners/ClipLoader";
import fileDownload from 'js-file-download';

const ExportReleveUneSemaine = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    let [color,] = useState("#dcd8d3");

    const chefChantierList = useSelector((state) => state.userList.chefChantierList);
    const releveHeureAdminList = useSelector(
        (state) => state.releveHeureAdminRdc.releveHeure
    );
    const [chefOptions, setChefOptions] = useState([]);
    const [numeroSemaines, setNumeroSemaines] = useState([]);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [chefSelected, setChefSelected] = useState(null);
    const [numeroSelected, setNumeroSelected] = useState(null);


    useEffect(() => {
        let chefChantierAvecReleve = [];
        for (const chef of chefChantierList) {
            if (chef.nbReleve > 0) {
                chefChantierAvecReleve.push({ label: `${chef.chef._id.login_matricule} - ${chef.chef._id.nom} ${chef.chef._id.prenom}`, value: chef.chef._id._id });
            }
        }

        setChefOptions(chefChantierAvecReleve);

    }, []);

    const onSubmit = () => {
        setButtonDisabled(true);
        axiosInstance.get(`/export_excel/releve_heure_semaine_chef/${chefSelected.value}&${numeroSelected.value}`, {
            responseType: 'blob',
        })
            .then((result) => {
                fileDownload(result.data, "test.xlsx");
                setButtonDisabled(false);
                close();
            }).catch((err) => {
                showError(err);
                setButtonDisabled(false);

            });
    };

    const onChangeChef = (opt) => {
        setChefSelected(opt);
        if (opt !== null) {
            let numeroSemaineDuChef = [];
            for (const releveHeure of releveHeureAdminList) {
                console.log(releveHeure.chef_chantier);
                if (opt.value === releveHeure.chef_chantier._id._id && releveHeure.statut_horaire_semaine === 1) {
                    numeroSemaineDuChef.push({ value: releveHeure._id, label: `${releveHeure.annee} - Semaine ${releveHeure.numero_semaine}` });
                }
            }
            setNumeroSemaines(numeroSemaineDuChef);
        }
    };

    return (
        <div className='modal-content ajouter-form'>
            <h3 className='modal-title'>Exporter un releve heure</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <label className='ajouter-form-label default-label' htmlFor="chefChantier">Chef de chantier</label>
                <Select
                    options={chefOptions}
                    className="formSelect-search-container formSelect-search-container-full mgB-s2"
                    classNamePrefix="formSelect-search"
                    onChange={(opt) => onChangeChef(opt)}
                    placeholder="Sélectionner un chef de chantier"
                    isSearchable={true}
                    value={chefSelected || ''}
                />
                <label className='ajouter-form-label default-label' htmlFor="chefChantier">Numéro de la semaine</label>
                <Select
                    options={numeroSemaines}
                    className="formSelect-search-container formSelect-search-container-full"
                    classNamePrefix="formSelect-search"
                    onChange={(opt) => setNumeroSelected(opt)}
                    placeholder="Sélectionner le numéro de semaine"
                    isDisabled={chefSelected === null}
                    value={numeroSelected || ''}
                />
                <button onClick={() => onSubmit()} disabled={isButtonDisabled || chefSelected === null || numeroSelected === null} className="default-button mgT-m2" style={{ height: "5rem", opacity: chefSelected === null || numeroSelected === null ? "0.4" : "1" }}>
                    <div className='container-flex' style={{ alignItems: "center", justifyContent: "center" }}>
                        <p className='mgR-s1'>Exporter le relevé</p>
                        <ClipLoader loading={isButtonDisabled} color={color} size={15} />
                    </div>
                </button>
            </div>
        </div>
    );
};

export default ExportReleveUneSemaine;