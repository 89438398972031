import React, { useEffect, useState } from "react";
import SearchInput from "../../../../components/ui/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import IntervenantsItem from "./IntervenantsItem";
import axiosInstance from "../../../../utils/axiosInstance";
import customToast from "../../../../utils/ToastifyHelper";
import { showError } from "../../../../utils/ErrorHelper";

const ProfilChantierAmiante_IntervenantsTiers = (props) => {
    const [searchBarContent, setSearchBarContent] = useState(null);

    const onChangeSearchIntervenantsTiers = (e) => {
        setSearchBarContent(e.target.value);
    };

    const [activeSearchValue, setActiveSearchValue] = useState(null);

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setActiveSearchValue(searchBarContent);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setActiveSearchValue(null);
        }
    }, [searchBarContent]);

    const [editMode, setEditMode] = useState(false);
    const [intervenantsTiersAjouterList, setIntervenantsTiersAjouterList] = useState([]);
    const [intervenantsTiersSupprimerList, setIntervenantsTiersSupprimerList] = useState([]);

    const changeEditionMode = () => {
        if (editMode) {
            // quitter le mode edition
            if (intervenantsTiersAjouterList.length || intervenantsTiersSupprimerList.length) {
                const patchData = {
                    ajouterIntervenantsTiersList: intervenantsTiersAjouterList.map((intervenant) => {
                        return intervenant._id;
                    }),
                    supprimerIntervenantsTiersList: intervenantsTiersSupprimerList.map((intervenant) => {
                        return intervenant._id;
                    })
                };
                props.progressBarRef.current.continuousStart();
                axiosInstance.patch(`/chantiers/modifierIntervenants/${props.chantier._id._id}`, patchData).then((result) => {
                    props.progressBarRef.current.complete();
                    customToast.success("La liste des intervenants a été modifié");
                    setIntervenantsTiersAjouterList([]);
                    setIntervenantsTiersSupprimerList([]);
                    props.setIntervenantsTiersChantier(sortByLibelleIntervenant(result.data, sortIntervenants.order));
                    setEditMode(!editMode);
                }).catch((err) => {
                    props.progressBarRef.current.complete();
                    showError(err);
                });
            }
            else {
                setEditMode(!editMode);
            }
        }
        else {
            setEditMode(!editMode);
        }
    };

    const isDefaultChecked = (intervenant) => {
        for (const intervenantChantier of props.chantier.intervenantsTiers) {
            if (intervenantChantier._id === intervenant._id) {
                return true;
            }
        }
        return false;
    };

    const supprimerIntervenantsTiers = (intervenantSupprimer) => {
        let intervenantInListAjouter = false;
        for (const intervenant of intervenantsTiersAjouterList) {
            if (intervenant._id === intervenantSupprimer._id) {
                intervenantInListAjouter = true;
                break;
            }
        }
        if (intervenantInListAjouter) {
            setIntervenantsTiersAjouterList(intervenantsTiersAjouterList.filter(intervenant => {
                return intervenant._id !== intervenantSupprimer._id;
            }));
        }
        else {
            setIntervenantsTiersSupprimerList(intervenantsTiersSupprimerList.concat(intervenantSupprimer));
        }
    };

    const ajouterIntervenantsTiersList = (intervenantAjouter) => {
        let intervenantInListSupprimer = false;
        for (const intervenant of intervenantsTiersSupprimerList) {
            if (intervenant._id === intervenantAjouter._id) {
                intervenantInListSupprimer = true;
                break;
            }
        }
        if (intervenantInListSupprimer) {
            setIntervenantsTiersSupprimerList(intervenantsTiersSupprimerList.filter(intervenant => {
                return intervenant._id !== intervenantAjouter._id;
            }));
        }
        else {
            setIntervenantsTiersAjouterList(intervenantsTiersAjouterList.concat(intervenantAjouter));
        }
    };

    const [sortIntervenants, setSortIntervenants] = useState({ sortProperty: "libelleIntervenant", order: "asc" });

    const onClickSort = (sort) => {
        if (sortIntervenants.sortProperty === sort) {
            if (sortIntervenants.order === "asc") {
                setSortIntervenants({ ...sortIntervenants, order: "desc" });
                if (sortIntervenants.sortProperty === "libelleIntervenant") {
                    props.setIntervenantsTiersChantier(sortByLibelleIntervenant(props.chantier.intervenantsTiers, "desc"));
                    props.setAllIntervenantsTiers(sortByLibelleIntervenant(props.allIntervenantsTiers, "desc"));
                }
                else {
                    //formation
                }
            }
            else {
                setSortIntervenants({ ...sortIntervenants, order: "asc" });
                props.setIntervenantsTiersChantier(sortByLibelleIntervenant(props.chantier.intervenantsTiers, "asc"));
                props.setAllIntervenantsTiers(sortByLibelleIntervenant(props.allIntervenantsTiers, "asc"));

            }
        }
        else {
            setSortIntervenants({ sortProperty: sort, order: "asc" });
            if (sortIntervenants.sortProperty === "libelleIntervenant") {
                props.setIntervenantsTiersChantier(sortByLibelleIntervenant(props.chantier.intervenantsTiers, "asc"));
                props.setAllIntervenantsTiers(sortByLibelleIntervenant(props.allIntervenantsTiers, "asc"));
            }
            else {
                //formation
            }
        }
    };

    const sortByLibelleIntervenant = (data, order) => {
        return data.sort((a, b) => {
            const libelleIntervenantComparison = a.libelleIntervenant.localeCompare(b.libelleIntervenant);
            return order === 'asc' ? libelleIntervenantComparison : -libelleIntervenantComparison;
        });
    };

    return (
        <>
            <div className="filterContainer">
                <SearchInput fullwidth={true} placeholder="Rechercher des intervenants, tiers" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchIntervenantsTiers} />
                <button onClick={() => changeEditionMode()} className="btn btn-primary">{editMode ? "Sauvegarder" : props.chantier.intervenantsTiers.length ? "Modifier les intervenants" : "Ajouter des intervenants"}</button>
            </div>
            <div className="container-basic mgB-s2 small-padding">
                <div style={{
                    display: "flex"
                }}>
                    <div onClick={() => onClickSort("libelleIntervenant")} style={{ width: "35%", display: "flex" }}>
                        <p className="pdR-s3" style={{ fontWeight: "700", fontSize: "1.5rem", cursor: "pointer" }}>Intervenant</p>
                        <div style={{ position: "relative", cursor: "pointer", opacity: sortIntervenants.sortProperty === "libelleIntervenant" ? 1 : 0 }}>
                            <FontAwesomeIcon style={{ position: "absolute", top: 0, fontSize: "1.7rem", color: sortIntervenants.sortProperty === "libelleIntervenant" && sortIntervenants.order === "desc" ? "#d13852" : "#1E1E1E" }} icon={faCaretUp} />
                            <FontAwesomeIcon style={{ position: "absolute", top: "8px", fontSize: "1.7rem", color: sortIntervenants.sortProperty === "libelleIntervenant" && sortIntervenants.order === "asc" ? "#d13852" : "#1E1E1E" }} icon={faCaretDown} />
                        </div>
                    </div>
                    <div style={{ width: "35%", display: "flex" }}>
                        <p className="pdR-s3" style={{ fontWeight: "700", fontSize: "1.5rem" }}>Adresse</p>
                        <div style={{ position: "relative", cursor: "pointer", opacity: sortIntervenants.sortProperty === "adresse" ? 1 : 0 }}>
                            <FontAwesomeIcon style={{ position: "absolute", top: 0, fontSize: "1.7rem", color: sortIntervenants.sortProperty === "adresse" && sortIntervenants.order === "desc" ? "#d13852" : "#1E1E1E" }} icon={faCaretUp} />
                            <FontAwesomeIcon style={{ position: "absolute", top: "8px", fontSize: "1.7rem", color: sortIntervenants.sortProperty === "adresse" && sortIntervenants.order === "asc" ? "#d13852" : "#1E1E1E" }} icon={faCaretDown} />
                        </div>
                    </div>
                    <div style={{ width: "30%", display: "flex" }}>
                        <p className="pdR-s3" style={{ fontWeight: "700", fontSize: "1.5rem" }}>Contacts</p>
                        <div style={{ position: "relative", cursor: "pointer", opacity: sortIntervenants.sortProperty === "contacts" ? 1 : 0 }}>
                            <FontAwesomeIcon style={{ position: "absolute", top: 0, fontSize: "1.7rem", color: sortIntervenants.sortProperty === "contacts" && sortIntervenants.order === "desc" ? "#d13852" : "#1E1E1E" }} icon={faCaretUp} />
                            <FontAwesomeIcon style={{ position: "absolute", top: "8px", fontSize: "1.7rem", color: sortIntervenants.sortProperty === "contacts" && sortIntervenants.order === "asc" ? "#d13852" : "#1E1E1E" }} icon={faCaretDown} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="intervenantsList mgB-m1" style={{ overflowY: "auto", maxHeight: "calc(100vh - 30rem)" }}>
                {
                    editMode ?
                        props.allIntervenantsTiers.map((intervenant) => {
                            const isIntervenantDefaultChecked = isDefaultChecked(intervenant);
                            return (
                                <IntervenantsItem searchValue={activeSearchValue} ajouterIntervenantsTiersList={() => ajouterIntervenantsTiersList(intervenant)} supprimerIntervenantsTiers={() => supprimerIntervenantsTiers(intervenant)} defaultChecked={isIntervenantDefaultChecked} editable={true} key={`${intervenant._id}-edit`} intervenantTier={intervenant} />
                            );
                        })
                        :
                        props.chantier.intervenantsTiers.length ?
                            props.chantier.intervenantsTiers.map((intervenant) => {
                                return (
                                    <IntervenantsItem searchValue={activeSearchValue} editable={false} key={`${intervenant._id}`} intervenantTier={intervenant} />
                                );
                            })
                            :
                            <p>La liste des intervenants est vide</p>
                }
            </div>
        </>
    );
};

export default ProfilChantierAmiante_IntervenantsTiers;
