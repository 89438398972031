import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as yup from "yup";
import { showError } from '../../../utils/ErrorHelper';
import { useFormik } from 'formik';
import Select from "react-select";
import customToast from '../../../utils/ToastifyHelper';
import axios from 'axios';
import axiosInstance from '../../../utils/axiosInstance';
import Creatable from 'react-select/creatable';
import ClipLoader from "react-spinners/ClipLoader";
import Map, { Marker } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import { getInsensitiveWorld } from '../../../utils/GeneralHelper';


const AjouterChantierAmianteForm = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    // handle form
    const formSchema = yup.object().shape({
        libelle_lieu: yup.string().required("Veuillez saisir le nom du chantier"),
        zone: yup.string().required("Veuillez entrer la zone du chantier"),
        codeChantier: yup.string().required("Veuillez saisir le code du chantier"),
        secteur: yup.string().required("Veuillez sélectionner le secteur du chantier"),
        lieuExecution: yup.string(),
        ville: yup.string().optional(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        gps: yup.string().nullable().notRequired().when("gps", (gps, schema) => {
            if (gps) {
                return schema.matches(/^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/, "Erreur de format du point gps, la latitude et la longitude doivent être séparé par une virgule");
            }
            return schema;
        }),
        nomMoa: yup.string().optional("Veuillez saisir le nom du maitre d'ouvrage"),
        villeMoa: yup.string().optional(),
        codePostalMoa: yup.string().nullable().notRequired().when("codePostalMoa", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        adresseMoa: yup.string().optional(),
        nomMoe: yup.string().optional("Veuillez saisir le nom du maitre d'oeuvre"),
        villeMoe: yup.string().optional(),
        codePostalMoe: yup.string().nullable().notRequired().when("codePostalMoe", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        adresseMoe: yup.string().optional(),
    },
        [["codePostal", "codePostal"], ["gps", "gps"], ["codePostalMoa", "codePostalMoa"], ["codePostalMoe", "codePostalMoe"]],
    );

    const formatGpsString = (gps) => {
        // Split the string into an array
        const gpsArray = gps.split(',').map(coord => parseFloat(coord.trim()));
        return gpsArray;
    };

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const postData = {
            ...values,
            gps: values.gps !== "" ? formatGpsString(values.gps) : null,
        };
        // post chantier
        axiosInstance.post('/lieux/chantierAmiante', postData).then((result) => {
            props.progressBarRef.current.complete();
            customToast.success("Le chantier a été ajouté ave succès");
            props.addChantier(result.data);
            close();
        }).catch((err) => {
            setSubmitting(false);
            props.progressBarRef.current.complete();
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelle_lieu: "",
            zone: "",
            codeChantier: "",
            secteur: "",
            gps: "",
            codePostal: "",
            ville: "",
            lieuExecution: "",
            nomMoa: "",
            codePostalMoa: "",
            villeMoa: "",
            adresseMoa: "",
            nomMoe: "",
            codePostalMoe: "",
            villeMoe: "",
            adresseMoe: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const zoneChantiersOptions = props.zoneChantiers.map((zone) => {
        return (
            { value: zone._id, label: zone.portee_zone === 2 ? zone.libelle_zone : `${zone.libelle_zone} - ${zone.portee_zone}km` }
        );
    });

    const onChangeZoneChantiers = (opt) => {
        setFieldValue("zone", opt.value);
    };

    const secteurOptions = [
        { label: "Gros oeuvres", value: "Gros oeuvres" },
        { label: "Travaux publics", value: "Travaux publics" }
    ];

    const onChangeSecteurChantiers = (opt) => {
        setFieldValue("secteur", opt.value);
    };

    //--------------- compose adresse ------------------
    const mapRef = useRef();
    const onMapLoad = useCallback(() => {
        mapRef.current.on('click', (e) => {
            setFieldValue("gps", `${e.lngLat.lat}, ${e.lngLat.lng}`);
        });
    }, []);
    const [communesList, setCommunesList] = useState([]);
    const [loadingCommuneInput, setLoadingCommuneInput] = useState(false);

    const onBlurPostalCode = () => {
        tryUpdateGps();
        if (values.codePostal.length === 5) {
            setLoadingCommuneInput(true);
            axios.get(`https://geo.api.gouv.fr/communes?codePostal=${values.codePostal}`).then((result) => {
                setLoadingCommuneInput(false);
                setCommunesList(result.data.map((commune) => {
                    return {
                        value: commune.nom,
                        label: commune.nom
                    };
                }));
            }).catch((err) => {
                setLoadingCommuneInput(false);
            });
        }
        else {
            if (communesList.length) {
                setCommunesList([]);
            }
        }
    };

    const onChangeVille = (opt) => {
        setFieldValue("ville", opt.label);
        tryUpdateGps();
    };

    const canCreateNewVilleOption = (opt) => {
        if (opt.length) {
            if (communesList.length) {
                for (const commune of communesList) {
                    if (getInsensitiveWorld(commune.label).includes(getInsensitiveWorld(opt))) {
                        return false;
                    }
                }
            }
            return true;
        }
        return false;
    };

    const onInputChangeVille = (opt, meta) => {
        if (meta.action === "input-change" || meta.action === "set-value") {
            setFieldValue("ville", opt);
        }
    };

    const tryUpdateGps = () => {
        if (values.lieuExecution.length && values.codePostal.length === 5 && values.ville.length) {
            const searchQUery = `${values.lieuExecution} ${values.codePostal} ${values.ville}`;
            axios.get(`https://api-adresse.data.gouv.fr/search/?q=${searchQUery}&type=housenumber&autocomplete=1&limit=15`).then((result) => {
                if (result.data.features.length === 1) {
                    setFieldValue("gps", `${result.data.features[0].geometry.coordinates[1]}, ${result.data.features[0].geometry.coordinates[0]}`);
                    mapRef.current.flyTo({
                        center: [result.data.features[0].geometry.coordinates[0], result.data.features[0].geometry.coordinates[1]],
                        essential: true,
                        duration: 1000,
                        zoom: 12
                    });
                }
            });
        }
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            tryUpdateGps();
        }, 1000);
        return () => clearTimeout(timer);
    }, [values.lieuExecution]);

    const [voirCarte, setVoirCarte] = useState(false);


    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Ajouter un chantier amiante</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='form-section'>
                    <h4>Présentation du chantier</h4>
                    <div className='label-and-input'>
                        <label htmlFor='codeChantier' className='form-label'>Code du chantier<span className='required-field'>*</span></label>
                        <input name='codeChantier' className={`new-default-input${errors.codeChantier && touched.codeChantier ? " input-error" : ""}`} type="text" placeholder="Code du chantier" value={values.codeChantier} onChange={handleChange} onBlur={handleBlur} />
                        {errors.codeChantier && touched.codeChantier && <p className="form-error-message">{errors.codeChantier}</p>}
                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='libelle_lieu' className='form-label'>Nom du chantier<span className='required-field'>*</span></label>
                        <input name='libelle_lieu' className={`new-default-input${errors.libelle_lieu && touched.libelle_lieu ? " input-error" : ""}`} type="text" placeholder="Nom du chantier" value={values.libelle_lieu} onChange={handleChange} onBlur={handleBlur} />
                        {errors.libelle_lieu && touched.libelle_lieu && <p className="form-error-message">{errors.libelle_lieu}</p>}
                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='zone' className='form-label'>Zone<span className='required-field'>*</span></label>
                        <Select
                            options={zoneChantiersOptions}
                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                            classNamePrefix="formSelect-search"
                            onChange={(opt) => onChangeZoneChantiers(opt)}
                            placeholder={"Zone"}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    fontFamily: "DM Sans",
                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                    borderRadius: "0.6rem",
                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                    '&:hover': {
                                        border: '1px solid black',
                                    },
                                })
                            }}
                            isSearchable={true}
                        // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                        />
                        {errors.zone && touched.zone && <p className="form-error-message">{errors.zone}</p>}
                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='secteur' className='form-label'>Secteur<span className='required-field'>*</span></label>
                        <Select
                            options={secteurOptions}
                            className="formSelect-search-container formSelect-search-container-full mgT-s2"
                            classNamePrefix="formSelect-search"
                            onChange={(opt) => onChangeSecteurChantiers(opt)}
                            placeholder={"Secteur"}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    fontFamily: "DM Sans",
                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                    borderRadius: "0.6rem",
                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                    '&:hover': {
                                        border: '1px solid black',
                                    },
                                })
                            }}
                            isSearchable={true}
                            value={values.secteur ? { label: values.secteur, value: values.secteur } : ""}
                        />
                        {errors.secteur && touched.secteur && <p className="form-error-message">{errors.secteur}</p>}
                    </div>
                    <div className='horizontal-form-fields-container'>
                        <div className='label-and-input'>
                            <label htmlFor='codePostal' className='form-label'>Code postal</label>
                            <input onBlurCapture={() => onBlurPostalCode()} name='codePostal' className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} type="text" placeholder="Code postal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='ville' className='form-label'>Ville</label>
                            <Creatable
                                isLoading={loadingCommuneInput}
                                options={communesList}
                                className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => onChangeVille(opt)}
                                createOptionPosition='first'
                                isValidNewOption={(opt) => canCreateNewVilleOption(opt)}
                                placeholder={"Ville"}
                                onInputChange={onInputChangeVille}
                                blurInputOnSelect={false}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        fontFamily: "DM Sans",
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                isClearable={false}
                                noOptionsMessage={() => false}
                                formatCreateLabel={value => value}
                            />
                            {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                        </div>
                    </div>
                    {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                    <div className='horizontal-form-fields-container'>
                        <div className='label-and-input'>
                            <label htmlFor='lieuExecution' className='form-label'>Adresse</label>
                            <input name='lieuExecution' className={`new-default-input${errors.lieuExecution && touched.lieuExecution ? " input-error" : ""}`} type="text" placeholder="Adresse du chantier" value={values.lieuExecution} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                    </div>
                    {voirCarte ?
                        <>
                            <div className='label-and-input'>
                                <label htmlFor='gps' className='form-label'>Point gps</label>
                                <input name='gps' className={`new-default-input${errors.gps && touched.gps ? " input-error" : ""}`} type="text" placeholder={`longitude,latitude`} value={values.gps} onChange={handleChange} onBlur={handleBlur} />
                                {errors.gps && touched.gps && <p className="form-error-message">{errors.gps}</p>}
                            </div>
                            <Map
                                ref={mapRef}
                                initialViewState={values.gps.match(/^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/) ? { longitude: formatGpsString(values.gps)[1], latitude: formatGpsString(values.gps)[0], zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                                style={{ width: "100%", flex: 1, borderRadius: ".5rem", marginBottom: "2rem", minHeight: "27rem", height: "auto" }}
                                mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                                onLoad={onMapLoad}
                                attributionControl={false}
                            >
                                {values.gps.match(/^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/) &&
                                    <Marker longitude={formatGpsString(values.gps)[1]} latitude={formatGpsString(values.gps)[0]} anchor="bottom">

                                    </Marker>
                                }
                            </Map>
                        </>
                        :
                        <div className='label-and-input'>
                            <button onClick={() => setVoirCarte(true)} className="btn btn-secondary">Afficher la carte</button>
                        </div>
                    }
                </div>
                <div className='form-section'>
                    <h4>Maitre d'ouvrage</h4>
                    <div className='label-and-input'>
                        <label htmlFor='nomMoa' className='form-label'>Nom</label>
                        <input name='nomMoa' className={`new-default-input${errors.nomMoa && touched.nomMoa ? " input-error" : ""}`} type="text" placeholder="Nom du maitre d'ouvrage" value={values.nomMoa} onChange={handleChange} onBlur={handleBlur} />
                        {errors.nomMoa && touched.nomMoa && <p className="form-error-message">{errors.nomMoa}</p>}
                    </div>
                    <div className='horizontal-form-fields-container'>
                        <div className='label-and-input'>
                            <label htmlFor='codePostalMoa' className='form-label'>Code postal</label>
                            <input name='codePostalMoa' className={`new-default-input${errors.codePostalMoa && touched.codePostalMoa ? " input-error" : ""}`} type="text" placeholder="Code postal du maitre d'ouvrage" value={values.codePostalMoa} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='villeMoa' className='form-label'>Ville</label>
                            <input name='villeMoa' className={`new-default-input${errors.villeMoa && touched.villeMoa ? " input-error" : ""}`} type="text" placeholder="Ville du maitre d'ouvrage" value={values.villeMoa} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                    </div>
                    {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                    <div className='label-and-input'>
                        <label htmlFor='adresseMoa' className='form-label'>Adresse</label>
                        <input name='adresseMoa' className={`new-default-input${errors.adresseMoa && touched.adresseMoa ? " input-error" : ""}`} type="text" placeholder="Adresse du maitre d'ouvrage" value={values.adresseMoa} onChange={handleChange} onBlur={handleBlur} />
                        {errors.adresseMoa && touched.adresseMoa && <p className="form-error-message">{errors.adresseMoa}</p>}
                    </div>
                </div>
                <div className='form-section'>
                    <h4>Maitre d'oeuvre</h4>
                    <div className='label-and-input'>
                        <label htmlFor='nomMoe' className='form-label'>Nom</label>
                        <input name='nomMoe' className={`new-default-input${errors.nomMoe && touched.nomMoe ? " input-error" : ""}`} type="text" placeholder="Nom du maitre d'oeuvre" value={values.nomMoe} onChange={handleChange} onBlur={handleBlur} />
                        {errors.nomMoe && touched.nomMoe && <p className="form-error-message">{errors.nomMoe}</p>}
                    </div>
                    <div className='horizontal-form-fields-container'>
                        <div className='label-and-input'>
                            <label htmlFor='codePostalMoe' className='form-label'>Code postal</label>
                            <input name='codePostalMoe' className={`new-default-input${errors.codePostalMoe && touched.codePostalMoe ? " input-error" : ""}`} type="text" placeholder="Code postal du maitre d'oeuvre" value={values.codePostalMoe} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='villeMoe' className='form-label'>Ville</label>
                            <input name='villeMoe' className={`new-default-input${errors.villeMoe && touched.villeMoe ? " input-error" : ""}`} type="text" placeholder="Ville du maitre d'oeuvre" value={values.villeMoe} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                    </div>
                    {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                    <div className='label-and-input'>
                        <label htmlFor='adresseMoe' className='form-label'>Adresse</label>
                        <input name='adresseMoe' className={`new-default-input${errors.adresseMoe && touched.adresseMoe ? " input-error" : ""}`} type="text" placeholder="Adresse du maitre d'oeuvre" value={values.adresseMoe} onChange={handleChange} onBlur={handleBlur} />
                        {errors.adresseMoe && touched.adresseMoe && <p className="form-error-message">{errors.adresseMoe}</p>}
                    </div>
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Ajouter le chantier" />
            </form>
        </div>
    );
};

export default AjouterChantierAmianteForm;