import React, { useState, useMemo, useEffect } from "react";
import { formatBytes, getAllUserType, removeFileExtension } from "../../utils/GeneralHelper";
import * as yup from "yup";
import { useFormik } from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../utils/GeneralHelper";
import { Icon } from "@iconify/react";
import mongoose from "mongoose";
import ProgressBar from "@ramonak/react-progress-bar";
import axiosInstance from "../../utils/axiosInstance";
import { addFichierChantier, removeFichierChantier, updateFichierChantier } from "../../store/actions/FichiersAct";
import { showError } from "../../utils/ErrorHelper";
import { useDispatch } from "react-redux";
import axios from "axios";
import customToast from "../../utils/ToastifyHelper";
import Select from "react-select";
const ModifierFichierModal = (props) => {
    const dispatch = useDispatch();
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const formSchema = yup.object().shape({
        nomFichier: yup.string().required("Veuillez saisir le nom du fichier"),
        visibility: yup.array().of(
            yup.object().shape({
                label: yup.string().required("Label is required"),
                value: yup.string().required("Value is required"),
            })
        ),
    });


    const [replaceFile, setReplaceFile] = useState(null);
    const [progress, setProgress] = useState(0);

    const onSubmit = (values, actions) => {
        if (replaceFile === null) {
            let patchData = {
                ...values,
                visibility: values.visibility.map(v => v.value)
            };
            axiosInstance.patch(`/fichiersChantiers/espaceDocumentaire/${props.fichier._id._id}`, patchData).then((result) => {
                dispatch(updateFichierChantier(result.data));
                close();
                setSubmitting(false);
            }).catch((err) => {
                showError(err);
                setSubmitting(false);
            });
        }
        else {
            const config = {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                },
                signal: replaceFile.abortController.signal,
                timeout: 0,
                headers: { 'Content-Type': 'multipart/form-data' }
            };

            let postData = new FormData();
            postData.append("_id", replaceFile._id);
            postData.append("nomFichier", values.nomFichier);
            postData.append("document", replaceFile.original);
            postData.append("visibility", values.visibility.map(v => v.value));
            axiosInstance.post(props.uploadLink, postData, config).then((result) => {
                dispatch(addFichierChantier(result.data));
                // delete old file
                const fichierId = props.fichier._id._id;
                axiosInstance.delete(`/fichiersChantiers/espaceDocumentaire/${fichierId}`).then((result) => {
                    dispatch(removeFichierChantier(fichierId));
                    close();
                    customToast.success("Le fichier a été modifié avec succès.");
                    setSubmitting(false);
                }).catch((err) => {
                    setSubmitting(false);
                    showError(err);
                });
            }).catch((err) => {
                if (!axios.isCancel(err)) {
                    showError(err);
                }
                else {
                    axiosInstance.delete(`/uploads/cancelUpload/${replaceFile._id}`).then((result) => {
                        setReplaceFile(null);
                        setSubmitting(false);
                        setProgress(0);
                    }).catch((err) => {
                    });
                }
            });
        }
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nomFichier: props.fichier.nomFichier,
            visibility: props.fichier.visibility.map((visibilityValue) => {
                return { label: visibilityValue, value: visibilityValue };
            })
        },
        validationSchema: formSchema,
        onSubmit
    });

    const optionsUserTypeVisibilityRights = getAllUserType().map((userType) => {
        return { label: userType, value: userType };
    });

    // UPLOAD NEW FILE

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': [],
            'application/zip': [],
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        marginBottom: 0,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            setReplaceFile({
                _id: new mongoose.Types.ObjectId(),
                original: acceptedFiles[0],
                abortController: new AbortController(),
                progress: 0
            });
            setFieldValue("nomFichier", removeFileExtension(acceptedFiles[0].name));
        }
    }, [acceptedFiles]);

    const removeNewFileUpload = () => {
        if (progress === 0) {
            setReplaceFile(null);
            setFieldValue("nomFichier", props.fichier.nomFichier);
        }
        else {
            replaceFile.abortController.abort();
            setReplaceFile(null);
            setFieldValue("nomFichier", props.fichier.nomFichier);
        }
    };

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier un fichier</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>

                <div className="uploadFile-item">
                    {replaceFile === null ?
                        <>
                            <div className="fileInfo mgB-s2">
                                <div className="fileIcon-container">
                                    <FontAwesomeIcon className="fileIcon" icon={faFile} />
                                </div>
                                <div className="fileInfo-content">
                                    <input name='nomFichier' className={`new-default-input${errors.nomFichier && touched.nomFichier ? " input-error" : ""}`} type="text" placeholder="Nom du fichier" value={values.nomFichier} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.nomFichier && touched.nomFichier && <p className="form-error-message">{errors.nomFichier}</p>}
                                    <p className="fileSize">{formatBytes(props.fichier._id.length)}</p>
                                </div>
                            </div>
                            <div className='label-and-input visibleBy'>
                                <label htmlFor='visibility' className='form-label'>Visible par</label>
                                <Select
                                    options={optionsUserTypeVisibilityRights}
                                    name="visibility"
                                    className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => setFieldValue("visibility", opt)}
                                    placeholder={"Visibilité"}
                                    defaultValue={values.visibility}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}
                                    isMulti={true}
                                    isSearchable={true}
                                    isClearable={false}
                                />
                            </div>
                            <div {...getRootProps({ style })}>
                                <input {...getInputProps()} />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                    <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                    <p className='dropzone-text'>Remplacer le fichier. <span>Glissez ou cliquez dans la zone pour le remplacer</span></p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {progress !== 100 &&
                                <div className="deleteIconContainer">
                                    <FontAwesomeIcon className="deleteIcon" icon={faXmark} onClick={() => removeNewFileUpload(replaceFile)} />
                                </div>
                            }
                            <div className="fileInfo">
                                <div className="fileIcon-container">
                                    <FontAwesomeIcon className="fileIcon" icon={faFile} />
                                </div>
                                <div className="fileInfo-content">
                                    {progress === 0 ?
                                        <>
                                            <input name='nomFichier' className={`new-default-input${errors.nomFichier && touched.nomFichier ? " input-error" : ""}`} type="text" placeholder="Nom du fichier" value={values.nomFichier} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.nomFichier && touched.nomFichier && <p className="form-error-message">{errors.nomFichier}</p>}
                                        </>
                                        :
                                        <p className="fileName">{values.nomFichier}</p>
                                    }
                                    <p className="fileSize">{formatBytes(replaceFile.original.size)}</p>
                                </div>
                            </div>
                            <div className='label-and-input visibleBy'>
                                <label htmlFor='visibility' className='form-label'>Visible par</label>
                                <Select
                                    options={optionsUserTypeVisibilityRights}
                                    name="visibility"
                                    className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                    classNamePrefix="formSelect-search"
                                    onChange={(opt) => setFieldValue("visibility", opt)}
                                    placeholder={"Visibilité"}
                                    defaultValue={values.visibility}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            fontFamily: "DM Sans",
                                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                            borderRadius: "0.6rem",
                                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                            '&:hover': {
                                                border: '1px solid black',
                                            },
                                        })
                                    }}
                                    isMulti={true}
                                    isSearchable={true}
                                    isClearable={false}
                                />
                            </div>
                            <div className="progressBarFileUpload-container">
                                <ProgressBar bgColor="#1E1E1E" height="10px" maxCompleted={100} isLabelVisible={false} className='progressBarFileUpload' completed={progress} />
                                {progress > 0 && <p>{progress}%</p>}
                            </div>
                        </>
                    }
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier le fichier" />
            </form>
        </div>
    );
};

export default ModifierFichierModal;
