import * as actionTypes from './actionTypes';

export const resetCurrentWeek = () => {
    return {
        type: actionTypes.RESET_CURRENT_WEEK,
    };
};

export const changeCurrentWeek = (currentWeekNumber, yearNumber, maxWeekInYear) => {
    return {
        type: actionTypes.CHANGE_CURRENT_WEEK,
        currentWeekNumber: currentWeekNumber,
        yearNumber: yearNumber,
        maxWeekInYear: maxWeekInYear
    };
};