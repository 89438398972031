import { format } from "date-fns";
import React from "react";
import { getAllDaysOfWeek } from "../../utils/DateHelper";
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import customToast from "../../utils/ToastifyHelper";
import { fr } from "date-fns/locale";
import ReleveJour from "./ReleveJour";

const LiReleveHeureConsultationV2 = (props) => {

    const allDaysInWeek = getAllDaysOfWeek(props.releveHeure.numeroSemaine, props.releveHeure.annee);

    return (
        <>
            <div className="releveConsultation-top mgB-s2">
                <h3>Relevé du {format(allDaysInWeek[0], 'eeee dd', { locale: fr })} au {format(allDaysInWeek[allDaysInWeek.length - 1], 'dd MMMM yyyy', { locale: fr })}</h3>
                <div className="validerButtonContainer">
                    <button onClick={() => props.verouillerSemaine()} disabled={props.releveHeure.statutSemaine} className={!props.releveHeure.statutSemaine ? 'validerSemaine-button' : 'validerSemaine-button disabled-button'}><p>Verrouiller la semaine</p></button>
                    <button onClick={() => props.deverouillerSemaine()} disabled={!props.releveHeure.statutSemaine} className={props.releveHeure.statutSemaine ? 'devaliderSemaine-button' : 'devaliderSemaine-button disabled-button'}><p>Déverrouiller la semaine</p></button>
                </div>
            </div>
            <div className="releve-heure-table-container">
                <table className="releve-heure-chef-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Nom du chantier</th>
                            <th>Zone</th>
                            <th>Nombre d'heures saisies</th>
                            <th>Etat</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allDaysInWeek.map((jour) =>
                            <ReleveJour ajouterHeure={props.ajouterHeure} ajouterAbsence={props.ajouterAbsence} saisiePar={props.releveSaisiePar} openSupprimerAbsence={props.openSupprimerAbsence} openCopierVers={props.openCopierVers} openSupprimerTouteHeuresChantier={props.openSupprimerTouteHeuresChantier} openSupprimerTouteHeures={props.openSupprimerTouteHeures} openModifierSalarieHeure={props.openModifierSalarieHeure} openDeleteHeure={props.openDeleteHeure} key={format(jour, 'yyyy-MM-dd')} listeDesSalaries={props.listeDesSalaries} listeDesChantiers={props.listeDesChantiers} statutSemaine={props.releveHeure.statutSemaine} dateJour={jour} jour={props.releveHeure.jours.hasOwnProperty(format(jour, 'yyyy-MM-dd')) ? props.releveHeure.jours[format(jour, 'yyyy-MM-dd')] : null} allDays={allDaysInWeek} openModifierChantier={props.openModifierChantier} />
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default LiReleveHeureConsultationV2;
