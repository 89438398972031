import React, { useRef, useState, useEffect } from "react";
import Select from 'react-select';
import EditIcon from "../../components/icon/EditIcon";
import { useSelector } from "react-redux";
import SelectSearch from 'react-select-search';
import TimePicker from 'react-time-picker-input';
import { formatIntTwoDigitString } from "../../utils/GeneralHelper";
import { Icon } from '@iconify/react';
import ReleveHeureTravailRenderList from "./ReleveHeureTravailRenderList";
import axiosInstance from "../../utils/axiosInstance";
import customToast from "../../utils/ToastifyHelper";
import { showError } from "../../utils/ErrorHelper";

const ReleveHeureListFormV2 = (props) => {

    const allChantierOptions = props.listeDesChantiers.map((chantier) => {
        return {
            value: chantier._id._id,
            label: `${chantier.codeChantier} - ${chantier._id.libelle_lieu} (${chantier.zone.libelle_zone})`,
        };
    });

    const userConnectedId = useSelector(
        state => state.user.user.id_utilisateur
    );

    const [chantierSelected, setChantierSelected] = useState(null);

    const [salarieSelectedId, setSalarieSelectedId] = useState(props.chantierId === null ? userConnectedId : null);

    const optionsSalarie = [
        {
            type: 'group',
            name: "Ouvrier",
            items: props.listeDesSalaries.hasOwnProperty("ouvrierList") && props.listeDesSalaries.ouvrierList.length ? props.listeDesSalaries.ouvrierList.map((ouvrier) => {
                let fullName = ouvrier._id.login_matricule + " / " + ouvrier._id.nom + " " + ouvrier._id.prenom;
                return { name: fullName, value: ouvrier._id._id };
            }) : []
        },
        {
            type: 'group',
            name: "Chef de chantier",
            items: props.listeDesSalaries.hasOwnProperty("chefChantierList") && props.listeDesSalaries.chefChantierList.length ? props.listeDesSalaries.chefChantierList.map((chef) => {
                let fullName = chef._id.login_matricule + " / " + chef._id.nom + " " + chef._id.prenom;
                return { name: fullName, value: chef._id._id };
            }) : []
        },
        {
            type: 'group',
            name: "Intérimaire",
            items: props.listeDesSalaries.hasOwnProperty("interimaireList") && props.listeDesSalaries.interimaireList.length ? props.listeDesSalaries.interimaireList.map((interimaire) => {
                let fullName = interimaire._id.login_matricule + " / " + interimaire._id.nom + " " + interimaire._id.prenom;
                return { name: fullName, value: interimaire._id._id };
            }) : []
        }
    ];

    const handleFilterSalarie = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return optionsSalarie;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChangeSalarie = (...args) => {
        setSalarieSelectedId(args[0]);
    };

    const searchSalarieInput = useRef();

    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const commentaire = useRef();

    // INPUT HEURE
    const [horaireMatinDeb, sethoraireMatinDeb] = useState("--:--");
    const [horaireMatinFin, sethoraireMatinFin] = useState("--:--");
    const [horaireApremDeb, sethoraireApremDeb] = useState("--:--");
    const [horaireApremFin, setHoraireApremFin] = useState("--:--");
    const [totalHeure, setTotalHeure] = useState("--:--");

    useEffect(() => {
        const tryUpdateTotalHeure = () => {
            const horaireMatinDebutT = horaireMatinDeb.match(/^(\d+):(\d+)/);
            const horaireMatinFinT = horaireMatinFin.match(/^(\d+):(\d+)/);
            const horaireApremDebutT = horaireApremDeb.match(/^(\d+):(\d+)/);
            const horaireApremFinT = horaireApremFin.match(/^(\d+):(\d+)/);
            if (horaireMatinDebutT !== null && horaireMatinFinT !== null && horaireApremDebutT !== null && horaireApremFinT !== null) {
                let totalHeureMatin = Math.abs(parseInt(horaireMatinFinT[1]) - parseInt(horaireMatinDebutT[1]));
                let totalMinuteMatin = parseInt(horaireMatinFinT[2]) - parseInt(horaireMatinDebutT[2]);
                let totalHeureAprem = Math.abs(parseInt(horaireApremFinT[1]) - parseInt(horaireApremDebutT[1]));
                let totalMinuteAprem = parseInt(horaireApremFinT[2]) - parseInt(horaireApremDebutT[2]);
                let totalHeure = totalHeureMatin + totalHeureAprem;
                if (totalMinuteMatin < 0) {
                    totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                    totalHeure--;
                }
                if (totalMinuteAprem < 0) {
                    totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                    totalHeure--;
                }
                let totalMinute = totalMinuteMatin + totalMinuteAprem;
                const quotientMinute = Math.floor(totalMinute / 60);
                if (quotientMinute >= 1) {
                    totalHeure += quotientMinute;
                    totalMinute = Math.floor(totalMinute % 60);
                }

                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else if (horaireMatinDebutT !== null && horaireMatinFinT !== null && horaireApremDebutT === null && horaireApremFinT === null) {
                let totalHeureMatin = Math.abs(parseInt(horaireMatinFinT[1]) - parseInt(horaireMatinDebutT[1]));
                let totalMinuteMatin = parseInt(horaireMatinFinT[2]) - parseInt(horaireMatinDebutT[2]);
                let totalHeure = totalHeureMatin;
                if (totalMinuteMatin < 0) {
                    totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                    totalHeure--;
                }
                let totalMinute = totalMinuteMatin;
                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else if (horaireMatinDebutT === null && horaireMatinFinT === null && horaireApremDebutT !== null && horaireApremFinT !== null) {
                let totalHeureAprem = Math.abs(parseInt(horaireApremFinT[1]) - parseInt(horaireApremDebutT[1]));
                let totalMinuteAprem = parseInt(horaireApremFinT[2]) - parseInt(horaireApremDebutT[2]);
                let totalHeure = totalHeureAprem;
                if (totalMinuteAprem < 0) {
                    totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                    totalHeure--;
                }
                let totalMinute = totalMinuteAprem;
                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else {
                setTotalHeure('--:--');
            }
        };
        tryUpdateTotalHeure();
    }, [horaireMatinDeb, horaireMatinFin, horaireApremDeb, horaireApremFin]);

    // FORM SUBMIT
    const onSubmit = () => {
        setButtonDisabled(true);
        if ((chantierSelected === null && props.jourIndex === -1)) {
            customToast.error("Aucun chantier sélectionné");
            setButtonDisabled(false);
        }
        else if (salarieSelectedId === null) {
            // alert("veuillez renseigner tout les champs")
            customToast.error("Aucun salarié sélectionné");
            setButtonDisabled(false);
        }
        else {
            const horaireMatinDebutT = horaireMatinDeb.match(/^(\d+):(\d+)/);
            const horaireMatinFinT = horaireMatinFin.match(/^(\d+):(\d+)/);
            const horaireApremDebutT = horaireApremDeb.match(/^(\d+):(\d+)/);
            const horaireApremFinT = horaireApremFin.match(/^(\d+):(\d+)/);

            if ((horaireMatinDebutT !== null && horaireMatinFinT !== null) || (horaireApremDebutT !== null && horaireApremFinT !== null)) {
                let heureMatDeb = null;
                let minuteMatDeb = null;
                let heureMatFin = null;
                let minuteMatFin = null;
                let heureApremDeb = null;
                let minuteApremDeb = null;
                let heureApremFin = null;
                let minuteApremFin = null;
                if (horaireMatinDebutT !== null && horaireMatinFinT !== null) {
                    heureMatDeb = parseInt(horaireMatinDebutT[1]);
                    minuteMatDeb = parseInt(horaireMatinDebutT[2]);
                    heureMatFin = parseInt(horaireMatinFinT[1]);
                    minuteMatFin = parseInt(horaireMatinFinT[2]);
                }
                if (horaireApremDebutT !== null && horaireApremFinT !== null) {
                    heureApremDeb = parseInt(horaireApremDebutT[1]);
                    minuteApremDeb = parseInt(horaireApremDebutT[2]);
                    heureApremFin = parseInt(horaireApremFinT[1]);
                    minuteApremFin = parseInt(horaireApremFinT[2]);
                }
                if (!(heureMatDeb === null && minuteMatDeb === null && heureMatFin === null && minuteMatFin === null) && (heureMatDeb === null || minuteMatDeb === null || heureMatFin === null || minuteMatFin === null)) {
                    customToast.error("Les heures du matin sont incomplètes");
                    setButtonDisabled(false);
                }
                else if (!(heureApremDeb === null && minuteApremDeb === null && heureApremFin === null && minuteApremFin === null) && (heureApremDeb === null || minuteApremDeb === null || heureApremFin === null || minuteApremFin === null)) {
                    customToast.error("Les heures de l'après-midi sont incomplètes");
                    setButtonDisabled(false);
                }
                else if (heureMatDeb === null && minuteMatDeb === null && heureMatFin === null && minuteMatFin === null && heureApremDeb === null && minuteApremDeb === null && heureApremFin === null && minuteApremFin === null) {
                    customToast.error("Aucune horaires saisies");
                    setButtonDisabled(false);
                }
                else {
                    let error = false;
                    if (heureMatDeb !== null) {
                        const totalMinuteMatinDebut = heureMatDeb * 60 + minuteMatDeb;
                        const totalMinuteMatinFin = heureMatFin * 60 + minuteMatFin;
                        if (totalMinuteMatinDebut >= totalMinuteMatinFin) {
                            error = true;
                            customToast.error("L'horaire saisie est incorrect, l'heure de fin, ne peut pas être antérieur à l'heure de début");
                            setButtonDisabled(false);
                        }
                    }
                    if (heureApremDeb !== null) {
                        const totalMinuteApremDebut = heureApremDeb * 60 + minuteApremDeb;
                        const totalMinuteApremFin = heureApremFin * 60 + minuteApremFin;
                        if (totalMinuteApremDebut >= totalMinuteApremFin) {
                            error = true;
                            customToast.error("L'horaire saisie est incorrect, l'heure de fin, ne peut pas être antérieur à l'heure de début");
                            setButtonDisabled(false);
                        }
                    }
                    if (!error) {
                        let postData = {
                            salarie: salarieSelectedId,
                            chantier: props.chantierId === null ? chantierSelected.value : props.chantierId,
                            date_jour: props.jourDate,
                            'heureMatDeb': heureMatDeb,
                            'minuteMatDeb': minuteMatDeb,
                            'heureMatFin': heureMatFin,
                            'minuteMatFin': minuteMatFin,
                            'heureApremDeb': heureApremDeb,
                            'minuteApremDeb': minuteApremDeb,
                            'heureApremFin': heureApremFin,
                            'minuteApremFin': minuteApremFin,
                            commentaire: commentaire.current.value
                        };

                        if (props.saisiePar) {
                            postData.saisiePar = props.saisiePar._id;
                            axiosInstance.post("/releve-heure-v2/travailSalarie/", postData).then((response) => {
                                customToast.success("L'heure a été ajoutée avec succès");
                                if (props.chantierId === null) {
                                    props.peutAjouterChantier();
                                }
                                else {
                                    setButtonDisabled(false);
                                    setSalarieSelectedId(null);
                                }
                                props.ajouterHeure(response.data);
                            }).catch((err) => {
                                setButtonDisabled(false);
                                showError(err);
                            });
                        }
                        else {
                            axiosInstance.post("/releve-heure-v2/travailSalarie/me", postData).then((response) => {
                                customToast.success("L'heure a été ajoutée avec succès");
                                if (props.chantierId === null) {
                                    props.peutAjouterChantier();
                                }
                                else {
                                    setButtonDisabled(false);
                                    setSalarieSelectedId(null);
                                }
                                props.ajouterHeure(response.data);
                            }).catch((err) => {
                                setButtonDisabled(false);
                                showError(err);
                            });
                        }

                    }
                }
            }
            else {
                customToast.error("Veuillez compléter les heures du salarié");
                setButtonDisabled(false);
            }
        }
    };

    return (
        <>
            {props.open ?
                <tr className='jourTableSubRow'>
                    <td className="salarieHeure-td" colSpan="6">
                        <div className="salarieHeureAndForm-container">
                            <div className='jourReleveHeure-form'>
                                <div className='jourReleveHeure-form-top'>
                                    <div className="chantierSelection">
                                        <label className='default-label' htmlFor="chantier">{props.chantierId === null ? "Sélectionner un chantier" : "Chantier sélectionné"}</label>
                                        {props.chantierId === null ?
                                            <Select
                                                options={allChantierOptions}
                                                name="selectChantier"
                                                className="formSelect-search-container mgB-s2"
                                                classNamePrefix="formSelect-search"
                                                onChange={(opt) => setChantierSelected(opt)}
                                                placeholder="Sélectionner le chantier"
                                                isSearchable={true}
                                            />
                                            : <div className="chantierInputEdit-container">
                                                <input readOnly type="text" className='new-default-input chantierInput-selected mgR-s2' disabled name="chantier" value={allChantierOptions.find(opt => opt.value === props.chantierId) !== undefined ? allChantierOptions.find(opt => opt.value === props.chantierId).label : ""} />
                                                <button className='table-modify-button' disabled={props.statutSemaine === 0 ? isButtonDisabled : true} onClick={() => { props.openModifierChantier({ date_jour: props.jourDate, oldChantierId: props.chantierId, allChantierOptions: allChantierOptions }); }}><EditIcon /></button>
                                            </div>
                                        }
                                    </div>
                                    <div className='ajouterHeureSalarie-form'>
                                        <div className="selectSalarie">
                                            <label className='default-label' htmlFor="salarieSelect">Sélectionner un salarié</label>
                                            <SelectSearch
                                                ref={searchSalarieInput}
                                                options={optionsSalarie}
                                                filterOptions={handleFilterSalarie}
                                                value={salarieSelectedId === null ? '' : salarieSelectedId}
                                                name="salarie"
                                                placeholder="Choisir un salarié"
                                                search
                                                onChange={handleChangeSalarie}
                                            />
                                        </div>
                                        <div className="horaireContainer">
                                            <label className='default-label' htmlFor="select-heure-matinFin">Matin</label>
                                            <div className="heureMatin">
                                                <TimePicker name='select-horaire-matinDeb' value={horaireMatinDeb} allowDelete onChange={(dateString) => sethoraireMatinDeb(dateString)} />
                                                <p>-</p>
                                                <TimePicker name='select-horaire-matinFin' value={horaireMatinFin} allowDelete onChange={(dateString) => sethoraireMatinFin(dateString)} />
                                            </div>
                                        </div>
                                        <div className="horaireContainer">
                                            <label className='default-label' htmlFor="select-heure-matinFin">Après-midi</label>
                                            <div className="heureAprem">
                                                <TimePicker name='select-horaire-apremDeb' value={horaireApremDeb} allowDelete onChange={(dateString) => sethoraireApremDeb(dateString)} />
                                                <p>-</p>
                                                <TimePicker name='select-horaire-apremFin' value={horaireApremFin} allowDelete onChange={(dateString) => setHoraireApremFin(dateString)} />
                                            </div>
                                        </div>
                                        <div className="totalContainer">
                                            <label className='default-label' htmlFor="select-heure-Total">Total</label>
                                            <div className="totalHeure">
                                                <div className="heureInputAndLabelContainer">
                                                    <TimePicker name='select-horaire-Total' value={totalHeure} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="commentaireContainer">
                                            <div className="commentaireOnly">
                                                <label className='default-label' htmlFor="commentaire">Commentaire</label>
                                                <input ref={commentaire} type="text" className='default-input' placeholder="Commentaire" />
                                            </div>
                                            <div className="ajouterHoraireButton-container">
                                                <button disabled={props.statutSemaine === 0 ? isButtonDisabled : true} className="ajouterHeureButton" style={{ marginRight: '0px' }} onClick={() => onSubmit()} value="Ajouter"><Icon icon="entypo:plus" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(props.chantierId !== null && props.travails.length) && <ReleveHeureTravailRenderList openDeleteHeure={props.openDeleteHeure} openModifierSalarieHeure={props.openModifierSalarieHeure} travails={props.travails} statutSemaine={props.statutSemaine} />}
                        </div>
                    </td>
                </tr>
                :
                null
            }
        </>
    );
};

export default ReleveHeureListFormV2;
