import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import { showError } from "../../../../utils/ErrorHelper";
import BasicTableRender from "../../../../components/tables/Basic/BasicTableRender";
import SearchInput from "../../../../components/ui/SearchInput";
import { getDechetChantierAmianteColumns } from "../../../../components/tables/Basic/BasicTableColumns";
import AjouterDechetChantierAmiante from "./AjouterDechetChantierAmiante";
import Modal from "../../../../components/Modal";


const ProfilChantierAmiante_GestionDechets = (props) => {
    const [searchDechetBarContent, setSearchDechetBarContent] = useState(null);
    const onChangeSearchDechet = (e) => {
        setSearchDechetBarContent(e.target.value);
    };

    const [loadingDechet, setLoadingDechet] = useState(false);

    const [filterTable, setFilterTable] = useState(false);
    const [dechetsChantier, setDechetsChantier] = useState([]);

    // load dechets
    const loadDechets = () => {
        setLoadingDechet(true);
        axiosInstance.get(`/chantiersAmiante/dechets/${props.idChantier}`).then((result) => {
            setLoadingDechet(false);
            setDechetsChantier(result.data);
        }).catch((err) => {
            setLoadingDechet(false);
            showError(err);
        });
    };

    useEffect(() => {
        loadDechets();
    }, []);

    const viewFile = (fichierId) => {
        axiosInstance.get(`/fichiers/${fichierId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    useEffect(() => {
        if (searchDechetBarContent !== null && searchDechetBarContent !== "") {
            let timer = setTimeout(() => {
                setFilterTable(true);
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setFilterTable(true);
        }
    }, [dechetsChantier, searchDechetBarContent]);

    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalDechetSelected, setModalDechetSelected] = useState({});

    const openAjouterDechetAmianteModal = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouter");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const ajouterDechetChantier = (dechet) => {
        setDechetsChantier(dechetsChantier.concat(dechet));
    };

    // loadDechets options
    const [dechetsOptions, setDechetsOptions] = useState([]);

    const getDechets = () => {
        axiosInstance.get("/dechets").then((result) => {
            setDechetsOptions(result.data.map((dechet) => {
                return { value: dechet._id, label: dechet.libelleDechet };
            }));
        }).catch((err) => {
            showError(err);
        });
    };

    const [zoneOptions, setZoneOptions] = useState([]);

    const getZoneChantierAmianteOptions = () => {
        axiosInstance.get(`/zoneAmiante/${props.idChantier}`).then((result) => {
            setZoneOptions(result.data.map((zone) => {
                return { value: zone._id, label: zone.portee_zone === 2 ? zone.libelle_zone : `${zone.nomZoneAmiante} (${zone.environnement})` };
            }));
        }).catch((err) => {
            showError(err);
        });
    };

    useEffect(() => {
        getDechets();
        getZoneChantierAmianteOptions();
    }, []);



    return (
        <>
            <div>
                <div className="filterContainer">
                    <SearchInput fullwidth={true} placeholder="Rechercher un déchet" searchContent={searchDechetBarContent ? searchDechetBarContent : ""} onChangeSearch={onChangeSearchDechet} />
                    <button className='btn btn-primary' onClick={() => openAjouterDechetAmianteModal()}>
                        + Ajouter un déchet
                    </button>
                </div>
                {loadingDechet ? <div className="loading">loading...</div> :
                    <BasicTableRender
                        columns={getDechetChantierAmianteColumns((fichierId) => viewFile(fichierId))}
                        tableData={dechetsChantier}
                        sortBy={[

                        ]}
                        filterTable={filterTable}
                        setFilterTable={setFilterTable}
                        searchBarContent={searchDechetBarContent}
                    />
                }
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "ajouter" &&
                    <AjouterDechetChantierAmiante dechetsOptions={dechetsOptions} zoneOptions={zoneOptions} ajouterDechetChantier={ajouterDechetChantier} idChantier={props.idChantier} progressBarRef={props.progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>
        </>
    );
};

export default ProfilChantierAmiante_GestionDechets;
