import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const suiviTypeFormationState = { allSuiviTypeFormationState: [] };


const reducer = (state = suiviTypeFormationState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_SUIVI_TYPE_FORMATION_LIST:
            return updateObject(state, { allSuiviTypeFormationState: action.suiviTypeFormationList });
        case actionTypes.UNLOAD_SUIVI_TYPE_FORMATION_LIST:
            return { allSuiviTypeFormationState: [] };
        case actionTypes.ADD_SUIVI_TYPE_FORMATION:
            return updateObject(state, { allSuiviTypeFormationState: state.allSuiviTypeFormationState.concat(action.suiviTypeFormation) });
        case actionTypes.MODIFY_SUIVI_TYPE_FORMATION:
            return updateObject(state,
                {
                    allSuiviTypeFormationState: state.allSuiviTypeFormationState.map(
                        (typeFormation) => typeFormation._id === action.suiviTypeFormationUpdated._id ?
                            action.suiviTypeFormationUpdated
                            : typeFormation
                    )
                });
        case actionTypes.DELETE_SUIVI_TYPE_FORMATION:
            return updateObject(state, { allSuiviTypeFormationState: state.allSuiviTypeFormationState.filter(typeFormation => typeFormation._id !== action.suiviTypeFormationId) });
        default:
            return state;
    }
};

export default reducer;