import React from "react";
import SupprimerIcon from "../../components/icon/SupprimerIcon";
import EditIcon from "../../components/icon/EditIcon";
import { formatIntTwoDigitString } from '../../utils/GeneralHelper';
import ReactTooltip from "react-tooltip";


const ReleveHeureTravailRenderList = (props) => {

    const totalHeure = (travail) => {
        const heureMatinDebut = { heure: travail.horaireMat.heureDeb, minute: travail.horaireMat.minuteDeb };
        const heureMatinFin = { heure: travail.horaireMat.heureFin, minute: travail.horaireMat.minuteFin };
        const heureApremDebut = { heure: travail.horaireAprem.heureDeb, minute: travail.horaireAprem.minuteDeb };
        const heureApremFin = { heure: travail.horaireAprem.heureFin, minute: travail.horaireAprem.minuteFin };
        if (heureMatinDebut.heure !== null && heureMatinFin.heure !== null && heureApremDebut.heure !== null && heureApremFin.heure !== null) {
            let totalHeureMatin = Math.abs(heureMatinFin.heure - heureMatinDebut.heure);
            let totalMinuteMatin = heureMatinFin.minute - heureMatinDebut.minute;
            let totalHeureAprem = Math.abs(heureApremFin.heure - heureApremDebut.heure);
            let totalMinuteAprem = heureApremFin.minute - heureApremDebut.minute;
            let totalHeure = totalHeureMatin + totalHeureAprem;
            if (totalMinuteMatin < 0) {
                totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                totalHeure--;
            }
            if (totalMinuteAprem < 0) {
                totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                totalHeure--;
            }
            let totalMinute = totalMinuteMatin + totalMinuteAprem;
            const quotientMinute = Math.floor(totalMinute / 60);
            if (quotientMinute >= 1) {
                totalHeure += quotientMinute;
                totalMinute = Math.floor(totalMinute % 60);
            }

            return (formatIntTwoDigitString(totalHeure) + " h " + formatIntTwoDigitString(totalMinute));
        }
        else if (heureMatinDebut.heure !== null && heureMatinFin.heure !== null && heureApremDebut.heure === null && heureApremFin.heure === null) {
            let totalHeureMatin = Math.abs(heureMatinFin.heure - heureMatinDebut.heure);
            let totalMinuteMatin = heureMatinFin.minute - heureMatinDebut.minute;
            let totalHeure = totalHeureMatin;
            if (totalMinuteMatin < 0) {
                totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                totalHeure--;
            }
            let totalMinute = totalMinuteMatin;
            return (formatIntTwoDigitString(totalHeure) + " h " + formatIntTwoDigitString(totalMinute));
        }
        else if (heureMatinDebut.heure === null && heureMatinFin.heure === null && heureApremDebut.heure !== null && heureApremFin.heure !== null) {
            let totalHeureAprem = Math.abs(heureApremFin.heure - heureApremDebut.heure);
            let totalMinuteAprem = heureApremFin.minute - heureApremDebut.minute;
            let totalHeure = totalHeureAprem;
            if (totalMinuteAprem < 0) {
                totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                totalHeure--;
            }
            let totalMinute = totalMinuteAprem;
            return (formatIntTwoDigitString(totalHeure) + " h " + formatIntTwoDigitString(totalMinute));
        }
        else {
            return ("");
        }
    };

    return (
        <ul className="salarieReleveHeure-list">
            {props.travails.map((travail) => {
                return (
                    <li key={travail._id}>
                        <p className='readOnly-input selectSalarie-readOnly-input' placeholder="Salarie">{travail.salarie.nom + " " + travail.salarie.prenom}</p>
                        <p className='readOnly-input selectHeure-readOnly-input' placeholder="Heure matin">{travail.horaireMat.heureDeb === null ? "" : travail.horaireMat.heureDeb + " h " + formatIntTwoDigitString(travail.horaireMat.minuteDeb) + "  -  " + travail.horaireMat.heureFin + " h " + formatIntTwoDigitString(travail.horaireMat.minuteFin)}</p>
                        <p className='readOnly-input selectHeure-readOnly-input' placeholder="Heure après-midi">{travail.horaireAprem.heureDeb === null ? "" : travail.horaireAprem.heureDeb + " h " + formatIntTwoDigitString(travail.horaireAprem.minuteDeb) + "  -  " + travail.horaireAprem.heureFin + " h " + formatIntTwoDigitString(travail.horaireAprem.minuteFin)}</p>
                        <p className='readOnly-input totalHeure-readOnly-input' placeholder="Total">{totalHeure(travail)}</p>
                        <div className='salarieList-commentaireButton-container'>
                            <p className='readOnly-input commentaireInput-readOnly' placeholder="Commentaire">{travail.commentaire || ''}</p>
                            <button data-tip data-for={"modifierHeureTip-" + travail._id} disabled={props.statutSemaine === 0 ? false : true} onClick={() => props.openModifierSalarieHeure(travail)} className='table-modify-button'><EditIcon /></button>
                            <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"modifierHeureTip-" + travail._id} place="bottom" effect="solid">
                                Modifier l'heure
                            </ReactTooltip>
                            <button data-tip data-for={"supprimerHeureTip-" + travail._id} disabled={props.statutSemaine === 0 ? false : true} onClick={() => props.openDeleteHeure(travail)} className='table-delete-button'><SupprimerIcon /></button>
                            <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"supprimerHeureTip-" + travail._id} place="bottom" effect="solid">
                                Supprimer l'heure
                            </ReactTooltip>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default ReleveHeureTravailRenderList;
