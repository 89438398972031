import React, { useEffect, useState } from 'react';
import SearchInput from '../../components/ui/SearchInput';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import SupprimerIcon from '../../components/icon/SupprimerIcon';
import ModifierStockMateriaux from '../../components/forms/Site/ModifierStockMateriaux';
import Modal from '../../components/Modal';

import SimpleTableRender from '../../components/tables/SimpleTableRender';
import { getHistoriqueStockageMateriauxColumns } from '../../components/tables/TableColumns';

const MateriauxProvenantSite = (props) => {
    const [showedMateriauxProvenantSite, setShowedMateriauxProvenantSite] = useState([]);
    const [historiqueOpenId, setHistoriqueOpenId] = useState(null);

    // searchBar
    const [searchMateriauxProvenantSiteBarContent, setSearchMateriauxProvenantSiteBarContent] = useState(null);
    const onChangeSearchMateriauxProvenantSite = (e) => {
        setSearchMateriauxProvenantSiteBarContent(e.target.value);
    };

    const materiauxProvenantSiteMatch = (materiaux, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return true;
    };

    useEffect(() => {
        if (searchMateriauxProvenantSiteBarContent !== null && searchMateriauxProvenantSiteBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedMateriauxProvenantSite(props.site.stockageMateriaux.filter(materiaux => {
                    return materiauxProvenantSiteMatch(materiaux, searchMateriauxProvenantSiteBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedMateriauxProvenantSite(props.site.stockageMateriaux);
        }
    }, [props.site.stockageMateriaux, searchMateriauxProvenantSiteBarContent]);

    //--------------- MODAL ------------------
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalMateriauxSelected, setModalMateriauxSelected] = useState({});

    //--------------- Edit materiaux quantité ------------------
    const openEditDemande = (demandeData) => {
        setModalMateriauxSelected(demandeData);
        setModalClasses("modal modal__active");
        setModalContent("modifierQuantiteStock");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const onModifierQuantiteMateriaux = (operation, materiauId) => {
        setShowedMateriauxProvenantSite(showedMateriauxProvenantSite.map((materiaux) => {
            if (materiauId === materiaux._id) {
                return {
                    ...materiaux,
                    stock: operation.value,
                    operations: [
                        {
                            utilisateur: operation.utilisateur,
                            value: operation.value,
                            dateModification: new Date(operation.dateModification),
                            commentaire: operation.commentaire
                        },
                        ...materiaux.operations
                    ]
                };
            }
            return materiaux;
        }));
    };

    return (
        <>
            <div className='mgT-s2'>
                <div className="new-filter-section">
                    <button className='btn btn-primary' onClick={() => props.openAddMateriauxProvenantDuSite(props.site._id._id)}>
                        + Ajouter matériaux
                    </button>
                    <div className='new-filter-container'>
                        <SearchInput style={{ flex: 1 }} placeholder="Rechercher un matériaux" searchContent={searchMateriauxProvenantSiteBarContent ? searchMateriauxProvenantSiteBarContent : ""} onChangeSearch={onChangeSearchMateriauxProvenantSite} />
                    </div>
                </div>
                {showedMateriauxProvenantSite.map((materiaux) => {
                    // console.log(materiaux);
                    return (
                        <div key={materiaux._id} className='container-basic mgB-m1'>
                            <div className='formationCard' style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className='currentFormation-container'>
                                    <h4>{materiaux.materiaux.libelle_mat}</h4>
                                    <div className='currentFormation'>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className='mgR-m2'>
                                                <p className='labelFormation'>Quantité:</p>
                                            </div>
                                            <div>
                                                <p>{materiaux.stock}t</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                                    <button onClick={() => openEditDemande(materiaux)} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", alignSelf: "flex-start" }} className='btn btn-primary'><Icon style={{ width: "2.2rem", height: "2.2rem" }} icon="ic:outline-edit" /><p className="mgL-s1">Éditer</p></button>
                                    <button onClick={() => console.log("d")} className='btn btn-primary mgL-s2 supprimerButton'><SupprimerIcon /></button>
                                </div>
                            </div>
                            {materiaux.operations.length > 0 &&
                                <div>
                                    <div onClick={() => {
                                        if (materiaux._id === historiqueOpenId) { setHistoriqueOpenId(null); } else {
                                            setHistoriqueOpenId(materiaux._id);
                                        }
                                    }} className={`historiqueFormation ${materiaux._id === historiqueOpenId ? "opened" : ""}`}>
                                        <h5>Historique</h5>
                                        <Icon icon="material-symbols:arrow-forward-ios" />
                                    </div>
                                    <div className='historiqueFormation-tableContainer' style={{ height: materiaux._id === historiqueOpenId ? `${47 * (materiaux.operations.length) + 44}px` : 0 }}>
                                        <SimpleTableRender
                                            columns={getHistoriqueStockageMateriauxColumns(
                                            )}
                                            tableData={materiaux.operations}
                                            sortBy={[
                                            ]}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "modifierQuantiteStock" &&
                    <ModifierStockMateriaux onModifierQuantiteMateriaux={(operation, materiauId) => onModifierQuantiteMateriaux(operation, materiauId)} siteId={props.site._id._id} progressBarRef={props.progressBarRef} modalMateriauxSelected={modalMateriauxSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>

        </>
    );
};

export default MateriauxProvenantSite;

// date de modification quantite modifier par commentaire