import * as actionTypes from './actionTypes';

export const setTopbarCurrentTab = (currentTab) => {
    return {
        type: actionTypes.SET_TOPBAR_CURRENT_TAB,
        currentTab
    };
};

// export const setTopbarScrollToTab = (scrollToTab) => {
//     return {
//         type: actionTypes.TOPBAR_SCROLL_TO_TAB,
//         scrollToTab
//     } 
// }

// export const unloadTopbarScroll = () => {
//     return {
//         type: actionTypes.UNLOAD_TOPBAR_SCROLL,
//     } 
// }

export const unloadTopbarTab = () => {
    return {
        type: actionTypes.UNLOAD_TOPBAR_TAB,
    };
};
