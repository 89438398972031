import React from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import Select from "react-select";
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';

const ModifierFormationSuivi = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };
    // handle form
    const formSchema = yup.object().shape({
        nomFormation: yup.string().required("Veuillez renseigner le nom de la formation"),
        formationPour: yup.string().oneOf(["Tous les salariés", "Amiante", "Chauffeurs"]),
        anneeRenouvellement: yup.number().integer("L'année doit être un nombre entier"),
        moisRenouvellement: yup.number().integer("Le mois doit être un nombre entier"),
        jourRenouvellement: yup.number().integer("Le jour doit être un nombre entier"),
        anneeSignalementUrgence: yup.number().integer("L'année doit être un nombre entier"),
        moisSignalementUrgence: yup.number().integer("Le mois doit être un nombre entier"),
        jourSignalementUrgence: yup.number().integer("Le jour doit être un nombre entier")
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const patchData = {
            ...values,
            anneeRenouvellement: values.anneeRenouvellement ? values.anneeRenouvellement : null,
            moisRenouvellement: values.moisRenouvellement ? values.moisRenouvellement : null,
            jourRenouvellement: values.jourRenouvellement ? values.jourRenouvellement : null,
            anneeSignalementUrgence: values.anneeSignalementUrgence ? values.anneeSignalementUrgence : null,
            moisSignalementUrgence: values.moisSignalementUrgence ? values.moisSignalementUrgence : null,
            jourSignalementUrgence: values.jourSignalementUrgence ? values.jourSignalementUrgence : null
        };
        axiosInstance.patch(`/formationsSuiviSalarie/${props.formationData._id}`, patchData).then((result) => {
            props.progressBarRef.current.complete();
            props.editFormation(result.data);
            actions.resetForm();
            close();
        }).catch((err) => {
            actions.resetForm();
            props.progressBarRef.current.complete();
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nomFormation: props.formationData.nomFormation,
            formationPour: props.formationData.formationPour,
            anneeRenouvellement: props.formationData.renouvellement.annee ? props.formationData.renouvellement.annee : "",
            moisRenouvellement: props.formationData.renouvellement.mois ? props.formationData.renouvellement.mois : "",
            jourRenouvellement: props.formationData.renouvellement.jour ? props.formationData.renouvellement.jour : "",
            anneeSignalementUrgence: props.formationData.signalerUrgence.annee ? props.formationData.signalerUrgence.annee : "",
            moisSignalementUrgence: props.formationData.signalerUrgence.mois ? props.formationData.signalerUrgence.mois : "",
            jourSignalementUrgence: props.formationData.signalerUrgence.jour ? props.formationData.signalerUrgence.jour : ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const formationPourOptions = [
        { label: "Tous les salariés", value: "Tous les salariés" },
        { label: "Amiante", value: "Amiante" },
        { label: "Chauffeurs", value: "Chauffeurs" }
    ];

    const onChangeFormationPour = (opt) => {
        setFieldValue("formationPour", opt.value);
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Modifier une formation</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='nomFormation' className='form-label'>Nom de la formation<span className='required-field'>*</span></label>
                    <input name='nomFormation' className={`new-default-input${errors.nomFormation && touched.nomFormation ? " input-error" : ""}`} type="text" placeholder="Nom de la formation" value={values.nomFormation} onChange={handleChange} onBlur={handleBlur} />
                    {errors.nomFormation && touched.nomFormation && <p className="form-error-message">{errors.nomFormation}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='formationPour' className='form-label'>Formation pour<span className='required-field'>*</span></label>
                    <Select
                        options={formationPourOptions}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeFormationPour(opt)}
                        placeholder={"Formation destinée à"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        value={values.formationPour ? { label: values.formationPour, value: values.formationPour } : ""}
                    />
                    {errors.formationPour && touched.formationPour && <p className="form-error-message">{errors.formationPour}</p>}
                </div>
                <div className='label-and-input'>
                    <label className='form-label'>Formation à renouveler dans</label>
                    <div className='number-label-input-container'>
                        <div>
                            <input name='anneeRenouvellement' className={`new-default-input${errors.anneeRenouvellement && touched.anneeRenouvellement ? " input-error" : ""}`} type="number" placeholder="nombre d'année(s)" value={values.anneeRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>an(s)</p>
                        </div>
                        <div>
                            <input name='moisRenouvellement' className={`new-default-input${errors.moisRenouvellement && touched.moisRenouvellement ? " input-error" : ""}`} type="number" placeholder="nombre de mois" value={values.moisRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>mois</p>
                        </div>
                        <div>
                            <input name='jourRenouvellement' className={`new-default-input${errors.jourRenouvellement && touched.jourRenouvellement ? " input-error" : ""}`} type="number" placeholder="nombre de jour(s)" value={values.jourRenouvellement} onChange={handleChange} onBlur={handleBlur} />
                            <p>jour(s)</p>
                        </div>
                    </div>
                </div>
                <div className='label-and-input last-input'>
                    <label className='form-label'>Mettre un rappel avant la fin de la formation</label>
                    <div className='number-label-input-container'>
                        <div>
                            <input name='anneeSignalementUrgence' className={`new-default-input${errors.anneeSignalementUrgence && touched.anneeSignalementUrgence ? " input-error" : ""}`} type="number" placeholder="nombre d'année(s)" value={values.anneeSignalementUrgence} onChange={handleChange} onBlur={handleBlur} />
                            <p>an(s)</p>
                        </div>
                        <div>
                            <input name='moisSignalementUrgence' className={`new-default-input${errors.moisSignalementUrgence && touched.moisSignalementUrgence ? " input-error" : ""}`} type="number" placeholder="nombre de mois" value={values.moisSignalementUrgence} onChange={handleChange} onBlur={handleBlur} />
                            <p>mois</p>
                        </div>
                        <div>
                            <input name='jourSignalementUrgence' className={`new-default-input${errors.jourSignalementUrgence && touched.jourSignalementUrgence ? " input-error" : ""}`} type="number" placeholder="nombre de jour(s)" value={values.jourSignalementUrgence} onChange={handleChange} onBlur={handleBlur} />
                            <p>jour(s)</p>
                        </div>
                    </div>
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Modifier la formation" />
            </form>
        </div>
    );
};

export default ModifierFormationSuivi;