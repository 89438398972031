import * as actionTypes from '../actions/actionTypes';

const formOptionsState = {};

const reducer = (state = formOptionsState, action) => {
    switch (action.type) {
        case actionTypes.SET_FORM_OPTIONS:
            return {
                ...state,
                [action.nomFormOptions]: action.formOptions,
            };
        case actionTypes.ADD_FORM_OPTIONS:
            return {
                ...state,
                [action.nomFormOptions]: { ...state[action.nomFormOptions], options: state[action.nomFormOptions].options.concat(action.option) }
            };
        case actionTypes.RESET_FORM_OPTIONS:
            return {};
        default:
            return state;
    }
};

export default reducer;