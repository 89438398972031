import React, { useState } from 'react';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../../utils/axiosInstance';

const ArchiverSalarieAmianteForm = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onSubmit = data => {
        setButtonDisabled(true);
        const postData = { typeArchivage: data.typeArchivage, commentaire: data.Commentaire };
        axiosInstance.post(`/salarie_archive/${props.salarie._id._id}/utilisateurAmiante`, postData)
            .then(() => {
                customToast.success(props.salarie._id.nom + " " + props.salarie._id.prenom + " a été archivée avec succès");
                props.archiverSalarie(props.salarie._id._id);
                close();
            }).catch((err) => {
                setButtonDisabled(false);
                showError(err);
            });
        setButtonDisabled(false);

    };

    return (
        <div className="modal-content modifier-form">
            <h3 className='modal-title'>Archiver {props.salarie._id.nom} {props.salarie._id.prenom}</h3>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
                <label className='ajouter-form-label default-label' htmlFor="typeArchivage">Type d'archivage</label>
                <select className="new-default-input" name="typeArchivage" {...register("typeArchivage", { required: true })}>
                    <option value="Sortie salarié">Sortie salarié</option>
                </select>
                <label className='ajouter-form-label default-label' htmlFor="commentaire">Commentaire</label>
                <textarea className="modifier-form-input default-input" name="commentaire" placeholder="Commentaire" {...register("Commentaire")} />
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <input disabled={isButtonDisabled} className="default-button modifier-form-valider" type="submit" value="Archiver" />
                </div>
            </form>
        </div>
    );
};

export default ArchiverSalarieAmianteForm;