import React, { useEffect, useState } from "react";
import FileUploadItem from "./FileUploadItem";
import customToast from "../../utils/ToastifyHelper";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../store/actions/uploadFilesAct";

const AjouterFichierModal = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const dispatch = useDispatch();
    const filesToUpload = useSelector(state => state.uploadFiles);

    const isUploadCompleted = (uploadedFiles) => {
        for (const file of uploadedFiles) {
            if (file.progress !== 100) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        if (filesToUpload.length) {
            if (isUploadCompleted(filesToUpload)) {
                setTimeout(() => {
                    customToast.success("Les documents ont été sauvegardés avec succès");
                    close();
                }, 1000);
            }
        }
        else {
            setTimeout(() => {
                // close();
            }, 1000);
        }
    }, [filesToUpload]);


    // const removeFileFromUploadList = (index) => {
    //     props.removeFileFromUpload(index);
    // };


    // const [indexFileUploaded, setIndexFileUploaded] = useState([]);

    // const addFileSuccesfullyUploaded = (index) => {
    //     setIndexFileUploaded(indexFileUploaded.concat(index));
    // };

    // useEffect(() => {
    //     console.log({ indexFileUploaded: indexFileUploaded, fichiers: props.fichiers.length });
    //     if (indexFileUploaded.length && indexFileUploaded.length === props.fichiers.length) {
    //         customToast.success("Les documents ont été sauvegardés avec succes");
    //         close();
    //     }
    // }, [indexFileUploaded, props.fichiers]);

    return (
        <div className="modal-content default-form">
            <h3 className='modal-title'>Ajouter des fichiers</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="uploadFile-list">
                {filesToUpload.map((fichier) => {
                    return (
                        <FileUploadItem key={fichier._id} fichier={fichier} />
                    );
                })}
                {/* {props.fichiers.map((fichier, index) => {
                    return (
                        <FileUploadItem ajouterFichier={props.ajouterFichier} removeFileFromUploadList={(deleteIndex) => removeFileFromUploadList(deleteIndex)} index={index} uploadLink={props.uploadLink} upload={upload} key={`${fichier.name}-${index}`} fichier={fichier} />
                    );
                })} */}
            </div>
            <button disabled={!filesToUpload.length} style={{ opacity: !filesToUpload.length ? 0.4 : 1 }} className="btn btn-primary medium-btn mgT-m2" onClick={() => dispatch(uploadFile(props.uploadLink))}>Sauvegarder les documents</button>
        </div>
    );
};

export default AjouterFichierModal;
