import React, { useCallback, useRef, useState } from 'react';
import { getCoordinatesFromDD, getCoordinatesFromDMS } from '../../utils/GeneralHelper';
import { useFormik } from 'formik';
import * as yup from "yup";
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import customToast from '../../utils/ToastifyHelper';
import Map, { Marker } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';




const ModifierSite = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const justeauHome = { longitude: -0.312243, latitude: 47.232832 };
    const mapRef = useRef();
    const [currentMarker, setCurrentMarker] = useState(props.modalSiteSelected._id.adresse.hasOwnProperty("gps") && props.modalSiteSelected._id.adresse.gps.latitude !== null && props.modalSiteSelected._id.adresse.gps.longitude !== null ? { latitude: props.modalSiteSelected._id.adresse.gps.latitude, longitude: props.modalSiteSelected._id.adresse.gps.longitude, zoom: 12 } : null);

    const onMapLoad = useCallback(() => {
        mapRef.current.on('click', (e) => {
            setFieldValue("gps", getCoordinatesFromDD(e.lngLat.lat, e.lngLat.lng));
            setCurrentMarker({ longitude: e.lngLat.lng, latitude: e.lngLat.lat });
        });
    }, []);

    // handle form
    const formSchema = yup.object().shape({
        libelle_lieu: yup.string().required("Veuillez saisir le nom du chantier"),
        ville: yup.string().optional(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        lieuExecution: yup.string().optional(),
        gps: yup.string().nullable().notRequired().when("gps", (gps, schema) => {
            if (gps) {
                return schema.matches(/^([0-9]{1,2})°([0-9]{1,2})'([0-9]{1,2}(?:\.[0-9]+)?)"([NS]) ([0-9]{1,3})°([0-9]{1,2})'([0-9]{1,2}(?:\.[0-9]+)?)"([EW])$/, "Erreur de format du point gps, la latitude et la longitude doivent être séparé par un espace");
            }
            return schema;
        }),
    },
        [["codePostal", "codePostal"], ["gps", "gps"]]
    );

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const patchData = {
            ...values,
            codePostal: values.codePostal ? values.codePostal : null,
            gps: values.gps.length ? values.gps : null,
        };
        // post chantier
        axiosInstance.patch(`/site/${props.modalSiteSelected._id._id}`, patchData)
            .then((result) => {
                props.progressBarRef.current.complete();
                customToast.success("Le site a été modifié avec succès");
                props.editSite(result.data);
                close();
            }).catch((err) => {
                actions.resetForm();
                props.progressBarRef.current.complete();
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelle_lieu: props.modalSiteSelected._id.libelle_lieu,
            ville: props.modalSiteSelected._id.adresse.ville,
            codePostal: props.modalSiteSelected._id.adresse.codePostal,
            lieuExecution: props.modalSiteSelected._id.adresse.lieuExecution,
            gps: props.modalSiteSelected._id.adresse.hasOwnProperty("gps") && props.modalSiteSelected._id.adresse.gps.latitude !== null && props.modalSiteSelected._id.adresse.gps.longitude !== null ? getCoordinatesFromDD(props.modalSiteSelected._id.adresse.gps.latitude, props.modalSiteSelected._id.adresse.gps.longitude) : ""

        },
        validationSchema: formSchema,
        onSubmit
    });


    const fieldNotModified = () => {
        return (values.libelle_lieu === props.modalSiteSelected._id.libelle_lieu &&
            values.ville === props.modalSiteSelected._id.adresse.ville &&
            values.codePostal === props.modalSiteSelected._id.adresse.codePostal &&
            values.lieuExecution === props.modalSiteSelected._id.adresse.lieuExecution &&
            values.gps === getCoordinatesFromDD(props.modalSiteSelected._id.adresse.gps.latitude, props.modalSiteSelected._id.adresse.gps.longitude));
    };

    const onGpsBlur = (e) => {
        if (!errors.gps && values.gps !== "") {
            setCurrentMarker(getCoordinatesFromDMS(values.gps));
            mapRef.current.flyTo({
                center: [getCoordinatesFromDMS(values.gps).longitude, getCoordinatesFromDMS(values.gps).latitude],
                essential: true
            });
        }
        else {
            setCurrentMarker(null);
        }
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Modifier un site</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='libelle_lieu' className='form-label'>Nom du chantier<span className='required-field'>*</span></label>
                    <input name='libelle_lieu' className={`new-default-input${errors.libelle_lieu && touched.libelle_lieu ? " input-error" : ""}`} type="text" placeholder="Nom du chantier" value={values.libelle_lieu} onChange={handleChange} onBlur={handleBlur} />
                    {errors.libelle_lieu && touched.libelle_lieu && <p className="form-error-message">{errors.libelle_lieu}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='lieuExecution' className='form-label'>Lieu d'éxecution</label>
                    <input name='lieuExecution' className={`new-default-input${errors.lieuExecution && touched.lieuExecution ? " input-error" : ""}`} type="text" placeholder="Lieu d'éxecution" value={values.lieuExecution} onChange={handleChange} onBlur={handleBlur} />
                    {errors.lieuExecution && touched.lieuExecution && <p className="form-error-message">{errors.lieuExecution}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='ville' className='form-label'>Ville</label>
                    <input name='ville' className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} type="text" placeholder="Ville" value={values.ville} onChange={handleChange} onBlur={handleBlur} />
                    {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='codePostal' className='form-label'>Code postal</label>
                    <input name='codePostal' className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} type="text" placeholder="Code postal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                    {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='gps' className='form-label'>Point gps</label>
                    <input name='gps' className={`new-default-input${errors.gps && touched.gps ? " input-error" : ""}`} type="text" placeholder={`exemple: 47°27'43.0"N 0°30'57.2"W`} value={values.gps} onChange={handleChange} onBlur={(e) => { handleBlur(e); onGpsBlur(e); }} />
                    {errors.gps && touched.gps && <p className="form-error-message">{errors.gps}</p>}
                </div>
                <Map
                    ref={mapRef}
                    attributionControl={false}
                    initialViewState={props.modalSiteSelected._id.adresse.hasOwnProperty("gps") && props.modalSiteSelected._id.adresse.gps.latitude !== null && props.modalSiteSelected._id.adresse.gps.longitude !== null ? { latitude: props.modalSiteSelected._id.adresse.gps.latitude, longitude: props.modalSiteSelected._id.adresse.gps.longitude, zoom: 12 } : { longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                    onLoad={onMapLoad}
                    style={{ width: "100%", height: "100%" }}
                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                >
                    {currentMarker !== null &&
                        <Marker longitude={currentMarker.longitude} latitude={currentMarker.latitude} anchor="bottom">

                        </Marker>
                    }
                </Map>

                <div className="button-container-flex mgT-b">
                    <button type="button" disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => close()}>Annuler</button>
                    <input disabled={isSubmitting || fieldNotModified()} style={{ opacity: isSubmitting || fieldNotModified() ? 0.4 : 1 }} type="submit" className='btn btn-primary medium-btn fw' value="Modifier" />
                </div>
            </form>
        </div>

    );
};

export default ModifierSite;