import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const chantierListState = { allChantier: [], zones: [], chantierFormatOptions: [] };

const reducer = (state = chantierListState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_CHANTIER_ZONE_LIST:
            return updateObject(state, { allChantier: action.chantierList, zones: action.zoneList });
        case actionTypes.UNLOAD_CHANTIER_ZONE_LIST:
            return { allChantier: [], zones: [], chantierFormatOptions: [] };
        case actionTypes.ADD_CHANTIER:
            return updateObject(state, { allChantier: state.allChantier.concat(action.chantier) });
        case actionTypes.MODIFY_CHANTIER:
            return {
                ...state,
                allChantier: state.allChantier.map(
                    (chantier) => chantier._id._id === action.chantierUpdated._id._id ?
                        {
                            ...chantier,
                            _id: action.chantierUpdated._id,
                            statut_chantier: action.chantierUpdated.statut_chantier,
                            codeChantier: action.chantierUpdated.codeChantier,
                            zone: action.chantierUpdated.zone,
                            secteur: action.chantierUpdated.secteur,
                            historique: action.chantierUpdated.historique,
                            type: action.chantierUpdated.type
                        }
                        : chantier
                )
            };
        case actionTypes.DELETE_CHANTIER:
            return {
                ...state,
                allChantier: state.allChantier.filter(chantier => chantier._id._id !== action.chantierId)
            };
        case actionTypes.LOAD_CHANTIER_OPTIONS:
            return updateObject(state, { chantierFormatOptions: action.allChantierOptions });
        default:
            return state;
    }
};

export default reducer;
