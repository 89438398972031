import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../../store/actions/exportAction';
import axiosInstance from '../../../utils/axiosInstance';
import TimePicker from 'react-time-picker';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';

const addHeureBanque = (horaireBanque) => actionCreators.addTempsBanqueHeure(horaireBanque);

const ModifierBanqueHeureForm = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [commentaire, setCommentaire] = useState('');

    const dispatch = useDispatch();
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onSubmit = () => {
        setButtonDisabled(true);
        if (valueHoraire !== null && valueHoraire[0] !== null && valueHoraire[1] !== null) {
            const horaire = valueHoraire.split(':');
            const heure = parseInt(horaire[0]);
            const minute = parseInt(horaire[1]);
            if (!props.positif) {
                axiosInstance.post('/banque_heure', { id_utilisateur: props.utilisateur, heure: -heure, minute: -minute, commentaire: commentaire })
                    .then((result) => {
                        dispatch(addHeureBanque(result.data));
                        customToast.success("L'heure a été enlevée à la banque");
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
            }
            else {
                axiosInstance.post('/banque_heure', { id_utilisateur: props.utilisateur, heure: heure, minute: minute, commentaire: commentaire })
                    .then((result) => {
                        dispatch(addHeureBanque(result.data));
                        customToast.success("L'heure a été ajoutée à la banque");
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
            }
        }
        else {
            setButtonDisabled(false);
            customToast.error("Veuillez compléter les heures et les minutes");
        }
    };

    const [valueHoraire, onChangeHoraire] = useState([null, null]);

    return (
        <div className="modal-content supprimerForm">
            <h3 className="modal-title">{props.positif ? "Ajouter" : "Enlever"} des heures à la banque</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <hr className='hrBanque' />
            <div className="vertical-form scrollable">
                <label className='ajouter-form-label default-label' htmlFor="nombreHeureBanque">Nombre d'heures</label>
                <TimePicker showLeadingZeros={true} id="nombreHeureBanque" className="input-datetimePicker" format="HH:mm" disableClock onChange={onChangeHoraire} value={valueHoraire} />
                <label className='ajouter-form-label default-label' htmlFor="commentaireBanque">Commentaire</label>
                <input className='default-input' type="text" id="commentaireBanque" placeholder="Commentaire" onChange={e => setCommentaire(e.target.value)} />
                <button disabled={isButtonDisabled} onClick={() => onSubmit()} className="validate-button-fw default-button banqueHeure-button">{props.positif ? "Ajouter" : "Enlever"}</button>
            </div>
        </div>
    );
};

export default ModifierBanqueHeureForm;