import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { showError } from '../../utils/ErrorHelper';
import axiosInstance from '../../utils/axiosInstance';
import { Hidden } from '@material-ui/core';
import LinesEllipsis from 'react-lines-ellipsis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';

import { useNavigate } from 'react-router-dom';
import { convert } from 'html-to-text';
import ClipLoader from "react-spinners/ClipLoader";



const ActualiteItem = (props) => {
    const [imagePreview, setImagePreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [color,] = useState("#d13852");

    const navigate = useNavigate();

    const options = {
        wordwrap: 130
    };

    useEffect(() => {
        if (props.actualite.pictures.hasOwnProperty("articlePicture") && props.actualite.pictures.articlePicture !== null) {
            setLoading(true);
            axiosInstance.get(`uploads/documents/${props.actualite.pictures.articlePicture._id}`, {
                responseType: 'blob',
                timeout: 20000
            }).then((res) => {
                let fileURL = URL.createObjectURL(res.data);
                setImagePreview(fileURL);
                setLoading(false);
            }).catch((error) => {
                showError(error);
            });
        }
    }, []);

    return (
        loading
            ?
            <div className='actualite-container'>
                <div style={{ width: "100%", height: "350px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ClipLoader loading={true} color={color} size={34} />
                </div>
            </div>
            :
            <div className='actualite-container' onClick={() => navigate(`/justeau-actus/actualites/${props.actualite._id}`)}>
                <div style={{ padding: "1.2rem 1.6rem" }}>
                    <p className='small-text'>publié le {format(new Date(props.actualite.created), "dd/MM/yyyy")}</p>
                    <h3>{props.actualite.titre}</h3>
                </div>
                <div style={{ width: "100%", height: "190px", overflow: "hidden" }}>
                    {imagePreview === null ?
                        <div style={{ width: "100%", height: "190px", display: "flex", justifyContent: "center", alignItems: "center", borderTop: "1px solid #d9d9d9", borderBottom: "1px solid #d9d9d9" }}>
                            <FontAwesomeIcon style={{ width: "50%", height: "50%" }} icon={faImage} />
                        </div>
                        :
                        <img
                            src={imagePreview}
                            className='actualiteImage'
                            // style={{  
                            //     display: 'block',
                            //     maxWidth: '100%',
                            //     maxHeight: '450px'
                            // }}
                            onLoad={() => { URL.revokeObjectURL(imagePreview); }}
                        >
                        </img>
                    }
                </div>
                <div style={{ padding: "1.2rem 1.6rem" }}>
                    {/* <p>{props.actualite.articleContent.replace(/<[^>]*>?/gm, '')}</p> */}
                    <LinesEllipsis
                        style={{ fontSize: "1.6rem" }}
                        text={convert(props.actualite.articleContent)}
                        maxLine={"1"}
                        ellipsis='...'
                        trimRight={true}
                        basedOn='letters'
                    />
                </div>
            </div>
    );
};

export default ActualiteItem;;