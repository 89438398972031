import React, { useEffect, useState } from 'react';
import * as actionCreators from '../../store/actions/exportAction';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import ArchiveChantierDetail from '../../components/ArchiveChantierDetail';
import ArchiverChantierForm from '../../components/forms/ArchiverChantierForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import TableRender from '../../components/tables/TableRender';
import { getArchiveChantierColumns } from '../../components/tables/TableColumns';
import { advancedSearch } from '../../utils/GeneralHelper';
import SearchInput from '../../components/ui/SearchInput';
import Breadcrumb from '../../components/ui/Breadcrumb';


const onLoadPage = () => (actionCreators.getChantierZoneList());
const onQuitPage = () => (actionCreators.unloadChantierZoneList());

const GestionArchiveChantier = () => {
    const [showedItems, setShowedItems] = useState([]);
    const [searchBarContent, setSearchBarContent] = useState(null);
    const [selectStatutChantier, setSelectStatutChantier] = useState("All");

    // get chantier_zone state
    const chantierZoneState = useSelector(
        state => state.chantierZoneList
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(onLoadPage());
        return () => {
            dispatch(onQuitPage());
            setShowedItems([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let showedChantier = chantierZoneState.allChantier.filter(chantier => (chantier.statut_chantier === 1));
        // searchbar
        if (searchBarContent !== null) {
            showedChantier = showedChantier.filter(chantier => (advancedSearch(chantier._id.libelle_lieu, searchBarContent) || advancedSearch(chantier.codeChantier, searchBarContent)));
        }
        if (selectStatutChantier !== 'All') {
            showedChantier = showedChantier.filter(chantier => {
                return chantier.secteur === selectStatutChantier;
            });
        }
        setShowedItems(showedChantier);

    }, [chantierZoneState.allChantier, searchBarContent, selectStatutChantier]);

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const searchChantier = (e) => {
        if (e.target.value !== '') {
            setSearchBarContent(e.target.value);
        }
        else {
            setSearchBarContent(null);
        }
    };

    const selectStatutChantierInput = (e) => {
        setSelectStatutChantier(e.target.value);
    };

    const openSupprimerChantier = (chantierData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerChantier" chantier={chantierData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openChantierDetail = (chantierData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ArchiveChantierDetail chantier={chantierData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const archiverChantier = () => {
        dispatch(onQuitPage());
        dispatch(onLoadPage());
    };

    const openDesarchiverChantier = (chantierData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ArchiverChantierForm archivage={false} chantier={chantierData} archiverChantier={() => archiverChantier()} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    return (
        <div className='section'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                <h1>Archive des chantiers</h1>
            </div>
            <Breadcrumb
                links={[
                    { label: "Gestion des données", link: "/gestion-de-donnees" },
                    { label: "Archive des chantiers", link: "/gestion-de-donnees/archive-chantier" },
                ]}
            />
            <div className="filterContainer mgT-m1">
                <SearchInput placeholder="Rechercher un chantier" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={searchChantier} />
                <select className="new-default-input" onChange={(e) => selectStatutChantierInput(e)} name="chantierStatutSelect" id="chantierStatut-select">
                    <option value="All">Tous les chantiers</option>
                    <option value="Gros oeuvres">Gros oeuvres</option>
                    <option value="Travaux publics">Travaux publics</option>
                </select>
            </div>
            {/* <div className="container-basic mgB-m2">
                <h1 className="h1-blue-dark">Liste des chantiers</h1>
                <div className="tableau-top-section">
                    <h3>{showedItems.length} chantier{showedItems.length > 1 && "s"} {showedItems.length > 1 ? "correspondent" : "correspond"} à votre recherche</h3>
                    <input className="header-input" onChange={(e) => searchChantier(e)} name="searchChantier" type="text" placeholder="Rechercher un chantier" />
                    <select className="header-select" onChange={(e) => selectStatutChantierInput(e)} name="chantierStatutSelect" id="chantierStatut-select">
                        <option value="All">Tous les chantiers</option>
                        <option value="Gros oeuvres">Gros oeuvres</option>
                        <option value="Travaux publics">Travaux publics</option>
                    </select>
                </div>
            </div> */}
            <TableRender
                columns={getArchiveChantierColumns((chantierData) => openChantierDetail(chantierData), (chantierData) => openDesarchiverChantier(chantierData), (chantierData) => openSupprimerChantier(chantierData))}
                tableData={showedItems}
                sortBy={[
                    {
                        id: '_id.libelle_lieu',
                        desc: false
                    }
                ]}
                pageName="ArchiveChantier"
            />
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default GestionArchiveChantier;