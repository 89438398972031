import React, { useState, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from "../../../utils/GeneralHelper";
import { Icon } from '@iconify/react';
import UploadDocumentChantierDoe from '../UploadDocumentChantierDoe';
import customToast from '../../../utils/ToastifyHelper';

const AjouterModifierDocumentChantier = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploadFile, setUploadFile] = useState(false);
    const [nomDocument, setNomDocument] = useState(props.documentSaveRoute === "modifierDocumentChantierSansType" ? props.title : "");

    // DROPZONE
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ multiple: false, accept: { 'text/csv': [], 'application/pdf': [], 'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [] } });

    const removeFile = () => {
        setFiles([]);
    };

    useEffect(() => {
        setFiles(acceptedFiles.map((file, index) => {
            return (
                <UploadDocumentChantierDoe documentId={props.documentId} title={nomDocument} updateDocuments={(documents) => props.updateDocuments(documents)} idChantier={props.idChantier} close={close} documentSaveRoute={props.documentSaveRoute} documentTypeId={props.documentTypeId} uploadFile={uploadFile} removeFile={removeFile} file={file} index={index} key={file.path} />
            );
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles, uploadFile]);

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const onSubmit = () => {
        setButtonDisabled(true);
        if (!nomDocument.length && props.documentSaveRoute === "ajouterDocumentChantierSansType") {
            setButtonDisabled(false);
            customToast.error("Veuillez saisir un nom de document");
        }
        else {
            setButtonDisabled(false);
            setUploadFile(true);
        }
    };

    return (
        <div className='modal-content ajouter-form'>
            <h3 className='modal-title'>{props.documentSaveRoute === "ajouterDocumentChantierSansType" ? "Ajouter un document au DOE" : "Modifier un document du DOE"}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                {
                    props.documentSaveRoute === "ajouterDocumentChantierSansType" &&
                    <>
                        <label className='ajouter-form-label default-label' htmlFor="nom">Nom du document</label>
                        <input onChange={(e) => setNomDocument(e.target.value)} className="ajouter-form-input default-input" name="nom" type="text" placeholder="Entrez le nom du document" />
                    </>
                }
                <label className='ajouter-form-label default-label' htmlFor="nom">Document</label>
                <div className="container last-input">
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                        <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                    </div>
                    <div>
                        {files.length > 0 && <h4 className='ajouter-form-label default-label'>Document à enregistrer</h4>}
                        <ul className='fileList'>{files}</ul>
                    </div>
                </div>
                <button onClick={() => onSubmit()} disabled={isButtonDisabled} className="btn btn-primary medium-btn">{props.documentSaveRoute === "ajouterDocumentChantierSansType" ? "Ajouter un document au DOE" : "Modifier un document du DOE"}</button>
            </div>
        </div>
    );
};

export default AjouterModifierDocumentChantier;