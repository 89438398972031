import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import ClipLoader from "react-spinners/ClipLoader";
import justeauLogo from '../../assets/img/justeaulogo.png';
import { Icon } from '@iconify/react';

const onUserLogin = (identifiant, mdp, stayConnected) => (actionCreators.authUserInput({ identifiant: identifiant, mdp: mdp, stayConnected: stayConnected }));
const onAutoConnect = (refreshToken) => (actionCreators.loginByTokenStored(refreshToken));

const LoginForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    let [color,] = useState("#d13852");
    const [showPassword, setShowPassword] = useState(false);
    const userState = useSelector(
        state => state.user
    );
    const dispatch = useDispatch();
    // Connection par clavier
    const onSubmit = data => {
        dispatch(onUserLogin(data['Identifiant'], data['Mot de passe'], data['Restez connecté']));
    };
    const [isChecked, setCheck] = useState(false);
    // Si un refreshToken est sauvegardé en local connection
    if (userState.refreshToken !== null && userState.user == null) {
        dispatch(onAutoConnect(userState.refreshToken));
    }
    let loginSentence = <p className="login-sentence">Connectez-vous pour accéder à votre espace utilisateur</p>;
    if (userState.loginError !== null) {
        loginSentence = <p className="login-sentence error_sentence">L'identifiant ou le mot de passe est incorrect, veuillez réesayer</p>;
    }
    if (userState.loadingSpinner) {
        loginSentence = <div className="login-sentence" style={{ display: "flex", alignContent: "center", justifyContent: "center" }}><ClipLoader loading={true} color={color} size={22} /></div>;
    }

    // lire les erreurs state Error et ecrire connection invalide
    return (
        <div className="login-container">
            <form className="" onSubmit={handleSubmit(onSubmit)}>
                <img src={justeauLogo} alt="justeau logo" />
                {loginSentence}
                <label className='login-label' htmlFor="identifiant">Identifiant</label>
                <input className="login-field" name='identifiant' type="text" placeholder="Entrez votre identifiant" {...register("Identifiant", { required: true })} />
                {errors['Identifiant'] && errors['Identifiant'].type === "required" && <p className="login-error-message">Veuillez saisir un identifiant</p>}
                <label className='login-label' htmlFor="password">Mot de passe</label>
                <div className="loginWrapper">
                    <div onClick={() => setShowPassword(!showPassword)} className="loginIcon"><Icon icon={showPassword ? "fa-solid:eye" : "fa-solid:eye-slash"} /></div>
                    <input className="login-field" type={showPassword ? "text" : "password"} placeholder="Entrez votre mot de passe" {...register("Mot de passe", { required: true, maxLength: 20 })} />
                </div>
                {errors['Mot de passe'] && errors['Mot de passe'].type === "required" && <p className="login-error-message">Veuillez saisir un mot de passe</p>}
                {errors['Mot de passe'] && errors['Mot de passe'].type === "maxLength" && <p className="login-error-message">Trop de caractères renseignés dans le champ</p>}
                <hr />
                <div className="login-options">
                    <div className="checkbox-container" onClick={() => setCheck(!isChecked)}>
                        <input className="login-checkbox" type="checkbox" checked={isChecked} {...register("Restez connecté", {})} />
                        <label className='checkbox-label-login' htmlFor="checkbox">Restez connecté</label>
                    </div>
                    <p>Mot de passe oublié ?</p>
                </div>
                <input className="button login-button" type="submit" value="Se connecter" />
            </form>
        </div>
    );
};

export default LoginForm;
