import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../utils/axiosInstance';
import customToast from '../../utils/ToastifyHelper';
import fileDownload from 'js-file-download';
import SelectSearch from "../SelectSearch";

const ModifierSalarieForm = (props) => {
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onSubmit = data => {
        setButtonDisabled(true);
        axiosInstance.get(`/export_excel/exportProcessus${data.annee.value}`, { responseType: 'blob' })
            .then((result) => {
                fileDownload(result.data, "Processus.xlsx");

            });
        close();
        setButtonDisabled(false);

    };

    let options = [];

    for (let i = new Date().getFullYear(); i >= 2018; i--) {
        options.push(i);

    }
    return (
        <div className="modal-content modifier-form">
            <h3 className='modal-title'>Exporter les données</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
                <label className='ajouter-form-label default-label' htmlFor="annee">Année</label>
                <SelectSearch
                    options={options}
                    dataName="annee"
                    control={control}
                    name="annee"
                    className="formSelect-search-container mgB-s2"
                    classNamePrefix="formSelect-search"
                    placeholder="Sélectionner l'annee de l'export"
                    isClearable
                    isSearchable={false}
                    required
                    defaultValue={{
                        label: `${options[0]}`,
                        value: options[0],
                    }}
                />
                <div className="button-container">
                    <input disabled={isButtonDisabled} className="default-button modifier-form-valider" type="submit" value="Exporter" />
                </div>
            </form>
        </div>
    );
};

export default ModifierSalarieForm;