import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import Select from "react-select";
import customToast from '../../../utils/ToastifyHelper';

const AjouterMateriauxProvenantSite = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [materiauxOptions, setMateriauxOptions] = useState([]);

    useEffect(() => {
        axiosInstance.get("/materiaux").then((result) => {
            setMateriauxOptions(result.data.map((materiaux) => {
                return (
                    { value: materiaux._id, label: materiaux.libelle_mat }
                );
            }));
        }).catch((err) => {
            showError(err);
        });
    }, []);


    // handle form
    const formSchema = yup.object().shape({
        materiaux: yup.string().required("Veuillez entrer le matériaux provenant du site"),
        stock: yup.number().required("Veuillez entrer la quantité présent sur le site")
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        // post materiauxSite
        axiosInstance.post('/materiauxSites', { id_site: props.modalDataSelected, materiaux: values.materiaux })
            .then((result) => {
                axiosInstance.patch(`/site/${props.modalDataSelected}/ajouterMateriauxProvenantSite`, { materiaux: result.data._id, stock: values.stock }).then((res) => {
                    props.progressBarRef.current.complete();
                    props.addMateriauxProvenantDuSite(res.data);
                    customToast.success("Le matériaux a été ajouté avec succès");
                }).catch((err2) => {
                    setSubmitting(false);
                    props.progressBarRef.current.complete();
                    showError(err2);
                });
                close();
            }).catch((err) => {
                setSubmitting(false);
                props.progressBarRef.current.complete();
                showError(err);
            });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            materiaux: "",
            stock: 0
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onChangeMateriaux = (opt) => {
        setFieldValue("materiaux", opt.value);
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Ajouter un materiaux provenant du site</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='materiaux' className='form-label'>Matériaux<span className='required-field'>*</span></label>
                    <Select
                        options={materiauxOptions}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeMateriaux(opt)}
                        placeholder={"Matériaux provenant du site"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                    />
                    {errors.materiaux && touched.materiaux && <p className="form-error-message">{errors.materiaux}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='stock' className='form-label'>Quantité</label>
                    <input name='stock' className={`new-default-input${errors.stock && touched.stock ? " input-error" : ""}`} type="number" placeholder="Quantité présent sur site" value={values.stock} onChange={handleChange} onBlur={handleBlur} />
                    {errors.stock && touched.stock && <p className="form-error-message">{errors.stock}</p>}
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Ajouter le matériaux" />
            </form>
        </div>
    );
};

export default AjouterMateriauxProvenantSite;