import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const userListState = {
    adminList: [],
    ouvrierList: [],
    interimaireList: [],
    chauffeurList: [],
    chefChantierList: [],
    dirigeantChantierList: [],
    archiveList: []
};

const reducer = (state = userListState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_OUVRIERS_LIST:
            return {
                ...state,
                adminList: [...state.adminList],
                ouvrierList: [...action.ouvrierList],
                interimaireList: [...state.interimaireList],
                chauffeurList: [...state.chauffeurList],
                chefChantierList: [...state.chefChantierList],
                dirigeantChantierList: [...state.dirigeantChantierList],
                archiveList: [...state.archiveList]
            };
        case actionTypes.LOAD_ADMIN_LIST:
            return action.adminList;
        case actionTypes.LOAD_CHEFS_CHANTIER_LIST:
            return {
                ...state,
                adminList: [...state.adminList],
                ouvrierList: [...state.ouvrierList],
                interimaireList: [...state.interimaireList],
                chauffeurList: [...state.chauffeurList],
                chefChantierList: [...action.chefChantierList],
                dirigeantChantierList: [...state.dirigeantChantierList],
                archiveList: [...state.archiveList]
            };
        case actionTypes.LOAD_ARCHIVE_LIST:
            return {
                ...state,
                adminList: [...state.adminList],
                ouvrierList: [...state.ouvrierList],
                interimaireList: [...state.interimaireList],
                chauffeurList: [...state.chauffeurList],
                chefChantierList: [...state.chefChantierList],
                dirigeantChantierList: [...state.dirigeantChantierList],
                archiveList: [...state.archiveList, ...action.archiveList]
            };
        case actionTypes.LOAD_CHAUFFEURS_LIST:
            return action.chauffeurList;
        case actionTypes.LOAD_INTERIMAIRES_LIST:
            return {
                ...state,
                adminList: [...state.adminList],
                ouvrierList: [...state.ouvrierList],
                interimaireList: [...action.interimaireList],
                chauffeurList: [...state.chauffeurList],
                chefChantierList: [...state.chefChantierList],
                dirigeantChantierList: [...state.dirigeantChantierList],
                archiveList: [...state.archiveList]
            };
        case actionTypes.LOAD_USER_LIST:
            return updateObject(state, action.userList);
        case actionTypes.ADD_USER_TO_LIST:
            switch (action.newSalarie.userRole) {
                case 'Ouvrier':
                    return {
                        ...state,
                        adminList: [...state.adminList],
                        ouvrierList: state.ouvrierList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: false } }),
                        interimaireList: [...state.interimaireList],
                        chauffeurList: [...state.chauffeurList],
                        chefChantierList: [...state.chefChantierList],
                        dirigeantChantierList: [...state.dirigeantChantierList],
                        archiveList: [...state.archiveList]
                    };
                case 'Opérateur':
                    return {
                        ...state,
                        adminList: [...state.adminList],
                        ouvrierList: state.ouvrierList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: true } }),
                        interimaireList: [...state.interimaireList],
                        chauffeurList: [...state.chauffeurList],
                        chefChantierList: [...state.chefChantierList],
                        dirigeantChantierList: [...state.dirigeantChantierList],
                        archiveList: [...state.archiveList]
                    };
                case 'Chef de chantier':
                    return {
                        ...state,
                        adminList: [...state.adminList],
                        ouvrierList: [...state.ouvrierList],
                        interimaireList: [...state.interimaireList],
                        chauffeurList: [...state.chauffeurList],
                        chefChantierList: state.chefChantierList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: false } }),
                        archiveList: [...state.archiveList],
                        dirigeantChantierList: [...state.dirigeantChantierList]
                    };
                case 'Encadrant de chantier':
                    return {
                        ...state,
                        adminList: [...state.adminList],
                        ouvrierList: [...state.ouvrierList],
                        interimaireList: [...state.interimaireList],
                        chauffeurList: [...state.chauffeurList],
                        chefChantierList: state.chefChantierList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: true } }),
                        dirigeantChantierList: [...state.dirigeantChantierList],
                        archiveList: [...state.archiveList]
                    };
                case 'Administration':
                    return {
                        ...state,
                        adminList: state.adminList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: false } }),
                        ouvrierList: [...state.ouvrierList],
                        interimaireList: [...state.interimaireList],
                        chauffeurList: [...state.chauffeurList],
                        chefChantierList: [...state.chefChantierList],
                        dirigeantChantierList: [...state.dirigeantChantierList],
                        archiveList: [...state.archiveList]
                    };
                case 'Dispatcher':
                    return {
                        ...state,
                        adminList: state.adminList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: false } }),
                        ouvrierList: [...state.ouvrierList],
                        interimaireList: [...state.interimaireList],
                        chauffeurList: [...state.chauffeurList],
                        chefChantierList: [...state.chefChantierList],
                        dirigeantChantierList: [...state.dirigeantChantierList],
                        archiveList: [...state.archiveList]
                    };
                case 'Encadrant technique':
                    return {
                        ...state,
                        adminList: [...state.adminList],
                        ouvrierList: [...state.ouvrierList],
                        interimaireList: [...state.interimaireList],
                        chauffeurList: [...state.chauffeurList],
                        dirigeantChantierList: [...state.dirigeantChantierList],
                        chefChantierList: state.chefChantierList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: true } }),
                        archiveList: [...state.archiveList]
                    };
                case 'Intérimaire':
                    return {
                        ...state,
                        adminList: [...state.adminList],
                        ouvrierList: [...state.ouvrierList],
                        interimaireList: state.interimaireList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: false } }),
                        chauffeurList: [...state.chauffeurList],
                        chefChantierList: [...state.chefChantierList],
                        dirigeantChantierList: [...state.dirigeantChantierList],
                        archiveList: [...state.archiveList]
                    };
                case 'Chauffeur':
                    return {
                        ...state,
                        adminList: [...state.adminList],
                        ouvrierList: [...state.ouvrierList],
                        interimaireList: [...state.interimaireList],
                        dirigeantChantierList: [...state.dirigeantChantierList],
                        chauffeurList: state.chauffeurList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: false } }),
                        chefChantierList: [...state.chefChantierList],
                        archiveList: [...state.archiveList]
                    };
                case 'Conducteur de travaux':
                    return {
                        ...state,
                        adminList: [...state.adminList],
                        ouvrierList: [...state.ouvrierList],
                        interimaireList: [...state.interimaireList],
                        dirigeantChantierList: state.dirigeantChantierList.concat({ _id: { ...action.newSalarie.user, secteur: action.newSalarie.secteur, isAmiante: false } }),
                        chauffeurList: [...state.chauffeurList],
                        chefChantierList: [...state.chefChantierList],
                        archiveList: [...state.archiveList]
                    };
                default:
                    return state;
            }

        case actionTypes.UNLOAD_USER_LIST:
            return {
                adminList: [],
                ouvrierList: [],
                interimaireList: [],
                chauffeurList: [],
                chefChantierList: [],
                dirigeantChantierList: [],
                archiveList: []
            };
        default:
            return state;
    }
};

export default reducer;

