import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import * as actionCreators from '../../store/actions/exportAction';
import { useSelector, useDispatch } from 'react-redux';
import CategorieItem from '../../components/CategorieItem';
import AjouterCategorieDocumentsForm from '../../components/forms/DocumentsForms/AjouterCategorieDocumentsForm';
import ModifierCategorieDocumentsForm from '../../components/forms/ModifierCategorieDocumentsForm';
import ModifierTypeDocumentsForm from '../../components/forms/ModifierTypeDocumentsForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import { advancedSearch } from '../../utils/GeneralHelper';

const onLoadPage = () => (actionCreators.getCategorieDocument());
const onQuitPage = () => (actionCreators.unloadCategoriesDocuments());

const CategorieDocuments = () => {
    const categoriesTypesDocument = useSelector(state => state.categorieDocument);
    const dispatch = useDispatch();
    const [showedItems, setShowedItems] = useState([]);
    const [searchBarContent, setSearchBarContent] = useState(null);
    const [selectCategorieDocument, setSelectCategorieDocument] = useState("All");

    // const selectCategorie = (e) => {
    //     setSelectCategorieDocument(e.target.value);
    // }

    const searchDocumentsType = (e) => {
        if (e.target.value !== '') {
            setSearchBarContent(e.target.value);
        }
        else {
            setSearchBarContent(null);
        }
    };

    useEffect(() => {
        dispatch(onLoadPage());
        return () => {
            dispatch(onQuitPage());
            setShowedItems([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let showedCategorie = categoriesTypesDocument;
        // searchbar
        if (searchBarContent !== null) {
            showedCategorie = showedCategorie.filter(categorie => advancedSearch(categorie.libelleCategorie, searchBarContent));
        }
        if (selectCategorieDocument !== 'All') {
            showedCategorie = showedCategorie.filter(showedCategorie => showedCategorie.libelleCategorie === selectCategorieDocument);
        }
        setShowedItems(showedCategorie);
    }, [categoriesTypesDocument, searchBarContent, selectCategorieDocument]);

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openAddCategorie = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterCategorieDocumentsForm closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierCategorie = (categorieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierCategorieDocumentsForm categorie={categorieData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerCategorie = (categorieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerCategorie" categorie={categorieData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerType = (typeData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerTypeDocuments" categorie={typeData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierType = (typeData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierTypeDocumentsForm categorie={typeData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const totalTypeFichier = () => {
        let total = 0;
        for (const categorie of categoriesTypesDocument) {
            total += categorie.typeDocuments.length;
        }
        return total;
    };

    return (
        <div className='section'>
            <div className="container-basic mgB-m2">
                <h1 className="h1-blue-dark">Gestion des catégories et des types de documents</h1>
                <div className="tableau-top-section">
                    <h3>Il y a {categoriesTypesDocument.length} catégorie et {totalTypeFichier()} types de documents</h3>
                    <input className="header-input" onChange={(e) => searchDocumentsType(e)} name="searchCategorie" type="text" placeholder="Rechercher une catégorie, un type..." />
                    {/* <select className="header-select" onChange={(e) => selectCategorie(e)} name="categorieSelect" id="categorie-select">
                        <option value="All">Tous les statuts</option>
                        {categoriesTypesDocument.map((categorie) => {
                            return <option key={categorie._id} value={categorie.libelleCategorie}>{categorie.libelleCategorie}</option>;
                        })}
                    </select> */}
                    <button onClick={() => openAddCategorie()} className='default-button default-button-blue'>+ Catégorie</button>
                </div>
            </div>
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
            <div className="main-content main-content-padding">
                <div className="headerCategorie">
                    <p className='headerCategorie-label'>Nom de la catégorie</p>
                    <p className='headerCategorie-label'>Nombre de type documents</p>
                    <p className='headerCategorie-label'>Actions</p>
                </div>
                {showedItems.map((categorie, index) => {
                    if (index === 0) {
                        return <CategorieItem openModifierType={(typeData) => openModifierType(typeData)} openModifierCategorie={(categorieData) => openModifierCategorie(categorieData)} openSupprimerType={(typeData) => openSupprimerType(typeData)} openSupprimerCategorie={(categorieData) => openSupprimerCategorie(categorieData)} border={true} key={categorie._id} categorie={categorie} />;
                    }
                    else {
                        return <CategorieItem openModifierType={(typeData) => openModifierType(typeData)} openModifierCategorie={(categorieData) => openModifierCategorie(categorieData)} openSupprimerCategorie={(categorieData) => openSupprimerCategorie(categorieData)} openSupprimerType={(typeData) => openSupprimerType(typeData)} border={false} key={categorie._id} categorie={categorie} />;
                    }
                })}
            </div>
        </div>
    );
};

export default CategorieDocuments;