import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import * as actionTypes from './actionTypes';

export const loadEquipementsList = (equipementEpcList) => {
    return {
        type: actionTypes.LOAD_EQUIPEMENT_EPC_LIST,
        equipementEpcList: equipementEpcList.equipementEPC,
        documentsEquipementEpcList: equipementEpcList.documentsEPC
    };
};

export const unloadEquipementsList = () => {
    return {
        type: actionTypes.UNLOAD_EQUIPEMENT_EPC_LIST,
    };
};

export const addEquipementEpc = (equipementEpcData) => {
    return {
        type: actionTypes.ADD_EQUIPEMENT_EPC,
        equipementEpc: equipementEpcData
    };
};

export const addDocumentEpc = (equipementEpcData) => {
    return {
        type: actionTypes.ADD_DOCUMENT_EPC,
        equipementEpc: equipementEpcData
    };
};

export const deleteDocumentEpc = (equipementEpcData) => {
    return {
        type: actionTypes.DELETE_DOCUMENT_EPC,
        equipementEpc: equipementEpcData
    };
};

export const modifyDocumentEpc = (equipementEpcData) => {
    return {
        type: actionTypes.MODIFY_DOCUMENT_EPC,
        equipementEpc: equipementEpcData
    };
};


export const patchEquipementEpc = (equipementEpcNewData) => {
    return {
        type: actionTypes.MODIFY_EQUIPEMENT_EPC,
        equipementUpdated: equipementEpcNewData
    };
};

export const deleteEquipementEpc = (equipementEpcId) => {
    return {
        type: actionTypes.DELETE_EQUIPEMENTS_EPC,
        equipementEpcId: equipementEpcId
    };
};

export const getEquipementEpcList = () => (dispatch) => {
    // get all equipement epc

    axiosInstance.get('/equipementEPC', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            const equipement = result.data.equipementEPC;

            axiosInstance.get('/uploads/documentEPC', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
                .then((result) => {
                    dispatch(loadEquipementsList({ equipementEPC: equipement, documentsEPC: result.data }));
                }).catch((err) => {
                    showError(err);
                });
        }).catch((err) => {
            showError(err);
        });

};