import React, { useState, useMemo, useEffect } from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import LoadingBar from 'react-top-loading-bar';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, iconExtension, formatBytes } from '../../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import customToast from '../../../utils/ToastifyHelper';

const ModifierDocumentGestionDoe = (props) => {
    const controller = new AbortController();
    const [progress, setProgress] = useState(0);

    const close = () => {
        controller.abort();
        props.closeModal();
        props.unrenderForm();
    };

    const [files, setFiles] = useState(null);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const formSchema = yup.object().shape({
        documentTitle: yup.string().required("Veuillez saisir le titre du document"),
    });

    const onSubmit = (values, actions) => {
        if (files !== null) {
            // changement de document
            const config = {
                onUploadProgress: progressEvent => {
                    let calculateProgress = progress;
                    calculateProgress = (progressEvent.loaded / progressEvent.total) * 100;
                    setProgress(Math.floor(calculateProgress));
                }
            };
            let postData = new FormData();
            postData.append("document", files);
            postData.append("titre", values.documentTitle);
            axiosInstance.patch(`/uploads/documentsGestionsChantiers/${props.documentData.documentId}`, postData, { ...config, timeout: 0, signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {
                    customToast.success("Le document a été modifié avec succès");
                    if (values.documentTitle !== props.documentData.documentTitre) {
                        axiosInstance.patch(`/gestionDocumentsChantiers/${props.documentData.documentId}`, { libelleDocument: values.documentTitle })
                            .then((result) => {
                                props.modifierDocumentDoe(result.data);
                                actions.resetForm();
                                close();
                            }).catch((err) => {
                                actions.resetForm();
                                showError(err);
                            });
                    }
                    else {
                        props.modifierDocumentDoe(result.data);
                        actions.resetForm();
                        close();
                    }
                }).catch((err) => {
                    actions.resetForm();
                    showError(err);
                });
        }
        else {
            props.progressBarRef.current.continuousStart();
            axiosInstance.patch(`/gestionDocumentsChantiers/${props.documentData.documentId}`, { libelleDocument: values.documentTitle })
                .then((result) => {
                    props.progressBarRef.current.complete();
                    customToast.success("Le document a été modifié avec succès");
                    props.modifierDocumentDoe(result.data);
                    actions.resetForm();
                    close();
                }).catch((err) => {
                    actions.resetForm();
                    showError(err);
                });
        }
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            "documentTitle": props.documentData.documentTitre.replace(/\.[^/.]+$/, ""),
        },
        validationSchema: formSchema,
        onSubmit
    });

    useEffect(() => {
        if (acceptedFiles.length) {
            //open modal
            setFiles(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    return (
        <>
            <LoadingBar color={"#d13852"} progress={progress} onLoaderFinished={() => setProgress(0)} />
            <div className="modal-content ajouter-form">
                <h3 className="modal-title">Modifier le document</h3>
                <button className='modal-button' onClick={() => close()}>x</button>
                <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                    <div className='label-and-input'>
                        {
                            files !== null ?
                                <>
                                    <label htmlFor='documentContent' className='form-label'>Document modifié</label>
                                    <div className="document-upload mgT-s2">
                                        <div className="fileItem mgB-s3">
                                            {iconExtension(files.path)}
                                            <p>{files.path} - {formatBytes(files.size)}</p>
                                            <Icon onClick={() => { setFiles(null); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                        </div>
                                        <div {...getRootProps({ style })}>
                                            <input {...getInputProps()} />
                                            <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                            <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <label htmlFor='documentContent' className='form-label'>Document</label>
                                    <div className="document-upload mgT-s2">
                                        <div className="fileItem mgB-s3">
                                            {iconExtension(props.documentData.documentBase.file.filename)}
                                            <p>{props.documentData.documentTitre} - {formatBytes(props.documentData.documentBase.file.length)}</p>
                                        </div>
                                        <div {...getRootProps({ style })}>
                                            <input {...getInputProps()} />
                                            <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                            <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>

                    <div className='label-and-input last-input'>
                        <label htmlFor='documentTitle' className='form-label'>Titre du document<span className='required-field'>*</span></label>
                        <input name='documentTitle' className={`new-default-input${errors.documentTitle && touched.documentTitle ? " input-error" : ""}`} type="text" placeholder="Titre du document" value={values.documentTitle} onChange={handleChange} onBlur={handleBlur} />
                        {errors.documentTitle && touched.documentTitle && <p className="form-error-message">{errors.documentTitle}</p>}
                    </div>
                    <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Sauvegarder" />
                </form>

            </div>
        </>
    );
};

export default ModifierDocumentGestionDoe;