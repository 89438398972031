import React, { useState } from "react";
import { totalHoraireSalarie } from "../../../utils/GeneralHelper";
import axiosInstance from "../../../utils/axiosInstance";
import customToast from "../../../utils/ToastifyHelper";
import { showError } from "../../../utils/ErrorHelper";
import { format } from "date-fns";

const ValiderSemaineV2 = (props) => {

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onValiderSemaine = () => {
        setButtonDisabled(true);
        axiosInstance.patch(`/releve-heure-v2/validerReleveHeure/me?annee=${props.semaineData.annee}&numeroSemaine=${props.semaineData.numeroSemaine}`).then((result) => {
            setButtonDisabled(false);
            customToast.success("La semaine à été validée avec succès");
            props.validerSemaine();
            close();
        }).catch((err) => {
            setButtonDisabled(false);
            showError(err);
        });
    };

    const getGroupHoraireSemaineParSalarie = () => {
        let horaires = {};
        for (const jour of Object.values(props.jours)) {
            if (jour.hasOwnProperty("travails")) {
                for (const travailsChantier of Object.values(jour.travails)) {
                    for (const travail of travailsChantier) {
                        if (horaires.hasOwnProperty(travail.salarie._id)) {
                            horaires[travail.salarie._id]["horaires"].push({ horaireAprem: travail.horaireAprem, horaireMat: travail.horaireMat });
                        } else {
                            horaires[travail.salarie._id] = { "salarie": travail.salarie, "horaires": [{ horaireAprem: travail.horaireAprem, horaireMat: travail.horaireMat }] };
                        }
                    }
                }
            }
        };

        return horaires;
    };

    const getAllAbsences = () => {
        let absences = [];
        for (const jour of Object.values(props.jours)) {
            if (jour.hasOwnProperty("absences")) {
                absences = absences.concat(jour.absences);
            }
        }
        return absences;
    };

    const horaireSemaineTotal = getGroupHoraireSemaineParSalarie();
    const absences = getAllAbsences();

    return (
        <div className="modal-content supprimerForm" style={{ display: "flex", flexDirection: "column" }}>
            <h1 className='modal-title'>Valider la semaine</h1>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <hr />
            <div className="textAndResume-container">
                <p className="modal-confirmation-text">Comfirmer la validation de la semaine ?</p>
                <ul className="resumeSalarieHeure">
                    {Object.values(horaireSemaineTotal).length && <h3>Total des heures par salarié</h3>}
                    {Object.values(horaireSemaineTotal).length &&
                        Object.keys(horaireSemaineTotal).map((salarieId) => {
                            return <li key={salarieId}>{horaireSemaineTotal[salarieId]["salarie"].nom} {horaireSemaineTotal[salarieId]["salarie"].prenom} {totalHoraireSalarie(horaireSemaineTotal[salarieId]["horaires"])}</li>;
                        })
                    }
                </ul>
                <ul className="resumeSalarieHeure">
                    {absences.length !== 0 && <h3>Absences</h3>}
                    {absences.map((absence) => {
                        return <li key={absence._id}>{absence["utilisateur_absent"].nom} {absence["utilisateur_absent"].prenom} {format(new Date(absence["date_deb_absence"]), "dd/MM/yyyy")}</li>;
                    })
                    }
                </ul>
            </div>
            <div className="button-container" style={{ flex: 1, alignItems: "flex-end" }}>
                <button disabled={isButtonDisabled} className='btn btn-secondary medium-btn fw mgR-m1' onClick={() => close()}>Annuler</button>
                <button disabled={isButtonDisabled} className='btn btn-primary medium-btn fw' onClick={() => onValiderSemaine()}>Valider</button>
            </div>
        </div>
    );
};

export default ValiderSemaineV2;
