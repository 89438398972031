import { addWeeks, eachDayOfInterval, endOfWeek, startOfWeek, format, addDays, getISOWeeksInYear } from "date-fns";


export const getWeekNumber = (date) => {
    if (!(date instanceof Date)) date = new Date(date);
    var nDay = (date.getDay() + 6) % 7;
    date.setDate(date.getDate() - nDay + 3);
    var n1stThursday = date.valueOf();
    date.setMonth(0, 1);
    if (date.getDay() !== 4) {
        date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((n1stThursday - date) / 604800000);
};

export const getAllDaysOfWeek = (weekNumber, year) => {
    // const startOfYearDay = new Date(year, 0, 1);
    // let addWeekToStartYear = addWeeks(startOfYearDay, weekNumber);
    // const startOfWeekDay = startOfWeek(addWeekToStartYear, { weekStartsOn: 1 });
    // const endOfWeekDay = endOfWeek(startOfWeekDay, { weekStartsOn: 1 });
    // let allDays = eachDayOfInterval({
    //     start: startOfWeekDay,
    //     end: endOfWeekDay
    // });


    // Calculate the date for January 4th of the specified ISO week and year
    const january4th = startOfWeek(new Date(year, 0, 4), { weekStartsOn: 1 });

    // Calculate the dates for the entire week (Monday to Sunday)
    const startOfWeekDate = addDays(january4th, (weekNumber - 1) * 7);
    let allDays = Array.from({ length: 7 }, (_, index) =>
        addDays(startOfWeekDate, index)
    );

    allDays = allDays.filter(day => {
        const dateDay = new Date(day);
        return dateDay.getDay() !== 0 && dateDay.getDay() !== 6;
    });

    return allDays;
};

export const dateIndexInJours = (date, jours) => {
    for (var i = 0; i < jours.length; i++) {
        if (jours[i].date === date) {
            return i;
        }
    }
    return -1;
};

export const dateIndexInJoursAdmin = (date, jours) => {
    for (var i = 0; i < jours.length; i++) {
        if (format(new Date(jours[i].date_jour), "yyyy-MM-dd") === date) {
            return i;
        }
    }
    return -1;
};

export const getHoraireTotal = (horaires) => {
    let heure = 0;
    let minute = 0;
    for (const horaire of horaires) {
        heure += horaire.horaire.heure;
        minute += horaire.horaire.minute;
    }
    // for(const absence of absences) {
    //     heure-=absences.length*7
    // }
    return heure.toString() + " H " + minute.toString();
};

export const getMaxWeekInYear = (year) => {
    return getISOWeeksInYear(new Date(year, 0, 1));
};

export const getAnneeNumeroSemaine = (date) => {
    let currentDay = new Date(date);
    var nDay = (currentDay.getDay() + 6) % 7;
    currentDay.setDate(currentDay.getDate() - nDay + 3);

    return currentDay.getFullYear();
};