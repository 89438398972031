import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar-edit';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import customToast from '../../utils/ToastifyHelper';

const EditProfilePictureModal = (props) => {
    const [src, setSrc] = useState(null);
    const [preview, setPreview] = useState(null);

    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(',');
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    const onClose = () => {
        setPreview(null);
    };
    const onCrop = view => {
        setPreview(view);
    };

    const onCloseModal = view => {
        setPreview(null);
        setPreview(view);
        setSrc(null);
        props.closeModal();
        props.unrenderForm();
    };

    useEffect(() => {
    }, [preview]);

    const onSaveProfilePicture = () => {
        props.progressBarRef.current.continuousStart();
        if (preview !== null) {
            let profilePicturePostData = new FormData();
            const file = DataURIToBlob(preview);
            profilePicturePostData.append("document", file);
            axiosInstance.post("/uploads/profilePicture/me", profilePicturePostData, { timeout: 0, headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
                props.progressBarRef.current.complete();
                onCloseModal();
                customToast.success("L'image de profil a été modifiée");
                props.updateProfilePicture(result.data.picture);
            }).catch((err) => {
                props.progressBarRef.current.complete();
                showError(err);
            });
        }
    };


    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Modifier votre photo de profil</h3>
            <button className='modal-button' onClick={() => {
                onCloseModal();
            }}>x</button>
            <div>
                <Avatar
                    width={"100%"}
                    height={300}
                    onCrop={onCrop}
                    onClose={onClose}
                    src={src}
                    exportSize={200}
                    label="Choisir une image"
                />
                <div className='mgT-b mgB-b' style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {preview && <img src={preview} />}
                </div>
            </div>
            <div className='mgB-s3' style={{ height: "100%", width: "100%", display: "flex", alignItems: "end" }}>
                <button onClick={() => onSaveProfilePicture()} className="btn btn-primary medium-btn fw">Enregistrer l'image</button>
            </div>

        </div>
    );
};

export default EditProfilePictureModal;