import React, { useEffect, useMemo, useRef, useState } from 'react';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from '../../../components/ui/Breadcrumb';
import SearchInput from '../../../components/ui/SearchInput';
import { getDocumentsAmianteColumns, getDocumentsAmianteColumnsChef } from '../../../components/tables/TableColumns';
import TableRender from '../../../components/tables/TableRender';
import { useDropzone } from 'react-dropzone';
import { acceptStyle, baseStyle, focusedStyle, getInsensitiveWorld, rejectStyle } from '../../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import Modal from '../../../components/Modal';

import AjouterDocumentAmiante from '../../../components/forms/AmianteForms/AjouterDocumentAmiante';
import SupprimerModalForm from '../../../components/forms/SupprimerModalForm';
import ModifierDocumentAmiante from '../../../components/forms/AmianteForms/ModifierDocumentAmiante';



const DocumentAmianteEncadrantChantier = () => {
    const [documentsAmiante, setDocumentsAmiante] = useState([]);
    const [showedItems, setShowedItems] = useState([]);

    const progressBarRef = useRef(null);

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/uploads/documentsAmiante/me").then((result) => {
            setDocumentsAmiante(result.data);
            progressBarRef.current.complete();
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const [searchBarContent, setSearchBarContent] = useState(null);
    const onChangeSearchDocumentAmiante = (e) => {
        setSearchBarContent(e.target.value);
    };
    const documentsAmianteMatch = (document, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(document._id.titre).includes(insensitiveSearch);
    };

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedItems(documentsAmiante.filter(doc => {
                    return documentsAmianteMatch(doc, searchBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedItems(documentsAmiante);
        }
    }, [documentsAmiante, searchBarContent]);

    const viewFile = (documentId) => {
        axiosInstance.get(`uploads/documentsAmiante/${documentId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div>
                <div>
                    <h1>Document amiante</h1>
                </div>
                <Breadcrumb
                    links={[
                        { label: "amiante", link: "/amiante" },
                        { label: "Documents", link: "/amiante/documents-amiante" },
                    ]}
                />
                <div className="filterContainer mgT-s3 mgB-s3">
                    <SearchInput fullwidth={true} placeholder="Rechercher un document" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchDocumentAmiante} />
                </div>
                <TableRender
                    columns={getDocumentsAmianteColumnsChef((documentId) => viewFile(documentId))}
                    tableData={showedItems}
                    sortBy={[
                        {
                            id: 'libelleDocument',
                            desc: false
                        }
                    ]}
                    pageName="GestionDocumentsAmiante"
                />
            </div>
        </>
    );
};

export default DocumentAmianteEncadrantChantier;