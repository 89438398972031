import React, { useState } from 'react';
import Select from 'react-select';
import MyTimeInput from '../../MyTimeInput';
import EditIcon from '../../icon/EditIcon';
import SupprimerIcon from '../../icon/SupprimerIcon';
import { dateToHoursAndMinutesString } from '../../../utils/GeneralHelper';
import axiosInstance from '../../../utils/axiosInstance';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';
import { Icon } from '@iconify/react';

const ModifierOperationsAmianteSalarie = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [operations, setOperations] = useState(props.entreeSortie.operations.map((operation) => {
        return {
            ...operation,
            dateHeureDebutTime: operation.dateHeureDebut,
            dateHeureDebut: {
                heure: dateToHoursAndMinutesString(operation.dateHeureDebut).substring(0, 2),
                minute: dateToHoursAndMinutesString(operation.dateHeureDebut).substring(3, 5)
            },
            dateHeureSortieTime: operation.dateHeureSortie,
            dateHeureSortie: {
                heure: dateToHoursAndMinutesString(operation.dateHeureSortie).substring(0, 2),
                minute: dateToHoursAndMinutesString(operation.dateHeureSortie).substring(3, 5)
            },
            confirmationModificationVisible: false,
            confirmationSuppressionVisible: false,
        };
    }));

    const [ajouterOperationProcessus, setAjouterOperationProcessus] = useState(null);

    const setConfirmationsModificationsVisible = (operationId) => {
        setOperations(operations.map((operation) => {
            if (operationId === operation._id) {
                return { ...operation, confirmationModificationVisible: true, confirmationSuppressionVisible: false };
            }
            return { ...operation, confirmationModificationVisible: false, confirmationSuppressionVisible: false };
        }));
    };

    const setConfirmationsSuppressionVisible = (operationId) => {
        setOperations(operations.map((operation) => {
            if (operationId === operation._id) {
                return { ...operation, confirmationSuppressionVisible: true, confirmationModificationVisible: false };
            }
            return { ...operation, confirmationSuppressionVisible: false, confirmationModificationVisible: false };
        }));
    };

    const modifierOperationsInput = (operationId, intervalle, horaire) => {
        setOperations(operations.map((operation) => {
            if (operationId === operation._id) {
                if (intervalle === "Debut") {
                    return { ...operation, dateHeureDebut: horaire };
                }
                else {
                    return { ...operation, dateHeureSortie: horaire };
                }
            }
            return operation;
        }));
    };

    const modifierOperationsPhaseChantier = (operationId, typeProcessus) => {
        setOperations(operations.map((operation) => {
            if (operationId === operation._id) {
                return { ...operation, typeProcessus: typeProcessus };
            }
            return operation;
        }));
    };

    const modifierOperation = (operationModifier, modifier) => {
        if (modifier) {
            const patchData = {
                dateHeureDebut: new Date(new Date(operationModifier.dateHeureDebutTime).setHours(parseInt(operationModifier.dateHeureDebut.heure), parseInt(operationModifier.dateHeureDebut.minute))).toISOString(),
                dateHeureSortie: new Date(new Date(operationModifier.dateHeureSortieTime).setHours(parseInt(operationModifier.dateHeureSortie.heure), parseInt(operationModifier.dateHeureSortie.minute))).toISOString(),
                typeProcessus: operationModifier.typeProcessus
            };
            axiosInstance.patch(`entree_sortie_zone/${props.idVacation}/${props.entreeSortie._id}/${operationModifier._id}`, patchData)
                .then((result) => {
                    props.modifierUnEntreeSortieZone(result.data);
                    customToast.success("L'opération a été modifié avec succès");
                }).catch((err) => {
                    showError(err);
                });
        }
        setOperations(operations.map((operation) => {
            if (operationModifier._id === operation._id) {
                return { ...operation, confirmationModificationVisible: false };
            }
            return operation;
        }));
    };

    const supprimerOperation = (operationId, supprimer) => {
        if (supprimer) {
            axiosInstance.delete(`entree_sortie_zone/${props.idVacation}/${props.entreeSortie._id}/${operationId}`)
                .then((result) => {
                    props.modifierUnEntreeSortieZone(result.data);
                    customToast.success("L'opération a été supprimée avec succès");
                    setOperations(operations.filter((operation) => {
                        if (operationId === operation._id) {
                            return false;
                        }
                        return true;
                    }));
                }).catch((err) => {
                    showError(err);
                });
        }
        else {
            setOperations(operations.map((operation) => {
                if (operationId === operation._id) {
                    return { ...operation, confirmationSuppressionVisible: false };
                }
                return operation;
            }));
        }
    };

    const [ajouterProcessusHoraire, setAjouterProcessusHoraire] = useState({ dateHeureDebut: operations[operations.length - 1].dateHeureSortie, dateHeureSortie: { heure: "", minute: "" } });

    const modifierHeureAjouterProcessus = (intervalle, horaire) => {
        if (intervalle === "Debut") {
            setAjouterProcessusHoraire({ ...ajouterProcessusHoraire, dateHeureDebut: horaire });
        }
        else {
            setAjouterProcessusHoraire({ ...ajouterProcessusHoraire, dateHeureSortie: horaire });
        }
    };

    const ajouterOperations = () => {
        const postData = {
            typeProcessus: ajouterOperationProcessus.value,
            dateHeureDebut: new Date(new Date(operations[0].dateHeureDebutTime).setHours(parseInt(ajouterProcessusHoraire.dateHeureDebut.heure), parseInt(ajouterProcessusHoraire.dateHeureDebut.minute))).toISOString(),
            dateHeureSortie: new Date(new Date(operations[0].dateHeureSortieTime).setHours(parseInt(ajouterProcessusHoraire.dateHeureSortie.heure), parseInt(ajouterProcessusHoraire.dateHeureSortie.minute))).toISOString(),
        };
        axiosInstance.patch(`/entree_sortie_zone/${props.idVacation}/${props.entreeSortie._id}/ajouterProcessus`, postData)
            .then((result) => {
                props.modifierUnEntreeSortieZone(result.data);
                customToast.success("L'opération a été modifié avec succès");
                close();
            }).catch((err) => {
                showError(err);
            });
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Intervention n° {props.numeroIntervention} de {props.salarie.prenom} {props.salarie.nom}</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <label className='default-label mgB-s1'>Types de processus</label>
            {
                operations.map((op) => {
                    return (
                        <div key={op._id} style={{ padding: "0.6rem 0", borderTop: "1px solid #ddd" }}>
                            <div className="container-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <Select
                                    options={props.processusChantier}
                                    name={"processus"}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={false}
                                    className={"formSelect-search-container formSelect-search-container-center mediumWidth mgR-s2"}
                                    classNamePrefix={"formSelect-search"}
                                    onChange={(opt) => modifierOperationsPhaseChantier(op._id, opt)}
                                    defaultValue={props.processusChantier.find(processus => {
                                        return processus.value === op.typeProcessus._id;
                                    })}
                                />
                                <div className="container-flex mgR-s2" style={{ flex: 1 }}>
                                    <MyTimeInput operation={op} horaire={op.dateHeureDebut} intervalle={"Debut"} modifierOperationsInput={(horaire) => modifierOperationsInput(op._id, "Debut", horaire)} marginRight={"0.8rem"} />
                                    <MyTimeInput operation={op} horaire={op.dateHeureSortie} modifierOperationsInput={(horaire) => modifierOperationsInput(op._id, "Sortie", horaire)} intervalle={"Sortie"} />
                                </div>
                                <div className='container-flex'>
                                    <button
                                        onClick={() => setConfirmationsModificationsVisible(op._id)}
                                        className="table-modify-button mgR-s2"
                                        style={{ height: "4rem", width: "4rem" }}
                                    >
                                        <EditIcon />
                                    </button>
                                    {operations.length - 1 ?
                                        <button
                                            onClick={() => setConfirmationsSuppressionVisible(op._id)}
                                            className="table-delete-button"
                                            style={{ height: "4rem", width: "4rem", marginRight: 0 }}
                                        >
                                            <SupprimerIcon />
                                        </button>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            {op.confirmationModificationVisible &&
                                <div className="container-flex" style={{ alignItems: "center", justifyContent: "space-between", marginTop: "0.6rem" }}>
                                    <p className='small-text'>Êtes vous sur de vouloir modifier cette operation ?</p>
                                    <div className='container-flex' style={{ width: "88px", alignItems: "center", justifyContent: "space-between" }}>
                                        <p onClick={() => modifierOperation(op, true)} className='small-title green-button' style={{ textAlign: "center", width: "40px", cursor: "pointer", color: "#fff", borderRadius: ".5rem" }}>Oui</p>
                                        <p onClick={() => modifierOperation(op, false)} className='small-title red-button' style={{ textAlign: "center", width: "40px", cursor: "pointer", color: "#fff", borderRadius: ".5rem" }}>Non</p>
                                    </div>
                                </div>
                            }
                            {op.confirmationSuppressionVisible &&
                                <div className="container-flex" style={{ alignItems: "center", justifyContent: "space-between", marginTop: "0.6rem" }}>
                                    <p className='small-text'>Êtes vous sur de vouloir supprimer cette operation ?</p>
                                    <div className='container-flex' style={{ width: "88px", alignItems: "center", justifyContent: "space-between" }}>
                                        <p onClick={() => supprimerOperation(op._id, true)} className='small-title green-button' style={{ textAlign: "center", width: "40px", cursor: "pointer", color: "#fff", borderRadius: ".5rem" }}>Oui</p>
                                        <p onClick={() => supprimerOperation(op._id, false)} className='small-title red-button' style={{ textAlign: "center", width: "40px", cursor: "pointer", color: "#fff", borderRadius: ".5rem" }}>Non</p>
                                    </div>
                                </div>
                            }
                        </div>
                    );
                })
            }
            <div className="container-flex" style={{ justifyContent: "space-between", alignItems: "center", padding: "0.6rem 0", borderTop: "1px solid #ddd" }}>
                <Select
                    options={props.processusChantier}
                    placeholder={"Sélectionner le processus"}
                    name={"processus"}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    className={"formSelect-search-container formSelect-search-container-center mediumWidth mgR-s2"}
                    classNamePrefix={"formSelect-search"}
                    onChange={(opt) => setAjouterOperationProcessus(opt)}
                />
                <div className="container-flex mgR-s2" style={{ flex: 1 }}>
                    <MyTimeInput intervalle={"Debut"} modifierOperationsInput={(horaire) => modifierHeureAjouterProcessus("Debut", horaire)} horaire={ajouterProcessusHoraire.dateHeureDebut} marginRight={"0.8rem"} />
                    <MyTimeInput intervalle={"Sortie"} modifierOperationsInput={(horaire) => modifierHeureAjouterProcessus("Sortie", horaire)} horaire={ajouterProcessusHoraire.dateHeureSortie} />
                </div>
                <button
                    onClick={() => ajouterOperations()}
                    className="table-modify-button mgR-s2"
                    style={{ height: "4rem", width: "4rem", opacity: (!ajouterOperationProcessus || ajouterProcessusHoraire.dateHeureDebut.heure === "" || ajouterProcessusHoraire.dateHeureDebut.minute === "" || ajouterProcessusHoraire.dateHeureSortie.heure === "" || ajouterProcessusHoraire.dateHeureSortie.minute === "") ? "0.4" : "1" }}
                    disabled={!ajouterOperationProcessus || ajouterProcessusHoraire.dateHeureDebut.heure === "" || ajouterProcessusHoraire.dateHeureDebut.minute === "" || ajouterProcessusHoraire.dateHeureSortie.heure === "" || ajouterProcessusHoraire.dateHeureSortie.minute === ""}
                >
                    <Icon icon="entypo:plus" />
                </button>
            </div>
        </div>
    );
};


export default ModifierOperationsAmianteSalarie;