import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { socket } from '../../utils/Socket';
import LoadingBar from 'react-top-loading-bar';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import SearchInput from '../../components/ui/SearchInput';
import Select from "react-select";
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import { format } from 'date-fns';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import useResize from '../../utils/useResize';
import { advancedSearch, getNumeroTransportDemande, getNumeroTransportType, typeVehicule } from '../../utils/GeneralHelper';
import LinesEllipsis from 'react-lines-ellipsis';
import customToast from '../../utils/ToastifyHelper';
import Modal from '../../components/Modal';

import ModifierDemandeTransport from '../../components/forms/Transport/ModifierDemandeTransport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import ScrollBar from 'react-overlayscrollbars';
import 'react-overlayscrollbars/dist/es/index.css';
import * as actionCreators from "../../store/actions/exportAction";
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from "@ramonak/react-progress-bar";
import ResumerTransportModal from '../../components/forms/Transport/ResumerTransportModal';
import { faArrowDownWideShort, faCaretUp, faPlus, faSortDown, faSortUp, faTruck } from '@fortawesome/free-solid-svg-icons';
import { faArrowsRotate, faBarsSort } from '@fortawesome/pro-solid-svg-icons';
import AjouterDemandeModal from '../../components/forms/Transport/AjouterDemandeModal';
import mongoose from "mongoose";
import ClipLoader from "react-spinners/ClipLoader";
import ModifierTransportAssigner from '../../components/forms/Transport/ModifierTransportAssigner';
import AvancementTransportSimple from '../../components/forms/Transport/AvancementTransportSimple';
import AvancementTransportCamion from '../../components/forms/Transport/AvancementTransportCamion';
import AdvanceSearch from '../../components/ui/AdvanceSearch';

const savePageOptions = (pageName, pageOptions) => (actionCreators.savePageOptions(pageName, pageOptions));

const DemandeTransport = () => {
    const pageOptions = useSelector(
        state => state.pageState
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const progressBarRef = useRef(null);

    //--------------- Gestion Demande ------------------
    const [allDemandesTransport, setAllDemandesTransport] = useState([]);
    const [demandesTransportShowed, setDemandesTransportShowed] = useState([]);
    const getPrioriteScore = (priorite) => {
        if (priorite === "Priorité normale") {
            return 0;
        }
        else if (priorite === "Urgent") {
            return 1;
        }
        else {
            return 0;
        }
    };

    useEffect(() => {
        window.addEventListener('error', (e) => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.' || e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    const getDemandesTransport = (date) => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/demandeTransport?dateLivraison=${format(date, "yyyy-MM-dd")}&etat=0`).then((result) => {
            progressBarRef.current.complete();
            setAllDemandesTransport(result.data);
            let allCreateurs = new Set();
            for (const demande of result.data) {
                allCreateurs.add({ value: demande.creator._id, label: `${demande.creator.nom} ${demande.creator.prenom}` });
            }
            setCurrentCreateurList([...allCreateurs].sort((a, b) => {
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                return 0;
            }));
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    //--------------- Date demande ------------------
    const [dateDemande, setDateDemande] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("dateDemande") ? new Date(pageOptions["DemandeTransport"]["dateDemande"]) : new Date());
    const onChangeDateDemande = (date) => {
        setDateDemande(date);
        getDemandesTransport(date);
        getTransportChauffeur(date);

    };

    //--------------- Gestion Transport ASSIGNE ------------------
    const [allTransportChauffeur, setAllTransportChauffeur] = useState({});
    const getTransportChauffeur = (date) => {
        axiosInstance.get(`/transports?dateDemande=${format(date, "yyyy-MM-dd")}`).then((result) => {
            progressBarRef.current.complete();
            setAllTransportChauffeur(result.data);
        }).catch((err) => {
            showError(err);
        });
    };

    useEffect(() => {
        getDemandesTransport(dateDemande);
        getTransportChauffeur(dateDemande);
    }, []);

    const getChauffeurData = (chauffeurId) => {
        for (const chauffeur of chauffeurs) {
            if (chauffeur._id._id === chauffeurId) {
                return chauffeur;
            }
        }
    };


    const getTracteurPorteur = (chauffeurId) => {
        for (const chauffeur of chauffeurs) {
            if (chauffeur._id._id === chauffeurId && chauffeur.vehiculeParDefaut.tracteurPorteur !== null) {
                return chauffeur.vehiculeParDefaut.tracteurPorteur;
            }
        }
        return null;
    };

    const getRemorque = (chauffeurId) => {
        for (const chauffeur of chauffeurs) {
            if (chauffeur._id._id === chauffeurId && chauffeur.vehiculeParDefaut.remorque !== null) {
                return chauffeur.vehiculeParDefaut.remorque;
            }
        }
        return null;
    };

    const getDemandeChauffeur = (chauffeurId, transportChauffeur) => {
        if (transportChauffeur.hasOwnProperty(chauffeurId)) {
            return transportChauffeur[chauffeurId].sort((a, b) => a.index - b.index);
        }
        else {
            return [];
        }
    };

    // useEffect(() => {
    //     console.log(allTransportChauffeur)
    // }, [allTransportChauffeur])




    const handleDragDrop = (results) => {
        const { source, destination } = results;
        if (!destination) return;

        if (source.droppableId === destination.droppableId && source.index === destination.index) return;
        const sourceIndex = source.index;
        const destinationIndex = destination.index;
        const reorderedDemandesStores = [...allDemandesTransport];

        if (source.droppableId === destination.droppableId) {
            // Demande a demande
            if (source.droppableId === "demandeTransport") {
                const [removedStore] = reorderedDemandesStores.splice(sourceIndex, 1);
                reorderedDemandesStores.splice(destinationIndex, 0, removedStore);
                return setAllDemandesTransport(reorderedDemandesStores);
            }
            else {
                //--------------- CHAUFFEUR -> CHAUFFEUR ------------------
                if (sourceIndex !== destinationIndex) {
                    const chauffeurId = destination.droppableId.split("-")[1];
                    // copie de l'etat de la liste des transport pour ce chauffeur
                    let reorderedChauffeurStores = [];
                    if (allTransportChauffeur.hasOwnProperty(chauffeurId)) {
                        reorderedChauffeurStores = [...allTransportChauffeur[chauffeurId]];
                    }
                    let transportMoved;
                    reorderedChauffeurStores = reorderedChauffeurStores.map((transport) => {
                        if (transport.index === sourceIndex) {
                            transportMoved = transport;
                            return { ...transport, index: destinationIndex };
                        }
                        else if (sourceIndex < destinationIndex && transport.index >= sourceIndex && transport.index <= destinationIndex) {
                            return { ...transport, index: transport.index - 1 };
                        }
                        else if (sourceIndex > destinationIndex && transport.index <= sourceIndex && transport.index >= destinationIndex) {
                            return { ...transport, index: transport.index + 1 };
                        }
                        return transport;
                    });
                    setAllTransportChauffeur({ ...allTransportChauffeur, [chauffeurId]: reorderedChauffeurStores });
                    axiosInstance.patch(`/transports/changementIndex/${transportMoved._id}`, { destinationIndex: destinationIndex }).then((result) => {
                        socket.emit("dispatcher:changer_index_transport", { transportId: transportMoved._id, room: chauffeurId, destinationIndex: destinationIndex, sourceIndex: sourceIndex, dateLivraison: format(new Date(transportMoved.demande.dateLivraison), "yyyy-MM-dd") });
                    }).catch((err) => {
                        showError(err);
                    });
                }
            }
        }
        else {
            if (source.droppableId === "demandeTransport") {
                //--------------- DEMANDE -> CHAUFFEUR ------------------
                const chauffeurId = destination.droppableId.split("-")[1];
                // Retirer la demande de la liste des demandes
                const [removedStoreDemande] = reorderedDemandesStores.splice(sourceIndex, 1);
                setAllDemandesTransport(reorderedDemandesStores);
                // copie de l'etat de la liste des transport pour ce chauffeur
                let reorderedChauffeurStores = [];
                if (allTransportChauffeur.hasOwnProperty(chauffeurId)) {
                    reorderedChauffeurStores = [...allTransportChauffeur[chauffeurId]];
                }
                // reorganisation des indexes
                reorderedChauffeurStores = reorderedChauffeurStores.map((transport) => {
                    if (transport.index >= destinationIndex) {
                        return { ...transport, index: transport.index + 1 };
                    }
                    return transport;
                });
                // inserer le transport dans la copie de la liste des chauffeurs
                let newTransport = {
                    _id: new mongoose.Types.ObjectId().toString(),
                    demande: removedStoreDemande,
                    chauffeur: getChauffeurData(chauffeurId),
                    tracteurPorteur: getTracteurPorteur(chauffeurId),
                    remorque: getRemorque(chauffeurId),
                    informationsTransport: "",
                    etat: 0,
                    dateValidation: null,
                    index: destinationIndex,
                    messageValidation: "",
                    inCreation: true
                };
                reorderedChauffeurStores.splice(destinationIndex, 0, newTransport);
                // mettre a jour la liste des transports du chauffeur
                setAllTransportChauffeur({ ...allTransportChauffeur, [chauffeurId]: reorderedChauffeurStores });

                // envoyer au serveur
                let data = {
                    demande: removedStoreDemande._id,
                    chauffeur: chauffeurId,
                    tracteurPorteur: getTracteurPorteur(chauffeurId)._id,
                    remorque: getRemorque(chauffeurId)._id,
                    index: destinationIndex
                };
                axiosInstance.post('/transports', data).then((result) => {
                    const transport = result.data;
                    reorderedChauffeurStores[destinationIndex] = transport;
                    socket.emit("dispatcher:assigner_transport", { transport: transport, room: chauffeurId });
                    return setAllTransportChauffeur({ ...allTransportChauffeur, [chauffeurId]: reorderedChauffeurStores });
                }).catch((err) => {
                    showError(err);
                });
            }
            else if (destination.droppableId === "demandeTransport") {
                //--------------- CHAUFFEUR -> DEMANDE ------------------
                // verifier si la demande n'a pas été commencer
                const chauffeurId = source.droppableId.split("-")[1];
                const etatTransport = allTransportChauffeur[chauffeurId][sourceIndex].etat;
                if (etatTransport > 0) {
                    if (etatTransport === 1) {
                        customToast.error("Vous ne pouvez pas annuler ce transport car il est en cours de traitement");
                    }
                    else {
                        customToast.error("Vous ne pouvez pas annuler ce transport car il est terminé");
                    }
                }
                else {
                    // retirer le transport de la liste des transports du chauffeur
                    const [removedTransport] = allTransportChauffeur[chauffeurId].splice(sourceIndex, 1);
                    // reorganiser les indexes de la liste des transports du chauffeur
                    setAllTransportChauffeur({
                        ...allTransportChauffeur,
                        [chauffeurId]: allTransportChauffeur[chauffeurId].filter(transport => {
                            return transport.index !== sourceIndex;
                        }).map((transport) => {
                            if (transport.index > 0 && transport.index > sourceIndex) {
                                return { ...transport, index: transport.index - 1 };
                            }
                            return transport;
                        })
                    });
                    // ajouter la demande dans la liste des demandes
                    reorderedDemandesStores.splice(destinationIndex, 0, removedTransport.demande);
                    setAllDemandesTransport(reorderedDemandesStores);
                    axiosInstance.patch(`/transports/desassignerTransport/${removedTransport._id}`).then(() => {
                        socket.emit("dispatcher:enlever_transport", { transportId: removedTransport._id, room: chauffeurId });
                    }).catch((err) => {
                        showError(err);
                    });
                }
            }
            else {
                //--------------- CHAUFFEUR -> AUTRE CHAUFFEUR ------------------
                // verifier si la demande n'a pas été commencer
                let oldChauffeurId = source.droppableId.split("-")[1];
                let newChauffeurId = destination.droppableId.split("-")[1];

                const etatTransport = allTransportChauffeur[oldChauffeurId][sourceIndex].etat;
                if (etatTransport > 0) {
                    if (etatTransport === 1) {
                        customToast.error("Vous ne pouvez pas changer de chauffeur pour ce transport car il est en cours de traitement");
                    }
                    else {
                        customToast.error("Vous ne pouvez pas changer de chauffeur pour ce transport car il est terminé");
                    }
                }
                else {
                    const [removedTransport] = allTransportChauffeur[oldChauffeurId].splice(sourceIndex, 1);
                    setAllTransportChauffeur({
                        ...allTransportChauffeur,
                        [oldChauffeurId]: allTransportChauffeur[oldChauffeurId].filter(transport => {
                            return transport.index !== sourceIndex;
                        }).map((transport) => {
                            if (transport.index > 0 && transport.index > sourceIndex) {
                                return { ...transport, index: transport.index - 1 };
                            }
                            return transport;
                        }),
                        [newChauffeurId]: allTransportChauffeur.hasOwnProperty(newChauffeurId) ? [...allTransportChauffeur[newChauffeurId].map((transport) => {
                            if (transport.index >= destinationIndex) {
                                return { ...transport, index: transport.index + 1 };
                            }
                            return transport;
                        }), { ...removedTransport, chauffeur: getChauffeurData(newChauffeurId), index: destinationIndex }] : [{ ...removedTransport, chauffeur: getChauffeurData(newChauffeurId), index: destinationIndex }]
                    });
                    axiosInstance.patch(`/transports/modifierChauffeur/${removedTransport._id}`, { newChauffeur: newChauffeurId, destinationIndex: destinationIndex }).then(() => {
                        socket.emit("dispatcher:enlever_transport", { transportId: removedTransport._id, room: oldChauffeurId });
                        socket.emit("dispatcher:assigner_transport", { transport: { ...removedTransport, chauffeur: getChauffeurData(newChauffeurId), index: destinationIndex }, room: newChauffeurId });
                    }).catch((err) => {
                        showError(err);
                    });
                }
            }
        }
        return;
    };

    const { jwtToken, refreshToken } = useSelector(
        state => {
            return ({ jwtToken: state.user.jwtToken, refreshToken: state.user.refreshToken });
        }
    );

    useEffect(() => {
        socket.emit("dispatcher:join_room", { dispatcherRoom: parseInt(process.env.REACT_APP_DISPATCHER_ROOM), jwtToken: jwtToken, refreshToken: refreshToken });

        // Message d'erreur socket
        socket.on("error_message", (data) => {
            console.log(data);
            customToast.error(data);
        });

        // Update the token
        socket.on("tokenRefresh", (data) => {
            dispatch(actionCreators.storeSessionToken(data));
            console.log("token updated" + data);
        });

        //--------------- Demande Transport ------------------

        socket.on("demande_transport_created", (data) => {
            if (format(dateDemande, "dd/MM/yyyy") === format(new Date(data.dateLivraison), "dd/MM/yyyy")) {
                customToast.success("Nouvelle demande ajouté");
                setAllDemandesTransport(allDemandesTransport.concat(data).sort((a, b) => getPrioriteScore(b.priorite) - getPrioriteScore(a.priorite)));
            }
        });

        socket.on("demande_transport_edited", (data) => {
            if (format(dateDemande, "dd/MM/yyyy") === format(new Date(data.dateLivraison), "dd/MM/yyyy")) {
                customToast.success("Une demande a été modifié");
                setAllDemandesTransport(allDemandesTransport.map((item) => {
                    if (item._id === data._id) {
                        return data;
                    }
                    return item;
                }));
            }
        });

        socket.on("demande_transport_deleted", (data) => {
            customToast.success("Une demande a été supprimé");
            setAllDemandesTransport(allDemandesTransport.filter((item) => {
                return (item._id !== data);
            }));
        });

        //--------------- COMMENCER ET VALIDER TRANSPORT ------------------

        socket.on("commencer_transport", (data) => {
            if (format(dateDemande, "dd/MM/yyyy") === format(new Date(data.dateLivraison), "dd/MM/yyyy")) {
                setAllTransportChauffeur({
                    ...allTransportChauffeur, [data.chauffeurId]: allTransportChauffeur[data.chauffeurId].map((transport) => {
                        if (transport._id === data.transportId) {
                            return { ...transport, etat: 1, dateDebutTransport: data.dateDebutTransport };
                        }
                        return transport;
                    })
                });
            }
        });

        socket.on("valider_transport", (data) => {
            if (format(dateDemande, "dd/MM/yyyy") === format(new Date(data.transport.demande.dateLivraison), "dd/MM/yyyy")) {
                setAllTransportChauffeur({
                    ...allTransportChauffeur, [data.transport.chauffeur._id._id]: allTransportChauffeur[data.transport.chauffeur._id._id].map((transport) => {
                        if (transport._id === data.transport._id) {
                            return data.transport;
                        }
                        return transport;
                    })
                });
            }
        });

        socket.on("valider_tour_transport", (data) => {
            if (format(dateDemande, "dd/MM/yyyy") === format(new Date(data.dateLivraison), "dd/MM/yyyy")) {
                setAllTransportChauffeur({
                    ...allTransportChauffeur, [data.chauffeurId]: allTransportChauffeur[data.chauffeurId].map((transport) => {
                        if (transport._id === data.transportId) {
                            return { ...transport, validationTours: transport.validationTours.concat({ dateValidation: new Date(data.dateValidation), quantite: data.quantite }) };
                        }
                        return transport;
                    })
                });
            }
        });


        return () => {
            socket.off('error_message');
            socket.off('tokenRefresh');
            socket.off('demande_transport_created');
            socket.off('demande_transport_edited');
            socket.off('demande_transport_deleted');
            socket.off('commencer_transport');
            socket.off('valider_transport');
            socket.off('valider_tour_transport');
        };

    }, [socket, allDemandesTransport, dateDemande, allTransportChauffeur]);

    //--------------- Barre de recherche ------------------
    const [searchBarContent, setSearchBarContent] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("searchBarContent") ? pageOptions["DemandeTransport"]["searchBarContent"] : "");
    const onChangeSearchDemandes = (e) => {
        setSearchBarContent(e.target.value);
    };

    const [searchValue, setSearchValue] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("searchBarContent") ? pageOptions["DemandeTransport"]["searchBarContent"] : "");

    const search = () => {
        setSearchValue(searchBarContent);
    };

    //--------------- Type demande filtre ------------------
    const typeDemandes = [
        { label: "Pose Ampirolle", value: "Pose Ampirolle" },
        { label: "Évacuation Ampirolle", value: "Évacuation Ampirolle" },
        { label: "Livraison Camion", value: "Livraison Camion" },
        { label: "Évacuation Camion", value: "Évacuation Camion" },
        { label: "Transfert Porte-engin", value: "Transfert Porte-engin" }
    ];
    const [typeDemandeFiltre, setTypeDemandeFiltre] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("typeDemandeFiltre") ? pageOptions["DemandeTransport"]["typeDemandeFiltre"] : null);

    //--------------- Chauffeur filtre ------------------
    const [chauffeurFiltreSelected, setChauffeurFiltreSelected] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("chauffeurFiltreSelected") ? pageOptions["DemandeTransport"]["chauffeurFiltreSelected"] : []);
    const [chauffeurs, setChauffeurs] = useState([]);
    const [chauffeursShowed, setChauffeursShowed] = useState([]);
    const [chauffeursOptions, setchauffeursOptions] = useState([]);

    const selectChauffeur = (e) => {
        setChauffeurFiltreSelected(e);
    };

    //--------------- Type vehicule filtre ------------------
    const [typeVehiculeFiltreSelected, setTypeVehiculeFiltreSelected] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("typeVehiculeFiltreSelected") ? pageOptions["DemandeTransport"]["typeVehiculeFiltreSelected"] : []);
    const typeVehiculeOptions = typeVehicule;

    const selectTypeVehicule = (e) => {
        setTypeVehiculeFiltreSelected(e);
    };

    //--------------- Materiaux filtre ------------------j
    const [materiauxFiltreSelected, setMateriauxFiltreSelected] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("materiauxFiltreSelected") ? pageOptions["DemandeTransport"]["materiauxFiltreSelected"] : null);
    const [materiauxOptions, setMateriauxOptions] = useState([]);

    const selectMateriaux = (e) => {
        setMateriauxFiltreSelected(e);
    };

    useEffect(() => {
        axiosInstance.get("/chauffeurs").then((result) => {
            setChauffeurs(result.data);
            setchauffeursOptions(result.data.map((chauffeur) => {
                return {
                    label: `${chauffeur._id.nom} ${chauffeur._id.prenom}`, value: chauffeur._id._id
                };
            }));
        }).catch((err) => {
            showError(err);
        });

        axiosInstance.get("/materiaux").then((result) => {
            setMateriauxOptions(result.data.map((materiau) => {
                return {
                    label: materiau.libelle_mat, value: materiau._id
                };
            }));
        }).catch((err) => {
            showError(err);
        });
    }, []);

    //--------------- Filtre createur de la demande ------------------
    const [createurDemandeSelected, setCreateurDemandeSelected] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("createurDemandeSelected") ? pageOptions["DemandeTransport"]["createurDemandeSelected"] : []);
    const [currentCreateurList, setCurrentCreateurList] = useState([]);
    const selectCreateurDemande = (e) => {
        setCreateurDemandeSelected(e);
    };

    const createurIdInFiltreSelected = (createurId) => {
        for (const idCreateurFiltre of createurDemandeSelected) {
            if (idCreateurFiltre.value === createurId) {
                return true;
            }
        }
        return false;
    };

    //--------------- MODAL ------------------
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);
    const [modalDemandeSelected, setModalDemandeSelected] = useState({});
    const [modalTransportSelected, setModalTransportSelected] = useState({});

    //--------------- Edit demande ------------------
    const openEditDemande = (demandeData) => {
        setModalDemandeSelected(demandeData);
        setModalClasses("modal modal__active");
        setModalContent("modifierDemande");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    //--------------- EDIT TRANSPORT ------------------
    const openEditTransport = (transportData) => {
        setModalTransportSelected(transportData);
        setModalClasses("modal modal__active");
        setModalContent("modifierTransport");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    //--------------- RESUMER MODAL ------------------
    const openResumerDemande = (transportData) => {
        setModalTransportSelected(transportData);
        setModalClasses("modal modal__active");
        setModalContent("resumerTransport");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    //--------------- AVANCEMENT MODAL ------------------
    const openAvancementModal = (transportData) => {
        setModalTransportSelected(transportData);
        setModalClasses("modal modal__active");
        if (transportData.type === "TransportCamion") {
            setModalContent("avancementTransportCamion");
        }
        else {
            setModalContent("avancementTransportSimple");
        }
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    //--------------- AJOUTER DEMANDE ------------------
    const openAjouterDemande = () => {
        setModalClasses("modal modal__active");
        setModalContent("ajouterDemande");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    //--------------- Supprimer demande ------------------
    const onSupprimerDemande = (demandeSupprimer) => {
        progressBarRef.current.continuousStart();
        axiosInstance.delete(`/demandeTransport/${demandeSupprimer._id}`).then((result) => {
            progressBarRef.current.complete();
            socket.emit("demande_transport:delete", { demandeTransportId: demandeSupprimer._id, room: demandeSupprimer.creator._id });
            customToast.success("Demande supprimée avec succès");
            setAllDemandesTransport(allDemandesTransport.filter((item) => {
                return (item._id !== demandeSupprimer._id);
            }));
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    // Supprimer transport assigner
    const onSupprimerTransportAssigner = (transportSupprimer) => {
        progressBarRef.current.continuousStart();
        axiosInstance.delete(`/transports/supprimerTransportAssigner/${transportSupprimer._id}`).then((result) => {
            progressBarRef.current.complete();
            socket.emit("demande_transport:delete", { demandeTransportId: transportSupprimer.demande._id, room: transportSupprimer.demande.creator._id });
            socket.emit("dispatcher:enlever_transport", { transportId: transportSupprimer._id, room: transportSupprimer.chauffeur._id._id });

            customToast.success("Transport supprimé avec succès");
            setAllTransportChauffeur({
                ...allTransportChauffeur,
                [transportSupprimer.chauffeur._id._id]: allTransportChauffeur[transportSupprimer.chauffeur._id._id].filter((item) => {
                    return (item._id !== transportSupprimer._id);
                }).map((transport) => {
                    if (transport.index > transportSupprimer.index) {
                        return { ...transport, index: transport.index - 1 };
                    }
                    return transport;
                })
            });
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });


    };

    //--------------- Dupliquer demande ------------------
    const onDupliquer = (demandeDupliquer, dateDuplication) => {
        progressBarRef.current.continuousStart();
        axiosInstance.post(`/demandeTransport/dupliquerDemande/${demandeDupliquer._id}`, { dateDuplication: format(dateDuplication, "yyyy-MM-dd") }).then((result) => {
            progressBarRef.current.complete();
            customToast.success("Demande dupliquée avec succès");
            socket.emit("demande_transport:create", { demandeTransport: result.data, room: demandeDupliquer.creator._id });
            if (format(dateDemande, "dd/MM/yyyy") === format(new Date(result.data.dateLivraison), "dd/MM/yyyy")) {
                setAllDemandesTransport(allDemandesTransport.concat(result.data).sort((a, b) => getPrioriteScore(b.priorite) - getPrioriteScore(a.priorite)));
            }
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    //--------------- Dupliquer Transport ------------------
    const onDupliquerTransport = (transportDupliquer, dateDuplication) => {
        progressBarRef.current.continuousStart();
        axiosInstance.post(`/transports/dupliquerTransport/${transportDupliquer._id}`, { dateDuplication: format(dateDuplication, "yyyy-MM-dd") }).then((result) => {
            progressBarRef.current.complete();
            customToast.success("Transport dupliquée avec succès");
            socket.emit("demande_transport:create", { demandeTransport: result.data.demande, room: result.data.demande.creator._id });
            socket.emit("dispatcher:assigner_transport", { transport: result.data, room: transportDupliquer.chauffeur._id._id });

            if (format(dateDemande, "dd/MM/yyyy") === format(new Date(result.data.demande.dateLivraison), "dd/MM/yyyy")) {
                setAllTransportChauffeur({
                    ...allTransportChauffeur,
                    [transportDupliquer.chauffeur._id._id]: allTransportChauffeur[transportDupliquer.chauffeur._id._id].concat(result.data).sort((a, b) => a.index - b.index)
                });
            }
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    const chauffeurIdInFiltreSelected = (chauffeurId) => {
        for (const idChauffeurFiltre of chauffeurFiltreSelected) {
            if (idChauffeurFiltre.value === chauffeurId) {
                return true;
            }
        }
        return false;
    };

    const typeVehiculeInFiltreSelected = (tracteurPorteur) => {
        if (!tracteurPorteur) {
            return false;
        }
        for (const typeVehiculeFiltre of typeVehiculeFiltreSelected) {
            if (typeVehiculeFiltre.value === tracteurPorteur.typeVehicule) {
                return true;
            }
            if (typeVehiculeFiltre.value === "Semi" && (tracteurPorteur.typeVehicule === "2 essieux" || tracteurPorteur.typeVehicule === "3 essieux")) {
                return true;
            }
        }
        return false;
    };

    //--------------- SORT CHAUFFEUR FILTER BAR ------------------
    const [sortChauffeurState, setSortChauffeurState] = useState(pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("sortChauffeurState") ? pageOptions["DemandeTransport"]["sortChauffeurState"] : { filterName: "nom", filterOrder: "asc" });
    const allSortChauffeur = [
        { filterLabel: "Nom", filterValue: "nom" },
        { filterLabel: "Prénom", filterValue: "prenom" },
        { filterLabel: "Nombre de transport", filterValue: "nombreTransport" },
        { filterLabel: "Nombre d'urgence", filterValue: "nombreUrgence" },
    ];

    const sortChauffeurListByProperty = (chauffeurPropertyFilter) => {
        if (sortChauffeurState.filterName === chauffeurPropertyFilter.filterValue) {
            if (sortChauffeurState.filterOrder === "asc") {
                setSortChauffeurState({ ...sortChauffeurState, filterOrder: "desc" });
            }
            else {
                setSortChauffeurState({ filterName: null, filterOrder: "asc" });
            }
        }
        else {
            setSortChauffeurState({ filterName: chauffeurPropertyFilter.filterValue, filterOrder: "asc" });
        }
    };

    const countNumberOfUrgentTransport = (transportList) => {
        let total = 0;
        for (const transport of transportList) {
            if (transport.demande.priorite === "Urgent") {
                total += 1;
            }
        }
        return total;
    };

    useEffect(() => {
        // filter
        let filterAllDemandesTransport = [...allDemandesTransport];
        let filterAllChauffeurs = [...chauffeurs];
        if (searchValue !== "") {
            // filtre demande
            filterAllDemandesTransport = filterAllDemandesTransport.filter((demande) => {
                return (
                    advancedSearch(searchBarContent, `${demande.creator.nom} ${demande.creator.prenom}`) ||
                    advancedSearch(searchBarContent, demande.typeVehicule) ||
                    advancedSearch(searchBarContent, `${getNumeroTransportType(demande.type)}${getNumeroTransportDemande(demande.typeDemande)}-${demande.numeroTransport}`) ||
                    advancedSearch(searchBarContent, demande.lieuDepart._id.libelle_lieu) ||
                    advancedSearch(searchBarContent, demande.lieuArriver._id.libelle_lieu)
                );
            });
            // filtre chauffeur
            // setChauffeursShowed();
        }
        if (typeDemandeFiltre !== null) {
            filterAllDemandesTransport = filterAllDemandesTransport.filter(demandeTransport => {
                if (typeDemandeFiltre.value === "Pose Ampirolle") {
                    return (
                        demandeTransport.type === "DemandeTransportAmpiroll" && demandeTransport.typeDemande === "Pose"
                    );
                }
                else if (typeDemandeFiltre.value === "Évacuation Ampirolle") {
                    return (
                        demandeTransport.type === "DemandeTransportAmpiroll" && demandeTransport.typeDemande === "Évacuation"
                    );
                }
                else if (typeDemandeFiltre.value === "Livraison Camion") {
                    return (
                        demandeTransport.type === "DemandeTransportCamion" && demandeTransport.typeDemande === "Livraison"
                    );
                }
                else if (typeDemandeFiltre.value === "Évacuation Camion") {
                    return (
                        demandeTransport.type === "DemandeTransportCamion" && demandeTransport.typeDemande === "Évacuation"
                    );
                }
                else {
                    return (
                        demandeTransport.type === "DemandeTransportPorteEngin"
                    );
                }
            });
        }
        if (chauffeurFiltreSelected.length) {
            filterAllChauffeurs = filterAllChauffeurs.filter(chauffeur => {
                return (
                    chauffeurIdInFiltreSelected(chauffeur._id._id)
                );
            });
        }
        if (typeVehiculeFiltreSelected.length) {
            filterAllChauffeurs = filterAllChauffeurs.filter(chauffeur => {
                return (
                    typeVehiculeInFiltreSelected(chauffeur.vehiculeParDefaut.tracteurPorteur)
                );
            });
        }
        if (materiauxFiltreSelected !== null) {
            const materiauxSearch = materiauxFiltreSelected.label.toLowerCase();
            filterAllDemandesTransport = filterAllDemandesTransport.filter(demandeTransport => {
                return demandeTransport.hasOwnProperty("materiau") && demandeTransport.materiau.libelle_mat.toLowerCase().includes(materiauxSearch);
            });
        }
        if (createurDemandeSelected.length) {
            filterAllDemandesTransport = filterAllDemandesTransport.filter(demandeTransport => {
                return (
                    createurIdInFiltreSelected(demandeTransport.creator._id)
                );
            });
        }
        if (sortChauffeurState.filterName !== null) {
            if (sortChauffeurState.filterName === "nom") {
                if (sortChauffeurState.filterOrder === "asc") {
                    filterAllChauffeurs.sort((a, b) => (a._id.nom > b._id.nom) ? 1 : ((b._id.nom > a._id.nom) ? -1 : 0));
                }
                else {
                    filterAllChauffeurs.sort((a, b) => (a._id.nom > b._id.nom) ? -1 : ((b._id.nom > a._id.nom) ? 1 : 0));
                }
            }
            else if (sortChauffeurState.filterName === "prenom") {
                if (sortChauffeurState.filterOrder === "asc") {
                    filterAllChauffeurs.sort((a, b) => (a._id.prenom > b._id.prenom) ? 1 : ((b._id.prenom > a._id.prenom) ? -1 : 0));
                }
                else {
                    filterAllChauffeurs.sort((a, b) => (a._id.prenom > b._id.prenom) ? -1 : ((b._id.prenom > a._id.prenom) ? 1 : 0));
                }
            }
            else if (sortChauffeurState.filterName === "nombreTransport") {
                if (sortChauffeurState.filterOrder === "asc") {
                    filterAllChauffeurs.sort((a, b) => {
                        if (allTransportChauffeur.hasOwnProperty(a._id._id) && allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return (allTransportChauffeur[b._id._id].length - allTransportChauffeur[a._id._id].length);
                        }
                        else if (allTransportChauffeur.hasOwnProperty(a._id._id) && !allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return -1;
                        }
                        else if (!allTransportChauffeur.hasOwnProperty(a._id._id) && allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return 1;
                        }
                        return 0;
                    });
                }
                else {
                    filterAllChauffeurs.sort((a, b) => {
                        if (allTransportChauffeur.hasOwnProperty(a._id._id) && allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return (allTransportChauffeur[a._id._id].length - allTransportChauffeur[b._id._id].length);
                        }
                        else if (allTransportChauffeur.hasOwnProperty(a._id._id) && !allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return 1;
                        }
                        else if (!allTransportChauffeur.hasOwnProperty(a._id._id) && allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return -1;
                        }
                        return 0;
                    });
                }
            }
            else if (sortChauffeurState.filterName === "nombreUrgence") {

                if (sortChauffeurState.filterOrder === "asc") {
                    filterAllChauffeurs.sort((a, b) => {
                        if (allTransportChauffeur.hasOwnProperty(a._id._id) && allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return (countNumberOfUrgentTransport(allTransportChauffeur[b._id._id]) - countNumberOfUrgentTransport(allTransportChauffeur[a._id._id]));
                        }
                        else if (allTransportChauffeur.hasOwnProperty(a._id._id) && !allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return -1;
                        }
                        else if (!allTransportChauffeur.hasOwnProperty(a._id._id) && allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return 1;
                        }
                        return 0;
                    });
                }
                else {
                    filterAllChauffeurs.sort((a, b) => {
                        if (allTransportChauffeur.hasOwnProperty(a._id._id) && allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return (countNumberOfUrgentTransport(allTransportChauffeur[a._id._id]) - countNumberOfUrgentTransport(allTransportChauffeur[b._id._id]));
                        }
                        else if (allTransportChauffeur.hasOwnProperty(a._id._id) && !allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return 1;
                        }
                        else if (!allTransportChauffeur.hasOwnProperty(a._id._id) && allTransportChauffeur.hasOwnProperty(b._id._id)) {
                            return -1;
                        }
                        return 0;
                    });
                }
            }
        }
        setDemandesTransportShowed(filterAllDemandesTransport);
        setChauffeursShowed(filterAllChauffeurs);
    }, [allDemandesTransport, typeDemandeFiltre, chauffeurFiltreSelected, chauffeurs, materiauxFiltreSelected, sortChauffeurState, createurDemandeSelected, typeVehiculeFiltreSelected, searchValue]);

    const onEditTransport = (editData) => {
        setAllTransportChauffeur({
            ...allTransportChauffeur,
            [editData.chauffeur._id._id]: allTransportChauffeur[editData.chauffeur._id._id].map((transport) => {
                if (transport._id === editData._id) {
                    return editData;
                }
                return transport;
            })
        });
    };

    const onEditDemande = (editData) => {
        // console.log(editData);
        // console.log(allDemandesTransport);
        setAllDemandesTransport(
            allDemandesTransport.map((demande) => {
                if (demande._id === editData._id) {
                    return editData;
                }
                return demande;
            })
        );
    };


    //--------------- loadData for ajouterDemande ------------------
    const [allAmpirollesOptions, setAllAmpirollesOptions] = useState([]);

    const fetchAmpiroll = () => {
        axiosInstance.get("/vehicules?typeVehicule=Ampiroll").then((res) => {
            setAllAmpirollesOptions(res.data.map((ampirolle) => {
                return { value: ampirolle._id, label: `n°${ampirolle.numeroParc} - ${ampirolle.immatriculation} - ${ampirolle.marque}` };
            }));
        }).catch((err) => {
            showError(err);
        });
    };

    const [chantiersOptions, setChantiersOptions] = useState([]);

    const fetchChantiers = () => {
        axiosInstance.get("/chantiers?statut_chantier=0&zone=true")
            .then((result) => {
                setChantiersOptions(result.data.map((chantier) => {
                    return { label: `(${chantier.codeChantier}) - ${chantier._id.libelle_lieu}`, value: chantier._id._id, adresse: chantier._id.adresse, typeLieu: "chantier" };
                }));
            }).catch((err) => {
                showError(err);
            });
    };

    const [sitesOptions, setSitesOptions] = useState([]);

    const fetchSites = () => {
        axiosInstance.get("/site").then((res) => {
            setSitesOptions(res.data.map((site) => {
                return { value: site._id._id, label: site._id.libelle_lieu, adresse: site._id.adresse, typeLieu: "site" };
            }));
        }).catch((err) => {
            showError(err);
        });
    };

    //--------------- Materiaux ------------------
    const [allMateriaux, setAllMateriaux] = useState([]);

    const fetchMateriaux = () => {
        axiosInstance.get("/materiaux").then((res) => {
            setAllMateriaux(res.data.flatMap((materiaux) => {
                let materiauxFormat = [{ value: materiaux._id, label: materiaux.libelle_mat, provenances: null }];
                for (const materiauxProvenances of materiaux.provenances) {
                    materiauxFormat.push({ value: materiaux._id, label: `${materiaux.libelle_mat} (${materiauxProvenances._id.libelle_lieu})`, provenances: materiauxProvenances._id._id });
                }
                return materiauxFormat;
            }));
        }).catch((err) => {
            showError(err);
        });
    };


    const chauffeurScrollbarRef = useRef(null);
    useEffect(() => {
        fetchChantiers();
        fetchSites();
        fetchAmpiroll();
        fetchMateriaux();
    }, []);
    const [chauffeurScrollPosition, setChauffeurScrollPosition] = useState(0);

    const onScroll = () => {
        setChauffeurScrollPosition(chauffeurScrollbarRef.current?.scrollLeft);
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            dispatch(savePageOptions("DemandeTransport", {
                chauffeurScrollPosition: chauffeurScrollPosition,
                dateDemande: dateDemande,
                sortChauffeurState: sortChauffeurState,
                typeDemandeFiltre: typeDemandeFiltre,
                chauffeurFiltreSelected: chauffeurFiltreSelected,
                materiauxFiltreSelected: materiauxFiltreSelected,
                searchBarContent: searchBarContent
            }));
        }, 1000);
        return () => clearTimeout(timer);
    }, [chauffeurScrollPosition, dateDemande, sortChauffeurState, typeDemandeFiltre, chauffeurFiltreSelected, materiauxFiltreSelected, searchBarContent]);

    const onAjouterDemande = (demandeData) => {
        socket.emit("demande_transport:create", { demandeTransport: demandeData, room: demandeData.creator._id });
        socket.emit("demande_transport:create", { demandeTransport: demandeData, room: parseInt(process.env.REACT_APP_DISPATCHER_ROOM) });
        if (format(dateDemande, "dd/MM/yyyy") === format(new Date(demandeData.dateLivraison), "dd/MM/yyyy")) {
            customToast.success("Nouvelle demande ajouté");
            setAllDemandesTransport(allDemandesTransport.concat(demandeData).sort((a, b) => getPrioriteScore(b.priorite) - getPrioriteScore(a.priorite)));
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (pageOptions.hasOwnProperty("DemandeTransport") && pageOptions["DemandeTransport"].hasOwnProperty("chauffeurScrollPosition")) {
                document.getElementById("chauffeurContainer").scroll({ left: pageOptions["DemandeTransport"]["chauffeurScrollPosition"], behavior: "smooth" });
            }
        }, 500);
    }, []);

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section" style={{ height: "calc(100vh - 64px - 4.9rem)", display: "flex", flexDirection: "column" }}>
                <div className="demandeTransport mgB-m2" style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "0.8rem", justifyContent: "flex-end" }}>
                    <div className="mgR-b2" style={{ display: "flex", flex: 1, alignItems: "center" }}>
                        <h1 className="mgR-s2">Demandes</h1>
                        <button className={"roundButton-ajouterDemande"} onClick={() => openAjouterDemande()}><FontAwesomeIcon icon={faPlus} size="lg" /></button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p className='mgR-s2'>Pour le</p>
                        <DatePicker onChange={(date) => onChangeDateDemande(date)} value={dateDemande} className="singleDatePicker" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd/MM/yyyy" showLeadingZeros={true} calendarIcon={null} clearIcon={null} />
                    </div>
                    <AdvanceSearch placeholder="Ma recherche" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchDemandes} search={() => search()} />
                    <Select
                        options={typeDemandes}
                        className="formSelect-search-container formSelect-search-container-full defaultFilter"
                        classNamePrefix="formSelect-search defaultFilter"
                        onChange={(opt) => setTypeDemandeFiltre(opt)}
                        placeholder={"Type"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                        value={typeDemandeFiltre}
                    />
                    <Select
                        defaultValue={""}
                        isMulti
                        name="chauffeurFilter"
                        placeholder={"Chauffeur(s)"}
                        options={chauffeursOptions}
                        className="formSelect-search-container formSelect-search-container-full defaultFilter"
                        classNamePrefix="formSelect-search"
                        isSearchable={true}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        onChange={(e) => selectChauffeur(e)}
                        value={chauffeurFiltreSelected}
                    />
                    <Select
                        defaultValue={""}
                        isMulti
                        name="createurFilter"
                        placeholder={"Créateur(s) demande"}
                        options={currentCreateurList}
                        className="formSelect-search-container formSelect-search-container-full defaultFilter"
                        classNamePrefix="formSelect-search"
                        isSearchable={true}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        onChange={(e) => selectCreateurDemande(e)}
                        value={createurDemandeSelected}
                    />
                    <Select
                        options={materiauxOptions}
                        className="formSelect-search-container formSelect-search-container-full defaultFilter"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => selectMateriaux(opt)}
                        placeholder={"Matériaux"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                        value={materiauxFiltreSelected}
                    />
                </div>
                <DragDropContext
                    onDragEnd={handleDragDrop}
                >
                    <div className='demandeTransportContainer-horizontal' style={{ minHeight: "10rem" }}>
                        {/* <div className='mgB-s2' style={{ display: "flex", flexWrap: "nowrap", gap: "1.6rem" }}> */}
                        <Droppable droppableId='demandeTransport' direction="horizontal">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {demandesTransportShowed.map((demandeTransport, index) => {
                                        if (demandeTransport.type === "DemandeTransportAmpiroll") {
                                            return (
                                                <Draggable draggableId={demandeTransport._id} key={demandeTransport._id} index={index}>
                                                    {(provided) => (
                                                        <div className='demandeTransportPadding' {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                                            {/* <p>Test</p> */}
                                                            <div className='demandeTransportItem'>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <p className='codeDemande'>{getNumeroTransportType(demandeTransport.type)}{getNumeroTransportDemande(demandeTransport.typeDemande)}-{demandeTransport.numeroTransport}</p>
                                                                        {demandeTransport.priorite === "Urgent" && <FontAwesomeIcon style={{ color: "#d13852", marginLeft: "0.8rem" }} icon={faTruck} size="lg" />}
                                                                    </div>
                                                                    <p className='utilisateurDemande'>de {demandeTransport.creator.nom} {demandeTransport.creator.prenom}</p>
                                                                </div>
                                                                <LinesEllipsis
                                                                    style={{ fontSize: "1.4rem", fontWeight: 700 }}
                                                                    text={`${demandeTransport.typeDemande}: n°${demandeTransport.vehicule.numeroParc} - ${demandeTransport.vehicule.immatriculation} - ${demandeTransport.vehicule.marque} avec ${demandeTransport.materiau.libelle_materiau}`}
                                                                    maxLine={"1"}
                                                                    ellipsis='...'
                                                                    trimRight={true}
                                                                    basedOn='letters'
                                                                />
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                                                    <LinesEllipsis
                                                                        style={{ fontSize: "1.2rem", fontWeight: 300 }}
                                                                        text={`De ${demandeTransport.lieuDepart._id.libelle_lieu} vers ${demandeTransport.lieuArriver._id.libelle_lieu}`}
                                                                        maxLine={"1"}
                                                                        ellipsis='...'
                                                                        trimRight={true}
                                                                        basedOn='letters'
                                                                    />
                                                                    <button onClick={() => openEditDemande(demandeTransport)} className='btn btn-primary'>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }
                                        else if (demandeTransport.type === "DemandeTransportPorteEngin") {
                                            return (
                                                <Draggable draggableId={demandeTransport._id} key={demandeTransport._id} index={index}>
                                                    {(provided) => (
                                                        <div className='demandeTransportPadding' {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                                            {/* <p>Test</p> */}
                                                            <div className='demandeTransportItem'>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <p className='codeDemande'>{getNumeroTransportType(demandeTransport.type)}{getNumeroTransportDemande(demandeTransport.typeDemande)}-{demandeTransport.numeroTransport}</p>
                                                                        {demandeTransport.priorite === "Urgent" && <FontAwesomeIcon style={{ color: "#d13852", marginLeft: "0.8rem" }} icon={faTruck} size="lg" />}
                                                                    </div>
                                                                    <p className='utilisateurDemande'>de {demandeTransport.creator.nom} {demandeTransport.creator.prenom}</p>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                                                    <LinesEllipsis
                                                                        style={{ fontSize: "1.2rem", fontWeight: 300 }}
                                                                        text={`Transfert d'un ${demandeTransport.typeVehicule} de ${demandeTransport.lieuDepart._id.libelle_lieu}  vers ${demandeTransport.lieuArriver._id.libelle_lieu}`}
                                                                        maxLine={"2"}
                                                                        ellipsis='...'
                                                                        trimRight={true}
                                                                        basedOn='letters'
                                                                    />
                                                                    <button onClick={() => openEditDemande(demandeTransport)} className='btn btn-primary'>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }
                                        else {
                                            return (
                                                <Draggable draggableId={demandeTransport._id} key={demandeTransport._id} index={index}>
                                                    {(provided) => (
                                                        <div className='demandeTransportPadding' {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                                            {/* <p>Test</p> */}
                                                            <div className='demandeTransportItem'>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <p className='codeDemande'>{getNumeroTransportType(demandeTransport.type)}{getNumeroTransportDemande(demandeTransport.typeDemande)}-{demandeTransport.numeroTransport}</p>
                                                                        {demandeTransport.priorite === "Urgent" && <FontAwesomeIcon style={{ color: "#d13852", marginLeft: "0.8rem" }} icon={faTruck} size="lg" />}
                                                                        {demandeTransport.doubleFret && <FontAwesomeIcon style={{ color: "#1E1E1E", marginLeft: "0.8rem" }} icon={faArrowsRotate} size="lg" />}
                                                                    </div>
                                                                    <p className='utilisateurDemande'>de {demandeTransport.creator.nom} {demandeTransport.creator.prenom}</p>
                                                                </div>
                                                                <LinesEllipsis
                                                                    style={{ fontSize: "1.4rem", fontWeight: 700 }}
                                                                    text={`${demandeTransport.typeDemande}: ${demandeTransport.nombreTours < 0 ? "Rotation sur place" : `${demandeTransport.nombreTours} tour${demandeTransport.nombreTours > 1 ? "s" : ""} de`} ${demandeTransport.materiau.libelle_mat} avec un ${demandeTransport.typeVehicule}`}
                                                                    maxLine={"1"}
                                                                    ellipsis='...'
                                                                    trimRight={true}
                                                                    basedOn='letters'
                                                                />
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                                                    <LinesEllipsis
                                                                        style={{ fontSize: "1.2rem", fontWeight: 300 }}
                                                                        text={`De ${demandeTransport.lieuDepart._id.libelle_lieu} vers ${demandeTransport.lieuArriver._id.libelle_lieu}`}
                                                                        maxLine={"1"}
                                                                        ellipsis='...'
                                                                        trimRight={true}
                                                                        basedOn='letters'
                                                                    />
                                                                    <button onClick={() => openEditDemande(demandeTransport)} className='btn btn-primary'>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div className="mgT-m1" style={{ width: "100%", display: "flex", alignItems: "center", height: "4rem" }}>
                        <FontAwesomeIcon icon={faBarsSort} size={"2xl"} style={{ marginRight: "1.2rem" }} />
                        {/* <button type='button' className='btn btn-secondary mgR-s3 small-btn' style={{display: "flex", alignItems: "center"}}><p style={{marginRight: "1.2rem", opacity: 0.4}}>Nom</p><div style={{display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center"}}><FontAwesomeIcon icon={faSortUp} style={{marginBottom: "-1.2rem", opacity: 0.4}}/><FontAwesomeIcon style={{opacity: 0.4}} icon={faSortDown} /></div></button>
                            <button type='button' className='btn btn-secondary mgR-s3 small-btn' style={{display: "flex", alignItems: "center"}}><p style={{marginRight: "1.2rem", opacity: 0.4}}>Prénom</p><div style={{display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center"}}><FontAwesomeIcon icon={faSortUp} style={{marginBottom: "-1.2rem", opacity: 0.4}}/><FontAwesomeIcon style={{opacity: 0.4}} icon={faSortDown} /></div></button>
                            <button type='button' className='btn btn-secondary mgR-s3 small-btn' style={{display: "flex", alignItems: "center"}}><p style={{marginRight: "1.2rem", opacity: 0.4}}>Nombre de transport</p><div style={{display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center"}}><FontAwesomeIcon icon={faSortUp} style={{marginBottom: "-1.2rem", opacity: 0.4}}/><FontAwesomeIcon style={{opacity: 0.4}} icon={faSortDown} /></div></button>
                            <button type='button' className='btn btn-secondary mgR-s3 small-btn' style={{display: "flex", alignItems: "center"}}><p style={{marginRight: "1.2rem", opacity: 0.4}}>Nombre d'urgence</p><div style={{display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center"}}><FontAwesomeIcon icon={faSortUp} style={{marginBottom: "-1.2rem", opacity: 0.4}}/><FontAwesomeIcon style={{opacity: 0.4}} icon={faSortDown} /></div></button> */}
                        {allSortChauffeur.map((chauffeurSort) => {
                            return (
                                <button
                                    onClick={() => { sortChauffeurListByProperty(chauffeurSort); }}
                                    key={chauffeurSort.filterValue}
                                    type='button'
                                    className={`btn mgR-s3 small-btn ${sortChauffeurState.filterName === chauffeurSort.filterValue ? "btn-primary" : "btn-secondary"}`}
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <p style={{ marginRight: "1.2rem", opacity: sortChauffeurState.filterName === chauffeurSort.filterValue ? 1 : 0.4 }}>{chauffeurSort.filterLabel}</p>
                                    <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignContent: "center" }}>
                                        <FontAwesomeIcon icon={faSortUp} style={{ marginBottom: "-1.2rem", opacity: sortChauffeurState.filterName === chauffeurSort.filterValue && sortChauffeurState.filterOrder === "asc" ? 1 : 0.4, color: sortChauffeurState.filterName === chauffeurSort.filterValue ? "#fff" : "#000" }} />
                                        <FontAwesomeIcon icon={faSortDown} style={{ opacity: sortChauffeurState.filterName === chauffeurSort.filterValue && sortChauffeurState.filterOrder === "desc" ? 1 : 0.4, color: sortChauffeurState.filterName === chauffeurSort.filterValue ? "#fff" : "#000" }} />
                                    </div>
                                </button>
                            );
                        })}
                        <Select
                            defaultValue={""}
                            isMulti
                            name="typeVehiculeFilter"
                            placeholder={"Type de véhicule"}
                            options={typeVehiculeOptions}
                            className="formSelect-search-container formSelect-search-container-full defaultFilter fixZIndex"
                            classNamePrefix="formSelect-search"
                            isSearchable={true}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    fontFamily: "DM Sans",
                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                    borderRadius: "0.6rem",
                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                    '&:hover': {
                                        border: '1px solid black',
                                    },
                                })
                            }}
                            onChange={(e) => selectTypeVehicule(e)}
                            value={typeVehiculeFiltreSelected}
                        />
                    </div>
                    <div ref={chauffeurScrollbarRef} id="chauffeurContainer" onScroll={onScroll} className="chauffeurTransportSection mgT-s1" style={{ flex: "1", display: "flex", gap: "1.8rem", overflowX: "auto", overflowY: "hidden" }}>
                        {chauffeursShowed.map((chauffeur) => {
                            return (
                                // <OverlayScrollbarsComponent element='div' className='chauffeurDroppableContainer' key={chauffeur._id._id} style={{width: "25rem", minWidth: "25rem", height: "100%", backgroundColor: "#fff", borderRadius: "1rem", display: "flex", flexDirection: "column"}} options={{ scrollbars: { autoHide: 'scroll' },   overflow: {
                                //     x: 'hidden',
                                //     y: 'scroll'
                                //   }, }} defer>
                                <div className='chauffeurDroppableContainer' key={chauffeur._id._id} style={{ width: "25rem", minWidth: "25rem", height: "100%", backgroundColor: "#fff", borderRadius: "1rem", display: "flex", flexDirection: "column" }}>
                                    <div className='chauffeurTransportHeader'>
                                        <div className='chauffeurTransportName-edit mgB-s2' style={{ display: "flex", alignItems: "center" }} onClick={() => navigate(`/gestion-de-donnees/salaries/profil/${chauffeur._id._id}`)}>
                                            <p className="titleHeader mgR-s2" style={{ marginBottom: 0 }}>{chauffeur._id.prenom} {chauffeur._id.nom}</p>
                                            <FontAwesomeIcon icon={faPenToSquare} style={{ width: "1.5rem", height: "1.5rem" }} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p style={{ fontWeight: 700, fontSize: "1.2rem" }}>Tracteur:</p>
                                            {chauffeur.vehiculeParDefaut.tracteurPorteur !== null &&
                                                <p className='mgL-s1' style={{ fontSize: "1.2rem" }}>{`n°${chauffeur.vehiculeParDefaut.tracteurPorteur.numeroParc} - ${chauffeur.vehiculeParDefaut.tracteurPorteur.immatriculation} - ${chauffeur.vehiculeParDefaut.tracteurPorteur.marque}`}</p>
                                            }
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p style={{ fontWeight: 700, fontSize: "1.2rem" }}>Benne:</p>
                                            {chauffeur.vehiculeParDefaut.remorque !== null &&
                                                <p className='mgL-s1' style={{ fontSize: "1.2rem" }}>{chauffeur.vehiculeParDefaut.remorque.numeroParc ? `n°${chauffeur.vehiculeParDefaut.remorque.numeroParc} - ${chauffeur.vehiculeParDefaut.remorque.immatriculation} - ${chauffeur.vehiculeParDefaut.remorque.marque}` : `${chauffeur.vehiculeParDefaut.remorque.immatriculation} - ${chauffeur.vehiculeParDefaut.remorque.marque}`}</p>
                                            }
                                        </div>
                                    </div>
                                    <ScrollBar overflow-behavior={{ x: 'hidden', y: 'auto' }}>

                                        {chauffeur.vehiculeParDefaut.tracteurPorteur === null && chauffeur.vehiculeParDefaut.remorque === null ?
                                            <div style={{ paddingLeft: "1.6rem", paddingRight: "1.6rem", display: "flex", alignItems: "center" }}>
                                                <p style={{ textAlign: "center" }}>Veuillez choisir un tracteur et une benne pour pouvoir assigner des demandes</p>
                                            </div>
                                            :
                                            <Droppable droppableId={`chauffeur-${chauffeur._id._id}`} direction="vertical">
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        {getDemandeChauffeur(chauffeur._id._id, allTransportChauffeur).map((transport, index) => {
                                                            if (transport.demande.type === "DemandeTransportCamion") {
                                                                return (
                                                                    <Draggable draggableId={transport._id} key={transport._id} index={transport.index}>
                                                                        {(provided) => (
                                                                            <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                                                                <div className='transportItem'>
                                                                                    {transport.hasOwnProperty("inCreation") && transport.inCreation
                                                                                        ?
                                                                                        <div style={{ width: "100%", height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                            <ClipLoader loading={true} color={"#1E1E1E"} size={15} />
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            <div className='transportEtat' style={{ backgroundColor: transport.etat === 0 ? "#fff" : transport.etat === 1 ? "#d8a72a" : transport.etat === 2 ? "#28a745" : "#d13852" }}></div>
                                                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                    <p className='codeDemande'>{getNumeroTransportType(transport.demande.type)}{getNumeroTransportDemande(transport.demande.typeDemande)}-{transport.demande.numeroTransport}</p>
                                                                                                    {transport.demande.priorite === "Urgent" && <FontAwesomeIcon style={{ color: "#d13852", marginLeft: "0.8rem" }} icon={faTruck} size="lg" />}
                                                                                                </div>
                                                                                                <p className='utilisateurDemande'>de {transport.demande.creator.nom} {transport.demande.creator.prenom}</p>
                                                                                            </div>
                                                                                            {
                                                                                                transport.validationTours.length ?
                                                                                                    <LinesEllipsis
                                                                                                        style={{ fontSize: "1.4rem", fontWeight: 700 }}
                                                                                                        text={`${transport.demande.typeDemande}: ${transport.demande.nombreTours < 0 ? "Rotation sur place" : ""} ${transport.demande.materiau.libelle_mat} avec un ${transport.demande.typeVehicule}`}
                                                                                                        maxLine={"2"}
                                                                                                        ellipsis='...'
                                                                                                        trimRight={true}
                                                                                                        basedOn='letters'
                                                                                                    />
                                                                                                    :
                                                                                                    <LinesEllipsis
                                                                                                        style={{ fontSize: "1.4rem", fontWeight: 700 }}
                                                                                                        text={`${transport.demande.typeDemande}: ${transport.demande.nombreTours < 0 ? "Rotation sur place" : `${transport.demande.nombreTours} tour${transport.demande.nombreTours > 1 ? "s" : ""} de`} ${transport.demande.materiau.libelle_mat} avec un ${transport.demande.typeVehicule}`}
                                                                                                        maxLine={"1"}
                                                                                                        ellipsis='...'
                                                                                                        trimRight={true}
                                                                                                        basedOn='letters'
                                                                                                    />
                                                                                            }
                                                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                                                                                <LinesEllipsis
                                                                                                    style={{ fontSize: "1.2rem", fontWeight: 300 }}
                                                                                                    text={`De ${transport.demande.lieuDepart._id.libelle_lieu} vers ${transport.demande.lieuArriver._id.libelle_lieu}`}
                                                                                                    maxLine={"1"}
                                                                                                    ellipsis='...'
                                                                                                    trimRight={true}
                                                                                                    basedOn='letters'
                                                                                                />
                                                                                                {/* <button onClick={() => console.log(`                                                                                           () => {
                                                                                                if (transport.etat > 1) {
                                                                                                    openResumerDemande(transport);
                                                                                                }
                                                                                                else if (transport.etat === 1) {
                                                                                                    openAvancementModal(transport);
                                                                                                }
                                                                                                else {
                                                                                                    openEditDemande({ ...transport.demande, type: transport.demande.type, transportId: transport._id, chauffeur: chauffeur._id._id, index: transport.index });
                                                                                                }`)} className='btn btn-primary'>
                                                                                                    +
                                                                                                </button> */}
                                                                                                <button onClick={() => {
                                                                                                    if (transport.etat === 0) {
                                                                                                        openEditTransport(transport);
                                                                                                    }
                                                                                                    else if (transport.etat === 1) {
                                                                                                        openAvancementModal(transport);
                                                                                                    }
                                                                                                    else {
                                                                                                        openResumerDemande(transport);
                                                                                                    }
                                                                                                }} className='btn btn-primary'>
                                                                                                    +
                                                                                                </button>
                                                                                            </div>
                                                                                            {transport.validationTours.length ?
                                                                                                transport.demande.nombreTours > 0 ?
                                                                                                    <div className='mgT-s2 progressBar_transport_camion' style={{ width: "100%" }}>
                                                                                                        <ProgressBar
                                                                                                            completed={transport.validationTours.length * 100 / transport.demande.nombreTours}
                                                                                                            bgColor='#1E1E1E'
                                                                                                            customLabel={`${transport.validationTours.length}/${transport.demande.nombreTours}`}
                                                                                                            labelAlignment='center'
                                                                                                            height='1.6rem'
                                                                                                            labelSize='1.4rem'
                                                                                                        />
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className='mgT-s2 progressBar_transport_camion' style={{ width: "100%" }}>
                                                                                                        <ProgressBar
                                                                                                            completed={100}
                                                                                                            bgColor='#1E1E1E'
                                                                                                            customLabel={`${transport.validationTours.length} rotation${transport.validationTours.length > 1 ? "s" : ""}`}
                                                                                                            labelAlignment='center'
                                                                                                            height='1.6rem'
                                                                                                            labelSize='1.4rem'
                                                                                                        />
                                                                                                    </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                );
                                                            }
                                                            else if (transport.demande.type === "DemandeTransportAmpiroll") {
                                                                return (
                                                                    <Draggable draggableId={transport._id} key={transport._id} index={transport.index}>
                                                                        {(provided) => (
                                                                            <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                                                                <div className='transportItem'>
                                                                                    <div className='transportEtat' style={{ backgroundColor: transport.etat === 0 ? "#fff" : transport.etat === 1 ? "#d8a72a" : transport.etat === 2 ? "#28a745" : "#d13852" }}>
                                                                                    </div>
                                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                                            <p className='codeDemande'>{getNumeroTransportType(transport.demande.type)}{getNumeroTransportDemande(transport.demande.typeDemande)}-{transport.demande.numeroTransport}</p>
                                                                                            {transport.demande.priorite === "Urgent" && <FontAwesomeIcon style={{ color: "#d13852", marginLeft: "0.8rem" }} icon={faTruck} size="lg" />}
                                                                                        </div>
                                                                                        <p className='utilisateurDemande'>de {transport.demande.creator.nom} {transport.demande.creator.prenom}</p>
                                                                                    </div>
                                                                                    <LinesEllipsis
                                                                                        style={{ fontSize: "1.4rem", fontWeight: 700 }}
                                                                                        text={`${transport.demande.typeDemande}: n°${transport.demande.vehicule.numeroParc} - ${transport.demande.vehicule.immatriculation} - ${transport.demande.vehicule.marque} avec ${transport.demande.materiau.libelle_materiau}`}
                                                                                        maxLine={"1"}
                                                                                        ellipsis='...'
                                                                                        trimRight={true}
                                                                                        basedOn='letters'
                                                                                    />
                                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                                                                        <LinesEllipsis
                                                                                            style={{ fontSize: "1.2rem", fontWeight: 300 }}
                                                                                            text={`De ${transport.demande.lieuDepart._id.libelle_lieu} vers ${transport.demande.lieuArriver._id.libelle_lieu}`}
                                                                                            maxLine={"1"}
                                                                                            ellipsis='...'
                                                                                            trimRight={true}
                                                                                            basedOn='letters'
                                                                                        />
                                                                                        {/* <button onClick={
                                                                                            () => console.log(`{
                                                                                                if (transport.etat > 1) {
                                                                                                    openResumerDemande(transport);
                                                                                                }
                                                                                                else if (transport.etat === 1) {
                                                                                                    openAvancementModal(transport);
                                                                                                }
                                                                                                else {
                                                                                                    openEditDemande({ ...transport.demande, type: transport.demande.type, transportId: transport._id, chauffeur: chauffeur._id._id, index: transport.index });
                                                                                                }
                                                                                            }`)} className='btn btn-primary'>
                                                                                            +
                                                                                        </button> */}
                                                                                        <button onClick={() => {
                                                                                            if (transport.etat === 0) {
                                                                                                openEditTransport(transport);
                                                                                            }
                                                                                            else if (transport.etat === 1) {
                                                                                                openAvancementModal(transport);
                                                                                            }
                                                                                            else {
                                                                                                openResumerDemande(transport);
                                                                                            }
                                                                                        }} className='btn btn-primary'>
                                                                                            +
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <Draggable draggableId={transport._id} key={transport._id} index={transport.index}>
                                                                        {(provided) => (
                                                                            <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                                                                <div className='transportItem'>
                                                                                    <div className='transportEtat' style={{ backgroundColor: transport.etat === 0 ? "#fff" : transport.etat === 1 ? "#d8a72a" : transport.etat === 2 ? "#28a745" : "#d13852" }}>
                                                                                    </div>
                                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                                            <p className='codeDemande'>{getNumeroTransportType(transport.demande.type)}{getNumeroTransportDemande(transport.demande.typeDemande)}-{transport.demande.numeroTransport}</p>
                                                                                            {transport.demande.priorite === "Urgent" && <FontAwesomeIcon style={{ color: "#d13852", marginLeft: "0.8rem" }} icon={faTruck} size="lg" />}
                                                                                        </div>
                                                                                        <p className='utilisateurDemande'>de {transport.demande.creator.nom} {transport.demande.creator.prenom}</p>
                                                                                    </div>
                                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                                                                        <LinesEllipsis
                                                                                            style={{ fontSize: "1.2rem", fontWeight: 300 }}
                                                                                            text={`Transfert d'un ${transport.demande.typeVehicule} de ${transport.demande.lieuDepart._id.libelle_lieu}  vers ${transport.demande.lieuArriver._id.libelle_lieu}`}
                                                                                            maxLine={"2"}
                                                                                            ellipsis='...'
                                                                                            trimRight={true}
                                                                                            basedOn='letters'
                                                                                        />
                                                                                        {/* <button onClick={
                                                                                            () => {
                                                                                                console.log(`
                                                                                                if (transport.etat > 1) {
                                                                                                    openResumerDemande(transport);
                                                                                                }
                                                                                                else if (transport.etat === 1) {
                                                                                                    openAvancementModal(transport);
                                                                                                }
                                                                                                else {
                                                                                                    openEditDemande({ ...transport.demande, type: transport.demande.type, transportId: transport._id, chauffeur: chauffeur._id._id, index: transport.index });
                                                                                                }`);
                                                                                            }} className='btn btn-primary'>+</button> */}
                                                                                        <button onClick={() => {
                                                                                            if (transport.etat === 0) {
                                                                                                openEditTransport(transport);
                                                                                            }
                                                                                            else if (transport.etat === 1) {
                                                                                                openAvancementModal(transport);
                                                                                            }
                                                                                            else {
                                                                                                openResumerDemande(transport);
                                                                                            }
                                                                                        }} className='btn btn-primary'>
                                                                                            +
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                );
                                                            }
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        }
                                    </ScrollBar>
                                </div>
                            );
                        })}
                        {/* </div> */}
                    </div>
                </DragDropContext>
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "modifierDemande" &&
                    <ModifierDemandeTransport onEditDemande={(editData) => onEditDemande(editData)} onEditTransport={(editData) => onEditTransport(editData)} onSupprimerDemande={(demandeSupprimer) => onSupprimerDemande(demandeSupprimer)} onDupliquer={(demandeDupliquer, dateDuplication) => onDupliquer(demandeDupliquer, dateDuplication)} progressBarRef={progressBarRef} demandeData={modalDemandeSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "modifierTransport" &&
                    <ModifierTransportAssigner transportData={modalTransportSelected} onEditTransport={(editData) => onEditTransport(editData)} onSupprimerTransportAssigner={(transportSupprimer) => onSupprimerTransportAssigner(transportSupprimer)} onDupliquerTransport={(transportDupliquer, dateDuplication) => onDupliquerTransport(transportDupliquer, dateDuplication)} progressBarRef={progressBarRef} demandeData={modalDemandeSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "resumerTransport" &&
                    <ResumerTransportModal transportData={modalTransportSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
                {modalContent === "avancementTransportSimple" &&
                    <AvancementTransportSimple transportData={modalTransportSelected} onEditTransport={(editData) => onEditTransport(editData)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} progressBarRef={progressBarRef} />
                }
                {modalContent === "avancementTransportCamion" &&
                    <AvancementTransportCamion transportData={modalTransportSelected} onEditTransport={(editData) => onEditTransport(editData)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} progressBarRef={progressBarRef} />
                }
                {modalContent === "ajouterDemande" &&
                    <AjouterDemandeModal onAjouterDemande={(demandeData) => onAjouterDemande(demandeData)} currentDate={dateDemande} allAmpirollesOptions={allAmpirollesOptions} chantiersOptions={chantiersOptions} sitesOptions={sitesOptions} allMateriaux={allMateriaux} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} progressBarRef={progressBarRef} />
                }
            </Modal>
        </>
    );
};

export default DemandeTransport



