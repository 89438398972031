import React, { useEffect, useState, useRef } from 'react';
import UserAccountIcon from '../components/icon/UserAccountIcon';
import { useDispatch, useSelector } from 'react-redux';
import customToast from '../utils/ToastifyHelper';
import axiosInstance from '../utils/axiosInstance';
import { Icon } from '@iconify/react';
import ReactTooltip from "react-tooltip";
import { showError } from '../utils/ErrorHelper';
import { useFormik } from 'formik';
import * as yup from "yup";
import LoadingBar from 'react-top-loading-bar';

import DefaultProfilePicture from '../components/ui/DefaultProfilePicture';
import Modal from '../components/Modal';
import EditProfilePictureModal from '../components/ui/EditProfilePictureModal';
import ProfilePicture from '../components/ui/ProfilePicture';
import * as actionCreators from '../store/actions/exportAction';



const MonCompte = () => {
    const userState = useSelector(
        state => state.user
    );


    const [salarie, setSalarie] = useState([]);
    const progressBarRef = useRef(null);
    const [currentMdp, setCurrentMdp] = useState("");
    const [newMdp, setNewMdp] = useState("");
    const [confirmNewMdp, setConfirmNewMdp] = useState("");
    const [error, setError] = useState({ dest: "", msg: "" });

    const modifierMotDePasse = () => {
        if (currentMdp.length === 0) {
            setError({ dest: "current", msg: "Veuillez renseigner votre mot de passe actuelle pour le modifier" });
        }
        else if (newMdp !== confirmNewMdp) {
            setError({ dest: "confirm", msg: "Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas" });
        }
        else {
            if (newMdp.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/, "i")) {
                axiosInstance.patch("/utilisateurs/changePassword/me", { currentMdp: currentMdp, newMdp: newMdp })
                    .then(() => {
                        customToast.success("Le mot de passe a été modifié avec succès");
                        setCurrentMdp("");
                        setNewMdp("");
                        setConfirmNewMdp("");
                    }).catch((err) => {
                        showError(err);
                    });
            }
            else {
                setError({ dest: "confirm", msg: "Le nouveau mot de passe doit contenir au moins 1 majuscule et au moins 1 chiffre" });
            }
        }
    };

    // form schema
    const formSchema = yup.object().shape({
        telephone: yup.string().matches(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/, "Veuillez saisir un numéro de téléphone correct "),
        residence: yup.string().nullable().notRequired(),
        ville: yup.string().nullable().notRequired(),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        })
    },
        ["codePostal", "codePostal"]
    );

    // handle submit
    const onSubmit = (values, actions) => {
        progressBarRef.current.continuousStart();
        const patchData = {
            telephone: values.telephone,
            adresse: values.residence,
            codePostal: values.codePostal.length ? values.codePostal : null,
            ville: values.ville,
        };
        axiosInstance.patch(`/utilisateurs/me`, patchData).then((result) => {
            setSubmitting(false);
            progressBarRef.current.complete();
            setFieldValue("telephone", result.data.telephone ? result.data.telephone : "");
            if (result.data.residence.adresse !== null) {
                setFieldValue("residence", result.data.residence.adresse);
            }
            if (result.data.residence.ville !== null) {
                setFieldValue("ville", result.data.residence.ville);
            }
            if (result.data.residence.codePostal !== null) {
                setFieldValue("codePostal", result.data.residence.codePostal);
            }
            customToast.success("Votre profil a été modifié avec succès");
        }).catch((err) => {
            showError();
            setSubmitting(false);
            progressBarRef.current.complete();
        });
    };

    // Handle formm
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            telephone: "",
            residence: "",
            ville: "",
            codePostal: "",
        },
        validationSchema: formSchema,
        onSubmit
    });

    useEffect(() => {
        progressBarRef.current.continuousStart();
        axiosInstance.get("/utilisateurs/me").then((result) => {
            progressBarRef.current.complete();
            setSalarie(result.data);
            setFieldValue("telephone", result.data.telephone ? result.data.telephone : "");
            if (result.data.residence.adresse !== null) {
                setFieldValue("residence", result.data.residence.adresse);
            }
            if (result.data.residence.ville !== null) {
                setFieldValue("ville", result.data.residence.ville);
            }
            if (result.data.residence.codePostal !== null) {
                setFieldValue("codePostal", result.data.residence.codePostal);
            }
        }).catch((err) => {
            showError(err);
            progressBarRef.current.complete();
        });
    }, []);

    const changePasswordSchema = yup.object().shape({
        currentPassword: yup
            .string()
            .required('Veuillez saisir votre mot de passe actuelle'),
        newPassword: yup
            .string()
            .required('Veuillez saisir votre nouveau mot de passe')
            .min(8, 'Votre mot de passe doit faire au minimum 8 caractères')
            .max(20, 'Votre mot de passe ne peut pas excedé 20 caractères')
            .matches(
                /^(?=.*[A-Z])/,
                'Votre mot de passe doit contenir au moins une lettre majuscule'
            )
            .matches(
                /^(?=.*\d)/,
                'Votre mot de passe doit contenir au moins un chiffre'
            ),
        confirmNewPassword: yup
            .string()
            .required()
            .oneOf([yup.ref('newPassword')], 'Les mot de passe ne correspondent pas')
    });

    const onChangePasswordSubmit = (values, actions) => {
        progressBarRef.current.continuousStart();
        axiosInstance.patch("/utilisateurs/changePassword/me", values).then((result) => {
            progressBarRef.current.complete();
            actions.resetForm();
            customToast.success("Le nouveau mot de passe a été enregistré");
        }).catch((err) => {
            progressBarRef.current.complete();
            actions.setSubmitting(false);
            showError(err);
        });
    };

    // Handle formm
    const changePasswordFormik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        },
        validationSchema: changePasswordSchema,
        onSubmit: onChangePasswordSubmit
    });

    const areAllFieldsTouched = (formik) => {
        return Object.keys(formik.touched).every((field) => formik.touched[field]);
    };

    const hasErrors = (formik) => {
        return Object.keys(formik.errors).some((field) => formik.errors[field]);
    };

    const canChangePassword = () => {
        return areAllFieldsTouched(changePasswordFormik) && !hasErrors(changePasswordFormik);
    };

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("editProfilePicture");

    const openEditProfilePicture = () => {
        setModalClasses("modal modal__active");
        setModalContent("editProfilePicture");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
    };

    const dispatch = useDispatch();

    const updateProfilePicture = (picture) => {
        setSalarie({
            ...salarie,
            profilePicture: picture._id
        });
        dispatch(actionCreators.updateProfilePicture(picture._id));
    };


    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <div style={{ display: "flex" }}>
                    <div className="container-basic mgR-m3" style={{ flex: 3 }}>
                        <h3 className='mgB-b'>
                            Informations du compte
                        </h3>
                        {/* <div className='editProfile-picture-container'>
                <UserAccountIcon />
            </div> */}
                        <div className="form">
                            {/* <div className="two-column-form">
                                <div className='label-and-input'>
                                    <label htmlFor='nom' className='form-label'>Nom</label>
                                    <input name='nom' className="new-default-input" type="text" placeholder="Nom" value={userState.user.nom} readOnly={true} />
                                </div>
                                <div className='label-and-input'>
                                    <label htmlFor='nom' className='form-label'>Prénom</label>
                                    <input name='nom' className="new-default-input" type="text" placeholder="Nom" value={userState.user.nom} readOnly={true} />
                                </div>
                            </div> */}
                            <div className='label-and-input'>
                                <label htmlFor='matricule' className='form-label'>Matricule</label>
                                <input name='matricule' className="new-default-input" type="text" placeholder="Matricule" value={userState.user.login_matricule} readOnly={true} />
                            </div>
                            <div className='label-and-input'>
                                <label htmlFor='statut' className='form-label'>Statut</label>
                                <input name='statut' className="new-default-input" type="text" placeholder="statut" value={userState.user.statut} readOnly={true} />
                            </div>
                            <form className='form' onSubmit={handleSubmit}>
                                <div className='label-and-input'>
                                    <label htmlFor='telephone' className='form-label'>Téléphone</label>
                                    <input name='telephone' className={`new-default-input${errors.telephone && touched.telephone ? " input-error" : ""}`} type="text" placeholder="Téléphone" value={values.telephone} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.telephone && touched.telephone && <p className="form-error-message">{errors.telephone}</p>}
                                </div>
                                <div className='label-and-input'>
                                    <label className='form-label' htmlFor="residence">Adresse</label>
                                    <input className={`new-default-input${errors.residence && touched.residence ? " input-error" : ""}`} name="residence" type="text" placeholder="residence" value={values.residence} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.residence && touched.residence && <p className="form-error-message">{errors.residence}</p>}
                                </div>
                                <div className='label-and-input'>
                                    <label className='form-label' htmlFor="codePostal">Code postal</label>
                                    <input className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} name="codePostal" type="text" placeholder="codePostal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                                </div>
                                <div className='label-and-input'>
                                    <label className='form-label' htmlFor="ville">Ville</label>
                                    <input className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} name="ville" type="text" placeholder="ville" value={values.ville} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                                </div>
                                <input disabled={isSubmitting} style={{ opacity: !isSubmitting ? 1 : 0.4 }} className="btn btn-primary" type="submit" value="Sauvegarder" />
                            </form>
                        </div>
                    </div>
                    <div className="container-basic" style={{ flex: 1.75 }}>
                        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <div style={{ flex: 1 }} className='mgB-m3 mgT-s2'>
                                <div className='accountSettings-profilePicture-wrapper'>
                                    {salarie.hasOwnProperty("profilePicture") && salarie.profilePicture !== null ?
                                        <ProfilePicture width="150px" height="150px" edit={true} openEditProfilePicture={() => openEditProfilePicture()} profilePicture={salarie.profilePicture} /> :
                                        <DefaultProfilePicture additionalClassname={"accountSettings"} width="150px" height="150px" edit={true} openEditProfilePicture={() => openEditProfilePicture()} />
                                    }
                                </div>
                                <div className='mgT-s2 pdB-m2' style={{ display: "flex", justifyContent: "center", borderBottom: "1px solid #000" }}>
                                    <p className='profileName'>{userState.user.prenom} {userState.user.nom}</p>
                                </div>
                            </div>
                            <div className="editPassword-container">
                                <h3 className='mgB-b'>
                                    Modifier mon mot de passe
                                </h3>
                                <form className='form' onSubmit={changePasswordFormik.handleSubmit}>
                                    <div className='label-and-input'>
                                        <label htmlFor='currentPassword' className='form-label'>Mot de passe actuelle</label>
                                        <div style={{ height: "38px", marginTop: "0.8rem" }} className="loginWrapper">
                                            <div onClick={() => setShowCurrentPassword(!showCurrentPassword)} className="loginIcon"><Icon icon={showCurrentPassword ? "fa-solid:eye" : "fa-solid:eye-slash"} /></div>
                                            <input style={{ marginTop: 0, position: "static" }} name='currentPassword' className={`new-default-input${changePasswordFormik.errors.currentPassword && changePasswordFormik.touched.currentPassword ? " input-error" : ""}`} type={showCurrentPassword ? "text" : "password"} placeholder="Mot de passe" value={changePasswordFormik.values.currentPassword} onChange={changePasswordFormik.handleChange} onBlur={changePasswordFormik.handleBlur} />
                                        </div>
                                        {changePasswordFormik.errors.currentPassword && changePasswordFormik.touched.currentPassword && <p className="form-error-message">{changePasswordFormik.errors.currentPassword}</p>}
                                    </div>
                                    <div className='label-and-input'>
                                        <label htmlFor='newPassword' className='form-label'>Nouveau mot de passe</label>
                                        <div style={{ height: "38px", marginTop: "0.8rem" }} className="loginWrapper">
                                            <div onClick={() => setShowNewPassword(!showNewPassword)} className="loginIcon"><Icon icon={showNewPassword ? "fa-solid:eye" : "fa-solid:eye-slash"} /></div>
                                            <input style={{ marginTop: 0, position: "static" }} name='newPassword' className={`new-default-input${changePasswordFormik.errors.newPassword && changePasswordFormik.touched.newPassword ? " input-error" : ""}`} type={showNewPassword ? "text" : "password"} placeholder="Nouveau mot de passe" value={changePasswordFormik.values.newPassword} onChange={changePasswordFormik.handleChange} onBlur={changePasswordFormik.handleBlur} />
                                        </div>
                                        {changePasswordFormik.errors.newPassword && changePasswordFormik.touched.newPassword && <p className="form-error-message">{changePasswordFormik.errors.newPassword}</p>}
                                    </div>
                                    <div className='label-and-input'>
                                        <label htmlFor='confirmNewPassword' className='form-label'>Confirmez le nouveau mot de passe</label>
                                        <div style={{ height: "38px", marginTop: "0.8rem" }} className="loginWrapper">
                                            <div onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} className="loginIcon"><Icon icon={showConfirmNewPassword ? "fa-solid:eye" : "fa-solid:eye-slash"} /></div>
                                            <input style={{ marginTop: 0, position: "static" }} name='confirmNewPassword' className={`new-default-input${changePasswordFormik.errors.confirmNewPassword && changePasswordFormik.touched.confirmNewPassword ? " input-error" : ""}`} type={showConfirmNewPassword ? "text" : "password"} placeholder="Confirmez nouveau mot de passe" value={changePasswordFormik.values.confirmNewPassword} onChange={changePasswordFormik.handleChange} onBlur={changePasswordFormik.handleBlur} />
                                        </div>
                                        {changePasswordFormik.errors.confirmNewPassword && changePasswordFormik.touched.confirmNewPassword && <p className="form-error-message">{changePasswordFormik.errors.confirmNewPassword}</p>}
                                    </div>
                                    <input disabled={changePasswordFormik.isSubmitting || !canChangePassword()} style={{ opacity: !changePasswordFormik.isSubmitting && canChangePassword() ? 1 : 0.4 }} className="btn btn-primary" type="submit" value="Enregistrez le nouveau mot de passe" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal modalClass={modalClasses}>
                {modalContent === "editProfilePicture" &&
                    <EditProfilePictureModal updateProfilePicture={(picture) => updateProfilePicture(picture)} progressBarRef={progressBarRef} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
                }
            </Modal>
        </>
        // <div className="monCompte-container">
        //   <div className="container-basic mgB-m2" style={{marginBottom: 0, width: "50%", minWidth: "42rem", padding: "3rem 6rem"}}>
        //     <h1 className='h1-blue-dark'>Mon compte</h1>
        //     <div className="allProfileContainer">
        //       <div className="monCompte-leftSection">
        //         <div className="profilePicture-container">
        //           <UserAccountIcon />
        //         </div>
        //       </div>
        //       <div className="monCompte-rightSection">
        //         <div className="informationsComptes">
        //           <h1 className='h1-blue-dark'>Informations du compte</h1>
        //           <div className="informationsComptes-labelInput-container">
        //             <label className='modifier-form-label default-label' htmlFor="immatriculation">Nom</label>
        //             <input disabled className="modifier-form-input default-input" type="text" defaultValue={userState.user.nom} />
        //           </div>
        //           <div className="informationsComptes-labelInput-container">
        //             <label className='modifier-form-label default-label' htmlFor="immatriculation">Prénom</label>
        //             <input disabled className="modifier-form-input default-input" type="text" defaultValue={userState.user.prenom} />
        //           </div>
        //           <div className="informationsComptes-labelInput-container">
        //             <label className='modifier-form-label default-label' htmlFor="immatriculation">Matricule</label>
        //             <input disabled className="modifier-form-input default-input" type="text" defaultValue={userState.user.login_matricule} />
        //           </div>
        //           <div className="informationsComptes-labelInput-container">
        //             <label className='modifier-form-label default-label' htmlFor="immatriculation">Secteur d'activité</label>
        //             <input disabled className="modifier-form-input default-input" type="text" defaultValue={userState.user.secteur} />
        //           </div>
        //         </div>
        //         <div className="modifierMotdDePasse-iconAndText">
        //           <h1 className='h1-blue-dark'>Modifier mon mot de passe</h1>
        //           <Icon icon="ep:question-filled" data-tip data-for="newPassword"/>
        //           <ReactTooltip id="newPassword" place="bottom" effect="solid">
        //             Le nouveau mot de passe doit contenir de 8 a 15 caractères, au moins 1 lettre en majuscule et au moins 1 chiffre
        //           </ReactTooltip>
        //         </div>
        //         <div className="informationsComptes-labelInput-container">
        //           <label className='modifier-form-label default-label' htmlFor="immatriculation">Mot de passe actuelle</label>
        //           <input className="modifier-form-input default-input" type="password" value={currentMdp} onChange={e => {setCurrentMdp(e.target.value); if (error.dest === "current" && e.target.value.length > 0) {
        //             setError({dest: "", msg: ""})
        //           }}}/>
        //           {error.dest === 'current' && <p className="login-error-message">{error.msg}</p>}
        //         </div>
        //         <div className="informationsComptes-labelInput-container">
        //           <label className='modifier-form-label default-label' htmlFor="immatriculation">Nouveau mot de passe</label>
        //           <input className="modifier-form-input default-input" type="password" value={newMdp} onChange={e => {setNewMdp(e.target.value); if (error.dest === "confirm" && e.target.value === confirmNewMdp) {setError({dest: "", msg: ""})}}}/>
        //         </div>
        //         <div className="informationsComptes-labelInput-container">
        //           <label className='modifier-form-label default-label' htmlFor="immatriculation">Confirmez le nouveau mot de passe</label>
        //           <input className="modifier-form-input default-input" type="password" value={confirmNewMdp} onChange={e => {setConfirmNewMdp(e.target.value); if (error.dest === "confirm" && newMdp === e.target.value) {setError({dest: "", msg: ""})}}}/>

        //           {error.dest === 'confirm' && <p className="login-error-message">{error.msg}</p>}
        //         </div>
        //         <button onClick={() => modifierMotDePasse()} className='default-button'>Sauvegarder le mot de passe</button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
    );
};

export default MonCompte

