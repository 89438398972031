import axios from 'axios';
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { showError } from '../utils/ErrorHelper';
import SearchInput from './ui/SearchInput';
import { getInsensitiveWorld, iconExtension } from '../utils/GeneralHelper';
import Modal from './Modal';
import { compareDesc, format, subDays, subMonths, subYears } from 'date-fns';
import { Icon } from '@iconify/react';
import { getEntretienValideesColumns } from './tables/TableColumns';
import SimpleTableRender from './tables/SimpleTableRender';
import SupprimerIcon from './icon/SupprimerIcon';
import AjouterEntretienToEquipement from './forms/AmianteForms/AjouterEntretienToEquipement';
import { useParams } from 'react-router-dom';
import SupprimerModalForm from './forms/SupprimerModalForm';
import ModifierEntretienToEquipement from './forms/AmianteForms/ModifierEntretienToEquipement';

const EntretiensEquipementList = (props) => {
    const { idMateriel } = useParams();
    // Formations data
    const [entretiens, setEntretiens] = useState([]);
    const [showedEntretien, setShowedEntretien] = useState([]);

    const [historiqueOpenId, setHistoriqueOpenId] = useState(null);

    useEffect(() => {

        axiosInstance.get(`/entretienEquipement/entretiensEquipementDispo/${idMateriel}`).then((result) => {
            // console.log(result);
            let entretiens = result.data;
            for (const entretien of entretiens) {
                entretien.entretienValidees.sort((a, b) => {
                    return compareDesc(new Date(a.dateEntretien), new Date(b.dateEntretien));
                });
            }

            setEntretiens(entretiens);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    // searchBar
    const [searchEntretienBarContent, setSearchEntretienBarContent] = useState(null);
    const onChangeSearchEntretien = (e) => {
        setSearchEntretienBarContent(e.target.value);
    };

    const formationMatch = (entretien, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return getInsensitiveWorld(entretien.entretien.nomEntretien).includes(insensitiveSearch);
    };


    const getDateSignalementUrgence = (dateExpiration, signalerUrgence) => {
        let dateUrgence = dateExpiration;
        if (signalerUrgence.annee !== null) {
            dateUrgence = subYears(dateUrgence, signalerUrgence.annee);
        }
        if (signalerUrgence.mois !== null) {
            dateUrgence = subMonths(dateUrgence, signalerUrgence.mois);
        }
        if (signalerUrgence.jour !== null) {
            dateUrgence = subDays(dateUrgence, signalerUrgence.jour);
        }
        return dateUrgence;
    };

    const getContainerExpirationClass = (dateExpiration, signalerUrgence) => {
        let currentDate = new Date();
        if (dateExpiration === null) {
            return "formation-valide";
        }
        else {
            let formatDateExpiration = new Date(dateExpiration);
            if (currentDate.getTime() > formatDateExpiration.getTime()) {
                return "formation-expired";
            }
            else {
                let dateUrgence = getDateSignalementUrgence(formatDateExpiration, signalerUrgence);
                if (currentDate.getTime() >= dateUrgence.getTime()) {
                    return "formation-urgent";
                }
                else {
                    return "formation-valide";
                }
            }

        }
    };

    useEffect(() => {
        if (searchEntretienBarContent !== null && searchEntretienBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedEntretien(entretiens.filter(entretien => {
                    return formationMatch(entretien, searchEntretienBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedEntretien(entretiens);
        }

    }, [entretiens, searchEntretienBarContent]);


    const addEntretien = (entretienAjouter) => {
        const isPresent = entretiens.map((entre) => entre._id).indexOf(entretienAjouter.data._id);

        if (isPresent > -1) {
            const indexFind = entretiens.map((entretien) => {
                if (entretien._id === entretienAjouter.data._id) {

                    return { ...entretien, entretienValidees: entretienAjouter.data.entretienValidees };
                } else {
                    return entretien;
                }
            });
            setEntretiens(indexFind);
        } else {
            // console.log("add",entretienAjouter.data);
            // console.log("x", formationsSalarie);
            setEntretiens([...entretiens, entretienAjouter.data]);
        }

    };

    const modifyEntretien = (entretienModify) => {

        const indexFind = entretiens.map((entretien) => {

            if (entretien._id === entretienModify.data._id) {
                return { ...entretien, entretienValidees: entretienModify.data.entretienValidees };
            } else {
                return entretien;
            }
        });

        setEntretiens(indexFind);
    };

    const deleteEntretien = (entretienDelete) => {

        if (entretienDelete.data.entretienValidees.length > 0) {

            const indexFind = entretiens.map((entretien) => {

                if (entretien._id === entretienDelete.data._id) {

                    return { ...entretien, entretienValidees: entretienDelete.data.entretienValidees };
                } else {
                    return entretien;
                }
            });

            setEntretiens(indexFind);
        } else {

            // console.log(formationsSalarie.filter((entre) => entre._id !== entretienDelete.data._id))
            setEntretiens(entretiens.filter((entre) => entre._id !== entretienDelete.data._id));
        }
    };

    //doc 
    const viewFile = (documentId) => {
        axiosInstance.get(`uploads/documents/${documentId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    // GESTION MODAL
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState(null);


    const openModalAjouterFormation = (addFormation) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterEntretienToEquipement addEntretien={addFormation} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };



    const openModalModifierEntretien = (entretienId) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierEntretienToEquipement entretien={entretienId} modifyEntretien={modifyEntretien} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };
    const openModalSupprimerEntretienValiditee = (entretien) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);

        setModalContent(<SupprimerModalForm destination="SupprimerEntretienValidFromEquipement" deleteEntretien={deleteEntretien} entretienId={entretien._id} validite={entretien.validiteId} equipement={entretien} idDoc={entretien.entretienDoc} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };


    return (
        <>
            <div>
                <div className="new-filter-section">
                    <button className='btn btn-primary' onClick={() => openModalAjouterFormation((entretien) => addEntretien(entretien))}>
                        + Ajouter entretien
                    </button>
                    <div className='new-filter-container'>
                        <SearchInput style={{ flex: 1 }} placeholder="Rechercher un entretien" searchContent={searchEntretienBarContent ? searchEntretienBarContent : ""} onChangeSearch={onChangeSearchEntretien} />
                    </div>
                </div>
                {showedEntretien.map((entretienMap) => {

                    return (
                        <div key={entretienMap._id} className={'container-basic mgB-m1 ' + getContainerExpirationClass(entretienMap.entretienValidees[0].dateExpiration, entretienMap.entretien.signalerUrgence)}>
                            <div className='formationCard' style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className='currentFormation-container'>
                                    <h4>{entretienMap.entretien.nomEntretien}</h4>
                                    <div className='currentFormation'>
                                        <div className='currentFormation-section'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div className='mgR-m2'>
                                                    <p className='labelFormation'>Dernière validation:</p>
                                                    <p className='labelFormation'>Expire le:</p>
                                                </div>
                                                <div>
                                                    <p>{entretienMap.entretienValidees[0].hasOwnProperty("dateEntretien") && entretienMap.entretienValidees[0].dateEntretien !== null ? format(new Date(entretienMap.entretienValidees[0].dateEntretien), "dd/MM/yyyy") : "aucune"}</p>
                                                    <p>{entretienMap.entretienValidees[0].hasOwnProperty("dateExpiration") && entretienMap.entretienValidees[0].dateExpiration !== null ? format(new Date(entretienMap.entretienValidees[0].dateExpiration), "dd/MM/yyyy") : "jamais"}</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='currentFormation-section'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div className='mgR-m2'>

                                                    <p className='labelFormation'>Document:</p>
                                                </div>
                                                <div>

                                                    {entretienMap.entretienValidees[0].hasOwnProperty("document") && entretienMap.entretienValidees[0].document !== null ?
                                                        <p onClick={() => viewFile(entretienMap.entretienValidees[0].document._id)} className='documentFormation'>{iconExtension(entretienMap.entretienValidees[0].document.file.filename)} {`${entretienMap.entretienValidees[0].document.titre}.${entretienMap.entretienValidees[0].document.file.filename.split(".")[entretienMap.entretienValidees[0].document.file.filename.split(".").length - 1]}`}</p>
                                                        :
                                                        <p>Pas de document</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                                    <button onClick={() => openModalModifierEntretien(
                                        {
                                            dateEntretien: entretienMap.entretienValidees[0].dateEntretien,
                                            dateExpiration: entretienMap.entretienValidees[0].dateExpiration,
                                            idValides: entretienMap.entretienValidees[0]._id,
                                            idLink: entretienMap._id,
                                            nomEntretien: entretienMap.entretien.nomEntretien,
                                            entretienDoc: entretienMap.entretienValidees[0].hasOwnProperty("document") ? entretienMap.entretienValidees[0].document : null
                                        }
                                    )} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", alignSelf: "flex-start" }} className='btn btn-primary'><Icon style={{ width: "2.2rem", height: "2.2rem" }} icon="ic:outline-edit" /><p className="mgL-s1">Éditer</p></button>
                                    <button onClick={() => openModalSupprimerEntretienValiditee(
                                        {
                                            _id: entretienMap._id,
                                            _idEquipement: entretienMap.idEquipement,
                                            validiteId: entretienMap.entretienValidees[0]._id,
                                            date: entretienMap.entretienValidees[0].dateEntretien,
                                            entretienDoc: entretienMap.entretienValidees[0].hasOwnProperty('document') ? entretienMap.entretienValidees[0].document._id : null

                                        })} className='btn btn-primary mgL-s2 supprimerButton'><SupprimerIcon /></button>
                                </div>
                            </div>
                            {
                                entretienMap.entretienValidees.length > 1 &&

                                <div>
                                    <div onClick={() => {
                                        if (entretienMap._id === historiqueOpenId) { setHistoriqueOpenId(null); } else {
                                            setHistoriqueOpenId(entretienMap._id);
                                        }
                                    }} className={`historiqueFormation ${entretienMap._id === historiqueOpenId ? "opened" : ""}`}>
                                        <h5>Historique</h5>
                                        <Icon icon="material-symbols:arrow-forward-ios" />
                                    </div>
                                    <div className='historiqueFormation-tableContainer' style={{ height: entretienMap._id === historiqueOpenId ? `${65 * (entretienMap.entretienValidees.length - 1) + 44}px` : 0 }}>
                                        <SimpleTableRender
                                            columns={getEntretienValideesColumns(

                                                (validiteeId, dateEntre, entretienDoc) => openModalSupprimerEntretienValiditee({ _id: entretienMap._id, validiteId: validiteeId, _idEquipement: entretienMap.idEquipement, date: dateEntre, entretiendDoc: entretienDoc }),
                                                (validiteeEntretien, validiteeExpiration, validiteeId, entretienDoc) => openModalModifierEntretien(
                                                    {
                                                        dateEntretien: validiteeEntretien,
                                                        dateExpiration: validiteeExpiration,
                                                        idValides: validiteeId,
                                                        idLink: entretienMap._id,
                                                        nomEntretien: entretienMap.entretien.nomEntretien,
                                                        entretienDoc: entretienDoc
                                                    }
                                                ),
                                                (docId) => viewFile(docId)
                                            )}
                                            tableData={entretienMap.entretienValidees.slice(1)}
                                            sortBy={[
                                            ]}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    );
                })}

            </div>
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </>
    );
};

export default EntretiensEquipementList;
