import { differenceInDays, differenceInHours, differenceInSeconds, differenceInMinutes, differenceInMonths, differenceInYears } from 'date-fns';
import { Icon } from '@iconify/react';
import customToast from './ToastifyHelper';
import axiosInstance from './axiosInstance';
import fileDownload from 'js-file-download';
import { showError } from './ErrorHelper';

const concentrationFibreRecuperation = 4.9;

export const truncateString = (str, num) => {
    if (str.length > num) {
        let subStr = str.substring(0, num);
        return subStr + "...";
    } else {
        return str;
    }
};

export const maxByPorteeZone = (obectArray) => {
    let maxIndex = Object.keys(obectArray).reduce(function (a, b) { return obectArray[a]['zone']['portee_zone'] > obectArray[b]['zone']['portee_zone'] ? a : b; });
    return obectArray[maxIndex]['zone']['libelle_zone'];
};

export const formatIntTwoDigitString = (numero) => {
    let numeroString = numero.toString();
    if (numeroString.length === 1) {
        numeroString = "0" + numeroString;
    }
    return numeroString;
};

export const groupHoraireBySalarie = (horaireSemaine) => {
    let res = { ouvrier: {}, chefChantier: {}, interimaire: {} };
    for (const jour of horaireSemaine) {
        // TRAVAIL OUVRIER
        for (const travailOuvrier of jour.travail_ouvriers) {
            if (res.ouvrier.hasOwnProperty(travailOuvrier.ouvrier._id._id)) {
                res.ouvrier[travailOuvrier.ouvrier._id._id].horaire.push({ horaireAprem: travailOuvrier.horaireAprem, horaireMat: travailOuvrier.horaireMat });
            }
            else {
                res.ouvrier[travailOuvrier.ouvrier._id._id] = { horaire: [{ horaireAprem: travailOuvrier.horaireAprem, horaireMat: travailOuvrier.horaireMat }], nom: travailOuvrier.ouvrier._id.nom, prenom: travailOuvrier.ouvrier._id.prenom };
            }
        }

        // TRAVAIL CHEF CHANTIER
        for (const travailChefChantier of jour.travail_chefs_chantier) {
            if (res.chefChantier.hasOwnProperty(travailChefChantier.chef_chantier._id._id)) {
                res.chefChantier[travailChefChantier.chef_chantier._id._id].horaire.push({ horaireAprem: travailChefChantier.horaireAprem, horaireMat: travailChefChantier.horaireMat });
            }
            else {
                res.chefChantier[travailChefChantier.chef_chantier._id._id] = { horaire: [{ horaireAprem: travailChefChantier.horaireAprem, horaireMat: travailChefChantier.horaireMat }], nom: travailChefChantier.chef_chantier._id.nom, prenom: travailChefChantier.chef_chantier._id.prenom };
            }
        }

        // TRAVAIL INTERIMAIRES
        for (const travailInterimaire of jour.travail_interimaires) {
            if (res.interimaire.hasOwnProperty(travailInterimaire.interimaire._id._id)) {
                res.interimaire[travailInterimaire.interimaire._id._id].horaire.push({ horaireAprem: travailInterimaire.horaireAprem, horaireMat: travailInterimaire.horaireMat });
            }
            else {
                res.interimaire[travailInterimaire.interimaire._id._id] = { horaire: [{ horaireAprem: travailInterimaire.horaireAprem, horaireMat: travailInterimaire.horaireMat }], nom: travailInterimaire.interimaire._id.nom, prenom: travailInterimaire.interimaire._id.prenom };
            }
        }
    }
    return res;
};

export const getTotalHeure = (horaire) => {
    if (horaire.horaireAprem.heureDeb !== null && horaire.horaireAprem.minuteDeb !== null && horaire.horaireAprem.heureFin !== null && horaire.horaireAprem.minuteFin !== null && horaire.horaireMat.heureDeb !== null && horaire.horaireMat.minuteDeb !== null && horaire.horaireMat.heureFin !== null && horaire.horaireMat.minuteFin !== null) {
        let totalHeureMatin = Math.abs(horaire.horaireMat.heureFin - horaire.horaireMat.heureDeb);
        let totalMinuteMatin = parseInt(horaire.horaireMat.minuteFin - horaire.horaireMat.minuteDeb);
        let totalHeureAprem = Math.abs(horaire.horaireAprem.heureFin - horaire.horaireAprem.heureDeb);
        let totalMinuteAprem = parseInt(horaire.horaireAprem.minuteFin - horaire.horaireAprem.minuteDeb);
        let totalHeure = totalHeureMatin + totalHeureAprem;
        if (totalMinuteMatin < 0) {
            totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
            totalHeure--;
        }
        if (totalMinuteAprem < 0) {
            totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
            totalHeure--;
        }
        let totalMinute = totalMinuteMatin + totalMinuteAprem;
        const quotientMinute = Math.floor(totalMinute / 60);
        if (quotientMinute >= 1) {
            totalHeure += quotientMinute;
            totalMinute = Math.floor(totalMinute % 60);
        }
        return ({ heure: totalHeure, minute: totalMinute });
    }
    else if (horaire.horaireAprem.heureDeb === null && horaire.horaireAprem.minuteDeb === null && horaire.horaireAprem.heureFin === null && horaire.horaireAprem.minuteFin === null && horaire.horaireMat.heureDeb !== null && horaire.horaireMat.minuteDeb !== null && horaire.horaireMat.heureFin !== null && horaire.horaireMat.minuteFin !== null) {
        let totalHeureMatin = Math.abs(horaire.horaireMat.heureFin - horaire.horaireMat.heureDeb);
        let totalMinuteMatin = parseInt(horaire.horaireMat.minuteFin - horaire.horaireMat.minuteDeb);
        let totalHeure = totalHeureMatin;
        if (totalMinuteMatin < 0) {
            totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
            totalHeure--;
        }
        let totalMinute = totalMinuteMatin;
        return ({ heure: totalHeure, minute: totalMinute });
    }
    else if (horaire.horaireAprem.heureDeb !== null && horaire.horaireAprem.minuteDeb !== null && horaire.horaireAprem.heureFin !== null && horaire.horaireAprem.minuteFin !== null && horaire.horaireMat.heureDeb === null && horaire.horaireMat.minuteDeb === null && horaire.horaireMat.heureFin === null && horaire.horaireMat.minuteFin === null) {
        let totalHeureAprem = Math.abs(horaire.horaireAprem.heureFin - horaire.horaireAprem.heureDeb);
        let totalMinuteAprem = parseInt(horaire.horaireAprem.minuteFin - horaire.horaireAprem.minuteDeb);
        let totalHeure = totalHeureAprem;
        if (totalMinuteAprem < 0) {
            totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
            totalHeure--;
        }
        let totalMinute = totalMinuteAprem;
        return ({ heure: totalHeure, minute: totalMinute });
    }
    else {
        return ({ heure: 0, minute: 0 });
    }
};

export const totalHoraireSalarie = (horaireSalarie) => {
    let totalHeure = 0;
    let totalMinute = 0;
    for (const horaire of horaireSalarie) {
        const totalHoraire = getTotalHeure(horaire);
        totalHeure += totalHoraire.heure;
        totalMinute += totalHoraire.minute;
        const quotientMinute = Math.floor(totalMinute / 60);
        if (quotientMinute >= 1) {
            totalHeure += quotientMinute;
            totalMinute = Math.floor(totalMinute % 60);
        }
    }
    return formatIntTwoDigitString(totalHeure) + "h" + formatIntTwoDigitString(totalMinute);
};

export const getChantierByIdAndOptions = (chantierId, optionsChantier) => {
    for (const chantier of optionsChantier) {
        if (chantier.value === chantierId) {
            return chantier.name;
        }
    }
    return null;
};

export const getTotalMinuteAbsence = (absenceSalarie) => {
    const dateDebConvert = new Date(absenceSalarie._id.date_deb_absence);
    const dateFinConvert = new Date(absenceSalarie.date_fin_absence);
    const differenceDateDebFin = differenceInDays(dateFinConvert, dateDebConvert) + 1;
    const oneDayTotalMinute = getTotalHeure({ horaireAprem: absenceSalarie.horaireAbsenceAprem, horaireMat: absenceSalarie.horaireAbsenceMatin });
    return (oneDayTotalMinute.heure * 60 + oneDayTotalMinute.minute) * differenceDateDebFin;
};

export const banqueHeureTotal = (horaireSalarie) => {
    // let totalMinute = 0;
    // let jourDifferent = new Set();
    // for (const horaire of horaireSalarie) {
    //     jourDifferent.add(horaire.annee + "-" + horaire.numeroSemaine)
    //     const totalHoraire = getTotalHeure(horaire.horaire);
    //     totalMinute+=totalHoraire.heure*60;
    //     totalMinute+=totalHoraire.minute;
    // }
    // let totalMinuteNormale = 42 * jourDifferent.size * 60;
    // totalMinute-=totalMinuteNormale;
    // if(totalMinute < 0) {
    //     totalMinute = 0;
    // }
    // let totalMinuteAbsence = 0
    // for (const absence of absenceSalarie) {
    //     if(absence.date_deb_absence !== null && absence.date_fin_absence) {
    //         totalMinuteAbsence += getTotalMinuteAbsence(absence)
    //     }
    // }
    // totalMinute-=totalMinuteAbsence
    // let heure = Math.floor(Math.abs(totalMinute) / 60);
    // let minute = Math.abs(totalMinute) % 60;
    // return {heure: heure, minute: minute, neg: totalMinute < 0};
    if (horaireSalarie === undefined) {
        return { heure: 0, minute: 0 };
    }
    let totalMinute = 0;
    for (const totalSemaine of horaireSalarie) {
        if (totalSemaine.editeur !== null) {
            totalMinute += totalSemaine.total.heure * 60;
            totalMinute += totalSemaine.total.minute;
        }
        else {
            if (totalSemaine.total.heure > 42 || (totalSemaine.total.heure === 42 && totalSemaine.total.minute > 0)) {
                totalMinute += (totalSemaine.total.heure - 42) * 60;
                totalMinute += totalSemaine.total.minute;
            }
        }
    }
    let heure = Math.floor(Math.abs(totalMinute) / 60);
    let minute = Math.abs(totalMinute) % 60;
    let val = "nul";
    if (totalMinute < 0) {
        val = "neg";
    }
    if (totalMinute > 0) {
        val = "pos";
    }
    return { heure: heure, minute: minute, val: val };
};

export const getHeureSup = (horaire) => {
    if (horaire.heure > 42 || (horaire.heure === 42 && horaire.minute > 0)) {
        return { heure: horaire.heure - 42, minute: horaire.minute };
    }
    else {
        return { heure: 0, minute: 0 };
    }
};

export const getTotalHeureAbsence = (absenceSalarie) => {
    const dateDebConvert = new Date(absenceSalarie._id.date_deb_absence);
    const dateFinConvert = new Date(absenceSalarie.date_fin_absence);
    const differenceDateDebFin = differenceInDays(dateFinConvert, dateDebConvert) + 1;
    const oneDayTotalMinute = getTotalHeure({ horaireAprem: absenceSalarie.horaireAbsenceAprem, horaireMat: absenceSalarie.horaireAbsenceMatin });
    let totalHeure = oneDayTotalMinute.heure * differenceDateDebFin;
    let totalMinute = oneDayTotalMinute.minute * differenceDateDebFin;
    const quotientMinute = Math.floor(totalMinute / 60);
    if (quotientMinute >= 1) {
        totalHeure += quotientMinute;
        totalMinute = Math.floor(totalMinute % 60);
    }
    return formatIntTwoDigitString(totalHeure) + "h" + formatIntTwoDigitString(totalMinute);
};


export const sortHoraireBySemaine = (horaires) => {
    let absences = [];
    let travails = {};
    for (const horaire of horaires) {
        if (horaire.type === "absence") {
            absences.push(horaire);
        }
        else {
            const key = horaire.travail.annee + "-" + horaire.travail.numeroSemaine;
            if (key in travails) {
                travails[key].push(horaire);
            }
            else {
                travails[key] = [horaire];
            }
        }
    }
    let result = [...absences];
    for (var horaireKey in travails) {
        result.push({ type: "travail", id: horaireKey, travails: travails[horaireKey] });
    }
    return result;
};

export const getTotalHeureSemaine = (horaireSemaine) => {
    let totalMinute = 0;
    let jourDifferent = new Set();
    for (const horaire of horaireSemaine) {
        jourDifferent.add(horaire.travail.annee + "-" + horaire.travail.numeroSemaine);
        const totalHoraire = getTotalHeure(horaire.travail.horaire);
        totalMinute += totalHoraire.heure * 60;
        totalMinute += totalHoraire.minute;
    }
    let totalMinuteNormale = 42 * jourDifferent.size * 60;
    totalMinute -= totalMinuteNormale;
    if (totalMinute < 0) {
        return { heure: 0, minute: 0 };
    }
    else {
        return { heure: Math.floor(totalMinute / 60), minute: Math.floor(totalMinute % 60) };
    }
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Octet';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.4rem',
    borderWidth: 2,
    borderRadius: ".5rem",
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: "1.4rem"
};

export const focusedStyle = {
    borderColor: '#1E1E1E'
};

export const acceptStyle = {
    borderColor: '#00e676'
};

export const rejectStyle = {
    borderColor: '#ff1744'
};

export const iconExtension = (filename) => {
    const fileExtension = filename.split(".").pop().toLowerCase();
    const imageExtensionList = ["svg", "png", "jpeg", "jpg", "ico", "gif", "bmp", "webp"];
    if (imageExtensionList.includes(fileExtension)) {
        return <Icon className='iconFileSvg imageIcon' icon="fa6-solid:file-image" />;
    }
    else if (fileExtension === "xls" || fileExtension === "xlsx") {
        return <Icon className='iconFileSvg excelIcon' icon="fa-solid:file-excel" />;
    }
    else if (fileExtension === "csv") {
        return <Icon className='iconFileSvg csvIcon' icon="fa-solid:file-csv" />;
    }
    else if (fileExtension === "pdf") {
        return <Icon className='iconFileSvg pdfIcon' icon="fa6-solid:file-pdf" />;
    }
    else {
        return <Icon className='iconFileSvg unknownIcon' icon="ant-design:file-unknown-filled" />;
    }
};

export const parseISOString = (s) => {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
};

export const getLastModified = (lastModifiedDate) => {
    const currentDate = new Date();
    const modifiedDate = new Date(lastModifiedDate);
    const differenceYear = differenceInYears(currentDate, modifiedDate);
    if (differenceYear) {
        return `${differenceYear} ans`;
    }
    const differenceMonth = differenceInMonths(currentDate, modifiedDate);
    if (differenceMonth) {
        return `${differenceMonth} mois`;
    }
    const differenceDay = differenceInDays(currentDate, modifiedDate);
    if (differenceDay) {
        return `${differenceDay} jour${differenceDay > 1 ? "s" : ""}`;
    }
    const differenceHeure = differenceInHours(currentDate, modifiedDate);
    if (differenceHeure) {
        return `${differenceHeure} heure${differenceHeure > 1 ? "s" : ""}`;
    }
    const differenceMinute = differenceInMinutes(currentDate, modifiedDate);
    if (differenceMinute) {
        return `${differenceMinute} minute${differenceMinute > 1 ? "s" : ""}`;
    }
    const differenceSeconde = differenceInSeconds(currentDate, modifiedDate);
    if (differenceSeconde) {
        return `${differenceSeconde} seconde${differenceSeconde > 1 ? "s" : ""}`;
    }
    return "0 s";
};

export const compareTotalHeure = (rowA, rowB, id, desc) => {
    console.log(rowA.values[id]);
    return 0;
};

export const downloadFile = (documentId, filename) => {
    axiosInstance.get(`uploads/documents/${documentId}`, {
        responseType: 'blob',
    }).then((result) => {
        fileDownload(result.data, filename);
    }).catch((err) => {
        showError(err);
    });
};

export const isOuvrierInVacation = (ouvrierEntreeSortieZone) => {
    if (ouvrierEntreeSortieZone.entreeSortie.length && ouvrierEntreeSortieZone.entreeSortie[ouvrierEntreeSortieZone.entreeSortie.length - 1].operations.length) {
        const lastOperation = ouvrierEntreeSortieZone.entreeSortie[ouvrierEntreeSortieZone.entreeSortie.length - 1].operations[ouvrierEntreeSortieZone.entreeSortie[ouvrierEntreeSortieZone.entreeSortie.length - 1].operations.length - 1];
        if (lastOperation.dateHeureSortie === null) {
            return true;
        }
    }
    return false;
};

export const salarieSontEnVacations = (entreeSorties) => {
    for (const entreeSortie of entreeSorties) {
        if (entreeSortie.signatureSalarie === null && isOuvrierInVacation(entreeSortie)) {
            return true;
        }
    }
    return false;
};

export const salarieSontEnAttente = (entreeSorties) => {
    for (const entreeSortie of entreeSorties) {
        if (entreeSortie.signatureSalarie === null && !isOuvrierInVacation(entreeSortie)) {
            return true;
        }
    }
    return false;
};

export const salarieATerminer = (entreeSorties) => {
    for (const entreeSortie of entreeSorties) {
        if (entreeSortie.signatureSalarie !== null) {
            return true;
        }
    }
    return false;
};

const isNumber = (char) => {
    return /^\d$/.test(char) || char === " ";
};

export const capitalizeFirstLetter = (string) => {
    let startString = "";
    for (let i = 0; i < string.length; i++) {
        if (!isNumber(string[i])) {
            return startString + string[i].toUpperCase() + string.slice(i + 1).toLowerCase();
        }
        else {
            startString += string[i];
        }
    }
    return startString;
};

export const calculateExpositionTotalDuration = (entreeSortieList) => {
    let totalDiffInSeconds = 0;
    for (const entreeSortie of entreeSortieList) {
        if (entreeSortie.operations.length) {
            const dateHeureDebut = new Date(entreeSortie.operations[0].dateHeureDebut);
            const dateHeureSortie = new Date(entreeSortie.operations[entreeSortie.operations.length - 1].dateHeureSortie);
            const diffInSeconds = Math.abs((dateHeureSortie.getTime() / 1000) - (dateHeureDebut.getTime() / 1000));
            totalDiffInSeconds += diffInSeconds;
        }
    }
    return new Date(totalDiffInSeconds * 1000).toISOString().substring(11, 16);
};

export const convertHourAndMinutesToHours = (hoursAndMinutes) => {
    return parseFloat(`${hoursAndMinutes.substring(0, 2)}.${Math.floor(parseFloat("0." + hoursAndMinutes.substring(3, 5)) * 60)}`);
};

const getConcentrationProcessus = (processus) => {
    // console.log(JSON.stringify(processus));
    // prendre le bon cycle d'analyse en regardant le chantier en cours
    if (processus.validationProcessus.length) {
        const mostRecentValidation = processus.validationProcessus.reduce((prev, curr) => {
            // Find the maximum date among the three dates
            const maxDateValidation = Object.values(curr).reduce((prev, curr) => {
                if (prev === null) return curr;
                if (curr === null || !curr.dateValidation) return prev;
                return (prev.dateValidation > curr.dateValidation) ? prev : curr;
            }, null);

            // If the current object has a date that is greater than the previous object's maximum date, return the current object
            if (maxDateValidation.dateValidation > Object.values(prev).reduce((prev, curr) => {
                if (prev === null) return curr;
                if (curr === null || !curr.dateValidation) return prev;
                return (prev.dateValidation > curr.dateValidation) ? prev : curr;
            }, null).dateValidation) {
                return curr;
            } else {
                return prev;
            }
        });

        if (mostRecentValidation.firstValidation !== null || mostRecentValidation.secondValidation !== null || mostRecentValidation.thirdValidation !== null || mostRecentValidation.validationTest !== null) {
            const maxConcentration = Object.values(mostRecentValidation).reduce((prev, curr) => {
                if (prev === null || !prev.concentrationFibre || !prev.concentrationFibre.rc) return curr;
                if (curr === null || !curr.concentrationFibre || !curr.concentrationFibre.rc) return prev;
                return (prev.concentrationFibre.rc > curr.concentrationFibre.rc) ? prev : curr;
            }, null).concentrationFibre;
            return maxConcentration;
        }
        else {
            if (processus.hasOwnProperty("estimationConcentration")) {
                return { rc: processus.estimationConcentration, f: null };
            }
            return { rc: 0, f: null };
        }
    }
    else {
        if (processus.hasOwnProperty("estimationConcentration")) {
            return { rc: processus.estimationConcentration, f: null };
        }
        return { rc: 0, f: null };
    }
};

export const getEstimationVlep = (entreeSortie, currentTime) => {
    let totalVacationsTerminer = 0;
    // if (recuperationAmiante !== null && recuperationAmiante.length) {
    //     for (const recuperation of recuperationAmiante) {
    //         if (recuperation.dateHeureSortie !== null) {
    //             const diffInSeconds = (new Date(recuperation.dateHeureDebut).getTime() - new Date(recuperation.dateHeureSortie).getTime()) / 1000;
    //             let differenceInMinutes = Math.abs(Math.round(diffInSeconds / 60))
    //             totalVacationsTerminer += differenceInMinutes * ((4.9) / 1)
    //         }
    //     }
    // }
    for (const vacation of entreeSortie) {
        if (vacation.recuperationAmiante.recuperation) {
            const dateSortieRecuperation = vacation.recuperationAmiante.dateHeureSortie !== null ? new Date(vacation.recuperationAmiante.dateHeureSortie) : new Date();
            const diffInSeconds = (new Date(vacation.operations[vacation.operations.length - 1].dateHeureSortie).getTime() - dateSortieRecuperation.getTime()) / 1000;
            // console.log({dateDebut: new Date(vacation.operations[vacation.operations.length - 1].dateHeureSortie), dateSortie: dateSortieRecuperation});
            let differenceInMinutes = Math.abs(Math.round(diffInSeconds / 60));
            totalVacationsTerminer += differenceInMinutes * ((concentrationFibreRecuperation) / 1);
        }
        let fpaProtection = 0;
        for (const equipementProtection of vacation.equipements) {
            fpaProtection += equipementProtection.fpa;
        }
        if (fpaProtection <= 0) {
            fpaProtection = 1;
        }
        for (const operation of vacation.operations) {
            if (operation.dateHeureSortie !== null) {
                //operation terminer
                const diffInSeconds = (new Date(operation.dateHeureDebut).getTime() - new Date(operation.dateHeureSortie).getTime()) / 1000;
                let differenceInMinutes = Math.abs(Math.round(diffInSeconds / 60));
                // const diffInSeconds = Math.abs((new Date(operation.dateHeureDebut).getTime() / 1000) - (new Date(operation.dateHeureSortie).getTime() / 1000));
                // const hourAndMinutes = new Date(diffInSeconds * 1000).toISOString().substring(11, 16);
                let concentrationProcessus = getConcentrationProcessus(operation.typeProcessus);
                if (concentrationProcessus.f === null || concentrationProcessus.f > 4) {
                    totalVacationsTerminer += differenceInMinutes * (concentrationProcessus.rc / fpaProtection);
                }
                else {
                    totalVacationsTerminer += differenceInMinutes * ((concentrationProcessus.rc / 2) / fpaProtection);
                }
            }
            else {
                // const diffInSeconds = Math.abs((new Date(operation.dateHeureDebut).getTime() / 1000) - (currentTime.getTime() / 1000));
                const diffInSeconds = (new Date(operation.dateHeureDebut).getTime() - new Date(currentTime).getTime()) / 1000;
                let differenceInMinutes = Math.abs(Math.round(diffInSeconds / 60));
                // const hourAndMinutes = new Date(diffInSeconds * 1000).toISOString().substring(11, 16);
                // totalVacationsTerminer += convertHourAndMinutesToHours(hourAndMinutes) * (getConcentrationProcessus(operation.typeProcessus) / fpaProtection)
                let concentrationProcessus = getConcentrationProcessus(operation.typeProcessus);
                if (concentrationProcessus.f === null || concentrationProcessus.f > 4) {
                    totalVacationsTerminer += differenceInMinutes * (concentrationProcessus.rc / fpaProtection);
                }
                else {
                    totalVacationsTerminer += differenceInMinutes * ((concentrationProcessus.rc / 2) / fpaProtection);
                }
            }
        }
    }
    let vlepEstimee = (totalVacationsTerminer / 480).toFixed(2);
    return vlepEstimee;
};

export const calculateFinishedExpositionDuration = (entreeSortie) => {
    const dateHeureDebut = new Date(entreeSortie.operations[0].dateHeureDebut);
    const dateHeureSortie = new Date(entreeSortie.operations[entreeSortie.operations.length - 1].dateHeureSortie);
    const diffInSeconds = Math.abs((dateHeureSortie.getTime() / 1000) - (dateHeureDebut.getTime() / 1000));
    return new Date(diffInSeconds * 1000).toISOString().substring(11, 16);
};

export const getLocalISOString = (date) => {
    // Get local time as ISO string with offset at the end
    var tzo = -date.getTimezoneOffset();
    var dif = tzo >= 0 ? '+' : '-';
    var pad = function (n, width) {
        width = width || 2;
        n = Math.abs(Math.floor(n)) + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    };
    return date.getFullYear()
        + '-' + pad(date.getMonth() + 1)
        + '-' + pad(date.getDate())
        + 'T' + pad(date.getHours())
        + ':' + pad(date.getMinutes())
        + ':' + pad(date.getSeconds())
        + '.' + pad(date.getMilliseconds(), 3)
        + dif + pad(tzo / 60)
        + ':' + pad(tzo % 60);
};

export const dateToHoursAndMinutesString = (date) => {
    if (!(date instanceof Date)) date = new Date(date);
    return getLocalISOString(date).substring(11, 16);
};

export const calculatePauseDuration = (dateHeureSortie, dateHeureDebutNouvelleVacation) => {
    if (!(dateHeureSortie instanceof Date)) dateHeureSortie = new Date(dateHeureSortie);
    if (!(dateHeureDebutNouvelleVacation instanceof Date)) dateHeureDebutNouvelleVacation = new Date(dateHeureDebutNouvelleVacation);
    const diffInSeconds = Math.abs((dateHeureDebutNouvelleVacation.getTime() / 1000) - (dateHeureSortie.getTime() / 1000));
    return new Date(diffInSeconds * 1000).toISOString().substring(11, 16);
};

export const calculateExpositionDuration = (dateDebutVacation, currentTime) => {
    const diffInSeconds = Math.abs((currentTime.getTime() / 1000) - (dateDebutVacation.getTime() / 1000));
    return new Date(diffInSeconds * 1000).toISOString().substring(11, 19);
};

export const calculateExpositionRemainingTime = (dateDebutVacation, currentTime, expositionDuration) => {
    const currentDurationTimeInSeconds = Math.abs((currentTime.getTime() / 1000) - (dateDebutVacation.getTime() / 1000));
    const remainingTimeInSeconds = expositionDuration - currentDurationTimeInSeconds;
    if (remainingTimeInSeconds < 0) {
        return <p style={{ color: "#D13852" }}>+{new Date(Math.abs(remainingTimeInSeconds) * 1000).toISOString().substring(11, 19)}</p>;
    }
    else {
        return <p style={{ color: "#1E1E1E" }}>-{new Date(remainingTimeInSeconds * 1000).toISOString().substring(11, 19)}</p>;
    }
};

const getDureeMaxVacationInt = (dureeMaxVacation, temperature) => {
    if (temperature <= 20) {
        return Math.min(dureeMaxVacation, 150);
    }
    else if (temperature <= 25) {
        return Math.min(dureeMaxVacation, 130);
    }
    else if (temperature <= 30) {
        return Math.min(dureeMaxVacation, 80);
    }
    else if (temperature <= 35) {
        return Math.min(dureeMaxVacation, 49);
    }
    else if (temperature <= 40) {
        return Math.min(dureeMaxVacation, 30);
    }
    else {
        return Math.min(dureeMaxVacation, 18);
    }
};

const formatMaxHourToSecond = (dureeMax) => {
    let hours = Math.trunc(dureeMax);
    let minutes = dureeMax.toString().split(".")[1];
    if (minutes) {
        minutes = Math.round(parseFloat("0." + minutes) * 60);
    }
    else {
        minutes = 0;
    }
    return (hours * 60 * 60) + (minutes * 60);
};

export const getDureeMaxVacationEnCours = (entreeSortie) => {
    let totalVacationsTerminer = 0;
    let currentEpiProtection;
    let currentProcessusConcentration;
    let currentTemperature;
    for (const vacation of entreeSortie) {
        if (vacation.recuperationAmiante.recuperation) {
            const dateSortieRecuperation = vacation.recuperationAmiante.dateHeureSortie !== null ? new Date(vacation.recuperationAmiante.dateHeureSortie) : new Date();
            const diffInSeconds = (new Date(vacation.operations[vacation.operations.length - 1].dateHeureSortie).getTime() - dateSortieRecuperation.getTime()) / 1000;
            let differenceInMinutes = Math.abs(Math.round(diffInSeconds / 60));
            totalVacationsTerminer += differenceInMinutes * ((concentrationFibreRecuperation) / 1);
        }
        let fpaProtection = 0;
        for (const equipementProtection of vacation.equipements) {
            fpaProtection += equipementProtection.fpa;
        }
        if (fpaProtection <= 0) {
            fpaProtection = 1;
        }
        for (const operation of vacation.operations) {
            if (operation.dateHeureSortie !== null) {
                //operation terminer
                const diffInSeconds = Math.abs((new Date(operation.dateHeureDebut).getTime() - new Date(operation.dateHeureSortie).getTime()) / 1000);
                let differenceInMinutes = Math.abs(Math.round(diffInSeconds / 60));
                let concentrationProcessus = getConcentrationProcessus(operation.typeProcessus);
                if (concentrationProcessus.f === null || concentrationProcessus.f > 4) {
                    totalVacationsTerminer += differenceInMinutes * (concentrationProcessus.rc / fpaProtection);
                }
                else {
                    totalVacationsTerminer += differenceInMinutes * ((concentrationProcessus.rc / 2) / fpaProtection);
                }
            }
            else {
                currentEpiProtection = fpaProtection;
                currentProcessusConcentration = getConcentrationProcessus(operation.typeProcessus);
                currentTemperature = vacation.temperature;
            }
        }
    }
    let totalAllVacation;
    if (currentProcessusConcentration.f === null || currentProcessusConcentration.f > 4) {
        totalAllVacation = totalVacationsTerminer + (currentProcessusConcentration.rc / currentEpiProtection);
    }
    else {
        totalAllVacation = totalVacationsTerminer + ((currentProcessusConcentration.rc / 2) / currentEpiProtection);
    }
    // console.log(4800 / totalAllVacation);
    let dureeMaxVacation = (4800 / totalAllVacation).toFixed(2);
    if (dureeMaxVacation === "0.00") {
        return 0;
    }
    else {
        let resMaxVacation = getDureeMaxVacationInt(parseFloat(dureeMaxVacation), currentTemperature);
        return resMaxVacation * 60;
    }
};

export const calculateProgress = (dateDebutVacation, currentTime, expositionDuration) => {
    const currentDurationTimeInSeconds = Math.abs((currentTime.getTime() / 1000) - (dateDebutVacation.getTime() / 1000));
    const remainingTimeInSeconds = expositionDuration - currentDurationTimeInSeconds;
    if (remainingTimeInSeconds < 0) {
        return 100;
    }
    else {
        return parseFloat((currentDurationTimeInSeconds / expositionDuration).toFixed(2)) * 100;
    }
};

export const formatTechniqueModeOperatoire = (techniqueModeOperatoire) => {
    if (techniqueModeOperatoire.categorie !== "") {
        if (techniqueModeOperatoire.methode !== "") {
            if (techniqueModeOperatoire.outil !== "") {
                return `${techniqueModeOperatoire.categorie}, ${techniqueModeOperatoire.methode}, ${techniqueModeOperatoire.outil}`;
            } else {
                return `${techniqueModeOperatoire.categorie}, ${techniqueModeOperatoire.methode}`;
            }
        } else {
            if (techniqueModeOperatoire.outil !== "") {
                return `${techniqueModeOperatoire.categorie}, ${techniqueModeOperatoire.outil}`;
            } else {
                return `${techniqueModeOperatoire.categorie}`;
            }
        }
    } else {
        if (techniqueModeOperatoire.methode !== "") {
            if (techniqueModeOperatoire.outil !== "") {
                return `${techniqueModeOperatoire.methode}, ${techniqueModeOperatoire.outil}`;
            } else {
                return `${techniqueModeOperatoire.methode}`;
            }
        } else {
            if (techniqueModeOperatoire.outil !== "") {
                return `${techniqueModeOperatoire.outil}`;
            } else {
                return "";
            }
        }
    }
};

export const formatMateriaux = (materiaux) => {
    if (materiaux.categorie !== "") {
        if (materiaux.materiaux !== "") {
            return `${materiaux.categorie}, ${materiaux.materiaux}`;
        } else {
            return materiaux.categorie;
        }
    } else {
        return materiaux.materiaux;
    }
};

export const advancedSearch = (str1, str2) => {
    if (!str1 || !str2) {
        return false;
    }
    // Remove accents from both strings
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
    str1 = removeAccents(str1);
    str2 = removeAccents(str2);

    // Convert both strings to lowercase
    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    // Use the includes method to check if str1 contains str2
    return str2.includes(str1) || str1.includes(str2);
};

export const getSecteursActivite = () => {
    return [
        "Travaux publics",
        "Gros oeuvres",
        "3D",
        "Transport",
        "Atelier"
    ];
};

export const getInsensitiveWorld = (world) => {
    return world.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
};

const convertDDtoDMS = (coord) => {
    let absoluteCoord = Math.abs(coord);
    let degrees = Math.floor(absoluteCoord);
    let minutes = Math.floor((absoluteCoord - degrees) * 60);
    let seconds = ((absoluteCoord - degrees - minutes / 60) * 3600).toFixed(1);

    let direction = coord >= 0 ? "N" : "W";

    return degrees + "°" + minutes + "'" + seconds + "\"" + direction;
};

export const getCoordinatesFromDD = (latitude, longitude) => {
    return `${convertDDtoDMS(latitude)} ${convertDDtoDMS(longitude)}`;
};

const convertDMSToDD = (coord) => {
    var parts = coord.split(/[^\d\w\.]+/);

    var degrees = parseInt(parts[0]);
    var minutes = parseInt(parts[1]);
    var seconds = parseFloat(parts[2]);
    var direction = parts[3];

    var dd = degrees + (minutes / 60) + (seconds / 3600);

    if (direction === "S" || direction === "W") {
        dd = -dd;
    }

    return dd;
};

export const getCoordinatesFromDMS = (dmsCoordinates) => {
    return {
        latitude: convertDMSToDD(dmsCoordinates.split(" ")[0]),
        longitude: convertDMSToDD(dmsCoordinates.split(" ")[1]),
    };
};

export const arrayDifference = (a, b) => {
    return a.filter(function (x) { return b.indexOf(x) < 0; });
};

export const getNumeroTransportType = (typeTransport) => {
    if (typeTransport === "DemandeTransportAmpiroll") {
        return "AM";
    }
    else if (typeTransport === "DemandeTransportCamion") {
        return "CA";
    }
    else {
        return "PE";
    }
};

export const getNumeroTransportDemande = (typeDemande) => {
    if (typeDemande === "Pose") {
        return "P";
    }
    else if (typeDemande === "Évacuation") {
        return "E";
    }
    else if (typeDemande === "Livraison") {
        return "L";
    }
    else {
        return "T";
    }
};

export const formatGpsString = (gps) => {
    // Split the string into an array
    const gpsArray = gps.split(',').map(coord => parseFloat(coord.trim()));
    return gpsArray;
};

export const isValidDate = (dateString) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
};

export const joinValuesWithSeparator = (list, separator) => {
    // Filter out null and undefined values, and convert non-null values to strings
    const filteredList = list.map(value => (value !== null && value !== undefined) ? String(value) : '');

    // Filter out empty strings after conversion
    const nonEmptyList = filteredList.filter(value => value !== '');

    // Join the non-empty values with the separator
    const resultString = nonEmptyList.join(separator);

    return resultString;
};

export const getAllUserType = () => {
    return [
        "Ouvrier",
        "Opérateur",
        "Encadrant de chantier",
        "Encadrant technique",
        "Intérimaire",
        "Administration",
        "Chef de chantier",
        "Chauffeur",
        "Conducteur de travaux",
        "Dispatcher"
    ];
};

export const removeFileExtension = (filename) => {
    // Use a regular expression to match the first dot in the filename
    const match = filename.match(/^([^.]+)\..+$/);

    // If a match is found, return the part before the first dot; otherwise, return the original filename
    return match ? match[1] : filename;
};

export const normalizeTime = (timeObject) => {
    // Extract hours and minutes from the timeObject
    let { heure, minute } = timeObject;

    // Convert minutes to hours and add to the original hours
    heure += Math.floor(minute / 60);

    // Normalize minutes (keep them within the range 0-59)
    minute = minute % 60;

    // Return the normalized time object
    return { heure, minute };
};

export const getHeureParSalarie = (travailOuvrier, travailInterimaire, travailChefChantier) => {
    let allSalarieHeure = {};
    for (const travail of travailOuvrier) {
        const totalHeureSalarie = getTotalHeure(travail);
        if (allSalarieHeure.hasOwnProperty(travail.ouvrier._id._id)) {
            allSalarieHeure[travail.ouvrier._id._id] = {
                ...allSalarieHeure[travail.ouvrier._id._id],
                heure: allSalarieHeure[travail.ouvrier._id._id].heure + totalHeureSalarie.heure,
                minute: allSalarieHeure[travail.ouvrier._id._id].minute + totalHeureSalarie.minute,
            };
        }
        else {
            allSalarieHeure[travail.ouvrier._id._id] = {
                nom: travail.ouvrier._id.nom,
                prenom: travail.ouvrier._id.prenom,
                matricule: travail.ouvrier._id.login_matricule,
                heure: totalHeureSalarie.heure,
                minute: totalHeureSalarie.minute
            };
        }
    }
    for (const travail of travailInterimaire) {
        const totalHeureSalarie = getTotalHeure(travail);
        if (allSalarieHeure.hasOwnProperty(travail.interimaire._id._id)) {
            allSalarieHeure[travail.interimaire._id._id] = {
                ...allSalarieHeure[travail.interimaire._id._id],
                heure: allSalarieHeure[travail.interimaire._id._id].heure + totalHeureSalarie.heure,
                minute: allSalarieHeure[travail.interimaire._id._id].minute + totalHeureSalarie.minute,
            };
        }
        else {
            allSalarieHeure[travail.interimaire._id._id] = {
                nom: travail.interimaire._id.nom,
                prenom: travail.interimaire._id.prenom,
                matricule: travail.interimaire._id.login_matricule,
                heure: totalHeureSalarie.heure,
                minute: totalHeureSalarie.minute
            };
        }
    }
    for (const travail of travailChefChantier) {
        const totalHeureSalarie = getTotalHeure(travail);
        if (allSalarieHeure.hasOwnProperty(travail.chef_chantier._id._id)) {
            allSalarieHeure[travail.chef_chantier._id._id] = {
                ...allSalarieHeure[travail.chef_chantier._id._id],
                heure: allSalarieHeure[travail.chef_chantier._id._id].heure + totalHeureSalarie.heure,
                minute: allSalarieHeure[travail.chef_chantier._id._id].minute + totalHeureSalarie.minute,
            };
        }
        else {
            allSalarieHeure[travail.chef_chantier._id._id] = {
                nom: travail.chef_chantier._id.nom,
                prenom: travail.chef_chantier._id.prenom,
                matricule: travail.chef_chantier._id.login_matricule,
                heure: totalHeureSalarie.heure,
                minute: totalHeureSalarie.minute
            };
        }
    }
    return allSalarieHeure;
};

export const typeVehicule = [
    { label: "19t", value: "19t" },
    { label: "8x4", value: "8x4" },
    { label: "6x4", value: "6x4" },
    { label: "Ampiroll", value: "Ampiroll" },
    { label: "Pata", value: "Pata" },
    { label: "Semi", value: "Semi" }
];