import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosInstance from '../../utils/axiosInstance';
import DatePicker from 'react-date-picker';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';

const addControle = (controle) => (actionCreators.addControle(controle));

const AjouterControleForm = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const dispatch = useDispatch();
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onSubmit = (data) => {
        setButtonDisabled(true);
        const date = new Date(data.date_controle.toString());
        date.setHours(0, 0, 0, 0);
        axiosInstance.patch('controleMateriel/addControle/' + props.materielId, { historique_controle: date.toISOString() })
            .then((result) => {
                customToast.success("Le controle a été ajouté avec succès");
                props.addControleMateriel(date.toISOString());
                close();
            }).catch((err) => {
                setButtonDisabled(false);
                showError(err);
            });
        setButtonDisabled(false);
        close();

    };


    // validationschema
    const schema = yup.object().shape({
        date_controle: yup.date().required("Veuillez selectionner une date").test(dateString => new Date(dateString).toString() !== 'Invalid Date')
    });
    const { register, handleSubmit, formState: { errors }, control } = useForm({
        defaultValues: { date_controle: new Date() },
        resolver: yupResolver(schema)
    });


    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Ajouter un controle</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form" onSubmit={handleSubmit(onSubmit)}>
                <label className='ajouter-form-label default-label' htmlFor="dateControle">Date du controle</label>
                <Controller control={control} name='date_controle' render={({ field: { value = new Date(), onBlur, onChange, name }, formState }) => (<DatePicker value={value} onChange={onChange} className="input-datePicker" onBlur={onBlur} name={name} />)} />
                {errors['date_controle'] && errors['date_controle'].type === "required" && <p className="login-error-message">Veuillez saisir un nom pour le suivi ou la formation</p>}
                <input disabled={isButtonDisabled} className="validate-button-fw" type="submit" value="Ajouter" />
            </form>
        </div>);
};

export default AjouterControleForm;