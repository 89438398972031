import { format } from "date-fns";
import React, { useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import customToast from "../../../utils/ToastifyHelper";
import { showError } from "../../../utils/ErrorHelper";

const CopierVersJourV2 = (props) => {

    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onValiderSuppression = () => {
        setButtonDisabled(true);
        const postData = { date_jour_dupliquer: props.jourData.date_jour_dupliquer, date_jour_destination: props.jourData.date_jour_destination };
        if (props.saisiePar) {
            postData.saisiePar = props.saisiePar._id;
            axiosInstance.patch('/releve-heure-v2/dupliquerJourTravailSalarie', postData).then((result) => {
                customToast.success("Copie des horaires effectuées, n'oubliez pas de compléter les horaires copiées.");
                setButtonDisabled(false);
                props.copierVers({ ...props.jourData, travailsDupliquer: result.data });
                close();
            }).catch((err) => {
                setButtonDisabled(false);
                showError(err);
            });
        }
        else {
            axiosInstance.patch('/releve-heure-v2/dupliquerJourTravailSalarie/me', postData).then((result) => {
                customToast.success("Copie des horaires effectuées, n'oubliez pas de compléter les horaires copiées.");
                setButtonDisabled(false);
                props.copierVers({ ...props.jourData, travailsDupliquer: result.data });
                close();
            }).catch((err) => {
                setButtonDisabled(false);
                showError(err);
            });
        }
    };

    return (
        <div className="modal-content supprimerForm" style={{ display: "flex", flexDirection: "column" }}>
            <h1 className='modal-title'>Copier les horaires du {format(new Date(props.jourData.date_jour_dupliquer), "dd/MM/yyyy")} vers le {format(new Date(props.jourData.date_jour_destination), "dd/MM/yyyy")} </h1>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <hr />
            <p className="modal-confirmation-text">Appuyer sur valider pour confirmer la copie des horaires. Attention la copie <span className="suppression-mot">remplace</span> toutes les données saisie le {format(new Date(props.jourData.date_jour_destination), "dd/MM/yyyy")}</p>
            <div className="button-container" style={{ flex: 1, alignItems: "flex-end" }} >
                <button disabled={isButtonDisabled} className='btn btn-secondary fw medium-btn mgR-m1' onClick={() => close()}>Annuler</button>
                <button disabled={isButtonDisabled} className='btn btn-primary fw medium-btn' onClick={() => onValiderSuppression()}>Valider</button>
            </div>
        </div>
    );
};

export default CopierVersJourV2;
