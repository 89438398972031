import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AjouterDemandeAmpirolle from './AjouterDemandeAmpirolle';
import AjouterDemandeCamion from './AjouterDemandeCamion';
import AjouterDemandePorteEngin from './AjouterDemandePorteEngin';

const AjouterDemandeModal = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [typeDemandeSelectionner, setTypeDemandeSelectionner] = useState({ typeDemande: null, typeLivraison: null });

    return (
        <div className="modal-content ajouter-form">
            <h3 style={{ marginBottom: 0 }} className='modal-title'>Ajouter une demande</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                {typeDemandeSelectionner.typeDemande === null &&
                    <div className='mgT-m3' style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <p style={{ fontSize: "2rem" }} className='form-label mgB-b1'>Sélectionner le type de demande à ajouter</p>
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "4rem" }}>
                            <div className='btn-secondary' style={{
                                width: "100%",
                                border: "1px solid",
                                padding: "0 1.4rem"
                            }}>
                                <p className='form-label mgB-s2 mgT-s2'>Ampirolle</p>
                                <button type='button' className='btn btn-primary fw mgR-m1 medium-btn mgB-s2 selectionDemandeButton' onClick={() => setTypeDemandeSelectionner({ typeDemande: "Ampirolle", typeLivraison: "Pose" })}>
                                    <Icon icon="mdi:tow-truck" />
                                    <p>Pose</p>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                                <button type='button' className='btn btn-primary fw mgR-m1 medium-btn mgB-m1 selectionDemandeButton' onClick={() => setTypeDemandeSelectionner({ typeDemande: "Ampirolle", typeLivraison: "Évacuation" })}>
                                    <Icon icon="mdi:tow-truck" style={{ transform: "rotateY(180deg)" }} />
                                    <p>Évacuation</p>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                            <div className='btn-secondary' style={{
                                width: "100%",
                                border: "1px solid",
                                padding: "0 1.4rem"
                            }}>
                                <p className='form-label mgB-s2 mgT-s2'>Camion</p>
                                <button type='button' className='btn btn-primary fw mgR-m1 medium-btn mgB-s2 selectionDemandeButton' onClick={() => setTypeDemandeSelectionner({ typeDemande: "Camion", typeLivraison: "Livraison" })}>
                                    <Icon icon="mdi:truck-delivery" />
                                    <p>Livraison</p>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                                <button type='button' className='btn btn-primary fw mgR-m1 medium-btn mgB-m1 selectionDemandeButton' onClick={() => setTypeDemandeSelectionner({ typeDemande: "Camion", typeLivraison: "Évacuation" })}>
                                    <Icon icon="mdi:truck-delivery" style={{ transform: "rotateY(180deg)" }} />
                                    <p>Évacuation</p>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                            <div className='btn-secondary' style={{
                                width: "100%",
                                border: "1px solid",
                                padding: "0 1.4rem"
                            }}>
                                <p className='form-label mgB-s2 mgT-s2'>Porte-engin</p>
                                <button type='button' className='btn btn-primary fw mgR-m1 medium-btn mgB-m1 selectionDemandeButton' onClick={() => setTypeDemandeSelectionner({ typeDemande: "Porte-engin", typeLivraison: "Transfert" })}>
                                    <Icon icon="mdi:truck-flatbed" />
                                    <p>Transfert</p>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {typeDemandeSelectionner.typeDemande === "Ampirolle" &&
                    <AjouterDemandeAmpirolle previous={() => setTypeDemandeSelectionner({ typeDemande: null, typeLivraison: null })} progressBarRef={props.progressBarRef} allMateriaux={props.allMateriaux} onAjouterDemande={props.onAjouterDemande} typeDemande={"Ampirolle"} currentDate={props.currentDate} typeLivraison={typeDemandeSelectionner.typeLivraison} allAmpirollesOptions={props.allAmpirollesOptions} chantiersOptions={props.chantiersOptions} sitesOptions={props.sitesOptions} close={() => close()} />
                }
                {typeDemandeSelectionner.typeDemande === "Camion" &&
                    <AjouterDemandeCamion previous={() => setTypeDemandeSelectionner({ typeDemande: null, typeLivraison: null })} progressBarRef={props.progressBarRef} allMateriaux={props.allMateriaux} onAjouterDemande={props.onAjouterDemande} typeDemande={"Camion"} currentDate={props.currentDate} typeLivraison={typeDemandeSelectionner.typeLivraison} chantiersOptions={props.chantiersOptions} sitesOptions={props.sitesOptions} close={() => close()} />
                }
                {typeDemandeSelectionner.typeDemande === "Porte-engin" &&
                    <AjouterDemandePorteEngin previous={() => setTypeDemandeSelectionner({ typeDemande: null, typeLivraison: null })} progressBarRef={props.progressBarRef} onAjouterDemande={props.onAjouterDemande} typeDemande={"Porte-engin"} currentDate={props.currentDate} typeLivraison={typeDemandeSelectionner.typeLivraison} chantiersOptions={props.chantiersOptions} allAmpirollesOptions={props.allAmpirollesOptions} sitesOptions={props.sitesOptions} close={() => close()} />
                }
            </div>
        </div>
    );
};

export default AjouterDemandeModal;