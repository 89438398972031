import * as actionTypes from './actionTypes';

export const setProcessusList = (allProcessus) => {
    return {
        type: actionTypes.SET_PROCESSUS,
        allProcessus
    };
};

export const addProcessus = (processus) => {
    return {
        type: actionTypes.AJOUTER_PROCESSUS,
        processus
    };
};

export const modifierProcessus = (processus) => {
    return {
        type: actionTypes.MODIFIER_PROCESSUS,
        processus
    };
};

export const supprimerProcessus = (deletedProcessusId) => {
    return {
        type: actionTypes.SUPPRIMER_PROCESSUS,
        deletedProcessusId
    };
};

export const unloadProcessus = () => {
    return {
        type: actionTypes.UNLOAD_PROCESSUS,
    };
};