import React, { useState } from 'react';
import { Menu, MenuItem } from "@material-ui/core";
import { Icon } from '@iconify/react';
import fileDownload from 'js-file-download';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import SupprimerIcon from '../icon/SupprimerIcon';

const DocumentSalarieDotMenu = (props) => {
    const [menuPosition, setMenuPosition] = useState(null);
    const [className, setClassName] = useState("threeDot-button");

    const dotOpenAction = (event) => {
        setClassName("threeDot-button hovered");
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition({
            top: event.pageY,
            left: event.pageX
        });
    };

    const downloadFile = () => {
        setMenuPosition(null);
        axiosInstance.get(`uploads/documents/${props.document._id}`, {
            responseType: 'blob',
        }).then((result) => {
            fileDownload(result.data, `${props.document.titre}.${props.document.file.filename
                .split(".")
                .pop()
                .toLowerCase()}`);
        }).catch((err) => {
            showError(err);
        });
    };

    const viewFile = () => {
        setMenuPosition(null);
        axiosInstance.get(`uploads/documents/${props.document._id}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    const editFile = () => {
        setMenuPosition(null);
        props.openEditDocument(props.document);
    };

    const deleteFile = () => {
        setMenuPosition(null);
        props.openSupprimerDocument(props.document);
    };

    return (
        <>
            <button className={className} onClick={(e) => dotOpenAction(e)}><Icon icon="bi:three-dots" /></button>

            <Menu open={!!menuPosition} onClose={() => { setMenuPosition(null); setClassName("threeDot-button"); }} anchorReference="anchorPosition" anchorPosition={menuPosition}>
                {props.document.file.filename.split(".").pop().toLowerCase() !== "xls" && props.document.file.filename.split(".").pop().toLowerCase() !== "xlsx" && props.document.file.filename.split(".").pop().toLowerCase() !== "csv" && <MenuItem onClick={() => { viewFile(); setClassName("threeDot-button"); }}><Icon className="eyeDocumentSvg" icon="akar-icons:eye" /> <p className='dotMenuLabel'>Voir le document</p></MenuItem>}
                <MenuItem onClick={() => { downloadFile(); setClassName("threeDot-button"); }}><Icon className="telechargerDocumentSvg" icon="eva:download-fill" /> <p className='dotMenuLabel'>Télécharger le document</p></MenuItem>
                <MenuItem onClick={() => { editFile(); setClassName("threeDot-button"); }}><Icon className="eyeDocumentSvg" icon="ci:edit" /> <p className='dotMenuLabel'>Modifier le document</p></MenuItem>
                <MenuItem onClick={() => { deleteFile(); setClassName("threeDot-button"); }}><SupprimerIcon className="supprimerDocumentSvg" /> <p className='dotMenuLabel'>Supprimer le document</p></MenuItem>
            </Menu>

        </>
    );
};

export default DocumentSalarieDotMenu;