import React, { useEffect } from 'react';
import * as actionCreators from '../../store/actions/exportAction';
import { useSelector, useDispatch } from 'react-redux';
import { banqueHeureTotal, formatIntTwoDigitString } from '../../utils/GeneralHelper';
import TableRender from '../../components/tables/TableRender';
import { getBanqueHeureColumns } from '../../components/tables/TableColumns';


const onLoadPage = (statutUser) => (actionCreators.getBanqueHeure(statutUser));
const onQuitPage = () => (actionCreators.unloadBanqueHeure());

const BanqueHeure = () => {
  const userState = useSelector(
    state => state.user.user.statut
  );

  const banqueHeureState = useSelector(state => state.banqueHeure.horaire);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onLoadPage(userState));
    return () => {
      dispatch(onQuitPage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalHeure = banqueHeureTotal(banqueHeureState);

  return (
    <div className='section'>
      <div className="container-basic mgB-m2">
        <h1 className="h1-blue-dark">Banque d'heures</h1>
        <h3 className="banqueHeure-Total">Vous avez comptabilisé {totalHeure.val === "neg" ? <span className='totalHeure-negatif'>-{formatIntTwoDigitString(totalHeure.heure)}h{formatIntTwoDigitString(totalHeure.minute)}</span> : totalHeure.val === "pos" ? <span className='totalHeure-positif'>+{formatIntTwoDigitString(totalHeure.heure)}h{formatIntTwoDigitString(totalHeure.minute)}</span> : <span className='totalHeure-nul'>{formatIntTwoDigitString(totalHeure.heure)}h{formatIntTwoDigitString(totalHeure.minute)}</span>} en banque à ce jour</h3>
      </div>
      <TableRender
        columns={getBanqueHeureColumns(userState)}
        tableData={banqueHeureState}
        sortBy={[{ id: 'dateModif', desc: true }]}
        pageName="OneBanqueHeure"
      />
    </div>
  );
};

export default BanqueHeure;
