import React from "react";
import * as yup from "yup";
import { useFormik } from 'formik';
import Creatable from 'react-select/creatable';
import axiosInstance from "../../utils/axiosInstance";
import customToast from "../../utils/ToastifyHelper";
import { showError } from "../../utils/ErrorHelper";
import { Icon } from '@iconify/react';

const ModifierIntervenant = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const formSchema = yup.object().shape({
        libelleIntervenant: yup.string().required("Nom de l'intervenant non saisi"),
        telephones: yup.array().of(yup.string().length(10, "Numéro de téléphone incorrect").matches(/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/, "Numero de telephone incorrect")).min(0),
        email: yup.string()
            .email("Email non valide")
            .nullable(),
        ville: yup.string()
            .nullable(),
        codePostal: yup.string()
            .length(5, "Le format du code postal est incorrect")
            .nullable(),
        adresse: yup.string()
            .nullable(),
        cs: yup.number()
            .typeError("Le champ doit être un nombre")
            .nullable(),
        categorieIntervenant: yup.string()
            .nullable(),
    });

    const onSubmit = (values, actions) => {
        let patchData = {
            ...values,
            telephones: values.telephones.filter(tel => tel !== "" && tel !== null),
        };
        props.progressBarRef.current.continuousStart();
        axiosInstance.patch(`/intervenantsTiers/${props.intervenantSelected._id}`, patchData).then((result) => {
            props.progressBarRef.current.complete();
            customToast.success("L'intervenant a été modifié avec succès");
            props.modifierIntervenantsTiers(result.data.intervenantTier);
            if (result.data.newOptions !== null) {
                props.ajouterCategorieIntervenants(result.data.newOptions);
            }
            close();
        }).catch((err) => {
            props.progressBarRef.current.complete();
            setSubmitting(false);
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelleIntervenant: props.intervenantSelected.libelleIntervenant,
            telephones: props.intervenantSelected.telephones.length ? props.intervenantSelected.telephones : [""],
            email: props.intervenantSelected.email,
            ville: props.intervenantSelected.adresse.ville,
            codePostal: props.intervenantSelected.adresse.codePostal,
            adresse: props.intervenantSelected.adresse.adresse,
            cs: props.intervenantSelected.cs,
            categorieIntervenant: props.intervenantSelected.categorieIntervenant,
        },
        validationSchema: formSchema,
        onSubmit
    });

    const categorieIntervenants = props.categorieIntervenants.map((categorie) => {
        return { label: categorie, value: categorie };
    });

    const onChangeCategorie = (opt) => {
        if (opt) {
            setFieldValue("categorieIntervenant", opt.label);
        }
        else {
            setFieldValue("categorieIntervenant", opt);
        }
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Modifier un intervenant</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='libelleIntervenant' className='form-label'>Nom de l'intervenant<span className='required-field'>*</span></label>
                    <input name='libelleIntervenant' className={`new-default-input${errors.libelleIntervenant && touched.libelleIntervenant ? " input-error" : ""}`} type="text" placeholder="Nom de l'intervenant" value={values.libelleIntervenant} onChange={handleChange} onBlur={handleBlur} />
                    {errors.libelleIntervenant && touched.libelleIntervenant && <p className="form-error-message">{errors.libelleIntervenant}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='categorieIntervenant' className='form-label'>Catégorie d'intervenant</label>
                    <Creatable
                        options={categorieIntervenants}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeCategorie(opt)}
                        placeholder={"Catégorie de l'intervenant"}
                        blurInputOnSelect={false}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        defaultValue={values.categorieIntervenant !== null ? { label: values.categorieIntervenant, value: values.categorieIntervenant } : null}
                        isSearchable={true}
                        isClearable={true}
                        noOptionsMessage={() => false}
                        formatCreateLabel={value => `Ajouter l'option "${value}"`}
                    />
                </div>
                <div className='label-and-input'>
                    <label htmlFor='email' className='form-label'>Email</label>
                    <input name='email' className={`new-default-input${errors.email && touched.email ? " input-error" : ""}`} type="text" placeholder="Email de l'intervenant" value={values.email !== null ? values.email : ""} onChange={handleChange} onBlur={handleBlur} />
                    {errors.email && touched.email && <p className="form-error-message">{errors.email}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='telephone' className='form-label'>Téléphone</label>
                    {values.telephones.map((telephone, index) => {
                        return (
                            <div key={`telephone-${index}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ flex: 1 }}>
                                    <input name={`telephones.${index}`} className={`new-default-input${errors.telephones && errors.telephones.length && touched.telephones && touched.telephones.length && errors.telephones[index] && touched.telephones[index] ? " input-error" : ""}`} type="text" placeholder={`Téléphone n°${index + 1}`} value={telephone !== null ? telephone : ""} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.telephones && errors.telephones.length && touched.telephones && touched.telephones.length && errors.telephones[index] && touched.telephones[index] && <p className="form-error-message">{errors.telephones[index]}</p>}
                                </div>
                                {index > 0 && <Icon style={{ marginTop: "0.8rem", cursor: "pointer", width: "2rem", height: "2rem", marginLeft: "0.8rem", marginRight: "0.8rem" }} icon="radix-icons:cross-2" onClick={() => { setFieldValue("telephones", values.telephones.filter((_, i) => i !== index)); }} />}
                            </div>
                        );
                    })}
                    <div className="mgT-s3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button type="button" onClick={() => { setFieldValue("telephones", [...values.telephones, ""]); }} className="btn btn-secondary">Ajouter un téléphone</button>
                    </div>
                </div>
                <div className='horizontal-form-fields-container'>
                    <div className='label-and-input'>
                        <label htmlFor='codePostal' className='form-label'>Code postal</label>
                        <input name='codePostal' className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} type="text" placeholder="Code postal" value={values.codePostal !== null ? values.codePostal : ""} onChange={handleChange} onBlur={handleBlur} />
                    </div>
                    <div className='label-and-input'>
                        <label htmlFor='ville' className='form-label'>Ville</label>
                        <input name='ville' className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} type="text" placeholder="Ville" value={values.ville !== null ? values.ville : ""} onChange={handleChange} onBlur={handleBlur} />
                    </div>
                </div>
                <div className='label-and-input'>
                    <label htmlFor='adresse' className='form-label'>Adresse</label>
                    <input name='adresse' className={`new-default-input${errors.adresse && touched.adresse ? " input-error" : ""}`} type="text" placeholder="Adresse" value={values.adresse !== null ? values.adresse : ""} onChange={handleChange} onBlur={handleBlur} />
                    {errors.adresse && touched.adresse && <p className="form-error-message">{errors.adresse}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='cs' className='form-label'>CS</label>
                    <input name='cs' className={`new-default-input${errors.cs && touched.cs ? " input-error" : ""}`} type="number" placeholder="CS" value={values.cs !== null ? values.cs : ""} onChange={handleChange} onBlur={handleBlur} />
                    {errors.cs && touched.cs && <p className="form-error-message">{errors.cs}</p>}
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Sauvegarder" />
            </form>
        </div>
    );
};

export default ModifierIntervenant;
