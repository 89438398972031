import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import * as actionTypes from './actionTypes';

export const loadSuiviTypeFormationList = (suiviTypeFormationList) => {
    return {
        type: actionTypes.LOAD_SUIVI_TYPE_FORMATION_LIST,
        suiviTypeFormationList: suiviTypeFormationList
    };
};
export const unloadSuiviTypeFormationList = () => {
    return {
        type: actionTypes.UNLOAD_SUIVI_TYPE_FORMATION_LIST,
    };
};

export const addSuiviTypeFormation = (suiviTypeFormation) => {
    return {
        type: actionTypes.ADD_SUIVI_TYPE_FORMATION,
        suiviTypeFormation: suiviTypeFormation
    };
};

export const patchSuiviTypeFormation = (suiviTypeFormationNewData) => {
    return {
        type: actionTypes.MODIFY_SUIVI_TYPE_FORMATION,
        suiviTypeFormationUpdated: suiviTypeFormationNewData
    };
};

export const deleteSuiviTypeFormation = (suiviTypeFormationId) => {
    return {
        type: actionTypes.DELETE_SUIVI_TYPE_FORMATION,
        suiviTypeFormationId: suiviTypeFormationId
    };
};

export const getSuiviTypeFormationList = () => (dispatch) => {
    // get all suiviTypeFormations

    axiosInstance.get('/gestionTypeSuivie', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadSuiviTypeFormationList(result.data));
        }).catch((err) => {
            showError(err);
        });
}

