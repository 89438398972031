import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';

const AdvanceSearch = (props) => {
    const [isFocused, setFocused] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        let timer = setTimeout(() => {
            props.search();
        }, 1000);
        return () => clearTimeout(timer);
    }, [props.searchContent]);

    return (
        <div onClick={() => { if (!isFocused) { setFocused(true); inputRef.current.focus(); } }} className={`${isFocused ? "search-input focus" : "search-input"}`}>
            <div className='searchIcon-container'>
                <Icon icon="mdi:search" />
            </div>
            <input value={props.searchContent} onChange={props.onChangeSearch} onBlur={() => setFocused(false)} onClick={() => { if (!isFocused) { setFocused(true); } }} ref={inputRef} placeholder={props.placeholder ? props.placeholder : ""} type={"text"} />
        </div>
    );
};

export default AdvanceSearch;