import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getReleveExpositionsColumns } from '../../components/tables/TableColumns';
import TableRender from '../../components/tables/TableRender';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import ClipLoader from "react-spinners/ClipLoader";
import fileDownload from 'js-file-download';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { format } from 'date-fns';
import customToast from '../../utils/ToastifyHelper';
import { Icon } from "@iconify/react";
import Modal from '../../components/Modal';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import SearchInput from '../../components/ui/SearchInput';
import { getInsensitiveWorld } from '../../utils/GeneralHelper';


const GestionRelevesExpositions = () => {
    const [relevesExpositions, setRelevesExpositions] = useState([]);
    const [showedItems, setShowedItems] = useState([]);
    const navigate = useNavigate();
    const [dateExport, onChangeDate] = useState([new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        axiosInstance.get("/vacationsAmiante").then((result) => {
            setRelevesExpositions(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const [searchBarContent, setSearchBarContent] = useState("");
    const relevesExpositionsMatch = (chantier, searchValue) => {
        let insensitiveSearch = getInsensitiveWorld(searchValue);
        return true;
    };
    const onChangeSearchReleve = (e) => {
        setSearchBarContent(e.target.value);
    };

    useEffect(() => {
        if (searchBarContent !== null && searchBarContent !== "") {
            let timer = setTimeout(() => {
                setShowedItems(relevesExpositions.filter(releve => {
                    return relevesExpositionsMatch(releve, searchBarContent);
                }));
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setShowedItems(relevesExpositions);
        }
    }, [relevesExpositions, searchBarContent]);


    const exportData = () => {
        if (dateExport !== null && dateExport[0] !== null && dateExport[1] !== null) {
            setIsLoading(true);
            axiosInstance.get(`/export_excel/vacationSalarie?dateDebutexport=${format(dateExport[0], "yyyy-MM-dd")}&dateFinExport=${format(dateExport[1], "yyyy-MM-dd")}`, {
                responseType: 'blob',
            })
                .then((result) => {
                    fileDownload(result.data, `ExportVacations-${format(dateExport[0], "dd/MM/yyyy")}_${format(dateExport[1], "dd/MM/yyyy")}.xlsx`);
                    setIsLoading(false);
                }).catch((err) => {
                    showError(err);
                    setIsLoading(false);
                });
        }
        else {
            customToast.error("Sélectionner une date de début et de fin de période pour télécharger l'export");
        }
    };

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const deleteReleveExposition = (releveExpositionId) => {
        console.log(releveExpositionId);
        console.log(relevesExpositions);
        console.log(showedItems);
        setRelevesExpositions(relevesExpositions.filter(rlv => {
            return rlv._id !== releveExpositionId;
        }));
    };

    const openDeleteVacation = (vacationData) => {
        console.log(relevesExpositions);
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm deleteReleveExposition={(releveExpositionId) => deleteReleveExposition(releveExpositionId)} relevesExpositions={relevesExpositions} destination="supprimerVacationChantier" vacation={vacationData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };


    return (
        <div className='section'>
            <div className='container-basic mgB-m2'>
                <h1 className="h1-blue-dark">Consulter les relevés d'expositions</h1>
                <div className="tableau-top-section">
                    <h3>{showedItems.length} relevé{showedItems.length > 1 && "s"} d'exposition{showedItems.length > 1 && "s"} {showedItems.length > 1 ? "correspondent" : "correspond"} à votre recherche</h3>
                    <SearchInput fullwidth={true} placeholder="Rechercher un relevé d'exposition" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchReleve} />
                    <DateRangePicker calendarIcon={null} minDate={new Date("2022-01-01")} maxDate={new Date("2099-12-31")} className="input-datetimePicker searchDate-select" showLeadingZeros={true} format="dd/MM/yyyy" onChange={onChangeDate} value={dateExport} />
                    <button onClick={() => exportData()} className='fullEdit-button black-button'>{isLoading ? <ClipLoader loading={isLoading} color={"#dcd8d3"} size={15} /> : <Icon icon="eva:download-fill" />}<p>Exporter les relevés</p></button>
                </div>
            </div>
            <TableRender
                columns={getReleveExpositionsColumns((link) => navigate(link), (vacationData) => openDeleteVacation(vacationData))}
                tableData={showedItems}
                sortBy={[
                    {
                        id: 'dateVacation',
                        desc: false,
                        disableSortRemove: true,
                    }
                ]}
                pageName="GestionReleveExposition"
            />
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default GestionRelevesExpositions;