import React, { useState } from "react";
import { Icon } from '@iconify/react';
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import SupprimerIcon from "../../components/icon/SupprimerIcon";
import { format } from 'date-fns';
import { truncateString } from "../../utils/GeneralHelper";

const ReleveHeureDotMenuV2 = (props) => {

    const [menuPosition, setMenuPosition] = useState(null);
    const [className, setClassName] = useState("threeDot-button");

    const dotOpenAction = (event) => {
        setClassName("threeDot-button hovered");
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition({
            top: event.pageY - window.scrollY,
            left: event.pageX
        });
    };

    const handleCopierVers = (copieData) => {
        setMenuPosition(null);
        setClassName("threeDot-button");
        props.openCopierVers(copieData);
    };

    const handleSupprimerChantierHoraire = (jourChantier) => {
        setMenuPosition(null);
        setClassName("threeDot-button");
        props.openSupprimerTouteHeuresChantier(jourChantier);
    };

    const handleSupprimerToutHoraires = (dateJour) => {
        setMenuPosition(null);
        setClassName("threeDot-button");
        if (props.chantiers.length) {
            props.openSupprimerTouteHeures(dateJour);
        }
    };

    return (
        <>
            <button className={className} disabled={props.statutSemaine !== 0} onClick={(e) => dotOpenAction(e)}><Icon icon="bi:three-dots" /></button>
            <Menu open={!!menuPosition} onClose={() => { setMenuPosition(null); setClassName("threeDot-button"); }} anchorReference="anchorPosition" anchorPosition={menuPosition}>
                <NestedMenuItem label={<p className="dotMenu-label"><Icon icon="fluent:copy-arrow-right-24-regular" />Copier vers</p>} parentMenuOpen={!!menuPosition}>
                    {props.allDays.map((jour) => {
                        if (format(jour, "yyyy-MM-dd") !== props.jourDate) {
                            return <MenuItem key={format(jour, "dd/MM/yyyy")} onClick={() => handleCopierVers({ date_jour_dupliquer: props.jourDate, date_jour_destination: format(jour, "yyyy-MM-dd") })}>{format(jour, "dd/MM/yyyy")}</MenuItem>;
                        }
                        return null;
                    })}
                </NestedMenuItem>
                <NestedMenuItem label={<p className="dotMenu-label"><SupprimerIcon />Supprimer</p>} parentMenuOpen={!!menuPosition}>
                    <MenuItem onClick={() => handleSupprimerToutHoraires(props.jourDate)}>Supprimer toutes les heures</MenuItem>
                    {props.chantiers.map((chantier) => {
                        return <MenuItem key={chantier._id._id} onClick={() => handleSupprimerChantierHoraire({ date_jour: props.jourDate, chantierId: chantier._id._id, nomChantier: chantier._id.libelle_lieu })}>{truncateString(chantier._id.libelle_lieu, 16)}</MenuItem>;
                    })}
                </NestedMenuItem>
            </Menu>

        </>
    );
};

export default ReleveHeureDotMenuV2;
