import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { useFormik } from 'formik';
import { addDays, format } from 'date-fns';
import Select from "react-select";
import Creatable from 'react-select/creatable';
import Map, { Marker } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import SingleTimeInput from '../../SingleTimeInput';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';




const AjouterDemandeCamion = (props) => {
    const dispatch = useDispatch();

    const formSchema = yup.object().shape({
        lieuDepart: yup.string().required("Veuillez sélectionner le lieu de prise"),
        lieuArriver: yup.string().required("Veuillez sélectionner le lieu d'arrivée"),
        typeVehicule: yup.string().required("Veuillez sélectionner un type de véhicule").typeError("Veuillez sélectionner un type de véhicule"),
        materiaux: yup.string().required("Veuillez sélectionner un matériaux").typeError("Veuillez sélectionner un matériaux"),
        provenanceMateriau: yup.string().nullable(),
        priorite: yup.string().required("Veuillez sélectionner la priorité"),
        doubleFret: yup.boolean(),
        nombreTours: yup.string("Veuillez sélectionner le nombre de tours").required("Veuillez sélectionner le nombre de tours").typeError('Veuillez sélectionner le nombre de tours').test("valid-numeric-value", "Le nombre de tours doit être une valeur numérique", ((value) => {
            return value !== undefined && (value === "Rotation sur place" || value.match(/^\d+$/));
        })),
        dateLivraison: yup.date(),
        info: yup.string()
    });

    const onSubmit = (values, actions) => {
        const data = {
            lieuDepart: values.lieuDepart,
            typeLieuDepart: typeLieuDepart,
            lieuArriver: values.lieuArriver,
            typeLieuArriver: typeLieuArriver,
            typeVehicule: values.typeVehicule,
            priorite: values.priorite,
            doubleFret: values.doubleFret,
            typeDemande: props.typeLivraison,
            dateLivraison: format(values.dateLivraison, "yyyy-MM-dd"),
            horaire: {
                heure: horaire.heure !== "" ? parseInt(horaire.heure) : null,
                minute: horaire.minute !== "" ? parseInt(horaire.minute) : null,
            },
            info: values.info,
            materiau: values.materiaux,
            provenanceMateriau: values.provenanceMateriau,
            nombreTours: values.nombreTours === "Rotation sur place" ? -1 : parseInt(values.nombreTours)
        };

        props.progressBarRef.current.continuousStart();
        axiosInstance.post("/demandeTransport/demandeCamion", data).then((result) => {
            props.progressBarRef.current.complete();
            props.onAjouterDemande(result.data);
            actions.resetForm();
            props.close();
        }).catch((err) => {
            showError(err);
        });
    };

    const groupedOptions = [
        {
            label: "Chantier",
            options: props.chantiersOptions
        },
        {
            label: "Site",
            options: props.sitesOptions
        }
    ];

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting, submitForm } = useFormik({
        initialValues: {
            lieuDepart: "",
            lieuArriver: "",
            typeVehicule: "",
            materiaux: "",
            provenanceMateriau: null,
            priorite: "Priorité normale",
            nombreTours: "",
            dateLivraison: props.currentDate,
            info: "",
            doubleFret: false
        },
        validationSchema: formSchema,
        onSubmit
    });

    const lieuDepartMapRef = useRef();
    const lieuArriverMapRef = useRef();

    const [typeLieuDepart, setTypeLieuDepart] = useState(null);
    const [typeLieuArriver, setTypeLieuArriver] = useState(null);

    const [lieuDepartMarker, setLieuDepartMarker] = useState(null);
    const [lieuArriverMarker, setLieuArriverMarker] = useState(null);

    const onChangeLieuDepart = (opt) => {
        if (opt !== null) {
            setTypeLieuDepart(opt.typeLieu);
            setFieldValue("lieuDepart", opt.value);
            if (opt.adresse !== null && opt.adresse.hasOwnProperty("gps") && opt.adresse.gps.latitude !== null && opt.adresse.gps.longitude !== null) {
                setLieuDepartMarker({ latitude: opt.adresse.gps.latitude, longitude: opt.adresse.gps.longitude });
                lieuDepartMapRef.current.flyTo({
                    center: [opt.adresse.gps.longitude, opt.adresse.gps.latitude],
                    essential: true,
                    duration: 1000
                });
            }
            else {
                setLieuDepartMarker(null);
            }
        }
        else {
            setLieuDepartMarker(null);
            setFieldValue("lieuDepart", "");
        }
    };

    const onChangeLieuArriver = (opt) => {
        if (opt !== null) {
            setTypeLieuArriver(opt.typeLieu);
            setFieldValue("lieuArriver", opt.value);
            if (opt.adresse !== null && opt.adresse.hasOwnProperty("gps") && opt.adresse.gps.latitude !== null && opt.adresse.gps.longitude !== null) {
                setLieuArriverMarker({ latitude: opt.adresse.gps.latitude, longitude: opt.adresse.gps.longitude });
                lieuArriverMapRef.current.flyTo({
                    center: [opt.adresse.gps.longitude, opt.adresse.gps.latitude],
                    essential: true,
                    duration: 1000
                });
            }
            else {
                setLieuArriverMarker(null);
            }
        }
        else {
            setLieuArriverMarker(null);
            setFieldValue("lieuArriver", "");
        }
    };

    const [horaire, setHoraire] = useState({ heure: "", minute: "" });
    const onModifierHeure = (horaire) => {
        setHoraire(horaire);
    };

    const onSelectMateriaux = (materiaux) => {
        if (materiaux) {
            setFieldValue("materiaux", materiaux.value);
            setFieldValue("provenanceMateriau", materiaux.provenances);
        }
        else {
            setFieldValue("materiaux", materiaux);
            setFieldValue("provenanceMateriau", null);
        }
    };

    const materiauxOptionsIsSelected = (materiaux) => {
        return (materiaux.value === values.materiaux && materiaux.provenances === values.provenanceMateriau);
    };

    return (
        <div className='mgT-m3' style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <p style={{ fontSize: "2rem" }} className='form-label mgB-s2'>{props.typeLivraison} camion</p>
            <form className="vertical-form demandeForm scrollable mgT-b1" onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1, marginRight: "1.4rem" }}>
                            <label htmlFor='documentTitle' className='form-label'>Véhicule</label>
                            <Select
                                options={[{ label: "19t", value: "19t" }, { label: "8x4", value: "8x4" }, { label: "6x4", value: "6x4" }, { label: "Pata", value: "Pata" }, { label: "Semi", value: "Semi" }]}
                                className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => setFieldValue("typeVehicule", opt ? opt.value : opt)}
                                placeholder={"Type de véhicule"}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        fontFamily: "DM Sans",
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                isClearable={true}
                            />
                            {errors.typeVehicule && touched.typeVehicule && <p className="form-error-message" style={{ textAlign: "left" }}>{errors.typeVehicule}</p>}
                        </div>
                        <div style={{ flex: 1 }}>
                            <label htmlFor='documentTitle' className='form-label'>Matériaux</label>
                            <Select
                                options={props.allMateriaux}
                                className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => onSelectMateriaux(opt)}
                                isOptionSelected={(materiaux) => materiauxOptionsIsSelected(materiaux)}
                                placeholder={"Matériaux"}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        fontFamily: "DM Sans",
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                isClearable={true}
                            />
                            {errors.materiaux && touched.materiaux && <p className="form-error-message" style={{ textAlign: "left" }}>{errors.materiaux}</p>}
                        </div>
                    </div>
                </div>
                <div className='label-and-input horizontal' style={{ marginBottom: "0.8rem" }}>
                    <label htmlFor='documentTitle' className='form-label'>De</label>
                    <Select
                        options={groupedOptions}
                        className="formSelect-search-container formSelect-search-container-full"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeLieuDepart(opt)}
                        placeholder={""}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                    />
                </div>
                {errors.lieuDepart && touched.lieuDepart && <p className="form-error-message" style={{ textAlign: "right" }}>{errors.lieuDepart}</p>}
                <Map
                    ref={lieuDepartMapRef}
                    initialViewState={{ longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                    style={{ width: "100%", flex: 1, borderRadius: ".5rem" }}
                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                    attributionControl={false}
                >
                    {lieuDepartMarker !== null &&
                        <Marker longitude={lieuDepartMarker.longitude} latitude={lieuDepartMarker.latitude} anchor="bottom">

                        </Marker>
                    }
                </Map>
                <div className='label-and-input horizontal mgT-m1' style={{ marginBottom: "0.8rem" }}>
                    <label htmlFor='documentTitle' className='form-label'>Vers</label>
                    <Select
                        options={groupedOptions}
                        className="formSelect-search-container formSelect-search-container-full"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeLieuArriver(opt)}
                        placeholder={""}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                    />
                </div>
                {errors.lieuArriver && touched.lieuArriver && <p className="form-error-message" style={{ textAlign: "right" }}>{errors.lieuArriver}</p>}
                <Map
                    ref={lieuArriverMapRef}
                    initialViewState={{ longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                    style={{ width: "100%", flex: 1, borderRadius: ".5rem" }}
                    mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                    attributionControl={false}
                >
                    {lieuArriverMarker !== null &&
                        <Marker longitude={lieuArriverMarker.longitude} latitude={lieuArriverMarker.latitude} anchor="bottom">

                        </Marker>
                    }
                </Map>
                <div className='label-and-input mgT-m2'>
                    <div className="container-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Select
                            options={[{ label: "Priorité normale", value: "Priorité normale" }, { label: "Urgent", value: "Urgent" }]}
                            className="formSelect-search-container formSelect-search-container-full"
                            classNamePrefix="formSelect-search"
                            onChange={(opt) => setFieldValue("priorite", opt.value)}
                            placeholder={""}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    fontFamily: "DM Sans",
                                    border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                    borderRadius: "0.6rem",
                                    boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                    '&:hover': {
                                        border: '1px solid black',
                                    },
                                })
                            }}
                            isSearchable={false}
                            isClearable={false}
                            defaultValue={{ label: "Priorité normale", value: "Priorité normale" }}
                        />
                        <SingleTimeInput style={{ flex: 0.5, marginLeft: "0.8rem" }} horaire={horaire} modifierHoraireInput={(horaire) => onModifierHeure(horaire)} />
                    </div>
                </div>
                <div className='label-and-input'>
                    <Creatable
                        options={[{ label: "Rotation sur place", value: "Rotation sur place" }]}
                        className="formSelect-search-container formSelect-search-container-full"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => setFieldValue("nombreTours", opt ? opt.value : opt)}
                        placeholder={"Nombre de tours"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                        noOptionsMessage={() => false}
                        formatCreateLabel={value => value}
                        createOptionPosition="first"
                    />
                    {errors.nombreTours && touched.nombreTours && <p className="form-error-message">{errors.nombreTours}</p>}
                </div>
                <div className='label-and-input' style={{ marginTop: "0.2rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <label htmlFor='documentTitle' className='form-label'>Double fret</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
                            <p>Oui</p>
                            <input
                                type="radio"
                                name="doubleFret-oui"
                                value="Oui"
                                checked={values.doubleFret}
                                onChange={() => setFieldValue("doubleFret", true)}
                            />
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
                            <p>Non</p>
                            <input
                                type="radio"
                                name="doubleFret-non"
                                value="Non"
                                checked={!values.doubleFret}
                                onChange={() => setFieldValue("doubleFret", false)}
                            />
                        </div>
                    </div>
                </div>
                <div className='label-and-input'>
                    <input name='info' style={{ marginTop: 0 }} className={`new-default-input${errors.info && touched.info ? " input-error" : ""}`} type="text" placeholder="Informations" value={values.info} onChange={handleChange} onBlur={handleBlur} />
                    {errors.info && touched.info && <p className="form-error-message">{errors.info}</p>}
                </div>
                <div className='mgB-m1' style={{ display: "flex" }}>
                    <button type='button' disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => props.previous()}>Annuler</button>
                    <button type="submit" disabled={isSubmitting} className='btn btn-primary fw medium-btn'>Valider</button>
                </div>
            </form>
        </div>
    );
};

export default AjouterDemandeCamion;