import React, { useCallback, useRef, useState } from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import customToast from '../../utils/ToastifyHelper';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import Map, { Marker } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import { getCoordinatesFromDD, getCoordinatesFromDMS } from '../../utils/GeneralHelper';




const AjouterSite = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const mapRef = useRef();
    const [currentMarker, setCurrentMarker] = useState(null);

    const onMapLoad = useCallback(() => {
        mapRef.current.on('click', (e) => {
            setFieldValue("gps", getCoordinatesFromDD(e.lngLat.lat, e.lngLat.lng));
            setCurrentMarker({ longitude: e.lngLat.lng, latitude: e.lngLat.lat });
        });
    }, []);


    // handle form
    const formSchema = yup.object().shape({
        libelle_lieu: yup.string().required("Veuillez renseigner le nom du lieu"),
        codePostal: yup.string().nullable().notRequired().when("codePostal", (cp, schema) => {
            if (cp) {
                return schema.length(5, "Format incorrect, le code postal est composé de 5 chiffres");
            }
            return schema;
        }),
        lieuExecution: yup.string(),
        ville: yup.string("Veuillez saisir la ville"),
        gps: yup.string().nullable().notRequired().when("gps", (gps, schema) => {
            if (gps) {
                return schema.matches(/^([0-9]{1,2})°([0-9]{1,2})'([0-9]{1,2}(?:\.[0-9]+)?)"([NS]) ([0-9]{1,3})°([0-9]{1,2})'([0-9]{1,2}(?:\.[0-9]+)?)"([EW])$/, "Erreur de format du point gps, la latitude et la longitude doivent être séparé par un espace");
            }
            return schema;
        })
    },
        [["codePostal", "codePostal"], ["gps", "gps"]]
    );

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const postData = {
            ...values,
            codePostal: values.codePostal.length ? values.codePostal : null,
            gps: values.gps.length ? values.gps : null,
            type_lieu: "Site"
        };

        // post chantier
        axiosInstance.post('/lieux', postData)
            .then((result) => {
                props.progressBarRef.current.complete();
                customToast.success("Le site a été ajouté ave succès");
                props.addSite(result.data);
                close();
            }).catch((err) => {
                actions.resetForm();
                props.progressBarRef.current.complete();
                showError(err);
            });

    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelle_lieu: "",
            codePostal: "",
            lieuExecution: "",
            ville: "",
            gps: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onGpsBlur = (e) => {
        if (!errors.gps && values.gps !== "") {
            setCurrentMarker(getCoordinatesFromDMS(values.gps));
            mapRef.current.flyTo({
                center: [getCoordinatesFromDMS(values.gps).longitude, getCoordinatesFromDMS(values.gps).latitude],
                essential: true
            });
        }
        else {
            setCurrentMarker(null);
        }
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Ajouter un site</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='libelle_lieu' className='form-label'>Nom du site<span className='required-field'>*</span></label>
                    <input name='libelle_lieu' className={`new-default-input${errors.libelle_lieu && touched.libelle_lieu ? " input-error" : ""}`} type="text" placeholder="Nom du chantier" value={values.libelle_lieu} onChange={handleChange} onBlur={handleBlur} />
                    {errors.libelle_lieu && touched.libelle_lieu && <p className="form-error-message">{errors.libelle_lieu}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='lieuExecution' className='form-label'>Lieu d'éxecution</label>
                    <input name='lieuExecution' className={`new-default-input${errors.lieuExecution && touched.lieuExecution ? " input-error" : ""}`} type="text" placeholder="Lieu d'éxecution" value={values.lieuExecution} onChange={handleChange} onBlur={handleBlur} />
                    {errors.lieuExecution && touched.lieuExecution && <p className="form-error-message">{errors.lieuExecution}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='ville' className='form-label'>Ville</label>
                    <input name='ville' className={`new-default-input${errors.ville && touched.ville ? " input-error" : ""}`} type="text" placeholder="Ville" value={values.ville} onChange={handleChange} onBlur={handleBlur} />
                    {errors.ville && touched.ville && <p className="form-error-message">{errors.ville}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='codePostal' className='form-label'>Code postal</label>
                    <input name='codePostal' className={`new-default-input${errors.codePostal && touched.codePostal ? " input-error" : ""}`} type="text" placeholder="Code postal" value={values.codePostal} onChange={handleChange} onBlur={handleBlur} />
                    {errors.codePostal && touched.codePostal && <p className="form-error-message">{errors.codePostal}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='gps' className='form-label'>Point gps</label>
                    <input name='gps' className={`new-default-input${errors.gps && touched.gps ? " input-error" : ""}`} type="text" placeholder={`exemple: 47°27'43.0"N 0°30'57.2"W`} value={values.gps} onChange={handleChange} onBlur={(e) => { handleBlur(e); onGpsBlur(e); }} />
                    {errors.gps && touched.gps && <p className="form-error-message">{errors.gps}</p>}
                </div>
                <div style={{ width: "100%", backgroundColor: "#000" }} className="last-input mgB-m2">
                    <Map
                        ref={mapRef}
                        attributionControl={false}
                        initialViewState={{ longitude: -0.312243, latitude: 47.232832, zoom: 12 }}
                        onLoad={onMapLoad}
                        style={{ width: "100%", height: "100%" }}
                        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                    >
                        {currentMarker !== null &&
                            <Marker longitude={currentMarker.longitude} latitude={currentMarker.latitude} anchor="bottom">

                            </Marker>
                        }
                    </Map>
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Ajouter" />
            </form>
        </div>
    );
};

export default AjouterSite;