import React from 'react';
import { useSelector } from 'react-redux';
import DocumentAmianteAdmin from './DocumentAmianteAdmin';
import DocumentAmianteEncadrantTechnique from './DocumentAmianteEncadrantTechnique';
import DocumentAmianteEncadrantChantier from './DocumentAmianteEncadrantChantier';

const DocumentAmiante = () => {
    const user = useSelector(
        state => state.user.user
    );

    const getPage = (user) => {
        switch (user.statut) {
            case "Administration":
                return (
                    <DocumentAmianteAdmin />
                );
            case "Chef de chantier":
                return (
                    <h1>Accès interdit</h1>
                );
            case "Encadrant technique":
                return (
                    <DocumentAmianteEncadrantTechnique />
                );
            case "Encadrant de chantier":
                return (
                    <DocumentAmianteEncadrantChantier />
                );
            case "Ouvrier":
                return (
                    <h1>Accès interdit</h1>
                );
            case "Intérimaire":
                return (
                    <h1>Accès interdit</h1>
                );
            case "Chauffeur":
                return (
                    <h1>Accès interdit</h1>
                );
            default:
                return (
                    <h1>Accès interdit</h1>
                );
        }
    };
    return (
        <div className="section">
            {getPage(user)}
        </div>
    );
};

export default DocumentAmiante;