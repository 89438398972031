import * as actionTypes from '../actions/actionTypes';
// import { updateObject } from '../helper';

const releveHeureState = {
    statut_horaire_semaine: null,
    jours: []
};

const reducer = (state = releveHeureState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_RELEVE_HEURE_ME:
            return { ...state, statut_horaire_semaine: action.statutSemaine, jours: action.jours };
        case actionTypes.UNLOAD_RELEVE_HEURE:
            return {
                statut_horaire_semaine: null,
                jours: []
            };
        default:
            return state;
    }
};

export default reducer;
