import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { Menu, MenuItem } from "@material-ui/core";
import SupprimerIcon from './icon/SupprimerIcon';
import axiosInstance from '../utils/axiosInstance';
import { showError } from '../utils/ErrorHelper';


const ProcessusDocumentDotMenu = (props) => {
    const [menuPosition, setMenuPosition] = useState(null);
    const [className, setClassName] = useState("threeDot-button mgL-s2");

    const dotOpenAction = (event) => {
        setClassName("threeDot-button hovered mgL-s2");
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition({
            top: event.pageY,
            left: event.pageX
        });
    };

    const viewFile = (documentId) => {
        setMenuPosition(null);
        axiosInstance.get(`uploads/documents/${documentId}`, {
            responseType: 'blob',
        }).then((result) => {
            let fileURL = URL.createObjectURL(result.data);
            window.open(fileURL);
        }).catch((err) => {
            showError(err);
        });
    };

    return (
        <>
            <button className={className + " mgL-s2"} onClick={(e) => dotOpenAction(e)}><Icon icon="bi:three-dots" /></button>
            {props.document === null
                ? <Menu open={!!menuPosition} onClose={() => { setMenuPosition(null); setClassName("threeDot-button mgL-s2"); }} anchorReference="anchorPosition" anchorPosition={menuPosition}>
                    <MenuItem onClick={() => { setMenuPosition(null); props.openAjouterDocumentAnalyse({ typeValidation: props.typeValidation, analyse: props.analyse, processus: props.processus }); setClassName("threeDot-button mgL-s2"); }}><Icon className="telechargerDocumentSvg" icon="ic:round-plus" /> <p className='dotMenuLabel'>Ajouter un document</p></MenuItem>
                </Menu>
                : <Menu open={!!menuPosition} onClose={() => { setMenuPosition(null); setClassName("threeDot-button mgL-s2"); }} anchorReference="anchorPosition" anchorPosition={menuPosition}>
                    <MenuItem onClick={() => { viewFile(props.document); setClassName("threeDot-button mgL-s2"); }}><Icon className="eyeDocumentSvg" icon="akar-icons:eye" /> <p className='dotMenuLabel'>Voir le document</p></MenuItem>
                    <MenuItem onClick={() => { setMenuPosition(null); setClassName("threeDot-button mgL-s2"); props.openSupprimerDocumentAnalyse({ typeValidation: props.typeValidation, analyse: props.analyse, analyseId: props.analyseId, document: props.document }); setClassName("threeDot-button"); }}><SupprimerIcon className="supprimerDocumentSvg" /> <p className='dotMenuLabel'>Supprimer le document</p></MenuItem>
                </Menu>
            }
        </>
    );
};

export default ProcessusDocumentDotMenu;