import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axiosInstance from '../../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import customToast from '../../utils/ToastifyHelper';
import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import { showError } from '../../utils/ErrorHelper';
import { useFormik } from 'formik';
import Select from "react-select";
import { format } from 'date-fns';

const modifierEquipement = (equipementNewData) => (actionCreators.patchEquipementEpc(equipementNewData));

const PatchEquipementEpcForm = (props) => {

    const close = () => {
        console.log(props.materielData);
        props.closeModal();
        props.unrenderForm();
    };

    // handle form
    const formSchema = yup.object().shape({
        nomEquipement: yup.string().required("Veuillez renseigner le nom du matériel"),
        marqueEquipement: yup.string().required("Veuillez entrer une marque"),
        numeroDeSerieEquipement: yup.string().required("Veuillez entrer un numéro de série"),
        refInterneEquipement: yup.string().optional("Veuillez entrer un numéro de série"),
        dateDernierControleEquipement: yup.date().nullable(),
        dateProControleEquipement: yup.date().nullable()
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const patchData = {
            ...values,
            dateDernierControleEquipement: values.dateDernierControleEquipement ? values.dateDernierControleEquipement : null,
            dateProControleEquipement: values.dateProControleEquipement ? values.dateProControleEquipement : null,
        };
        axiosInstance.patch(`/equipementEPC/${props.materielData._id}`, patchData).then((result) => {
            props.progressBarRef.current.complete();
            console.log(result.data);
            props.editMateriel(result.data);
            actions.resetForm();
            close();
        }).catch((err) => {
            actions.resetForm();
            props.progressBarRef.current.complete();
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nomEquipement: props.materielData.nom,
            marqueEquipement: props.materielData.marque,
            numeroDeSerieEquipement: props.materielData.numero_de_serie,
            refInterneEquipement: props.materielData.ref_interne,
            dateDernierControleEquipement: props.materielData.date_dernier_controle ? format(new Date(props.materielData.date_dernier_controle), "yyyy-MM-dd") : null,
            dateProControleEquipement: props.materielData.date_prochain_controle ? format(new Date(props.materielData.date_prochain_controle), "yyyy-MM-dd") : null
        },
        validationSchema: formSchema,
        onSubmit
    });

    const nomMaterielOptions = [
        { label: "EXTRACTEUR", value: "EXTRACTEUR" },
        { label: "UNITE DE FILTRATION UF", value: "UNITE DE FILTRATION UF" },
        { label: "UNITE DE CHAUFFE ET FILTRATION", value: "UNITE DE CHAUFFE ET FILTRATION" },
        { label: "SYSTÈME ADDUCTION  AIR", value: "SYSTÈME ADDUCTION  AIR" },
        { label: "ASPIRATEUR", value: "ASPIRATEUR" },
        { label: "BORDUREUSE", value: "BORDUREUSE" },
        { label: "RECTIFIEUSE", value: "RECTIFIEUSE" },
        { label: "CONTROLEUR DE DEPRESSION", value: "CONTROLEUR DE DEPRESSION" },
        { label: "ANEMOMETRE", value: "ANEMOMETRE" },
        { label: "LUXMETRE", value: "LUXMETRE" },
        { label: "U.M.D", value: "U.M.D" },
    ];

    const onChangeNomMateriel = (opt) => {
        setFieldValue("nomEquipement", opt.value);
    };

    return (
        <div className='modal-content modifier-form'>
            <h3 className='modal-title'>Modifier un équipement</h3>
            {/* <h3 className='modal-title'>{props.equipementEpc.nom}</h3> */}
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='nomEquipement' className='form-label'>Nom du matériel<span className='required-field'>*</span></label>
                    <Select
                        options={nomMaterielOptions}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeNomMateriel(opt)}
                        placeholder={"Nom du matériel"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        value={values.nomEquipement ? { label: values.nomEquipement, value: values.nomEquipement } : ""}
                    />
                    {errors.nomEquipement && touched.nomEquipement && <p className="form-error-message">{errors.nomEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='marqueEquipement' className='form-label'>Marque du matériel<span className='required-field'>*</span></label>
                    <input name='marqueEquipement' className={`new-default-input${errors.marqueEquipement && touched.marqueEquipement ? " input-error" : ""}`} type="text" placeholder="Marque du matériel" value={values.marqueEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.marqueEquipement && touched.marqueEquipement && <p className="form-error-message">{errors.marqueEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='numeroDeSerieEquipement' className='form-label'>Numero de série du matériel<span className='required-field'>*</span></label>
                    <input name='numeroDeSerieEquipement' className={`new-default-input${errors.numeroDeSerieEquipement && touched.numeroDeSerieEquipement ? " input-error" : ""}`} type="text" placeholder="Numéro de série du matériel" value={values.numeroDeSerieEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.numeroDeSerieEquipement && touched.numeroDeSerieEquipement && <p className="form-error-message">{errors.numeroDeSerieEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='refInterneEquipement' className='form-label'>Reférence du matériel</label>
                    <input name='refInterneEquipement' className={`new-default-input${errors.refInterneEquipement && touched.refInterneEquipement ? " input-error" : ""}`} type="text" placeholder="Reférence du matériel" value={values.refInterneEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.refInterneEquipement && touched.refInterneEquipement && <p className="form-error-message">{errors.refInterneEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateDernierControleEquipement' className='form-label'>Dâte du dernier contrôle</label>
                    <input name='dateDernierControleEquipement' className={`new-default-input${errors.dateDernierControleEquipement && touched.dateDernierControleEquipement ? " input-error" : ""}`} type="date" placeholder="Datu du dernier contrôle" value={values.dateDernierControleEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.dateDernierControleEquipement && touched.dateDernierControleEquipement && <p className="form-error-message">{errors.dateDernierControleEquipement}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='dateProControleEquipement' className='form-label'>Dâte du prochain contrôle</label>
                    <input name='dateProControleEquipement' className={`new-default-input${errors.dateProControleEquipement && touched.dateProControleEquipement ? " input-error" : ""}`} type="date" placeholder="Datu du prochain contrôle" value={values.dateProControleEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.dateProControleEquipement && touched.dateProControleEquipement && <p className="form-error-message">{errors.dateProControleEquipement}</p>}
                </div>
                <div className="button-container-flex">
                    <button type="button" disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => close()}>Annuler</button>
                    <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} type="submit" className='btn btn-primary medium-btn fw' value="Modifier" />
                </div>
            </form>
        </div>
    );
};

export default PatchEquipementEpcForm;