import React, { useState } from "react";
import { getInsensitiveWorld, joinValuesWithSeparator } from "../../../../utils/GeneralHelper";

const IntervenantsItem = (props) => {
    const [checked, setChecked] = useState(props.editable ? props.defaultChecked : null);

    const onClickItem = () => {
        if (props.editable) {
            if (checked) {
                props.supprimerIntervenantsTiers();
            }
            else {
                props.ajouterIntervenantsTiersList();
            }
            setChecked(!checked);
        }
    };

    const onChangeCheck = () => {
        if (checked) {
            props.supprimerIntervenantsTiers();
        }
        else {
            props.ajouterIntervenantsTiersList();
        }
        setChecked(!checked);
    };

    const isIntervenantVisible = () => {
        return props.searchValue === null ||
            getInsensitiveWorld(props.intervenantTier.libelleIntervenant).includes(getInsensitiveWorld(props.searchValue)) ||
            getInsensitiveWorld(props.intervenantTier.categorieIntervenant).includes(getInsensitiveWorld(props.searchValue)) ||
            getInsensitiveWorld(props.intervenantTier.adresse.adresse).includes(getInsensitiveWorld(props.searchValue)) ||
            getInsensitiveWorld(props.intervenantTier.adresse.codePostal + " " + props.intervenantTier.adresse.ville).includes(getInsensitiveWorld(props.searchValue));
    };

    return (
        <div onClick={() => onClickItem()} className="container-basic intervenantsItem" style={{ opacity: checked === null || checked ? 1 : 0.5, cursor: props.editable ? "pointer" : "auto", display: isIntervenantVisible() ? "flex" : "none" }}>
            <div className="intervenantBasicInfo">
                <div>
                    <p><span className="intervenantNom">{props.intervenantTier.libelleIntervenant}</span></p>
                    <p className="intervenantCategorie">{props.intervenantTier.categorieIntervenant}</p>
                </div>
            </div>
            <div className={"intervenantAdresse"}>
                <p>{`${props.intervenantTier.adresse.hasOwnProperty("adresse") && props.intervenantTier.adresse.adresse !== null ? props.intervenantTier.adresse.adresse + " " : ""}${props.intervenantTier.adresse.hasOwnProperty("cs") && props.intervenantTier.adresse.cs !== null ? "CS " + props.intervenantTier.adresse.cs + " " : ""}${props.intervenantTier.adresse.codePostal !== null ? props.intervenantTier.adresse.codePostal + " " : ""}${props.intervenantTier.adresse.ville !== null ? props.intervenantTier.adresse.ville + " " : ""}`}</p>
            </div>
            <div className={`intervenantContacts${props.editable ? " selectable" : ""}`}>
                {/* <p>{`${props.intervenantTier.email !== null ? props.intervenantTier.email + props.intervenantTier.telephone !== null ? " - " : " " : ""}${props.intervenantTier.telephone !== null ? props.intervenantTier.telephone + " " : ""}`}</p> */}
                <p>
                    {
                        joinValuesWithSeparator([props.intervenantTier.email, props.intervenantTier.telephone], " - ")
                    }
                </p>
            </div>
            {props.editable &&
                <div className="editBox-container">
                    <input className="login-checkbox" type="checkbox" checked={checked} onChange={onChangeCheck} />
                </div>
            }
        </div>
    );
};

export default IntervenantsItem;
