import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import Modal from '../../components/Modal';
import AjouterSalarieForm from '../../components/forms/GestionsForms/AjouterSalarieForm';
import ModifySalarieForm from '../../components/forms/ModifySalarieForm';
import ArchiverSalarieForm from '../../components/forms/ArchiverSalarieForm';
import AjouterGestionTypeSuiviSalarieForm from '../../components/forms/DocumentsForms/AjouterGestionTypeSuiviSalarieForm';
import ModifierGestionTypeSuiviSalarieForm from '../../components/forms/ModifierGestionTypeSuiviSalarieForm';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import TableRender from '../../components/tables/TableRender';
import { getGestionSalarieColumns, getGestionSuiviFormationSalarieColumns } from '../../components/tables/TableColumns';
import { useNavigate } from 'react-router-dom';

import { advancedSearch } from '../../utils/GeneralHelper';
import Breadcrumb from '../../components/ui/Breadcrumb';
import SearchInput from '../../components/ui/SearchInput';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';

const onLoadPage = () => (actionCreators.getUserList());
const onQuitPage = () => (actionCreators.unloadUserList());

const onLoadPageSuivi = () => (actionCreators.getSuiviTypeFormationList());
const onQuitPageSuivi = () => (actionCreators.unloadSuiviTypeFormationList());
const addSuiviTypeFormation = (typeFormation) => (actionCreators.addSuiviTypeFormation(typeFormation));
const modifySuiviTypeFormation = (typeFormation) => (actionCreators.patchSuiviTypeFormation(typeFormation));
const deleteSuiviTypeFormation = (typeFormationId) => (actionCreators.deleteSuiviTypeFormation(typeFormationId));

const ajouterSalarie = (salarie, statut) => (actionCreators.addSalarie(salarie, statut));
const unloadTab = () => (actionCreators.unloadTopbarTab());
const setCurrentTab = (tabName) => (actionCreators.setTopbarCurrentTab(tabName));
// const resetScrollAction = () => (actionCreators.unloadTopbarScroll())


const GestionSalariePage = () => {
    const navigate = useNavigate();
    const userListState = useSelector(
        state => state.userList
    );
    const userState = useSelector(
        state => state.user
    );
    const dispatch = useDispatch();
    const [showedItem, setItemToShow] = useState([]);
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");
    const [itemStatutToShow, setItemStatutToShow] = useState("All");
    const [itemSecteurToShow, setItemSecteurToShow] = useState("All");
    const [searchBarContent, setSearchBarContent] = useState(null);

    useEffect(() => {
        dispatch(onLoadPage());
        dispatch(onLoadPageSuivi());
        dispatch(setCurrentTab("Liste des salariés"));
        return () => {
            dispatch(onQuitPage());
            dispatch(onQuitPageSuivi());
            dispatch(dispatch(unloadTab()));
            setItemToShow([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setItemToShow([]);
        if (itemStatutToShow === "All" || itemStatutToShow === "Administration") {
            // ADMIN
            if (userListState.adminList) {
                //console.log(userListState)
                userListState.adminList.forEach((admin) => {
                    if (admin._id.userRole.statut === "Administration") {
                        if (userState.user.login_matricule !== admin._id.login_matricule) {
                            if (((itemSecteurToShow === "All" || admin._id.secteur === itemSecteurToShow) && !admin._id.isAmiante) &&
                                (!searchBarContent || advancedSearch(admin._id.nom, searchBarContent) || advancedSearch(admin._id.prenom, searchBarContent) || advancedSearch(admin._id.login_matricule, searchBarContent))) {
                                let addAdmin = { userRole: "Administration", user: admin._id };
                                setItemToShow(oldArray => [...oldArray, addAdmin]);
                            }
                        }
                    }
                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Dispatcher") {
            // DISPATCHER
            if (userListState.adminList) {
                //console.log(userListState)
                userListState.adminList.forEach((admin) => {
                    if (admin._id.userRole.statut === "Dispatcher") {
                        if (((itemSecteurToShow === "All" || admin._id.secteur === itemSecteurToShow) && !admin._id.isAmiante) &&
                            (!searchBarContent || advancedSearch(admin._id.nom, searchBarContent) || advancedSearch(admin._id.prenom, searchBarContent) || advancedSearch(admin._id.login_matricule, searchBarContent))) {
                            let addAdmin = { userRole: "Dispatcher", user: admin._id };
                            setItemToShow(oldArray => [...oldArray, addAdmin]);
                        }
                    }
                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Encadrant technique") {
            if (userListState.chefChantierList) {
                userListState.chefChantierList.forEach((chefChantier) => {
                    if (chefChantier.hasOwnProperty("_id")) {
                        if (((itemSecteurToShow === "All" || chefChantier._id.secteur === itemSecteurToShow) && chefChantier._id.isAmiante && chefChantier._id.userRole.statut === "Encadrant technique") && (!searchBarContent || advancedSearch(chefChantier._id.nom, searchBarContent) || advancedSearch(chefChantier._id.prenom, searchBarContent) || advancedSearch(chefChantier._id.login_matricule, searchBarContent))) {
                            let addChefChantier = { userRole: "Encadrant technique", user: chefChantier._id };
                            setItemToShow(oldArray => [...oldArray, addChefChantier]);
                        }
                    }
                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Ouvrier") {
            // OUVRIER
            if (userListState.ouvrierList) {
                userListState.ouvrierList.forEach((ouvrier) => {
                    if (((itemSecteurToShow === "All" || ouvrier._id.secteur === itemSecteurToShow) && !ouvrier._id.isAmiante) && (!searchBarContent || advancedSearch(ouvrier._id.nom, searchBarContent) || advancedSearch(ouvrier._id.prenom, searchBarContent) || advancedSearch(ouvrier._id.login_matricule, searchBarContent))) {
                        let addOuvrier = { userRole: "Ouvrier", user: ouvrier._id };
                        setItemToShow(oldArray => [...oldArray, addOuvrier]);
                    }
                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Opérateur") {
            // OUVRIER
            if (userListState.ouvrierList) {
                userListState.ouvrierList.forEach((ouvrier) => {
                    if (((itemSecteurToShow === "All" || ouvrier._id.secteur === itemSecteurToShow) && ouvrier._id.isAmiante) && (!searchBarContent || advancedSearch(ouvrier._id.nom, searchBarContent) || advancedSearch(ouvrier._id.prenom, searchBarContent) || advancedSearch(ouvrier._id.login_matricule, searchBarContent))) {
                        let addOuvrier = { userRole: "Opérateur", user: ouvrier._id };
                        setItemToShow(oldArray => [...oldArray, addOuvrier]);
                    }
                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Intérimaire") {
            // INTERIMAIRE
            if (userListState.interimaireList) {
                userListState.interimaireList.forEach((interimaire) => {
                    if ((itemSecteurToShow === "All" || interimaire._id.secteur === itemSecteurToShow) && (!searchBarContent || advancedSearch(interimaire._id.nom, searchBarContent) || advancedSearch(interimaire._id.prenom, searchBarContent) || advancedSearch(interimaire._id.login_matricule, searchBarContent))) {
                        let addInterimaire = { userRole: "Intérimaire", user: interimaire._id };
                        setItemToShow(oldArray => [...oldArray, addInterimaire]);
                    }
                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Chauffeur") {
            // CHAUFFEUR
            if (userListState.chauffeurList) {
                userListState.chauffeurList.forEach((chauffeur) => {
                    if ((itemSecteurToShow === "All" || chauffeur._id.secteur === itemSecteurToShow) && (!searchBarContent || advancedSearch(chauffeur._id.nom, searchBarContent) || advancedSearch(chauffeur._id.prenom, searchBarContent) || advancedSearch(chauffeur._id.login_matricule, searchBarContent))) {
                        let addChauffeur = { userRole: "Chauffeur", user: { ...chauffeur._id, numeroPermis: chauffeur.numeroPermis } };
                        setItemToShow(oldArray => [...oldArray, addChauffeur]);
                    }
                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Conducteur de travaux") {
            // CHAUFFEUR
            if (userListState.dirigeantChantierList) {
                userListState.dirigeantChantierList.forEach((dirigeantChantier) => {
                    if ((itemSecteurToShow === "All" || dirigeantChantier._id.secteur === itemSecteurToShow) && (!searchBarContent || advancedSearch(dirigeantChantier._id.nom, searchBarContent) || advancedSearch(dirigeantChantier._id.prenom, searchBarContent) || advancedSearch(dirigeantChantier._id.login_matricule, searchBarContent))) {
                        let addDirigeantChantier = { userRole: "Conducteur de travaux", user: dirigeantChantier._id };
                        setItemToShow(oldArray => [...oldArray, addDirigeantChantier]);
                    }
                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Chef de chantier") {
            // CHEF CHANTIER
            if (userListState.chefChantierList) {
                userListState.chefChantierList.forEach((chefChantier) => {
                    if (chefChantier.hasOwnProperty("_id")) {
                        if (((itemSecteurToShow === "All" || chefChantier._id.secteur === itemSecteurToShow) && !chefChantier._id.isAmiante) && (!searchBarContent || advancedSearch(chefChantier._id.nom, searchBarContent) || advancedSearch(chefChantier._id.prenom, searchBarContent) || advancedSearch(chefChantier._id.login_matricule, searchBarContent))) {
                            let addChefChantier = { userRole: "Chef de chantier", user: chefChantier._id };
                            setItemToShow(oldArray => [...oldArray, addChefChantier]);
                        }
                    }

                });
            }
        }
        if (itemStatutToShow === "All" || itemStatutToShow === "Encadrant de chantier") {
            // CHEF CHANTIER
            if (userListState.chefChantierList) {
                userListState.chefChantierList.forEach((chefChantier) => {
                    if (chefChantier.hasOwnProperty("_id")) {
                        if (((itemSecteurToShow === "All" || chefChantier._id.secteur === itemSecteurToShow) && chefChantier._id.isAmiante && chefChantier._id.userRole.statut === "Encadrant de chantier") && (!searchBarContent || advancedSearch(chefChantier._id.nom, searchBarContent) || advancedSearch(chefChantier._id.prenom, searchBarContent) || advancedSearch(chefChantier._id.login_matricule, searchBarContent))) {
                            let addChefChantier = { userRole: "Encadrant de chantier", user: chefChantier._id };
                            setItemToShow(oldArray => [...oldArray, addChefChantier]);
                        }
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userListState, itemStatutToShow, searchBarContent, itemSecteurToShow]);
    const addSalarie = (salarie) => {
        dispatch(ajouterSalarie(salarie));
    };
    const openAddSalarie = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterSalarieForm ajouterSalarie={(salarie) => addSalarie(salarie)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    // const deleteSalarie = () => {
    //     dispatch(onQuitPage())
    //     dispatch(onLoadPage())
    // }

    const modifySalarie = () => {
        dispatch(onQuitPage());
        dispatch(onLoadPage());
    };

    const openModifySalarie = (salarieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifySalarieForm salarie={salarieData} modifierSalarie={() => modifySalarie()} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const archiverSalarie = () => {
        dispatch(onQuitPage());
        dispatch(onLoadPage());
    };

    const openArchiverSalarie = (salarieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ArchiverSalarieForm salarie={salarieData} archiverSalarie={() => archiverSalarie()} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const selectStatut = (e) => {
        setItemStatutToShow(e.target.value);
    };

    const selectSecteur = (e) => {
        setItemSecteurToShow(e.target.value);
    };

    const searchSalarie = (e) => {
        if (e.target.value !== '') {
            setSearchBarContent(e.target.value);
        }
        else {
            setSearchBarContent(null);
        }
    };

    // const scrollToRef = (ref) => ref.current.scrollIntoView({behavior: 'smooth', block: 'start'}) 
    // const listeSalarieScrollRef = useRef()
    // const exportScrollRef = useRef()
    // const suivreFormationScrollRef = useRef()

    const topBarState = useSelector(state => state.topBar.currentTab);
    const [insideNavigationPage, setInsideNavigationPage] = useState("listeSalariés");

    useEffect(() => {
        // if (topBarStateAction === "Liste des salariés") {
        //     window.scrollTo({
        //         top: 0,
        //         behavior: "smooth"
        //     });
        // }
        // else if (topBarStateAction === "Suivi et formation salariés") {
        //     scrollToRef(suivreFormationScrollRef)

        // }
        // else if (topBarStateAction === "Export") {
        //     scrollToRef(exportScrollRef)
        // }
    }, []);

    // const { ref: listeSalarieRef, inView: listeSalarieVisible } = useInView({threshold: 0.18})
    // const { ref: suiviFormationRef, inView: suiviFormationVisible } = useInView({treshold: 0.6})
    // const { ref: exportSalarieRef, inView: exportSalarieVisible } = useInView({threshold: 0.6})

    // useEffect(() => {
    //     if (listeSalarieVisible) {
    //         dispatch(setCurrentTab("Liste des salariés"))
    //     }
    //     else if (exportSalarieVisible) {
    //         dispatch(setCurrentTab("Export"))
    //     }
    //     else if (suiviFormationVisible) {
    //         dispatch(setCurrentTab("Suivi et formation salariés"))
    //     }
    // }, [listeSalarieVisible, exportSalarieVisible, suiviFormationVisible])


    // GESTION SUIVI SALARIE
    const suiviTypeFormationState = useSelector(
        state => state.suiviTypeFormation
    );
    const [showedSuiviFormationDoc, setShowedSuiviFormationDoc] = useState([]);
    const [searchSuiviFormation, setSearchSuiviFormation] = useState("");
    const [destineToShow, setDestineToShow] = useState("All");

    const openAddSuiviSalarie = () => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<AjouterGestionTypeSuiviSalarieForm ajouterSuiviFormationSalarie={(suiviFormation) => dispatch(addSuiviTypeFormation(suiviFormation))} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierSuiviSalarie = (suiviFormationId) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierGestionTypeSuiviSalarieForm suiviFormationId={suiviFormationId} modifierSuiviFormationSalarie={(suiviFormation) => dispatch(modifySuiviTypeFormation(suiviFormation))} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerSuiviSalarie = (suiviFormationId) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm suiviFormationId={suiviFormationId} supprimerSuiviFormationSalarie={(suiviFormation) => dispatch(deleteSuiviTypeFormation(suiviFormation))} destination="supprimerSuiviTypeFormation" closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };



    // useEffect(() => {
    //     setShowedSuiviFormationDoc(allSuiviTypeFormation.filter(suiviFormation => {

    //          return true//(!searchSuiviFormation.length || suiviFormation.libelleGestion.toLowerCase().includes(searchSuiviFormation.toLowerCase()) || searchSuiviFormation.toLowerCase().includes(suiviFormation.libelleGestion.toLowerCase())) && (destineToShow === "All" || suiviFormation.typeGestion === destineToShow)
    //     }))
    //     console.log("Actu ", {showedSuiviFormationDoc, allSuiviTypeFormation})

    //     // if (itemStatutToShow === "All" || itemStatutToShow === "Administration") {
    //     //     // ADMIN
    //     //     if(userListState.adminList) {
    //     //         userListState.adminList.forEach((admin) => {
    //     //             console.log(admin)
    //     //             if (userState.user.login_matricule !== admin._id.login_matricule) {
    //     //                 if (((itemSecteurToShow === "All" || admin._id.secteur === itemSecteurToShow) && !admin._id.isAmiante) && (!searchBarContent || admin._id.nom.toLowerCase().includes(searchBarContent.toLowerCase()) || admin._id.prenom.toLowerCase().includes(searchBarContent.toLowerCase()) || admin._id.login_matricule.toLowerCase().includes(searchBarContent.toLowerCase()))) {
    //     //                     let addAdmin = {userRole: "Administration", user: admin._id};
    //     //                     setItemToShow(oldArray => [...oldArray, addAdmin]);
    //     //                 }
    //     //             }
    //     //         });
    //     //     }
    //     // }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [allSuiviTypeFormation, searchSuiviFormation, destineToShow])


    useEffect(() => {
        setShowedSuiviFormationDoc(suiviTypeFormationState.allSuiviTypeFormationState.filter(suiviFormation => (!searchSuiviFormation.length || advancedSearch(suiviFormation.libelleGestion, searchBarContent) || advancedSearch(searchSuiviFormation, searchBarContent) && (destineToShow === "All" || suiviFormation.typeGestion === destineToShow))));

    }, [suiviTypeFormationState.allSuiviTypeFormationState, searchSuiviFormation, destineToShow]);



    return (
        <div className='section'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                <h1>{insideNavigationPage === "listeSalariés" ? "Liste des salariés" : "Export"}</h1>
                <button onClick={() => openAddSalarie()} className='btn btn-primary medium-btn'>+ Salarié</button>
            </div>
            <Breadcrumb
                links={[
                    { label: "Geston des données", link: "/gestion-de-donnees" },
                    { label: "Salariés", link: "/gestion-de-donnees/salaries" },
                ]}
            />
            <InsideNavigationBar
                activeValue={insideNavigationPage}
                setActiveValue={(value) => setInsideNavigationPage(value)}
                links={[
                    { label: "Liste des salariés", value: "listeSalariés" },
                    { label: "Export", value: "export" }
                ]}
            />
            {insideNavigationPage === "listeSalariés" &&
                <>
                    <div className="filterContainer">
                        <SearchInput placeholder="Rechercher un salarié" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={searchSalarie} />
                        <select onChange={(e) => selectSecteur(e)} name="secteurSelect" id="secteur-select" className='new-default-input'>
                            <option value="All">Tous les secteurs</option>
                            <option value="Travaux publics">Travaux publics</option>
                            <option value="Gros oeuvres">Gros oeuvres</option>
                            <option value="3D">3D</option>
                            <option value="Transport">Transport</option>
                            <option value="Atelier">Atelier</option>
                        </select>
                        <select onChange={(e) => selectStatut(e)} name="statutSelect" id="statut-select" className='new-default-input'>
                            <option value="All">Tous les statuts</option>
                            <option value="Administration">Administration</option>
                            <option value="Chauffeur">Chauffeur</option>
                            <option value="Chef de chantier">Chef de chantier</option>
                            <option value="Conducteur de travaux">Conducteur de travaux</option>
                            <option value="Dispatcher">Dispatcher</option>
                            <option value="Encadrant de chantier">Encadrant de chantier</option>
                            <option value="Encadrant technique">Encadrant technique</option>
                            <option value="Intérimaire">Intérimaire</option>
                            <option value="Opérateur">Opérateur</option>
                            <option value="Ouvrier">Ouvrier</option>
                        </select>
                    </div>

                    <TableRender
                        columns={getGestionSalarieColumns((salarieData) => openModifySalarie(salarieData), (salarieData) => openArchiverSalarie(salarieData), (link) => navigate(link))}
                        tableData={showedItem}
                        sortBy={[
                            {
                                id: 'user.nom',
                                desc: false,
                                disableSortRemove: true,
                            }
                        ]}
                        pageName="GestionSalarie"
                    />
                </>
            }

            {topBarState === "Suivi et formation salariés" &&
                <>
                    <div className="suivi-formation-wrapper">
                        <div style={{ scrollMarginTop: "8rem" }} className="container-basic mgB-m2">
                            <h1 className="h1-blue-dark">Suivi documents et formations salariés</h1>
                            <div className="tableau-top-section">
                                <h3>{showedSuiviFormationDoc.length} document{showedSuiviFormationDoc.length > 1 && "s"} {showedSuiviFormationDoc.length > 1 ? "correspondent" : "correspond"} à votre recherche</h3>
                                <input value={searchSuiviFormation} className="header-input" onChange={(e) => setSearchSuiviFormation(e.target.value)} name="searchChantier" type="text" placeholder="Rechercher une formation, document,..." />
                                <select value={destineToShow} className="header-select" onChange={(e) => setDestineToShow(e.target.value)} name="statutSelect" id="statut-select">
                                    <option value="All">Tout le monde</option>
                                    <option value="Tous les salariés">Tous les salariés</option>
                                    <option value="Les salariés amiante">Les salariés amiante</option>
                                    <option value="Les chauffeurs">Les chauffeurs</option>
                                </select>
                                <button onClick={() => openAddSuiviSalarie()} className='default-button default-button-blue'>+ SuiviFormation</button>
                            </div>
                        </div>
                    </div>
                    <TableRender
                        columns={getGestionSuiviFormationSalarieColumns((suiviFormationId) => openModifierSuiviSalarie(suiviFormationId), (suiviFormationId) => openSupprimerSuiviSalarie(suiviFormationId))}
                        tableData={showedSuiviFormationDoc}
                        sortBy={[
                            {
                                id: 'libelleGestion',
                                desc: false,
                                disableSortRemove: true,
                            }
                        ]}
                        pageName="GestionSuiviFormationSalarie"
                    />
                </>
            }
            {topBarState === "Export" &&
                <>
                    <div className="gestion-exports-wrapper">
                        <div className="container-basic mgB-m2">
                            <h1 className="h1-blue-dark">Export des salariés</h1>
                            <div className="tableau-top-section">
                                <h3>{showedSuiviFormationDoc.length} document{showedSuiviFormationDoc.length > 1 && "s"} {showedSuiviFormationDoc.length > 1 ? "correspondent" : "correspond"} à votre recherche</h3>
                                <input className="header-input" onChange={(e) => console.log(e)} name="searchChantier" type="text" placeholder="Rechercher un document" />
                                <button onClick={() => console.log()} className='default-button default-button-blue'>+ Document</button>
                            </div>
                        </div>
                        <div className="main-content">
                            <table className="default-table">
                                <thead>
                                    <tr>
                                        <th>test1</th>
                                        <th>test2</th>
                                        <th>test3</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>test1</td>
                                        <td>test2</td>
                                        <td>test3</td>
                                    </tr>
                                    <tr>
                                        <td>test1</td>
                                        <td>test2</td>
                                        <td>test3</td>
                                    </tr>
                                    <tr>
                                        <td>test1</td>
                                        <td>test2</td>
                                        <td>test3</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </div>
    );
};

export default GestionSalariePage;
