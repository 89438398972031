import React from 'react';

const InsideNavigationBar = (props) => {
    return (
        <div className='insideNavigationContainer mgB-s3'>
            <ul>
                {props.links.map((link) => {
                    if (link.value === props.activeValue) {
                        return <li key={link.value} onClick={() => props.setActiveValue(link.value)} className='active'><p>{link.label}</p></li>;
                    }
                    else {
                        return <li key={link.value} onClick={() => props.setActiveValue(link.value)}><p>{link.label}</p></li>;
                    }
                })}
            </ul>
        </div>
    );
};

export default InsideNavigationBar;