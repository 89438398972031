import React from 'react';
import { useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';

const Breadcrumb = (props) => {
    const navigate = useNavigate();

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {props.links.map((link, index) => {
                if (index === props.links.length - 1) {
                    return <div className='breadcrumb-link' style={{ display: "flex", alignItems: "center" }} key={link.label}>
                        <p style={{ fontSize: "1.2rem", fontWeight: 500 }}>{link.label}</p>
                    </div>;
                }
                else {
                    return <div style={{ display: "flex", alignItems: "center" }} className='breadcrumb-link' onClick={() => navigate(link.link)} key={link.label}>
                        <p style={{ fontSize: "1.2rem", opacity: 0.4, fontWeight: 500 }}>{link.label}</p>
                        <Icon style={{ width: "2rem", height: "2rem", opacity: 0.4 }} icon="ri:arrow-right-s-line" />
                    </div>;
                }
            })}
        </div>
    );
};

export default Breadcrumb;