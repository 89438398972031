import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import customToast from '../../utils/ToastifyHelper';
import * as actionTypes from './actionTypes';

const loadMateriauxList = (materiauxList) => {
    return {
        type: actionTypes.LOAD_MATERIAUX_LIST,
        materiauxList: materiauxList
    };
};

export const unloadMateriauxList = () => {
    return {
        type: actionTypes.UNLOAD_MATERIAUX_LIST,
    };
};

export const addMateriaux = (materiauxData) => {
    return {
        type: actionTypes.ADD_MATERIAUX,
        materiaux: materiauxData
    };
};

export const modifyMateriaux = (materiauxNewData) => {
    return {
        type: actionTypes.MODIFY_MATERIAUX,
        materiauxUpdated: materiauxNewData
    };
};

export const deleteMateriaux = (materiauxId) => {
    return {
        type: actionTypes.DELETE_MATERIAUX,
        materiauxId: materiauxId
    };
};

export const getMateriauxList = () => (dispatch) => {
    // get all materiaux
    axiosInstance.get('/materiaux', { headers: { 'x-auth-token': sessionStorage.getItem('jwtToken') } })
        .then((result) => {
            dispatch(loadMateriauxList(result.data));
        }).catch((err) => {
            showError(err);
        });
};