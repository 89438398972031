import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions/exportAction';
import { showError } from '../../utils/ErrorHelper';
import { Icon } from "@iconify/react";
import EditIcon from "../../components/icon/EditIcon";
import ModifySalarieForm from '../../components/forms/ModifySalarieForm';
import { format } from "date-fns";
import { banqueHeureTotal, formatIntTwoDigitString } from '../../utils/GeneralHelper';
import fileDownload from 'js-file-download';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import customToast from '../../utils/ToastifyHelper';
import ClipLoader from "react-spinners/ClipLoader";
import AjouterDocumentsSalarieForms from '../../components/forms/DocumentsForms/AjouterDocumentsSalarieForms';

const ProfilSalarie = () => {
  let { idSalarie } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [salarie, setSalarie] = useState(null);
  const [salarieBanqueHeure, setSalarieBanqueHeure] = useState(null);
  const [statAmiante, setStatAmiante] = useState(null);
  const [dateExport, onChangeDate] = useState([new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)]);
  const [isLoading, setIsLoading] = useState(false);
  const [typeDocument, setDocumentType] = useState([]);

  useEffect(() => {
    //load salarie data
    axiosInstance.get(`utilisateurs/${idSalarie}`).then((result) => {
      setSalarie(result.data);
      if (result.data.isAmiante) {
        axiosInstance.get(`entree_sortie_zone/stats/${idSalarie}`).then((res) => {
          setStatAmiante(res.data);
        }).catch((error) => {
          showError(error);
        });
      }
    }).catch((err) => {
      showError(err);
    });
    //load salarie banque heure
    axiosInstance.get(`/banque_heure/${idSalarie}`)
      .then((result) => {
        setSalarieBanqueHeure(result.data);
      }).catch((err) => {
        showError(err);
      });
    // LOAD TYPE DE DOC SALARIE
    axiosInstance.get(`/gestionTypeSuivie`)
      .then((result) => {
        setDocumentType(result.data);
      }).catch((err) => {
        showError(err);
      });

  }, [idSalarie]);

  const modifierProfilSalarie = (salarieData) => {
    setSalarie(salarieData);
  };

  // Gestion Modal
  const [modalClasses, setModalClasses] = useState("modal");
  const [modalContent, setModalContent] = useState("Aucun contenu");

  const openModifySalarie = (salarieData) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<ModifySalarieForm editFromProfile={true} salarie={salarieData} modifierProfilSalarie={(salarieData) => modifierProfilSalarie(salarieData)} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openAjouterDocSalarie = (typeDocumentSuivi) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<AjouterDocumentsSalarieForms typeDocumentSuivi={typeDocumentSuivi} salarieData={salarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const totalHeure = salarieBanqueHeure !== null ? banqueHeureTotal(salarieBanqueHeure) : { heure: 0, minute: 0 };

  const exportData = () => {
    if (dateExport !== null && dateExport[0] !== null && dateExport[1] !== null) {
      setIsLoading(true);
      axiosInstance.get(`/export_excel/vacationSalarie/${idSalarie}?dateDebutexport=${format(dateExport[0], "yyyy-MM-dd")}&dateFinExport=${format(dateExport[1], "yyyy-MM-dd")}`, {
        responseType: 'blob',
      })
        .then((result) => {
          fileDownload(result.data, `ExportVacations-${salarie.login_matricule}_${salarie.prenom}_${salarie.nom}-${format(dateExport[0], "dd/MM/yyyy")}_${format(dateExport[1], "dd/MM/yyyy")}.xlsx`);
          setIsLoading(false);
        }).catch((err) => {
          showError(err);
          setIsLoading(false);
        });
    }
    else {
      customToast.error("Sélectionner une date de début et de fin de période pour télécharger l'export");
    }
  };

  return (
    <div className='section'>
      {salarie ?
        <>
          <div className='container-basic mgB-m2'>
            <div className="profilSalarie-Header">
              <div className="leftSide">
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Icon
                    onClick={() => navigate(-1)}
                    className="backButton"
                    icon="akar-icons:chevron-left"
                  />
                  <div>
                    <h1 className="h1-blue-dark">{salarie.prenom} {salarie.nom}</h1>
                    <h3 className="banqueHeure-Total">{salarie.login_matricule}</h3>
                  </div>
                </div>
              </div>
              <div>
                <button onClick={() => openModifySalarie({
                  user: salarie,
                  userRole: salarie.userRole.statut
                }
                )} className='fullEdit-button green-button'><EditIcon /><p>Modifier</p></button>
              </div>
              {/* <div className="container-flex">
                          <button onClick={() => console.log("")} className='fullEdit-button yellow-button'><Icon className='icon-button' icon="fluent:archive-28-regular" /><p>Archiver</p></button>
                      </div> */}
            </div>
          </div>
          <div className="container-basic mgB-m2">
            <h2 className="section-title mgB-s2">Informations générales</h2>
            {salarie.hasOwnProperty("telephone") && salarie.telephone !== "" &&
              <div className="mgB-s1">
                <p className="small-title">Téléphone</p>
                <p className="small-text">
                  {salarie.telephone}
                </p>
              </div>
            }
            <div className="mgB-s1">
              <p className="small-title">Secteur d'activité</p>
              <p className="small-text">{salarie.secteur}</p>
            </div>
            <div className="mgB-s1">
              <p className="small-title">Statut</p>
              {
                salarie.historiqueStatut.map((statut) => {
                  return (
                    <p key={statut._id} className="small-text">{format(new Date(statut.dateDebutStatut), "dd/MM/yyyy")} - {statut.statut}</p>
                  );
                })
              }
            </div>
            <div>
              <p className="small-title">Total banque heure</p>
              <p className="small-text">
                {totalHeure.val === "neg" ?
                  `-${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}` :
                  totalHeure.val === "pos" ?
                    `+${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}` :
                    `${formatIntTwoDigitString(totalHeure.heure)}h${formatIntTwoDigitString(totalHeure.minute)}`
                }
              </p>
            </div>
          </div>
          <div className="container-basic mgB-m2">
            <div className="container-flex mgB-s2" style={{ justifyContent: "space-between", alignItems: "center" }}>
              <h2 className="section-title">Documents</h2>
              <button onClick={() => openAjouterDocSalarie(typeDocument)} className='default-button default-button-black mgL-s2'>Ajouter un document</button>
            </div>
          </div>
          {salarie.isAmiante && statAmiante !== null &&
            <div className="container-basic mgB-m2">
              <h2 className="section-title mgB-s2">Amiante</h2>
              <div className="mgB-s1">
                <p className="small-title">Nombre de vacations</p>
                <p className="small-text">
                  {statAmiante.entreesSortiesZoneTotal} vacation{statAmiante.entreesSortiesZoneTotal > 1 ? "s" : ""} effectuée{statAmiante.entreesSortiesZoneTotal > 1 ? "s" : ""} au total
                </p>
                <p className="small-text">
                  {statAmiante.entreesSortiesZoneTotalCurrentMonth} vacation{statAmiante.entreesSortiesZoneTotalCurrentMonth > 1 ? "s" : ""} effectuée{statAmiante.entreesSortiesZoneTotalCurrentMonth > 1 ? "s" : ""} ce mois-ci
                </p>
              </div>
              <div className="mgB-s1">
                <p className="small-title">Exporter les vacations</p>
                <div className="container-flex mgT-s1">
                  {/* <label className='small-text' htmlFor="nom">Sélectionner la période d'export: </label> */}
                  <DateRangePicker calendarIcon={null} minDate={new Date("2022-01-01")} maxDate={new Date("2099-12-31")} className="input-datetimePicker searchDate-select" showLeadingZeros={true} format="dd/MM/yyyy" onChange={onChangeDate} value={dateExport} />
                  <button onClick={() => exportData()} className='fullEdit-button black-button'>{isLoading ? <ClipLoader loading={isLoading} color={"#dcd8d3"} size={15} /> : <Icon icon="eva:download-fill" />}<p>Télécharger</p></button>
                </div>
              </div>

            </div>
          }
        </>
        :
        <p>Loading</p>
      }

      <Modal modalClass={modalClasses}>{modalContent}</Modal>
    </div>
  );
};

export default ProfilSalarie;