import React, { useRef, useState, useMemo, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';
import * as yup from "yup";
import { useFormik } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from '../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import { useNavigate } from 'react-router-dom';


const AjouterActualites = () => {
    const progressBarRef = useRef(null);
    const [articleImage, setArticleImage] = useState(null);
    const navigate = useNavigate();

    const formSchema = yup.object().shape({
        titre: yup.string().required("Veuillez saisir le titre de l'article"),
        articleContent: yup.string().required("Veuillez saisir le contenu de l'article")
    });

    const onSubmit = (values) => {
        progressBarRef.current.continuousStart();
        let postData = { ...values };
        if (articleImage !== null) {
            let picturePostData = new FormData();
            picturePostData.append("titre", articleImage.image.path.split(".").slice(0, -1)[0].normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
            picturePostData.append("document", articleImage.image);
            picturePostData.append("documentSaveRoute", "saveImages");
            axiosInstance.post("/uploads", picturePostData, { timeout: 0, headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {
                    postData.picture = result.data.picture;
                    axiosInstance.post("/actualites", postData).then((res) => {
                        progressBarRef.current.complete();
                        setSubmitting(false);
                        URL.revokeObjectURL(articleImage.preview);
                        navigate(`/justeau-actus/modifier-actualite/${res.data.actualite}`, { replace: true });
                    }).catch((error) => {
                        progressBarRef.current.complete();
                        setSubmitting(false);
                        showError(error);
                    });
                }).catch((err) => {
                    progressBarRef.current.complete();
                    setSubmitting(false);
                    showError(err);
                });
        }
        else {
            axiosInstance.post("/actualites", postData).then((res) => {
                progressBarRef.current.complete();
                setSubmitting(false);
                navigate(`/justeau-actus/modifier-actualite/${res.data.actualite}`, { replace: true });
            }).catch((error) => {
                setSubmitting(false);
                showError(error);
            });
        }
    };

    // Handle formm
    const { values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting, submitForm, setSubmitting } = useFormik({
        initialValues: {
            titre: "",
            articleContent: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': []
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (articleImage !== null) {
            URL.revokeObjectURL(articleImage.preview);
        }
        if (acceptedFiles.length) {
            setArticleImage({ image: acceptedFiles[0], preview: URL.createObjectURL(acceptedFiles[0]) });
        }
    }, [acceptedFiles]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => {
            if (articleImage !== null) {
                URL.revokeObjectURL(articleImage.preview);
            }
        };
    }, []);

    const deletePicture = () => {
        URL.revokeObjectURL(articleImage.preview);
        setArticleImage(null);
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div className='mgB-m1' style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                }}>
                    <h1>Ajouter une actualité</h1>
                    <button onClick={() => submitForm()} disabled={isSubmitting} style={{ opacity: !isSubmitting ? 1 : 0.4 }} className="btn btn-primary medium-btn" type="submit" form="addArticle">Sauvegarder</button>
                </div>
                <div className='container-basic' id="addArticle">
                    <form className='form'>
                        <div className='label-and-input'>
                            <label htmlFor='nom' className='form-label'><h4 className=''>Titre</h4></label>
                            <input name='titre' className={`new-default-input${errors.titre && touched.titre ? " input-error" : ""}`} type="text" placeholder="Titre de l'article" value={values.titre} onChange={handleChange} onBlur={handleBlur} />
                            {errors.titre && touched.titre && <p className="form-error-message">{errors.titre}</p>}
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='articleContent' className='form-label'>Contenu de l'article</label>
                            <div className='mgT-s2'>
                                <Editor
                                    textareaName='articleContent'
                                    apiKey='amwslsmq87or9tnsmqt8c5hikteg74lm1rmajlr0isluzbx9'
                                    init={{
                                        branding: false,
                                        menubar: false,
                                        image_advtab: false,
                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link'
                                    }}
                                    onEditorChange={(newText) => { setFieldValue("articleContent", newText); }}
                                />
                            </div>
                            {errors.articleContent && touched.articleContent && <p className="form-error-message">{errors.articleContent}</p>}
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='image' className='form-label'>Image principale</label>
                            {articleImage === null
                                ?
                                <div className='mgT-s2'>
                                    <div {...getRootProps({ style })}>
                                        <input {...getInputProps()} />
                                        <Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                        <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className='mgT-s2' style={{
                                        overflow: 'hidden',
                                    }}>
                                        <div className='addImageContainer'>
                                            <img
                                                src={articleImage.preview}
                                                className='articleImage'
                                                // style={{  
                                                //     display: 'block',
                                                //     maxWidth: '100%',
                                                //     maxHeight: '450px'
                                                // }}
                                                onLoad={() => { URL.revokeObjectURL(articleImage.preview); }}
                                            >
                                            </img>
                                            <div className='imageDeleteOverlay'>
                                                <button onClick={() => deletePicture()} className="btn_image"><FontAwesomeIcon icon={faTrashCan} /></button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </form>
                </div>

            </div>
        </>

    );
};

export default AjouterActualites;