import React, { useState } from 'react';
import customToast from '../../utils/ToastifyHelper';
import * as actionCreators from '../../store/actions/exportAction';
import axiosInstance from '../../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import { showError } from '../../utils/ErrorHelper';

const modifierCategorie = (categorie) => (actionCreators.modifyCategorie(categorie));

const ModifierCategorieDocumentsForm = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [modifierCategorieText, setModifierCategorieText] = useState(props.categorie.libelleCategorie);
    const dispatch = useDispatch();

    const onSubmit = () => {
        setButtonDisabled(true);
        if (modifierCategorieText.length > 0) {
            axiosInstance.patch(`/categories/modifierLibelle/${props.categorie._id}`, { libelleCategorie: modifierCategorieText })
                .then((result) => {
                    dispatch(modifierCategorie(result.data));
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
        }
        else {
            customToast.error("Veuillez entrer un nom de catégorie");
            setButtonDisabled(false);
        }
    };

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier une catégorie de document</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <label className='ajouter-form-label default-label' htmlFor="nom">Nom de la catégorie</label>
                <input value={modifierCategorieText} onChange={(e) => setModifierCategorieText(e.target.value)} className="ajouter-form-input default-input" name="nom" type="text" placeholder="Entrez le nom de la catégorie" />
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <button disabled={isButtonDisabled} className="default-button modifier-form-valider" onClick={() => onSubmit()}>Valider</button>
                </div>
            </div>
        </div>
    );
};

export default ModifierCategorieDocumentsForm;