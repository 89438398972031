import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useFormik } from 'formik';
import Select from 'react-select';
import { useDropzone } from "react-dropzone";
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, iconExtension, formatBytes } from '../../../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import { addYears, format } from "date-fns";
import axiosInstance from "../../../../utils/axiosInstance";
import { showError } from "../../../../utils/ErrorHelper";
import LoadingBar from "react-top-loading-bar";
import customToast from "../../../../utils/ToastifyHelper";

const AjouterDechetChantierAmiante = (props) => {
    const controller = new AbortController();
    const [progress, setProgress] = useState(0);

    const close = () => {
        controller.abort();
        props.closeModal();
        props.unrenderForm();
    };

    //--------------- FORM SCHEMA ------------------
    const formSchema = yup.object().shape({
        dechet: yup.object().required("Veuillez sélectionner un déchet").shape({
            value: yup.string().required("Veuillez sélectionner un déchet"),
            label: yup.string().required("Veuillez sélectionner un déchet"),
        }),
        quantite: yup.number().required("Veuillez saisir la quantité estimée du déchet"),
        zoneAmiante: yup.object().required("Veuillez sélectionner la zone ou se trouve le dechet").shape({
            value: yup.string().nullable(),
            label: yup.string().required("Veuillez sélectionner la zone ou se trouve le dechet"),
        }),
        urlBsda: yup.string().nullable().notRequired(),
        dateDebutValiditeFid: yup.date().nullable().notRequired(),
        dateFinValiditeFid: yup.date().nullable().notRequired(),
        dateDebutValiditeCap: yup.date().nullable().notRequired(),
        dateFinValiditeCap: yup.date().nullable().notRequired(),
    });

    const onSubmit = (values, actions) => {
        // bar progress
        const config = {
            onUploadProgress: progressEvent => {
                let calculateProgress = progress;
                calculateProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress(Math.floor(calculateProgress));
            }
        };

        let postData = new FormData();
        postData.append("dechet", values.dechet.value);
        postData.append("quantite", values.quantite);
        postData.append("zoneAmiante", values.zoneAmiante.value);
        postData.append("dateDebutValiditeFid", fidDocumentSelected ? values.dateDebutValiditeFid : null);
        postData.append("dateFinValiditeFid", fidDocumentSelected ? values.dateFinValiditeFid : null);
        postData.append("dateDebutValiditeCap", capDocumentSelected ? values.dateDebutValiditeCap : null);
        postData.append("dateFinValiditeCap", capDocumentSelected ? values.dateFinValiditeCap : null);
        postData.append("urlBsda", bsdaDocumentSelected ? values.urlBsda : null);
        if (fidDocumentSelected !== null) {
            postData.append("fid", fidDocumentSelected);
            postData.append("nomFichierFid", fidDocumentSelected ? nomFichierFid : null);
        }
        if (capDocumentSelected !== null) {
            postData.append("cap", capDocumentSelected);
            postData.append("nomFichierCap", capDocumentSelected ? nomFichierCap : null);
        }
        if (bsdaDocumentSelected !== null) {
            postData.append("bsda", bsdaDocumentSelected);
            postData.append("nomFichierBsda", bsdaDocumentSelected ? nomFichierBsda : null);
        }
        console.log(postData);
        axiosInstance.post(`/chantiersAmiante/ajouterDechet/${props.idChantier}`, postData, { ...config, timeout: 0, signal: controller.signal, headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
            actions.setSubmitting(false);
            customToast.success("Le déchet a été ajouté au chantier");
            console.log(result.data);
            props.ajouterDechetChantier(result.data);
            close();
        }).catch((err) => {
            actions.setSubmitting(false);
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            dechet: undefined,
            zoneAmiante: undefined,
            quantite: undefined,
            urlBsda: "",
            dateDebutValiditeFid: format(new Date(), "yyyy-MM-dd"),
            dateFinValiditeFid: undefined,
            dateDebutValiditeCap: format(new Date(), "yyyy-MM-dd"),
            dateFinValiditeCap: undefined
        },
        validationSchema: formSchema,
        onSubmit
    });

    const [fidDocumentSelected, setFidDocumentSelected] = useState(null);
    const [capDocumentSelected, setCapDocumentSelected] = useState(null);
    const [bsdaDocumentSelected, setBsdaDocumentSelected] = useState(null);

    // fidDocumentSelected
    const {
        acceptedFiles: acceptedFidFiles,
        getRootProps: getRootPropsFid,
        getInputProps: getInputPropsFid,
        isFocused: isFocusedFid,
        isDragAccept: isDragAcceptFid,
        isDragReject: isDragRejectFid
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    // capDocumentSelected
    const {
        acceptedFiles: acceptedCapFiles,
        getRootProps: getRootPropsCap,
        getInputProps: getInputPropsCap,
        isFocused: isFocusedCap,
        isDragAccept: isDragAcceptCap,
        isDragReject: isDragRejectCap
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    // bsdaDocumentSelected
    const {
        acceptedFiles: acceptedBsdaFiles,
        getRootProps: getRootPropsBsda,
        getInputProps: getInputPropsBsda,
        isFocused: isFocusedBsda,
        isDragAccept: isDragAcceptBsda,
        isDragReject: isDragRejectBsda
    } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'image/jpeg': [],
            'image/png': []
        }
    });

    const styleFid = useMemo(() => ({
        ...baseStyle,
        marginBottom: 0,
        ...(isFocusedFid ? focusedStyle : {}),
        ...(isDragAcceptFid ? acceptStyle : {}),
        ...(isDragRejectFid ? rejectStyle : {})
    }), [
        isFocusedFid,
        isDragAcceptFid,
        isDragRejectFid
    ]);

    const styleCap = useMemo(() => ({
        ...baseStyle,
        marginBottom: 0,
        ...(isFocusedCap ? focusedStyle : {}),
        ...(isDragAcceptCap ? acceptStyle : {}),
        ...(isDragRejectCap ? rejectStyle : {})
    }), [
        isFocusedCap,
        isDragAcceptCap,
        isDragRejectCap
    ]);

    const styleBsda = useMemo(() => ({
        ...baseStyle,
        marginBottom: 0,
        ...(isFocusedBsda ? focusedStyle : {}),
        ...(isDragAcceptBsda ? acceptStyle : {}),
        ...(isDragRejectBsda ? rejectStyle : {})
    }), [
        isFocusedBsda,
        isDragAcceptBsda,
        isDragRejectBsda
    ]);

    const [nomFichierFid, setNomFichierFid] = useState("");
    const [nomFichierCap, setNomFichierCap] = useState("");
    const [nomFichierBsda, setNomFichierBsda] = useState("");

    useEffect(() => {
        if (acceptedFidFiles.length) {
            setFidDocumentSelected(acceptedFidFiles[0]);
            setNomFichierFid(acceptedFidFiles[0].path.split(".").slice(0, -1)[0]);
        }
    }, [acceptedFidFiles]);

    useEffect(() => {
        if (acceptedCapFiles.length) {
            setCapDocumentSelected(acceptedCapFiles[0]);
            setNomFichierCap(acceptedCapFiles[0].path.split(".").slice(0, -1)[0]);
        }
    }, [acceptedCapFiles]);

    useEffect(() => {
        if (acceptedBsdaFiles.length) {
            setBsdaDocumentSelected(acceptedBsdaFiles[0]);
            setNomFichierBsda(acceptedBsdaFiles[0].path.split(".").slice(0, -1)[0]);
        }
    }, [acceptedBsdaFiles]);

    useEffect(() => {
        if (values.dateDebutValiditeFid !== "" && values.dateDebutValiditeFid !== undefined) {
            let dateExpiration = new Date(values.dateDebutValiditeFid);
            dateExpiration = addYears(dateExpiration, 1);
            setFieldValue("dateFinValiditeFid", format(dateExpiration, "yyyy-MM-dd"));
        }
    }, [values.dateDebutValiditeFid]);

    useEffect(() => {
        if (values.dateDebutValiditeCap !== "" && values.dateDebutValiditeCap !== undefined) {
            let dateExpiration = new Date(values.dateDebutValiditeCap);
            dateExpiration = addYears(dateExpiration, 1);
            setFieldValue("dateFinValiditeCap", format(dateExpiration, "yyyy-MM-dd"));
        }
    }, [values.dateDebutValiditeCap]);

    useEffect(() => {
        if (values.dateDebutValidationBsda !== "" && values.dateDebutValidationBsda !== undefined) {
            let dateExpiration = new Date(values.dateDebutValidationBsda);
            dateExpiration = addYears(dateExpiration, 1);
            setFieldValue("dateFinValidationBsda", format(dateExpiration, "yyyy-MM-dd"));
        }
    }, [values.dateDebutValidationBsda]);

    return (
        <>
            <LoadingBar color={"#d13852"} progress={progress} onLoaderFinished={() => setProgress(0)} />
            <div className="new-modal">
                <button className='modal-button' onClick={() => close()}>x</button>
                <div className="new-modal-topSection">
                    <h3 className='modal-title'>Ajouter un déchet</h3>
                </div>
                <div className="new-modal-mainSection">
                    <form className="fh-form" onSubmit={handleSubmit}>
                        <div className='label-and-input'>
                            <label htmlFor='dechet' className='form-label'>Déchet<span className='required-field'>*</span></label>
                            <Select
                                name="dechet"
                                options={props.dechetsOptions ? props.dechetsOptions : []}
                                isLoading={!props.dechetsOptions.length}
                                isDisabled={!props.dechetsOptions.length}
                                className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => setFieldValue("dechet", opt ? opt : "")}
                                placeholder={"Sélectionner un déchet"}
                                value={values.dechet ? values.dechet : ""}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        fontFamily: "DM Sans",
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                isClearable={true}
                            />
                            {errors.dechet && touched.dechet && <p className="form-error-message">{errors.dechet.value || errors.dechet.label}</p>}
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='quantite' className='form-label'>Quantité<span className='required-field'>*</span></label>
                            <input name='quantite' className={`new-default-input${errors.quantite && touched.quantite ? " input-error" : ""}`} type="number" placeholder="Quantité de déchet estimée en t" value={values.quantite !== undefined ? values.quantite : ""} onChange={handleChange} onBlur={handleBlur} />
                            {errors.quantite && touched.quantite && <p className="form-error-message">{errors.quantite}</p>}
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='zoneAmiante' className='form-label'>Zone du chantier<span className='required-field'>*</span></label>
                            <Select
                                name="zoneAmiante"
                                options={props.zoneOptions ? props.zoneOptions : []}
                                isLoading={!props.zoneOptions.length}
                                isDisabled={!props.zoneOptions.length}
                                className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => setFieldValue("zoneAmiante", opt ? opt : "")}
                                placeholder={"Sélectionner une zone"}
                                value={values.zoneAmiante ? values.zoneAmiante : ""}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        fontFamily: "DM Sans",
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                isClearable={true}
                            />
                            {errors.zoneAmiante && touched.zoneAmiante && <p className="form-error-message">{errors.zoneAmiante.value || errors.zoneAmiante.label}</p>}
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='fid' className='form-label'>Fiche d'identification du déchet</label>
                            <div className='mgT-s2'>
                                {fidDocumentSelected ?
                                    <>
                                        <div className="fileItem">
                                            {iconExtension(fidDocumentSelected.path)}
                                            <input style={{ marginTop: 0 }} name='nomFichierFid' className={"new-default-input"} type="text" value={nomFichierFid} placeholder='Nom du fichier fid' onChange={(e) => setNomFichierFid(e.target.value)} />
                                            <p className='mgL-s2 mgR-s3' style={{ whiteSpace: "nowrap" }}>.{fidDocumentSelected.path.split(".")[fidDocumentSelected.path.split(".").length - 1]} - {formatBytes(fidDocumentSelected.size)}</p>
                                            <Icon onClick={() => { setFidDocumentSelected(null); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                        </div>
                                        <div className='mgT-s3'>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ flex: 1, marginRight: "1.4rem" }}>
                                                    <label htmlFor='documentTitle' className='form-label'>Date fid</label>
                                                    <input name='dateDebutValiditeFid' className={`new-default-input${errors.dateDebutValiditeFid && touched.dateDebutValiditeFid ? " input-error" : ""}`} type="date" value={values.dateDebutValiditeFid ? values.dateDebutValiditeFid : ""} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <label htmlFor='documentTitle' className='form-label'>Date d'expiration fid</label>
                                                    <input name='dateFinValiditeFid' className={`new-default-input${errors.dateFinValiditeFid && touched.dateFinValiditeFid ? " input-error" : ""}`} type="date" value={values.dateFinValiditeFid ? values.dateFinValiditeFid : ""} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div {...getRootPropsFid({ style: styleFid })}>
                                        <input {...getInputPropsFid()} />
                                        <Icon style={{ color: isFocusedFid ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                        <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='cap' className='form-label'>Certificats d'Acceptation Préalable du déchet</label>
                            <div className='mgT-s2'>
                                {capDocumentSelected ?
                                    <>
                                        <div className="fileItem">
                                            {iconExtension(capDocumentSelected.path)}
                                            <input style={{ marginTop: 0 }} name='nomFichierCap' className={"new-default-input"} type="text" value={nomFichierCap} placeholder='Nom du fichier cap' onChange={(e) => setNomFichierCap(e.target.value)} />
                                            <p className='mgL-s2 mgR-s3' style={{ whiteSpace: "nowrap" }}>.{capDocumentSelected.path.split(".")[capDocumentSelected.path.split(".").length - 1]} - {formatBytes(capDocumentSelected.size)}</p>
                                            <Icon onClick={() => { setCapDocumentSelected(null); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                        </div>
                                        <div className='mgT-s3'>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ flex: 1, marginRight: "1.4rem" }}>
                                                    <label htmlFor='documentTitle' className='form-label'>Date cap</label>
                                                    <input name='dateDebutValiditeCap' className={`new-default-input${errors.dateDebutValiditeCap && touched.dateDebutValiditeCap ? " input-error" : ""}`} type="date" value={values.dateDebutValiditeCap ? values.dateDebutValiditeCap : ""} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <label htmlFor='documentTitle' className='form-label'>Date d'expiration cap</label>
                                                    <input name='dateFinValiditeCap' className={`new-default-input${errors.dateFinValiditeCap && touched.dateFinValiditeCap ? " input-error" : ""}`} type="date" value={values.dateFinValiditeCap ? values.dateFinValiditeCap : ""} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div {...getRootPropsCap({ style: styleCap })}>
                                        <input {...getInputPropsCap()} />
                                        <Icon style={{ color: isFocusedCap ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                        <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='label-and-input'>
                            <label htmlFor='bsda' className='form-label'>Bordereau de suivi déchets</label>
                            <div className='mgT-s2'>
                                {bsdaDocumentSelected ?
                                    <>
                                        <div className="fileItem">
                                            {iconExtension(bsdaDocumentSelected.path)}
                                            <input style={{ marginTop: 0 }} name='nomFichierBsda' className={"new-default-input"} type="text" value={nomFichierBsda} placeholder='Nom du fichier bsda' onChange={(e) => setNomFichierBsda(e.target.value)} />
                                            <p className='mgL-s2 mgR-s3' style={{ whiteSpace: "nowrap" }}>.{bsdaDocumentSelected.path.split(".")[bsdaDocumentSelected.path.split(".").length - 1]} - {formatBytes(bsdaDocumentSelected.size)}</p>
                                            <Icon onClick={() => { setBsdaDocumentSelected(null); }} style={{ width: "2rem", height: "2rem", cursor: "pointer" }} icon="mdi:close-circle" />
                                        </div>
                                        <div className='mgT-s3'>
                                            <div style={{ flex: 1, marginRight: "1.4rem" }}>
                                                <label htmlFor='documentTitle' className='form-label'>Url du bordereau de suivi de déchet</label>
                                                <input name='urlBsda' className={`new-default-input${errors.urlBsda && touched.urlBsda ? " input-error" : ""}`} type="url" placeholder='Url du bordereau de suivi de déchet' value={values.urlBsda} onChange={handleChange} onBlur={handleBlur} />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div {...getRootPropsBsda({ style: styleBsda })}>
                                        <input {...getInputPropsBsda()} />
                                        <Icon style={{ color: isFocusedBsda ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
                                        <p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="submit-section">
                            <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn fw" type="submit" value="Ajouter le déchet" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AjouterDechetChantierAmiante;
