import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const banqueHeureState = {
    horaire: [],

};

const reducer = (state = banqueHeureState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_BANQUE_HEURE_ME:
            return { ...state, horaire: action.horaire };
        case actionTypes.UNLOAD_BANQUE_HEURE:
            return { horaire: [] };
        case actionTypes.ADD_HORAIRE_BANQUE_HEURE:
            return updateObject(state, { horaire: state.horaire.concat(action.horaire) });
        case actionTypes.DELETE_BANQUE_HEURE:
            return {
                ...state,
                horaire: state.horaire.filter(horaire => horaire._id !== action.idBanque)
            };
        default:
            return state;
    }
};

export default reducer;