import React from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';

const ModifierEpiForm = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    // handle form
    const formSchema = yup.object().shape({
        libelleEquipement: yup.string().required("Veuillez saisir le nom de l'équipement"),
        codeEquipement: yup.string().required("Veuillez saisir le code de l'équipement"),
        shortLibelleEquipement: yup.string().required("Veuillez saisir le sigle de l'équipement"),
        fpa: yup.number().integer("Le facteur de protection doit être un nombre entier").min(0, "Le facteur de protection ne peut pas être négatif").required("Veuillez saisir le facteur de protection de l'équipement"),
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        axiosInstance.patch(`/equipement_amiante/${props.epiData._id}`, values).then((result) => {
            props.progressBarRef.current.complete();
            props.editEpi(result.data);
            setSubmitting(false);
            close();
        }).catch((err) => {
            setSubmitting(false);
            props.progressBarRef.current.complete();
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            libelleEquipement: props.epiData.libelleEquipement,
            codeEquipement: props.epiData.codeEquipement,
            shortLibelleEquipement: props.epiData.shortLibelleEquipement,
            fpa: props.epiData.fpa,
        },
        validationSchema: formSchema,
        onSubmit
    });

    const fieldNotModified = () => {
        return props.epiData.libelleEquipement === values.libelleEquipement && props.epiData.shortLibelleEquipement === values.shortLibelleEquipement && props.epiData.codeEquipement === values.codeEquipement && props.epiData.fpa === values.fpa;
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Modifier un epi</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='libelleEquipement' className='form-label'>Nom de l'équipement<span className='required-field'>*</span></label>
                    <input name='libelleEquipement' id='libelleEquipement' className={`new-default-input${errors.libelleEquipement && touched.libelleEquipement ? " input-error" : ""}`} type="text" placeholder="Nom de l'équipement" value={values.libelleEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.libelleEquipement && touched.libelleEquipement && <p className="form-error-message">{errors.libelleEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='shortLibelleEquipement' className='form-label'>Sigle de l'équipement<span className='required-field'>*</span></label>
                    <input name='shortLibelleEquipement' className={`new-default-input${errors.shortLibelleEquipement && touched.shortLibelleEquipement ? " input-error" : ""}`} type="text" placeholder="Sigle de l'équipement" value={values.shortLibelleEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.shortLibelleEquipement && touched.shortLibelleEquipement && <p className="form-error-message">{errors.shortLibelleEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='codeEquipement' className='form-label'>Code de l'équipement<span className='required-field'>*</span></label>
                    <input name='codeEquipement' className={`new-default-input${errors.codeEquipement && touched.codeEquipement ? " input-error" : ""}`} type="text" placeholder="Code de l'équipement" value={values.codeEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.codeEquipement && touched.codeEquipement && <p className="form-error-message">{errors.codeEquipement}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='fpa' className='form-label'>Facteur de protection de l'équipement<span className='required-field'>*</span></label>
                    <input name='fpa' className={`new-default-input${errors.fpa && touched.fpa ? " input-error" : ""}`} type="number" placeholder="Facteur de protection de l'équipement" value={values.fpa} onChange={handleChange} onBlur={handleBlur} />
                    {errors.fpa && touched.fpa && <p className="form-error-message">{errors.fpa}</p>}
                </div>
                <div className="button-container-flex mgT-b">
                    <button type="button" disabled={isSubmitting} className='btn btn-secondary fw mgR-m1 medium-btn' onClick={() => close()}>Annuler</button>
                    <input disabled={isSubmitting || fieldNotModified()} style={{ opacity: isSubmitting || fieldNotModified() ? 0.4 : 1 }} type="submit" className='btn btn-primary medium-btn fw' value="Modifier" />
                </div>
            </form>
        </div>
    );
};

export default ModifierEpiForm;