import axiosInstance from "../../utils/axiosInstance";
import * as actionTypes from './actionTypes';
import { showError } from '../../utils/ErrorHelper';


const startLoading = () => {
    return {
        type: actionTypes.START_LOADING
    };
};

const stopLoading = () => {
    return {
        type: actionTypes.STOP_LOADING
    };
};

const setFichiersChantier = (fichiersChantier) => {
    return {
        type: actionTypes.LOAD_FICHIERS_CHANTIER,
        fichiersChantier: fichiersChantier
    };
};

export const addFichierChantier = (fichierChantier) => {
    return {
        type: actionTypes.ADD_FICHIER_CHANTIER,
        fichierChantier: fichierChantier
    };
};

export const removeFichierChantier = (fichierChantierId) => {
    return {
        type: actionTypes.REMOVE_FICHIER_CHANTIER,
        fichierChantierId: fichierChantierId
    };
};

export const updateFichierChantier = (fichierChantier) => {
    return {
        type: actionTypes.UPDATE_FICHIER_CHANTIER,
        fichierChantier: fichierChantier
    };
};

export const getEspaceDocumentaireChantier = (idChantier) => (dispatch) => {
    dispatch(startLoading());
    axiosInstance.get(`/fichiersChantiers/espaceDocumentaire/${idChantier}`).then((result) => {
        dispatch(stopLoading());
        dispatch(setFichiersChantier(result.data));
    }).catch((err) => {
        dispatch(stopLoading());
        showError(err);
    });
};