import React from 'react';
import * as yup from "yup";
import axiosInstance from '../../../utils/axiosInstance';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';
import { useFormik } from 'formik';
import Select from "react-select";


const AjouterEquipementsEpcForm = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    // handle form
    const formSchema = yup.object().shape({
        nomEquipement: yup.string().required("Veuillez renseigner le nom du matériel"),
        marqueEquipement: yup.string().required("Veuillez entrer une marque"),
        numeroDeSerieEquipement: yup.string().required("Veuillez entrer un numéro de série"),
        refInterneEquipement: yup.string().optional("Veuillez entrer un numéro de série"),
        dateDernierControleEquipement: yup.date().max(new Date(), "La date doit etre inférieure à aujourd'hui").nullable(),
        dateProControleEquipement: yup.date().min(new Date(new Date().getTime() - (24 * 60 * 60 * 1000)), "La date doit etre supérieure à aujourd'hui").nullable()
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const postData = {
            ...values,
            dateDernierControleEquipement: values.dateDernierControleEquipement ? values.dateDernierControleEquipement : null,
            dateProControleEquipement: values.dateProControleEquipement ? values.dateProControleEquipement : null,
        };
        console.log(postData);
        axiosInstance.post("/equipementEPC", postData).then((result) => {
            props.progressBarRef.current.complete();
            props.addMateriel(result.data.equipementEpc);
            actions.resetForm();
            close();
        }).catch((err) => {
            actions.resetForm();
            props.progressBarRef.current.complete();
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            nomEquipement: "",
            marqueEquipement: "",
            numeroDeSerieEquipement: "",
            refInterneEquipement: "",
            dateDernierControleEquipement: "",
            dateProControleEquipement: ""
        },
        validationSchema: formSchema,
        onSubmit
    });

    const nomMaterielOptions = [
        { label: "EXTRACTEUR", value: "EXTRACTEUR" },
        { label: "UNITE DE FILTRATION UF", value: "UNITE DE FILTRATION UF" },
        { label: "UNITE DE CHAUFFE ET FILTRATION", value: "UNITE DE CHAUFFE ET FILTRATION" },
        { label: "SYSTÈME ADDUCTION  AIR", value: "SYSTÈME ADDUCTION  AIR" },
        { label: "ASPIRATEUR", value: "ASPIRATEUR" },
        { label: "BORDUREUSE", value: "BORDUREUSE" },
        { label: "RECTIFIEUSE", value: "RECTIFIEUSE" },
        { label: "CONTROLEUR DE DEPRESSION", value: "CONTROLEUR DE DEPRESSION" },
        { label: "ANEMOMETRE", value: "ANEMOMETRE" },
        { label: "LUXMETRE", value: "LUXMETRE" },
        { label: "U.M.D", value: "U.M.D" },
    ];

    const onChangeNomMateriel = (opt) => {
        setFieldValue("nomEquipement", opt.value);
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Ajouter un équipement</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='nomEquipement' className='form-label'>Nom du matériel<span className='required-field'>*</span></label>
                    <Select
                        options={nomMaterielOptions}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeNomMateriel(opt)}
                        placeholder={"Nom du matériel"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        value={values.nomEquipement ? { label: values.nomEquipement, value: values.nomEquipement } : ""}
                    />
                    {errors.nomEquipement && touched.nomEquipement && <p className="form-error-message">{errors.nomEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='marqueEquipement' className='form-label'>Marque du matériel<span className='required-field'>*</span></label>
                    <input name='marqueEquipement' className={`new-default-input${errors.marqueEquipement && touched.marqueEquipement ? " input-error" : ""}`} type="text" placeholder="Marque du matériel" value={values.marqueEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.marqueEquipement && touched.marqueEquipement && <p className="form-error-message">{errors.marqueEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='numeroDeSerieEquipement' className='form-label'>Numero de série du matériel<span className='required-field'>*</span></label>
                    <input name='numeroDeSerieEquipement' className={`new-default-input${errors.numeroDeSerieEquipement && touched.numeroDeSerieEquipement ? " input-error" : ""}`} type="text" placeholder="Numéro de série du matériel" value={values.numeroDeSerieEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.numeroDeSerieEquipement && touched.numeroDeSerieEquipement && <p className="form-error-message">{errors.numeroDeSerieEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='refInterneEquipement' className='form-label'>Reférence du matériel</label>
                    <input name='refInterneEquipement' className={`new-default-input${errors.refInterneEquipement && touched.refInterneEquipement ? " input-error" : ""}`} type="text" placeholder="Reférence du matériel" value={values.refInterneEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.refInterneEquipement && touched.refInterneEquipement && <p className="form-error-message">{errors.refInterneEquipement}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='dateDernierControleEquipement' className='form-label'>Dâte du dernier contrôle</label>
                    <input name='dateDernierControleEquipement' className={`new-default-input${errors.dateDernierControleEquipement && touched.dateDernierControleEquipement ? " input-error" : ""}`} type="date" placeholder="Datu du dernier contrôle" value={values.dateDernierControleEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.dateDernierControleEquipement && touched.dateDernierControleEquipement && <p className="form-error-message">{errors.dateDernierControleEquipement}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='dateProControleEquipement' className='form-label'>Dâte du prochain contrôle</label>
                    <input name='dateProControleEquipement' className={`new-default-input${errors.dateProControleEquipement && touched.dateProControleEquipement ? " input-error" : ""}`} type="date" placeholder="Datu du prochain contrôle" value={values.dateProControleEquipement} onChange={handleChange} onBlur={handleBlur} />
                    {errors.dateProControleEquipement && touched.dateProControleEquipement && <p className="form-error-message">{errors.dateProControleEquipement}</p>}
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Ajouter l'équipement" />
                {/* <label htmlFor="equipementSelect" className='ajouter-form-label default-label'>Nom</label>
                <select name="equipementSelect"  id="equipement-select" className="new-default-input"  {...register("Nom", {required: true})} value={selectedNom} onChange={(e)=>changerNom(e)}>
                    <option value="All">Choisir une option</option>
                    <option value="EXTRACTEUR">Extracteurs</option>
                    <option value="UNITE DE FILTRATION UF">Unité de filtration UF</option>
                    <option value="UNITE DE CHAUFFE ET FILTRATION">Unité de chauffe et filtration</option>
                    <option value="SYSTÈME ADDUCTION  AIR">Système adduction air</option>
                    <option value="ASPIRATEUR">Aspirateur</option>
                    <option value="BORDUREUSE">Bordureuse</option>
                    <option value="RECTIFIEUSE">Rectifieuse</option>
                    <option value="CONTROLEUR DE DEPRESSION">Contrôleur de dépression</option>
                    <option value="ANEMOMETRE">Anemomètre</option>
                    <option value="LUXMETRE">Luxmètre</option>
                    <option value="U.M.D">U.M.D</option>
                </select>
                {errors['Nom'] && <p className="login-error-message">{errors['Nom'].message}</p>}

                <label className='ajouter-form-label default-label' htmlFor="nom">Marque</label>
                <input className="ajouter-form-input default-input" name="marque" type="text" placeholder="Marque de l'équipement" {...register("Marque", {required: true})} />
                {errors['Marque'] && <p className="login-error-message">{errors['Marque'].message}</p>}

                <label className='ajouter-form-label default-label' htmlFor="numero_de_serie">Numéro de série</label>
                <input className="ajouter-form-input default-input" name="numero_de_serie" type="text" placeholder="Numéro de série" {...register("Numero_de_serie", {required: true})}/>
                {errors['Numero_de_serie'] && <p className="login-error-message">{errors['Numero_de_serie'].message}</p>}

                <label className='ajouter-form-label default-label' htmlFor="ref_interne">Référence interne (Facultatif)</label>
                <input className="ajouter-form-input default-input" name="ref_interne" type="text" placeholder="Référence interne" {...register("Ref_interne")}/>

                <label className='ajouter-form-label default-label' htmlFor="Date_dernier_controle">Date du dernier controle</label>
                <Controller control={control} name='Date_dernier_controle' render={({field: { value, onBlur,onChange , name },formState}) => (<DatePicker value={value} onChange={onChange}  className="input-datePicker" onBlur={onBlur} name={name} />)}/>
                {errors['Date_dernier_controle'] && <p className="login-error-message">{errors['Date_dernier_controle'].message}</p>}

                <label className='ajouter-form-label default-label' htmlFor="Date_prochain_controle">Date du prochain controle</label>
                <Controller control={control} name='Date_prochain_controle'render={({field: { value, onBlur,onChange , name },formState}) => (<DatePicker value={value} onChange={onChange}  className="input-datePicker"  onBlur={onBlur} name={name} />)}/>
                {errors['Date_prochain_controle'] && <p className="login-error-message">{errors['Date_prochain_controle'].message}</p>}

                <input disabled={isButtonDisabled} className="default-button validate-button-fw" type='submit' value="Ajouter" /> */}
            </form>
        </div>
    );
};


export default AjouterEquipementsEpcForm


