import React from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import SelectSearchCreatable from '../SelectSearchCreatable';
import Select from 'react-select/creatable';
import axiosInstance from '../../../utils/axiosInstance';
import { showError } from '../../../utils/ErrorHelper';
import customToast from '../../../utils/ToastifyHelper';


const AjouterVehicule = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    // handle form
    const formSchema = yup.object().shape({
        categorie: yup.string("Veuillez sélectionner la catégorie du véhicule"),
        typeVehicule: yup.string().required("Veuillez sélectionner le type du véhicule"),
        marque: yup.string("Veuillez sélectionner le type du véhicule"),
        immatriculation: yup.string().required("Veuillez saisir l'immatriculation du véhicule"),
        numeroParc: yup.number().integer("Veuillez saisir le numéro de parc du véhicule"),
        pav: yup.number("Veuillez sélectionner le pav du véhicule"),
        commentaire: yup.string(),
        particularite: yup.string(),
        pneus: yup.string(),
        jantes: yup.string(),
    });

    const onSubmit = (values, actions) => {
        let postData = {
            categorie: values.categorie.length ? values.categorie : null,
            typeVehicule: values.typeVehicule.length ? values.typeVehicule : null,
            marque: values.marque.length ? values.marque : null,
            immatriculation: values.immatriculation.length ? values.immatriculation : null,
            numeroParc: values.numeroParc !== "" ? values.numeroParc : null,
            pav: values.pav !== "" ? values.pav : null,
            commentaire: values.commentaire.length ? values.commentaire : null,
            particularite: values.particularite.length ? values.particularite : null,
            pneus: values.pneus.length ? values.pneus : null,
            jantes: values.jantes.length ? values.jantes : null
        };
        props.progressBarRef.current.continuousStart();
        axiosInstance.post('/vehicules', postData)
            .then((result) => {
                props.progressBarRef.current.complete();
                customToast.success("Le véhicule a été ajouté ave succès");
                props.addVehicule(result.data);
                if (postData.categorie !== null && !props.formOptions.categoriesVehiculeOptions.options.includes(postData.categorie)) {
                    axiosInstance.patch(`/formOptions/ajouterOptions/${props.formOptions.categoriesVehiculeOptions._id}`, { option: postData.categorie }).then(() => {
                        props.addFormOptions("categoriesVehiculeOptions", postData.categorie);
                    }).catch((err1) => {
                        showError(err1);
                    });
                }
                if (postData.typeVehicule !== null && !props.formOptions.typesVehiculeOptions.options.includes(postData.typeVehicule)) {
                    axiosInstance.patch(`/formOptions/ajouterOptions/${props.formOptions.typesVehiculeOptions._id}`, { option: postData.typeVehicule }).then(() => {
                        props.addFormOptions("typesVehiculeOptions", postData.typeVehicule);
                    }).catch((err1) => {
                        showError(err1);
                    });
                }
                if (postData.pneus !== null && !props.formOptions.pneusVehiculeOptions.options.includes(postData.pneus)) {
                    axiosInstance.patch(`/formOptions/ajouterOptions/${props.formOptions.pneusVehiculeOptions._id}`, { option: postData.pneus }).then(() => {
                        props.addFormOptions("pneusVehiculeOptions", postData.pneus);
                    }).catch((err1) => {
                        showError(err1);
                    });
                }
                if (postData.jantes !== null && !props.formOptions.jantesVehiculeOptions.options.includes(postData.jantes)) {
                    axiosInstance.patch(`/formOptions/ajouterOptions/${props.formOptions.jantesVehiculeOptions._id}`, { option: postData.jantes }).then(() => {
                        props.addFormOptions("jantesVehiculeOptions", postData.jantes);
                    }).catch((err1) => {
                        showError(err1);
                    });
                }
                close();
            }).catch((err) => {
                actions.resetForm();
                props.progressBarRef.current.complete();
                showError(err);
            });
        setSubmitting(false);
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            categorie: "",
            typeVehicule: "",
            marque: "",
            immatriculation: "",
            numeroParc: "",
            pav: "",
            commentaire: "",
            particularite: "",
            pneus: "",
            jantes: "",
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onChangeCategorieVehicule = (opt) => {
        if (opt) {
            setFieldValue("categorie", opt.value);
        }
        else {
            setFieldValue("categorie", "");
        }
    };

    const onChangeTypeVehicule = (opt) => {
        if (opt) {
            setFieldValue("typeVehicule", opt.value);
        }
        else {
            setFieldValue("typeVehicule", "");
        }
    };

    const onChangePneuVehicule = (opt) => {
        if (opt) {
            setFieldValue("pneus", opt.value);
        }
        else {
            setFieldValue("pneus", "");
        }
    };

    const onChangeJanteVehicule = (opt) => {
        if (opt) {
            setFieldValue("jantes", opt.value);
        }
        else {
            setFieldValue("jantes", "");
        }
    };


    return (
        <div className="modal-content ajouter-form">
            <h3 className='modal-title'>Ajouter un véhicule</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className='vertical-form scrollable' onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='categorie' className='form-label'>Catégorie du véhicule</label>
                    <Select
                        options={props.formOptions.categoriesVehiculeOptions.options.map((option) => {
                            return (
                                { label: option, value: option }
                            );
                        })}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeCategorieVehicule(opt)}
                        placeholder="Sélectionner la catégorie du véhicule"
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        formatCreateLabel={(value) => `Ajouter ${value} à la catégorie du véhicule`}
                        isClearable={true}
                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                    />
                    {errors.categorie && touched.categorie && <p className="form-error-message">{errors.categorie}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='typeVehicule' className='form-label'>Type de véhicule</label>
                    <Select
                        options={props.formOptions.typesVehiculeOptions.options.map((option) => {
                            return (
                                { label: option, value: option }
                            );
                        })}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeTypeVehicule(opt)}
                        placeholder="Sélectionner le type du véhicule"
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                        formatCreateLabel={(value) => `Ajouter ${value} au type du véhicule`}
                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                    />
                    {errors.typeVehicule && touched.typeVehicule && <p className="form-error-message">{errors.typeVehicule}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='marque' className='form-label'>Marque du véhicule</label>
                    <input name='marque' className={`new-default-input${errors.marque && touched.marque ? " input-error" : ""}`} type="text" placeholder="Marque du véhicule" value={values.marque} onChange={handleChange} onBlur={handleBlur} />
                    {errors.marque && touched.marque && <p className="form-error-message">{errors.marque}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='immatriculation' className='form-label'>Immatriculation du véhicule</label>
                    <input name='immatriculation' className={`new-default-input${errors.immatriculation && touched.immatriculation ? " input-error" : ""}`} type="text" placeholder="Immatriculation du véhicule" value={values.immatriculation} onChange={handleChange} onBlur={handleBlur} />
                    {errors.immatriculation && touched.immatriculation && <p className="form-error-message">{errors.immatriculation}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='numeroParc' className='form-label'>Numéro de parc</label>
                    <input name='numeroParc' className={`new-default-input${errors.numeroParc && touched.numeroParc ? " input-error" : ""}`} type="number" placeholder="Numéro de parc du véhicule" value={values.numeroParc} onChange={handleChange} onBlur={handleBlur} />
                    {errors.numeroParc && touched.numeroParc && <p className="form-error-message">{errors.numeroParc}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='pav' className='form-label'>Poids à vide</label>
                    <input name='pav' className={`new-default-input${errors.pav && touched.pav ? " input-error" : ""}`} type="number" placeholder="Poids à vide du véhicule" value={values.pav} onChange={handleChange} onBlur={handleBlur} />
                    {errors.pav && touched.pav && <p className="form-error-message">{errors.pav}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='commentaire' className='form-label'>Commentaire</label>
                    <input name='commentaire' className={`new-default-input${errors.commentaire && touched.commentaire ? " input-error" : ""}`} type="text" placeholder="Commentaire" value={values.commentaire} onChange={handleChange} onBlur={handleBlur} />
                    {errors.commentaire && touched.commentaire && <p className="form-error-message">{errors.commentaire}</p>}
                </div>
                <div className='label-and-input'>
                    <label htmlFor='particularite' className='form-label'>Particularité du véhicule</label>
                    <input name='particularite' className={`new-default-input${errors.particularite && touched.particularite ? " input-error" : ""}`} type="text" placeholder="Particularite du véhicule" value={values.particularite} onChange={handleChange} onBlur={handleBlur} />
                    {errors.particularite && touched.particularite && <p className="form-error-message">{errors.particularite}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='pneus' className='form-label'>Pneus</label>
                    <Select
                        options={props.formOptions.pneusVehiculeOptions.options.map((option) => {
                            return (
                                { label: option, value: option }
                            );
                        })}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangePneuVehicule(opt)}
                        placeholder="Sélectionner le type de pneu"
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                        formatCreateLabel={(value) => `Ajouter ${value} comme type de pneu`}
                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                    />
                    {errors.pneusOptions && touched.pneusOptions && <p className="form-error-message">{errors.pneusOptions}</p>}
                </div>
                <div className='label-and-input last-input'>
                    <label htmlFor='jantes' className='form-label'>Jantes</label>
                    <Select
                        options={props.formOptions.jantesVehiculeOptions.options.map((option) => {
                            return (
                                { label: option, value: option }
                            );
                        })}
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeJanteVehicule(opt)}
                        placeholder="Sélectionner le type de jante"
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        formatCreateLabel={(value) => `Ajouter ${value} comme type de jante`}
                        isClearable={true}
                    // value={values.zone ? {label: values.zone, value: values.zone} : ""}
                    />
                    {errors.jantesOptions && touched.jantesOptions && <p className="form-error-message">{errors.jantesOptions}</p>}
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Ajouter le véhicule" />
            </form>
        </div>
    );
};

export default AjouterVehicule;

// 51 32 19 47 60