import * as actionTypes from '../actions/actionTypes';

const categoriesDocumentList = [];

const reducer = (state = categoriesDocumentList, action) => {
    switch (action.type) {
        case actionTypes.LOAD_CATEGORIES_DOCUMENT:
            return [...action.categoriesDocument];
        case actionTypes.UNLOAD_CATEGORIES_DOCUMENT:
            return [];
        case actionTypes.ADD_CATEGORIE_DOCUMENT:
            return state.concat(action.documentCategorie);
        case actionTypes.MODIFY_CATEGORIE_DOCUMENT:
            return (
                state.map(
                    (categorie) => categorie._id === action.categorieUpdated._id ?
                        { ...categorie, libelleCategorie: action.categorieUpdated.libelleCategorie, typeDocuments: action.categorieUpdated.typeDocuments }
                        : categorie
                )
            );

        case actionTypes.DELETE_CATEGORIE_DOCUMENT:
            return state.filter(categorie => categorie._id !== action.categorieId);
        case actionTypes.ADD_TYPE_DOCUMENTS:
            return (
                state.map(
                    (categorie) => categorie._id === action.categorieUpdatedId ?
                        { ...categorie, typeDocuments: categorie.typeDocuments.concat(action.typeDocuments) }
                        : categorie
                )
            );
        case actionTypes.DELETE_TYPE_DOCUMENTS:
            return (
                state.map(
                    (categorie) => categorie._id === action.categorieUpdated._id ?
                        { ...categorie, typeDocuments: categorie.typeDocuments.filter(type => type._id !== action.idTypeSupprimer) }
                        : categorie
                )
            );
        case actionTypes.MODIFY_TYPE_DOCUMENT:
            return (
                state.map(
                    (categorie) => categorie._id === action.categorieUpdated._id ?
                        {
                            ...categorie, typeDocuments: categorie.typeDocuments.map(
                                (type) => type._id === action.typeDocument._id ?
                                    { ...type, libelleType: action.typeDocument.libelleType }
                                    : type
                            )
                        }
                        : categorie
                )
            );
        default:
            return state;
    }
};

export default reducer;