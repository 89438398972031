import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helper';

const vehiculeListState = { allVehicule: [], showedVehicule: [], error: null };

const reducer = (state = vehiculeListState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_VEHICULE_LIST:
            return updateObject(state, { allVehicule: action.vehiculeList, showedVehicule: action.vehiculeList });
        case actionTypes.UNLOAD_VEHICULE_LIST:
            return { allVehicule: [], showedVehicule: [], error: null };
        case actionTypes.ADD_VEHICULE:
            return updateObject(state, { allVehicule: state.allVehicule.concat(action.vehicule) });
        case actionTypes.MODIFY_VEHICULE:
            return {
                ...state,
                allVehicule: state.allVehicule.map(
                    (vehicule) => vehicule._id === action.vehiculeUpdated._id ?
                        { ...vehicule, immatriculation: action.vehiculeUpdated.immatriculation, pav: action.vehiculeUpdated.pav }
                        : vehicule
                )
            };
        case actionTypes.DELETE_VEHICULE:
            return {
                ...state,
                allVehicule: state.allVehicule.filter(vehicule => vehicule.immatriculation !== action.vehiculeImmatriculation)
            };
        default:
            return state;
    }
};

export default reducer;
