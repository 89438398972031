import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { FieldArray, Field, ErrorMessage, Formik, Form } from 'formik';
import { showError } from "../../../../utils/ErrorHelper";
import axiosInstance from "../../../../utils/axiosInstance";
import { formatMateriaux, formatTechniqueModeOperatoire } from "../../../../utils/GeneralHelper";
import Select from 'react-select';
import { Icon } from "@iconify/react";
import customToast from "../../../../utils/ToastifyHelper";

const AjouterZoneAmiante = (props) => {
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const [allUtilisateursAmiante, setAllUtilisateursAmiante] = useState([]);

    useEffect(() => {
        axiosInstance.get("/utilisateurs/allUtilisateursAmiante").then((result) => {
            let allUtilisateurs = {};
            let allUtilisateursAmianteOptions = [];
            for (const utilisateur of result.data) {
                if (allUtilisateurs.hasOwnProperty(utilisateur._id.userRole.statut)) {
                    allUtilisateurs[utilisateur._id.userRole.statut].push({ label: `${utilisateur._id.login_matricule} - ${utilisateur._id.nom} ${utilisateur._id.prenom}`, value: utilisateur._id._id, fullName: `${utilisateur._id.nom} ${utilisateur._id.prenom}` });
                }
                else {
                    allUtilisateurs[utilisateur._id.userRole.statut] = [{ label: `${utilisateur._id.login_matricule} - ${utilisateur._id.nom} ${utilisateur._id.prenom}`, value: utilisateur._id._id, fullName: `${utilisateur._id.nom} ${utilisateur._id.prenom}` }];
                }
            }
            for (const userRole of Object.keys(allUtilisateurs)) {
                allUtilisateurs[userRole].sort((a, b) => a.fullName.localeCompare(b.fullName));
                allUtilisateursAmianteOptions.push({ label: userRole, options: allUtilisateurs[userRole] });
            }
            allUtilisateursAmianteOptions.sort((a, b) => a.label.localeCompare(b.label));
            setAllUtilisateursAmiante(allUtilisateursAmianteOptions);
        }).catch((err) => {
            showError(err);
        });
    }, []);


    const formSchema = yup.object().shape({
        zones: yup.array().of(
            yup.object().shape({
                nomZoneAmiante: yup.string().required("Veuillez renseigner le nom de la zone"),
                environnement: yup.string().required("Veuillez renseigner l'environnement de la zone"),
                phase: yup.string().required("Veuillez renseigner la phase de la zone"),
                encadrantDeChantier: yup.object().required("Veuillez renseigner l'encadrant de chantier de la zone").shape({
                    value: yup.string().required("Veuillez renseigner l'encadrant de chantier de la zone"),
                    label: yup.string().required("Veuillez renseigner l'encadrant de chantier de la zone"),
                }),
                processus: yup.array().min(1, "Veuillez renseigner les processus de la zone").of(yup.string()).required("Veuillez renseigner les processus de la zone"),
            })
        ),
    });

    const onSubmit = (values, actions) => {
        props.progressBarRef.current.continuousStart();
        const postData = {
            zones: values.zones.map((zone) => {
                return {
                    ...zone,
                    encadrantDeChantier: zone.encadrantDeChantier.value,
                    chantier: props.chantierId
                };
            })
        };

        axiosInstance.post("/zoneAmiante", postData).then((result) => {
            close();
            props.progressBarRef.current.complete();
            actions.setSubmitting(false);
            props.setZonesChantier(result.data);
            customToast.success("Les zones ont bien été ajoutées au chantier");
        }).catch((err) => {
            props.progressBarRef.current.complete();
            actions.setSubmitting(false);
            showError(err);
        });
    };

    const initialValues = {
        zones: [{ nomZoneAmiante: "", environnement: "", phase: "", encadrantDeChantier: "", processus: [] }]
    };

    const environnementOptions = [
        { label: "Intérieur", value: "Intérieur" },
        { label: "Exterieur", value: "Exterieur" },
    ];

    const [phaseOptions, setPhaseOptions] = useState([
        { label: "Préparation", value: "phasePreparation" },
        { label: "Exécution", value: "phaseExecution" },
        { label: "Décontamination matériel", value: "phaseDecontaminationMateriel" },
        { label: "Repli de chantier", value: "phaseRepliDeChantier" }
    ]);

    const [allProcessus, setAllProcessus] = useState([]);

    useEffect(() => {
        axiosInstance.get("/processusAmiante").then((res) => {
            let dataSelectFormat = [];
            for (const processus of res.data) {
                dataSelectFormat.push({
                    value: processus._id, label: `${processus.codeProcessus} - ${formatMateriaux(processus.materiaux)} - Niveau ${processus.niveauEmpoussierement} - ${formatTechniqueModeOperatoire(processus.techniqueModesOperatoires)} - ${processus.protectionCollective}`
                });
            }
            setAllProcessus(dataSelectFormat);
        }).catch((error) => {
            showError(error);
        });

        axiosInstance.get("/options?categorieOptions=amiante").then((result) => {
            setPhaseOptions([
                { label: result.data.options.phasePreparation, value: "phasePreparation" },
                { label: result.data.options.phaseExecution, value: "phaseExecution" },
                { label: result.data.options.phaseDecontaminationMateriel, value: "phaseDecontaminationMateriel" },
                { label: result.data.options.phaseRepliDeChantier, value: "phaseRepliDeChantier" }
            ]);
        }).catch((err) => {
            showError(err);
        });

    }, []);

    return <div className="modal-content default-form">
        <h3 className='modal-title'>Ajouter des zones</h3>
        <button className='modal-button' onClick={() => close()}>x</button>
        <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form className="vertical-form scrollable" onSubmit={handleSubmit}>
                    <FieldArray name="zones">
                        {({ push, remove }) => (
                            <>
                                {values.zones.map((zone, index) => (
                                    <div key={index}>
                                        <h4 className='modal-title'>{`Zone ${index + 1}`}</h4>
                                        <div className='label-and-input'>
                                            <label htmlFor={`zones.${index}.nomZoneAmiante`} className='form-label'>Nom de la zone<span className='required-field'>*</span></label>
                                            <Field name={`zones.${index}.nomZoneAmiante`} className={`new-default-input${errors.zones && errors.zones[index] && errors.zones[index].nomZoneAmiante && touched.zones && touched.zones[index] && touched.zones[index].nomZoneAmiante ? " input-error" : ""}`} type="text" placeholder="Nom de la zone" />
                                            <ErrorMessage name={`zones.${index}.nomZoneAmiante`} component="p" className="form-error-message" />
                                        </div>
                                        <div className='label-and-input'>
                                            <label htmlFor={`zones.${index}.environnement`} className='form-label'>Environnement de la zone<span className='required-field'>*</span></label>
                                            <Field name={`zones.${index}.environnement`}>
                                                {({ field, form }) => (
                                                    <Select
                                                        isLoading={!environnementOptions.length}
                                                        isDisabled={!environnementOptions.length}
                                                        options={environnementOptions}
                                                        placeholder={"Sélectionner l'environnement de la zone"}
                                                        isSearchable={false}
                                                        className={`formSelect-search-container formSelect-search-container-full mgT-s2${errors.zones && errors.zones[index] && errors.zones[index].environnement && touched.zones && touched.zones[index] && touched.zones[index].environnement ? " input-error" : ""}`}
                                                        classNamePrefix="formSelect-search"
                                                        onChange={(opt) => form.setFieldValue(`zones.${index}.environnement`, opt ? opt.value : "")}
                                                        value={values.zones[index].environnement ? { value: values.zones[index].environnement, label: values.zones[index].environnement } : ""}
                                                        onBlur={field.onBlur}
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                fontFamily: "DM Sans",
                                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                                borderRadius: "0.6rem",
                                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                                '&:hover': {
                                                                    border: '1px solid black',
                                                                },
                                                            })
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name={`zones.${index}.environnement`} component="p" className="form-error-message" />
                                        </div>

                                        <div className='label-and-input'>
                                            <label htmlFor={`zones.${index}.phase`} className='form-label'>Phase de la zone<span className='required-field'>*</span></label>
                                            <Field name={`zones.${index}.phase`}>
                                                {({ field, form }) => (
                                                    <Select
                                                        options={phaseOptions}
                                                        isLoading={!phaseOptions.length}
                                                        isDisabled={!phaseOptions.length}
                                                        placeholder={"Sélectionner la phase de la zone"}
                                                        isSearchable={false}
                                                        className={`formSelect-search-container formSelect-search-container-full mgT-s2${errors.zones && errors.zones[index] && errors.zones[index].phase && touched.zones && touched.zones[index] && touched.zones[index].phase ? " input-error" : ""}`}
                                                        classNamePrefix="formSelect-search"
                                                        onChange={(opt) => form.setFieldValue(`zones.${index}.phase`, opt ? opt.value : "")}
                                                        onBlur={field.onBlur}
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                fontFamily: "DM Sans",
                                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                                borderRadius: "0.6rem",
                                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                                '&:hover': {
                                                                    border: '1px solid black',
                                                                },
                                                            })
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name={`zones.${index}.phase`} component="p" className="form-error-message" />
                                        </div>

                                        <div className='label-and-input'>
                                            <label htmlFor={`zones.${index}.processus`} className='form-label'>Processus effectuer dans la zone<span className='required-field'>*</span></label>
                                            <Field name={`zones.${index}.processus`}>
                                                {({ field, form }) => (
                                                    <Select
                                                        options={allProcessus}
                                                        isLoading={!allProcessus.length}
                                                        isDisabled={!allProcessus.length}
                                                        placeholder={"Sélectionner les processus dans la zone"}
                                                        isSearchable={true}
                                                        isMulti={true}
                                                        className={`formSelect-search-container formSelect-search-container-full mgT-s2${errors.zones && errors.zones[index] && errors.zones[index].processus && touched.zones && touched.zones[index] && touched.zones[index].processus ? " input-error" : ""}`}
                                                        classNamePrefix="formSelect-search"
                                                        onChange={(opt) => form.setFieldValue(`zones.${index}.processus`, opt.map(processus => processus.value))}
                                                        onBlur={field.onBlur}
                                                        defaultValue={() => {
                                                            return values.zones[index].processus.map((processusId) => {
                                                                return allProcessus.find(processus => processus.value === processusId);
                                                            });
                                                        }}
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                fontFamily: "DM Sans",
                                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                                borderRadius: "0.6rem",
                                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                                '&:hover': {
                                                                    border: '1px solid black',
                                                                },
                                                            })
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name={`zones.${index}.processus`} component="p" className="form-error-message" />
                                        </div>

                                        <div className='label-and-input'>
                                            <label htmlFor={`zones.${index}.encadrantDeChantier`} className='form-label'>Encadrant de chantier<span className='required-field'>*</span></label>
                                            <Field name={`zones.${index}.encadrantDeChantier`}>
                                                {({ field, form }) => (
                                                    <Select
                                                        options={allUtilisateursAmiante}
                                                        isLoading={!allUtilisateursAmiante.length}
                                                        isDisabled={!allUtilisateursAmiante.length}
                                                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                                                        classNamePrefix="formSelect-search"
                                                        onChange={(opt) => form.setFieldValue(`zones.${index}.encadrantDeChantier`, opt ? opt : "")}
                                                        placeholder={"Sélectionner l'encadrant de chantier"}
                                                        value={values.zones[index].encadrantDeChantier ? values.zones[index].encadrantDeChantier : ""}
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                fontFamily: "DM Sans",
                                                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                                                borderRadius: "0.6rem",
                                                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                                                '&:hover': {
                                                                    border: '1px solid black',
                                                                },
                                                            })
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onBlur={field.onBlur}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage
                                                name={`zones.${index}.encadrantDeChantier`}
                                                render={(error) => (
                                                    <p className="form-error-message">{error.value || error.label}</p>
                                                )}
                                            />
                                        </div>

                                        <div className="mgB-m1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {values.zones.length - 1 === index ?
                                                index === 0 ?
                                                    <button type="button" onClick={() => push({ nomZoneAmiante: "", environnement: values.zones[index].environnement, phase: "", encadrantDeChantier: values.zones[index].encadrantDeChantier, processus: values.zones[index].processus })} className="btn btn-secondary">Ajouter une zone</button>
                                                    :
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <button className="btn btn-secondary mgR-s3" type="button" onClick={() => remove(index)}>Supprimer cette zone</button>
                                                        <button type="button" onClick={() => push({ nomZoneAmiante: "", environnement: values.zones[index].environnement, phase: "", encadrantDeChantier: values.zones[index].encadrantDeChantier, processus: values.zones[index].processus })} className="btn btn-secondary">Ajouter une zone</button>
                                                    </div>
                                                :
                                                <button className="btn btn-secondary" type="button" onClick={() => remove(index)}>Supprimer cette zone</button>
                                            }
                                        </div>

                                    </div>
                                ))}
                            </>
                        )}
                    </FieldArray>
                    <input disabled={isSubmitting} style={{ opacity: !isSubmitting ? 1 : 0.4 }} className="btn btn-primary medium-btn" type="submit" value="Sauvegarder" />
                </Form>
            )}
        </Formik>
        {/* <div className='label-and-input'>
                <label htmlFor='nomZoneAmiante' className='form-label'>Nom du site<span className='required-field'>*</span></label>
                <input name='nomZoneAmiante' className={`new-default-input${errors.nomZoneAmiante && touched.nomZoneAmiante ? " input-error" : ""}`} type="text" placeholder="Nom de la zone" value={values.nomZoneAmiante} onChange={handleChange} onBlur={handleBlur} />
                {errors.nomZoneAmiante && touched.nomZoneAmiante && <p className="form-error-message">{errors.nomZoneAmiante}</p>}
            </div>
            <div className='label-and-input'>
                <label htmlFor='environnement' className='form-label'>Environnement de la zone<span className='required-field'>*</span></label>
                <Select
                    options={environnementOptions}
                    placeholder={"Sélectionner l'environnement de la zone"}
                    name={"environnement"}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    onChange={(opt) => opt ? setFieldValue("environnement", opt.value) : setFieldValue("environnement", "")}
                    className="formSelect-search-container formSelect-search-container-full mgT-s2"
                    classNamePrefix="formSelect-search"
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            fontFamily: "DM Sans",
                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                            borderRadius: "0.6rem",
                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                            '&:hover': {
                                border: '1px solid black',
                            },
                        })
                    }}
                />
                {errors.environnement && touched.environnement && <p className="form-error-message">{errors.environnement}</p>}

            </div>
            <div className='label-and-input'>
                <label htmlFor='phase' className='form-label'>Phase de la zone<span className='required-field'>*</span></label>
                <Select
                    options={phaseOptions}
                    placeholder={"Sélectionner la phase de la zone"}
                    name={"phase"}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    onChange={(opt) => opt ? setFieldValue("phase", opt.value) : setFieldValue("phase", "")}
                    className="formSelect-search-container formSelect-search-container-full mgT-s2"
                    classNamePrefix="formSelect-search"
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            fontFamily: "DM Sans",
                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                            borderRadius: "0.6rem",
                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                            '&:hover': {
                                border: '1px solid black',
                            },
                        })
                    }}
                />
                {errors.phase && touched.phase && <p className="form-error-message">{errors.phase}</p>}

            </div>
            <div className='label-and-input'>
                <label htmlFor='processus' className='form-label'>Processus effectuer dans la zone<span className='required-field'>*</span></label>
                <Select
                    options={allProcessus}
                    className="formSelect-search-container formSelect-search-container-full mgT-s2"
                    classNamePrefix="formSelect-search"
                    onChange={(opt) => onChangeProcessus(opt)}
                    placeholder="Sélectionner un procesus"
                    isSearchable={true}
                    isMulti={true}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            fontFamily: "DM Sans",
                            border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                            borderRadius: "0.6rem",
                            boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                            '&:hover': {
                                border: '1px solid black',
                            },
                        }),
                        menu: (base) => ({
                            ...base
                        }),
                        // menuList: (base) => ({
                        //     ...base,
                        //     minHeight: `${screenSize.height - screenSize.height / 3}px` // your desired height
                        // })
                    }}
                />
                {errors.processus && touched.processus && <p className="form-error-message">{errors.processus}</p>}
            </div>
            <div className="mgB-m1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button onClick={() => console.log("ajouter une zone")} type="button" className="btn btn-secondary">Ajouter une zone</button>
            </div>
            <div className="last-input">

            </div>
            <input disabled={isSubmitting} style={{ opacity: !isSubmitting ? 1 : 0.4 }} className="btn btn-primary medium-btn" type="submit" value="Sauvegarder" /> */}

    </div>;
};

export default AjouterZoneAmiante;
