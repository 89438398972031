import React, { useEffect, useState } from 'react';
import AdminSidebar from '../sidebar/AdminSidebar';
import AdminHeader from '../header/AdminHeader';
// import FooterDashboard from '../components/FooterDashboard'
import { Outlet, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlockBrick, faClock, faFolder, faNewspaper, faRecycle, faTrianglePersonDigging, faTruck, faUserHelmetSafety } from '@fortawesome/pro-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { Icon } from '@iconify/react';
import axiosInstance from '../../utils/axiosInstance';
import { showError } from '../../utils/ErrorHelper';
import ActualiteItem from '../../pages/Actualites/ActualiteItem';


const DashboardAdmin = () => {
    const [sidebarState, setSidebarState] = useState([
        {
            Header: "Accueil",
            url: "/",
            type: "link",
            iconName: "ic:baseline-home",
            active: false
        },
        {
            Header: "Just'actus",
            url: "/justeau-actus",
            type: "group",
            iconName: "fa6-solid:newspaper",
            active: false,
            opened: false,
            height: 0,
            links: [
                {
                    Header: "Actualités",
                    url: "/justeau-actus/actualites",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Archives",
                    url: "/justeau-actus/archives-actualites",
                    type: "sublink",
                    active: false
                }
            ]
        },
        {
            Header: "Relevé d'heures",
            url: "/releve-heure",
            type: "group",
            iconName: "teenyicons:clock-solid",
            active: false,
            opened: false,
            height: 0,
            links: [
                {
                    Header: "Consulter les relevés d'heures",
                    url: "/releve-heure/releve-chef",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Banque d'heures salariés",
                    url: "/releve-heure/banque-salaries",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Absences",
                    url: "/releve-heure/absence",
                    type: "sublink",
                    active: false
                },
            ]
        },
        {
            Header: "Transport",
            type: "group",
            url: "/transport",
            iconName: "mdi:truck",
            active: false,
            opened: false,
            height: 0,
            links: [
                {
                    Header: "Demande",
                    url: "/transport/demande",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Véhicules",
                    url: "/transport/vehicules",
                    type: "sublink",
                    active: false
                }
            ]
        },
        {
            Header: "Gestion des données",
            type: "group",
            url: "/gestion-de-donnees",
            iconName: "bxs:data",
            active: false,
            opened: false,
            height: 0,
            links: [
                {
                    Header: "Salariés",
                    url: "/gestion-de-donnees/salaries",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Archive salariés",
                    url: "/gestion-de-donnees/archive-salarie",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Chantiers",
                    type: "sublink",
                    url: "/gestion-de-donnees/chantiers",
                    active: false
                },
                {
                    Header: "Archive chantiers",
                    url: "/gestion-de-donnees/archive-chantier",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Formations",
                    url: "/gestion-de-donnees/formations-suivis",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Sites",
                    url: "/gestion-de-donnees/sites",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Matériaux",
                    url: "/gestion-de-donnees/materiaux",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Déchets",
                    url: "/gestion-de-donnees/dechets",
                    type: "sublink",
                    active: false
                },
                {
                    Header: "Intervenants / tiers",
                    url: "/gestion-de-donnees/intervenants",
                    type: "sublink",
                    active: false
                },
            ]
        },
        {
            Header: "Amiante",
            type: "group",
            iconName: "ph:recycle-bold",
            url: "/amiante",
            active: false,
            opened: false,
            height: 0,
            links: [
                {
                    Header: "Chantiers amiante",
                    type: "sublink",
                    url: "/amiante/chantiers",
                    active: false
                },
                {
                    Header: "Processus",
                    type: "sublink",
                    url: "/amiante/processus",
                    active: false
                },
                {
                    Header: "Relevé d'exposition",
                    type: "sublink",
                    url: "/amiante/releve-exposition",
                    active: false
                },
                {
                    Header: "Suivi matériel",
                    type: "sublink",
                    url: "/amiante/suivi-materiel",
                    active: false
                },
                {
                    Header: "Entretiens",
                    type: "sublink",
                    url: "/amiante/suivi-entretiens",
                    active: false
                },
                {
                    Header: "Documents",
                    type: "sublink",
                    url: "/amiante/documents-amiante",
                    active: false
                },
                // {
                //     Header: "EPI",
                //     type: "sublink",
                //     url: "/amiante/epi",
                //     active: false
                // },
            ]
        },
        {
            Header: "Réglages",
            type: "group",
            iconName: "mingcute:settings-6-fill",
            url: "/reglages",
            active: false,
            opened: false,
            height: 0,
            links: [
                {
                    Header: "Amiante",
                    type: "sublink",
                    url: "/reglages/amiante",
                    active: false
                },
                {
                    Header: "EPI",
                    type: "sublink",
                    url: "/reglages/epi",
                    active: false
                },
                {
                    Header: "Options formulaires",
                    type: "sublink",
                    url: "/reglages/options-formulaires",
                    active: false
                },
            ]
        }
        // {
        //     Header: "Documents",
        //     type: "group",
        //     iconName: "ph:folder-fill",
        //     url: "/documents",
        //     active: false,
        //     opened: false,
        //     height: 0,
        //     links: [
        //         {
        //             Header: "Documents publics",
        //             url: "/documents/publics",
        //             type: "sublink",
        //             active: false
        //         },
        //         {
        //             Header: "Gestion des catégories",
        //             url: "/documents/categories",
        //             type: "sublink",
        //             active: false
        //         },
        //     ]
        // }
    ]);


    const navigate = useNavigate();

    const currentUrl = window.location.pathname;

    useEffect(() => {
        if (currentUrl === "/") {
            setSidebarState(sidebarState.map((nav) => {
                if (nav.url === "/") {
                    return { ...nav, active: true };
                }
                return nav;
            }));
        }
        else {
            setSidebarState(sidebarState.map((nav) => {
                if (nav.type === "group") {
                    if (`/${currentUrl.split("/")[1]}`.includes(nav.url)) {
                        return {
                            ...nav, active: true, opened: true, height: "fit-content", links: nav.links.map((subnav) => {

                                if (currentUrl.includes(subnav.url)) {
                                    return { ...subnav, active: true };
                                }
                                return subnav;
                            })
                        };
                    }
                    return nav;
                }
                else {
                    if (currentUrl.includes(nav.url) && nav.url !== "/") {
                        return { ...nav, active: true };
                    }
                    return nav;
                }
            }));
        }
    }, []);

    const handleIconNavigation = (url) => {
        setSidebarState(
            sidebarState.map((nav) => {
                if (nav.url === "/") {
                    return { ...nav, active: false };
                }
                else {
                    if (nav.type === "group") {
                        if (url.includes(nav.url)) {
                            if (nav.opened) {
                                return {
                                    ...nav, active: true, links: nav.links.map((subnav) => {
                                        if (subnav.url === url) {
                                            return { ...subnav, active: true };
                                        }
                                        return { ...subnav, active: false };
                                    })
                                };
                            }
                            return {
                                ...nav, active: true, opened: true, height: "fit-content", links: nav.links.map((subnav) => {
                                    if (subnav.url === url) {
                                        return { ...subnav, active: true };
                                    }
                                    return { ...subnav, active: false };
                                })
                            };
                        }
                        return {
                            ...nav, active: false, links: nav.links.map((subnav) => {
                                return { ...subnav, active: false };
                            })
                        };
                    }
                    else {
                        if (nav.url.includes(url)) {
                            return { ...nav, active: true };
                        }
                        return { ...nav, active: false };
                    }
                }
            })
        );
        navigate(url);
    };

    const [actualite, setActualite] = useState([]);

    const fetchActualite = () => {
        axiosInstance.get("/actualites?etat=1&page=1&limit=4&sort=order,asc").then((result) => {
            setActualite(result.data.actualite);
        }).catch((err) => {
            showError(err);
        });
    };

    useEffect(() => {
        fetchActualite();
    }, []);

    const mainSection = <div className='section mgT-m1'>
        <div id="applicationGrid">
            <div className='appIcon' onClick={() => handleIconNavigation("/releve-heure/releve-chef")} data-tip data-for="releveHeure">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faClock} />
                </div>
                <ReactTooltip id="releveHeure" place="bottom" effect='solid'>
                    Relevé d'heures
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/transport/affecter-transport")} data-tip data-for="transport">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faTruck} />
                </div>
                <ReactTooltip id="transport" place="bottom" effect='solid'>
                    Transport
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/amiante/releve-exposition")} data-tip data-for="releveExposition">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faRecycle} />
                </div>
                <ReactTooltip id="releveExposition" place="bottom" effect='solid'>
                    Relevé d'exposition
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/gestion-de-donnees/salaries")} data-tip data-for="salaries">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faUserHelmetSafety} />
                </div>
                <ReactTooltip id="salaries" place="bottom" effect='solid'>
                    Salariés
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/gestion-de-donnees/chantiers")} data-tip data-for="chantiers">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faTrianglePersonDigging} />
                </div>
                <ReactTooltip id="chantiers" place="bottom" effect='solid'>
                    Chantiers
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/documents/publics")} data-tip data-for="documents">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faFolder} />
                </div>
                <ReactTooltip id="documents" place="bottom" effect='solid'>
                    Documents publics
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/gestion-de-donnees/materiaux")} data-tip data-for="materiaux">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={faBlockBrick} />
                </div>
                <ReactTooltip id="materiaux" place="bottom" effect='solid'>
                    Matériaux
                </ReactTooltip>
            </div>
            <div className='appIcon' onClick={() => handleIconNavigation("/justeau-actus/actualites")} >
                <div className="iconContainer" data-tip data-for="actualite">
                    <FontAwesomeIcon icon={faNewspaper} />
                </div>
                <ReactTooltip id="actualite" place="bottom" effect='solid'>
                    Actualités
                </ReactTooltip>
            </div>
        </div>
        <div className='mgT-b1'>
            <h3 className='mgB-s2'>Just'actus</h3>
            <div className='mgB-m1' style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "2rem"
            }}>
                {actualite.map((actu) => {
                    return (
                        <ActualiteItem key={actu._id} actualite={actu} />
                    );
                })}
            </div>
        </div>
        <div className='mgT-b1'>
            <h3>Télécharger l'application</h3>
            <div className='mgT-s3' style={{ display: 'flex', alignContent: "center", flexWrap: "wrap", gap: "1.6rem" }}>
                <a className='downloadAppContainer' href='https://play.google.com/store/apps/details?id=com.justeau_mobileapp' target="_blank">
                    <div className='downloadApp-infoContainer'>
                        <Icon icon="ant-design:android-filled" />
                        <div className='downloadApp-infoText'>
                            <p>Télécharger pour android</p>
                            <p>version: 2.1.8</p>
                        </div>
                    </div>
                </a>
                <a className='downloadAppContainer' href='https://apps.apple.com/us/app/justeau/id6476137992'>
                    <div className='downloadApp-infoContainer'>
                        <Icon icon="ant-design:apple-filled" />
                        <div className='downloadApp-infoText'>
                            <p>Télécharger pour ios</p>
                            <p>version: 1.1</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>;


    return (
        <div className="dashboard-layout admin-dashboard-layout">
            <AdminSidebar sidebarState={sidebarState} setSidebarState={setSidebarState} />
            <div className="dashboard-layout-main-container">
                <AdminHeader />
                <div className="dashboard-layout-page-content">
                    {currentUrl === "/" ? mainSection : <Outlet />}
                </div>
                {/* <FooterDashboard /> */}
            </div>
        </div>
    );
};

export default DashboardAdmin;
