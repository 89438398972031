import React from 'react';
import { useNavigate } from 'react-router-dom';
import justeauLogo from '../../assets/img/justeaulogo.png';
import ChevronRightIcon from '../icon/ChevronRightIcon';
import { Icon } from '@iconify/react';

const Sidebar = (props) => {
    const navigate = useNavigate();

    const handleItemClick = (e, nav) => {
        if (nav.type === "link") {
            props.setSidebarState(props.sidebarState.map((sidebarNav) => {
                if (sidebarNav.url === nav.url) {
                    return { ...sidebarNav, active: true };
                }
                else if (sidebarNav.type === "group") {
                    return {
                        ...sidebarNav, active: false, links: sidebarNav.links.map((sidebarSubNav) => {
                            return { ...sidebarSubNav, active: false };
                        })
                    };
                }
                return { ...sidebarNav, active: false };
            }));
            navigate(nav.url);
        }
        else if (nav.type === "group") {
            let openDocumentsHeight = e.currentTarget.nextSibling.childNodes.length * e.currentTarget.nextSibling.childNodes[0].offsetHeight - 4;
            let wasAutoOpened = false;
            props.setSidebarState(props.sidebarState.map((sidebarNav) => {
                if (sidebarNav.url === nav.url) {
                    if (sidebarNav.height === "fit-content") {
                        wasAutoOpened = true;
                        return { ...sidebarNav, height: openDocumentsHeight };
                    }
                    return { ...sidebarNav, opened: !sidebarNav.opened, height: !sidebarNav.opened ? openDocumentsHeight : 0 };
                }
                return sidebarNav;
            }));
            if (wasAutoOpened) {
                setTimeout(() => {
                    props.setSidebarState(props.sidebarState.map((sidebarNav) => {
                        if (sidebarNav.url === nav.url) {
                            return { ...sidebarNav, opened: !sidebarNav.opened, height: 0 };
                        }
                        return sidebarNav;
                    }));
                }, 50);
            }
        }
        else if (nav.type === "sublink") {
            props.setSidebarState(props.sidebarState.map((sidebarNav) => {
                if (nav.url.includes(sidebarNav.url) && sidebarNav.url !== "/") {
                    return {
                        ...sidebarNav, active: true, links: sidebarNav.links.map((subnavLink) => {
                            if (nav.url.includes(subnavLink.url)) {
                                return { ...subnavLink, active: true };
                            }
                            return { ...subnavLink, active: false };
                        })
                    };
                }
                else {
                    if (sidebarNav.type === "group") {
                        return {
                            ...sidebarNav, active: false, links: sidebarNav.links.map((subnavLink) => {
                                return { ...subnavLink, active: false };
                            })
                        };
                    }
                    else {
                        return { ...sidebarNav, active: false };
                    }
                }
            }));
            navigate(nav.url);
        }
    };

    return (
        <div onMouseEnter={(e) => { e.currentTarget.classList.add("openHover"); document.getElementsByClassName("dashboard-layout-main-container")[0].classList.add("openHover"); }} onMouseLeave={(e) => { e.currentTarget.classList.remove("openHover"); document.getElementsByClassName("dashboard-layout-main-container")[0].classList.remove("openHover"); }} className="sidebar sidebar--admin-sidebar">
            <div className="sidebar__top">
                <img className="sidebar__top-logo" src={justeauLogo} alt="justeau logo" />
            </div>
            <hr className='sidebar__separator' />
            <div className="sidebar__main">
                <ul>
                    {props.sidebarState.map((nav) => {

                        return <li key={nav.Header} className='sidebar__main-linkContainer'>
                            <div className={nav.active ? "sidebar__main-linkItem sidebar__main-linkItem-linkActive" : "sidebar__main-linkItem"} onClick={(e) => handleItemClick(e, nav)}>
                                <Icon icon={nav.iconName} className='link-icon' />
                                <p className="sidebar__main-linkItem-text">{nav.Header}</p>
                                {nav.type === "group" &&
                                    <ChevronRightIcon menuClass={nav.opened ? "chevron-right chevron-right-opened" : "chevron-right"} />
                                }
                            </div>
                            {nav.type === "group" &&
                                <ul className="subMenu-linklist" style={{ height: nav.height }}>
                                    {nav.links.map((subnavLink) => {
                                        return <li key={subnavLink.url} className={subnavLink.active ? 'sidebar__main-nested-link sidebar__main-nested-link-linkActive' : 'sidebar__main-nested-link'} onClick={(e) => handleItemClick(e, subnavLink)}>
                                            {subnavLink.Header}
                                        </li>;
                                    })}
                                </ul>
                            }
                        </li>;
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;