import * as actionTypes from "./actionTypes";

export const setOptionsFilterSort = (pageName, optionName, optionValue) => {
	return {
		type: actionTypes.SAVE_OPTIONS_FILTER_SORT,
		pageName,
		optionName,
		optionValue
	};
};

export const addTableFilter = (pageName, filterId, desc) => {
	return {
		type: actionTypes.ADD_TABLE_FILTER,
		pageName,
		filterId,
		desc
	};
};

export const removeTableFilter = (pageName, filterId) => {
	return {
		type: actionTypes.REMOVE_TABLE_FILTER,
		pageName,
		filterId,
	};
}

