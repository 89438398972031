import React, { useState } from 'react';
import * as yup from "yup";
import { useFormik } from 'formik';
import { formatBytes, iconExtension } from '../../../utils/GeneralHelper';
import Select from 'react-select/creatable';
import SelectSearch from 'react-select';
import axiosInstance from '../../../utils/axiosInstance';
import customToast from '../../../utils/ToastifyHelper';
import { showError } from '../../../utils/ErrorHelper';

const ModifierDocumentAmiante = (props) => {

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const formSchema = yup.object().shape({
        titre: yup.string().required("Veuillez saisir le titre du document"),
        // typeDocumentAmiante: yup.string().nullable(),
        userTypeVisibilityRights: yup.array().of(yup.string()).min(0)
    });

    const onSubmit = (values, actions) => {
        axiosInstance.patch(`/documentAmiante/${props.documentData._id._id}`, values).then((result) => {
            actions.resetForm();
            customToast.success("Le document a été enregistré avec succès");
            if (result.data.typeDocumentAmiante !== "" && result.data.typeDocumentAmiante !== null && !props.typeDocumentsOptions.options.includes(result.data.typeDocumentAmiante)) {
                props.ajouterTypeDocumentsOptions(result.data.typeDocumentAmiante);
            }
            props.modifierDocumentAmiante(result.data);
            close();
        }).catch((err) => {
            setSubmitting(false);
            showError(err);
        });
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting, setFieldValue } = useFormik({
        initialValues: {
            "titre": props.documentData._id.titre,
            // "typeDocumentAmiante": props.documentData.typeDocumentAmiante,
            "userTypeVisibilityRights": props.documentData.userTypeVisibilityRights
        },
        validationSchema: formSchema,
        onSubmit
    });

    const onChangeTypeDocument = (opt) => {
        if (opt) {
            setFieldValue("typeDocumentAmiante", opt.value);
        }
        else {
            setFieldValue("typeDocumentAmiante", opt);
        }
    };

    const onChangeUserVisibility = (opt) => {
        if (opt.length) {
            setFieldValue("userTypeVisibilityRights", opt.map((visibilityUser) => {
                return visibilityUser.value;
            }));
        }
        else {
            setFieldValue("userTypeVisibilityRights", opt);
        }
    };

    return (
        <div className="modal-content ajouter-form">
            <h3 className="modal-title">Modifier un document amiante</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit}>
                <div className='label-and-input'>
                    <label htmlFor='documentContent' className='form-label'>Document</label>
                    <div className="document-upload mgT-s2">
                        <div className="fileItem mgB-s3">
                            {iconExtension(props.documentData._id.file.filename)}
                            <p>{props.documentData._id.titre} - {formatBytes(props.documentData._id.file.length)}</p>
                        </div>
                    </div>
                </div>
                <div className='label-and-input'>
                    <label htmlFor='titre' className='form-label'>Titre du document<span className='required-field'>*</span></label>
                    <input name='titre' className={`new-default-input${errors.titre && touched.titre ? " input-error" : ""}`} type="text" placeholder="Titre du document" value={values.titre} onChange={handleChange} onBlur={handleBlur} />
                    {errors.titre && touched.titre && <p className="form-error-message">{errors.titre}</p>}
                </div>
                {/* <div className='label-and-input'>
                    <label htmlFor='typeDocumentAmiante' className='form-label'>Type du document</label>
                    <Select 
                        options={props.typeDocumentsOptions.options.map((typeDocumentAmiante) => {
                            return {label: typeDocumentAmiante, value: typeDocumentAmiante}
                        })}
                        name='typeDocumentAmiante'
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeTypeDocument(opt)}
                        placeholder={"Type de document"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b"  : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                        formatCreateLabel={(value) => `Ajouter "${value}" comme nouveau type de document`}
                        value={values.typeDocumentAmiante ? {label: values.typeDocumentAmiante, value: values.typeDocumentAmiante} : ""}
                    />
                    {errors.typeDocumentAmiante && touched.typeDocumentAmiante && <p className="form-error-message">{errors.typeDocumentAmiante}</p>}
                </div> */}
                <div className='label-and-input last-input'>
                    <label htmlFor='userTypeVisibilityRights' className='form-label'>Visibilité du document</label>
                    <SelectSearch
                        options={[
                            { label: "opérateur", value: "opérateur" },
                            { label: "Encadrant technique", value: "Encadrant technique" },
                            { label: "Encadrant de chantier", value: "Encadrant de chantier" },

                        ]}
                        name='userTypeVisibilityRights'
                        className="formSelect-search-container formSelect-search-container-full mgT-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => onChangeUserVisibility(opt)}
                        placeholder={"Visibilité du document"}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                fontFamily: "DM Sans",
                                border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                borderRadius: "0.6rem",
                                boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                '&:hover': {
                                    border: '1px solid black',
                                },
                            })
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        defaultValue={values.userTypeVisibilityRights.map((userRight) => {
                            return { label: userRight, value: userRight };
                        })}

                    />
                    {errors.userTypeVisibilityRights && touched.userTypeVisibilityRights && <p className="form-error-message">{errors.userTypeVisibilityRights}</p>}
                </div>
                <input disabled={isSubmitting} style={{ opacity: isSubmitting ? 0.4 : 1 }} className="btn btn-primary medium-btn" type="submit" value="Sauvegarder" />
            </form>

        </div>
    );
};

export default ModifierDocumentAmiante;