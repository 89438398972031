import React, { useRef, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import customToast from "../../utils/ToastifyHelper";
import { Icon } from "@iconify/react";
import Modal from "../../components/Modal";
import AjouterAnalyseProccessusCycle from "../../components/forms/AmianteForms/AjouterAnalyseProccessusCycle";
import { useDispatch } from "react-redux";
import * as actionCreators from "../../store/actions/exportAction";
import ValidationProcessus from "../../components/ValidationProcessus";
import SupprimerModalForm from "../../components/forms/SupprimerModalForm";
import ModifierAnalyseProcessusForm from "../../components/forms/ModifierAnalyseProcessusForm";
import AjouterAnalyseProcessus from "../../components/forms/AmianteForms/AjouterAnalyseProcessus";
import { showError } from "../../utils/ErrorHelper";
import { setChantierOptions } from "../../store/actions/chantierListAct";
import AjouterDocumentAnalyse from "../../components/forms/AmianteForms/AjouterDocumentAnalyse";
import LoadingBar from 'react-top-loading-bar';
import Breadcrumb from "../../components/ui/Breadcrumb";
import InsideNavigationBar from "../../components/ui/InsideNavigationBar";

import SearchInput from "../../components/ui/SearchInput";
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle, getInsensitiveWorld } from '../../utils/GeneralHelper';
import AjouterDocumentProcessus from "../../components/forms/AmianteForms/AjouterDocumentProcessus";
import TableRender from "../../components/tables/TableRender";
import { getDocumentsProcessusColumns } from "../../components/tables/TableColumns";
import ModifierDocumentProcessus from "../../components/forms/AmianteForms/ModifierDocumentProcessus";


const DetailProcessus = () => {
	const progressBarRef = useRef(null);
	const navigate = useNavigate();
	const { idProcessus } = useParams();
	const [currentProcessus, setCurrentProcessus] = useState();
	const [insideNavigationPage, setInsideNavigationPage] = useState("validationsProcessus");
	const dispatch = useDispatch();
	const [showedDocuments, setShowedDocuments] = useState([]);


	const setValidations = (validations) => {
		setCurrentProcessus((prevState) => ({
			...prevState,
			validationProcessus: validations,
		}));
	};

	const updateOneValidations = (validations) => {
		setCurrentProcessus((prevState) => ({
			...prevState,
			validationProcessus: prevState.validationProcessus.map((validation) => {
				return (validation._id === validations._id ? validations : validation);
			}),
		}));
	};

	const getSubNavigation = () => {
		let links = [
			{ label: "Validation du processus", value: "validationsProcessus" },
			{ label: "Documents", value: "documents" },
		];
		return links;
	};

	// const modifierAnalyse = (analyse) => {
	//     setCurrentProcessus((prevState) => ({
	//         ...prevState,
	//         validationProcessus: currentProcessus.validationProcessus.map((anl) => {

	//         })
	//     }))
	// }

	const deleteAllAnalyse = (analyseId) => {
		setCurrentProcessus((prevState) => ({
			...prevState,
			validationProcessus: prevState.validationProcessus.filter(validation => {
				return (validation._id !== analyseId);
			}),
		}));
	};

	const deleteOneAnalyse = (analyseId, typeAnalyseSupprimer, controleId) => {
		if (typeAnalyseSupprimer === "controle") {
			setCurrentProcessus((prevState) => ({
				...prevState,
				validationProcessus: prevState.validationProcessus.map((validation) => {
					if (validation._id === analyseId) {
						let updatedValidation = { ...validation };
						updatedValidation[typeAnalyseSupprimer] = validation[typeAnalyseSupprimer].filter(analyseControle => {
							return (analyseControle._id !== controleId);
						});
						return updatedValidation;
					}
					return validation;
				})
			}));
		}
		else {
			setCurrentProcessus((prevState) => ({
				...prevState,
				validationProcessus: prevState.validationProcessus.map((validation) => {
					if (validation._id === analyseId) {
						let updatedValidation = { ...validation };
						updatedValidation[typeAnalyseSupprimer] = null;
						return updatedValidation;
					}
					return validation;
				})
			}));
		}
	};

	const deleteDocumentAnalyse = (analyseId, typeAnalyseSupprimer, controleId) => {
		if (typeAnalyseSupprimer === "controle") {
			setCurrentProcessus((prevState) => ({
				...prevState,
				validationProcessus: prevState.validationProcessus.map((validation) => {
					if (validation._id === analyseId) {
						let updatedValidation = { ...validation };
						updatedValidation[typeAnalyseSupprimer] = validation[typeAnalyseSupprimer].map(analyseControle => {
							if (analyseControle._id === controleId) {
								return { ...analyseControle, document: null };
							}
							return analyseControle;
						});
						return updatedValidation;
					}
					return validation;
				})
			}));
		}
		else {
			setCurrentProcessus((prevState) => ({
				...prevState,
				validationProcessus: prevState.validationProcessus.map((validation) => {
					if (validation._id === analyseId) {
						let updatedValidation = { ...validation };
						updatedValidation[typeAnalyseSupprimer].document = null;
						return updatedValidation;
					}
					return validation;
				})
			}));
		}
	};

	useEffect(() => {
		progressBarRef.current.continuousStart();

		axiosInstance
			.get(`/processusAmiante/${idProcessus}`)
			.then((result) => {
				console.log(result.data);
				setCurrentProcessus(result.data);
				progressBarRef.current.complete();
			})
			.catch((err) => {
				progressBarRef.current.complete();
				showError(err);
			});
		axiosInstance.get('/chantiersAmiante?zone=true')
			.then((result) => {
				let formatOptions = [];
				for (const chantier of result.data) {
					formatOptions.push({ label: `${chantier.codeChantier} - ${chantier._id.libelle_lieu} (${chantier.zone.libelle_zone})`, value: chantier._id._id });
				}
				dispatch(setChantierOptions(formatOptions));
			}).catch((err) => {
				progressBarRef.current.complete();
				showError(err);
			});
	}, []);

	// gestion modal
	const [modalClasses, setModalClasses] = useState("modal");
	const [modalContent, setModalContent] = useState("Aucun contenu");
	const [modalDocumentSelected, setModalDocumentSelected] = useState({});


	const openAjouterAnalyseCycle = () => {
		setModalClasses("modal modal__active");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
		setModalContent(
			<AjouterAnalyseProccessusCycle
				processusId={currentProcessus._id}
				setValidations={setValidations}
				closeModal={() => setModalClasses("modal")}
				unrenderForm={() => setModalContent("Aucune modal")}
			/>
		);
	};

	const openAjouterAnalyse = (processusAnalyse) => {
		setModalClasses("modal modal__active");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
		setModalContent(
			<AjouterAnalyseProcessus
				processusId={currentProcessus._id}
				processusAnalyse={processusAnalyse}
				updateValidations={updateOneValidations}
				closeModal={() => setModalClasses("modal")}
				unrenderForm={() => setModalContent("Aucune modal")}
			/>
		);
	};

	const openModifierAnalyse = (analyse) => {
		setModalClasses("modal modal__active");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
		setModalContent(
			<ModifierAnalyseProcessusForm
				analyse={analyse}
				processusId={currentProcessus._id}
				setValidations={setValidations}
				updateValidations={updateOneValidations}
				closeModal={() => setModalClasses("modal")}
				unrenderForm={() => setModalContent("Aucune modal")}
			/>
		);
	};

	const openAjouterDocumentAnalyse = (analyse) => {
		setModalClasses("modal modal__active");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
		setModalContent(
			<AjouterDocumentAnalyse
				analyse={analyse}
				processusId={currentProcessus._id}
				setValidations={setValidations}
				updateValidations={updateOneValidations}
				closeModal={() => setModalClasses("modal")}
				unrenderForm={() => setModalContent("Aucune modal")}
			/>
		);
	};

	const openSupprimerAnalyse = (analyse) => {
		setModalClasses("modal modal__active");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
		setModalContent(
			<SupprimerModalForm
				processus={currentProcessus._id}
				deleteOneAnalyse={(analyseId, typeAnalyseSupprimer, controleId) =>
					deleteOneAnalyse(analyseId, typeAnalyseSupprimer, controleId)
				}
				deleteAllAnalyse={(analyseId) =>
					deleteAllAnalyse(analyseId)
				}
				analyse={analyse}
				destination="supprimerAnalyseProcessus"
				closeModal={() => setModalClasses("modal")}
				unrenderForm={() => setModalContent("Aucune modal")}
			/>
		);
	};

	const openSupprimerDocumentAnalyse = (analyse) => {
		setModalClasses("modal modal__active");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
		setModalContent(
			<SupprimerModalForm
				analyse={analyse}
				destination="supprimerDocumentAnalyseProcessus"
				closeModal={() => setModalClasses("modal")}
				unrenderForm={() => setModalContent("Aucune modal")}
				deleteDocumentAnalyse={(analyseId, typeAnalyseSupprimer, controleId) =>
					deleteDocumentAnalyse(analyseId, typeAnalyseSupprimer, controleId)
				}
			/>
		);
	};

	const [searchBarContent, setSearchBarContent] = useState(null);
	const onChangeSearchDocument = (e) => {
		setSearchBarContent(e.target.value);
	};

	useEffect(() => {
		if (currentProcessus) {
			if (searchBarContent !== null && searchBarContent !== "") {
				let timer = setTimeout(() => {
					setShowedDocuments(currentProcessus.documents.filter(doc => {
						return getInsensitiveWorld(doc.titre).includes(getInsensitiveWorld(searchBarContent));
					}));
				}, 1000);
				return () => {
					clearTimeout(timer);
				};
			}
			else {
				setShowedDocuments(currentProcessus.documents);
			}
		}
	}, [currentProcessus, searchBarContent]);


	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject
	} = useDropzone({
		multiple: false,
		accept: {
			'text/csv': [],
			'application/pdf': [],
			'application/vnd.ms-excel': [],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
			'image/jpeg': [],
			'image/png': []
		}
	});

	const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	]);

	useEffect(() => {
		if (acceptedFiles.length) {
			//open modal
			openAddDocumentProcessusModal(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	const addDocumentProcessus = (doc) => {
		setCurrentProcessus({ ...currentProcessus, documents: currentProcessus.documents.concat(doc) });
	};

	const openAddDocumentProcessusModal = (documentData) => {
		setModalDocumentSelected(documentData);
		setModalClasses("modal modal__active");
		setModalContent("ajouterDocument");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
	};

	const editDocumentProcessus = (doc) => {
		setCurrentProcessus({
			...currentProcessus, documents: currentProcessus.documents.map((document) => {
				if (document._id._id === doc._id._id) {
					return doc;
				}
				else {
					return document;
				}
			})
		});
	};

	const openEditDocumentProcessus = (documentData) => {
		setModalDocumentSelected(documentData);
		setModalClasses("modal modal__active");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
		setModalContent("modifierDocument");
	};

	const deleteDocumentProcessus = (documentId) => {
		setCurrentProcessus({
			...currentProcessus, documents: currentProcessus.documents.filter(doc => {
				return doc._id !== documentId;
			})
		});
	};

	const openSupprimerDocumentProcessus = (documentData) => {
		setModalDocumentSelected(documentData);
		setModalClasses("modal modal__active");
		setTimeout(() => {
			setModalClasses("modal modal__active modal__fade");
		}, 0.1);
		setModalContent("supprimerDocument");
	};


	return (
		<>
			<LoadingBar color={"#d13852"} ref={progressBarRef} />
			<div className='section'>
				{currentProcessus !== undefined && (
					<>
						{/* <div className="container-basic mgB-m2">
							<div style={{ display: "flex", alignItems: "start" }}>
								<Icon
									onClick={() => navigate(-1)}
									className="backButton"
									icon="akar-icons:chevron-left"
								/>
								<h1 className="h1-blue-dark">{`Processus ${currentProcessus.codeProcessus}`}</h1>
							</div>
						</div> */}
						<h1>
							{`Processus ${currentProcessus.codeProcessus}`}
						</h1>
						<Breadcrumb
							links={[
								{ label: "amiante", link: "/amiante" },
								{ label: "Processus amiante", link: "/amiante/processus" },
								{ label: currentProcessus.codeProcessus, link: "/amiante/processus/" },
							]}
						/>
						<InsideNavigationBar
							activeValue={insideNavigationPage}
							setActiveValue={(value) => setInsideNavigationPage(value)}
							links={getSubNavigation()}
						/>
						{insideNavigationPage === "validationsProcessus" &&
							<>
								{currentProcessus.validationProcessus.length ? (
									currentProcessus.validationProcessus.map((processus) => {
										return (
											<ValidationProcessus
												key={processus._id}
												processus={processus}
												openSupprimerAnalyse={(analyse) =>
													openSupprimerAnalyse(analyse)
												}
												openModifierAnalyse={(analyse) =>
													openModifierAnalyse(analyse)
												}
												openAjouterAnalyse={(processusAnalyse) => openAjouterAnalyse(processusAnalyse)}
												openAjouterDocumentAnalyse={(analyse) => openAjouterDocumentAnalyse(analyse)}
												openSupprimerDocumentAnalyse={(analyse) => openSupprimerDocumentAnalyse(analyse)}
											/>
										);
									})
								) : (
									<>
										<div className="container-basic mgB-m2">
											<p className="small-text">
												Aucune analyse effectuée sur ce processus
											</p>
										</div>

									</>
								)}
								<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
									<button
										onClick={() => openAjouterAnalyseCycle()}
										className="default-button default-button-blue"
									>
										+ Nouveau cycle de validation
									</button>
								</div>
							</>
						}
						{insideNavigationPage === "documents" &&
							<>
								<div className="filterContainer mgT-s3 mgB-s3">
									<SearchInput fullwidth={true} placeholder="Rechercher un document" searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearchDocument} />
								</div>
								<div>
									<div {...getRootProps({ style })}>
										<input {...getInputProps()} />
										<Icon style={{ color: isFocused ? "#1E1E1E" : "#bdbdbd" }} className='dropzone-icon' icon="ant-design:upload-outlined" />
										<p className='dropzone-text'>Glissez le fichier dans la zone, ou <span>cliquez pour le sélectionner</span></p>
									</div>
								</div>
								<TableRender
									// columns={getDocumentsMaterielsColumns((doc) => openEditDocument(doc), (doc) => openSupprimerDocument(doc))}
									columns={getDocumentsProcessusColumns((doc) => openEditDocumentProcessus(doc), (doc) => openSupprimerDocumentProcessus(doc))}
									tableData={showedDocuments}
									sortBy={[

									]}
									pageName="ProfilProcessusDocuments"
								/>
							</>
						}
						<Modal modalClass={modalClasses}>{modalContent}
							{modalContent === "ajouterDocument" &&
								<AjouterDocumentProcessus processusId={currentProcessus._id} progressBarRef={progressBarRef} closeModal={() => setModalClasses("modal")} documentData={modalDocumentSelected} addDocumentProcessus={(doc) => addDocumentProcessus(doc)} unrenderForm={() => setModalContent("Aucune modal")} />
							}
							{modalContent === "modifierDocument" &&
								<ModifierDocumentProcessus editDocumentProcessus={(doc) => editDocumentProcessus(doc)} progressBarRef={progressBarRef} processusId={currentProcessus._id} documentData={modalDocumentSelected} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
							}
							{modalContent === "supprimerDocument" &&
								<SupprimerModalForm destination="supprimerDocumentProcessus" deleteDocumentProcessus={(documentId) => deleteDocumentProcessus(documentId)} documentData={modalDocumentSelected} processusId={currentProcessus._id} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />
							}
						</Modal>
					</>
				)}
			</div>
		</>
	);
};

export default DetailProcessus;
